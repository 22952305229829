import { observer } from "mobx-react-lite";
import { Header } from "semantic-ui-react";
import { AppSkeleton } from "../../../../App";
import useValidParams from "../../../../hooks/useValidParameters";
import { useStore } from "../../../../stores/store";
import CourseFileLister from "./CourseFileLister";

const ViewCourseFiles: React.FC = () => {
  const { courseID } = useValidParams<{
    courseID: string;
  }>();
  const { userStore } = useStore();
  const { user } = userStore;

  if (!user) return <AppSkeleton />;

  return (
    <>
      <Header as="h2">Course Files</Header>
      <CourseFileLister courseID={courseID} />
    </>
  );
};

export default observer(ViewCourseFiles);
