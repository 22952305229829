import { Formik, Form, FormikValues } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Header } from "semantic-ui-react";
import TEACHFRONT_PATHS from "../../paths";
import { useStore } from "../../stores/store";
import FormLogoInput from "../_common/form/FormLogoInput";
import "./CourseRegistrationModal.css";

const CourseRegistrationModal: React.FC = () => {
  const { courseStore, userStore, modalStore, toastStore } = useStore();
  const { registerSelfForCourse, reset: resetCourseStore } = courseStore;
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <div className="CourseRegistrationModal">
      <Header as="h3" content="Register for Course" />
      <span className="action-description">
        Use the course code provided <br /> by your instructor to register below.
      </span>
      <Formik
        initialValues={{ code: "" }}
        initialErrors={{ code: "" }}
        onSubmit={(values: FormikValues, { setErrors }) => {
          const registrationCode = values.code;

          if (registrationCode) {
            setLoading(true);
            registerSelfForCourse(userStore.user?.userID as string, registrationCode, {
              customHandler: () => {
                setErrors({ code: "Something went wrong. Are you sure you have the right code?" });
                setLoading(false);
              },
            }).then((success) => {
              setLoading(false);
              if (success) {
                modalStore.closeModal();
                resetCourseStore();
                navigate(TEACHFRONT_PATHS.Landing);
                toastStore.showToast("Successfully registered for course.", {
                  color: "green",
                });
              }
            });
          }
        }}
      >
        {({ values, errors }) => (
          <Form className="form">
            <FormLogoInput
              type="text"
              required
              name="code"
              placeholder="Enter registration code here..."
              label="Registration Code"
              iconName="lock"
            />
            {errors.code && <span className="error">{errors.code as string}</span>}
            <Button
              content="Register for Course"
              type="submit"
              color="blue"
              fluid
              loading={loading}
              disabled={!values.code}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CourseRegistrationModal;
