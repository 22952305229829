import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RoundedSegment from "../components/_common/style/RoundedSegment";
import CreateOrEditAssessment from "../components/courses/[courseID]/_modal/assessment/CreateOrEditAssessment";
import useValidParams from "../hooks/useValidParameters";
import { useStore } from "../stores/store";
import buildPath from "../utilities/routing/buildPath";
import LoadingComponent from "../utilities/routing/components/LoadingComponent";

const CourseHomeCreateOrUpdateAssessmentTab: React.FC = () => {
  const { ratingStore, assignmentStore, userStore, submissionStore } = useStore();
  const { courseID, assignmentID, submissionID, userID } = useValidParams<{
    courseID: string;
    assignmentID: string;
    userID: string;
    submissionID: string;
  }>();

  const {
    ratingsForOneAssignmentForOneStudent,
    loadRatingsForOneAssignmentForOneStudent,
    hasLoadedRatingsForOneAssignmentForOneStudent,
  } = ratingStore;

  // We need the submission to get submission information (date & data)
  const { submissionsForAssignment, loadSubmissionsForAssignment } = submissionStore;

  const { assignmentWithObjectivesAndMasteryLevels, loadAssignmentWithObjectivesAndMasteryLevels } =
    assignmentStore;

  const { user } = userStore;

  const navigate = useNavigate();

  useEffect(() => {
    loadRatingsForOneAssignmentForOneStudent(assignmentID, userID, courseID);
  }, [courseID, assignmentID, userID, loadRatingsForOneAssignmentForOneStudent]);

  useEffect(() => {
    loadSubmissionsForAssignment(assignmentID, userID, courseID);
  }, [assignmentID, userID, courseID, loadSubmissionsForAssignment]);

  useEffect(() => {
    loadAssignmentWithObjectivesAndMasteryLevels(assignmentID, courseID);
  }, [courseID, assignmentID, loadAssignmentWithObjectivesAndMasteryLevels]);

  const submission =
    submissionsForAssignment?.find((s) => s.id === submissionID) && submissionsForAssignment[0];

  if (
    !hasLoadedRatingsForOneAssignmentForOneStudent(assignmentID, userID) ||
    !ratingsForOneAssignmentForOneStudent ||
    !assignmentWithObjectivesAndMasteryLevels ||
    !user ||
    !submission
  )
    return <LoadingComponent content="Loading assessment page..." />;

  return (
    <RoundedSegment>
      <CreateOrEditAssessment
        studentID={userID}
        assignment={{
          ...assignmentWithObjectivesAndMasteryLevels,
          objectives: ratingsForOneAssignmentForOneStudent,
        }}
        submission={submission}
        user={user}
        onCancel={() => {
          navigate(buildPath("CourseHomeAssignmentAllSubmissionsTab", { courseID, assignmentID }));
        }}
        courseID={courseID}
        onSubmit={() => {
          navigate(buildPath("CourseHomeAssignmentAllSubmissionsTab", { courseID, assignmentID }));
        }}
      />
    </RoundedSegment>
  );
};

export default observer(CourseHomeCreateOrUpdateAssessmentTab);
