import RoundedSegment from "../components/_common/style/RoundedSegment";
import ViewAssignmentDetails from "../components/courses/[courseID]/assignments/[assignmentID]/ViewAssignmentDetails";

const CourseHomeAssignmentDetailsTab: React.FC = () => (
  <RoundedSegment>
    <ViewAssignmentDetails />
  </RoundedSegment>
);

export default CourseHomeAssignmentDetailsTab;
