import { observer } from "mobx-react-lite";
import Hub from "../../../../api/hub";

const NonCoursePage = ({ children }: { children: JSX.Element }) => {
  // if the Hub is connected to anything, disconnect it.
  Hub.getCurrentInstance()?.disconnect();

  return children;
};

export default observer(NonCoursePage);
