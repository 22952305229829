import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { useStore } from "../../../../stores/store";
import "../../../_common/file/FileLister.css";
import "./CourseFileLister.css";
import CourseDirectory from "./CourseDirectory";

interface CourseFileListerProps {
  courseID: string;
}

const CourseFileLister: React.FC<CourseFileListerProps> = ({ courseID }) => {
  const { uploadedCourseFileStore } = useStore();
  const { hasLoadedCourseFiles, loadUploadedFilesForCourse, uploadedFilesForCourse } =
    uploadedCourseFileStore;

  useEffect(() => {
    loadUploadedFilesForCourse(courseID);
  }, [courseID, loadUploadedFilesForCourse]);

  // there are files to display
  return (
    <div className={"FileLister CourseFileLister"}>
      {(!uploadedFilesForCourse || !hasLoadedCourseFiles(courseID)) && (
        <Loader active={true} inline size={"small"} />
      )}
      {hasLoadedCourseFiles(courseID) && uploadedFilesForCourse && (
        <CourseDirectory directory={uploadedFilesForCourse} preventCollapsing />
      )}
    </div>
  );
};

export default observer(CourseFileLister);
