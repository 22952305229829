import { Field } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Dropdown, DropdownDivider, Header, Icon, Label } from "semantic-ui-react";
import useBooleanState from "../../../../../../hooks/useBooleanState";
import { CalendarEntry } from "../../../../../../models/CalendarEntry";
import { CalendarEntryLink } from "../../../../../../models/CalendarEntryLink";
import { CalendarLinkType } from "../../../../../../models/CalendarLinkType";
import { useStore } from "../../../../../../stores/store";
import LoadingComponent from "../../../../../../utilities/routing/components/LoadingComponent";
import { emptyID } from "../../../../../../utilities/submissionUtils";
import { createClassName } from "../../../../../../utilities/utils";
import FlexContainer from "../../../../../_common/style/FlexContainer";
import "./CalendarEntryLinkEditor.css";
import QuickLinkTypeModal from "./QuickLinkTypeModal";
import {
  FormikSetFieldValueFunction,
  FormikSetValuesFunction,
} from "../../../../../../utilities/formUtils";
import { CalendarEntryModalFormValues } from "../CreateOrUpdateCalendarEntryModal";

interface LinkRowProps {
  calendarEntryLink: CalendarEntryLinkWithType;
  editCalendarEntryLink: (calendarEntryLink: CalendarEntryLink, newValue: string) => void;
  removeCalendarEntryLink: (calendarEntryLink: CalendarEntryLink) => void;
}

const LinkRow: React.FC<LinkRowProps> = ({
  removeCalendarEntryLink,
  calendarEntryLink,
  editCalendarEntryLink,
}) => {
  const [hovered, setHovered] = useBooleanState();
  const [focused, setFocused] = useBooleanState();

  return (
    <FlexContainer
      flexDirection="row"
      className={createClassName("link-row", { name: "focused", apply: focused })}
      alignItems="center"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      {!hovered && <Icon name="chain" className="link-icon" />}
      {hovered && (
        <div role="button" onClick={() => removeCalendarEntryLink(calendarEntryLink)}>
          <Icon name="trash" className="link-icon" />
        </div>
      )}
      <Field
        className="link-row-field blank-field-input"
        value={calendarEntryLink.link}
        placeholder="Enter link here..."
        onChange={(newValue: React.ChangeEvent<HTMLInputElement>) =>
          editCalendarEntryLink(calendarEntryLink, newValue.target.value)
        }
        id={calendarEntryLink.calendarLinkTypeID} // TODO: Change this to .id once calendar entry links have IDs
      />
      <Label
        icon={<Icon name={calendarEntryLink.calendarLinkType.icon} fitted size="large" />}
        content={calendarEntryLink.calendarLinkType.name}
        className="link-row-label blank bold"
      />
    </FlexContainer>
  );
};

type CalendarEntryLinkWithType = CalendarEntryLink & {
  calendarLinkType: CalendarLinkType;
};

interface CalendarEntryLinkEditorProps {
  currentCalendarEntryLinks: CalendarEntryLink[];
  courseID: string;
  calendarEntry: CalendarEntry | undefined;
  setFieldValue: FormikSetFieldValueFunction;
  values: CalendarEntryModalFormValues;
  setValues: FormikSetValuesFunction<CalendarEntryModalFormValues>;
  calendarEntryLinksFieldValueName: string;
  newLinkFieldValueName: string;
  currentNewLink: string;
}

const CalendarEntryLinkEditor: React.FC<CalendarEntryLinkEditorProps> = ({
  currentCalendarEntryLinks,
  courseID,
  calendarEntry,
  setFieldValue,
  values,
  setValues,
  newLinkFieldValueName,
  calendarEntryLinksFieldValueName,
  currentNewLink,
}) => {
  const { calendarStore, modalStore } = useStore();
  const { calendarLinkTypes, loadCalendarLinkTypes, hasLoadedCalendarLinkTypesForCourse } =
    calendarStore;
  const [dropdownRowFocused, setDropdownRowFocused] = useBooleanState();

  useEffect(() => {
    loadCalendarLinkTypes(courseID);
  }, [courseID]);

  if (!calendarLinkTypes || !hasLoadedCalendarLinkTypesForCourse(courseID))
    return <LoadingComponent content="Loading calendar link types..." />;

  // Ensure that the CalendarEntryLink has its CalendarLinkType
  const calendarEntryLinksWithCalendarLinkTypes: CalendarEntryLinkWithType[] =
    currentCalendarEntryLinks.map((calendarEntryLink) => ({
      ...calendarEntryLink,
      calendarLinkType:
        calendarEntryLink.calendarLinkType ??
        (calendarLinkTypes.find(
          ({ id }) => id === calendarEntryLink.calendarLinkTypeID
        ) as CalendarLinkType),
    }));

  const unusedCalendarLinkTypes = calendarLinkTypes.filter(
    (calendarLinkType) =>
      !currentCalendarEntryLinks.some((link) => link.calendarLinkTypeID === calendarLinkType.id)
  );

  const addNewCalendarLink = (calendarLinkType: CalendarLinkType) => {
    const calendarLink: CalendarEntryLink = {
      calendarEntryID: calendarEntry?.id ?? emptyID,
      calendarLinkTypeID: calendarLinkType.id,
      courseID,
      isLocalRoute: false,
      link: currentNewLink,
      calendarLinkType,
    };

    const newCalendarEntryLinks = [...calendarEntryLinksWithCalendarLinkTypes, calendarLink];

    setValues({
      ...values,
      [calendarEntryLinksFieldValueName]: newCalendarEntryLinks,
      [newLinkFieldValueName]: "",
    });
  };

  const editCalendarEntryLink = (calendarEntryLink: CalendarEntryLink, newLink: string) => {
    const calendarLink: CalendarEntryLink = {
      ...calendarEntryLink,
      link: newLink,
    };

    const tempCalendarLinks = [...currentCalendarEntryLinks];

    const index = tempCalendarLinks.findIndex(
      (link) => link.calendarLinkTypeID === calendarLink.calendarLinkTypeID
    );

    if (index !== -1) (tempCalendarLinks[index] as CalendarEntryLink).link = newLink;

    setFieldValue(calendarEntryLinksFieldValueName, tempCalendarLinks);
  };

  const removeCalendarEntryLink = (calendarEntryLink: CalendarEntryLink) => {
    const tempCalendarLinks = [...currentCalendarEntryLinks];

    const index = tempCalendarLinks.findIndex(
      (link) => link.calendarLinkTypeID === calendarEntryLink.calendarLinkTypeID
    );

    if (index !== -1) tempCalendarLinks.splice(index, 1);

    setFieldValue(calendarEntryLinksFieldValueName, tempCalendarLinks);
  };

  return (
    <FlexContainer flexDirection="column" gap="0.5em" className="CalendarEntryLinkEditor">
      <Header
        content="Do you want to add any links to this event?"
        as="h4"
        className="no-bottom-margin"
      />
      {calendarEntryLinksWithCalendarLinkTypes.map((calendarEntryLink) => (
        <LinkRow
          removeCalendarEntryLink={removeCalendarEntryLink}
          calendarEntryLink={calendarEntryLink}
          editCalendarEntryLink={editCalendarEntryLink}
          key={calendarEntryLink.calendarLinkTypeID}
        />
      ))}
      <FlexContainer
        flexDirection="row"
        className={createClassName("link-row", { name: "focused", apply: dropdownRowFocused })}
        alignItems="center"
        onFocus={() => setDropdownRowFocused(true)}
        onBlur={() => setDropdownRowFocused(false)}
      >
        <Icon name="chain" className="link-icon" />
        <Field
          className="link-row-field blank-field-input"
          name={newLinkFieldValueName}
          placeholder="Enter link here, then select quick link type..."
        />
        <Dropdown
          className="link-row-label"
          disabled={!currentNewLink}
          text="Select Quick Link Type"
        >
          <Dropdown.Menu>
            {unusedCalendarLinkTypes.map((calendarLinkType) => (
              <Dropdown.Item
                key={calendarLinkType.id}
                text={calendarLinkType.name}
                icon={calendarLinkType.icon}
                onClick={() => addNewCalendarLink(calendarLinkType)}
              />
            ))}
            {unusedCalendarLinkTypes.length > 0 && <DropdownDivider />}
            <Dropdown.Item
              text="New Quick Link Type"
              icon="plus"
              onClick={() =>
                modalStore.openModal(
                  (index) => (
                    <QuickLinkTypeModal
                      courseID={courseID}
                      modalIndex={index}
                      onLinkCreated={(newLink) => addNewCalendarLink(newLink)}
                    />
                  ),
                  { isChildModal: true }
                )
              }
            />
          </Dropdown.Menu>
        </Dropdown>
      </FlexContainer>
    </FlexContainer>
  );
};

export default observer(CalendarEntryLinkEditor);
