import { useParentSize } from "@visx/responsive";
import { scaleLog } from "@visx/scale";
import { Text } from "@visx/text";
import Wordcloud from "@visx/wordcloud/lib/Wordcloud";
import { useState } from "react";
import { TbArrowsDiagonal, TbArrowsDiagonalMinimize2 } from "react-icons/tb";
import { PollingActivityQuestion } from "../../../../../../models/polling/PollingActivityQuestion";
import { createClassName } from "../../../../../../utilities/utils";
import FlexContainer from "../../../../../_common/style/FlexContainer";
import "./InstructorPollingActivityOverviewWordCloudGraphic.css";

interface InstructorPollingActivityOverviewWordCloudGraphicProps {
  pollingActivityQuestions: PollingActivityQuestion[];
}

interface WordCloudWord {
  text: string;
  value: number;
}

const InstructorPollingActivityOverviewWordCloudGraphic: React.FC<
  InstructorPollingActivityOverviewWordCloudGraphicProps
> = ({ pollingActivityQuestions }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const wordCloudQuestion = pollingActivityQuestions.find((q) =>
    q.pollingQuestionTemplate.name.includes("Word")
  );

  const wordMap: { [key: string]: number } = {};

  wordCloudQuestion?.pollingActivityQuestionResponses.forEach((r) => {
    if (wordMap[r.text]) {
      // eslint-disable-next-line operator-assignment, @typescript-eslint/no-non-null-assertion
      wordMap[r.text] = wordMap[r.text]! + 1;
    } else {
      wordMap[r.text] = 1;
    }
  });

  const responseWords = Object.keys(wordMap).map((key) => ({
    text: key,
    value: wordMap[key],
  })) as WordCloudWord[];

  const colors = [
    "var(--blue-5)",
    "var(--teal-5)",
    "var(--green-5)",
    "var(--olive-5)",
    "var(--yellow-5)",
    "var(--orange-5)",
    "var(--brown-5)",
    "var(--red-5)",
    "var(--pink-5)",
    "var(--violet-5)",
    "var(--purple-5)",
    "var(--indigo-5)",
  ];

  const { parentRef, width, height } = useParentSize({ debounceTime: 150 });

  const longestWordLength = Math.max(...responseWords.map((w) => w.text.length));

  // try to gauge the font size by the window size
  let maxFontSize = (width / longestWordLength) * 1.5;
  // make sure the font size is at least 16 and no more than 100
  maxFontSize = Math.min(maxFontSize, 100);
  maxFontSize = Math.max(maxFontSize, 16);

  const minFontSize = Math.max(maxFontSize / 2, 12);

  const fontScale = scaleLog({
    domain: [
      Math.min(...responseWords.map((w) => w.value)),
      Math.max(...responseWords.map((w) => w.value)),
    ],
    range: [minFontSize, maxFontSize],
  });

  const fontSizeSetter = (datum: WordCloudWord) => fontScale(datum.value);

  return (
    <FlexContainer
      gap="1rem"
      alignItems="center"
      justifyContent="center"
      className="InstructorPollingActivityOverviewWordCloudGraphic"
    >
      <div
        ref={parentRef}
        className={createClassName("chart-wrapper", { name: "expanded", apply: expanded })}
      >
        {!expanded && (
          <TbArrowsDiagonal
            className="expand-word-cloud-button"
            onClick={() => setExpanded(!expanded)}
          />
        )}
        {expanded && (
          <TbArrowsDiagonalMinimize2
            className="expand-word-cloud-button"
            onClick={() => setExpanded(!expanded)}
          />
        )}
        <Wordcloud
          words={responseWords}
          width={width}
          height={height}
          fontSize={fontSizeSetter}
          font={"Impact"}
          padding={2}
          spiral="rectangular"
          rotate={0}
        >
          {(cloudWords) =>
            cloudWords.map((w, i) => (
              <Text
                key={`${w.text}-${i}`}
                fill={colors[i % colors.length]}
                textAnchor={"middle"}
                transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                fontSize={w.size}
                fontFamily={w.font}
              >
                {w.text}
              </Text>
            ))
          }
        </Wordcloud>
      </div>
    </FlexContainer>
  );
};

export default InstructorPollingActivityOverviewWordCloudGraphic;
