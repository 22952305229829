import Color from "../../../models/Color";
import FlexContainer from "../style/FlexContainer";
import "./ColorDisplayBox.css";

interface ColorDisplayBoxProps {
  color: Color;
  onClick: () => void;
}

const ColorDisplayBox: React.FC<ColorDisplayBoxProps> = ({ onClick, color }) => (
  <FlexContainer justifyContent="center" alignItems="center" className="ColorPickerInput">
    <div
      className="input"
      role="button"
      onClick={onClick}
      style={{ backgroundColor: `var(--${color}-5` }}
    />
  </FlexContainer>
);

export default ColorDisplayBox;
