import { Field, Form, Formik, FormikErrors, FormikValues } from "formik";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Checkbox,
  Container,
  Header,
  Icon,
  Input,
  Label,
  LabelDetail,
  Message,
  Segment,
} from "semantic-ui-react";
import useToast from "../../../../../hooks/useToast";
import { Assignment, AssignmentSubmissionFormat } from "../../../../../models/Assignment";
import { AssignmentType } from "../../../../../models/AssignmentType";
import { Objective } from "../../../../../models/Objective";
import { useStore } from "../../../../../stores/store";
import { getMapValues, map, objectMap, objectSize } from "../../../../../utilities/collectionUtils";
import { isFormDirty } from "../../../../../utilities/formUtils";
import LoadingComponent from "../../../../../utilities/routing/components/LoadingComponent";
import { emptyID } from "../../../../../utilities/submissionUtils";
import { createClassName } from "../../../../../utilities/utils";
import DragAndDropFile from "../../../../_common/file/DragAndDropFile";
import HorizontalIndent from "../../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import TextEditor from "../../../../_common/textEditing/TextEditor";
import CourseObjective from "../../syllabus/CourseObjective";
import AssignmentTypeSelectorForm from "./AssignmentTypeSelectorForm";
import "./CreateOrEditAssignment.css";

interface CreateOrEditAssignmentProps {
  courseID: string;
  assignmentToEdit?: Assignment<Objective>;
}

export type CreateOrEditAssignmentFormValues = {
  name: string;
  details: string;
  hasUploadedFiles: boolean;
  dueDate: Date | undefined;
  resubmissionDueDate: Date | undefined;
  acceptSubmissionsUntilDate: Date | undefined;
  releaseDate: Date | undefined;
  selectedObjectives: Objective[];
  assignmentTypes: AssignmentType[];
  allowedSubmissionFormat: AssignmentSubmissionFormat[];
  requestAssignmentSurvey: boolean;
  newAssignmentTypeName: string;
};

const dateFormat = "MM/dd/yyyy hh:mm a";
const tonightAtMidnight = new Date();
tonightAtMidnight.setHours(23, 59, 59, 999);

const CreateOrEditAssignment: React.FC<CreateOrEditAssignmentProps> = ({
  courseID,
  assignmentToEdit,
}) => {
  const dueDateRef = useRef<DatePicker<never, undefined> | null>();
  const resubmissionDueDateRef = useRef<DatePicker<never, undefined> | null>();
  const acceptSubmissionsUntilRef = useRef<DatePicker<never, undefined> | null>();
  const releaseDateRef = useRef<DatePicker<never, undefined> | null>();
  const { objectiveStore, modalStore, assignmentStore, ratingStore, userStore } = useStore();
  const {
    allObjectives,
    hierarchicalObjectives,
    loadAllObjectives,
    loadObjectivesHierarchical,
    hasLoadedAllObjectives,
    hasLoadedHierarchicalObjectives,
  } = objectiveStore;
  const {
    ratingsForOneAssignmentForAllStudents,
    loadRatingsForOneAssignmentForAllStudents,
    hasLoadedRatingsForOneAssignmentForAllStudents,
  } = ratingStore;
  const { loadAssignmentTypes, assignmentTypes, createDraftAssignment } = assignmentStore;
  const { user } = userStore;
  const setToast = useToast();

  const [assignment, setAssignment] = useState<Assignment<Objective>>();

  useEffect(() => {
    loadAssignmentTypes(courseID);
    loadAllObjectives(courseID);
    loadObjectivesHierarchical(courseID);
  }, [courseID]);

  useEffect(() => {
    if (assignmentToEdit === undefined || assignmentToEdit === null) {
      createDraftAssignment(courseID).then((a) => {
        if (a !== undefined) {
          setAssignment(a);
        }
      });
    } else {
      setAssignment(assignmentToEdit);
    }
  }, [createDraftAssignment, courseID, assignmentToEdit]);

  useEffect(() => {
    if (assignment) {
      // load ratings
      loadRatingsForOneAssignmentForAllStudents(assignment.id, courseID, false);
    }
  }, [loadRatingsForOneAssignmentForAllStudents, courseID, assignment]);

  if (!assignment) {
    return <LoadingComponent content="Creating draft assignment..." />;
  }

  if (
    !user ||
    !allObjectives ||
    !hierarchicalObjectives ||
    !assignmentTypes ||
    !hasLoadedAllObjectives() ||
    !hasLoadedHierarchicalObjectives() ||
    (assignment && assignment.id && !hasLoadedRatingsForOneAssignmentForAllStudents(assignment?.id))
  )
    return <LoadingComponent content="Loading Assignment..." />;

  const doRatingsExist = !!ratingsForOneAssignmentForAllStudents?.some(
    (ro) => ro.ratingsForStudents && objectSize(ro.ratingsForStudents) > 0
  );

  const allObjectiveValues = getMapValues(allObjectives);

  const validate = (values: FormikValues) => {
    const errors: FormikValues = {};

    const { name, dueDate, resubmissionDueDate, acceptSubmissionsUntilDate, releaseDate } = values;

    if (!name) errors.name = "You must enter an assessment name";

    if (!dueDate) errors.dueDate = "You must set a due date";

    if (resubmissionDueDate && resubmissionDueDate.getTime() < dueDate.getTime())
      errors.resubmissionDueDate =
        "The resubmission due date cannot be before the original due date";

    if (acceptSubmissionsUntilDate && acceptSubmissionsUntilDate.getTime() < dueDate.getTime())
      errors.acceptSubmissionsUntilDate =
        "The late due date cannot be before the original due date";

    if (releaseDate && releaseDate.getTime() >= dueDate.getTime())
      errors.acceptSubmissionsUntilDate = "The release date cannot be after the original due date";

    return errors;
  };

  const handleSubmissionFormatLabelClick = (
    submissionFormat: AssignmentSubmissionFormat,
    submissionFormats: AssignmentSubmissionFormat[],
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void
  ) => {
    let newChildren;

    if (submissionFormat === "NoSubmission") {
      newChildren = ["NoSubmission"];
    } else if (submissionFormat === "Polling") {
      newChildren = ["Polling"];
    } else if (submissionFormats?.some((sF) => sF === submissionFormat)) {
      newChildren = submissionFormats.filter(
        (sF) => sF !== submissionFormat && sF !== "NoSubmission" && sF !== "Polling"
      );
    } else {
      newChildren = [
        ...submissionFormats.filter((sF) => sF !== "NoSubmission" && sF !== "Polling"),
        submissionFormat,
      ];
    }

    if (newChildren.length === 0) {
      newChildren = ["NoSubmission"];
    }

    setFieldValue("allowedSubmissionFormat", [...newChildren]);
  };

  const handleObjectiveLabelClick = (
    objective: Objective,
    selectedObjectives: Objective[],
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void
  ) => {
    let newChildren;

    if (doRatingsExist) return;

    // Check if the objective is already selected
    const isObjectiveSelected = selectedObjectives?.find((o) => o.id === objective.id);

    if (isObjectiveSelected) {
      // If selected, remove it from the list
      newChildren = selectedObjectives.filter((o) => o.id !== objective.id);
    } else {
      // If not selected, add it to the list
      newChildren = [...selectedObjectives, objective];

      // Remove the objective from its parent's list
      const parentObjectivesArray = Array.from(hierarchicalObjectives.values());
      const parentObjective = parentObjectivesArray.find((o) => o.id === objective.parentID);
      if (parentObjective) {
        newChildren = newChildren.filter((o) => o.id !== parentObjective.id);
      }
    }

    setFieldValue("selectedObjectives", newChildren);
  };

  const handleHowToShowMasteryForObjectiveChange = (
    objective: Objective,
    howToShowMasteryForObjective: string,
    selectedObjectives: Objective[],
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void
  ) => {
    // create new array of selected objectives so we don't overwrite
    // the initial one used to determine if the form is dirty
    const newSelectedObjectives = [...selectedObjectives];

    // get the selected objective and make a copy of that too for good measure
    const selectedObjectiveIndex = newSelectedObjectives.indexOf(objective);
    const selectedObjective = { ...newSelectedObjectives[selectedObjectiveIndex] } as Objective;

    // should always be true
    if (selectedObjective) {
      // update that objective with the new details and save it back into the array
      selectedObjective.howToShowMastery = howToShowMasteryForObjective;
      newSelectedObjectives[selectedObjectiveIndex] = selectedObjective;
    }

    // update the selected objectives array
    setFieldValue("selectedObjectives", newSelectedObjectives);
  };

  const handleAssignmentTypeLabelClick = (
    assignmentTypeID: string,
    assignmentTs: AssignmentType[],
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void
  ) => {
    let newChildren;

    if (assignmentTs?.some((aT) => aT.id === assignmentTypeID)) {
      newChildren = assignmentTs.filter((aT) => aT.id !== assignmentTypeID);
    } else {
      newChildren = Array.from([
        ...(assignmentTs || []),
        assignmentTypes?.find((aT) => aT.id === assignmentTypeID),
      ]);
    }

    setFieldValue("assignmentTypes", [...newChildren]);
  };

  const handleDatePickerOpen = (
    date: Date | undefined,
    fieldName: string,
    setFieldValue: (
      field: string,
      value: unknown,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<CreateOrEditAssignmentFormValues>>
  ) => {
    const dateToSet = date ?? tonightAtMidnight;
    setFieldValue(fieldName, dateToSet);
  };

  const handleFormSubmit = (values: FormikValues, draft: boolean) => {
    if (!values) return;

    const {
      name,
      details,
      dueDate,
      resubmissionDueDate,
      acceptSubmissionsUntilDate,
      releaseDate,
      selectedObjectives,
      assignmentTypes: types,
      allowedSubmissionFormat,
      requestAssignmentSurvey,
    } = values;

    const assignmentID = assignment?.id ?? emptyID;
    const newAssignment: Assignment<Objective> = {
      id: assignmentID,
      name,
      dueDate: dueDate as Date,
      resubmissionDueDate,
      acceptSubmissionsUntilDate,
      releaseDate,
      description: details || undefined,
      hasUploadedFiles: false, // TODO: DELETE THIS
      courseID,
      allowedSubmissionFormat: allowedSubmissionFormat || ["FileUploads", "Text"],
      assignmentObjectives: selectedObjectives.map(
        (objective: Objective, relativeOrderIndex: number) => ({
          objectiveID: objective.id,
          courseID,
          assignmentID,
          howToShowMastery: objective.howToShowMastery,
          relativeOrderIndex,
        })
      ),
      assignmentTypes: types || [],
      isDraft: draft,
      requestAssignmentSurvey,
    };

    assignmentStore.createOrUpdateAssignment(newAssignment).then((success) => {
      assignmentStore.reset();
      modalStore.closeModal();

      if (success) {
        setToast(`Successfully ${assignment ? "updated" : "created"} assignment.`, "green");
      } else {
        setToast(
          `Something went wrong while ${assignment ? "updating" : "creating"} the assignment.`,
          "green"
        );
      }
    });
  };

  let selectedObjectivesList: Objective[] = [];
  if (assignment?.objectives) {
    selectedObjectivesList = _.cloneDeep(assignment?.objectives);
  }

  const initValues: CreateOrEditAssignmentFormValues = {
    name: assignment?.name ?? "",
    details: assignment?.description ?? "",
    hasUploadedFiles: assignment?.hasUploadedFiles ?? false,
    dueDate: assignment?.dueDate,
    resubmissionDueDate: assignment?.resubmissionDueDate,
    acceptSubmissionsUntilDate: assignment?.acceptSubmissionsUntilDate,
    releaseDate: assignment?.releaseDate,
    selectedObjectives: selectedObjectivesList,
    assignmentTypes: assignment?.assignmentTypes ?? [],
    allowedSubmissionFormat:
      assignment &&
      assignment.id &&
      assignment.allowedSubmissionFormat &&
      assignment.allowedSubmissionFormat.length > 0
        ? assignment.allowedSubmissionFormat
        : (["FileUploads", "Text"] as AssignmentSubmissionFormat[]),
    requestAssignmentSurvey: assignment?.requestAssignmentSurvey,
    newAssignmentTypeName: "",
  };

  return (
    <Container>
      <Formik
        initialValues={initValues}
        onSubmit={(formValues) => {
          handleFormSubmit(formValues, false);
        }}
        validate={validate}
      >
        {({ values, setFieldValue, errors, setFieldTouched, setValues }) => (
          <Form className="CreateOrEditAssignment">
            <Header
              as="h2"
              content={
                assignment.name ? `Update Assignment: ${assignment.name}` : "Create Assignment"
              }
            />
            <Label content="Name This Assignment:*" className="blank bold" />
            <br />
            <Field name="name" className="name" placeholder="Enter assignment name..." />
            <VerticalGap height="1em" />
            <Label content="Select Allowed Submission Format(s):*" className="blank bold" />
            <div className="submission-formats">
              <Label
                className={createClassName("submission-format", "rounded-0", "pointer", {
                  name: "blue",
                  else: "",
                  apply: !!values.allowedSubmissionFormat?.includes("Text"),
                })}
                onClick={() =>
                  handleSubmissionFormatLabelClick(
                    "Text",
                    values.allowedSubmissionFormat || [],
                    setFieldValue
                  )
                }
              >
                <Icon name="write" />
                <br />
                Text Input
              </Label>
              <Label
                className={createClassName("submission-format", "rounded-0", "pointer", {
                  name: "blue",
                  else: "",
                  apply: !!values.allowedSubmissionFormat?.includes("URL"),
                })}
                onClick={() =>
                  handleSubmissionFormatLabelClick(
                    "URL",
                    values.allowedSubmissionFormat || [],
                    setFieldValue
                  )
                }
              >
                <Icon name="linkify" />
                <br />
                Web Link
              </Label>
              &nbsp;
              <Label
                className={createClassName("submission-format", "rounded-0", "pointer", {
                  name: "blue",
                  apply: !!values.allowedSubmissionFormat?.includes("FileUploads"),
                })}
                onClick={() =>
                  handleSubmissionFormatLabelClick(
                    "FileUploads",
                    values.allowedSubmissionFormat || [],
                    setFieldValue
                  )
                }
              >
                <Icon name="upload" />
                <br />
                Uploaded Files
              </Label>
              &nbsp;
              <Label
                className={createClassName("submission-format", "rounded-0", "pointer", {
                  name: "blue",
                  else: "",
                  apply: !!values.allowedSubmissionFormat?.includes("NoSubmission"),
                })}
                onClick={() =>
                  handleSubmissionFormatLabelClick(
                    "NoSubmission",
                    values.allowedSubmissionFormat || [],
                    setFieldValue
                  )
                }
              >
                <Icon name="dont" />
                <br />
                No Submission
              </Label>
            </div>

            <VerticalGap height="1em" />
            <Label content="Add Instructions:" className="blank bold" />

            <div className="instructions">
              <HorizontalIndent>
                <div className="instructions-input">
                  <TextEditor
                    defaultValue={values.details}
                    onChange={(value) => setFieldValue("details", value)}
                    placeholder={"Enter assessment details here..."}
                  />
                </div>
              </HorizontalIndent>
            </div>
            <VerticalGap height="1em" />

            <Label content="Upload Related File(s):" className="blank bold" />
            <DragAndDropFile
              connectedComponentID={assignment.id}
              uploadFileCategory="assignment"
              courseID={courseID}
              userID={user.userID}
              showUploadComponentByDefault={true}
            />
            <VerticalGap height="2em" />
            <Label content="Select Date(s):" className="blank bold" />
            <div className="date-pickers">
              <HorizontalIndent>
                <Input className="date-parent" onClick={() => dueDateRef.current?.setOpen(true)}>
                  <Label
                    className={createClassName({
                      name: "blue",
                      else: "",
                      apply: values.dueDate !== null && values.dueDate !== undefined,
                    })}
                  >
                    <Icon name="calendar alternate outline" />
                    Initial Submissions Due:*
                  </Label>
                  <DatePicker
                    ref={(r) => {
                      dueDateRef.current = r;
                    }}
                    className="initial-submissions-due-date"
                    selected={values.dueDate}
                    onChange={(date) => setFieldValue("dueDate", date)}
                    onCalendarOpen={() => {
                      handleDatePickerOpen(values.dueDate, "dueDate", setFieldValue);
                    }}
                    showTimeInput
                    dateFormat={dateFormat}
                  />
                </Input>
                <VerticalGap height="0.5em" />

                <Input
                  className="date-parent"
                  onClick={() => acceptSubmissionsUntilRef.current?.setOpen(true)}
                >
                  <Label
                    className={createClassName({
                      name: "blue",
                      else: "",
                      apply:
                        values.acceptSubmissionsUntilDate !== null &&
                        values.acceptSubmissionsUntilDate !== undefined,
                    })}
                  >
                    <Icon name="stopwatch" />
                    Late Submissions Due:
                  </Label>
                  <DatePicker
                    ref={(r) => {
                      acceptSubmissionsUntilRef.current = r;
                    }}
                    className="late-submissions-due-date"
                    minDate={values.dueDate ?? tonightAtMidnight}
                    minTime={values.dueDate ?? tonightAtMidnight}
                    selected={values.acceptSubmissionsUntilDate}
                    onChange={(date) => setFieldValue("acceptSubmissionsUntilDate", date)}
                    onCalendarOpen={() => {
                      handleDatePickerOpen(
                        values.acceptSubmissionsUntilDate ?? values.dueDate,
                        "acceptSubmissionsUntilDate",
                        setFieldValue
                      );
                    }}
                    showTimeInput
                    dateFormat={dateFormat}
                  />
                </Input>
                <VerticalGap height="0.5em" />

                <Input
                  className="date-parent"
                  onClick={() => resubmissionDueDateRef.current?.setOpen(true)}
                >
                  <Label
                    className={createClassName({
                      name: "blue",
                      else: "",
                      apply:
                        values.resubmissionDueDate !== null &&
                        values.resubmissionDueDate !== undefined,
                    })}
                  >
                    <Icon name="redo" />
                    Reassessment Requests Due:
                  </Label>
                  <DatePicker
                    ref={(r) => {
                      resubmissionDueDateRef.current = r;
                    }}
                    className="reassessment-requests-due-date"
                    minDate={values.dueDate ?? tonightAtMidnight}
                    minTime={values.dueDate ?? tonightAtMidnight}
                    selected={values.resubmissionDueDate}
                    onChange={(date) => setFieldValue("resubmissionDueDate", date)}
                    onCalendarOpen={() => {
                      handleDatePickerOpen(
                        values.resubmissionDueDate ?? values.dueDate,
                        "resubmissionDueDate",
                        setFieldValue
                      );
                    }}
                    showTimeInput
                    dateFormat={dateFormat}
                  />
                </Input>
                <VerticalGap height="0.5em" />

                <Input
                  className="date-parent"
                  onClick={() => releaseDateRef.current?.setOpen(true)}
                >
                  <Label
                    className={createClassName({
                      name: "blue",
                      else: "",
                      apply: values.releaseDate !== null && values.releaseDate !== undefined,
                    })}
                  >
                    <Icon name="unhide" />
                    Hide Details from Students Until:
                  </Label>
                  <DatePicker
                    ref={(r) => {
                      releaseDateRef.current = r;
                    }}
                    className="release-date"
                    maxDate={values.dueDate ?? tonightAtMidnight}
                    maxTime={values.dueDate ?? tonightAtMidnight}
                    selected={values.releaseDate}
                    onChange={(date) => setFieldValue("releaseDate", date)}
                    onCalendarOpen={() => {
                      handleDatePickerOpen(values.releaseDate, "releaseDate", setFieldValue);
                    }}
                    showTimeInput
                    dateFormat={dateFormat}
                  />
                </Input>
              </HorizontalIndent>
            </div>
            <VerticalGap height="2em" />
            <Label content="Select Objective(s):" className="blank bold" />
            <div className="objective-segment-container">
              <HorizontalIndent>
                <Segment className="objective-segment">
                  {
                    // allow selection of new objectives
                    !doRatingsExist &&
                      map(hierarchicalObjectives, (parentID, parentObjective) => {
                        const { shortName } = parentObjective;

                        // Get all children for the current parentID
                        const allChildren = allObjectiveValues
                          .filter(({ parentID: thisParentID }) => thisParentID === parentID)
                          .map((objective) => ({
                            ...objective,
                            selected: values.selectedObjectives.some((o) => o.id === objective.id),
                          }));

                        // If no children, create a placeholder
                        if (allChildren.length === 0) {
                          allChildren.push({
                            id: parentID,
                            shortName,
                            longName: parentObjective.longName,
                            color: parentObjective.color,
                            selected: values.selectedObjectives.some(
                              (o) => o.id === parentObjective.id
                            ),
                          });
                        }

                        // Count selected children
                        const selectedChildrenCount = allChildren.filter(
                          (child) => child.selected
                        ).length;

                        return (
                          <div key={`hierarchical-${parentID}`}>
                            <Label content={shortName} ribbon />
                            <div className="click-counter">{`(${selectedChildrenCount}/${allChildren.length} selected)`}</div>
                            <br />
                            {allChildren.map((objective) => {
                              const isObjectiveSelected = values.selectedObjectives.some(
                                (o) => o.id === objective.id
                              );

                              return (
                                <Label
                                  as="a"
                                  onClick={() =>
                                    handleObjectiveLabelClick(
                                      objective,
                                      values.selectedObjectives,
                                      setFieldValue
                                    )
                                  }
                                  key={`child-${objective.id}`}
                                  className={createClassName("rounded-0", "pointer", {
                                    name: isObjectiveSelected ? objective.color : "grey",
                                    apply: isObjectiveSelected,
                                  })}
                                >
                                  {isObjectiveSelected ? (
                                    <LabelDetail className="objective-order-number">
                                      {values.selectedObjectives.findIndex(
                                        (o) => o.shortName === objective.shortName
                                      ) + 1}
                                    </LabelDetail>
                                  ) : (
                                    <></>
                                  )}
                                  {objective.shortName}
                                </Label>
                              );
                            })}
                          </div>
                        );
                      })
                  }
                  {doRatingsExist && (
                    <>
                      <br />
                      <i className="ratings-exist">
                        Ratings exist for this assignment, so the assignment's objectives cannot be
                        edited.
                      </i>
                      <br />
                    </>
                  )}
                </Segment>
              </HorizontalIndent>
            </div>
            {
              // show assignment objective details
              values.selectedObjectives.length > 0 && (
                <HorizontalIndent>
                  {values.selectedObjectives.map((objective) => (
                    <CourseObjective
                      objective={objective}
                      cardTypeLabel="Objective to be Assessed"
                      showHowToShowMastery={false}
                      enableCollapseWithArrowIcon={true}
                      collapsedByDefault={true}
                    >
                      <Header className="show-mastery-header" as="h5">
                        How to Show Mastery of {objective.shortName} in this Assignment:
                      </Header>
                      <HorizontalIndent>
                        <TextEditor
                          container=""
                          defaultValue={objective.howToShowMastery}
                          onChange={(value) =>
                            handleHowToShowMasteryForObjectiveChange(
                              objective,
                              value,
                              values.selectedObjectives,
                              setFieldValue
                            )
                          }
                          placeholder={"Enter details of how to show mastery here..."}
                          smallHeight
                        />
                      </HorizontalIndent>
                    </CourseObjective>
                  ))}
                </HorizontalIndent>
              )
            }
            <VerticalGap height="1em" />
            <Label content="Select Assignment Type(s):" className="blank bold" />
            <AssignmentTypeSelectorForm
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              setValues={setValues}
              handleAssignmentTypeLabelClick={handleAssignmentTypeLabelClick}
              values={values}
              courseID={courseID}
            />
            <VerticalGap height="2em" />
            <Label content="Request Post-Assignment Survey:" className="blank bold" />
            <br />
            <Checkbox
              toggle
              defaultChecked={values.requestAssignmentSurvey}
              onChange={() => {
                setFieldValue("requestAssignmentSurvey", !values.requestAssignmentSurvey);
              }}
              name="requestAssignmentSurveyCheckbox"
              label="Yes, students should have the option to fill out a post-assignment survey regarding difficulty,
                effectiveness, time taken to complete, etc."
            />
            <VerticalGap height="1em" />
            {objectSize(errors) > 0 && (
              <Message negative list={objectMap(errors, (key, value) => value as string)} />
            )}
            <Button
              content="Cancel"
              type="button"
              color="red"
              onClick={() => modalStore.closeModal()}
              icon="x"
            />
            <Button
              disabled={
                (!isFormDirty(initValues, values) && !assignment.isDraft) ||
                (errors && objectSize(errors) !== 0)
              }
              content={!assignment.isDraft ? "Update Assignment" : "Create Assignment"}
              type="submit"
              color="blue"
              floated="right"
              icon="plus"
            />
            {assignment.isDraft && (
              <Button
                disabled={!isFormDirty(initValues, values)}
                content="Save as Draft"
                type="button"
                onClick={() => handleFormSubmit(values, true)}
                color="green"
                floated="right"
                icon="file outline
                "
              />
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default observer(CreateOrEditAssignment);
