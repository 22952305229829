import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Container, Header } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../../../hooks/useValidParameters";
import TEACHFRONT_PATHS from "../../../../../../paths";
import { useStore } from "../../../../../../stores/store";
import CourseRoles from "../../../../../../utilities/routing/components/routeAuthorizationExtensions/CourseRoles";
import { isValidID } from "../../../../../../utilities/utils";
import GradeCalculationOverview from "./GradeCalculationOverview";
import GradeCalculationSelectionForm from "./GradeCalculationSelectionForm";
import GradeCalculationsTable from "./GradeCalculationsTable";
import RatedAssignmentsTable from "./RatedAssignmentsTable";
import StudentLearningLog from "./StudentLearningLog";
import "./ViewGradeCalculationsForCourse.css";

const header = <Header as="h2">Course Grade Calculations</Header>;
const ViewGradeCalculationsForCourseSkeleton: React.FC = () => <div>{header}</div>;

const ViewGradeCalculationsForCourse: React.FC = () => {
  const { courseID } = useValidParams<{ courseID: string }>();
  const { userID: userIDFromParams } = useParams<{ userID: string }>();
  const { courseStore, userStore } = useStore();
  const { user } = userStore;
  const { currentCourseRoster, loadCurrentCourseRoster, hasLoadedRoster } = courseStore;
  const { isUserTeachingTeam, isUserStudent } = useCurrentUserCourseRole(courseID);

  useEffect(() => {
    if (isUserTeachingTeam) loadCurrentCourseRoster(courseID);
  });

  if (isUserTeachingTeam && (!currentCourseRoster || !hasLoadedRoster(courseID)))
    return <ViewGradeCalculationsForCourseSkeleton />;
  if (
    isUserTeachingTeam &&
    currentCourseRoster?.every(
      ({ courseRole }) => !CourseRoles.StudentRoles.includes(courseRole ?? "")
    )
  )
    return (
      <>
        {header}
        <p>There are no students enrolled in the course.</p>
      </>
    );

  let studentID;

  if (isUserTeachingTeam && currentCourseRoster) {
    if (userIDFromParams) {
      if (!currentCourseRoster.some(({ userID }) => userID === userIDFromParams)) {
        // the course does not contain the user
        return <Navigate to={TEACHFRONT_PATHS.NotFound} />;
      }
    }

    studentID =
      userIDFromParams ??
      currentCourseRoster.find(({ courseRole }) =>
        CourseRoles.StudentRoles.includes(courseRole ?? "")
      )?.userID;
  } else if (isUserStudent) {
    studentID = user?.userID;
  }

  if (!studentID || !isValidID(studentID)) return <ViewGradeCalculationsForCourseSkeleton />;

  return (
    <div className="GradeCalculationsForCourse">
      {header}
      {isUserTeachingTeam && <GradeCalculationSelectionForm studentID={studentID} />}

      <StudentLearningLog courseID={courseID} userID={studentID}></StudentLearningLog>
      <GradeCalculationOverview studentID={studentID} />

      <Header as="h3">How Is My Grade Calculated?:</Header>
      <GradeCalculationsTable studentID={studentID} />

      <Container className="assignment-table">
        <Header as="h3">Ratings Overview By Assignment:</Header>
        <RatedAssignmentsTable studentID={studentID} />
      </Container>
    </div>
  );
};

export default observer(ViewGradeCalculationsForCourse);
