import { Icon } from "semantic-ui-react";
import { Field } from "formik";
import FlexContainer from "../../../../../_common/style/FlexContainer";
import "./ObjectiveGradeConstraintRow.css";
import GradeRecipeConstraintQuantifier from "../../../../../../models/GradeRecipeConstraintQuantifier";
import { MasteryLevelScheme } from "../../../../../../models/MasteryLevelScheme";
import GradeRecipeConstraintExtension from "../../../../../../models/GradeRecipeConstraintExtension";
import GradeRecipeConstraintQuantityUnit from "../../../../../../models/GradeRecipeConstraintQuantityUnit";
import Tooltip from "../../../../../_common/misc/Tooltip";

interface ObjectiveGradeConstraintRowProps {
  constraintID: string;
  masteryLevelScheme: MasteryLevelScheme;
  onDelete: () => void;
}

const ObjectiveGradeConstraintRow: React.FC<ObjectiveGradeConstraintRowProps> = ({
  constraintID,
  masteryLevelScheme,
  onDelete,
}) => {
  const masteryLevels = [...masteryLevelScheme.masteryLevels].sort(
    (a, b) => a.relativeOrderIndex - b.relativeOrderIndex
  );

  return (
    <div className="ObjectiveGradeConstraintRow">
      <FlexContainer flexDirection="row" gap="0.5rem" flexWrap="wrap" alignItems="center">
        <span>Students must earn</span>
        <Field name={`${constraintID}-quantifier`} as="select">
          <option value="unselected" />
          <option value={GradeRecipeConstraintQuantifier.AT_LEAST}>
            {GradeRecipeConstraintQuantifier.AT_LEAST}
          </option>
          <option value={GradeRecipeConstraintQuantifier.AT_MOST}>
            {GradeRecipeConstraintQuantifier.AT_MOST}
          </option>
          <option value={GradeRecipeConstraintQuantifier.EXACTLY}>
            {GradeRecipeConstraintQuantifier.EXACTLY}
          </option>
        </Field>
        <Tooltip tooltip="The value that will fill this blank will be different for each grade distinction. You can set those values on the Grade Recipes page." />
        <Field name={`${constraintID}-quantityUnit`} as="select">
          <option value="unselected" />
          <option value={GradeRecipeConstraintQuantityUnit.PERCENT}>
            {GradeRecipeConstraintQuantityUnit.PERCENT}
          </option>
          <option value={GradeRecipeConstraintQuantityUnit.RAW_NUMBER}>
            {GradeRecipeConstraintQuantityUnit.RAW_NUMBER}
          </option>
        </Field>
        <span>ratings that are</span>
        <Field name={`${constraintID}-masteryLevelID`} as="select">
          <option value="unselected" />
          {masteryLevels.map((masteryLevel) => (
            <option value={masteryLevel.id} key={masteryLevel.id}>
              {masteryLevel.name}
            </option>
          ))}
        </Field>
        <Field name={`${constraintID}-extension`} as="select">
          <option value="unselected" />
          <option value={GradeRecipeConstraintExtension.OR_BETTER}>
            {GradeRecipeConstraintExtension.OR_BETTER}
          </option>
          <option value={GradeRecipeConstraintExtension.OR_BELOW}>
            {GradeRecipeConstraintExtension.OR_BELOW}
          </option>
          <option value={GradeRecipeConstraintExtension.FULL_STOP}>
            {GradeRecipeConstraintExtension.FULL_STOP}
          </option>
        </Field>
        <div className="delete-icon" role="button" onClick={onDelete}>
          <Icon name="trash" fitted />
        </div>
      </FlexContainer>
    </div>
  );
};

export default ObjectiveGradeConstraintRow;
