import React, { useRef, useState } from "react";
import { Button, Form, Header, Input, Message, Modal } from "semantic-ui-react";
import "./InputDialog.css";

interface InputDialogProps {
  prompt: JSX.Element;
  defaultValue?: string;
  setCursorAtStart?: boolean;
  validateInput?: (input: string) => true | string;
  confirmButtonText?: string;
  onConfirm?: (inputValue: string) => void;
  cancelButtonText?: string;
  onCancel?: () => void;
}

const InputDialog: React.FC<InputDialogProps> = ({
  prompt,
  defaultValue = "",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validateInput = (input: string) => true,
  confirmButtonText = "Confirm",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onConfirm = (inputValue: string) => {},
  cancelButtonText = "Cancel",
  onCancel = () => {},
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
  };

  const handleConfirmClick = () => {
    const validationResult = validateInput(inputValue);
    if (validationResult === true) {
      onConfirm(inputValue);
    } else {
      setErrorMessage(validationResult);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleConfirmClick();
    }
  };

  return (
    <div className="InputDialog">
      <Modal.Content>
        <Form error>
          <Form.Field>
            <Header as="h4">{prompt}</Header>
            <Input
              autofocus
              placeholder="Type here..."
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              inputRef={inputRef}
            />
          </Form.Field>
          {errorMessage && <Message error content={errorMessage} />}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button className="default-button" onClick={onCancel}>
          {cancelButtonText}
        </Button>
        <Button className="default-button" positive onClick={handleConfirmClick}>
          {confirmButtonText}
        </Button>
      </Modal.Actions>
    </div>
  );
};

export default InputDialog;
