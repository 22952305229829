import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * This hook allows the user to access query parameters in the route.
 * @returns a URLSearchParams object representing search parameters in the current route.
 */
export default function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
