import { matchPath } from "react-router-dom";
import { uniqueId } from "lodash";
import { toJS } from "mobx";
import React from "react";
import { Assignment } from "../models/Assignment";
import { CalendarEntry, CalendarEntryContentType } from "../models/CalendarEntry";
import { CalendarEntryType } from "../models/CalendarEntryType";
import { MasteryLevelScheme } from "../models/MasteryLevelScheme";
import { Objective } from "../models/Objective";
import { User } from "../models/User";
import CourseRoles from "./routing/components/routeAuthorizationExtensions/CourseRoles";
import { objectForEach } from "./collectionUtils";
import TEACHFRONT_PATHS from "../paths";
import {
  ASSIGNMENT_DUE_DATE_CALENDAR_ENTRY_TYPE_ID,
  ASSIGNMENT_RELEASE_DATE_CALENDAR_ENTRY_TYPE_ID,
  ASSIGNMENT_RESUBMISSION_DUE_DATE_CALENDAR_ENTRY_TYPE_ID,
} from "./globalVariables";

const guidRegex =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const urlRegex =
  /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+([a-z]{2,}))(?::\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/;

const fileRegex = /^[a-zA-Z0-9 _-]+\.[a-zA-Z0-9_-]{2,10}$/;

const validSpecialCharacters = "\"#$^+=!*.':;<>~?^_`{}|[\\],()@%&\\-";
export const invalidPasswordMessage = `Password must contain 8 characters: 1 lowercase, 1 uppercase, 1 number, and 1 special character. Special characters include any of: ${validSpecialCharacters}`;
const passwordRegex = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[${validSpecialCharacters}]).{8,}$`;

export function isValidEmail(email: string | undefined) {
  return email !== undefined && email.match(emailRegex);
}

export function isValidID(id: string | undefined) {
  return id !== undefined && id.match(guidRegex);
}

export function isValidPassword(password: string | undefined) {
  return password !== undefined && password.match(passwordRegex);
}

export function isValidURL(url: string | undefined) {
  return url !== undefined && url.match(urlRegex);
}

export function isValidIntegerAllowLeadingZeroes(s: string) {
  return /^[0-9]+$|^0[0-9]+$/.test(s);
}

export function getFileExtension(fileName: string) {
  const thisFileNamePieces = fileName.split(".");
  return thisFileNamePieces[thisFileNamePieces.length - 1];
}

export function isValidFileName(input: string, requiredExtension?: string) {
  const suggestedFileNameIsValid = fileRegex.test(input);

  if (
    // not a valid file name
    !suggestedFileNameIsValid ||
    // file extension doesn't match
    !(requiredExtension && requiredExtension === getFileExtension(input))
  ) {
    return false;
  }

  return true;
}

export function capitalizeWords(input: string | undefined): string {
  if (!input) {
    return "";
  }
  return input
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

// Pre-compiled regular expressions for efficiency
const wordRegex = /\w+\+?-?/;
const anWordRegex = /^(euler|heir|honest|hono)/;
const hourRegex = /^hour(?!i)/;
const singleLetterAnRegex = /^[aedhilmnorsx]$/;
const complexAnRegex =
  /(?!FJO|[HLMNS]Y.|RY[EO]|SQU|(F[LR]?|[HL]|MN?|N|RH?|S[CHKLMNPTVW]?|X(YL)?)[AEIOU])[FHLMNRSX][A-Z]/;
const aRegex = [/^e[uw]/, /^onc?e\b/, /^uni([^nmd]|mo)/, /^u[bcfhjkqrst][aeiou]/, /^U[NK][AIEO]/];
const uppercaseAnRegex = /^[aehilmnorsx]$/;
const vowelRegex = /^[aeiou]/;
const yRegex = /^y(b[lor]|cl[ea]|fere|gg|p[ios]|rou|tt)/;
const numberStartsWithAnSound = /^(8|11|18)/;
const numberStartsWithASound = /^([12345679]|10)/;

/**
 * Determines the appropriate indefinite article ("a" or "an") for a given noun phrase.
 * Algorithm adapted from CPAN package Lingua-EN-Inflect-1.891 by Damian Conway.
 * @param nounPhrase The noun phrase to analyze.
 * @returns The appropriate indefinite article ("a" or "an") as a string.
 */
export function indefiniteArticle(nounPhrase: string | undefined): string {
  if (!nounPhrase) return "";

  const wordMatch = nounPhrase.match(wordRegex);
  if (!wordMatch || !wordMatch[0]) {
    return ""; // Default to empty string if no word is found
  }
  const rawWord = wordMatch[0];
  const firstWord = rawWord.toLowerCase();

  // Check for numerical values and apply specific rules
  if (!Number.isNaN(parseFloat(rawWord))) {
    // Check if 'word' is numeric
    if (numberStartsWithAnSound.test(rawWord)) {
      return "an";
    }
    if (numberStartsWithASound.test(rawWord)) {
      return "a";
    }
  }

  // Check for specific starting words that require 'an'
  if (
    anWordRegex.test(firstWord) ||
    (hourRegex.test(firstWord) && !firstWord.startsWith("houri")) ||
    singleLetterAnRegex.test(rawWord) ||
    complexAnRegex.test(rawWord)
  ) {
    return "an";
  }

  // Words starting with specific patterns that should take 'a'
  if (aRegex.some((regex) => regex.test(firstWord))) {
    return "a";
  }

  // Acronyms or all uppercase words handling
  if (firstWord[0] && rawWord === rawWord.toUpperCase()) {
    if (uppercaseAnRegex.test(firstWord[0])) {
      return "an";
    }
    return "a";
  }

  // General case based on the first letter
  if (firstWord[0] && vowelRegex.test(firstWord[0])) {
    return "an";
  }

  // Special 'y' starting words handling
  if (yRegex.test(firstWord)) {
    return "an";
  }
  return "a";
}

/**
 * Creates a className for a component based off the selectors.
 * @param selectors each selector can be a string or an object representing a "possible" parameter. The selector will only be added if the boolean apply is true.
 * @returns a string className.
 */
export function createClassName(
  ...selectors: (string | undefined | { name: string; apply: boolean; else?: string })[]
): string {
  const selectorArray: string[] = [];

  selectors.forEach((selector) => {
    if (!selector) return;
    // eslint-disable-next-line prettier/prettier
    if (typeof selector === "string") selectorArray.push(selector);
    else if (selector.apply) selectorArray.push(selector.name);
    else if (selector.else) selectorArray.push(selector.else);
  });

  return selectorArray.join(" ");
}

// Tailwind style classname function used for concise conditional class naming in components.
export function cn(
  ...selectors:
    | (
        | string
        | undefined
        | {
            [selector: string]: boolean | undefined | null;
          }
      )[]
) {
  const selectorArray: string[] = [];

  selectors.forEach((selector) => {
    if (!selector) return;
    // eslint-disable-next-line prettier/prettier
    if (typeof selector === "string") selectorArray.push(selector);
    else {
      objectForEach(selector, (className: unknown, apply: boolean | undefined | null) => {
        if (apply) {
          selectorArray.push(className as string);
        }
      });
    }
  });

  return selectorArray.join(" ");
}

export function getColorForRating(
  ratedObjectiveColor: string,
  masteryLevelScheme: MasteryLevelScheme | undefined,
  masteryLevelID: string | undefined
) {
  const masteryLevels = masteryLevelScheme?.masteryLevels;
  const levelIndex = masteryLevels?.findIndex((ml) => ml.id === masteryLevelID);
  if (levelIndex !== undefined) return ratedObjectiveColor;
  return "grey-1";
}

/**
 * Based on a ReactQuill input, tests if it actually contains content.
 * Adapted from https://stackoverflow.com/a/70417483
 *
 * @param value the value to be tested.
 * @returns true if the quill is empty, false otherwise.
 */
export function isQuillEmpty(value: string | undefined) {
  if (!value) return true;
  return value.replace(/<(.|\n)*?>/g, "").trim().length === 0 && !value.includes("<img");
}

export function concatenateUserName(
  firstName: string,
  lastName: string,
  honorific: string | undefined,
  suffix: string | undefined
): string {
  let name = honorific ? `${honorific} ` : "";
  name += `${firstName} ${lastName}`;
  name += suffix ? ` ${suffix}` : "";
  return name;
}

export function getFullUserName(user: User): string {
  const { firstName, lastName, honorific, suffix } = user;
  let name = honorific ? `${honorific} ` : "";
  name += `${firstName} ${lastName}`;
  name += suffix ? ` ${suffix}` : "";
  return name;
}

export function removeKeysFromObject(ob: object, ...keys: string[]) {
  const copy = { ...ob };
  keys.forEach((key) => {
    delete (copy as { [key: string]: unknown })[key];
  });

  return copy;
}

export function extractTextContent(element: JSX.Element): string {
  const childrenArray = React.Children.toArray(toJS(element.props.children));
  return childrenArray.reduce<string>((text, child) => {
    if (typeof child === "string") {
      return `${text} ${child}`;
    }
    if (React.isValidElement(child)) {
      return text + extractTextContent(child);
    }
    return text;
  }, "");
}

export function toSemanticWidth(
  value: number
): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 {
  if (value >= 1 && value <= 16) {
    return value as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
  }
  // Default value if out of range
  return 1;
}

export function doesRosterHaveStudents(roster: User[]) {
  return roster.some(({ courseRole }) => CourseRoles.StudentRoles.includes(courseRole ?? ""));
}

export function isPollingAssignment(assignment: Assignment<Objective>) {
  return assignment.allowedSubmissionFormat?.includes("Polling");
}

export function mapAssignmentsToCalendarEntries(
  assignments: Assignment<Objective>[],
  calendarEntryTypes: CalendarEntryType[]
): CalendarEntry[] {
  const calendarEntries: CalendarEntry[] = [];

  const createAssignmentCalendarEntry = (
    assignment: Assignment<Objective>,
    date: Date,
    entryTypeID: string,
    calendarEntryContentType: CalendarEntryContentType
  ) => {
    const calendarEntryID = uniqueId();
    const { courseID, id: assignmentID, name } = assignment;

    calendarEntries.push({
      id: calendarEntryID,
      calendarEntryTypes: calendarEntryTypes.filter(({ id }) => id === entryTypeID),
      courseID,
      links: [
        {
          calendarEntryID,
          isLocalRoute: true,
          courseID,
          link: `/courses/${courseID}/assignments/${assignmentID}`,
          calendarLinkTypeID: uniqueId(),
          calendarLinkType: {
            courseID,
            icon: "paper plane outline",
            id: uniqueId(),
            name: "View Assignment",
          },
        },
      ],
      startTime: date,
      endTime: date,
      isCanceled: false,
      isRequired: false,
      description: "",
      location: "",
      title: name ?? "",
      calendarEntryContentType,
      assignment,
    });
  };

  assignments.forEach((assignment) => {
    if (assignment.releaseDate) {
      createAssignmentCalendarEntry(
        assignment,
        assignment.releaseDate,
        ASSIGNMENT_RELEASE_DATE_CALENDAR_ENTRY_TYPE_ID,
        CalendarEntryContentType.ASSIGNMENT_RELEASE_DATE
      );
    }

    if (assignment.dueDate) {
      createAssignmentCalendarEntry(
        assignment,
        assignment.dueDate,
        ASSIGNMENT_DUE_DATE_CALENDAR_ENTRY_TYPE_ID,
        CalendarEntryContentType.ASSIGNMENT_DUE_DATE
      );
    }

    if (assignment.resubmissionDueDate) {
      createAssignmentCalendarEntry(
        assignment,
        assignment.resubmissionDueDate,
        ASSIGNMENT_RESUBMISSION_DUE_DATE_CALENDAR_ENTRY_TYPE_ID,
        CalendarEntryContentType.ASSIGNMENT_RESUBMISSION_DUE_DATE
      );
    }
  });

  return calendarEntries;
}

export function doesRouteMatchTeachFrontPath<TPage extends keyof typeof TEACHFRONT_PATHS>(
  path: TPage
) {
  return !!matchPath(
    {
      path: TEACHFRONT_PATHS[path],
    },
    window.location.pathname
  );
}
