import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { StoreContext, store } from "./stores/store";
import "./semantic-ui/Colors.css";
import { router } from "./utilities/routing/Routes";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const body = document.querySelector("body");

if (body) body.id = "TeachFront";

root.render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <RouterProvider router={router} />
    </StoreContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
