import { Ref, forwardRef, useState } from "react";
import { Header, Icon, Menu, Step } from "semantic-ui-react";
import "./MasteryGradingSection.css";
import { MasteryGradingStep, MasteryGradingSteps } from "./MasteryGradingSteps";

const MasteryGradingSection = forwardRef<HTMLDivElement>((props, ref: Ref<HTMLDivElement>) => {
  const [activeStep, setActiveStep] = useState<MasteryGradingStep>(
    () => MasteryGradingSteps[0] ?? ({} as MasteryGradingStep)
  );

  return (
    <div id="MasteryGradingSection" className="MasteryGradingSection" ref={ref}>
      <div className="inner-mastery-grading-section">
        <Header as="h2">How Does Mastery Grading Work?</Header>
        <Menu icon="labeled" secondary pointing stackable size="small" widths={6} fluid>
          {MasteryGradingSteps.map((step) => (
            <Menu.Item
              className={`step-${step.stepNumber}`}
              key={step.stepNumber}
              active={activeStep.stepNumber === step.stepNumber}
              onClick={() => setActiveStep(step)}
            >
              <Icon name={step.icon} size="big" />
              <Step.Content>
                <Step.Title>Step {step.stepNumber}:</Step.Title>
                <Step.Description>{step.title}</Step.Description>
              </Step.Content>
            </Menu.Item>
          ))}
        </Menu>
        <Header as="h3" className="mastery-step-title">
          Step {activeStep.stepNumber}: {activeStep.title}
        </Header>
        <img className="mastery-step-image" src={activeStep.img} alt={activeStep.imgAlt} />
        <div className="mastery-step-description">{activeStep.description}</div>
        <div className="mastery-step-icon">
          <Icon name={activeStep.icon} size="huge" />
        </div>
      </div>
    </div>
  );
});

export default MasteryGradingSection;
