import { useParams } from "react-router-dom";
import NotFound404 from "../../../../components/notFound/NotFound404";
// import NotFound404 from "../../../../pages/NotFound404";
import { objectSome } from "../../../collectionUtils";
import { isValidID } from "../../../utils";

interface RequireValidIDParameterProps {
  parameters: string[];
  alternateParameterValues?: Record<string, string[]>;
  children: JSX.Element;
}

/**
 * Validates parameters of a Route based on the following inputs.
 * @param parameters a string array containing each key for each expected parameter. It is possible, and normal, that all the parameters will not match those specified in the parameters array, this is due to children components requiring them.
 * @param alternateParameterValues a Record of accepted alternates to parameter IDs.
 */
const RequireValidIDParameter: React.FC<RequireValidIDParameterProps> = ({
  children,
  parameters,
  alternateParameterValues,
}) => {
  const params = useParams();

  // Checks if any IDs in the required parameters are invalid
  const anyInvalidIDs = objectSome(params, (key, value: string | undefined) => {
    const keyToString = key.toString();

    // We only want to check the expected keys, as there could be children using the other values.
    if (!parameters.includes(keyToString)) return false;

    if (isValidID(value)) return false;

    if (alternateParameterValues && alternateParameterValues[keyToString]) {
      return value === undefined || !alternateParameterValues[keyToString]?.includes(value);
    }

    return true;
  });

  return anyInvalidIDs ? <NotFound404 /> : children;
};

export default RequireValidIDParameter;
