import { Assignment } from "../models/Assignment";
import { GradeDistinction } from "../models/GradeDistinction";
import { GradeRecipeConstraint } from "../models/GradeRecipeConstraint";
import { GradeRecipeIngredient } from "../models/GradeRecipeIngredient";
import { MasteryLevel } from "../models/MasteryLevel";
import { MasteryLevelScheme } from "../models/MasteryLevelScheme";
import { Objective } from "../models/Objective";
import { RatedObjective } from "../models/RatedObjective";
import { Rating } from "../models/Rating";
import { indefiniteArticle } from "./utils";

export type AssignmentRatingsMappedToMasteryLevel = {
  // masteryLevelID -> objectiveID
  // The length of each objectiveID array represents how many ratings that mastery level has.
  ratings: {
    [masteryLevelID: string]: string[];
  };
  unassessed: boolean;
  // The total number of ratings across all mastery levels.
  numTotalRatings: number;
};

/**
 * Holds data essential to showing grade calculations.
 */
export type RecoupledAssignmentRatings = {
  // objectiveID -> Assignment with RatedObjectives[]
  parentObjectiveToAssignmentMap: Map<string, Assignment<RatedObjective>[]>;
  // objectiveID -> Objective
  // Used to hold all the masteryLevelSchemes associated with a course/all assignments
  parentObjectiveMap: Map<string, Objective>;
  // objectiveID -> (assignmentID -> AssignmentRatingsMappedToMasteryLevelScheme)
  // Used to hold ratings for every assignment based on masteryLevels
  assignmentToMasteryLevelSchemeRatings: Map<
    string,
    Map<string, AssignmentRatingsMappedToMasteryLevel>
  >;
  assignmentToLatestSubmissionID: Map<string, string | undefined>;
};

/**
 * Recursively finds the parent objective.
 * @param currentObjective the objective to find the parent for.
 * @param allObjectives all objectives in the course.
 * @returns the top-level parent objective. If allObjectives doesn't contain a parent objective ID, the current
 *          objective will be returned.
 */
function getParentObjective(
  currentObjective: Objective,
  allObjectives: Map<string, Objective>
): Objective {
  const objectiveWithParent = allObjectives.get(currentObjective.id);

  if (!objectiveWithParent) return currentObjective;

  const { parentID } = objectiveWithParent;

  if (parentID && allObjectives.has(parentID)) {
    return getParentObjective(allObjectives.get(parentID) as Objective, allObjectives);
  }

  return currentObjective;
}

/**
 * Maps assignment ratings for a user only under the specified masteryLevelScheme to be
 * accessed easily.
 * @param userID the user to find ratings for.
 * @param masteryLevelScheme the masteryLevelScheme to filter ratings by.
 * @param assignment the assignment to search through.
 * @returns an object that represents ratings, if the assignment has been assessed, and the total number of ratings.
 */
function getParentObjectiveRatingsForAssignment(
  userID: string,
  parentObjective: Objective,
  assignment: Assignment<RatedObjective>,
  allObjectives: Map<string, Objective>
): AssignmentRatingsMappedToMasteryLevel {
  const returnObject: {
    [masteryLevelID: string]: string[];
  } = {};
  let unassessed = true;
  let numTotalRatings = 0;
  const { masteryLevelScheme } = parentObjective;

  if (!masteryLevelScheme)
    return {
      ratings: returnObject,
      unassessed,
      numTotalRatings,
    };

  // Populate the return object with empty arrays for each masteryLevelID
  masteryLevelScheme.masteryLevels.forEach((masteryLevelID) => {
    returnObject[masteryLevelID.id] = [];
  });

  assignment.objectives
    // Ensure each objective for this assignment used in the forEach actually matches the current masteryLevelScheme
    ?.filter((ro) => ro.masteryLevelScheme?.id === masteryLevelScheme.id)
    // Ensure the parent objective for this objective matches the parent objective passed into the method
    .filter((ro) => getParentObjective(ro, allObjectives).id === parentObjective.id)
    .forEach((ratedObjective) => {
      const { ratingsForStudents, id } = ratedObjective;

      // Ensure there are ratings for the current student
      if (ratingsForStudents && ratingsForStudents[userID]) {
        const ratings = ratingsForStudents[userID];

        // If there are no ratings, return
        if (!ratings?.length) return;

        // Given the presence of the ratings, this assignment is assessed.
        unassessed = false;

        // The length of the array should always be 1, but grab the last element anyways.
        const rating = ratings[ratings.length - 1];

        // The array in the return object mapped to the current masteryLevelID
        const masteryLevelArray = returnObject[rating?.masteryLevelID as string];

        // This array should always be defined, but check anyways.
        if (masteryLevelArray) {
          returnObject[rating?.masteryLevelID as string] = [...masteryLevelArray, id];
          numTotalRatings += 1;
        }
      }
    });

  return {
    ratings: returnObject,
    unassessed,
    numTotalRatings,
  };
}

export type RatingToAssignment = {
  rating: Rating;
  assignment: Assignment<RatedObjective>;
};

export function getBestRatingsForObjectiveAndMasteryLevels(
  objectiveID: string,
  masteryLevelIDs: string[],
  studentID: string,
  assignments: Assignment<RatedObjective>[] | undefined,
  allObjectives: Map<string, Objective>
) {
  const foundRatings: Map<string, RatingToAssignment[]> = new Map();

  // we want to search each assignment
  assignments?.forEach((assignment) => {
    // search each objective in the assignment that is associated with our objective ID
    // and has ratings for our studentID
    assignment.objectives?.forEach((objective) => {
      if (
        (objective.id === objectiveID ||
          allObjectives?.get(objective.id)?.parentID === objectiveID) &&
        objective.ratingsForStudents &&
        studentID in objective.ratingsForStudents
      ) {
        // get the ratings for the students we want
        const studentRatings = objective.ratingsForStudents[studentID];

        // find the best rating in the list
        const bestRating = studentRatings?.reduce((bestSoFar: Rating, thisRating: Rating) =>
          objective.masteryLevelScheme &&
          // is the mastery level scheme of this rating
          objective.masteryLevelScheme?.masteryLevels.findIndex(
            (ml) => ml.id === thisRating.masteryLevelID
          ) <
            // better than the best mastery level found so far?
            objective.masteryLevelScheme?.masteryLevels.findIndex(
              (ml) => ml.id === bestSoFar.masteryLevelID
            ) // if so, return this rating, otherwise the return best so far
            ? thisRating
            : bestSoFar
        );

        if (bestRating && masteryLevelIDs.includes(bestRating.masteryLevelID || "")) {
          const existingList = foundRatings.get(bestRating.masteryLevelID) || [];
          existingList.push({ rating: bestRating, assignment });
          foundRatings.set(bestRating.masteryLevelID, existingList);
        }
      }
    });
  });

  return foundRatings;
}

/**
 * Finds rating data for many assignments.
 * @param map a map of objectiveID -> AssignmentRatingsMappedToMasteryLevel
 * @returns an object holding the following data:
 *   ratings: a dictionary type that holds masteryLevelID -> number of ratings for this mastery level in each assignment
 *   numTotalRatings: the total number of ratings for every assignment combined
 */
export function mapNumTotalRatings(
  parentObjectiveToAssignmentRatingsMap:
    | Map<string, AssignmentRatingsMappedToMasteryLevel>
    | undefined,
  masteryLevelScheme: MasteryLevelScheme | undefined
) {
  const returnObject: {
    ratings: { [masteryLevelID: string]: number }; // the ratings for ALL assignments under each masteryLevelID
    numTotalRatings: number; // the total number of ratings for ALL assignments
    numTotalUnexcusedRatings: number; // the total number of ratings for ALL assignments that are not excused
  } = {
    ratings: {},
    numTotalRatings: 0,
    numTotalUnexcusedRatings: 0,
  };

  if (!parentObjectiveToAssignmentRatingsMap) return returnObject;

  // Loop through each parentObjectiveID in the original map to do logic
  Array.from(parentObjectiveToAssignmentRatingsMap.keys()).forEach((parentObjectiveID) => {
    // Gets the ratings map for this masteryLevelScheme
    const { ratings, unassessed, numTotalRatings } = parentObjectiveToAssignmentRatingsMap.get(
      parentObjectiveID
    ) as AssignmentRatingsMappedToMasteryLevel;

    // If it is unassessed, we won't have any ratings to compute, so continue to the next iteration.
    if (unassessed) return;

    returnObject.numTotalRatings += numTotalRatings;

    // For each rating (masteryLevelID -> ObjectiveID[]) add the length of that ObjectiveID[] array to the existing
    // total, or make a new total if the masteryLevelID key doesn't yet exist.
    Object.keys(ratings).forEach((masteryLevelID) => {
      const masteryLevel = masteryLevelScheme?.masteryLevels.find(
        (ml: MasteryLevel) => ml.id === masteryLevelID
      );
      // should this rating be considered in grade calculations?
      if (!masteryLevel?.excludeInGradeCalculations) {
        returnObject.numTotalUnexcusedRatings += ratings[masteryLevelID]?.length ?? 0;
      }
      const currentValue = returnObject.ratings[masteryLevelID] || 0; // Default currentValue to 0 if there isn't a current value
      returnObject.ratings[masteryLevelID] = currentValue + (ratings[masteryLevelID]?.length || 0); // Increment by the number of ratings at this masteryLevel or 0 if it doesn't exist
    });
  });

  return returnObject;
}

/**
 * Convenience method to create two maps from an array of assignments and all objectives for a course.
 * @param assignments the assignments to make the maps with.
 * @param allObjectives the objectives to make the maps with.
 * @returns two maps. The parentObjectiveMap maps parentObjectiveID -> parentObjective (this is the same as hierarchical objectives)
 * The parentObjectiveToAssignmentMap maps parentObjectiveID -> a list of assignments with RatedObjective.
 */
export function mapParentObjectivesToAssignments(
  assignments: Assignment<RatedObjective>[],
  allObjectives: Map<string, Objective>
) {
  const parentObjectiveMap = new Map<string, Objective>();
  const parentObjectiveToAssignmentMap = new Map<string, Assignment<RatedObjective>[]>();

  assignments.forEach((assignment) => {
    // Track the objectiveIDs to ensure uniqueness
    const parentObjectiveIDs: string[] = [];

    assignment.objectives?.forEach((ratedObjective) => {
      // Copy object because the original value is observed by MobX
      const parentObjective = { ...getParentObjective(ratedObjective, allObjectives) };

      // The api doesn't give us the objectives with their masteryLevelSchemes, so assign it here
      parentObjective.masteryLevelScheme = ratedObjective.masteryLevelScheme;

      const { id } = parentObjective;

      // To ensure uniqueness, check if the array contains the value before adding it
      if (!parentObjectiveIDs.includes(id)) parentObjectiveIDs.push(id);

      if (!parentObjectiveMap.has(id)) parentObjectiveMap.set(id, { ...parentObjective });
    });
    // For every parent objective, add the assignment to the array of assignments associated with that scheme.
    parentObjectiveIDs.forEach((schemeID) => {
      const getOrDefault = parentObjectiveToAssignmentMap.get(schemeID) || [];
      parentObjectiveToAssignmentMap.set(schemeID, [...getOrDefault, assignment]);
    });
  });

  return {
    parentObjectiveMap,
    parentObjectiveToAssignmentMap,
  };
}

/**
 * Recouples Assignment data to be easily used to find ratings and other information.
 * @param userID the user to find ratings for.
 * @param assignments the list of assignments to recouple from.
 * @returns an object holding three maps.
 */
export function recoupleRatedAssignments(
  userID: string,
  assignments: Assignment<RatedObjective>[],
  allObjectives: Map<string, Objective>
): RecoupledAssignmentRatings {
  // find the latest submission id for each assignment
  const assignmentToLatestSubmissionID = new Map<string, string | undefined>();

  // for all the assignments
  assignments.forEach((assignment) => {
    // keep track of the last rating so far
    let lastRatingForAssignment: Rating | undefined;
    // look through each objective
    assignment.objectives?.forEach((objective) => {
      // find the rating that was created last
      const thisStudentsRatings = objective.ratingsForStudents
        ? objective.ratingsForStudents[userID]
        : [];

      const latestRating =
        thisStudentsRatings && thisStudentsRatings.length > 0
          ? thisStudentsRatings.reduce((latestRatingFoundSoFar: Rating, thisRating: Rating) =>
              latestRatingFoundSoFar.createdAt > thisRating.createdAt
                ? latestRatingFoundSoFar
                : thisRating
            )
          : undefined;

      // store this objective's latest rating if it's later than the latest found for all objectives
      lastRatingForAssignment =
        latestRating &&
        lastRatingForAssignment &&
        latestRating.createdAt < lastRatingForAssignment.createdAt
          ? lastRatingForAssignment
          : latestRating;
    });

    // save submission id of latest rating
    assignmentToLatestSubmissionID.set(assignment.id, lastRatingForAssignment?.submissionID);
  });

  const assignmentToMasteryLevelSchemeRatings = new Map<
    string,
    Map<string, AssignmentRatingsMappedToMasteryLevel>
  >();

  const { parentObjectiveMap, parentObjectiveToAssignmentMap } = mapParentObjectivesToAssignments(
    assignments,
    allObjectives
  );

  // With each assignment per parentObjective, loop through them to form the assignmentToMasteryLevelSchemeRatings map
  // This loop will essentially accomplish mapping masteryLevelSchemeID -> (assignmentID -> ratings for only that masteryLevelScheme)
  Array.from(parentObjectiveToAssignmentMap.keys()).forEach((parentObjectiveID) => {
    const currentAssignments = parentObjectiveToAssignmentMap.get(
      parentObjectiveID
    ) as Assignment<RatedObjective>[];
    const parentObjective = parentObjectiveMap.get(parentObjectiveID) as Objective;

    // Loop through each assignment to add to the ratings
    currentAssignments?.forEach((assignment) => {
      const masteryLevelSchemeRatingsForAssignment = getParentObjectiveRatingsForAssignment(
        userID,
        parentObjective,
        assignment,
        allObjectives
      );
      const currentAssignmentMap = assignmentToMasteryLevelSchemeRatings.get(parentObjectiveID);

      // Basically compute() logic to set make a new map or add to an existing one
      if (currentAssignmentMap) {
        currentAssignmentMap.set(assignment.id, masteryLevelSchemeRatingsForAssignment);
      } else {
        const newMap = new Map<string, AssignmentRatingsMappedToMasteryLevel>();

        newMap.set(assignment.id, masteryLevelSchemeRatingsForAssignment);

        assignmentToMasteryLevelSchemeRatings.set(parentObjectiveID, newMap);
      }
    });
  });

  return {
    parentObjectiveMap,
    parentObjectiveToAssignmentMap,
    assignmentToMasteryLevelSchemeRatings,
    assignmentToLatestSubmissionID,
  };
}

/**
 * Determines whether a given student's work satisfies the threshold given in the ingredient.
 * @param userID the user to find ratings for.
 * @param assignments the constraint that needs to be met
 * @param ingredient the ingredient that sets the threshold for the constraint.
 * @param masteryLevelID the mastery level to be counted
 * @param objective the objective for which the ratings were given
 * @returns an object containing: (calculatedQuantity: number) the student's quantity that was
 * compared with the threshold and (fulfilled: boolean) whether the student's quantity fulfills the threshold.
 */
export function doesStudentWorkFulfillIngredient(
  assignmentRatingsMappedToMasteryLevel:
    | Map<string, Map<string, AssignmentRatingsMappedToMasteryLevel>>
    | undefined,
  constraint: GradeRecipeConstraint,
  ingredient: GradeRecipeIngredient,
  masteryLevelID: string,
  objective: Objective
) {
  // if we don't have the data, just return.
  if (!assignmentRatingsMappedToMasteryLevel)
    return {
      calculatedQuantity: 0,
      rawQuantity: 0,
      fulfilled: false,
      numTotalUnexcusedRatings: -1,
    };

  const { ratings, numTotalUnexcusedRatings } = mapNumTotalRatings(
    assignmentRatingsMappedToMasteryLevel.get(objective.id),
    objective.masteryLevelScheme
  );

  const masteryLevels = objective.masteryLevelScheme?.masteryLevels;
  const thisMasteryLevel = masteryLevels?.find((ml) => ml.id === masteryLevelID);

  // something might be wrong, if so, return
  if (!masteryLevels || !thisMasteryLevel)
    return {
      calculatedQuantity: -1,
      rawQuantity: -1,
      fulfilled: false,
      numTotalUnexcusedRatings: -1,
    };

  let rawQuantity = 0;

  // if the constraint has extension "full stop", just get the quantity
  if (constraint.extension === "[full stop]") {
    rawQuantity = ratings[masteryLevelID] ?? 0;
  } // if the constraint has extension "or better", sum the quantities for that ml and all better
  else if (constraint.extension === "or better") {
    const betterMasteryLevels = masteryLevels?.filter(
      (ml) => ml.relativeOrderIndex <= thisMasteryLevel?.relativeOrderIndex
    );
    rawQuantity = betterMasteryLevels.reduce(
      (previousSum, ml) => previousSum + (ratings[ml.id] ?? 0),
      0
    );
  } // if the constraint has extension "or below", sum the quantities for that ml and all below
  else if (constraint.extension === "or below") {
    const belowMasteryLevels = masteryLevels?.filter(
      (ml) => ml.relativeOrderIndex >= thisMasteryLevel?.relativeOrderIndex
    );
    rawQuantity = belowMasteryLevels.reduce(
      (previousSum, ml) => previousSum + (ratings[ml.id] ?? 0),
      0
    );
  }

  let calculatedQuantity = rawQuantity;

  // if the constraint uses percentages, modify studentQuantity into a percentage
  if (constraint.quantityUnit === "%" && calculatedQuantity !== 0) {
    calculatedQuantity = Math.round((rawQuantity / numTotalUnexcusedRatings) * 100);
  }

  // if the constraint has quantifier "at least"
  if (constraint.quantifier === "at least" && ingredient.quantity !== undefined) {
    return {
      calculatedQuantity,
      rawQuantity,
      fulfilled: calculatedQuantity >= ingredient.quantity,
      numTotalUnexcusedRatings,
    };
  }
  // // if the constraint has quantifier "at most"
  if (constraint.quantifier === "at most" && ingredient.quantity !== undefined) {
    return {
      calculatedQuantity,
      rawQuantity,
      fulfilled: calculatedQuantity <= ingredient.quantity,
      numTotalUnexcusedRatings,
    };
  }
  // // if the constraint has quantifier "exactly"
  if (constraint.quantifier === "exactly" && ingredient.quantity !== undefined) {
    return {
      calculatedQuantity,
      rawQuantity,
      fulfilled: calculatedQuantity === ingredient.quantity,
      numTotalUnexcusedRatings,
    };
  }

  // execution should never reach this point
  return { calculatedQuantity, rawQuantity, fulfilled: false, numTotalUnexcusedRatings };
}

export interface ConstraintStringOptions {
  gradeRecipeConstraint: GradeRecipeConstraint;
  defaultGradeDistinction?: GradeDistinction;
  gradeRecipeIngredient?: GradeRecipeIngredient | null;
  gradeDistinction?: GradeDistinction | null;
  objective?: Objective | null;
  fulfilled?: boolean;
  calculatedQuantity?: number;
  rawQuantity?: number;
  numTotalRatings?: number;
  frontMatter?: JSX.Element;
}

// returns a string describing the constraint
export function constraintString(options: ConstraintStringOptions) {
  const {
    gradeRecipeConstraint,
    defaultGradeDistinction,
    gradeRecipeIngredient = null,
    gradeDistinction = null,
    objective = null,
    fulfilled = false,
    calculatedQuantity = -1,
    rawQuantity = -1,
    numTotalRatings = -1,
    frontMatter = <></>,
  } = options;

  // if this is the default grade distinction column (no ingredient to describe)
  if (
    gradeDistinction === defaultGradeDistinction &&
    !gradeRecipeIngredient &&
    calculatedQuantity < 1
  ) {
    return `To earn "${defaultGradeDistinction?.name}" students must not meet the threshold for any other grade distinction.`;
  }
  // if this is an actual ingredient, describe it, and add details about the student's performance if provided
  return (
    <>
      <p>
        {frontMatter}
        {gradeRecipeIngredient &&
          `To earn ${indefiniteArticle(gradeRecipeIngredient?.gradeDistinction?.name)} "${
            gradeRecipeIngredient?.gradeDistinction?.name
          }", `}
        Students must earn {gradeRecipeConstraint.quantifier}{" "}
        {gradeRecipeIngredient ? gradeRecipeIngredient.quantity : "_____"}
        {gradeRecipeConstraint.quantityUnit === "%" ? "% " : " "}
        {gradeRecipeConstraint.masteryLevel?.name}
        {" ratings"}
        {gradeRecipeConstraint.extension === "[full stop]"
          ? ""
          : ` ${gradeRecipeConstraint.extension}`}
        {"."}
      </p>
      {gradeRecipeIngredient && (
        <p>
          {gradeRecipeIngredient &&
            numTotalRatings > 0 &&
            ` You earned ${calculatedQuantity}${
              gradeRecipeConstraint.quantityUnit === "%" ? "% " : " "
            } ${
              gradeRecipeConstraint.masteryLevel?.name
            } ratings (${rawQuantity} of the ${numTotalRatings} ratings for ${
              objective?.shortName
            }), so your work ${fulfilled ? "meets" : "does not meet"} this threshold.`}
          {numTotalRatings === 0 &&
            ` You don't have any ratings for this objective yet, so we're ignoring this threshold for now.`}
        </p>
      )}
    </>
  );
}

// class names used to impact the rendering of ingredients that students have earned
// or ingredients that are in the column of their grade distinction
export const unknownIfStudentEarnedClassName = "unknown-if-student-earned";
export const studentEarnedClassName = "student-earned";
export const studentGradeDistinctionClassName = "student-grade-distinction";

// returns the class that should be given to a cell based on student performance, if any
export function gradeCalculationsTableCellClass(
  fulfilled: boolean,
  numTotalRatings: number,
  gradeDistinction: GradeDistinction | undefined,
  objective: Objective,
  gradeDistinctionForStudent: GradeDistinction | undefined = undefined,
  defaultGradeDistinction: GradeDistinction | undefined = undefined,
  constraint: GradeRecipeConstraint | undefined = undefined,
  lastIngredientThatStudentFulfilled: GradeRecipeIngredient | undefined = undefined
) {
  const classes: { [key: string]: boolean } = {};
  // if the student doesn't have any ratings in this category
  if (numTotalRatings === 0) {
    classes[unknownIfStudentEarnedClassName] = true;
  }

  // if the student fulfilled the ingredient and this is the first ingredient fulfilled
  if (numTotalRatings > 0 && fulfilled) {
    classes[studentEarnedClassName] = true;
  }
  if (gradeDistinction?.id === gradeDistinctionForStudent?.id) {
    classes[studentGradeDistinctionClassName] = true;
  }
  if (
    numTotalRatings > 0 &&
    constraint &&
    gradeDistinction?.id === defaultGradeDistinction?.id &&
    lastIngredientThatStudentFulfilled
  ) {
    classes[studentEarnedClassName] = true;
  }

  // otherwise, return either that this is in the column of their grade distinction or do nothing
  return Object.keys(classes).join(" ");
}
