// eslint-disable-next-line import/no-cycle
import api, { ErrorHandlerPackage } from "../api/api";
import PredefinedAssessmentComment from "../models/PredefinedAssessmentComment";
import { insertObjectInArrayOfObjects } from "../utilities/collectionUtils";
import { cleanCommentText } from "../utilities/formUtils";
import { emptyID } from "../utilities/submissionUtils";
import { StoreValue } from "./storeValue";

class PredefinedAssessmentCommentStore {
  private commentsRegistry = new StoreValue<
    PredefinedAssessmentComment[],
    { courseID: string; assignmentID: string }
  >();

  private uniqueCommentsRegistry = new StoreValue<
    PredefinedAssessmentComment[],
    { courseID: string; assignmentID: string }
  >();

  reset = () => {};

  get comments() {
    return this.commentsRegistry.value;
  }

  loadComments = async (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    if (this.commentsRegistry.fresh(true, { courseID, assignmentID })) return;

    this.commentsRegistry.setLoading(true, { courseID, assignmentID });
    this.commentsRegistry.setValue([]);

    const result = await api.PredefinedAssessmentComments.list(
      courseID,
      assignmentID,
      undefined,
      undefined,
      errorHandlerPackage
    );

    this.updateComments(result);

    this.commentsRegistry.setLoading(false);
  };

  loadUniqueComments = async (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    if (this.uniqueCommentsRegistry.fresh(true, { courseID, assignmentID })) return;

    this.uniqueCommentsRegistry.setLoading(true, { courseID, assignmentID });

    const result = await api.PredefinedAssessmentComments.getUniqueComments(
      courseID,
      assignmentID,
      undefined,
      undefined,
      undefined,
      errorHandlerPackage
    );

    this.updateComments(result);

    this.uniqueCommentsRegistry.setLoading(false);
  };

  createOrUpdate = async (
    courseID: string,
    comment: PredefinedAssessmentComment,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.PredefinedAssessmentComments.createOrUpdate(
      courseID,
      comment,
      errorHandlerPackage
    );

    this.updateComments(result);

    return result;
  };

  // creates a new pac and returns the new pac's id unless the comment text in the form values is weird/empty
  handleCreatePAC = async (
    commentText: string,
    courseID: string,
    assignmentID: string,
    objectiveID?: string,
    pacToEdit?: PredefinedAssessmentComment
  ) => {
    const cleanedCommentText = cleanCommentText(commentText);

    const pacsForObjective = this.comments?.filter((comment) =>
      objectiveID ? comment.objectiveID === objectiveID : !comment.objectiveID
    );
    if (cleanedCommentText) {
      const newPAC: PredefinedAssessmentComment = {
        id: pacToEdit ? pacToEdit.id : emptyID,
        assignmentID,
        objectiveID: objectiveID || undefined,
        courseID,
        commentText: cleanedCommentText,
        relativeOrderIndex: pacToEdit
          ? pacToEdit.relativeOrderIndex
          : pacsForObjective?.length || 0,
      };

      const newPACs = await this.createOrUpdate(courseID, newPAC);

      return pacToEdit ? pacToEdit.id : newPACs[newPAC.relativeOrderIndex]?.id;
    }

    return undefined;
  };

  deletePAC = async (
    courseID: string,
    comment: PredefinedAssessmentComment,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.PredefinedAssessmentComments.delete(
      courseID,
      comment,
      errorHandlerPackage
    );

    if (result) {
      this.deleteComment(comment);
    }
  };

  getNumberOfAssessmentComments = async (
    commentID: string,
    courseID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.PredefinedAssessmentComments.getNumberOfAssessmentComments(
      commentID,
      courseID,
      errorHandlerPackage
    );

    return result;
  };

  recordSomeCommentsCreatedOrUpdated = (comments: PredefinedAssessmentComment[]) => {
    this.updateComments(comments);
  };

  recordAllCommentsCreatedOrUpdated = (comments: PredefinedAssessmentComment[]) => {
    if (comments && comments.length > 0) {
      const { courseID, assignmentID } = comments[0] as PredefinedAssessmentComment;

      if (this.commentsRegistry.fresh(false, { courseID, assignmentID })) {
        this.commentsRegistry.setAll(comments, { courseID, assignmentID });
      }

      if (this.uniqueCommentsRegistry.fresh(false, { courseID, assignmentID })) {
        this.uniqueCommentsRegistry.setAll(comments, { courseID, assignmentID });
      }
    }
  };

  recordCommentDeleted = (comment: PredefinedAssessmentComment) => {
    this.deleteComment(comment);
  };

  private updateComments = (comments: PredefinedAssessmentComment[] | undefined) => {
    if (!comments || comments.length === 0) return;

    const { courseID, assignmentID } = comments[0] as PredefinedAssessmentComment;

    const updateCommentForRegistry = (
      registry: StoreValue<
        PredefinedAssessmentComment[],
        { courseID: string; assignmentID: string }
      >
    ) => {
      const newComments = registry.value ?? [];

      comments.forEach((c) => insertObjectInArrayOfObjects(newComments, c, "id"));

      registry.setAll(newComments, { courseID, assignmentID });
    };

    updateCommentForRegistry(this.commentsRegistry);
    updateCommentForRegistry(this.uniqueCommentsRegistry);
  };

  private deleteComment = (comment: PredefinedAssessmentComment) => {
    const { courseID, assignmentID } = comment;

    const deleteCommentForRegistry = (
      registry: StoreValue<
        PredefinedAssessmentComment[],
        { courseID: string; assignmentID: string }
      >
    ) => {
      // If the registry passed in isn't loaded with this comment's assignment or there isn't a value already, return.
      if (!registry.value || !registry.fresh(false, { courseID, assignmentID })) return;

      let newComments = registry.value ?? [];

      newComments = newComments.filter((c) => c.id !== comment.id);

      registry.setAll(newComments, { courseID, assignmentID });
    };

    deleteCommentForRegistry(this.commentsRegistry);
    deleteCommentForRegistry(this.uniqueCommentsRegistry);
  };
}

export default PredefinedAssessmentCommentStore;
