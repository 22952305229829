// eslint-disable-next-line import/no-cycle
import api, { ApiCallOptions, ErrorHandlerPackage } from "../api/api";
import { MasteryLevelScheme } from "../models/MasteryLevelScheme";
import { StoreValue } from "./storeValue";

export default class MasteryLevelSchemeStore {
  private masteryLevelSchemesForCourseRegistry = new StoreValue<MasteryLevelScheme[]>();

  loadMasteryLevelSchemesForCourse = async (courseID: string, apiCallOptions?: ApiCallOptions) => {
    if (!apiCallOptions?.overrideIfFresh && this.masteryLevelSchemesForCourseRegistry.fresh(true))
      return;

    this.masteryLevelSchemesForCourseRegistry.setLoading(true);

    const schemes = await api.MasteryLevelSchemes.listForCourse(courseID);

    this.masteryLevelSchemesForCourseRegistry.setValue(schemes);

    this.masteryLevelSchemesForCourseRegistry.setLoading(false);
  };

  createOrUpdate = async (
    masteryLevelScheme: MasteryLevelScheme,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.MasteryLevelSchemes.createOrUpdate(
      masteryLevelScheme,
      errorHandlerPackage
    );

    if (result) {
      this.masteryLevelSchemesForCourseRegistry.setValue(result);
    }
  };

  delete = async (
    courseID: string,
    masteryLevelSchemeID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const successful = await api.MasteryLevelSchemes.delete(
      courseID,
      masteryLevelSchemeID,
      errorHandlerPackage
    );

    if (successful) {
      const newMasteryLevelSchemes = [
        ...(this.masteryLevelSchemesForCourseRegistry.value ?? []),
      ].filter(({ id }) => id !== masteryLevelSchemeID);

      this.masteryLevelSchemesForCourseRegistry.setValue(newMasteryLevelSchemes);
    }
  };

  hasLoadedMasteryLevelSchemesForCourse = () =>
    this.masteryLevelSchemesForCourseRegistry.fresh(false);

  get masteryLevelSchemesForCourse() {
    return this.masteryLevelSchemesForCourseRegistry.value;
  }

  reset = () => {
    this.masteryLevelSchemesForCourseRegistry.reset();
  };
}
