import { runInAction } from "mobx";
import { LinkPreviewMetadata } from "../models/LinkPreviewMetadata";
// eslint-disable-next-line import/no-cycle
import { isValidURL } from "../utilities/utils";
import { StoreValue } from "./storeValue";

export default class LinkPreviewStore {
  PROXY_SERVER_NAME = "https://proxy.teachfront.com";

  private linkPreviewRegistry = new StoreValue<
    Map<string, LinkPreviewMetadata>,
    Record<string, never>
  >();

  private defaultLinkPreview = {
    url: "",
    title: "No Preview Available",
    description:
      "No preview is available for this link. Either the site doesn't publish metadata or the URL is invalid.",
    image: `${this.PROXY_SERVER_NAME}/unknown-polka-dots.png`,
    siteName: "No Preview",
    hostname: "Unknown Host",
    loading: false,
  } as LinkPreviewMetadata;

  get linkPreviewData() {
    return this.linkPreviewRegistry.value;
  }

  getLinkPreviewMetadata = (link: string) => {
    if (!this.linkPreviewRegistry.value) {
      this.linkPreviewRegistry.setValue(new Map<string, LinkPreviewMetadata>());
    }

    if (link.length < 6 || !isValidURL(link)) {
      return { ...this.defaultLinkPreview } as LinkPreviewMetadata;
    }
    return this.linkPreviewRegistry.value?.get(link);
  };

  loadLinkPreviewMetadata = async (link: string) => {
    if (!this.linkPreviewRegistry.value) {
      this.linkPreviewRegistry.setValue(new Map<string, LinkPreviewMetadata>());
    }

    // if the details already exist, return them
    const preview = this.linkPreviewRegistry.value?.get(link);
    if (preview) return;

    // if not, set temporary link details so there's something while we wait
    const temporaryLinkPreview = { ...this.defaultLinkPreview };
    temporaryLinkPreview.loading = true;
    this.linkPreviewRegistry.value?.set(link, temporaryLinkPreview);

    // set the repository as loading (really only necessary to trigger that the value changed)
    this.linkPreviewRegistry.setLoading(true);

    // get the details from the proxy server
    const response = await fetch(`${this.PROXY_SERVER_NAME}/?url=${link}`);
    const json = await response.json();
    json.loading = false;

    // store them in the registry
    runInAction(() => {
      this.linkPreviewRegistry.value?.set(link, json);

      // set the repository as not loading (really only necessary to trigger that the value changed)
      this.linkPreviewRegistry.setLoading(false);
    });
  };

  hasLoadedLinkPreviewMetadata = (link: string) =>
    this.linkPreviewRegistry.value &&
    this.linkPreviewRegistry.value?.get(link) &&
    !this.linkPreviewRegistry.value?.get(link)?.loading;

  reset = () => {
    this.linkPreviewRegistry.reset();
  };
}
