import { PropsWithChildren } from "react";
import RoundedSegment from "../components/_common/style/RoundedSegment";
import useValidParams from "../hooks/useValidParameters";
import RosterView from "../components/courses/[courseID]/roster/RosterView";

const CourseHomeRosterView: React.FC<PropsWithChildren> = ({ children }) => {
  const { courseID } = useValidParams<{
    courseID: string;
  }>();

  return (
    <RoundedSegment>
      <RosterView courseID={courseID} children={children} />
    </RoundedSegment>
  );
};

export default CourseHomeRosterView;
