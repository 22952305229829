import { useParams } from "react-router-dom";

export type RouteParameter =
  | "courseID"
  | "userID"
  | "assignmentID"
  | "taskType"
  | "submissionID"
  | "pageID";

/**
 * Checks if the current route contains a parameter name.
 * @param routeParameter the parameter name to check for.
 * @returns true if the route contains the parameter, false otherwise.
 */
export function useCheckForRouteParameters(routeParameter: RouteParameter) {
  const parameters = useParams();

  return Object.hasOwn(parameters, routeParameter);
}
