import { CSSProperties } from "react";
import { Icon, Popup } from "semantic-ui-react";
import "./Tooltip.css";

interface TooltipProps {
  tooltip: string;
  style?: CSSProperties;
}

const Tooltip: React.FC<TooltipProps> = ({ tooltip, style }) => (
  <Popup
    content={tooltip}
    position={"left center"}
    trigger={
      <div className={"Tooltip"} style={style}>
        <Icon name={"question circle outline"} fitted size={"large"} />
      </div>
    }
  />
);

export default Tooltip;
