import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Container,
  Header,
  Label,
  Message,
  Radio,
  Segment,
} from "semantic-ui-react";
import { Assignment, AssignmentSubmissionFormat } from "../../../../models/Assignment";
import { MasteryLevel } from "../../../../models/MasteryLevel";
import { Objective } from "../../../../models/Objective";
import { useStore } from "../../../../stores/store";
import { map, objectMap, objectSize } from "../../../../utilities/collectionUtils";
import { formatDateDownToDayNoWeekday, getTime } from "../../../../utilities/dateTimeUtils";
import { FormikSetValuesFunction, isFormDirty } from "../../../../utilities/formUtils";
import LoadingComponent from "../../../../utilities/routing/components/LoadingComponent";
import { emptyID } from "../../../../utilities/submissionUtils";
import { createClassName } from "../../../../utilities/utils";
import FormLogoInput from "../../../_common/form/FormLogoInput";
import FlexContainer from "../../../_common/style/FlexContainer";
import VerticalGap from "../../../_common/style/spacing/VerticalGap";
import "./CreateOrEditPollingAssignment.css";
import MasteryLevelSchemeInputs from "./MasteryLevelSchemeInputs";
import ObjectivePopUp from "./[assignmentID]/ObjectivePopUp";
import { PollingAssignmentAssessmentRule } from "../../../../models/PollingAssignmentAssessmentRule";
import FormDateInput from "../../../_common/form/FormDateInput";

export type PollingRuleMasteryPercentages = { [masteryLevelID: string]: string };

type PollingAssignmentDueDateOptions = {
  creationOption: "1" | "4" | "12" | undefined;
  dueDate: Date | undefined;
  noDueDate: boolean;
};

export interface CreateOrEditPollingAssignmentFormValues {
  name: string;
  dueDateOptions: PollingAssignmentDueDateOptions;
  selectedObjective: Objective | undefined;
  assessmentMethod: "manual" | "auto" | "noAssessment";
  masteryPercentages: PollingRuleMasteryPercentages;
  popupHasBeenShown: boolean;
}

interface CreateOrEditPollingAssignmentProps {
  courseID: string;
  pollingAssignment?: Assignment<Objective>;
}

const CreateOrEditPollingAssignment: React.FC<CreateOrEditPollingAssignmentProps> = ({
  courseID,
  pollingAssignment,
}) => {
  const navigate = useNavigate();
  const { toastStore, userStore, objectiveStore, modalStore, assignmentStore } = useStore();
  const { user } = userStore;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    loadPollingAssignmentAssessmentRulesForAssignment,
    pollingAssignmentAssessmentRulesForAssignment: pollAssessmentRules,
    hasLoadedPollingAssignmentAssessmentRulesForAssignment,
  } = assignmentStore;
  const {
    allObjectives,
    hierarchicalObjectives,
    loadAllObjectives,
    loadObjectivesHierarchical,
    hasLoadedAllObjectives,
    hasLoadedHierarchicalObjectives,
  } = objectiveStore;

  useEffect(() => {
    loadAllObjectives(courseID);
    loadObjectivesHierarchical(courseID);
  }, [courseID]);

  useEffect(() => {
    if (pollingAssignment) {
      loadPollingAssignmentAssessmentRulesForAssignment(courseID, pollingAssignment.id);
    }
  }, [courseID, pollingAssignment]);

  const hasLoadedPollingAssessmentRules =
    !pollingAssignment ||
    (pollAssessmentRules &&
      hasLoadedPollingAssignmentAssessmentRulesForAssignment(pollingAssignment.id));

  if (
    !user ||
    !allObjectives ||
    !hierarchicalObjectives ||
    !hasLoadedAllObjectives() ||
    !hasLoadedHierarchicalObjectives() ||
    !hasLoadedPollingAssessmentRules
  )
    return <LoadingComponent content="Loading Assignment..." />;

  const getDefaultMasteryPercentages = (masteryLevels: MasteryLevel[], objectiveID: string) => {
    const percentages: PollingRuleMasteryPercentages = {};

    masteryLevels.sort((a, b) => a.relativeOrderIndex - b.relativeOrderIndex);
    masteryLevels.forEach((masteryLevel, index) => {
      percentages[masteryLevel.id] =
        percentages[index]?.toString() ??
        pollAssessmentRules
          ?.find((par) => par.masteryLevelID === masteryLevel.id && par.objectiveID === objectiveID)
          ?.percentComplete.toString() ??
        "0";
    });

    return percentages;
  };

  const selectObjective = (
    objective: Objective,
    values: CreateOrEditPollingAssignmentFormValues,
    setValues: FormikSetValuesFunction<CreateOrEditPollingAssignmentFormValues>
  ) => {
    if (objective.id === values.selectedObjective?.id) {
      setValues({
        ...values,
        selectedObjective: undefined,
        assessmentMethod: "noAssessment",
        masteryPercentages: {},
      });
      return;
    }
    const { assessmentMethod } = values;
    const newValues = {
      ...values,
      selectedObjective: objective,
      popupHasBeenShown: true,
      assessmentMethod: assessmentMethod === "noAssessment" ? "auto" : assessmentMethod, // Default to auto when an objective is selected
    };

    if (values.assessmentMethod === "auto" && objective.masteryLevelScheme) {
      const { masteryLevels } = objective.masteryLevelScheme;
      const percentages = getDefaultMasteryPercentages(
        masteryLevels.filter((ml) => !ml.excludeInGradeCalculations),
        objective.id
      );
      newValues.masteryPercentages = percentages;
    }

    setValues(newValues, true);
  };

  const handleObjectiveLabelClick = (
    values: CreateOrEditPollingAssignmentFormValues,
    clickedObjective: Objective,
    setValues: FormikSetValuesFunction<CreateOrEditPollingAssignmentFormValues>
  ) => {
    const { popupHasBeenShown } = values;

    const previousObjective = values.selectedObjective;
    if (!previousObjective || popupHasBeenShown || clickedObjective.id === previousObjective.id) {
      selectObjective(clickedObjective, values, setValues);
    } else {
      modalStore.openModal(
        (index) => (
          <ObjectivePopUp
            handleSelectNewObjective={(objective: Objective) => {
              selectObjective(objective, values, setValues);
              modalStore.closeModal(index);
            }}
            previousObjective={previousObjective}
            newObjective={clickedObjective}
            handleSelectPreviousObjective={() => {
              modalStore.closeModal(index);
            }}
          />
        ),
        { isChildModal: true }
      );
    }
  };

  const defaultMasteryPercentages: PollingRuleMasteryPercentages = {};

  if (pollAssessmentRules) {
    pollAssessmentRules.forEach((rule) => {
      defaultMasteryPercentages[rule.masteryLevelID] = rule.percentComplete.toString();
    });
  }

  const initialValues: CreateOrEditPollingAssignmentFormValues = {
    name: pollingAssignment?.name ?? "",
    dueDateOptions: {
      creationOption: pollingAssignment ? undefined : "4", // Used when creating polling assignment
      dueDate: pollingAssignment?.dueDate, // used when editing polling assignment
      noDueDate: !!pollingAssignment && !pollingAssignment.dueDate,
    },
    selectedObjective: pollingAssignment?.objectives?.at(0),
    assessmentMethod:
      pollingAssignment && pollingAssignment.objectives?.length ? "auto" : "noAssessment", // TODO: update this later when we add manual
    masteryPercentages: defaultMasteryPercentages,
    popupHasBeenShown: false,
  };

  const calculateDueDate = (values: CreateOrEditPollingAssignmentFormValues): Date | undefined => {
    const { noDueDate, dueDate, creationOption } = values.dueDateOptions;

    if (noDueDate) return undefined;
    if (dueDate) return dueDate;

    const currentTime = new Date(getTime());
    switch (creationOption) {
      case "1":
        return new Date(currentTime.setHours(currentTime.getHours() + 1));
      case "4":
        return new Date(currentTime.setHours(currentTime.getHours() + 4));
      case "12":
        return new Date(currentTime.setHours(currentTime.getHours() + 12));
      default:
        return undefined;
    }
  };

  const handleSubmit = async (values: CreateOrEditPollingAssignmentFormValues) => {
    setIsSubmitting(true);

    const date = calculateDueDate(values);
    const assignmentID = pollingAssignment?.id ?? emptyID;

    const defaultTitle = values.name
      ? values.name
      : `Polling Activities for ${formatDateDownToDayNoWeekday(new Date())}`;

    const newAssignment: Assignment<Objective> = {
      id: assignmentID,
      name: defaultTitle,
      dueDate: date,
      courseID,
      hasUploadedFiles: false,
      isDraft: false,
      requestAssignmentSurvey: false,
      allowedSubmissionFormat: ["Polling"] as AssignmentSubmissionFormat[],
      assignmentObjectives: values.selectedObjective
        ? [
            {
              objectiveID: values.selectedObjective.id,
              courseID,
              assignmentID,
              relativeOrderIndex: 0,
            },
          ]
        : undefined,
    };

    const createdAssignment = await assignmentStore.createOrUpdateAssignment(newAssignment);
    if (!createdAssignment) {
      toastStore.showToast("The Assignment Failed to Create", { color: "red" });
      setIsSubmitting(false);
      return;
    }

    if (values.selectedObjective) {
      const { id: objectiveID } = values.selectedObjective;

      if (values.masteryPercentages) {
        const rules: PollingAssignmentAssessmentRule[] = [];

        Object.entries(values.masteryPercentages).forEach(([masteryLevelID, pc]) => {
          const percentComplete = Number(pc);
          const existingRule = pollAssessmentRules?.find(
            (r) => r.masteryLevelID === masteryLevelID && r.objectiveID === objectiveID
          );

          if (!existingRule || existingRule.percentComplete !== percentComplete) {
            rules.push({
              id: existingRule?.id ?? emptyID,
              assignmentID: createdAssignment.id,
              courseID,
              objectiveID,
              masteryLevelID,
              percentComplete,
            });
          }
        });

        await Promise.all(
          rules.map((rule) => assignmentStore.createOrUpdatePollingAssignmentAssessmentRule(rule))
        );
      }
    }

    assignmentStore.reset();
    modalStore.closeModal();
    setIsSubmitting(false);
    navigate(`/courses/${courseID}/assignments/${createdAssignment.id}`);
  };

  const validate = (values: CreateOrEditPollingAssignmentFormValues) => {
    const errors: { [key: string]: string } = {};

    if (values.assessmentMethod === "auto") {
      if (!values.masteryPercentages || Object.keys(values.masteryPercentages).length === 0) {
        errors.masteryPercentages = "You must provide mastery percentages for auto assessment";
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(values.masteryPercentages).forEach(([masteryLevel, percentage]) => {
          const percentAsNumber = Number(percentage);
          if (!percentAsNumber && percentAsNumber !== 0) {
            errors.masteryPercentages = "Mastery Level percentages are required";
          } else if (percentAsNumber < 0 || percentAsNumber > 100) {
            errors.masteryPercentages =
              "Percentage for Mastery Levels must be a number between 1 and 100";
          } else if (values.selectedObjective && values.selectedObjective.masteryLevelScheme) {
            const scheme = values.selectedObjective.masteryLevelScheme;
            let previousNumber = -1;

            scheme.masteryLevels
              .filter((ml) => !ml.excludeInGradeCalculations)
              .filter((ml) => ml.relativeOrderIndex < scheme.masteryLevels.length - 2) // consider excused mastery levels
              .sort((a, b) => a.relativeOrderIndex - b.relativeOrderIndex)
              .forEach((ml) => {
                const percentageForMasteryLevel = Number(values.masteryPercentages[ml.id] ?? "0");
                if (previousNumber !== -1 && percentageForMasteryLevel >= previousNumber) {
                  errors.masteryPercentages =
                    "Mastery level percentage values must be descending from the highest mastery level";
                } else {
                  previousNumber = percentageForMasteryLevel;
                }
              });
          }
        });
      }
    }

    return errors;
  };

  const createOrUpdateLabel = pollingAssignment ? "Update" : "Create";

  return (
    <Container>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
        {({ values, setFieldValue, errors, touched, setValues }) => (
          <Form className="CreateOrEditPollingAssignment">
            <Header as="h2" content={`${createOrUpdateLabel} Polling Session`} />

            {/* Name Input */}
            <FormLogoInput
              iconName="pencil alternate"
              label="Assignment Name"
              name="name"
              placeholder="Enter assignment name"
              required={false}
              type="text"
              error={touched.name && errors.name}
              maxWidth="100%"
            />

            {/* Due Date Options */}
            {pollingAssignment ? (
              <>
                <Header as="h4" content="Set Session End Date" />
                <Checkbox
                  toggle
                  label="This polling session should have no end date"
                  checked={values.dueDateOptions.noDueDate}
                  onClick={() =>
                    setFieldValue("dueDateOptions", {
                      ...values.dueDateOptions,
                      noDueDate: !values.dueDateOptions.noDueDate,
                      creationOption: undefined,
                    })
                  }
                />
                <VerticalGap height="1rem" />
                {!values.dueDateOptions.noDueDate && (
                  <div className="date-pickers">
                    <FormDateInput
                      onDateChange={(date) =>
                        setFieldValue("dueDateOptions", {
                          ...values.dueDateOptions,
                          dueDate: date,
                        })
                      }
                      label="End Date"
                      currentDate={values.dueDateOptions.dueDate}
                      icon="calendar alternate outline"
                      datePickerProps={{
                        showTimeInput: true,
                        shouldCloseOnSelect: true,
                        dateFormat: "MM/dd/yyyy hh:mm",
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <Header as="h4" content="End Session After" />
                <FlexContainer flexDirection="column" gap=".5rem">
                  <Field
                    as={Radio}
                    label="After 1 hour"
                    name="dueDateOption"
                    value="1"
                    type="radio"
                    checked={values.dueDateOptions.creationOption === "1"}
                    onChange={() =>
                      setFieldValue("dueDateOptions", {
                        ...values.dueDateOptions,
                        creationOption: "1",
                        noDueDate: false,
                      })
                    }
                  />
                  <Field
                    as={Radio}
                    label="After 4 hours"
                    name="dueDateOption"
                    value="4"
                    type="radio"
                    checked={values.dueDateOptions.creationOption === "4"}
                    onChange={() =>
                      setFieldValue("dueDateOptions", {
                        ...values.dueDateOptions,
                        creationOption: "4",
                        noDueDate: false,
                      })
                    }
                  />
                  <Field
                    as={Radio}
                    label="After 12 hours"
                    name="dueDateOption"
                    value="12"
                    type="radio"
                    checked={values.dueDateOptions.creationOption === "12"}
                    onChange={() =>
                      setFieldValue("dueDateOptions", {
                        ...values.dueDateOptions,
                        creationOption: "12",
                        noDueDate: false,
                      })
                    }
                  />
                  <Field
                    as={Radio}
                    label="No due date"
                    name="dueDateOption"
                    value="never"
                    type="radio"
                    checked={values.dueDateOptions.noDueDate}
                    onChange={() =>
                      setFieldValue("dueDateOptions", {
                        ...values.dueDateOptions,
                        creationOption: undefined,
                        noDueDate: true,
                      })
                    }
                  />
                </FlexContainer>
              </>
            )}

            <VerticalGap height="1em" />

            {/* Objective Selector */}

            <Header
              className="objective-selector-header"
              as="h4"
              content="Select a Single Objective:"
            />
            <div className="objective-segment-container">
              <Segment className="objective-segment">
                {map(hierarchicalObjectives, (_, parentObjective) => parentObjective).map(
                  (parentObjective) => {
                    const { shortName, id: parentID, children } = parentObjective;
                    const isParentSelected =
                      !(children?.length ?? 0) &&
                      parentObjective.id === values.selectedObjective?.id;

                    return (
                      <div key={parentID}>
                        <Label content={shortName} ribbon />
                        <br />

                        {children && children.length > 0 ? (
                          children.map((childObjective) => {
                            const isSelectedChild =
                              childObjective.id === values.selectedObjective?.id;

                            return (
                              <Label
                                as="a"
                                onClick={() =>
                                  handleObjectiveLabelClick(values, childObjective, setValues)
                                }
                                key={`child-${childObjective.id}`}
                                className={createClassName("rounded-0", "pointer", {
                                  name: isSelectedChild ? childObjective.color : "grey",
                                  apply: isSelectedChild,
                                })}
                              >
                                {childObjective.shortName}
                              </Label>
                            );
                          })
                        ) : (
                          <Label
                            as="a"
                            onClick={() =>
                              handleObjectiveLabelClick(values, parentObjective, setValues)
                            }
                            key={parentID}
                            className={createClassName("rounded-0", "pointer", {
                              name: isParentSelected ? parentObjective.color : "grey",
                              apply: isParentSelected,
                            })}
                          >
                            {shortName}
                          </Label>
                        )}
                      </div>
                    );
                  }
                )}
              </Segment>
            </div>

            {/* Asessment Method Section */}
            <Header as="h4" content="Assessment Method" />
            <FlexContainer flexDirection="column" gap=".5rem">
              <Field
                as={Radio}
                label="Assess Automatically Based On Participation"
                name="assessmentMethod"
                value="auto"
                type="radio"
                checked={values.assessmentMethod === "auto"}
                onChange={() => {
                  const newValues = {
                    ...values,
                    assessmentMethod: "auto",
                  } as CreateOrEditPollingAssignmentFormValues;

                  if (values.selectedObjective && values.selectedObjective.masteryLevelScheme) {
                    const newPercentages = getDefaultMasteryPercentages(
                      values.selectedObjective.masteryLevelScheme.masteryLevels,
                      values.selectedObjective.id
                    );
                    newValues.masteryPercentages = newPercentages;
                  }

                  setValues(newValues, true);
                }}
                disabled={!values.selectedObjective}
              />
              <Field
                as={Radio}
                label="Manually Assess (coming soon)"
                name="assessmentMethod"
                value="manual"
                type="radio"
                disabled={true || !values.selectedObjective} // TODO: Fix this conditional when manual assessment is working
                onChange={() => setFieldValue("assessmentMethod", "manual", true)}
              />
              <Field
                as={Radio}
                label="Do Not Assess"
                name="assessmentMethod"
                value="noAssessment"
                type="radio"
                checked={values.assessmentMethod === "noAssessment"}
                onChange={() => setFieldValue("assessmentMethod", "noAssessment", true)}
                disabled={!!values.selectedObjective}
              />

              {values.assessmentMethod === "auto" && !values.selectedObjective && (
                <div className="click-counter">
                  Select an objective if you want TeachFront to assess this assignment
                  automatically.
                </div>
              )}

              {values.assessmentMethod === "auto" && values.selectedObjective && (
                <MasteryLevelSchemeInputs
                  selectedObjective={values.selectedObjective}
                  values={values}
                  setFieldValue={setFieldValue}
                  courseID={courseID}
                  errors={errors}
                  pollingAssessmentRules={pollAssessmentRules}
                />
              )}
            </FlexContainer>

            {objectSize(errors) > 0 && (
              <Message negative list={objectMap(errors, (key, value) => value as string)} />
            )}
            <Button
              content="Cancel"
              type="button"
              color="red"
              onClick={() => modalStore.closeModal()}
              icon="x"
            />
            <Button
              loading={isSubmitting}
              disabled={
                // Only check that the form has been touched when editing polling assignments. In the current state, polling assignments can be created without making changes to the form.
                (pollingAssignment && !isFormDirty(initialValues, values)) || objectSize(errors) > 0
              }
              type="submit"
              content={`${createOrUpdateLabel} Polling Session`}
              icon="plus"
              color="blue"
              floated="right"
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default observer(CreateOrEditPollingAssignment);
