import { action, observable, runInAction } from "mobx";
// eslint-disable-next-line import/no-cycle
import api, { ErrorHandlerPackage } from "../api/api";
import { Directory } from "../models/Directory";
import { UploadedFile } from "../models/UploadedFile";
import { UploadedFileBlobLink } from "../models/UploadedFileBlobLink";
import { emptyID } from "../utilities/submissionUtils";
import { store } from "./store";
import { StoreValue } from "./storeValue";

export default class UploadedCourseFileStore {
  private courseUploadedFilesRegistry = new StoreValue<
    Directory,
    { connectedComponentID: string }
  >();

  private mostRecentlyUploadedCourseFileIDRepository = new StoreValue<
    string,
    { connectedComponentID: string }
  >();

  hasLoadedCourseFiles = (courseID: string): boolean =>
    !this.courseUploadedFilesRegistry.isLoading() &&
    this.courseUploadedFilesRegistry.fresh(false, { connectedComponentID: courseID });

  get uploadedFilesForCourse() {
    return this.courseUploadedFilesRegistry.value;
  }

  get mostRecentlyUploadedCourseFileID() {
    return this.mostRecentlyUploadedCourseFileIDRepository.value;
  }

  reset = () => {
    this.courseUploadedFilesRegistry.reset();
  };

  downloadCourseFile = async (file: UploadedFile) => {
    const { id, connectedComponentID: courseID } = file;

    if (!id || !courseID) return;

    const result: UploadedFileBlobLink = await api.UploadedFiles.getBlobLinkForCourseFile(
      courseID,
      courseID,
      id
    );

    store.uploadedFileStore.performDownloadWithLink(file, result);
  };

  handleUploadedFileError = (message: string, indexOfFile: string, uploadedFile: UploadedFile) => {
    // show the toast
    store.toastStore.showToast(message, { color: "red" });

    if (uploadedFile.courseID) {
      this.handleDeleteCourseFile(indexOfFile, uploadedFile.courseID);
    }
  };

  uploadCourseFile = action(async (uploadedFile: UploadedFile) => {
    // if this is an image, we want to compress it
    // eslint-disable-next-line no-param-reassign
    uploadedFile.uploadedFile = await store.uploadedFileStore.compressFile(
      uploadedFile.uploadedFile
    );

    // ensure the file size is acceptable
    if (uploadedFile.uploadedFile.size <= store.uploadedFileStore.maxFileSizeInBytes) {
      // handle upload to server and blob store
      const tempID = this.addTemporaryCourseFile(uploadedFile);
      try {
        const result = await api.UploadedFiles.uploadCourseFile(uploadedFile, {
          // request too large error (hopefully we've prevented this, but just in case)
          413: () => {
            this.handleUploadedFileError(
              store.uploadedFileStore.errorMessage413(uploadedFile.originalFileName),
              tempID,
              uploadedFile
            );
          },
          // invalid file type
          415: () => {
            this.handleUploadedFileError(
              store.uploadedFileStore.errorMessage415(uploadedFile.originalFileName),
              tempID,
              uploadedFile
            );
          },
          // this file is corrupt for some reason
          400: () => {
            this.handleUploadedFileError(
              store.uploadedFileStore.errorMessage400(uploadedFile.originalFileName),
              tempID,
              uploadedFile
            );
          },
        } as ErrorHandlerPackage);

        this.addTemporaryCourseFile(result, tempID);
      } catch (error) {
        // do nothing. the errorHandler should handle the errors
      }
    } else {
      // the file is too large
      store.toastStore.showToast(
        store.uploadedFileStore.errorMessage413(uploadedFile.originalFileName),
        { color: "red" }
      );
    }
  });

  updateCourseFile = action(async (uploadedFile: UploadedFile) => {
    const updatedFile = await api.UploadedFiles.updateFile(uploadedFile);
    if (updatedFile.directoryID == null || updatedFile.id == null) {
      updatedFile.directoryID = emptyID;
    }

    if (
      !updatedFile.id ||
      !updatedFile.courseID ||
      !this.courseUploadedFilesRegistry.fresh(false, {
        connectedComponentID: updatedFile.courseID,
      }) ||
      !this.courseUploadedFilesRegistry.value // pleasing the compiler
    )
      return;

    // find the directory that currently contains this file (we may not have its previous parentID)
    const directoryThatContainsFile = this.findDirectoryThatContainsFile(
      this.courseUploadedFilesRegistry.value,
      updatedFile.id
    );

    // find the file inside the directory
    const file = directoryThatContainsFile?.uploadedFiles.find((uf) => uf.id === uploadedFile.id);

    if (!directoryThatContainsFile || !file) return;

    // update the file name
    file.originalFileName = updatedFile.originalFileName;

    this.sortUploadedFiles(directoryThatContainsFile);

    if (file.id && file.courseID) this.setMostRecentlyUploadedCourseFileID(file.id, file.courseID);

    // mobx doesn't like to notice changes to deeply nested things, so force the change to be noticed
    this.courseUploadedFilesRegistry.setValue({ ...this.courseUploadedFilesRegistry.value });
  });

  deleteCourseFile = action(async (fileID: string, courseID: string) => {
    await api.UploadedFiles.deleteCourseFile(courseID, fileID);

    this.handleDeleteCourseFile(fileID, courseID);
  });

  handleDeleteCourseFile = action(async (fileID: string, courseID: string) => {
    if (
      !this.courseUploadedFilesRegistry.fresh(false, { connectedComponentID: courseID }) ||
      !this.courseUploadedFilesRegistry.value // pleasing the compiler
    )
      return;

    // find the directory that contains this file
    const directoryThatContainsFile = this.findDirectoryThatContainsFile(
      this.courseUploadedFilesRegistry.value,
      fileID
    );

    // find the file inside the directory
    const file = directoryThatContainsFile?.uploadedFiles.find(
      (uf) => uf.id === fileID || uf.id === fileID
    );

    // if the file was found, remove it from its directory
    if (directoryThatContainsFile && file) {
      directoryThatContainsFile.uploadedFiles = [
        ...directoryThatContainsFile.uploadedFiles.filter((uf) => uf.id !== fileID),
      ];
    }

    // mobx doesn't like to notice changes to deeply nested things, so force the change to be noticed
    this.courseUploadedFilesRegistry.setValue({ ...this.courseUploadedFilesRegistry.value });
  });

  loadUploadedFilesForCourse = action(async (courseID: string) => {
    const registry = this.courseUploadedFilesRegistry;

    if (registry.fresh(true, { connectedComponentID: courseID })) return;

    registry.setLoading(true, { connectedComponentID: courseID });

    const directory = await api.Directories.getForCourse(courseID);

    // ensure that the children of the root directory have the correct parent ids
    directory.uploadedFiles.forEach((uf) => {
      // eslint-disable-next-line no-param-reassign
      uf.directoryID = emptyID;
    });
    directory.childDirectories.forEach((cd) => {
      // eslint-disable-next-line no-param-reassign
      cd.parentID = emptyID;
    });

    registry.setAll(directory, { connectedComponentID: courseID });

    registry.setLoading(false);
  });

  private setMostRecentlyUploadedCourseFileID(id: string, courseID: string) {
    runInAction(() => {
      this.mostRecentlyUploadedCourseFileIDRepository.setAll(id, {
        connectedComponentID: courseID,
      });
    });

    // reset this after 4 seconds
    setTimeout(() => {
      runInAction(() => this.mostRecentlyUploadedCourseFileIDRepository.reset());
    }, 4000);
  }

  createOrUpdateDirectory = action(async (directory: Directory) => {
    const newDirectory = await api.Directories.createOrUpdate(directory);

    // get the registry, ensure the course for the registry is correct
    const registry = this.courseUploadedFilesRegistry;
    if (!registry.value || !registry.fresh(true, { connectedComponentID: directory.courseID }))
      return null;

    // ensure that if this dir's parent is the root, the parentID is set appropriately
    if (!newDirectory.parentID) newDirectory.parentID = emptyID;

    // get the parent directory of the file
    const parentDirectory = this.findDirectoryByID(registry.value, newDirectory.parentID);

    // try to find the file. if it exists, update the name
    const existingDirectory = this.findDirectoryByID(registry.value, newDirectory.id);
    if (existingDirectory) {
      existingDirectory.name = newDirectory.name;
    } else if (parentDirectory) {
      parentDirectory.childDirectories.push(newDirectory);
    }

    // sort the directories
    if (parentDirectory) this.sortChildDirectories(parentDirectory);

    // update the most recently uploaded course file, so it can be highlighted
    if (newDirectory.id && newDirectory.courseID)
      this.setMostRecentlyUploadedCourseFileID(newDirectory.id, newDirectory.courseID);

    // mobx doesn't like to notice changes to deeply nested things, so force the change to be noticed
    registry.setValue({ ...registry.value });

    return newDirectory;
  });

  deleteDirectory = action(async (directory: Directory) => {
    if (!directory.id) return;

    // delete in the database
    const successfullyDeleted = await api.Directories.delete(directory.courseID, directory.id);
    if (!successfullyDeleted) return;

    // get the registry, ensure the course for the registry is correct
    const registry = this.courseUploadedFilesRegistry;
    if (!registry.value || !registry.fresh(true, { connectedComponentID: directory.courseID }))
      return;

    // find the parent directory
    const parentDirectory = this.findDirectoryByID(registry.value, directory.parentID);

    // remove the directory from the parent
    if (parentDirectory) {
      parentDirectory.childDirectories = [
        ...parentDirectory.childDirectories.filter((cd) => cd.id !== directory.id),
      ];
    }

    // mobx doesn't like to notice changes to deeply nested things, so force the change to be noticed
    registry.setValue({ ...registry.value });
  });

  private addTemporaryCourseFile = action((file: UploadedFile, previousID?: string): string => {
    const temporaryFile = observable.object({ ...file }) as UploadedFile;

    if (
      !this.courseUploadedFilesRegistry.fresh(false, {
        connectedComponentID: temporaryFile.connectedComponentID,
      }) ||
      !this.courseUploadedFilesRegistry.value
    )
      return "";

    if (!temporaryFile.id) {
      temporaryFile.id = `${this.getRandomFileID()}`;
    }

    if (!temporaryFile.directoryID) {
      temporaryFile.directoryID = emptyID;
    }

    // get the directory for this file
    let directory = this.findDirectoryByID(
      this.courseUploadedFilesRegistry.value,
      temporaryFile.directoryID
    );
    directory = directory ?? this.courseUploadedFilesRegistry.value;

    // add the file to the directory
    directory.uploadedFiles = [
      ...(directory.uploadedFiles ?? []).filter((uf) => uf.id !== previousID),
      temporaryFile,
    ];

    this.sortUploadedFiles(directory);

    if (temporaryFile.id && temporaryFile.courseID)
      this.setMostRecentlyUploadedCourseFileID(temporaryFile.id, temporaryFile.courseID);

    // mobx doesn't like to notice changes to deeply nested things, so force the change to be noticed
    this.courseUploadedFilesRegistry.setValue({ ...this.courseUploadedFilesRegistry.value });

    return temporaryFile.id;
  });

  private getRandomFileID() {
    return Math.floor(Math.random() * 100000000);
  }

  private findDirectoryByID = (
    rootDirectory: Directory,
    directoryID: string | undefined
  ): Directory | undefined => {
    // if this directory is the root or the one we're looking for, return the directory
    if (directoryID === undefined || rootDirectory.id === directoryID) {
      return rootDirectory;
    }

    // Iterate through each child directory to search deeper
    // eslint-disable-next-line no-restricted-syntax
    for (const childDirectory of rootDirectory.childDirectories) {
      const found = this.findDirectoryByID(childDirectory, directoryID);
      if (found) {
        // If the directory is found in the subtree, return it
        return found;
      }
    }

    // If the directory is not found in this subtree, return undefined
    return undefined;
  };

  private findDirectoryThatContainsFile = (
    rootDirectory: Directory,
    fileID: string
  ): Directory | undefined => {
    // if this directory contains this file, return the directory
    if (rootDirectory.uploadedFiles.find((uf) => uf.id === fileID || uf.id === fileID)) {
      return rootDirectory;
    }

    // Iterate through each child directory to search deeper
    // eslint-disable-next-line no-restricted-syntax
    for (const childDirectory of rootDirectory.childDirectories) {
      const found = this.findDirectoryThatContainsFile(childDirectory, fileID);
      if (found) {
        // If the directory is found in the subtree, return it
        return found;
      }
    }

    // If the file is not found in this subtree, return undefined
    return undefined;
  };

  private sortUploadedFiles(directory: Directory) {
    runInAction(() =>
      directory.uploadedFiles.sort((a: UploadedFile, b: UploadedFile) =>
        a.originalFileName.localeCompare(b.originalFileName)
      )
    );
  }

  private sortChildDirectories(directory: Directory) {
    runInAction(() =>
      directory.childDirectories.sort((a: Directory, b: Directory) => a.name.localeCompare(b.name))
    );
  }
}
