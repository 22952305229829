import { PropsWithChildren } from "react";
import "./HorizontalIndent.css";

interface HorizontalIndentProps {
  indent?: string;
  className?: string;
}

const HorizontalIndent: React.FC<PropsWithChildren<HorizontalIndentProps>> = ({
  indent,
  children,
  className,
}) => (
  <div className={`HorizontalIndent ${className ?? ""}`} style={{ marginLeft: indent ?? "1em" }}>
    {children}
  </div>
);

export default HorizontalIndent;
