import { Button, Icon } from "semantic-ui-react";
import { createClassName } from "../../../utilities/utils";
import FlexContainer from "../style/FlexContainer";
import "./CheckboxButton.css";

interface CheckboxButtonProps {
  content: string;
  color: "blue";
  checked: boolean;
  onClick: (checked: boolean) => void;
  className?: string;
}

const CheckboxButton: React.FC<CheckboxButtonProps> = ({
  content,
  color,
  checked,
  className,
  onClick,
}) => (
  <Button
    color={color}
    onClick={() => onClick(!checked)}
    type="button"
    className={createClassName("CheckboxButton", className, { name: "checked", apply: checked })}
  >
    <FlexContainer justifyContent="center" alignItems="center" gap="0.5rem">
      <FlexContainer className="checkbox" justifyContent="center" alignItems="center">
        {checked && <Icon name="checkmark" fitted size="small" />}
      </FlexContainer>
      <div>{content}</div>
    </FlexContainer>
  </Button>
);

export default CheckboxButton;
