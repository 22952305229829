import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import FormLogoInput from "../../../_common/form/FormLogoInput";
import "./AssignmentSurveyForm.css";
import LinkPreview from "./LinkPreview/LinkPreview";
import "./SubmissionURLTextInput.css";

export interface SubmissionURLTextInputProps {
  url: string | undefined;
}

const SubmissionURLTextInput: React.FC<SubmissionURLTextInputProps> = ({ url }) => {
  const [previewURL, setPreviewURL] = useState<string | undefined>(url);
  const [previewOpen, setPreviewOpen] = useState<boolean>(url !== undefined && url !== "");
  const [userIsTyping, setUserIsTyping] = useState<boolean>(false);

  const fetchURLPreviewData = useCallback(
    debounce(async (link: string) => {
      // the user has stopped typing for .75 seconds
      setPreviewURL(link);
      setUserIsTyping(false);
    }, 750), // .75 seconds
    []
  );

  const shouldPreviewBeClosed = (link: string) => {
    setUserIsTyping(true);
    if (link === "") {
      setPreviewOpen(false);
    } else {
      setPreviewOpen(true);
    }
  };

  useEffect(() => {
    if (url !== undefined) {
      fetchURLPreviewData(url);
      shouldPreviewBeClosed(url);
    }
  }, [url]);

  return (
    <div className="SubmissionURLTextInput">
      <FormLogoInput
        name={"url"}
        placeholder={"Your link here..."}
        iconName={"linkify"}
        required={false}
        type={"text"}
        maxWidth="100%"
      />
      <div className={`LinkPreview expandable ${previewOpen ? "open" : ""}`}>
        <LinkPreview url={previewURL ?? ""} showLoading={userIsTyping} />
      </div>
    </div>
  );
};

export default observer(SubmissionURLTextInput);
