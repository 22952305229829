import { observer } from "mobx-react-lite";
import { Navigate, useNavigate } from "react-router-dom";
import { Header, Image } from "semantic-ui-react";
import TEACHFRONT_PATHS from "../../paths";
import { useStore } from "../../stores/store";
import RoundedSegment from "../_common/style/RoundedSegment";
import logo from "../../assets/logo.svg";
import "./ConfirmEmailPage.css";
import VerticalGap from "../_common/style/spacing/VerticalGap";

const ConfirmEmailPage: React.FC = () => {
  const { userStore } = useStore();
  const { registerEmail } = userStore;
  const navigate = useNavigate();

  if (!registerEmail) {
    return <Navigate to={TEACHFRONT_PATHS.Landing} replace />;
  }

  return (
    <RoundedSegment className="ConfirmEmailPage ui page-background-gradient">
      <Image src={logo} centered size="small" />
      <Header as="h2">Successfully Registered</Header>
      <VerticalGap height="2em" />
      <span>
        We have sent a verification email to your inbox{" "}
        <strong className="email">{registerEmail}</strong>.
      </span>
      <span>Be sure to check your spam inbox if you cannot find this email.</span>
      <VerticalGap height="3em" />
      <button className="ui button blue" onClick={() => navigate(TEACHFRONT_PATHS.Landing)}>
        Return Home
      </button>
    </RoundedSegment>
  );
};

export default observer(ConfirmEmailPage);
