import { Form, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { Button, Loader, Message, Table } from "semantic-ui-react";
import useBooleanState from "../../../../../../hooks/useBooleanState";
import useCourseColor from "../../../../../../hooks/useCourseColor";
import useValidParams from "../../../../../../hooks/useValidParameters";
import Color from "../../../../../../models/Color";
import { GradeRecipeIngredient } from "../../../../../../models/GradeRecipeIngredient";
import { useStore } from "../../../../../../stores/store";
import {
  getMapValues,
  objectForEach,
  objectSize,
} from "../../../../../../utilities/collectionUtils";
import { isFormDirty } from "../../../../../../utilities/formUtils";
import VerticalGap from "../../../../../_common/style/spacing/VerticalGap";
import "./GradeRecipesSettingsForm.css";
import GradeRecipesSettingsFormHeader from "./GradeRecipesSettingsFormHeader";
import GradeRecipesTableHeader from "./_tableComponents/GradeRecipesTableHeader";
import GradeRecipesTableIngredientRow from "./_tableComponents/GradeRecipesTableIngredientRow";
import GradeRecipesTableObjectiveRow from "./_tableComponents/GradeRecipesTableObjectiveRow";

export const createGradeRecipesSettingsFormValueKey = (constraintID: string, index: number) =>
  `${constraintID}-${index}`;

export type GradeRecipesSettingsFormValues = {
  [id: string]: string; // quantities as string
};

const getUniqueErrorValues = (errors: FormikErrors<GradeRecipesSettingsFormValues>) => {
  const errorSet = new Set<string>();

  objectForEach(errors, (_, value) => errorSet.add(value as string));

  return Array.from(errorSet);
};

const GradeRecipesSettingsFormSkeleton: React.FC = () => (
  <div className="GradeRecipesSettingsForm">
    <GradeRecipesSettingsFormHeader />
    <VerticalGap height="1rem" />
    <Loader active={true} content={"Loading table content..."} />
  </div>
);

const GradeRecipesSettingsForm: React.FC = () => {
  const [submitting, setSubmitting] = useBooleanState();
  const { courseID } = useValidParams<{ courseID: string }>();
  const courseColor = useCourseColor(Color.BLUE);
  const {
    gradeDistinctionStore,
    gradeRecipeConstraintStore,
    gradeRecipeIngredientStore,
    objectiveStore,
    toastStore,
  } = useStore();
  const {
    gradeDistinctionsForCourse,
    loadGradeDistinctionsForCourse,
    hasLoadedGradeDistinctionsForCourse,
  } = gradeDistinctionStore;
  const { gradeRecipeConstraints, loadGradeRecipeConstraints, hasLoadedGradeRecipeConstraints } =
    gradeRecipeConstraintStore;
  const {
    gradeRecipeIngredients,
    loadGradeRecipeIngredients,
    hasLoadedGradeRecipeIngredients,
    createOrUpdateGradeRecipeIngredients,
  } = gradeRecipeIngredientStore;
  const { hierarchicalObjectives, loadObjectivesHierarchical, hasLoadedHierarchicalObjectives } =
    objectiveStore;

  useEffect(() => {
    loadGradeDistinctionsForCourse(courseID);
    loadGradeRecipeConstraints(courseID);
    loadGradeRecipeIngredients(courseID);
    loadObjectivesHierarchical(courseID);
  }, [courseID]);

  // Set the quantities from existing entities
  const quantityDictionary = useMemo(() => {
    if (gradeRecipeIngredients && gradeRecipeConstraints && gradeDistinctionsForCourse) {
      const dictionary: GradeRecipesSettingsFormValues = {};

      gradeRecipeConstraints.forEach((constraint) => {
        const { id: constraintID } = constraint;
        const ingredientsForConstraint = gradeRecipeIngredients.filter(
          (i) => i.gradeRecipeConstraintID === constraintID
        );

        gradeDistinctionsForCourse.forEach((distinction, index) => {
          const ingredientForDistinction = ingredientsForConstraint.find(
            (i) => i.gradeDistinctionID === distinction.id
          );

          dictionary[createGradeRecipesSettingsFormValueKey(constraintID, index)] =
            ingredientForDistinction?.quantity?.toString() ?? "";
        });
      });

      return dictionary;
    }

    return undefined;
  }, [gradeRecipeIngredients, gradeRecipeConstraints, gradeDistinctionsForCourse]);

  if (
    !gradeDistinctionsForCourse ||
    !gradeRecipeConstraints ||
    !gradeRecipeIngredients ||
    !hierarchicalObjectives ||
    !quantityDictionary ||
    !hasLoadedHierarchicalObjectives() ||
    !hasLoadedGradeRecipeConstraints(courseID) ||
    !hasLoadedGradeRecipeIngredients(courseID) ||
    !hasLoadedGradeDistinctionsForCourse()
  )
    return <GradeRecipesSettingsFormSkeleton />;

  const handleSubmit = async (values: GradeRecipesSettingsFormValues) => {
    const newIngredients: GradeRecipeIngredient[] = [];

    gradeRecipeIngredients
      // Only send grace recipe ingredients that still have an existing grade recipe constraint
      .filter((gri) => gradeRecipeConstraints.some((grc) => grc.id === gri.gradeRecipeConstraintID))
      .forEach((ingredient) => {
        const gradeDistinctionIndex = gradeDistinctionsForCourse.findIndex(
          (g) => g.id === ingredient.gradeDistinctionID
        );

        // If the grade distinction doesn't exist or the ingredient belongs to the last grade distinction
        if (
          gradeDistinctionIndex === -1 ||
          gradeDistinctionIndex === gradeDistinctionsForCourse.length - 1
        )
          return;

        const quantity =
          values[
            createGradeRecipesSettingsFormValueKey(
              ingredient.gradeRecipeConstraintID,
              gradeDistinctionIndex
            )
          ];

        const parsedQuantity =
          quantity === "" || quantity === undefined ? undefined : parseInt(quantity, 10);

        newIngredients.push({
          ...ingredient,
          quantity: parsedQuantity,
        });
      });

    setSubmitting(true);

    await createOrUpdateGradeRecipeIngredients(courseID, newIngredients);

    toastStore.showToast("Successfully saved grade recipes");

    setSubmitting(false);
  };

  const initialValues: GradeRecipesSettingsFormValues = quantityDictionary;

  return (
    <Formik initialValues={{ ...initialValues }} onSubmit={handleSubmit}>
      {({ errors, values, setValues }) => (
        <Form className="GradeRecipesSettingsForm">
          <GradeRecipesSettingsFormHeader />
          <VerticalGap height="1rem" />
          <div className="table-container">
            <Table unstackable={true}>
              <Table.Body>
                <GradeRecipesTableHeader
                  courseColor={courseColor}
                  gradeDistinctionsForCourse={gradeDistinctionsForCourse}
                />
                {getMapValues(hierarchicalObjectives)
                  .filter((objective) =>
                    gradeRecipeConstraints.some((c) => c.objectiveID === objective.id)
                  )
                  .map((objective) => (
                    <React.Fragment key={objective.id}>
                      <GradeRecipesTableObjectiveRow
                        objective={objective}
                        numberOfGradeDistinctions={gradeDistinctionsForCourse.length}
                      />
                      {gradeRecipeConstraints
                        .filter((c) => c.objectiveID === objective.id)
                        .map((constraint) => (
                          <GradeRecipesTableIngredientRow
                            key={constraint.id}
                            constraint={constraint}
                            errors={errors}
                            gradeDistinctions={gradeDistinctionsForCourse}
                          />
                        ))}
                    </React.Fragment>
                  ))}
              </Table.Body>
            </Table>
          </div>
          {errors && objectSize(errors) > 0 && (
            <>
              <Message negative list={getUniqueErrorValues(errors)} />
            </>
          )}
          {(!errors || objectSize(errors) === 0) && <VerticalGap height="1rem" />}
          <Button
            content="Save Changes"
            icon="checkmark"
            color="blue"
            floated="right"
            loading={submitting}
            type="submit"
            disabled={!isFormDirty(initialValues, values) || objectSize(errors) > 0}
          />
          <Button
            content="Cancel"
            icon="x"
            color="red"
            floated="right"
            type="button"
            disabled={!isFormDirty(initialValues, values)}
            onClick={() => setValues({ ...initialValues })}
          />
          <VerticalGap height="1rem" />
        </Form>
      )}
    </Formik>
  );
};

export default observer(GradeRecipesSettingsForm);
