import { useMemo, useState } from "react";
import { Header } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../../../hooks/useCurrentUserCourseRole";
import { User } from "../../../../../../models/User";
import { PollingActivity } from "../../../../../../models/polling/PollingActivity";
import CourseRoles from "../../../../../../utilities/routing/components/routeAuthorizationExtensions/CourseRoles";
import VerticalGap from "../../../../../_common/style/spacing/VerticalGap";
import InstructorPollingActivityOverviewIndividualQuestionGraphic from "./InstructorPollingActivityOverviewIndividualQuestionGraphic";
import InstructorPollingActivityOverviewResponseRateGraphic from "./InstructorPollingActivityOverviewResponseRateGraphic";
import InstructorPollingActivityOverviewWordCloudGraphic from "./InstructorPollingActivityOverviewWordCloudGraphic";
import "./PollingActivityOverview.css";
import InstructorPollingActivityOverviewResponseRateTable from "./PollingActivityOverviewResponseRateTable";
import PollingActivityOverviewTabs from "./PollingActivityOverviewTabs";

interface PollingActivityOverviewProps {
  pollingActivity: PollingActivity;
  roster: User[];
  courseID: string;
}

const PollingActivityOverview: React.FC<PollingActivityOverviewProps> = ({
  pollingActivity,
  roster,
  courseID,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const { pollingActivityQuestions } = pollingActivity;

  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);

  const students = useMemo(
    () => roster.filter((user) => CourseRoles.StudentRoles.includes(user.courseRole ?? "")),
    [roster]
  );

  if (!pollingActivityQuestions) return <></>;

  // Get the selected question based off which tab is selected. Offset the index by one, because the first tab
  // shows the overall response rate, not a specific question
  const selectedQuestion =
    selectedTabIndex > 0 && selectedTabIndex <= pollingActivityQuestions.length
      ? pollingActivityQuestions[selectedTabIndex - 1]
      : undefined;

  return (
    <div className="PollingActivityOverview">
      {isUserTeachingTeam && (
        <>
          <PollingActivityOverviewTabs
            defaultTabName="Response Rate"
            questionTabNames={pollingActivityQuestions.map((q) => q.pollingQuestionTemplate.name)}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
          />
          <VerticalGap height="0.5rem" />
        </>
      )}

      <div className="overview-content">
        {isUserTeachingTeam && (
          <>
            <Header
              icon={selectedQuestion ? "question circle outline" : "feed"}
              content={
                selectedQuestion ? selectedQuestion.pollingQuestionTemplate.name : "Response Rate"
              }
              as="h3"
            />
            {!selectedQuestion && (
              <InstructorPollingActivityOverviewResponseRateGraphic
                pollingActivityQuestions={pollingActivityQuestions}
                students={students}
              />
            )}
            {/* If the question is a multiple choice-type question with more than one response option. */}
            {selectedQuestion &&
              !selectedQuestion.pollingQuestionTemplate.allowShortAnswer &&
              selectedQuestion.pollingQuestionTemplate.responseOptions.length > 1 && (
                <InstructorPollingActivityOverviewIndividualQuestionGraphic
                  pollingActivityQuestion={selectedQuestion}
                  students={students}
                />
              )}
            {/* If the question is not a word cloud question and either allows short answer or has only one response option. */}
            {selectedQuestion &&
              !selectedQuestion.pollingQuestionTemplate.name.includes("Word") &&
              (selectedQuestion.pollingQuestionTemplate.allowShortAnswer ||
                selectedQuestion.pollingQuestionTemplate.responseOptions.length === 1) && (
                <InstructorPollingActivityOverviewResponseRateGraphic
                  pollingActivityQuestions={[selectedQuestion]}
                  students={students}
                />
              )}
            {/* If the question is a word cloud question. */}
            {selectedQuestion && selectedQuestion.pollingQuestionTemplate.name.includes("Word") && (
              <InstructorPollingActivityOverviewWordCloudGraphic
                pollingActivityQuestions={[selectedQuestion]}
              />
            )}
            <Header
              content={
                selectedQuestion ? "Student Responses for this Question:" : "All Student Responses:"
              }
              as="h4"
              className="no-bottom-margin"
            />
            <VerticalGap height="0.5rem" />
          </>
        )}
        <div className="table-container">
          <InstructorPollingActivityOverviewResponseRateTable
            pollingActivityQuestions={
              selectedQuestion ? [selectedQuestion] : pollingActivityQuestions
            }
            students={students}
            courseID={courseID}
          />
        </div>
      </div>
    </div>
  );
};

export default PollingActivityOverview;
