import { useLocation } from "react-router-dom";
import { useStore } from "../stores/store";
import { ToastColor } from "../stores/toastStore";

/**
 * Custom hook that provides a function to set a global toast for the site.
 * This hook is necessary because it hooks into react-router-dom's useLocation hook to
 * enable removing the toast when a user navigates to another page.
 * @returns a function that takes parameters message and optional parameter color, using them to set the global toast.
 */
export default function useToast() {
  const location = useLocation();
  const { toastStore } = useStore();

  return (message: string, color?: ToastColor) =>
    toastStore.showToast(message, {
      locationKey: location.key,
      color,
    });
}
