import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Header } from "semantic-ui-react";
import useCourseColor from "../../../../../hooks/useCourseColor";
import useValidParams from "../../../../../hooks/useValidParameters";
import Color from "../../../../../models/Color";
import { MasteryLevelScheme } from "../../../../../models/MasteryLevelScheme";
import { useStore } from "../../../../../stores/store";
import { emptyID } from "../../../../../utilities/submissionUtils";
import MasteryLevelSchemeCard, {
  MasteryLevelSchemeCardSkeleton,
} from "../../../../_common/cards/MasteryLevelSchemeCard";
import FlexContainer from "../../../../_common/style/FlexContainer";
import HorizontalIndent from "../../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import "./MasteryLevelsSettingsForm.css";

interface MasteryLevelSettingsFormSkeletonProps {
  color: Color;
}

const MasteryLevelSettingsFormSkeleton: React.FC<MasteryLevelSettingsFormSkeletonProps> = ({
  color,
}) => (
  <div>
    <Header as="h3" content="Mastery Levels" />
    <p>
      Mastery levels are used to describe how well a student's work exhibits mastery toward an
      objective. Mastery levels are like the columns in a rubric, but with descriptive names instead
      of grade distinctions or point values.
    </p>
    <Header as="h3" content="Mastery Level Schemes" />
    <p>
      Mastery levels are organized into mastery level “schemes” for assessing student work with
      regards to an objective. If the mastery levels are the columns in a rubric, mastery level
      schemes are the <i>complete set</i> of columns in the rubric.
    </p>
    <p>
      Each objective or objective category has one of the course's mastery level schemes, which
      ensures consistency in the assessment of all assignments related to that objective. It is very
      common to use the same mastery level scheme for many objectives.
    </p>
    <Header as="h3" content="Create and Update Mastery Level Schemes" />
    <Button color="blue" content="Add Mastery Level Scheme" icon="plus" disabled={true} />
    <VerticalGap height="1rem" />
    <HorizontalIndent>
      <FlexContainer flexDirection="column" gap="1rem">
        {[0, 1, 2, 3].map((value) => (
          <MasteryLevelSchemeCardSkeleton key={value} color={color} />
        ))}
      </FlexContainer>
    </HorizontalIndent>
  </div>
);

const MasteryLevelsSettingsForm: React.FC = () => {
  const { courseID } = useValidParams<{ courseID: string }>();
  const { masteryLevelSchemeStore } = useStore();
  const {
    masteryLevelSchemesForCourse,
    loadMasteryLevelSchemesForCourse,
    hasLoadedMasteryLevelSchemesForCourse,
  } = masteryLevelSchemeStore;
  const [newMasteryLevelSchemes, setNewMasteryLevelSchemes] = useState<MasteryLevelScheme[]>([]);
  const color = useCourseColor(Color.BLUE);

  useEffect(() => {
    loadMasteryLevelSchemesForCourse(courseID);
  }, []);

  if (!masteryLevelSchemesForCourse || !hasLoadedMasteryLevelSchemesForCourse())
    return <MasteryLevelSettingsFormSkeleton color={color} />;

  const handleAddMasteryLevelSchemeButtonClick = () => {
    const newScheme: MasteryLevelScheme = {
      id: emptyID,
      masteryLevels: [],
      name: "",
      isDraft: true,
      draftCreatedAt: Date.now(),
      courseID,
    };
    setNewMasteryLevelSchemes([...newMasteryLevelSchemes, newScheme]);
  };

  const removeDraftMasteryLevelScheme = (masteryLevelScheme: MasteryLevelScheme) => {
    if (!masteryLevelScheme.isDraft) {
      return;
    }

    const newSchemes = [...newMasteryLevelSchemes];
    const index = newSchemes.findIndex(
      ({ draftCreatedAt }) => draftCreatedAt === masteryLevelScheme.draftCreatedAt
    );
    if (index !== -1) newSchemes.splice(index, 1);

    setNewMasteryLevelSchemes(newSchemes);
  };

  const allSchemes = [...masteryLevelSchemesForCourse, ...newMasteryLevelSchemes];

  allSchemes.sort((a, b) => {
    if (a.draftCreatedAt && b.draftCreatedAt) return b.draftCreatedAt - a.draftCreatedAt;
    if (a.draftCreatedAt) return -1;
    if (b.draftCreatedAt) return 1;

    return a.name.localeCompare(b.name);
  });

  return (
    <div>
      <Header as="h3" content="Mastery Levels" />
      <p>
        Mastery levels are used to describe how well a student's work exhibits mastery toward an
        objective. Mastery levels are like the columns in a rubric, but with descriptive names
        instead of grade distinctions or point values.
      </p>
      <Header as="h3" content="Mastery Level Schemes" />
      <p>
        Mastery levels are organized into mastery level “schemes” for assessing student work with
        regards to an objective. If the mastery levels are the columns in a rubric, mastery level
        schemes are the <i>complete set</i> of columns in the rubric.
      </p>
      <p>
        Each objective or objective category has one of the course's mastery level schemes, which
        ensures consistency in the assessment of all assignments related to that objective. It is
        very common to use the same mastery level scheme for many objectives.
      </p>
      <Header as="h3" content="Create and Update Mastery Level Schemes" />
      <Button
        color="blue"
        content="Add Mastery Level Scheme"
        icon="plus"
        onClick={handleAddMasteryLevelSchemeButtonClick}
      />
      <VerticalGap height="1rem" />
      <HorizontalIndent>
        <FlexContainer flexDirection="column" gap="1rem">
          {allSchemes.map((scheme) => (
            <MasteryLevelSchemeCard
              masteryLevelScheme={scheme}
              key={scheme.draftCreatedAt ? scheme.draftCreatedAt : scheme.id}
              onCancelButtonClick={() => removeDraftMasteryLevelScheme(scheme)}
              onSaveButtonClick={() => removeDraftMasteryLevelScheme(scheme)}
            />
          ))}
        </FlexContainer>
      </HorizontalIndent>
    </div>
  );
};

export default observer(MasteryLevelsSettingsForm);
