import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Header } from "semantic-ui-react";
import useBooleanState from "../../../../../hooks/useBooleanState";
import Color from "../../../../../models/Color";
import { Objective } from "../../../../../models/Objective";
import { useStore } from "../../../../../stores/store";
import { objectSize } from "../../../../../utilities/collectionUtils";
import { isFormDirty } from "../../../../../utilities/formUtils";
import LoadingComponent from "../../../../../utilities/routing/components/LoadingComponent";
import { emptyID } from "../../../../../utilities/submissionUtils";
import ColorPickerInput from "../../../../_common/form/ColorPickerInput";
import FormDropdownInput from "../../../../_common/form/FormDropdownInput";
import FormLogoInput from "../../../../_common/form/FormLogoInput";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import "./CreateOrUpdateObjectiveModal.css";

interface CreateOrUpdateObjectiveModalProps {
  courseID: string;
  objective?: Objective;
  parentObjectiveID?: string;
  masteryLevelSchemeID?: string;
  title: string;
}

const CreateOrUpdateObjectiveModal: React.FC<CreateOrUpdateObjectiveModalProps> = ({
  courseID,
  objective,
  parentObjectiveID,
  title,
  masteryLevelSchemeID,
}) => {
  const { objectiveStore, masteryLevelSchemeStore, modalStore, toastStore } = useStore();
  const {
    masteryLevelSchemesForCourse,
    loadMasteryLevelSchemesForCourse,
    hasLoadedMasteryLevelSchemesForCourse,
  } = masteryLevelSchemeStore;
  const {
    createOrUpdate,
    loadHierarchicalObjectivesWithAssignments,
    loadAllObjectives,
    loadObjectivesHierarchical,
  } = objectiveStore;
  const [submitting, setSubmitting] = useBooleanState();

  useEffect(() => {
    loadMasteryLevelSchemesForCourse(courseID);
  }, [courseID]);

  if (!masteryLevelSchemesForCourse || !hasLoadedMasteryLevelSchemesForCourse())
    return <LoadingComponent content="Loading mastery level schemes..." />;

  const initValues: Objective = {
    color: objective?.color ?? Color.BLUE,
    id: objective?.id ?? emptyID,
    longName: objective?.longName ?? "",
    shortName: objective?.shortName ?? "",
    parentID: objective?.parentID ?? parentObjectiveID ?? undefined,
    masteryLevelSchemeID: objective?.masteryLevelSchemeID ?? masteryLevelSchemeID ?? undefined,
  };

  const handleSubmit = async (values: Objective) => {
    setSubmitting(true);
    await createOrUpdate(courseID, [{ ...values }]);
    await loadHierarchicalObjectivesWithAssignments(courseID, { overrideIfFresh: true });
    setSubmitting(false);

    modalStore.closeModal();
    toastStore.showToast(`Successfully ${objective ? "updated" : "created"} objective`);

    loadAllObjectives(courseID, { overrideIfFresh: true });
    loadObjectivesHierarchical(courseID, { overrideIfFresh: true });
    loadMasteryLevelSchemesForCourse(courseID, { overrideIfFresh: true });
  };

  const editTerm = objective ? "Save" : "Create";

  const validate = (values: Objective) => {
    const errors: Partial<Objective> = {};

    if (!values.shortName) errors.shortName = "You must provide the objective a name";

    if (!values.masteryLevelSchemeID)
      errors.masteryLevelSchemeID =
        "The objective must have a mastery level scheme associated with it";

    return errors;
  };

  return (
    <Formik initialValues={initValues} onSubmit={handleSubmit} validate={validate}>
      {({ values, setFieldValue, errors, touched }) => (
        <Form className="CreateOrUpdateObjectiveModal">
          <Header content={title} />
          <FormLogoInput
            name="shortName"
            label="Objective Name"
            iconName="plus"
            placeholder="(e.g. Attendance)"
            required={true}
            type="text"
            error={touched.shortName && errors.shortName}
          />
          <VerticalGap height="1rem" />
          <FormLogoInput
            name="longName"
            label="Objective Description"
            iconName="plus"
            placeholder="(e.g. Attend class.)"
            required={false}
            type="text"
            textarea={true}
            error={touched.longName && errors.longName}
          />
          <VerticalGap height="1rem" />
          <div className="choose-course-color">
            <Header
              as="h4"
              className="choose-color-header"
              content={
                <>
                  <span>Choose objective color:</span>
                  <ColorPickerInput
                    currentColor={values.color}
                    onColorSelect={(newColor) => setFieldValue("color", newColor)}
                    childModalIndex={1}
                  />
                </>
              }
            />
          </div>
          <VerticalGap height="1rem" />
          <FormDropdownInput
            label="Mastery Level Scheme"
            placeholder="Select mastery level scheme..."
            options={masteryLevelSchemesForCourse.map((scheme) => ({
              id: scheme.id,
              label: scheme.name,
            }))}
            onOptionSelect={(option) => setFieldValue("masteryLevelSchemeID", option.id)}
            error={touched.masteryLevelSchemeID && errors.masteryLevelSchemeID}
            required={true}
            defaultValue={values.masteryLevelSchemeID}
            disabled={!!parentObjectiveID}
          />
          <VerticalGap height="3rem" />
          <Button content="Cancel" icon="x" color="red" onClick={() => modalStore.closeModal()} />
          <Button
            type="submit"
            content={editTerm}
            icon={editTerm === "Create" ? "plus" : "edit"}
            color="blue"
            floated="right"
            disabled={!isFormDirty(initValues, values) || objectSize(errors) > 0}
            loading={submitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default observer(CreateOrUpdateObjectiveModal);
