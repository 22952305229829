import useWindowSize from "../../../../../../hooks/useWindowSize";
import { User } from "../../../../../../models/User";
import { PollingActivityQuestion } from "../../../../../../models/polling/PollingActivityQuestion";
import HistogramChart from "../../../../../_common/charts/HistogramChart";
import FlexContainer from "../../../../../_common/style/FlexContainer";
import "./InstructorPollingActivityOverviewIndividualQuestionGraphic.css";
import InstructorPollingActivityOverviewResponseRateGraphicMobile from "./InstructorPollingActivityOverviewIndividualQuestionGraphicMobile";

interface InstructorPollingActivityOverviewIndividualQuestionGraphicProps {
  pollingActivityQuestion: PollingActivityQuestion;
  students: User[];
}

export type InstructorPollingActivityOverviewIndividualQuestionGraphicData = {
  name: string;
  numResponses: number;
};

const InstructorPollingActivityOverviewIndividualQuestionGraphic: React.FC<
  InstructorPollingActivityOverviewIndividualQuestionGraphicProps
> = ({ pollingActivityQuestion, students }) => {
  const { width } = useWindowSize([600]);
  const { responseOptions } = pollingActivityQuestion.pollingQuestionTemplate;

  const data: InstructorPollingActivityOverviewIndividualQuestionGraphicData[] =
    responseOptions.map(({ id, name, description }) => {
      const numResponses = pollingActivityQuestion.pollingActivityQuestionResponses.filter(
        (r) => r.responseOptionID === id
      ).length;

      return {
        numResponses,
        name: `${description || name}`,
      };
    });

  if (width < 600) {
    return (
      <InstructorPollingActivityOverviewResponseRateGraphicMobile data={data} students={students} />
    );
  }

  const dataWithPercentageLabels = data.map(({ name, numResponses }) => ({
    numResponses,
    name: `${name} (${Math.round(
      (100 * numResponses) / pollingActivityQuestion.pollingActivityQuestionResponses.length
    )}%)`,
  }));

  return (
    <FlexContainer
      className="InstructorPollingActivityOverviewIndividualQuestionGraphic"
      alignItems="center"
      justifyContent="center"
    >
      <div className="chart-wrapper" style={{ width: `${8 * responseOptions.length}rem` }}>
        <HistogramChart
          height={196}
          verticalBars={false}
          chartTitle="Answer Distribution"
          data={dataWithPercentageLabels}
          dataKey="numResponses"
          minBinValue={0}
          maxBinValue={students.length}
          binLabels={dataWithPercentageLabels.map((d) => d.name)}
          labelUnit=""
          countUnit="students"
          binDataMapper={(bin) =>
            dataWithPercentageLabels.find(({ name }) => name === bin)?.numResponses ?? 0
          }
          lineLength="100%"
          titlePosition="30%"
          labelWidth={
            dataWithPercentageLabels &&
            dataWithPercentageLabels[0] &&
            dataWithPercentageLabels[0]?.name.length >= 8
              ? 230
              : undefined
          }
        />
      </div>
    </FlexContainer>
  );
};

export default InstructorPollingActivityOverviewIndividualQuestionGraphic;
