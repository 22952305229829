import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "semantic-ui-react";
import AssessmentSummarySymbols from "../components/_common/assessmentGraphic/AssessmentSummarySymbols";
import CourseLogo from "../components/_misc/CourseLogo";
import CourseHomeMenu from "../components/courses/[courseID]/CourseHomeMenu";
import useValidParams from "../hooks/useValidParameters";
import useWindowSize from "../hooks/useWindowSize";
import { useStore } from "../stores/store";

const CourseHomeLayout = () => {
  const { isMobile } = useWindowSize();
  const { courseID } = useValidParams<{ courseID: string }>();
  const store = useStore();
  const { courseStore, userStore, reset: resetStore } = store;
  const {
    loadCurrentCourse,
    currentCourse,
    instructorsForCourse,
    courseMemberDetailsForVisibleUser,
    loadCourseMemberDetailsForVisibleUser,
    resetCourseMemberDetailsForVisibleUser,
    loadCourseRoleForCurrentUser,
  } = courseStore;
  const { user } = userStore;

  useEffect(() => {
    if (userStore.user && courseID) {
      loadCourseRoleForCurrentUser(courseID, userStore.user.userID);
    }
    // make sure the current course is loaded
    if (!currentCourse) {
      loadCurrentCourse(courseID);
    } else if (courseID !== currentCourse.id) {
      resetStore();
    }
    // if the userID is set, make sure the course member details are set
    if (user && courseID) {
      loadCourseMemberDetailsForVisibleUser(courseID, user.userID);
    }
  }, [
    loadCourseRoleForCurrentUser,
    resetStore,
    userStore.user,
    currentCourse,
    instructorsForCourse,
    loadCurrentCourse,
    courseID,
    store,
    user,
    courseMemberDetailsForVisibleUser,
    loadCourseMemberDetailsForVisibleUser,
    resetCourseMemberDetailsForVisibleUser,
  ]);

  return (
    <>
      <AssessmentSummarySymbols />
      {!isMobile && (
        <div className="transparent-header">
          <CourseLogo data-print-id="hide" course={currentCourse} />
          <Header as="h1" className="current-course-short-name">
            {currentCourse?.shortName}: {currentCourse?.longName}
          </Header>
          <Header as="h3" className="current-course-instructors">
            {currentCourse?.term} -&nbsp;
            {instructorsForCourse?.map((i) => `${i.firstName} ${i.lastName}`).join(", ")}
          </Header>
        </div>
      )}
      <CourseHomeMenu />
      <Outlet />
    </>
  );
};

export default observer(CourseHomeLayout);
