// eslint-disable-next-line import/no-cycle
import api, { ApiCallOptions, ErrorHandlerPackage } from "../api/api";
import { CalendarEntry } from "../models/CalendarEntry";
import { CalendarEntryType } from "../models/CalendarEntryType";
import { CalendarLinkType } from "../models/CalendarLinkType";
import { getLocalDate } from "../utilities/dateTimeUtils";
import {
  ASSIGNMENT_DUE_DATE_CALENDAR_ENTRY_TYPE_ID,
  ASSIGNMENT_RELEASE_DATE_CALENDAR_ENTRY_TYPE_ID,
  ASSIGNMENT_RESUBMISSION_DUE_DATE_CALENDAR_ENTRY_TYPE_ID,
} from "../utilities/globalVariables";
import PersistentPreferenceStore from "./persistentPreferenceStore";

import { StoreValue } from "./storeValue";

const getAssignmentCalendarEntryTypes = (courseID: string): CalendarEntryType[] => [
  {
    courseID,
    name: "Assignment Released",
    id: ASSIGNMENT_RELEASE_DATE_CALENDAR_ENTRY_TYPE_ID,
    isAssignmentEntryType: true,
  },
  {
    courseID,
    name: "Assignment Due",
    id: ASSIGNMENT_DUE_DATE_CALENDAR_ENTRY_TYPE_ID,
    isAssignmentEntryType: true,
  },
  {
    courseID,
    name: "Assignment Resubmissions Due",
    id: ASSIGNMENT_RESUBMISSION_DUE_DATE_CALENDAR_ENTRY_TYPE_ID,
    isAssignmentEntryType: true,
  },
];

export default class CalendarStore {
  private calendarEntriesRegistry = new StoreValue<CalendarEntry[], { courseID: string }>();

  private calendarEntryTypesRegistry = new StoreValue<CalendarEntryType[], { courseID: string }>();

  private calendarLinkTypeRegistry = new StoreValue<CalendarLinkType[], { courseID: string }>();

  private preferenceStore: PersistentPreferenceStore;

  hasLoadedCalendarEntryTypesForCourse = (courseID: string) =>
    this.calendarEntryTypesRegistry.fresh(false, { courseID });

  hasLoadedCalendarEntriesForCourse = (courseID: string) =>
    this.calendarEntriesRegistry.fresh(false, { courseID });

  hasLoadedCalendarLinkTypesForCourse = (courseID: string) =>
    this.calendarLinkTypeRegistry.fresh(false, { courseID });

  get calendarEntries() {
    return this.calendarEntriesRegistry.value;
  }

  get calendarLinkTypes() {
    return this.calendarLinkTypeRegistry.value;
  }

  get calendarEntryTypes() {
    return this.calendarEntryTypesRegistry.value;
  }

  constructor(preferenceStore: PersistentPreferenceStore) {
    this.preferenceStore = preferenceStore;
  }

  reset = () => {
    this.calendarEntriesRegistry.reset();
    this.calendarEntryTypesRegistry.reset();
  };

  loadCalendarEntriesForCourse = async (courseID: string, apiCallOptions?: ApiCallOptions) => {
    if (
      this.calendarEntriesRegistry.fresh(true, { courseID }) &&
      !apiCallOptions?.overrideIfFresh
    ) {
      return;
    }

    this.calendarEntriesRegistry.setLoading(true, { courseID });

    const calendarEntries = await api.CalendarEntries.listForCourse(
      courseID,
      apiCallOptions?.errorHandlerPackage
    );

    calendarEntries.forEach(this.setCalendarEntryDates);

    this.calendarEntriesRegistry.setAll(calendarEntries, { courseID });

    this.calendarEntriesRegistry.setLoading(false);
  };

  loadCalendarEntryTypes = async (courseID: string, apiCallOptions?: ApiCallOptions) => {
    if (this.calendarEntriesRegistry.fresh(true, { courseID }) && !apiCallOptions?.overrideIfFresh)
      return;

    this.calendarEntryTypesRegistry.setLoading(true, { courseID });

    const calendarEntryTypes = await api.CalendarEntryTypes.listForCourse(courseID);

    const allCalendarEntryTypes = [
      ...calendarEntryTypes,
      ...getAssignmentCalendarEntryTypes(courseID),
    ];

    this.calendarEntryTypesRegistry.setAll(allCalendarEntryTypes, { courseID });

    this.preferenceStore.setCalendarEntryTypes(allCalendarEntryTypes);

    this.calendarEntryTypesRegistry.setLoading(false);
  };

  setCalendarEntryDates(ce: CalendarEntry) {
    const calendarEntry = ce;
    calendarEntry.startTime = getLocalDate(calendarEntry.startTime);
    calendarEntry.endTime = getLocalDate(calendarEntry.endTime);
  }

  createOrUpdateCalendarEntries = async (
    courseID: string,
    calendarEntries: CalendarEntry[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.CalendarEntries.createOrUpdateCourseEntries(
      courseID,
      calendarEntries,
      errorHandlerPackage
    );

    if (result) {
      const tempEntries = [...(this.calendarEntriesRegistry.value ?? [])];

      result.forEach((e) => {
        const entry = e;
        // Don't use this.setCalendarEntryDates because the dates are already in local time
        entry.startTime = new Date(entry.startTime);
        entry.endTime = new Date(entry.endTime);

        // Manually set the link types that are associated with this calendar entry
        entry.links = entry.links.map((link) => ({
          ...link,
          calendarLinkType: this.calendarLinkTypeRegistry?.value?.find(
            ({ id }) => id === link.calendarLinkTypeID
          ),
        }));

        const index = tempEntries.findIndex((tempEntry) => tempEntry.id === entry.id);

        if (index === -1) tempEntries.push(entry);
        else tempEntries[index] = entry;
      });

      this.calendarEntriesRegistry.setValue(tempEntries);

      return result;
    }

    return undefined;
  };

  deleteCalendarEntry = async (calendarEntryToDelete: CalendarEntry) => {
    const isSuccess = await api.CalendarEntries.delete(calendarEntryToDelete);

    if (isSuccess && this.calendarEntriesRegistry.value) {
      const remainingCalendarEntries = this.calendarEntriesRegistry.value.filter(
        (entry) => entry.id !== calendarEntryToDelete.id
      );
      this.calendarEntriesRegistry.setValue(remainingCalendarEntries);
    }

    return isSuccess;
  };

  createOrUpdateCalendarEntryType = async (
    calendarEntryType: CalendarEntryType,
    errorhandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.CalendarEntryTypes.createOrUpdate(
      calendarEntryType,
      errorhandlerPackage
    );

    if (result) {
      this.preferenceStore.addOrUpdateCalendarEntryType(result);

      const tempEntryTypes = [...(this.calendarEntryTypesRegistry.value ?? [])];

      const index = tempEntryTypes.findIndex((type) => type.id === result.id);

      if (index === -1) tempEntryTypes.push(result);
      else tempEntryTypes[index] = result;

      this.calendarEntryTypesRegistry.setValue(tempEntryTypes);

      return result;
    }

    return undefined;
  };

  loadCalendarLinkTypes = async (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) => {
    if (this.calendarLinkTypeRegistry.fresh(true, { courseID })) {
      return;
    }

    this.calendarLinkTypeRegistry.setLoading(true, { courseID });

    const result = await api.CalendarLinkTypes.listForCourse(courseID, errorHandlerPackage);

    this.calendarLinkTypeRegistry.setAll(result, { courseID });

    this.calendarEntriesRegistry.setLoading(false);
  };

  createOrUpdateCalendarLinkType = async (
    calendarLinkType: CalendarLinkType,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.CalendarLinkTypes.createOrUpdate(
      calendarLinkType,
      errorHandlerPackage
    );

    if (result) {
      const tempLinkTypes = [...(this.calendarLinkTypeRegistry.value ?? [])];

      const index = tempLinkTypes.findIndex((linkType) => linkType.id === result.id);

      if (index === -1) tempLinkTypes.push(result);
      else tempLinkTypes[index] = result;

      this.calendarLinkTypeRegistry.setValue(tempLinkTypes);

      return result;
    }

    return undefined;
  };
}
