import { observer } from "mobx-react-lite";
import { PropsWithChildren, useEffect } from "react";
import {
  Button,
  Dropdown,
  Header,
  Icon,
  Label,
  Placeholder,
  PlaceholderLine,
} from "semantic-ui-react";
import useBooleanState from "../../../../hooks/useBooleanState";
import useValidParams from "../../../../hooks/useValidParameters";
import { Assignment } from "../../../../models/Assignment";
import { Objective } from "../../../../models/Objective";
import { useStore } from "../../../../stores/store";
import TeachFrontNavLink from "../../../../utilities/routing/components/TeachFrontNavLink";
import { createClassName, isQuillEmpty } from "../../../../utilities/utils";
import CardTypeLabel from "../../../_common/cards/_common/CardTypeLabel";
import CheckboxButton from "../../../_common/form/CheckboxButton";
import FlexContainer from "../../../_common/style/FlexContainer";
import HorizontalIndent from "../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../_common/style/spacing/VerticalGap";
import UserInputDisplay from "../../../_common/textEditing/UserInputDisplay";
import CreateOrUpdateObjectiveModal from "../settings/objectives/CreateOrUpdateObjectiveModal";
import "./CourseObjective.css";

interface CourseObjectiveProps {
  objective: Objective;
  isEditable?: boolean;
  showHowToShowMastery?: boolean;
  showAssignments?: boolean;
  showChildObjectives?: boolean;
  backgroundColor?: "white" | "grey";
  cardTypeLabel?: string;
  className?: string;
  enableCollapseWithCheckbox?: boolean; // allows users to collapse the children of this CourseObjective with checkbox
  enableCollapseWithArrowIcon?: boolean; // allows users to collapse the children of this CourseObjective with icon
  collapsedByDefault?: boolean;
  scrolledTo?: boolean; // true if this objective was clicked on in the corresponding section of an assessed shell. Indicates that the objective dropdown should be open (i.e. not collapsed) on scroll
}

export const CourseObjectiveSkeleton: React.FC = () => (
  <div className="CourseObjectiveSkeleton">
    <CardTypeLabel content={"Objective"} />
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
  </div>
);

const CourseObjective: React.FC<PropsWithChildren<CourseObjectiveProps>> = ({
  objective,
  isEditable,
  showHowToShowMastery = true,
  showAssignments = true,
  showChildObjectives = true,
  backgroundColor,
  cardTypeLabel = "Objective",
  className = "",
  children,
  enableCollapseWithCheckbox,
  enableCollapseWithArrowIcon,
  collapsedByDefault = false,
  scrolledTo,
}) => {
  const { courseID } = useValidParams<{ courseID: string }>();
  const { modalStore, objectiveStore, toastStore } = useStore();
  const { delete: deleteObjective } = objectiveStore;
  const [collapsed, setCollapsed] = useBooleanState(collapsedByDefault);
  const [submitting, setSubmitting] = useBooleanState();

  useEffect(() => {
    if (scrolledTo) {
      setCollapsed(false);
    }
  }, [scrolledTo]);

  if (!objective) return <></>;

  const handleEditClicked = () => {
    modalStore.openModal(
      <CreateOrUpdateObjectiveModal
        objective={objective}
        parentObjectiveID={objective.parentID}
        courseID={courseID}
        title={`Edit Objective: ${objective.shortName}`}
      />
    );
  };

  const handleDeleteClicked = async () => {
    let deletionNotPermittedMessage = "";

    if (objective.assignments && objective.assignments.length > 0)
      deletionNotPermittedMessage =
        "This objective is associated with an assignment, so it can't be deleted.";
    else if (
      objective.children &&
      objective.children.some(
        (childObjective) => childObjective.assignments && childObjective.assignments.length > 0
      )
    )
      deletionNotPermittedMessage =
        "A child of this objective is associated with an assignment. This objective cannot be deleted.";

    if (!deletionNotPermittedMessage) {
      setSubmitting(true);
      await deleteObjective(courseID, [objective]);
      setSubmitting(false);
      toastStore.showToast("Objective successfully deleted.");
    } else toastStore.showToast(deletionNotPermittedMessage, { color: "orange" });
  };

  const getDropdownOptions = () => {
    const options = [
      {
        key: "edit",
        content: "Edit Objective",
        icon: "edit",
        onClick: handleEditClicked,
      },
      {
        key: "delete",
        content: "Delete Objective",
        icon: "trash",
        onClick: handleDeleteClicked,
      },
    ];

    return options;
  };

  const handleAddSubObjectiveClick = () => {
    modalStore.openModal(
      <CreateOrUpdateObjectiveModal
        courseID={courseID}
        parentObjectiveID={objective.id}
        title={`Add sub-objective to ${objective.shortName}`}
        masteryLevelSchemeID={objective.masteryLevelSchemeID}
      />
    );
  };

  return (
    <div
      className={`CourseObjective ${className}}`}
      style={{
        borderColor: `var(--${objective.color}-7)`,
        backgroundColor: backgroundColor === "white" ? `var(--white)` : `var(--bg-grey)`,
        // Show a pointer cursor if the objective is collapsed and this objective can be collapsed
        cursor:
          (enableCollapseWithArrowIcon || enableCollapseWithCheckbox) && collapsed
            ? "pointer"
            : undefined,
      }}
      data-print-id="color"
      onClick={() => {
        // Allow clicking the entire objective to expand it, only when it is collapsed.
        if ((enableCollapseWithArrowIcon || enableCollapseWithCheckbox) && collapsed)
          setCollapsed(!collapsed);
      }}
    >
      {children && enableCollapseWithCheckbox && (
        <CheckboxButton
          checked={collapsed}
          onClick={setCollapsed}
          color="blue"
          content={"Assessed"}
          className="collapse-button"
        />
      )}
      <CardTypeLabel content={cardTypeLabel} />
      <VerticalGap height="1rem" />
      <Label
        as="h3"
        ribbon
        className={createClassName(objective.color, "rounded-0", {
          name: "collapse-with-arrow-icon-enabled",
          apply: !!enableCollapseWithArrowIcon,
        })}
        data-print-id="color"
        onClick={() => {
          if (enableCollapseWithArrowIcon) setCollapsed(!collapsed);
        }}
      >
        {enableCollapseWithArrowIcon && !collapsed && (
          <Icon className="expand-icon" name="caret down" />
        )}
        {enableCollapseWithArrowIcon && collapsed && (
          <Icon className="expand-icon" name="caret right" />
        )}
        {objective.shortName}
      </Label>
      <VerticalGap height="0.5rem" />
      <p className="objective-description">
        {objective.longName} ({objective.masteryLevelScheme?.name})
      </p>
      {
        // display assignments that assess this objective
        showAssignments && objective.assignments && objective.assignments.length > 0 && (
          <>
            <VerticalGap height="1rem" />
            <FlexContainer gap="0.25rem" flexWrap="wrap">
              {objective.assignments?.map((assignment: Assignment<Objective>) => {
                const assignmentID = assignment.id;
                return (
                  <TeachFrontNavLink
                    to={"CourseHomeAssignmentDetailsTab"}
                    params={{ courseID, assignmentID }}
                    key={assignment.id}
                    className="objective-assignment-link rounded-0"
                  >
                    {assignment.name}
                  </TeachFrontNavLink>
                );
              })}
            </FlexContainer>
          </>
        )
      }
      {
        // display child objectives
        showChildObjectives && objective.children && objective.children.length !== 0 && (
          <HorizontalIndent indent="2rem">
            <FlexContainer flexDirection="column" gap="0rem">
              {objective.children?.map((childObjective: Objective) => (
                <CourseObjective
                  objective={childObjective}
                  key={childObjective.id}
                  isEditable={isEditable}
                  backgroundColor="white"
                />
              ))}
            </FlexContainer>
          </HorizontalIndent>
        )
      }
      {
        // display how to show mastery of this objective
        !isQuillEmpty(objective.howToShowMastery) && showHowToShowMastery && !collapsed && (
          <>
            <Header as="h5" className="show-mastery-header">
              How to Show Mastery of {objective.shortName} in this Assignment:
            </Header>
            <HorizontalIndent>
              <UserInputDisplay content={objective.howToShowMastery} />
            </HorizontalIndent>
          </>
        )
      }
      {children && !collapsed && <>{children}</>}
      {isEditable && (
        <>
          <Dropdown
            className="edit-objective-button"
            icon={"ellipsis vertical"}
            options={getDropdownOptions()}
            direction="left"
            loading={submitting}
          />
          {!objective.parentID && (
            <>
              <VerticalGap height="1rem" />
              <HorizontalIndent>
                <Button
                  content="Add sub objective"
                  className="white"
                  icon="plus"
                  onClick={handleAddSubObjectiveClick}
                />
              </HorizontalIndent>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default observer(CourseObjective);
