import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createClassName } from "../../../utilities/utils";
import "./TextEditor.css";
import Toolbar from "./Toolbar";

interface TextEditorProps {
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  container?: string; // optional container name when multiple editors are needed. Cannot start with a digit.
  smallHeight?: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}

const TextEditor: React.FC<TextEditorProps> = ({
  onChange,
  value,
  defaultValue,
  placeholder,
  smallHeight,
  onKeyDown,
}) => {
  const [toolbarNode, setToolbarNode] = useState();

  return (
    <div
      className={createClassName("TextEditor", {
        name: "small-height",
        apply: smallHeight === true,
      })}
    >
      <Toolbar ref={setToolbarNode} />
      {/* TODO: Fix this messy logic in future */}
      {!!toolbarNode &&
        (value !== undefined ? (
          <ReactQuill
            placeholder={placeholder}
            value={value}
            theme="snow"
            onChange={onChange}
            onKeyDown={onKeyDown}
            modules={{
              toolbar: {
                container: toolbarNode,
              },
              clipboard: { matchVisual: false },
            }}
          />
        ) : (
          <ReactQuill
            placeholder={placeholder}
            defaultValue={defaultValue}
            theme="snow"
            onChange={onChange}
            onKeyDown={onKeyDown}
            modules={{
              toolbar: {
                container: toolbarNode,
              },
              clipboard: { matchVisual: false },
            }}
          />
        ))}
    </div>
  );
};

export default TextEditor;
