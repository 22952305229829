import { CalendarEntry } from "../models/CalendarEntry";

export enum DayOfTheWeek {
  SUNDAY = "Sunday", // 0
  MONDAY = "Monday", // 1
  TUESDAY = "Tuesday", // 2
  WEDNESDAY = "Wednesday", // 3
  THURSDAY = "Thursday", // 4
  FRIDAY = "Friday", // 5
  SATURDAY = "Saturday", // 6
}

export function getLocalDate(date: Date | string): Date {
  if (typeof date === "string") {
    // Treat all strings as UTC dates (if it doesn't already look like one, make it look like one)
    const utcDateString = date.includes("Z") || /[+-]\d{2}:\d{2}$/.test(date) ? date : `${date}Z`;
    // A UTC date string will be translated to the local timezone by default
    // Return the date directly, no further conversion needed
    return new Date(utcDateString);
  }

  // The date is already in current time, so just return a copy.
  return new Date(date);
}

export function getTimeSince(submissionDate: Date) {
  const milliseconds = 1000;
  const seconds = 60;
  const minutes = 60;
  const hours = 24;
  const timeDiff = Math.abs(
    submissionDate.getTime() - Date.now() - submissionDate.getTimezoneOffset()
  );
  const diffInDays = Math.round(timeDiff / (milliseconds * seconds * minutes * hours));
  const diffInHours = Math.round(timeDiff / (milliseconds * seconds * minutes));
  const diffInMinutes = Math.round(timeDiff / (milliseconds * seconds));

  if (diffInMinutes < 1) {
    return "less than a minute";
  }
  if (diffInMinutes < 60) {
    return `${diffInMinutes} minutes`;
  }
  if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours === 1 ? "s" : ""}`;
  }
  return `${diffInDays} days`;
}

export function getUTCDate(date: Date) {
  // just in case the date wasn't actually created
  const safeDate = new Date(date);
  // convert the date to UTC time
  const timeZonedDate = new Date(safeDate.getTime());
  return timeZonedDate;
}

export function calculateDaysBetweenDates(startDate: Date, endDate: Date): number {
  // Get the difference in milliseconds
  const differenceInMillis = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to days
  // Note: 1 day = 24 hours, 1 hour = 60 minutes, 1 minute = 60 seconds, 1 second = 1000 milliseconds
  const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24);

  // Return the absolute value to avoid negative results if startDate is after endDate
  return Math.floor(Math.abs(differenceInDays));
}

export function dateIsToday(date: Date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function formatDateMinutesHoursDayMonth(date: Date) {
  return date.toLocaleDateString("en-us", {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
  });
}

export function formatDateDownToMinutes(date: Date) {
  return date.toLocaleDateString("en-us", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
  });
}

export function formatDateOnlyHoursAndMinutes(date: Date) {
  const timestamp = date.toLocaleTimeString("en-us", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return timestamp;
}

export function formatDateDownToDay(date: Date) {
  return date.toLocaleDateString("en-us", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function formatDateDownToDayNoYear(date: Date) {
  return date.toLocaleDateString("en-us", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });
}

export function formatDateDownToDayNoWeekday(date: Date) {
  return date.toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

/**
 * Gets the current time in milliseconds.
 * @returns the current time in milliseconds.
 */
export function getTime() {
  return Date.now();
}

export function compareDates(date1: Date | undefined, date2: Date | undefined): number {
  // Case 1: Both dates are undefined
  if (date1 === undefined && date2 === undefined) {
    return 0;
  }

  // Case 2: First date is undefined, so it's considered less than any defined date
  if (date1 === undefined) {
    return -1;
  }

  // Case 3: Second date is undefined, so the first date is considered greater
  if (date2 === undefined) {
    return 1;
  }

  // Case 4: Both dates are defined, compare them directly
  return getUTCDate(date1).getTime() - getUTCDate(date2).getTime();
}

export function createRecurringCalendarEntries(
  calendarEntryTemplate: CalendarEntry,
  daysToRecur: DayOfTheWeek[],
  untilDate: Date | undefined
) {
  const allCalendarEntries: CalendarEntry[] = [];

  if (daysToRecur.length === 0 || !untilDate) {
    return [calendarEntryTemplate];
  }

  const adjustedUntilDate = new Date(untilDate);
  adjustedUntilDate.setDate(adjustedUntilDate.getDate() + 1);
  const daysToRecurAsIndexes = daysToRecur.map((d) => Object.values(DayOfTheWeek).indexOf(d));

  const getNumDaysUntilNextDayOfTheWeek = (
    currentDayIndex: number,
    nextDayIndex: number
  ): number => {
    // If the days are the same, then it is a week until the next day.
    if (currentDayIndex === nextDayIndex) return 7;

    let difference = nextDayIndex - currentDayIndex;

    if (difference < 0) difference += 7;

    return difference;
  };

  let startTimeClone = new Date(calendarEntryTemplate.startTime);
  let endTimeClone = new Date(calendarEntryTemplate.endTime);
  let currentDayIndex = calendarEntryTemplate.startTime.getDay();

  const getNextDayIndex = (currentDayAsIndex: number) => {
    let tempCurrentDayIndex = currentDayAsIndex + 1;

    // If the current day is saturday, reset it to sunday
    if (tempCurrentDayIndex === 7) tempCurrentDayIndex = 0;

    while (tempCurrentDayIndex !== currentDayAsIndex) {
      if (daysToRecurAsIndexes.includes(tempCurrentDayIndex)) return tempCurrentDayIndex;

      // Increment the day, or set it to 0
      if (tempCurrentDayIndex === 6) tempCurrentDayIndex = 0;
      else tempCurrentDayIndex += 1;
    }

    return currentDayAsIndex;
  };

  while (startTimeClone.getTime() < adjustedUntilDate.getTime()) {
    const entryClone = { ...calendarEntryTemplate };
    entryClone.startTime = startTimeClone;
    entryClone.endTime = endTimeClone;
    allCalendarEntries.push(entryClone);

    const nextDayIndex = getNextDayIndex(currentDayIndex);
    const numDaysUntil = getNumDaysUntilNextDayOfTheWeek(currentDayIndex, nextDayIndex);

    currentDayIndex = nextDayIndex;
    startTimeClone = new Date(startTimeClone);
    endTimeClone = new Date(endTimeClone);
    startTimeClone.setDate(startTimeClone.getDate() + numDaysUntil);
    endTimeClone.setDate(startTimeClone.getDate() + numDaysUntil);
  }

  return allCalendarEntries;
}

export function getDateOnly(date: Date) {
  return date.toISOString().split("T")[0] as unknown as Date;
}
