import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Container, Header } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../hooks/useCurrentUserCourseRole";
import { Submission } from "../../../../models/Submission";
import { ModalProps } from "../../../../stores/modalStore";
import { useStore } from "../../../../stores/store";
import { formatDateDownToMinutes } from "../../../../utilities/dateTimeUtils";
import LoadingComponent from "../../../../utilities/routing/components/LoadingComponent";
import { getSubmissionPackage } from "../../../../utilities/submissionUtils";
import { isQuillEmpty } from "../../../../utilities/utils";
import HorizontalIndent from "../../../_common/style/spacing/HorizontalIndent";
import AssignmentSubmissionContents from "../_common/AssignmentSubmissionContents";
import "./SubmissionCommentModal.css";

interface SubmissionCommentModalProps extends ModalProps {
  submission: Submission;
  isFirstSubmission: boolean;
}

const SubmissionCommentModal: React.FC<SubmissionCommentModalProps> = ({
  submission,
  isFirstSubmission,
  modalIndex,
}) => {
  const { assignmentStore, modalStore } = useStore();
  const { isUserStudent } = useCurrentUserCourseRole(submission.courseID);

  const {
    assignmentWithObjectivesAndMasteryLevels,
    loadAssignmentWithObjectivesAndMasteryLevels,
    hasLoadedAssignmentWithObjectivesAndMasteryLevels,
  } = assignmentStore;

  // load assignment
  useEffect(() => {
    loadAssignmentWithObjectivesAndMasteryLevels(submission.assignmentID, submission.courseID);
  }, [assignmentWithObjectivesAndMasteryLevels, submission.assignmentID, submission.courseID]);

  // loading component if things aren't ready yet
  if (
    !hasLoadedAssignmentWithObjectivesAndMasteryLevels(submission.assignmentID) ||
    assignmentWithObjectivesAndMasteryLevels == null
  ) {
    return <LoadingComponent content="Loading Assignment..." />;
  }

  return (
    <Container className="SubmissionCommentModal">
      <Header as="h3" content="Submission contents:" />
      <HorizontalIndent>
        <Container className="submission-details">
          Status:{" "}
          {submission.isDraft
            ? "Draft (Instructors will not be able to view the contents of this submission when assessing)"
            : "Successfully Submitted"}
        </Container>
        <Container className="submission-details">
          {submission.isDraft ? "Saved At: " : "Submitted At: "}{" "}
          {formatDateDownToMinutes(submission.createdAt)}
        </Container>
      </HorizontalIndent>
      {!submission.hasUploadedFiles && isQuillEmpty(submission.data) && (
        <span>There is no content for this submission.</span>
      )}
      <AssignmentSubmissionContents
        submissionPackage={getSubmissionPackage(
          submission.userID,
          submission,
          assignmentWithObjectivesAndMasteryLevels,
          isUserStudent,
          isFirstSubmission
        )}
      />
      <Container className="button-container">
        <Button
          size="small"
          color="grey"
          onClick={() => modalStore.closeModal(modalIndex)}
          icon="x"
          content="Close"
        />
      </Container>
    </Container>
  );
};

export default observer(SubmissionCommentModal);
