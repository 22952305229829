import { observer } from "mobx-react-lite";
import { Icon, Loader } from "semantic-ui-react";
import useBooleanState from "../../../hooks/useBooleanState";
import { UploadedFile } from "../../../models/UploadedFile";
import { useStore } from "../../../stores/store";
import { UploadedFileCategory } from "../../../stores/uploadedFileStore";
import { createClassName, isValidID } from "../../../utilities/utils";
import HorizontalIndent from "../style/spacing/HorizontalIndent";
import "./FileLister.css";
import FilePreview from "./FilePreview";

interface ListedFileProps {
  file: UploadedFile;
  index: number;
  uploadFileCategory: UploadedFileCategory;
  onClick?: () => void;
  showFilePreviewer?: boolean;
}

const ListedFile: React.FC<ListedFileProps> = ({
  file,
  index,
  uploadFileCategory,
  showFilePreviewer,
}) => {
  const { uploadedFileStore } = useStore();
  const { downloadFileWithCategory } = uploadedFileStore;

  const [collapsed, setCollapsed] = useBooleanState(true);

  // there are files to display
  return (
    <div
      className={createClassName(
        "ListedFile",
        {
          name: "no-preview",
          apply: !showFilePreviewer,
          else: "preview-enabled",
        },
        {
          name: "expanded",
          apply: !collapsed,
        }
      )}
    >
      <div key={file.id ?? index} className="existing-file" role="button">
        <div
          className="file-meta"
          onClick={() =>
            showFilePreviewer
              ? setCollapsed(!collapsed)
              : downloadFileWithCategory(file, uploadFileCategory)
          }
        >
          {showFilePreviewer && <Icon name={collapsed ? "caret right" : "caret down"} />}
          <Icon name="file outline" />
          {file.originalFileName}
        </div>
        {isValidID(file.id) && (
          <div className="icons" onClick={() => downloadFileWithCategory(file, uploadFileCategory)}>
            <Icon name="download" />
          </div>
        )}
        {!isValidID(file.id) && <Loader active={true} size="small" inline />}
      </div>
      {showFilePreviewer && !collapsed && isValidID(file.id) && (
        <HorizontalIndent>
          <FilePreview file={file} uploadFileCategory={uploadFileCategory} />
        </HorizontalIndent>
      )}
    </div>
  );
};

export default observer(ListedFile);
