import { Form, Formik, FormikErrors } from "formik";
import { Button, Header, Icon, Loader, SemanticICONS } from "semantic-ui-react";
import useActionBuffer from "../../hooks/useActionBuffer";
import useBooleanState from "../../hooks/useBooleanState";
import Color from "../../models/Color";
import { Course } from "../../models/Course";
import { useStore } from "../../stores/store";
import { objectSize } from "../../utilities/collectionUtils";
import { isFormDirty } from "../../utilities/formUtils";
import { emptyID } from "../../utilities/submissionUtils";
import ColorPickerInput from "../_common/form/ColorPickerInput";
import FormDateInput from "../_common/form/FormDateInput";
import FormLogoInput from "../_common/form/FormLogoInput";
import FlexContainer from "../_common/style/FlexContainer";
import VerticalGap from "../_common/style/spacing/VerticalGap";
import "./CreateCourseModal.css";

type CreateCourseFormValues = {
  shortName: string;
  longName: string;
  term: string;
  uniqueRegistrationCode: string;
  color: Color;
  startDate: Date | undefined;
  endDate: Date | undefined;
};

interface VerifyCodeMessageProps {
  message: string;
  className: string;
  icon: SemanticICONS | "loading";
}

const VerifyCodeMessage: React.FC<VerifyCodeMessageProps> = ({ message, className, icon }) => (
  <FlexContainer className={`VerifyCodeMessage ${className}`} alignItems="center" gap="0.5rem">
    {icon === "loading" ? <Loader inline size="small" /> : <Icon name={icon} fitted />}
    <span>{message}</span>
  </FlexContainer>
);

const CreateCourseModal: React.FC = () => {
  const { courseStore, userStore, modalStore } = useStore();
  const { user } = userStore;
  const { createOrUpdateCourse, verifyRegistrationCode } = courseStore;
  const { setAction } = useActionBuffer(1500);
  const [hasAttemptedCodeVerification, setHasAttemptedCodeVerification] = useBooleanState(false);
  const [verifyingCode, setVerifyingCode] = useBooleanState(false);
  const [submittingForm, setSubmittingForm] = useBooleanState(false);
  const [isCodeTaken, setCodeTaken] = useBooleanState(false);

  if (!user) return <></>;

  const handleSubmit = async (values: CreateCourseFormValues) => {
    if (!values.startDate || !values.endDate || !user) return;

    const newCourse: Course = {
      id: emptyID,
      color: values.color,
      shortName: values.shortName,
      longName: values.longName,
      term: values.term,
      registrationCode: values.uniqueRegistrationCode,
      endDate: values.endDate.toISOString().split("T")[0] as unknown as Date, // only get the date, not the time
      startDate: values.startDate.toISOString().split("T")[0] as unknown as Date, // only get the date, not the time
      anonymousMode: false,
    };

    setSubmittingForm(true);

    await createOrUpdateCourse(user.userID, newCourse);

    setSubmittingForm(false);

    modalStore.closeModal();
  };

  const cleanRegistrationCode = (code: string): string => {
    let copy = code;

    copy = copy.replaceAll(" ", "_").toUpperCase();

    return copy;
  };

  const initialValues: CreateCourseFormValues = {
    color: Color.BLUE,
    startDate: undefined,
    endDate: undefined,
    longName: "",
    shortName: "",
    term: "",
    uniqueRegistrationCode: cleanRegistrationCode(user.lastName),
  };

  const getUniqueRegistrationCodeFromValues = (values: CreateCourseFormValues): string => {
    const codeValues = [user.lastName];

    if (values.shortName) codeValues.push(values.shortName);
    if (values.term) codeValues.push(values.term);

    return codeValues.join("_");
  };

  const hasUserChangedRegistrationCode = (values: CreateCourseFormValues): boolean =>
    values.uniqueRegistrationCode !==
    cleanRegistrationCode(getUniqueRegistrationCodeFromValues(values));

  const isCodeValid = (code: string | undefined) => code && code.length >= 3;

  const validate = (values: CreateCourseFormValues) => {
    const errors: FormikErrors<CreateCourseFormValues> = {};

    if (!values.shortName) errors.shortName = "You must specify the course number";
    if (!values.term) errors.term = "You must specify the course term";
    if (!values.longName) errors.longName = "You must specify the course name";
    if (!isCodeValid(values.uniqueRegistrationCode))
      errors.uniqueRegistrationCode = "Registration code must contain at least 3 characters";
    if (!values.startDate) errors.startDate = "You must specify a start date for the course";
    if (!values.endDate) errors.endDate = "You must specify an end date for the course";
    if (values.startDate && values.endDate && values.endDate < values.startDate)
      errors.endDate = "The course's end date must be later than the start date";

    return errors;
  };

  const onFormValueChange = (
    target: EventTarget,
    values: CreateCourseFormValues,
    setFieldValue: (field: string, message: string | undefined) => void
  ) => {
    const fieldsToListenTo = ["shortName", "term"];

    let newCode: string | undefined;

    if (target instanceof HTMLInputElement) {
      if (fieldsToListenTo.includes(target.name) && !hasUserChangedRegistrationCode(values)) {
        const registrationCode = getUniqueRegistrationCodeFromValues({
          ...values,
          [target.name]: target.value,
        });

        newCode = cleanRegistrationCode(registrationCode);
      } else if (target.name === "uniqueRegistrationCode") {
        newCode = cleanRegistrationCode(target.value);
      }
    }

    if (isCodeValid(newCode)) {
      setFieldValue("uniqueRegistrationCode", newCode);
      setHasAttemptedCodeVerification(true);
      setVerifyingCode(true);

      setAction(async () => {
        const isCodeAvailable = await verifyRegistrationCode(newCode as string);

        if (!isCodeAvailable) {
          setCodeTaken(true);
        } else {
          setCodeTaken(false);
        }

        setVerifyingCode(false);
      });
    } else if (newCode !== undefined) {
      setHasAttemptedCodeVerification(true);
      setFieldValue("uniqueRegistrationCode", newCode);
      setVerifyingCode(false);
      setAction(() => {});
    }
  };

  return (
    <div className="CreateCourseModal">
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validate={validate}>
        {({ values, setFieldValue, touched, errors, setFieldTouched }) => (
          <Form onChange={({ target }) => onFormValueChange(target, values, setFieldValue)}>
            <Header as="h2" content={"Create a Course"} />
            <FormLogoInput
              iconName="hashtag"
              label="Course Number"
              name="shortName"
              placeholder="e.g. FRNT 101"
              required={true}
              type="text"
              error={touched.shortName && errors.shortName}
              maxWidth="100%"
            />
            <VerticalGap height="1rem" />
            <FormLogoInput
              iconName="file alternate outline"
              label="Course Name"
              name="longName"
              placeholder="e.g. Introduction to TeachFront"
              required={true}
              type="text"
              maxWidth="100%"
            />
            <VerticalGap height="1rem" />
            <FormLogoInput
              iconName="calendar plus outline"
              label="Term"
              name="term"
              placeholder={`e.g. Fall ${new Date(Date.now()).getFullYear()}`}
              required={true}
              type="text"
              maxWidth="100%"
            />
            <VerticalGap height="2rem" />
            <div className="date-pickers">
              <Header as="h4" content="Course Dates:" />
              <FormDateInput
                currentDate={values.startDate}
                icon="calendar alternate outline"
                label="Course Start Date"
                onDateChange={(date) => {
                  setFieldValue("startDate", date);
                  setFieldTouched("startDate", true, false);
                }}
                datePickerProps={{
                  shouldCloseOnSelect: true,
                }}
                required={true}
                error={touched.startDate && errors.startDate}
              />
              <VerticalGap height="1rem" />
              <FormDateInput
                currentDate={values.endDate}
                disabled={!values.startDate}
                icon={"calendar alternate outline"}
                label="Course End Date"
                onDateChange={(date) => {
                  setFieldValue("endDate", date);
                  setFieldTouched("endDate", true, false);
                }}
                datePickerProps={{
                  minDate: values.startDate,
                  shouldCloseOnSelect: true,
                }}
                required={true}
                error={touched.endDate && errors.endDate}
              />
            </div>
            <VerticalGap height="2rem" />
            <div className="choose-course-color">
              <Header
                as="h4"
                className="choose-color-header"
                content={
                  <>
                    <span>Choose course color:</span>
                    <ColorPickerInput
                      currentColor={values.color}
                      onColorSelect={(newColor) => setFieldValue("color", newColor)}
                      childModalIndex={1}
                    />
                  </>
                }
              />
            </div>
            <VerticalGap height="2rem" />
            <FormLogoInput
              iconName="lock"
              label="Course Registration Code"
              name="uniqueRegistrationCode"
              placeholder="e.g. teachfront_fall_2023"
              required={true}
              type="text"
              tooltip="The course registration code is a unique code that can be used by students to join your course. It can be edited once you have filled in the course term and number."
              disabled={
                !hasUserChangedRegistrationCode(values) && (!values.term || !values.shortName)
              }
              maxWidth="100%"
            />
            {hasAttemptedCodeVerification && (
              <>
                {verifyingCode && (
                  <VerifyCodeMessage
                    message="Verifying registration code..."
                    className="verifying"
                    icon="loading"
                  />
                )}
                {!verifyingCode && (isCodeTaken || errors.uniqueRegistrationCode) && (
                  <VerifyCodeMessage
                    message={errors.uniqueRegistrationCode ?? "Registration code unavailable"}
                    className="error"
                    icon="x"
                  />
                )}
                {!verifyingCode && !isCodeTaken && !errors.uniqueRegistrationCode && (
                  <VerifyCodeMessage
                    message="Registration code is available!"
                    className="success"
                    icon="checkmark"
                  />
                )}
              </>
            )}
            <VerticalGap height="2rem" />
            <Button content="Cancel" icon="x" onClick={() => modalStore.closeModal()} color="red" />
            <Button
              content="Create Course"
              icon="plus"
              type="submit"
              disabled={
                !isFormDirty(initialValues, values) ||
                (errors && objectSize(errors) !== 0) ||
                verifyingCode ||
                isCodeTaken
              }
              color="blue"
              floated="right"
              loading={submittingForm}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateCourseModal;
