import { MasteryLevel } from "../models/MasteryLevel";
import { MasteryLevelScheme } from "../models/MasteryLevelScheme";
import { Objective } from "../models/Objective";
import { RatedObjective } from "../models/RatedObjective";
import { Rating } from "../models/Rating";
import { Submission } from "../models/Submission";

function getRatingForObjective(
  ratings: Rating[],
  submissionID: string | undefined,
  submissions: Submission[]
) {
  const submissionIndex = submissions.findIndex((s) => s.id === submissionID);
  for (let i = submissionIndex; i >= 0; i -= 1) {
    const mostRecentRating = ratings.find((r) => r.submissionID === submissions[i]?.id);
    if (mostRecentRating) return mostRecentRating;
  }
  return undefined;
}

/**
 * Finds the most recent rating for a given assignment and returns that level of mastery.
 * @param ratings the ratings to be searched through
 * @param submissionID the original submissionID. This will be overridden if this submission has no rating for
 *                     this objective.
 * @param submissions an array of all submissions for the current assignment.
 * @param masteryLevels the mastery levels associated with this objective.
 * @returns a number representing the most recent mastery level for this assignment.
 */
export function getMostRecentMasteryLevel(
  ratings: Rating[] | undefined,
  submissionID: string | undefined,
  submissions: Submission[],
  masteryLevels: MasteryLevel[] | undefined
): number {
  if (!masteryLevels) {
    return 0;
  }

  if (!ratings) {
    return -1;
  }

  const ratingForObjective = getRatingForObjective(ratings, submissionID, submissions);

  // Return -1 to signify this assignment has not been graded
  if (ratingForObjective === undefined) {
    return -1;
  }

  const masteryLevelIndex = masteryLevels.findIndex(
    (ml) => ml.id === ratingForObjective?.masteryLevelID
  );

  if (masteryLevelIndex === undefined || masteryLevelIndex === -1) {
    return 0;
  }

  return masteryLevels[masteryLevelIndex]?.relativeOrderIndex ?? -1;
}

/**
 * Represents a single "segment" in respect to a shell.
 * Should be used in an array if given multiple objectives/assignments.
 */
export type AssessmentSummaryGraphicData = {
  id: string;
  numSegments: number; // This value should represent how many other sections of grades are being shown in the graphic.
  numLevels: number; // This value represents the number of levels shown in the graphic, generally equal to numMasteryLevels - 1
  color: string; // Objective color, such as "blue", "red", or "orange"
  assessmentScore: number; // The score associated with this piece of data
  excused: boolean; // True if the rating was excused
  onGraphicClick?: () => void; // Callback function upon a user clicking on the graphic.
};

function getNumLevels(masteryLevelScheme: MasteryLevelScheme | undefined) {
  return (
    (masteryLevelScheme?.masteryLevels.filter((ml) => !ml.excludeInGradeCalculations).length || 1) -
    1
  );
}

/**
 * Creates the data necessary to make an AssessmentSummaryGraphic.
 * @param numSegments the total number of segments to be associated with the graphic.
 *                    This is important in the creation of the graphic to calculate how wide a segment
 *                    should be assuming numSegments != 1
 * @returns an AssessmentSummaryGraphicData objects that can be used to create a graphic.
 */
function getSingularGraphicDataForObjective(
  currentSubmission: Submission,
  submissions: Submission[],
  currentRatedObjective: RatedObjective,
  allRatedObjectives: RatedObjective[],
  numSegments: number
): AssessmentSummaryGraphicData {
  const ratings = currentRatedObjective.ratingsForStudents;
  const { id, userID } = currentSubmission;

  const unassessed = allRatedObjectives.every(
    (ratedObjective) =>
      !ratedObjective.ratingsForStudents ||
      !ratedObjective.ratingsForStudents[userID] ||
      ratedObjective.ratingsForStudents[userID]?.every((rating) => rating.submissionID !== id)
  );

  const mostRecentMasteryLevel = getMostRecentMasteryLevel(
    ratings ? ratings[userID] : undefined,
    id,
    submissions,
    currentRatedObjective.masteryLevelScheme?.masteryLevels
  );

  const assessmentScore = unassessed ? -1 : mostRecentMasteryLevel;
  const excused =
    !unassessed &&
    mostRecentMasteryLevel >= 0 &&
    !!currentRatedObjective.masteryLevelScheme?.masteryLevels[mostRecentMasteryLevel]
      ?.excludeInGradeCalculations;

  return {
    id: currentRatedObjective.id,
    numSegments,
    numLevels: getNumLevels(currentRatedObjective.masteryLevelScheme),
    color: currentRatedObjective.color,
    assessmentScore,
    excused,
  };
}

/**
 * Gets the necessary data to create a graphic based off the following parameters:
 * @param currentSubmission the selected submission.
 * @param submissions an array of all submissions in the current assignment.
 * @param ratingsForAssignment an array of RatedObjective representing the current and past ratings for the assignment.
 * @returns an array of AssessmentSummaryGraphicData objects that can be used to create a graphic.
 */
export function getGraphicDataForSubmission(
  currentSubmission: Submission,
  submissions: Submission[],
  ratingsForAssignment: RatedObjective[]
): AssessmentSummaryGraphicData[] {
  return ratingsForAssignment.map((currentRating) =>
    getSingularGraphicDataForObjective(
      currentSubmission,
      submissions,
      currentRating,
      ratingsForAssignment,
      ratingsForAssignment.length
    )
  );
}

/**
 * Gets the necessary data to create a graphic for a given Assignment assuming
 * the use of the most recent (last) rating.
 * @param userID the user to check ratings for.
 * @param assignment the assignment to check ratings from.
 * @returns an array of AssessmentSummaryGraphicData objects that can be used to create a graphic.
 */
export function getGraphicDataForBestRatings(
  userID: string,
  ratedObjectives: RatedObjective[]
): AssessmentSummaryGraphicData[] {
  return ratedObjectives.map((ratedObjective) => {
    const { masteryLevelScheme } = ratedObjective;

    const ratings = ratedObjective.ratingsForStudents;
    let assessmentScore = -1;
    let excused = false;

    if (ratings && ratings[userID] && masteryLevelScheme) {
      const rating = ratings[userID] as Rating[];
      const masteryLevelID = rating[rating.length - 1]?.masteryLevelID;

      // Given that the highest levels are index based, subtract from the length -1 to find
      // the level.
      const assessmentLevel = masteryLevelScheme.masteryLevels.findIndex(
        (ml) => ml.id === masteryLevelID
      );

      assessmentScore = masteryLevelScheme.masteryLevels[assessmentLevel]?.relativeOrderIndex ?? -1;
      excused =
        excused || !!masteryLevelScheme.masteryLevels[assessmentLevel]?.excludeInGradeCalculations;
    }

    return {
      id: `${ratedObjective.id}`,
      numSegments: ratedObjectives.length || 0,
      numLevels: getNumLevels(masteryLevelScheme),
      color: ratedObjective.color,
      assessmentScore,
      excused,
    };
  });
}

export function getGraphicDataForRatings(ratings: Rating[], allObjectives: Objective[]) {
  return allObjectives.map(({ id, masteryLevelScheme, color }) => {
    const rating = ratings.find((r) => r.objectiveID === id);

    let assessmentScore = -1;
    let excused = false;

    if (rating && masteryLevelScheme) {
      assessmentScore = masteryLevelScheme.masteryLevels.findIndex(
        (ml) => ml.id === rating.masteryLevelID
      );
      excused =
        assessmentScore >= 0 &&
        !!masteryLevelScheme.masteryLevels[assessmentScore]?.excludeInGradeCalculations;
    }

    return {
      id,
      numSegments: allObjectives.length,
      numLevels: getNumLevels(masteryLevelScheme),
      color,
      assessmentScore,
      excused,
    } as AssessmentSummaryGraphicData;
  });
}
