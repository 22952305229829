import PageContainer from "../components/_common/style/PageContainer";
import ViewVerticalCalendar from "../components/courses/[courseID]/dashboard/ViewVerticalCalendar";

const CourseHomeCalendarTab: React.FC = () => (
  <PageContainer header="Course Calendar">
    <ViewVerticalCalendar />
  </PageContainer>
);

export default CourseHomeCalendarTab;
