import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Header, Input, Placeholder, PlaceholderLine } from "semantic-ui-react";
import useBooleanState from "../../../../../../hooks/useBooleanState";
import { PollingActivityQuestion } from "../../../../../../models/polling/PollingActivityQuestion";
import { PollingActivityQuestionResponse } from "../../../../../../models/polling/PollingActivityQuestionResponse";
import { useStore } from "../../../../../../stores/store";
import { emptyID } from "../../../../../../utilities/submissionUtils";
import { isQuillEmpty } from "../../../../../../utilities/utils";
import FlexContainer from "../../../../../_common/style/FlexContainer";
import VerticalGap from "../../../../../_common/style/spacing/VerticalGap";
import TextEditor from "../../../../../_common/textEditing/TextEditor";
import "./PollingResponseForm.css";

interface Props {
  question: PollingActivityQuestion;
  response: PollingActivityQuestionResponse | undefined;
  disableNextButton: boolean;
  disablePreviousButton: boolean;
  handleNavigateToPreviousQuestion: () => void;
  handleNavigateToNextQuestion: () => void;
}

const PollingResponseFormSkeleton: React.FC = () => (
  <div className="PollingResponseFormSkeleton">
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
  </div>
);

const PollingResponseForm: React.FC<Props> = ({
  question,
  response,
  disableNextButton,
  disablePreviousButton,
  handleNavigateToNextQuestion,
  handleNavigateToPreviousQuestion,
}) => {
  const { pollingStore, userStore, toastStore } = useStore();
  const { user } = userStore;
  const { loadPollingQuestionTemplate, hasLoadedPollingQuestionTemplate, pollingQuestionTemplate } =
    pollingStore;
  const [submitting, setSubmitting] = useBooleanState();

  useEffect(() => {
    loadPollingQuestionTemplate(question.pollingQuestionTemplateID);
  }, [question, user?.userID]);

  if (!user) return <></>;

  if (
    !pollingQuestionTemplate ||
    !hasLoadedPollingQuestionTemplate(question.pollingQuestionTemplateID)
  )
    return <PollingResponseFormSkeleton />;

  const handleSubmit = async (values: PollingActivityQuestionResponse) => {
    setSubmitting(true);

    let { responseOptionID } = values;

    if (pollingQuestionTemplate.allowShortAnswer) {
      responseOptionID = pollingQuestionTemplate.responseOptions[0]?.id ?? emptyID;
    }

    const successful = await pollingStore.createOrUpdatePollingActivityQuestionResponse({
      ...values,
      id: response?.id ?? emptyID,
      responseTime: new Date(Date.now()),
      responseOptionID,
    });

    if (successful) {
      // If there is a next question and there isn't already a response (meaning that the user hasn't already answered this question) then navigate.
      if (!response) {
        handleNavigateToNextQuestion();
      }
    } else {
      toastStore.showToast("Something went wrong when submitting your answer.", { color: "red" });
    }

    setSubmitting(false);
  };

  const initialValues: PollingActivityQuestionResponse = {
    courseID: question.courseID,
    id: response?.id ?? emptyID,
    pollingActivityQuestionID: question.id,
    responseOptionID: response?.responseOptionID ?? "",
    responseTime: response?.responseTime ?? new Date(Date.now()),
    studentID: user.userID,
    text: response?.text ?? "",
    pollingActivityID: question.pollingActivityID,
  };

  const shouldDisableSubmitButton = (values: PollingActivityQuestionResponse) => {
    if (submitting) return true;

    if (
      pollingQuestionTemplate.allowShortAnswer &&
      !pollingQuestionTemplate.questionText.includes("word")
    ) {
      return isQuillEmpty(values.text) || (response && response.text === values.text);
    }

    if (
      pollingQuestionTemplate.allowShortAnswer &&
      pollingQuestionTemplate.questionText.includes("word")
    ) {
      return values.text.length > 0 && response && response.text === values.text;
    }

    return (
      !values.responseOptionID ||
      (response && values.responseOptionID === response.responseOptionID)
    );
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}>
      {({ values, setFieldValue }) => (
        <Form className="PollingResponseForm">
          <Header
            content={pollingQuestionTemplate.questionText}
            as="h4"
            className="question-text"
          />
          <VerticalGap height="1rem" />
          <FlexContainer flexDirection="column" gap="1rem">
            {pollingQuestionTemplate.allowShortAnswer &&
              !pollingQuestionTemplate.questionText.includes("word") && (
                <TextEditor
                  placeholder={"Enter response..."}
                  onChange={(value) => {
                    setFieldValue("text", value);
                  }}
                  defaultValue={response?.text ?? ""}
                  smallHeight
                />
              )}
            {pollingQuestionTemplate.allowShortAnswer &&
              pollingQuestionTemplate.questionText.includes("word") && (
                <Input
                  placeholder={"Enter response..."}
                  onChange={(event) => {
                    setFieldValue("text", event.target.value);
                  }}
                  defaultValue={response?.text ?? ""}
                />
              )}
            {!pollingQuestionTemplate.allowShortAnswer &&
              pollingQuestionTemplate.responseOptions.map((option) => (
                <Button
                  type="button"
                  key={option.id}
                  content={
                    option.description ? `${option.name}) ${option.description}` : option.name
                  }
                  color={values?.responseOptionID === option.id ? "blue" : "grey"}
                  onClick={() => {
                    setFieldValue("responseOptionID", option.id);
                  }}
                />
              ))}
          </FlexContainer>
          <VerticalGap height="1rem" />
          <FlexContainer flexDirection="row" justifyContent="space-between">
            <Button
              icon="arrow left"
              color="grey"
              disabled={disablePreviousButton}
              onClick={handleNavigateToPreviousQuestion}
            />
            <Button
              icon="checkmark"
              content={shouldDisableSubmitButton(values) && response ? "Submitted" : "Submit"}
              type="submit"
              color="blue"
              disabled={shouldDisableSubmitButton(values)}
            />
            <Button
              icon="arrow right"
              color="grey"
              floated="right"
              disabled={disableNextButton || !response || !response.responseOptionID}
              onClick={handleNavigateToNextQuestion}
            />
          </FlexContainer>
        </Form>
      )}
    </Formik>
  );
};

export default observer(PollingResponseForm);
