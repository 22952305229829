import { observable, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { Container, Header, Label } from "semantic-ui-react";
import { RatedObjective } from "../../../../models/RatedObjective";
import { Submission } from "../../../../models/Submission";
import { getColorForRating, isQuillEmpty } from "../../../../utilities/utils";
import HorizontalIndent from "../../../_common/style/spacing/HorizontalIndent";
import UserInputDisplay from "../../../_common/textEditing/UserInputDisplay";
import CourseObjective from "../syllabus/CourseObjective";
import "./SubmissionRatedObjective.css";

interface SubmissionRatedObjectiveProps {
  ratedObjective: RatedObjective;
  submissions: Submission[];
  userID: string;
  submissionID: string;
  scrolledTo: boolean;
}

const getRatingForObjective = (
  userID: string | undefined,
  ratedObjective: RatedObjective,
  submissionID: string | undefined,
  submissions: Submission[]
) => {
  if (!userID) {
    return undefined;
  }

  const submissionIndex = submissions.findIndex((s) => s.id === submissionID);
  for (let i = submissionIndex; i >= 0; i -= 1) {
    const ratings = ratedObjective.ratingsForStudents;

    // ensure ratings isn't null
    if (!ratings) return undefined;

    const mostRecentRating = ratings[userID]?.find((r) => r.submissionID === submissions[i]?.id);
    if (mostRecentRating) return mostRecentRating;
  }
  return undefined;
};

const SubmissionRatedObjective: React.FC<SubmissionRatedObjectiveProps> = ({
  ratedObjective,
  submissions,
  userID,
  submissionID,
  scrolledTo,
}) => {
  const ratingForObjective = getRatingForObjective(
    userID,
    ratedObjective,
    submissionID,
    submissions
  );
  const fromPreviousSubmission = ratingForObjective?.submissionID !== submissionID;
  const masteryLevelForRating = ratedObjective.masteryLevelScheme?.masteryLevels.find(
    (ml) => ml.id === ratingForObjective?.masteryLevelID
  );
  const objectiveColor = getColorForRating(
    ratedObjective.color,
    ratedObjective.masteryLevelScheme,
    masteryLevelForRating?.id
  );

  const toggled = useRef(
    observable.map(new Map([[ratedObjective.id, !fromPreviousSubmission]]))
  ).current;

  // value doesn't update when navigating to different submission, so we force the update here
  useEffect(() => {
    runInAction(() => {
      toggled.set(ratedObjective.id, !fromPreviousSubmission);
    });
  }, [fromPreviousSubmission, ratedObjective.id, submissionID, toggled]);

  const currentSubmission = submissions.find(({ id }) => id === submissionID);
  const reassessmentRequest = currentSubmission?.reassessmentRequests?.find(
    (rr) => rr.objectiveID === ratedObjective.id
  );

  return (
    <CourseObjective
      objective={ratedObjective}
      cardTypeLabel={ratingForObjective ? "Assessed Objective" : "Objective To Be Assessed"}
      className="SubmissionRatedObjective"
      enableCollapseWithArrowIcon
      collapsedByDefault={!ratingForObjective || fromPreviousSubmission}
      scrolledTo={scrolledTo}
    >
      <Header as="h5" className="rating-header">
        Rating:
        <Label horizontal className={`mastery-level ${objectiveColor}`}>
          {masteryLevelForRating?.name}
        </Label>
      </Header>
      {toggled.get(ratedObjective.id) && (
        <>
          {ratingForObjective && reassessmentRequest && reassessmentRequest.improvementSummary && (
            <Container className="objective-rating">
              <Header
                as="h5"
                content={"Summary of Improvement:"}
                className={"improvement-summary-header"}
              />
              <HorizontalIndent className={"feedback-body"}>
                <UserInputDisplay content={reassessmentRequest.improvementSummary} />
              </HorizontalIndent>
            </Container>
          )}
          {ratingForObjective && !isQuillEmpty(ratingForObjective.comment) && (
            <Container className="objective-rating">
              <Header
                as="h5"
                content={"Comments About This Objective:"}
                className={"feedback-header"}
              />
              {ratingForObjective?.comment && (
                <HorizontalIndent className={"feedback-body"}>
                  <UserInputDisplay content={ratingForObjective.comment} />
                </HorizontalIndent>
              )}
            </Container>
          )}
        </>
      )}
      {!ratingForObjective && <HorizontalIndent>No rating yet. Stay tuned!</HorizontalIndent>}
      {ratingForObjective && fromPreviousSubmission && (
        <HorizontalIndent className="previously-assessed">
          (assessed in previous submission)
        </HorizontalIndent>
      )}
    </CourseObjective>
  );
};

export default observer(SubmissionRatedObjective);
