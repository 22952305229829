import { Navigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../stores/store";
import TEACHFRONT_PATHS from "../../../../paths";
import { AppSkeleton } from "../../../../App";

// inspired by https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

const RequireAuthentication = ({ children }: { children: JSX.Element }) => {
  const { userStore } = useStore();
  const { user } = userStore;
  const location = useLocation();

  if (userStore.loading) {
    return <AppSkeleton />;
  }

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={TEACHFRONT_PATHS.Login} state={{ from: location }} replace />;
  }

  return children;
};

export default observer(RequireAuthentication);
