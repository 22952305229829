import { observer } from "mobx-react-lite";
import { RefObject, useRef, useState } from "react";
import "./UnauthenticatedLandingPage.css";
import VerticalGap from "./_common/style/spacing/VerticalGap";
import FeatureSection from "./_common/unauthenticatedLandingPage/FeatureSection";
import HeaderSection from "./_common/unauthenticatedLandingPage/HeaderSection";
import MasteryGradingSection from "./_common/unauthenticatedLandingPage/MasteryGradingSection";
import MeetTeamFront from "./_common/unauthenticatedLandingPage/MeetTeamFront";
import MissionStatement from "./_common/unauthenticatedLandingPage/MissionStatement";
import TestimonialSection from "./_common/unauthenticatedLandingPage/TestimonialSection";
import BackgroundWaves from "./_misc/BackgroundWaves";
import NavBarHomePage from "./_navbar/NavBarHomePage";

// Define a type for the mapping from div IDs to their ref objects
type RefMap = {
  [key: string]: RefObject<HTMLDivElement>;
};

const UnauthenticatedLandingPage = () => {
  // State to keep track of the current visible div
  const [divCurrentlyInViewbox, setDivCurrentlyInViewbox] = useState<string | null>(null);
  const isScrollingRef = useRef(false);

  const refMap: RefMap = {
    HeaderSection: useRef(null),
    TestimonialSection: useRef(null),
    MissionStatement: useRef(null),
    MasteryGradingSection: useRef(null),
    FeatureSection: useRef(null),
  };

  const scrollObserver = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      if (isScrollingRef.current) {
        return; // Ignore intersection events if scrolling is happening
      }

      let topMostEntry: IntersectionObserverEntry | null = null; // Start with no topmost entry
      let topMostEntryID: string | null = null; // Start with no topmost entry id

      // Iterate over the entries
      entries.forEach((entry: IntersectionObserverEntry) => {
        // Check if the entry is in the viewbox
        if (entry.isIntersecting) {
          // If topMostEntry is null or this entry is higher up (smaller top value), update topMostEntry
          if (!topMostEntry || entry.boundingClientRect.top < topMostEntry.boundingClientRect.top) {
            topMostEntry = entry;
            topMostEntryID = topMostEntry.target.id;
          }
        }
      });

      // If topMostEntry is found, update the state; otherwise, set to null
      setDivCurrentlyInViewbox(topMostEntryID || null);
    },
    { threshold: 0.1 }
  );

  // make sure all the refs (except the header) are observed by the scrollObserver
  Object.values(refMap).forEach((ref: RefObject<HTMLDivElement>) => {
    if (ref.current && ref !== refMap.HeaderSection) scrollObserver.observe(ref.current);
  });

  const scrollToDiv = (divID: string) => {
    const ref = refMap[divID];
    if (ref?.current) {
      // Indicate that a scroll action has been initiated
      isScrollingRef.current = true;

      setDivCurrentlyInViewbox(ref.current.id);
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });

      // reset scrolling after a set amount of time
      setTimeout(() => {
        isScrollingRef.current = false;
      }, 1000); // 1 second
    }
  };

  return (
    <div className="UnauthenticatedLandingPage">
      <BackgroundWaves />
      <NavBarHomePage divCurrentlyInViewbox={divCurrentlyInViewbox} scrollToDiv={scrollToDiv} />
      <HeaderSection ref={refMap.HeaderSection} />
      <TestimonialSection ref={refMap.TestimonialSection} />
      <MissionStatement ref={refMap.MissionStatement} />
      <VerticalGap height="5em" />
      <MasteryGradingSection ref={refMap.MasteryGradingSection} />
      <FeatureSection ref={refMap.FeatureSection} />
      <MeetTeamFront />
    </div>
  );
};

export default observer(UnauthenticatedLandingPage);
