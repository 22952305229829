import { Formik, FormikValues } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Container,
  Form,
  Header,
  Label,
  Message,
  Segment,
} from "semantic-ui-react";
import { AssignmentSubmissionFormat } from "../../../../models/Assignment";
import { ReassessmentRequest } from "../../../../models/ReassessmentRequest";
import { Submission } from "../../../../models/Submission";
import { useStore } from "../../../../stores/store";
import {
  deepCompareValues,
  objectMap,
  objectSize,
  objectSome,
} from "../../../../utilities/collectionUtils";
import buildPath from "../../../../utilities/routing/buildPath";
import LoadingComponent from "../../../../utilities/routing/components/LoadingComponent";
import PageReloadOrRedirectHandler from "../../../../utilities/routing/components/PageReloadOrRedirectHandler";
import {
  SubmissionPackage,
  getSubmissionInstructions,
} from "../../../../utilities/submissionUtils";
import { isQuillEmpty } from "../../../../utilities/utils";
import Tooltip from "../../../_common/misc/Tooltip";
import TextEditor from "../../../_common/textEditing/TextEditor";
import "./AssignmentReassessmentForm.css";
import SubmissionDataSelection from "./SubmissionDataSelection";

interface AssignmentReassessmentFormProps {
  courseID: string;
  assignmentID: string;
  allowedSubmissionFormat: AssignmentSubmissionFormat[];
  submissionPackage: SubmissionPackage;
  setSubmissionID?: (submissionID: string | undefined) => void;
  showingInAssignmentDetails?: boolean;
}

const objectiveCheckboxName = (id: string) => `${id}-request-reassessment`;
const objectiveImprovementSummaryName = (id: string) => `${id}-improvement`;

const AssignmentReassessmentForm: React.FC<AssignmentReassessmentFormProps> = ({
  courseID,
  assignmentID,
  submissionPackage,
  setSubmissionID,
  showingInAssignmentDetails,
  allowedSubmissionFormat,
}) => {
  const navigate = useNavigate();
  const { assignmentStore, submissionStore, userStore } = useStore();
  const {
    assignmentWithObjectivesAndMasteryLevels,
    loadAssignmentWithObjectivesAndMasteryLevels,
    hasLoadedAssignmentWithObjectivesAndMasteryLevels,
  } = assignmentStore;
  const { user } = userStore;
  const [savingAsDraft, setSavingAsDraft] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    loadAssignmentWithObjectivesAndMasteryLevels(assignmentID, courseID);
  }, [assignmentID, courseID, loadAssignmentWithObjectivesAndMasteryLevels]);

  if (!user) {
    return <LoadingComponent content="Loading submission content..." />;
  }

  if (
    !assignmentWithObjectivesAndMasteryLevels ||
    !hasLoadedAssignmentWithObjectivesAndMasteryLevels(assignmentID) ||
    !user
  )
    return <LoadingComponent content="Loading assignment content..." />;

  const { objectives } = assignmentWithObjectivesAndMasteryLevels;

  // Currently only ensures that if an object has a comment, it must have a mastery level selected.
  const validate = (values: FormikValues) => {
    const errors: FormikValues = {};

    objectives
      ?.filter(({ id }) => values[objectiveCheckboxName(id)])
      .forEach(({ id, shortName }) => {
        const comment = values[objectiveImprovementSummaryName(id)];

        if (!comment) {
          errors[
            objectiveImprovementSummaryName(id)
          ] = `You must enter a summary of improvement for the objective ${shortName}`;
        }
      });

    return errors;
  };

  const { submission: activeSubmission, isDraft: isPackageDraft } = submissionPackage;

  const initValues: FormikValues = {
    data: activeSubmission?.data ?? "",
  };

  // Fill the reassessment request values in
  assignmentWithObjectivesAndMasteryLevels.objectives?.forEach(({ id }) => {
    const rr = activeSubmission?.reassessmentRequests?.find((r) => r.objectiveID === id);

    initValues[objectiveCheckboxName(id)] = rr !== undefined;
    initValues[objectiveImprovementSummaryName(id)] = rr?.improvementSummary ?? "";
  });

  const handleFormSubmit = async (
    formValues: FormikValues,
    draft: boolean,
    onPageReload: boolean,
    preventNavigation?: boolean
  ) => {
    // If the page isn't being changed/reloaded, set the loading state for buttons
    if (!onPageReload) {
      if (draft) setSavingAsDraft(true);
      else setSubmitting(true);
    }

    const reassessmentRequests: ReassessmentRequest[] = [];

    objectives?.forEach(({ id }) => {
      if (!formValues[objectiveCheckboxName(id)]) return;

      const improvementSummary = formValues[objectiveImprovementSummaryName(id) as string];

      reassessmentRequests.push({
        assignmentID,
        courseID,
        improvementSummary,
        objectiveID: id,
        userID: user.userID,
      });
    });

    const data = isQuillEmpty(formValues.data) ? undefined : formValues.data;
    const { url } = formValues;

    const submission: Submission = {
      courseID,
      userID: user.userID,
      assignmentID,
      data,
      url,
      isDraft: draft,
      createdAt: new Date(Date.now()),
      reassessmentRequests,
      id: activeSubmission?.id,
      hasUploadedFiles: false,
    };
    const newSubmission = await submissionStore.newSubmission(submission, courseID);

    if (!preventNavigation) {
      submissionStore.reset();
    }

    if (showingInAssignmentDetails && setSubmissionID) {
      setSubmissionID(newSubmission.id);
      if (!onPageReload) {
        if (draft) setSavingAsDraft(false);
        else setSubmitting(false);
      }
    } else if (!preventNavigation)
      navigate(buildPath("CourseHomeAssignmentDetailsTab", { courseID, assignmentID }));

    return newSubmission.id as string;
  };

  return (
    <div className="AssignmentReassessmentForm">
      <Tooltip
        tooltip={getSubmissionInstructions(allowedSubmissionFormat)}
        style={{ position: "absolute", right: "1em" }}
      />
      <Formik
        initialValues={initValues}
        onSubmit={(formValues: FormikValues) => handleFormSubmit(formValues, false, false)}
        validate={validate}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => {
          const onReload = deepCompareValues(initValues, values)
            ? () => {}
            : () => handleFormSubmit(values, isPackageDraft && true, true);

          return (
            <Form className="ui form" onSubmit={handleSubmit}>
              <PageReloadOrRedirectHandler onReload={onReload} />
              <Header as="h4">Prepare Your Submission:</Header>
              <SubmissionDataSelection
                submissionTypes={allowedSubmissionFormat}
                fileUploadsSubmissionProps={
                  allowedSubmissionFormat.includes("FileUploads") && submissionPackage.submissionID
                    ? {
                        courseID,
                        userID: user.userID,
                        connectedComponentID: submissionPackage.submissionID,
                      }
                    : undefined
                }
                textSubmissionProps={
                  allowedSubmissionFormat.includes("Text")
                    ? { data: values.data, setFieldValue }
                    : undefined
                }
                urlSubmissionProps={
                  allowedSubmissionFormat.includes("URL")
                    ? {
                        url: values.url,
                      }
                    : undefined
                }
              />
              {assignmentWithObjectivesAndMasteryLevels.objectives?.map(
                ({ id, color, shortName, longName }) => (
                  <Segment key={id} className={"objective-container"}>
                    <Label className={`${color} objective-container-label`} size="large" ribbon>
                      {shortName}
                    </Label>
                    {longName}
                    <Container>
                      <Checkbox
                        className="objective-checkbox"
                        toggle
                        name={objectiveCheckboxName(id)}
                        label="Request Reassessment for this Objective"
                        onChange={(e, { checked }) =>
                          setFieldValue(objectiveCheckboxName(id), checked)
                        }
                        checked={values[objectiveCheckboxName(id)] === true}
                      />
                    </Container>
                    {values[objectiveCheckboxName(id)] && (
                      <>
                        <Header as="h4" content="Summary of Improvement" />
                        <TextEditor
                          defaultValue={values[objectiveImprovementSummaryName(id)]}
                          onChange={(v) => setFieldValue(objectiveImprovementSummaryName(id), v)}
                          placeholder="How did your work improve? Tell your rater here..."
                          container={`toolbar-${objectiveImprovementSummaryName(id)}`}
                        />
                      </>
                    )}
                    {errors[objectiveImprovementSummaryName(id)] && (
                      <Message
                        negative
                        content={errors[objectiveImprovementSummaryName(id)] as string}
                      />
                    )}
                  </Segment>
                )
              )}
              {errors && objectSize(errors) > 0 && (
                <Message negative list={objectMap(errors, (key, value) => value as string)} />
              )}
              <Button
                disabled={
                  !objectSome(
                    values,
                    (key, value) =>
                      key.toString().includes("request-reassessment") && value === true
                  ) ||
                  (errors && objectSize(errors) > 0)
                }
                content={"Submit Reassessment Request"}
                type="submit"
                color="blue"
                floated="right"
                loading={submitting}
              />
              {isPackageDraft && (
                <Button
                  disabled={deepCompareValues(initValues, values)}
                  content={"Save as Draft"}
                  onClick={() => handleFormSubmit(values, true, false)}
                  color="green"
                  floated="right"
                  type="button"
                  loading={savingAsDraft}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default observer(AssignmentReassessmentForm);
