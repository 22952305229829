import { Header } from "semantic-ui-react";
import Color from "../../models/Color";
import ColorDisplayBox from "../_common/form/ColorDisplayBox";
import FlexContainer from "../_common/style/FlexContainer";
import "./ColorPickerModal.css";

interface ColorPickerModalProps {
  currentColor: Color;
  onColorSelect: (newColor: Color) => void;
}

const ColorPickerModal: React.FC<ColorPickerModalProps> = ({ onColorSelect }) => (
  <div className="ColorPickerModal">
    <Header content="Choose a color:" />
    <FlexContainer flexWrap="wrap" gap="0.5em">
      {Object.values(Color).map((color) => (
        <ColorDisplayBox color={color} onClick={() => onColorSelect(color as Color)} key={color} />
      ))}
    </FlexContainer>
  </div>
);

export default ColorPickerModal;
