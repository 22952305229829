import { Assignment, AssignmentSubmissionFormat } from "../models/Assignment";
import { Feedback } from "../models/Feedback";
import { Objective } from "../models/Objective";
import { RatedObjective } from "../models/RatedObjective";
import { Rating } from "../models/Rating";
import { Submission } from "../models/Submission";
import { getTime, getUTCDate } from "./dateTimeUtils";

/**
 * Creates a map of potential Rating objects for a user under a submission id from an array of RatedObjective.
 * It is possible the map will be empty if the RatedOjective[] contains no ratings for the user under the submissionID.
 * @param userID the user to check ratings for.
 * @param submissionID the submission to check ratings for.
 * @param ratedObjectives the RatedObjectives[] to loop through and find ratings for.
 * @returns a map (objectiveID -> Rating)
 */
export function getRatingsForSubmissionFromRatedObjectives(
  userID: string,
  submissionID: string,
  ratedObjectives: RatedObjective[] | undefined
): Map<string, Rating> {
  const ratingMap = new Map<string, Rating>();

  if (!ratedObjectives) return ratingMap;

  ratedObjectives
    .filter(
      // Filter to ensure there are ratings in this objective for this student
      (ratedObjective) =>
        ratedObjective.ratingsForStudents && ratedObjective.ratingsForStudents[userID]
    )
    .forEach((ratedObjective) => {
      const ratings = ratedObjective.ratingsForStudents[userID];

      // Find a rating by submissionID
      const potentialRating = ratings?.find((r) => r.submissionID === submissionID);

      // Set the rating in the map
      if (potentialRating && Object.hasOwn(ratedObjective, "id"))
        ratingMap.set(ratedObjective.id, potentialRating);
    });

  return ratingMap;
}

/**
 * Convenience method to check if a submission has been assessed in an Assignment of RatedObjectives.
 * @param userID the userID to check for assessment.
 * @param submissionID the submission id to check for assessment.
 * @param ratedObjectives an array of RatedObjectives to check for assessment.
 * @returns true if the RatedObjective array has any ratings for any objective for the submission, false otherwise.
 */
export function isSubmissionAssessed(
  userID: string,
  submissionID: string,
  ratedObjectives: RatedObjective[]
) {
  return ratedObjectives.some(
    ({ ratingsForStudents }) =>
      ratingsForStudents &&
      ratingsForStudents[userID] &&
      ratingsForStudents[userID]?.some((r) => r.submissionID === submissionID)
  );
}

/**
 * Finds if there are ratings for an assignment for a student that are not published.
 *
 * Prerequisites: there must be at least one rating made for the student.
 * @param studentID the student to check for.
 * @param ratedObjectives the assignment's objectives to check for.
 * @returns true if there are unpublished ratings, false otherwise.
 */
export function areAnyAssignmentRatingsUnpublished(
  studentID: string,
  ratedObjectives: RatedObjective[] | undefined
) {
  if (!ratedObjectives) {
    return false;
  }

  const filteredObjectives = ratedObjectives
    .filter(
      (ratedObjective) =>
        ratedObjective.ratingsForStudents && ratedObjective.ratingsForStudents[studentID]
    )
    .map(({ ratingsForStudents }) => ratingsForStudents[studentID] as Rating[]);

  if (filteredObjectives.length === 0) {
    return false;
  }

  return filteredObjectives.some((ratings) => ratings.some(({ isDraft }) => isDraft !== false));
}

/**
 * Convenience method to check if a submission has been published. Ideally, this method would be used after checking
 * if ratings exist in the first place, as this would return false if ratings don't exist in general.
 * @param userID the userID to check for ratings that have been published.
 * @param submissionID the submission id to check for ratings that are associated.
 * @param ratedObjectives an array of RatedObjectives that ratings might exist under.
 * @returns true if there are ratings that exist for the user that have all been published under this submission, false otherwise.
 */
export function isSubmissionAssessmentPublished(
  userID: string,
  submissionID: string | undefined,
  ratedObjectives: RatedObjective[] | undefined,
  feedback?: Feedback
) {
  if (!ratedObjectives) {
    return false;
  }

  const filteredRatings = ratedObjectives
    .filter((ro) => ro.ratingsForStudents && ro.ratingsForStudents[userID])
    .map((ro) => ro.ratingsForStudents[userID] as Rating[])
    .map((ratings) => ratings.find((r) => r.submissionID === submissionID))
    .filter((r) => r !== undefined);

  // If there are no ratings present, the submission has not been assessed/published.
  if (filteredRatings.length === 0) {
    return false;
  }

  return (
    filteredRatings.every((r) => r?.isDraft === false) && (!feedback || feedback.isDraft === false)
  );
}

/**
 * Convenience method to tell if a submission is requesting reassessment.
 * @param submission the submission to check for.
 * @returns true if the submission has one or more reassessment requests.
 */
export function isSubmissionRequestingReassessment(submission?: Submission) {
  return (
    submission !== undefined &&
    submission.reassessmentRequests !== undefined &&
    submission.reassessmentRequests.length > 0
  );
}

export type SubmissionType = "submission" | "resubmission";

export type SubmissionPackage = {
  submissionID: string | undefined;
  submission: Submission | undefined;
  submissionType: SubmissionType;
  isEditable: boolean;
  isDraft: boolean;
  selected: boolean;
  userID: string;
  courseID: string;
  assignmentID: string;
  willBeLateIfEdited: boolean;
  isSubmissionAlreadyLate: boolean;
};

/**
 * Creates the appropriate data needed for assignment submission details.
 * @param userID the userID viewing the submission.
 * @param submissions the list of submissions for the user under the assignment.
 * @param assignment the assignment to get ratings and due dates from.
 * @param submissionID the id of the current submission, if applicable.
 * @param courseID the id of the course.
 * @param assignmentID the id of assignment that the submissions belong to.
 * @param isUserStudent boolean value representing if the user is a student.
 * @returns a list of submission packages that provide the necessary information for filling out submission details.
 */
export function getSubmissionPackage(
  userID: string,
  submission: Submission,
  assignment: Assignment<Objective>,
  isUserStudent: boolean,
  firstSubmission: boolean,
  selectedSubmissionID?: string
): SubmissionPackage {
  const isWithinDueDate = (date: Date): boolean =>
    getUTCDate(new Date(getTime())).getTime() < getUTCDate(date).getTime();

  const { hasRatings, id, isDraft } = submission;
  const submissionType = firstSubmission ? "submission" : "resubmission";
  let isEditable: boolean = !hasRatings && isUserStudent;
  let willBeLateIfEdited = false;

  // Check the due dates before saying if the submission is editable.
  if (submissionType === "submission") {
    const isAssignmentWithinDueDate = !!assignment.dueDate && isWithinDueDate(assignment.dueDate);
    const isAssignmentWithinLateSubmissionDueDate =
      !!assignment.acceptSubmissionsUntilDate &&
      isWithinDueDate(assignment.acceptSubmissionsUntilDate);
    isEditable = !!(
      isEditable &&
      (isAssignmentWithinDueDate || isAssignmentWithinLateSubmissionDueDate)
    );
    willBeLateIfEdited = !isAssignmentWithinDueDate && isAssignmentWithinLateSubmissionDueDate;
  }

  if (submissionType === "resubmission")
    isEditable =
      isEditable &&
      assignment.resubmissionDueDate !== undefined &&
      isWithinDueDate(assignment.resubmissionDueDate);

  return {
    submissionID: id,
    submission,
    submissionType,
    isEditable,
    isDraft: !hasRatings && isDraft,
    selected: selectedSubmissionID === id,
    userID,
    assignmentID: assignment.id,
    courseID: assignment.courseID,
    willBeLateIfEdited,
    isSubmissionAlreadyLate: !!assignment.dueDate && submission.createdAt > assignment.dueDate,
  } as SubmissionPackage;
}

/**
 * Creates the appropriate data needed for assignment submission details.
 * @param userID the userID viewing the submission.
 * @param submissions the list of submissions for the user under the assignment.
 * @param assignment the assignment to get ratings and due dates from.
 * @param submissionID the id of the current submission, if applicable.
 * @param courseID the id of the course.
 * @param assignmentID the id of assignment that the submissions belong to.
 * @param isUserStudent boolean value representing if the user is a student.
 * @returns a list of submission packages that provide the necessary information for filling out submission details.
 */
export function getSubmissionPackages(
  userID: string,
  submissionID: string | undefined,
  submissions: Submission[],
  assignment: Assignment<Objective>,
  isUserStudent: boolean
): SubmissionPackage[] {
  const packages: SubmissionPackage[] = submissions.map((submission, index) =>
    getSubmissionPackage(userID, submission, assignment, isUserStudent, index === 0, submissionID)
  );
  // If there are packages and none of them match the submissionID, set the last one to be selected.
  if (packages.length > 0) {
    // If every package is not selected, select the most recent one.
    if (packages.every((p) => !p.selected)) {
      (packages[packages.length - 1] as SubmissionPackage).selected = true;
    }
  }

  return packages;
}

/**
 * With a list of AssignmentSubmissionFormats, this method creates a user friendly tooltip to be displayed on submission forms.
 * @param submissionTypes the accepted formats of submissions for the current assignment.
 */
export function getSubmissionInstructions(submissionTypes: AssignmentSubmissionFormat[]) {
  const noSubmissionMessage =
    "You don't need to submit anything to TeachFront for this assignment.";
  const fileUploadMessage =
    "To prepare your submission, attach files using the selection tool below.";
  const textMessage = "To prepare your submission, use the text box below.";
  const urlMessage = "To prepare your submission, enter a link in the box below.";
  const fileUploadAndTextMessage =
    "To prepare your submission, you can attach files using the selection tool and/or use the text box.";
  const fileUploadAndURLMessage =
    "To prepare your submission, you can attach files using the selection tool and/or enter a link.";
  const textAndURLMessage =
    "To prepare your submission, you can use the text box and/or enter a link.";
  const fileUploadAndURLAndText =
    "To prepare your submission, you can use the text box, attach files using the selection tool, and/or enter a link.";

  if (submissionTypes.length === 0) {
    return noSubmissionMessage;
  }

  if (submissionTypes.length === 1) {
    switch (submissionTypes[0] as AssignmentSubmissionFormat) {
      case "Text":
        return textMessage;
      case "FileUploads":
        return fileUploadMessage;
      case "URL":
        return urlMessage;
      default:
        return noSubmissionMessage;
    }
  }

  if (submissionTypes.includes("Text") && submissionTypes.includes("FileUploads")) {
    return fileUploadAndTextMessage;
  }
  if (submissionTypes.includes("Text") && submissionTypes.includes("URL")) {
    return textAndURLMessage;
  }
  if (submissionTypes.includes("FileUploads") && submissionTypes.includes("URL")) {
    return fileUploadAndURLMessage;
  }
  if (
    submissionTypes.includes("FileUploads") &&
    submissionTypes.includes("URL") &&
    submissionTypes.includes("Text")
  ) {
    return fileUploadAndURLAndText;
  }

  return noSubmissionMessage;
}

/**
 * Finds whether a submission comes first in an array of submissions.
 */
export function isFirstSubmission(submission: Submission, allSubmissions: Submission[]) {
  return allSubmissions.findIndex((s) => s.id === submission.id) === 0;
}

export const emptyID = "00000000-0000-0000-0000-000000000000";
