import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import { useStore } from "../../stores/store";
import "./ScrollToTopButton.css";

// from https://www.geeksforgeeks.org/how-to-create-a-scroll-to-top-button-in-react-js/

const ScrollToTopButton: React.FC = () => {
  const { courseStore } = useStore();
  const { course } = courseStore;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setVisible(true);
      } else if (scrolled <= 300) {
        setVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisible);

    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return visible ? (
    <div className="ScrollToTopButton">
      <Icon
        name="arrow up"
        fitted
        circular
        inverted
        className={course ? course.color : "blue"}
        onClick={scrollToTop}
      />
    </div>
  ) : (
    <></>
  );
};

export default observer(ScrollToTopButton);
