import { NavLink, NavLinkProps } from "react-router-dom";
import buildPath from "../buildPath";
import TEACHFRONT_PATHS from "../../../paths";
import { TeachFrontPageName, TeachFrontPathParams } from "../types";

interface Props<TPage extends TeachFrontPageName> extends NavLinkProps {
  to: TPage;
  // eslint-disable-next-line prettier/prettier
  params: TeachFrontPathParams<typeof TEACHFRONT_PATHS[TPage]>;
}

const TeachFrontNavLink = <TPage extends TeachFrontPageName>(props: Props<TPage>) => (
  <NavLink {...props} to={buildPath(props.to, props.params)}>
    {props.children}
  </NavLink>
);

export default TeachFrontNavLink;
