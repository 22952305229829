// eslint-disable-next-line import/no-cycle
import api, { ApiCallOptions } from "../api/api";
import { AssignedRater } from "../models/AssignedRater";
import { StoreValue } from "./storeValue";

export default class AssignedRaterStore {
  private assignedRatersByStudentRegistry = new StoreValue<
    Map<string, AssignedRater>,
    { courseID: string; assignmentID: string }
  >();

  loadAssignedRatersByStudent = async (
    courseID: string,
    assignmentID: string,
    studentIDs: string[],
    apiCallOptions?: ApiCallOptions
  ) => {
    if (
      !apiCallOptions?.overrideIfFresh &&
      this.assignedRatersByStudentRegistry.fresh(true, { courseID, assignmentID })
    )
      return;

    this.assignedRatersByStudentRegistry.setLoading(true, { courseID, assignmentID });

    const assignedRaters = await api.AssignedRaters.listAssignedRatersForStudents(
      courseID,
      assignmentID,
      studentIDs
    );

    this.assignedRatersByStudentRegistry.setAll(new Map<string, AssignedRater>(), {
      courseID,
      assignmentID,
    });
    assignedRaters.forEach((ar: AssignedRater) => {
      this.assignedRatersByStudentRegistry.ifPresent((v) => v.set(ar.studentID, ar));
    });

    this.assignedRatersByStudentRegistry.setLoading(false);
  };

  hasLoadedAssignedRatersByStudent = (courseID: string, assignmentID: string) =>
    !this.assignedRatersByStudentRegistry.isLoading() &&
    this.assignedRatersByStudentRegistry.fresh(false, { courseID, assignmentID });

  get assignedRatersByStudent() {
    return this.assignedRatersByStudentRegistry.value;
  }

  assignRater = async (
    courseID: string,
    assignmentID: string,
    studentIDs: string[],
    raterID: string
  ) => {
    const updatedAssignedRaters = await api.AssignedRaters.createOrUpdate(
      courseID,
      assignmentID,
      studentIDs,
      raterID
    );

    if (updatedAssignedRaters && updatedAssignedRaters.length > 0) {
      const newAssignedRaters = new Map(this.assignedRatersByStudentRegistry.value);

      updatedAssignedRaters.forEach((ar) => {
        newAssignedRaters.set(ar.studentID, ar);
      });

      this.assignedRatersByStudentRegistry.setValue(newAssignedRaters);
    }
  };

  assignRaterForAllSubmissionsForAssignment = async (
    courseID: string,
    assignmentID: string,
    raterID: string
  ) => {
    await api.AssignedRaters.setAssignedRaterForAssignment(courseID, assignmentID, raterID);
  };

  reset = () => {
    this.assignedRatersByStudentRegistry.reset();
  };
}
