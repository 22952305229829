import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import useToast from "../../../../hooks/useToast";
import { Assignment } from "../../../../models/Assignment";
import { RatedObjective } from "../../../../models/RatedObjective";
import { useStore } from "../../../../stores/store";
import PublishModal from "../../../_modal/PublishModal";
import CreateOrEditAssessment from "../_modal/assessment/CreateOrEditAssessment";
import "./AssignmentDropdown.css";

interface AssignmentDropdownProps {
  assignment: Assignment<RatedObjective>;
  courseID: string;
}

const AssignmentDropdown: React.FC<AssignmentDropdownProps> = ({ assignment, courseID }) => {
  const { modalStore, userStore, submissionStore, gradeStore } = useStore();
  const setToast = useToast();
  const { name } = assignment;

  const { user } = userStore;

  // This condition should never be true
  if (!user) return <></>;

  return (
    <Dropdown text={name} className="AssignmentDropdown">
      <Dropdown.Menu>
        <Dropdown.Item
          icon="edit"
          text="Assess for All Students"
          onClick={() =>
            modalStore.openModal(
              <CreateOrEditAssessment
                assignment={assignment}
                courseID={courseID}
                user={user}
                isSettingDefaultAssessment={true}
                onSubmit={(success) => {
                  modalStore.closeModal();
                  gradeStore.resetGradeDistinctions();
                  submissionStore.reset();

                  if (success) {
                    setToast(`Set default ratings for assignment: ${assignment.name}.`, "green");
                  } else {
                    setToast(
                      `No ratings were updated for assignment: ${assignment.name} when setting default ratings`,
                      "orange"
                    );
                  }
                }}
                onCancel={() => modalStore.closeModal()}
              />,
              { showCloseIcon: true }
            )
          }
        />
        <Dropdown.Item
          icon="upload"
          text="Publish Ratings"
          onClick={() => {
            modalStore.openModal(
              <PublishModal
                assignmentID={assignment.id}
                assignmentName={assignment.name || ""}
                courseID={courseID}
              />
            );
          }}
        />
        <Dropdown.Item
          icon="clipboard list"
          text="View Assignment Details"
          as={Link}
          to={`/courses/${courseID}/assignments/${assignment.id}`}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default observer(AssignmentDropdown);
