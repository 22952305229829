import Hub from "../api/hub";
import { Observer } from "../models/Observer";
import PredefinedAssessmentComment from "../models/PredefinedAssessmentComment";
import { store } from "../stores/store";

export default class PredefinedAssessmentCommentsHubObserver implements Observer {
  constructor() {
    Hub.addObserver(this);
  }

  update(): void {
    const hubInstance = Hub.getCurrentInstance();
    const userID = store.userStore.user?.userID;
    if (!userID) return;

    // Some comments were created/edited
    hubInstance.listenForHook(
      "PredefinedAssessmentCommentsCreatedOrEdited",
      (comments: PredefinedAssessmentComment[]) => {
        store.predefinedAssessmentCommentStore.recordSomeCommentsCreatedOrUpdated(comments);
      }
    );

    // All comments were created/edited
    hubInstance.listenForHook(
      "PredefinedAssessmentCommentsAllCreatedOrEdited",
      (comments: PredefinedAssessmentComment[]) => {
        store.predefinedAssessmentCommentStore.recordAllCommentsCreatedOrUpdated(comments);
      }
    );

    // A comment was deleted
    hubInstance.listenForHook(
      "PredefinedAssessmentCommentDeleted",
      (comment: PredefinedAssessmentComment) => {
        store.predefinedAssessmentCommentStore.recordCommentDeleted(comment);
      }
    );
  }
}
