import { useEffect } from "react";
// eslint-disable-next-line import/no-cycle
import { useStore } from "../stores/store";
import CourseRoles from "../utilities/routing/components/routeAuthorizationExtensions/CourseRoles";

/**
 * Hook that provides an easy way to check if the current user's role is contained under an inputted roles list.
 * @param courseID the courseID to check for.
 * @returns a function that takes a course role string array as an input and returns true if the course roles (the string array) includes that role.
 */
export default function useCurrentUserCourseRole(courseID: string) {
  const { courseStore, userStore } = useStore();
  const { user } = userStore;
  const { courseRoleForCurrentUser, loadCourseRoleForCurrentUser } = courseStore;

  useEffect(() => {
    if (user) loadCourseRoleForCurrentUser(courseID, user.userID);
  });

  return {
    checkUserRole: (courseRoles: string[]) => courseRoles.includes(courseRoleForCurrentUser || ""),
    isUserTeachingTeam: CourseRoles.TeachingTeamRoles.includes(courseRoleForCurrentUser || ""),
    isUserReassessmentManager: CourseRoles.ResubmissionManagerRoles.includes(
      courseRoleForCurrentUser || ""
    ),
    isUserStudent: CourseRoles.StudentRoles.includes(courseRoleForCurrentUser || ""),
    isUserInstructor: CourseRoles.InstructorRoles.includes(courseRoleForCurrentUser || ""),
  };
}
