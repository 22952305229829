import { Form, Formik, FormikValues } from "formik";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Button, Header, Image } from "semantic-ui-react";
import logo from "../../assets/logo.svg";
import { useStore } from "../../stores/store";
import "./RegisterPage.css";
import FormLogoInput from "../_common/form/FormLogoInput";
import VerticalGap from "../_common/style/spacing/VerticalGap";
import { invalidPasswordMessage, isValidEmail, isValidPassword } from "../../utilities/utils";
import { objectEvery } from "../../utilities/collectionUtils";
import TEACHFRONT_PATHS from "../../paths";

const RegisterForm: React.FC = () => {
  const { userStore } = useStore();
  const navigate = useNavigate();

  const validate = (values: FormikValues) => {
    const errors: {
      firstName?: string;
      lastName?: string;
      email?: string;
      password?: string;
      confirmPassword?: string;
      errors?: string;
    } = {};
    if (values.firstName.length === 0) errors.firstName = "You must enter your first name";
    if (values.lastName.length === 0) errors.lastName = "You must enter your last name";
    if (!isValidEmail(values.email)) errors.email = "You must enter a valid email";
    if (!isValidPassword(values.password)) errors.password = invalidPasswordMessage;
    if (values.password !== values.confirmPassword)
      errors.confirmPassword = "Your passwords must match";

    return errors;
  };

  return (
    <Formik
      validate={validate}
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        error: null,
      }}
      initialTouched={{}}
      initialErrors={{}}
      onSubmit={(values, { setErrors }) =>
        userStore
          .register(
            {
              firstName: values.firstName,
              lastName: values.lastName,
              password: values.password,
              email: values.email,
            },
            {
              400: (status, message) => setErrors({ error: message }),
              401: (status, message) => setErrors({ error: message }),
            }
          )
          .then((success) => success && navigate(TEACHFRONT_PATHS.ConfirmEmail))
      }
    >
      {({ handleSubmit, isSubmitting, errors, touched, values }) => (
        <Form className="RegisterPage ui form" onSubmit={handleSubmit} autoComplete="off">
          <Image src={logo} centered size="small" />
          <Header as="h2" content="Create an Account" textAlign="center" />
          <div className="content">
            <FormLogoInput
              type={"text"}
              label={"First Name"}
              name={"firstName"}
              placeholder={"Enter your first name..."}
              iconName={"user circle"}
              required={true}
              error={touched.firstName && errors.firstName}
            />
            <FormLogoInput
              type={"text"}
              label={"Last Name"}
              name={"lastName"}
              placeholder={"Enter your last name..."}
              iconName={"user circle"}
              required={true}
              error={touched.lastName && errors.lastName}
            />
            <FormLogoInput
              type={"email"}
              label={"Email"}
              name={"email"}
              placeholder={"Enter your email..."}
              iconName={"mail"}
              required={true}
              error={touched.email && errors.email}
            />
            <FormLogoInput
              type={"password"}
              label={"Password"}
              name={"password"}
              placeholder={"Enter your password..."}
              iconName={"lock"}
              required={true}
              error={touched.password && errors.password}
            />
            <FormLogoInput
              type={"password"}
              label={"Confirm Password"}
              name={"confirmPassword"}
              placeholder={"Confirm your password..."}
              iconName={"lock"}
              required={true}
              error={touched.confirmPassword && errors.confirmPassword}
            />
            {errors.error && <span className="error">Error: {errors.error}</span>}
            <VerticalGap height={"1em"} />
            <Button
              loading={isSubmitting}
              content="Register"
              type="submit"
              color="blue"
              fluid
              disabled={!objectEvery(validate(values), (_, value) => !value)}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default observer(RegisterForm);
