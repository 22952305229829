import { useState } from "react";
import { Icon } from "semantic-ui-react";
import { Params, useNavigate, useParams } from "react-router-dom";
import { SidebarItemNavigation, SidebarRow, SidebarSubItem } from "../../../models/sidebar";
import useCourseColor from "../../../hooks/useCourseColor";
import Color from "../../../models/Color";
import FlexContainer from "../style/FlexContainer";
import { createClassName, doesRouteMatchTeachFrontPath } from "../../../utilities/utils";
import "./Sidebar.css";
import buildPath from "../../../utilities/routing/buildPath";
import { objectEvery } from "../../../utilities/collectionUtils";

interface SidebarProps {
  sidebarData: SidebarRow[];
}

// A navigation item is selected if its route matches the TeachFront path and all of its defined route params match the current route params
function isNavigationItemSelected(
  item: Partial<SidebarItemNavigation>,
  routeParams: Readonly<Params<string>>
) {
  // If the current route doesn't match the route of the item, this item is not selected
  if (!item.route || !doesRouteMatchTeachFrontPath(item.route)) return false;

  // If the item has no more params, we dont need to do any more checking
  if (!item.params) return true;

  return objectEvery(
    item.params,
    (paramKey, paramValue) => routeParams[paramKey as string] === paramValue
  );
}

// A row is active if the row itself is selected or any of its sub items or hidden sub items are selected
function isRowActive(sidebarRow: SidebarRow, routeParams: Readonly<Params<string>>) {
  if (isNavigationItemSelected(sidebarRow, routeParams)) return true;

  return (
    !!sidebarRow.subItems?.some((s) => isNavigationItemSelected(s, routeParams)) ||
    !!sidebarRow.hiddenSubItems?.some((s) => isNavigationItemSelected(s, routeParams))
  );
}

const Sidebar = ({ sidebarData }: SidebarProps) => {
  const routeParams = useParams(); // get the route params so they can be used when navigating & when passing to subItems to tell if they are selected
  const navigate = useNavigate();
  const [openSubMenuIndexes, setOpenSubMenuIndexes] = useState<number[]>(
    sidebarData
      .map((row, index) => (row.subItems && isRowActive(row, routeParams) ? index : -1)) // handy way to quickly get indexes of open rows
      .filter((index) => index !== -1)
  );
  const courseColor = useCourseColor(Color.GREY);

  // Navigates to the new page. Replaces any potential URL slugs (like :courseID) with the actual courseID.
  const navigateToNewPage = (navigation: Partial<SidebarItemNavigation>) => {
    if (!navigation.route) return;

    navigate(
      // Include the existing route params and any custom params defined by the navigation item. Params defined in the navigation item will override the existing params if the param names match.
      buildPath(navigation.route, { ...routeParams, ...(navigation.params ?? {}) } as Record<
        string,
        string
      >)
    );
  };

  const handleRowClick = (item: SidebarRow, itemIndex: number) => {
    if (item.route) {
      navigateToNewPage(item);
    } else if (item.subItems && item.subItems.length) {
      // Add/remove open sub menu index
      let newOpenSubMenuIndexes = [...openSubMenuIndexes];
      if (newOpenSubMenuIndexes.includes(itemIndex)) {
        newOpenSubMenuIndexes = newOpenSubMenuIndexes.filter((i) => i !== itemIndex);
      } else {
        newOpenSubMenuIndexes.push(itemIndex);
      }
      setOpenSubMenuIndexes(newOpenSubMenuIndexes);
    }
  };

  const handleSubItemClick = (item: SidebarSubItem) => {
    navigateToNewPage(item);
  };

  function getSidebarParentExpandedIcon(parent: SidebarRow, index: number): React.ReactNode {
    if (!parent.subItems?.length) return <></>;

    if (openSubMenuIndexes.includes(index)) return <Icon name="angle down" />;

    return <Icon name="angle right" />;
  }

  return (
    <FlexContainer flexDirection="column" className="Sidebar">
      <FlexContainer flexDirection="column" gap="1.5rem" className="sidebar-menu">
        {sidebarData.map((item, index) => (
          <div role="button" key={index}>
            <div
              className={createClassName("sidebar-menuItem")}
              onClick={() => handleRowClick(item, index)}
            >
              <div className="hover-indicator" />
              {isRowActive(item, routeParams) && (
                <div
                  className="active-indicator"
                  style={{ backgroundColor: `var(--${courseColor}-4)` }}
                />
              )}
              <Icon name={item.icon} />
              <span>
                {item.header} {getSidebarParentExpandedIcon(item, index)}
              </span>
            </div>
            {openSubMenuIndexes.includes(index) && item.subItems && (
              <div className="sub-container">
                {item.subItems.map((subItem, subIndex) => (
                  <div
                    role="button"
                    key={subIndex}
                    className={createClassName("sidebar-subMenuItem", {
                      name: "active-sub",
                      apply: isNavigationItemSelected(subItem, routeParams),
                    })}
                    onClick={() => handleSubItemClick(subItem)}
                    style={
                      isNavigationItemSelected(subItem, routeParams)
                        ? { borderColor: `var(--${courseColor}-2)` }
                        : {}
                    }
                  >
                    {isNavigationItemSelected(subItem, routeParams) && (
                      <div
                        style={{
                          backgroundColor: `var(--${courseColor}-2)`,
                          borderRight: `var(--${courseColor}--2)`,
                        }}
                      />
                    )}
                    <span className="sub-icon">
                      <Icon name={subItem.icon} />
                    </span>
                    <span>{subItem.header}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Sidebar;
