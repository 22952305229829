// code copied directly from
// https://github.com/Dhaiwat10/react-link-preview/blob/master/src/components/LinkPreview/Skeleton.tsx

import React from "react";
import LoadingSkeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./URLPreviewSkeleton.css";

interface URLPreviewSkeletonProps {
  width?: string | number;
  imageHeight?: string | number;
  margin?: string | number;
}

const URLPreviewSkeleton: React.FC<URLPreviewSkeletonProps> = ({
  width = "100%",
  imageHeight = "35vh",
  margin,
}) => (
  <div className="URLPreviewSkeleton" style={{ width, margin }}>
    <LoadingSkeleton className="skeleton-image" width={width} height={imageHeight} />
    <div className="skeleton-lower-container">
      <LoadingSkeleton count={3} />
    </div>
  </div>
);

export default URLPreviewSkeleton;
