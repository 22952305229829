import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Icon, Label } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../hooks/useValidParameters";
import { Assignment } from "../../../../models/Assignment";
import { Objective } from "../../../../models/Objective";
import { useStore } from "../../../../stores/store";
import VerticalGap from "../../../_common/style/spacing/VerticalGap";
import CreateOrEditAssignment from "../_modal/assignment/CreateOrEditAssignment";
import "./AssignmentsSidebar.css";
import { AssignmentType } from "../../../../models/AssignmentType";
import FlexContainer from "../../../_common/style/FlexContainer";
import CreatePollingSession from "./CreateOrEditPollingAssignment";
import useCourseColor from "../../../../hooks/useCourseColor";
import Color from "../../../../models/Color";
import FilterLabel from "../../../_common/form/FilterLabel";

interface AssignmentFilterProps {
  assignmentsByCourse: Assignment<Objective>[];
  assignmentTypes: AssignmentType[];
}

const AssignmentFilter: React.FC<AssignmentFilterProps> = ({
  assignmentsByCourse,
  assignmentTypes,
}) => {
  const { courseID } = useValidParams<{ courseID: string }>();
  const { objectiveStore, modalStore, persistentPreferenceStore } = useStore();
  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);
  const { allObjectives, hasLoadedAllObjectives, loadAllObjectives } = objectiveStore;
  const { assignmentFilter, updateAssignmentFilter } = persistentPreferenceStore;
  const courseColor = useCourseColor(Color.GREY);

  const toggleAssignmentType = (assignmentTypeID: string) => {
    const newAssignmentTypeIDs = [...assignmentFilter.assignmentTypes];

    const index = newAssignmentTypeIDs.indexOf(assignmentTypeID);
    if (index === -1) newAssignmentTypeIDs.push(assignmentTypeID);
    else newAssignmentTypeIDs.splice(index, 1);

    updateAssignmentFilter({
      ...assignmentFilter,
      assignmentTypes: newAssignmentTypeIDs,
    });
  };

  const updateFilterWithNewSearchValue = (newValue: string) => {
    updateAssignmentFilter({
      ...assignmentFilter,
      searchValue: newValue,
    });
  };

  const togglePastAssignmentsBeingShown = () => {
    updateAssignmentFilter({
      ...assignmentFilter,
      showPastAssignments: !assignmentFilter.showPastAssignments,
    });
  };

  useEffect(() => {
    loadAllObjectives(courseID);
  }, [courseID, loadAllObjectives]);

  if (!allObjectives || !hasLoadedAllObjectives()) return <></>;

  return (
    <div className="AssignmentFilter">
      {isUserTeachingTeam && (
        <>
          <Label className="blank bold" content="Manage Assignments" size="large" />
          <div
            className="manage-assignments-button ui default-button"
            role="button"
            onClick={() => modalStore.openModal(<CreateOrEditAssignment courseID={courseID} />)}
          >
            <Icon name="plus" size="small" />
            Create Assignment
          </div>
          <div
            className="manage-polling-button ui default-button"
            role="button"
            onClick={() => modalStore.openModal(<CreatePollingSession courseID={courseID} />)}
          >
            <Icon name="plus" size="small" />
            New Polling Session
          </div>
          <VerticalGap height="0.5em" />
        </>
      )}
      {assignmentsByCourse.length > 0 && (
        <>
          <Label className="blank bold" content="Filter Assignments" size="large" />
          <div className="search border-grey">
            <input
              type="text"
              value={assignmentFilter.searchValue}
              placeholder="Search for an assignment..."
              onChange={(e) => updateFilterWithNewSearchValue(e.target.value)}
            />
          </div>
          {assignmentTypes.length > 0 && (
            <FlexContainer flexDirection="column" gap="0.5em">
              <Label className="blank bold" content="Filter by Assignment Type" size="large" />
              {assignmentTypes.map(({ name, id }) => (
                <FilterLabel
                  key={id}
                  tag
                  content={name}
                  checkedColor={courseColor}
                  checked={assignmentFilter.assignmentTypes.includes(id)}
                  onClick={() => toggleAssignmentType(id)}
                />
              ))}
            </FlexContainer>
          )}
          {assignmentsByCourse && !assignmentFilter.showPastAssignments && (
            <Button
              content="Show Past Assignments"
              color="blue"
              icon="eye"
              onClick={togglePastAssignmentsBeingShown}
            />
          )}
          {assignmentsByCourse && assignmentFilter.showPastAssignments && (
            <Button
              content="Hide Past Assignments"
              color="grey"
              icon="hide"
              onClick={togglePastAssignmentsBeingShown}
            />
          )}
        </>
      )}
    </div>
  );
};

export default observer(AssignmentFilter);
