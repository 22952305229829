import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import App from "../../App";
import ConfirmEmailPage from "../../components/confirmEmail/ConfirmEmailPage";
import ViewAssessmentTasksForCourse from "../../components/courses/[courseID]/dashboard/[taskType]/ViewAssessmentTasksForCourse";
import ForgotPasswordPage from "../../components/forgotPassword/ForgotPasswordPage";
import ResetPassword from "../../components/forgotPassword/ResetPassword";
import LoginPage from "../../components/login/LoginPage";
import NotFound404 from "../../components/notFound/NotFound404";
import ProfilePage from "../../components/profile/ProfilePage";
import RegisterPage from "../../components/register/RegisterPage";
import VerifyEmailPage from "../../components/validateEmail/VerifyEmailPage";
import CourseHomeLayout from "../../layouts/CourseHomeLayout";
import CourseHomeAssignmentAllSubmissionsTab from "../../pages/CourseHomeAssignmentAllSubmissionsTab";
import CourseHomeAssignmentDetailsTab from "../../pages/CourseHomeAssignmentDetailsTab";
import CourseHomeAssignmentsTab from "../../pages/CourseHomeAssignmentsTab";
import CourseHomeAssignmentSubmissionTab from "../../pages/CourseHomeAssignmentSubmissionTab";
import CourseHomeCalendarTab from "../../pages/CourseHomeCalendarTab";
import CourseHomeCreateOrUpdateAssessmentTab from "../../pages/CourseHomeCreateOrUpdateAssessmentTab";
import CourseHomeFilesTab from "../../pages/CourseHomeFilesTab";
import CourseHomeCourseHomeGradebookTab from "../../pages/CourseHomeGradebookTab";
import CourseHomeGradeCalculationsTab from "../../pages/CourseHomeGradeCalculationsTab";
import CourseHomePageView from "../../pages/CourseHomePageView";
import CourseHomeRosterTab from "../../pages/CourseHomeRosterTab";
import CourseHomeSettingsView from "../../pages/CourseHomeSettingsView";
import CourseHomeSyllabusTab from "../../pages/CourseHomeSyllabusTab";
import ErrorPage from "../../pages/ErrorPage";
import Landing from "../../pages/Landing";
import TEACHFRONT_PATHS from "../../paths";
import NonCoursePage from "./components/routeAuthorizationExtensions/NonCoursePage";
import RequireAuthentication from "./components/routeAuthorizationExtensions/RequireAuthentication";
import RequireCourseRoleAuthorization from "./components/routeAuthorizationExtensions/RequireCourseRoleAuthorization";
import RequireValidIDParameter from "./components/routeAuthorizationExtensions/RequireValidIDParameters";
import RequireCourseRosterAndGroupSets from "./components/routeAuthorizationExtensions/RequireCourseRosterAndGroupSets";
import MasteryLevelsSettingsForm from "../../components/courses/[courseID]/settings/mastery-levels/MasteryLevelsSettingsForm";
import GradeDistinctionsSettingsForm from "../../components/courses/[courseID]/settings/grade-calculations/grade-distinctions/GradeDistinctionsSettingsForm";
import GradeIngredientsSettingsForm from "../../components/courses/[courseID]/settings/grade-calculations/grade-ingredients/GradeIngredientsSettingsForm";
import GradeRecipesSettingsForm from "../../components/courses/[courseID]/settings/grade-calculations/grade-recipes/GradeRecipesSettingsForm";
import ObjectiveSettingsForm from "../../components/courses/[courseID]/settings/objectives/ObjectiveSettingsForm";
import CourseSettingsForm from "../../components/courses/[courseID]/settings/CourseSettingsForm";
import ViewRosterForCourse from "../../components/courses/[courseID]/roster/ViewRosterForCourse";
import { CourseRoleGroup } from "./components/routeAuthorizationExtensions/CourseRoles";
import GroupSetView from "../../components/courses/[courseID]/roster/group-sets/GroupSetView";
import NewGroupSet from "../../components/courses/[courseID]/roster/group-sets/NewGroupSet";
import NoGroupFound from "../../components/courses/[courseID]/roster/group-sets/_common/NoGroupFound";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    errorElement: (
      <NonCoursePage>
        <ErrorPage />
      </NonCoursePage>
    ),
    children: [
      {
        path: TEACHFRONT_PATHS.Landing,
        element: (
          <NonCoursePage>
            <Landing />
          </NonCoursePage>
        ),
      },
      {
        path: TEACHFRONT_PATHS.Login,
        element: (
          <NonCoursePage>
            <LoginPage />
          </NonCoursePage>
        ),
      },
      {
        path: TEACHFRONT_PATHS.ForgotPassword,
        element: (
          <NonCoursePage>
            <ForgotPasswordPage />
          </NonCoursePage>
        ),
      },
      {
        path: TEACHFRONT_PATHS.ResetPassword,
        element: (
          <NonCoursePage>
            <ResetPassword />
          </NonCoursePage>
        ),
      },
      {
        path: TEACHFRONT_PATHS.Register,
        element: (
          <NonCoursePage>
            <RegisterPage />
          </NonCoursePage>
        ),
      },
      {
        path: TEACHFRONT_PATHS.ConfirmEmail,
        element: (
          <NonCoursePage>
            <ConfirmEmailPage />
          </NonCoursePage>
        ),
      },
      {
        path: TEACHFRONT_PATHS.ProfileForSelf,
        element: (
          <RequireAuthentication>
            <NonCoursePage>
              <ProfilePage />
            </NonCoursePage>
          </RequireAuthentication>
        ),
      },
      {
        path: TEACHFRONT_PATHS.ProfileForOther,
        element: (
          <RequireValidIDParameter parameters={["userID"]}>
            <RequireAuthentication>
              <NonCoursePage>
                <ProfilePage />
              </NonCoursePage>
            </RequireAuthentication>
          </RequireValidIDParameter>
        ),
      },
      {
        path: TEACHFRONT_PATHS.ValidateEmail,
        element: (
          <NonCoursePage>
            <VerifyEmailPage />
          </NonCoursePage>
        ),
      },
      {
        element: (
          <RequireValidIDParameter parameters={["courseID"]}>
            <RequireAuthentication>
              <CourseHomeLayout />
            </RequireAuthentication>
          </RequireValidIDParameter>
        ),
        // All children will be required to have a courseID in their path because of the RequireValidIDParameter wrapper.
        children: [
          {
            path: TEACHFRONT_PATHS.CourseHomeRosterTab,
            element: (
              <RequireCourseRosterAndGroupSets selectedCourseRoleGroup={CourseRoleGroup.ALL}>
                <CourseHomeRosterTab>
                  <ViewRosterForCourse selectedCourseRoleGroup={CourseRoleGroup.ALL} />
                </CourseHomeRosterTab>
              </RequireCourseRosterAndGroupSets>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeRosterTabInstructors,
            element: (
              <RequireCourseRosterAndGroupSets
                selectedCourseRoleGroup={CourseRoleGroup.INSTRUCTORS}
              >
                <CourseHomeRosterTab>
                  <ViewRosterForCourse selectedCourseRoleGroup={CourseRoleGroup.INSTRUCTORS} />
                </CourseHomeRosterTab>
              </RequireCourseRosterAndGroupSets>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeRosterTabGroupSet,
            element: (
              <RequireCourseRosterAndGroupSets ensureValidGroupSetID>
                <CourseHomeRosterTab>
                  <GroupSetView />
                </CourseHomeRosterTab>
              </RequireCourseRosterAndGroupSets>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeRosterTabNewGroupSet,
            element: (
              <RequireCourseRosterAndGroupSets>
                <CourseHomeRosterTab>
                  <NewGroupSet />
                </CourseHomeRosterTab>
              </RequireCourseRosterAndGroupSets>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeRosterTabStudents,
            element: (
              <RequireCourseRosterAndGroupSets selectedCourseRoleGroup={CourseRoleGroup.STUDENTS}>
                <CourseHomeRosterTab>
                  <ViewRosterForCourse selectedCourseRoleGroup={CourseRoleGroup.STUDENTS} />
                </CourseHomeRosterTab>
              </RequireCourseRosterAndGroupSets>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeRosterTabTeachingAssistants,
            element: (
              <RequireCourseRosterAndGroupSets
                selectedCourseRoleGroup={CourseRoleGroup.TEACHING_ASSISTANTS}
              >
                <CourseHomeRosterTab>
                  <ViewRosterForCourse
                    selectedCourseRoleGroup={CourseRoleGroup.TEACHING_ASSISTANTS}
                  />
                </CourseHomeRosterTab>
              </RequireCourseRosterAndGroupSets>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeRosterTabTeachingTeam,
            element: (
              <RequireCourseRosterAndGroupSets
                selectedCourseRoleGroup={CourseRoleGroup.TEACHING_TEAM}
              >
                <CourseHomeRosterTab>
                  <ViewRosterForCourse selectedCourseRoleGroup={CourseRoleGroup.TEACHING_TEAM} />
                </CourseHomeRosterTab>
              </RequireCourseRosterAndGroupSets>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeRosterTabGroupSetNotFound,
            element: (
              <RequireCourseRosterAndGroupSets>
                <CourseHomeRosterTab>
                  <NoGroupFound />
                </CourseHomeRosterTab>
              </RequireCourseRosterAndGroupSets>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeAssessmentTasksTab,
            element: (
              <RequireCourseRoleAuthorization requiredRoleSet="TeachingTeamRoles">
                <ViewAssessmentTasksForCourse />
              </RequireCourseRoleAuthorization>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeCalendarTab,
            element: (
              <RequireCourseRoleAuthorization requiredRoleSet="AllRoles">
                <CourseHomeCalendarTab />
              </RequireCourseRoleAuthorization>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomePageTab,
            element: (
              <RequireValidIDParameter parameters={["pageID"]}>
                <RequireCourseRoleAuthorization requiredRoleSet="AllRoles">
                  <CourseHomePageView />
                </RequireCourseRoleAuthorization>
              </RequireValidIDParameter>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeFilesTab,
            element: (
              <RequireCourseRoleAuthorization requiredRoleSet="AllRoles">
                <CourseHomeFilesTab />
              </RequireCourseRoleAuthorization>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeSettingsTabMasteryLevels,
            element: (
              <RequireCourseRoleAuthorization requiredRoleSet="TeachingTeamRoles">
                <CourseHomeSettingsView>
                  <MasteryLevelsSettingsForm />
                </CourseHomeSettingsView>
              </RequireCourseRoleAuthorization>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeSettingsTabGradeDistinctions,
            element: (
              <RequireCourseRoleAuthorization requiredRoleSet="TeachingTeamRoles">
                <CourseHomeSettingsView>
                  <GradeDistinctionsSettingsForm />
                </CourseHomeSettingsView>
              </RequireCourseRoleAuthorization>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeSettingsTabGradeIngredients,
            element: (
              <RequireCourseRoleAuthorization requiredRoleSet="TeachingTeamRoles">
                <CourseHomeSettingsView>
                  <GradeIngredientsSettingsForm />
                </CourseHomeSettingsView>
              </RequireCourseRoleAuthorization>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeSettingsTabGradeRecipes,
            element: (
              <RequireCourseRoleAuthorization requiredRoleSet="TeachingTeamRoles">
                <CourseHomeSettingsView>
                  <GradeRecipesSettingsForm />
                </CourseHomeSettingsView>
              </RequireCourseRoleAuthorization>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeSettingsTabObjectives,
            element: (
              <RequireCourseRoleAuthorization requiredRoleSet="TeachingTeamRoles">
                <CourseHomeSettingsView>
                  <ObjectiveSettingsForm />
                </CourseHomeSettingsView>
              </RequireCourseRoleAuthorization>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeSettingsTab,
            element: (
              <RequireCourseRoleAuthorization requiredRoleSet="TeachingTeamRoles">
                <CourseHomeSettingsView>
                  <CourseSettingsForm />
                </CourseHomeSettingsView>
              </RequireCourseRoleAuthorization>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeAssignmentsTab,
            element: (
              <RequireCourseRoleAuthorization requiredRoleSet="AllRoles">
                <CourseHomeAssignmentsTab />
              </RequireCourseRoleAuthorization>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeAssignmentDetailsTab,
            element: (
              <RequireValidIDParameter parameters={["assignmentID"]}>
                <RequireCourseRoleAuthorization requiredRoleSet="AllRoles">
                  <CourseHomeAssignmentDetailsTab />
                </RequireCourseRoleAuthorization>
              </RequireValidIDParameter>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeAssignmentSubmissionTab,
            element: (
              <RequireValidIDParameter
                parameters={["assignmentID", "userID", "submissionID"]}
                alternateParameterValues={{ submissionID: ["new", "reassessment"] }}
              >
                <RequireCourseRoleAuthorization requiredRoleSet="AllRoles">
                  <CourseHomeAssignmentSubmissionTab />
                </RequireCourseRoleAuthorization>
              </RequireValidIDParameter>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeAssignmentAllSubmissionsTab,
            element: (
              <RequireValidIDParameter parameters={["assignmentID"]}>
                <RequireCourseRoleAuthorization requiredRoleSet="TeachingTeamRoles">
                  <CourseHomeAssignmentAllSubmissionsTab />
                </RequireCourseRoleAuthorization>
              </RequireValidIDParameter>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeCreateOrUpdateAssessmentTab,
            element: (
              <RequireValidIDParameter parameters={["assignmentID", "userID", "submissionID"]}>
                <RequireCourseRoleAuthorization requiredRoleSet="TeachingTeamRoles">
                  <CourseHomeCreateOrUpdateAssessmentTab />
                </RequireCourseRoleAuthorization>
              </RequireValidIDParameter>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeGradebookTab,
            element: (
              <RequireCourseRoleAuthorization requiredRoleSet="TeachingTeamRoles">
                <CourseHomeCourseHomeGradebookTab />
              </RequireCourseRoleAuthorization>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeGradeCalculationsTab,
            element: (
              <RequireCourseRoleAuthorization requiredRoleSet="AllRoles">
                <CourseHomeGradeCalculationsTab />
              </RequireCourseRoleAuthorization>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeGradeCalculationsForUserTab,
            element: (
              <RequireValidIDParameter parameters={["userID"]}>
                <RequireCourseRoleAuthorization requiredRoleSet="AllRoles">
                  <CourseHomeGradeCalculationsTab />
                </RequireCourseRoleAuthorization>
              </RequireValidIDParameter>
            ),
          },
          {
            path: TEACHFRONT_PATHS.CourseHomeSyllabusTab,
            element: (
              <RequireCourseRoleAuthorization requiredRoleSet="AllRoles">
                <CourseHomeSyllabusTab />
              </RequireCourseRoleAuthorization>
            ),
          },
        ],
      },
      {
        path: "/not-found",
        element: (
          <NonCoursePage>
            <NotFound404 />
          </NonCoursePage>
        ),
      },
      {
        path: "/error",
        element: (
          <NonCoursePage>
            <ErrorPage />
          </NonCoursePage>
        ),
      },
      {
        path: "*",
        element: (
          <NonCoursePage>
            <Navigate replace to="/not-found" />
          </NonCoursePage>
        ),
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
