import { Header } from "semantic-ui-react";
import { DayOfTheWeek } from "../../../../../../utilities/dateTimeUtils";
import { FormikSetFieldValueFunction } from "../../../../../../utilities/formUtils";
import { createClassName } from "../../../../../../utilities/utils";
import FormDateInput from "../../../../../_common/form/FormDateInput";
import FlexContainer from "../../../../../_common/style/FlexContainer";
import "./CalendarEntryRecurrenceEditor.css";

interface CalendarEntryRecurrenceEditorProps {
  minDate: Date | undefined;
  maxDate: Date;
  daysToRecur: DayOfTheWeek[];
  recurUntilDate: Date | undefined;
  setFieldValue: FormikSetFieldValueFunction;
}

const CalendarEntryRecurrenceEditor: React.FC<CalendarEntryRecurrenceEditorProps> = ({
  daysToRecur,
  recurUntilDate,
  setFieldValue,
  minDate,
  maxDate,
}) => {
  const handleSelectDayToRecur = (day: DayOfTheWeek) => {
    const newDays = [...daysToRecur];

    const index = newDays.indexOf(day);

    if (index === -1) newDays.push(day);
    else newDays.splice(index, 1);

    setFieldValue("daysToRecur", newDays, false);
  };

  const handleSelectRecurUntilDate = (date: Date) => {
    setFieldValue("recurUntilDate", date);
  };

  return (
    <div className="CalendarEntryRecurrenceEditor">
      <Header
        content="On which days should this event recur?"
        as="h4"
        className="no-bottom-margin"
      />
      <FlexContainer gap="0.5rem" flexWrap="wrap">
        {Object.values(DayOfTheWeek).map((day) => (
          <button
            className={createClassName("day-of-the-week-button", {
              name: "selected",
              apply: daysToRecur.includes(day as DayOfTheWeek),
            })}
            key={day}
            onClick={() => handleSelectDayToRecur(day as DayOfTheWeek)}
            type="button"
          >
            {day.toString().substring(0, 3)}
          </button>
        ))}
      </FlexContainer>
      <Header
        content="When should the event stop recurring?"
        as="h4"
        className="no-bottom-margin"
      />
      <FormDateInput
        currentDate={recurUntilDate}
        onDateChange={(date) => handleSelectRecurUntilDate(date)}
        label="Select Until Date"
        icon="calendar alternate outline"
        required={true}
        datePickerProps={{
          dateFormat: "dd/MM/YYY",
          minDate,
          maxDate,
        }}
      />
    </div>
  );
};

export default CalendarEntryRecurrenceEditor;
