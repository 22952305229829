import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import useCurrentUserCourseRole from "../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../hooks/useValidParameters";
import useWindowSize from "../../../../hooks/useWindowSize";
import { CalendarEntry } from "../../../../models/CalendarEntry";
import { useStore } from "../../../../stores/store";
import { filterCalendarEntries } from "../../../../utilities/preferenceUtils";
import { createClassName, mapAssignmentsToCalendarEntries } from "../../../../utilities/utils";
import FlexContainer from "../../../_common/style/FlexContainer";
import VerticalGap from "../../../_common/style/spacing/VerticalGap";
import "./ViewVerticalCalendar.css";
import { CalendarEntryCardSkeleton } from "./_common/CalendarEntryCard";
import VerticalCalendar from "./_common/VerticalCalendar";
import VerticalCalendarEditor from "./_common/VerticalCalendarEditor";
import VerticalCalendarPreferences from "./_common/VerticalCalendarPreferences";

interface ViewVerticalCalendarProps {
  allowFiltering?: boolean;
}

const ViewVerticalCalendarSkeleton: React.FC = () => (
  <>
    <VerticalGap height="1rem" />
    <FlexContainer gap="1rem" flexDirection="column">
      <CalendarEntryCardSkeleton />
      <CalendarEntryCardSkeleton />
      <CalendarEntryCardSkeleton />
      <CalendarEntryCardSkeleton />
      <CalendarEntryCardSkeleton />
    </FlexContainer>
  </>
);

const ViewVerticalCalendar: React.FC<ViewVerticalCalendarProps> = ({ allowFiltering = true }) => {
  const { width } = useWindowSize([1400]);
  const { courseID } = useValidParams<{ courseID: string }>();
  const { calendarStore, persistentPreferenceStore, assignmentStore } = useStore();
  const {
    calendarEntries,
    loadCalendarEntriesForCourse,
    hasLoadedCalendarEntriesForCourse,
    calendarEntryTypes,
    loadCalendarEntryTypes,
    hasLoadedCalendarEntryTypesForCourse,
  } = calendarStore;
  const {
    assignmentsByCourse,
    loadAssignmentsByCourse,
    hasLoadedAssignmentsByCourse,
    assignmentTypes,
    loadAssignmentTypes,
    hasLoadedAssignmentTypes,
  } = assignmentStore;
  const { calendarEntryFilter } = persistentPreferenceStore;
  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);

  useEffect(() => {
    loadCalendarEntryTypes(courseID);
  }, [courseID, calendarEntryTypes]);

  useEffect(() => {
    loadCalendarEntriesForCourse(courseID);
    loadAssignmentsByCourse(courseID);
    loadAssignmentTypes(courseID);
  }, [courseID]);

  const allCalendarEntries: CalendarEntry[] = useMemo(() => {
    if (assignmentsByCourse && calendarEntryTypes && calendarEntries) {
      return [
        ...mapAssignmentsToCalendarEntries(assignmentsByCourse, calendarEntryTypes),
        ...calendarEntries,
      ];
    }

    return [];
  }, [assignmentsByCourse, calendarEntries, calendarEntryTypes]);

  if (
    !calendarEntries ||
    !calendarEntryTypes ||
    !assignmentsByCourse ||
    !assignmentTypes ||
    !hasLoadedCalendarEntriesForCourse(courseID) ||
    !hasLoadedCalendarEntryTypesForCourse(courseID) ||
    !hasLoadedAssignmentsByCourse() ||
    !hasLoadedAssignmentTypes()
  )
    return <ViewVerticalCalendarSkeleton />;

  const filteredCalendarEntries = filterCalendarEntries(allCalendarEntries, calendarEntryFilter);

  return (
    <div
      className={createClassName("ViewVerticalCalendar", { name: "mobile", apply: width < 1400 })}
    >
      <VerticalCalendar
        calendarEntries={allowFiltering ? filteredCalendarEntries : allCalendarEntries}
      />
      <div className="grid-section-b">
        {isUserTeachingTeam && <VerticalCalendarEditor courseID={courseID} />}
        <VerticalCalendarPreferences
          calendarEntryTypes={calendarEntryTypes}
          assignmentTypes={assignmentTypes}
        />
      </div>
    </div>
  );
};

export default observer(ViewVerticalCalendar);
