import { Segment, SegmentProps } from "semantic-ui-react";
import { createClassName, removeKeysFromObject } from "../../../utilities/utils";
import VerticalGap from "./spacing/VerticalGap";

interface RoundedSegmentProps extends SegmentProps {
  withBorder?: boolean;
  marginTop?: string;
}

/**
 * Wrapper component that wraps Semantic UI's Segment component. This component allows users to easily round segments and specify
 * if borders should be added or if there should be margin from the top, as well as supporting Segment's props.
 * @param props
 * @returns
 */
const RoundedSegment: React.FC<RoundedSegmentProps> = (props) => {
  const { marginTop, withBorder, children } = props;

  const className = createClassName(props.className ?? "", "rounded", {
    name: "no-border",
    apply: withBorder !== true,
  });

  return (
    <>
      {marginTop && <VerticalGap height={marginTop} />}
      <Segment {...removeKeysFromObject({ ...props, className }, "withBorder", "marginTop")}>
        {children && children}
      </Segment>
    </>
  );
};

export default RoundedSegment;
