import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Container, Segment } from "semantic-ui-react";
import VerticalGap from "../components/_common/style/spacing/VerticalGap";
import BackgroundWaves from "../components/_misc/BackgroundWaves";
import ToastContainer from "../components/_misc/ToastContainer";
import ModalContainer from "../components/_modal/ModalContainer";
import NavBar from "../components/_navbar/NavBar";
import useWindowSize from "../hooks/useWindowSize";
import { User } from "../models/User";
import { useStore } from "../stores/store";
import { createClassName, isValidID } from "../utilities/utils";
import { getLocalDate, getTime } from "../utilities/dateTimeUtils";
import ScrollToTopButton from "../components/_misc/ScrollToTopButton";

type AppLayoutConsumer = (user: User | null, isMobile: boolean) => JSX.Element;

type AppLayoutDictionary = {
  [name: string]: AppLayoutConsumer;
};

const defaultAppLayout: AppLayoutConsumer = (_, isMobile) => (
  <>
    <header className="App-header">
      <NavBar />
    </header>
    <BackgroundWaves fixed />
    {isMobile && <VerticalGap height={"4em"} />}
    <Container className="main-content">
      <Outlet />
    </Container>
  </>
);

const layouts: AppLayoutDictionary = {
  "/": (user, isMobile) => (user ? defaultAppLayout(user, isMobile) : <Outlet />),
};

const AppLayout = () => {
  const { isMobile } = useWindowSize();
  const { courseID } = useParams<{ courseID: string }>();
  const location = useLocation();
  const store = useStore();
  const { courseStore, userStore } = store;
  const { user } = userStore;
  const { loadCurrentCourse, currentCourse } = courseStore;

  useEffect(() => {
    // make sure the current course is loaded so we can properly set the background colors
    if (!currentCourse && courseID && isValidID(courseID) && user) {
      loadCurrentCourse(courseID);
    }
  }, [currentCourse, courseID, user]);

  const setColor = (colorVarName: string, colorVar: string) => {
    document.body.style.setProperty(
      colorVarName,
      getComputedStyle(document.body).getPropertyValue(colorVar)
    );
  };

  const setAllColors = (colorName: string) => {
    setColor("--course-color-1", `--${colorName}-1`);
    setColor("--course-color-2", `--${colorName}-2`);
    setColor("--course-color-3", `--${colorName}-3`);
    setColor("--course-color-4", `--${colorName}-4`);
    setColor("--course-color-5", `--${colorName}-5`);
    setColor("--course-color-6", `--${colorName}-6`);
    setColor("--course-color-7", `--${colorName}-7`);
    setColor("--nav-bar-color", `--${colorName}-6`);
  };

  if (courseID && currentCourse) {
    setAllColors(currentCourse.color.split("-")[0] ?? "blue");
  } else {
    setAllColors("blue");
  }

  return (
    <div className={createClassName("App", { name: "mobile", apply: isMobile, else: "desktop" })}>
      <ModalContainer />
      <ToastContainer />
      {Object.hasOwn(layouts, location.pathname)
        ? (layouts[location.pathname] as AppLayoutConsumer)(user, isMobile)
        : defaultAppLayout(user, isMobile)}
      <ScrollToTopButton />
      <Segment className="copyright-notice">
        Copyright TeachFront, {getLocalDate(new Date(getTime())).getFullYear()}
      </Segment>
    </div>
  );
};

export default observer(AppLayout);
