import { Header } from "semantic-ui-react";
import "./FeaturePreview.css";

interface FeaturePreviewProps {
  feature: string;
  description: string;
  photo: string;
  alignPhoto: string;
}

const FeaturePreview: React.FC<FeaturePreviewProps> = ({
  feature,
  description,
  photo,
  alignPhoto,
}) => (
  <div
    className={`FeaturePreview ${
      alignPhoto === "right" ? "rightFeaturePreview" : "leftFeaturePreview"
    }`}
  >
    <div className="feature-preview-background" />
    <Header as="h2" className="feature">
      {feature}
    </Header>
    <p className="description">{description}</p>
    <div
      className="photo"
      style={{
        backgroundImage: `url(${photo})`,
      }}
    ></div>
  </div>
);

export default FeaturePreview;
