import { Form, Formik, FormikErrors, FormikTouched, FormikValues } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Container, Header, Label, Message } from "semantic-ui-react";
import { VscLayoutSidebarRight, VscLayoutPanel } from "react-icons/vsc";
import { Assignment } from "../../../../../models/Assignment";
import { Feedback } from "../../../../../models/Feedback";
import { Objective } from "../../../../../models/Objective";
import { RatedObjective } from "../../../../../models/RatedObjective";
import { Rating } from "../../../../../models/Rating";
import { Submission } from "../../../../../models/Submission";
import { User } from "../../../../../models/User";
import "../../../../../semantic-ui/Colors.css";
import { useStore } from "../../../../../stores/store";
import { getGraphicDataForRatings } from "../../../../../utilities/assessmentGraphicUtils";
import {
  forEach,
  objectMap,
  objectSize,
  objectSome,
} from "../../../../../utilities/collectionUtils";
import LoadingComponent from "../../../../../utilities/routing/components/LoadingComponent";
import {
  emptyID,
  getRatingsForSubmissionFromRatedObjectives,
  getSubmissionPackage,
  isFirstSubmission,
  isSubmissionAssessed,
  isSubmissionAssessmentPublished,
} from "../../../../../utilities/submissionUtils";
import { createClassName } from "../../../../../utilities/utils";
import AssessmentSummaryGraphic from "../../../../_common/assessmentGraphic/AssessmentSummaryGraphic";
import CardTypeLabel from "../../../../_common/cards/_common/CardTypeLabel";
import DragAndDropFile from "../../../../_common/file/DragAndDropFile";
import HorizontalIndent from "../../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import TextEditor from "../../../../_common/textEditing/TextEditor";
import PublishModal from "../../../../_modal/PublishModal";
import AssignmentNoSubmission from "../../_common/AssignmentNoSubmission";
import AssignmentSubmission from "../../_common/AssignmentSubmission";
import CourseObjective from "../../syllabus/CourseObjective";
import "./CreateOrEditAssessment.css";
import MasteryLevelPicker from "./MasteryLevelPicker";
import FlexContainer from "../../../../_common/style/FlexContainer";
import PredefinedAssessmentComment from "../../../../../models/PredefinedAssessmentComment";

// Name for mastery levels to be used in the form
const objectiveMasteryLevelName = (objectiveID: string) => `${objectiveID}-masteryLevel`;
// Name for the boolean overrideExistingRatings for the specific objective.
const objectiveOverrideName = (objectiveID: string) => `${objectiveID}-override`;
// Name for comments to be used in the form
const objectiveCommentName = (objectiveID: string) => `${objectiveID}-feedback`;
// Name for overall feedback given about the submission
const overallFeedbackName = () => `overall-feedback`;

// Name for PAC associated with an objective or overall feedback
export const pacName = (objectiveID?: string) => `${objectiveID || "overall"}-PAC`;
// Bool for if a PAC's toggle is selected
export const pacToggleSelected = (pacID?: string) => `${pacID || "new"}-PAC-toggle`;

// These are needed for saving PAC's being edited upon assessment form submit:
// PACID for PAC being edited
export const pacEditingID = (objectiveID?: string) => `${objectiveID || "overall"}-PAC-ID`;
// relativeOrderIndex for PAC being edited
export const pacEditingRelIndex = (objectiveID?: string) =>
  `${objectiveID || "overall"}-PAC-RelIndex`;

// Checks if errors exist in the form
const doErrorsExist = (errors: FormikErrors<FormikValues>, touched: FormikTouched<FormikValues>) =>
  Object.keys(errors).length > 0 && Object.keys(touched).length > 0;

// Checks if errors exist for an objective by checking the form values.
const doesErrorExistForObjective = (
  objectiveID: string,
  errors: FormikErrors<FormikValues>,
  touched: FormikTouched<FormikValues>
) =>
  doErrorsExist(errors, touched) &&
  errors[objectiveCommentName(objectiveID)] &&
  (touched[objectiveCommentName(objectiveID)] || touched[objectiveMasteryLevelName(objectiveID)]);

// Checks if the form is dirty, meaning if it has been edited at all.
// This will only return true if no values are empty and values differ from the initial values.
function isFormDirty(initialValues: FormikValues, currentValues: FormikValues) {
  return objectSome(currentValues, (key, value) => {
    const initialValue = initialValues[key as string];

    if (value && initialValue && value !== initialValue) return true;

    if (value && !initialValue) return true;

    return false;
  });
}

// Creates an array of ratings from form values and existing ratings.
function createRatingsFromFormValues(
  assignmentID: string,
  submissionID: string,
  courseID: string,
  studentID: string,
  raterID: string,
  objectives: Objective[],
  ratings: Map<string, Rating>,
  formValues: FormikValues
) {
  const newRatings: Rating[] = [];

  objectives
    .filter((objective) => formValues[objectiveMasteryLevelName(objective.id)])
    .forEach(({ id }) => {
      const masteryLevelID = formValues[objectiveMasteryLevelName(id)];
      const comment = formValues[objectiveCommentName(id)];
      const existingRating = ratings.get(id);

      newRatings.push({
        id: existingRating?.id || emptyID,
        studentID,
        submissionID,
        assignmentID,
        raterID,
        objectiveID: id,
        createdAt: existingRating?.createdAt || new Date(Date.now()),
        isDraft: true,
        masteryLevelID,
        comment,
        courseID,
      });
    });

  return newRatings;
}

// Creates an array of ratings from the form values that can be used to set
// the default ratings for an assessment of a course.
function createDefaultRatingsFromFormValues(
  assignmentID: string,
  courseID: string,
  raterID: string,
  objectives: Objective[],
  formValues: FormikValues
) {
  const newRatings: Rating[] = [];

  objectives
    .filter((objective) => formValues[objectiveMasteryLevelName(objective.id)])
    .forEach(({ id }) => {
      const masteryLevelID = formValues[objectiveMasteryLevelName(id)];
      const comment = formValues[objectiveCommentName(id)];

      newRatings.push({
        id: emptyID,
        studentID: emptyID,
        submissionID: emptyID,
        assignmentID,
        raterID,
        objectiveID: id,
        createdAt: new Date(Date.now()),
        isDraft: true,
        masteryLevelID,
        comment,
        courseID,
      });
    });

  return newRatings;
}

function getSubmissionID(submission?: Submission): string {
  return (submission as Submission).id as string;
}

interface CreateOrEditAssessmentProps {
  assignment: Assignment<RatedObjective>;
  user: User;
  submission?: Submission;
  onCancel: () => void;
  courseID: string;
  onSubmit: (changesMade: boolean) => void;
  studentID?: string;
  isSettingDefaultAssessment?: boolean;
  hideOverrideObjectivesSwitches?: boolean;
  generateSubmissionIfNoneExist?: boolean;
}

/**
 * A form to create or edit an assessment with support for setting default ratings.
 * @param submission is optional when isSettingDefaultAssessment is true. If the latter is false and submission is undefined, the component will not be rendered.
 */
const CreateOrEditAssessment: React.FC<CreateOrEditAssessmentProps> = ({
  assignment,
  user,
  submission,
  onCancel,
  studentID,
  courseID,
  onSubmit,
  isSettingDefaultAssessment,
  hideOverrideObjectivesSwitches,
  generateSubmissionIfNoneExist,
}) => {
  const {
    courseStore,
    feedbackStore,
    ratingStore,
    modalStore,
    submissionStore,
    predefinedAssessmentCommentStore,
  } = useStore();
  const { roster, loadCurrentCourseRoster, hasLoadedRoster } = courseStore;
  const { createOrUpdateRatings, setDefaultRatingsForSpecificStudents } = ratingStore;
  const {
    submissionsForAssignment,
    loadSubmissionsForAssignment,
    autoGenerateSubmission,
    hasLoadedSubmissionsForAssignment,
  } = submissionStore;
  const {
    feedbackForSubmission,
    loadFeedbackForSubmission,
    hasLoadedFeedbackForSubmission,
    createOrUpdateFeedback,
    setDefaultFeedbackForAllStudents,
    setDefaultFeedbackForSpecificStudents,
  } = feedbackStore;
  const { handleCreatePAC, loadComments } = predefinedAssessmentCommentStore;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [displayedSubmission, setDisplayedSubmission] = useState<Submission | undefined>(
    submission
  );
  const [assessmentLayoutPreference, setAssessmentLayoutPreference] = useState<
    "sidebar" | "layoutPanel" | null
  >(
    (localStorage.getItem("assessmentLayoutPreference") as "sidebar" | "layoutPanel" | null) ||
      "sidebar"
  );

  useEffect(() => {
    loadComments(courseID, assignment.id);
  }, [courseID, assignment.id]);

  useEffect(() => {
    if (assessmentLayoutPreference === null) {
      localStorage.removeItem("assessmentLayoutPreference");
    } else {
      localStorage.setItem("assessmentLayoutPreference", assessmentLayoutPreference);
    }
  }, [assessmentLayoutPreference]);

  const requestedAutogenerateRef = useRef(false);

  useEffect(() => {
    loadCurrentCourseRoster(courseID);
  }, [courseID]);

  useEffect(() => {
    loadFeedbackForSubmission(submission?.id, studentID, courseID);
  }, [studentID, submission, courseID]);

  const { objectives, id: assignmentID } = assignment;

  // autogenerate a submission if one doesn't exist yet
  useEffect(() => {
    if (
      generateSubmissionIfNoneExist &&
      studentID &&
      !requestedAutogenerateRef.current &&
      (!submissionsForAssignment || submissionsForAssignment?.length === 0)
    ) {
      requestedAutogenerateRef.current = true;
      autoGenerateSubmission(assignment.id, courseID, studentID).then(() => {
        if (submissionsForAssignment) {
          setDisplayedSubmission(submissionsForAssignment[0]);
        }
      });
    } else {
      loadSubmissionsForAssignment(assignment.id, studentID, courseID);
    }
  }, [
    assignment,
    user,
    courseID,
    generateSubmissionIfNoneExist,
    studentID,
    submissionsForAssignment,
  ]);

  if (!displayedSubmission && submissionsForAssignment && submissionsForAssignment.length > 0) {
    setDisplayedSubmission(submissionsForAssignment[0]);
  }

  if (!roster || !hasLoadedRoster(courseID)) {
    return <LoadingComponent content="Loading roster..." />;
  }

  if (
    !submissionsForAssignment ||
    (studentID && !hasLoadedSubmissionsForAssignment(studentID, assignment.id)) ||
    !hasLoadedFeedbackForSubmission(studentID, submission?.id)
  )
    return <LoadingComponent content="Loading Submissions..." />;

  if (!objectives) return <>This assignment has no objectives.</>;

  if (!isSettingDefaultAssessment && !studentID) return <>Student not found.</>;

  const mappedRatings = isSettingDefaultAssessment
    ? undefined
    : getRatingsForSubmissionFromRatedObjectives(
        studentID as string,
        getSubmissionID(displayedSubmission),
        objectives
      );

  const canAssessmentBePublished =
    displayedSubmission !== undefined &&
    displayedSubmission.id &&
    studentID &&
    assignment.objectives &&
    feedbackForSubmission &&
    isSubmissionAssessed(studentID, displayedSubmission.id, assignment.objectives) &&
    !isSubmissionAssessmentPublished(
      displayedSubmission.userID,
      displayedSubmission.id,
      assignment.objectives,
      feedbackForSubmission
    );

  // If there are any ratings, then the submission is assessed.
  const assessed = mappedRatings !== undefined && mappedRatings.size > 0;

  const submissionPackage =
    studentID &&
    displayedSubmission &&
    getSubmissionPackage(
      studentID,
      displayedSubmission,
      assignment,
      false,
      isFirstSubmission(displayedSubmission, submissionsForAssignment)
    );

  const initValues: FormikValues = {};

  // Currently only ensures that if an object has a comment, it must have a mastery level selected.
  const validate = (values: FormikValues) => {
    const errors: FormikValues = {};

    objectives?.forEach((objective) => {
      const { id } = objective;
      const masteryLevelID = values[objectiveMasteryLevelName(id)];

      if (!masteryLevelID || masteryLevelID === "none") {
        errors[
          objectiveCommentName(id)
        ] = `You must select a mastery level rating for ${objective.shortName}`;
      }
    });

    return errors;
  };

  // Initialize default values for existing ratings.
  if (assessed) {
    forEach(mappedRatings, (objectiveID, rating) => {
      initValues[objectiveMasteryLevelName(objectiveID)] = rating.masteryLevelID;
      if (rating.comment) initValues[objectiveCommentName(objectiveID)] = rating.comment;
    });
  }
  if (feedbackForSubmission) {
    initValues[overallFeedbackName()] = feedbackForSubmission.comment;
  }

  const then = (success: boolean) => {
    ratingStore.reset();
    feedbackStore.reset();
    onSubmit(success);

    setIsSubmitting(false);
  };

  const publishAssessment = (
    success: boolean,
    resetStoresOnClose: boolean,
    submissionIDs?: string[]
  ) => {
    modalStore.openModal(
      () => (
        <PublishModal
          assignmentID={assignmentID}
          assignmentName={assignment.name || ""}
          courseID={courseID}
          disableToast={true}
          submissionIDs={submissionIDs}
          then={() => then(success)}
        />
      ),
      {
        showCloseIcon: true,
        onClose: () => resetStoresOnClose && then(success),
        isChildModal: true,
      }
    );
  };

  const handleFormSubmit = async (formValues: FormikValues, publish: boolean) => {
    if (formValues) {
      const saveInProgressPAC = async (objectiveID?: string) => {
        if (formValues[pacName(objectiveID)]) {
          // if PAC was being edited
          if (
            formValues[pacEditingID(objectiveID)] &&
            formValues[pacEditingRelIndex(objectiveID)]
          ) {
            const pacToEdit: PredefinedAssessmentComment = {
              id: formValues[pacEditingID(objectiveID)],
              assignmentID,
              objectiveID,
              courseID,
              commentText: "",
              relativeOrderIndex: formValues[pacEditingRelIndex(objectiveID)],
            };

            await handleCreatePAC(
              formValues[pacName(objectiveID)],
              courseID,
              assignmentID,
              objectiveID,
              pacToEdit
            );

            // if PAC was being created
          } else {
            await handleCreatePAC(
              formValues[pacName(objectiveID)],
              courseID,
              assignmentID,
              objectiveID
            );
          }
        }
      };

      // save any "in progress" work if user was creating/editing any of the objective pacs
      objectives.forEach(async (objective) => {
        saveInProgressPAC(objective.id);
      });

      // save any "in progress" work if user was creating/editing any of the overall feedback pacs
      saveInProgressPAC();

      if (isSettingDefaultAssessment) {
        const ratings = createDefaultRatingsFromFormValues(
          assignmentID,
          courseID,
          user.userID,
          objectives,
          formValues
        );

        const objectiveIDsToBeOverridden: string[] = [];

        // Set the objective ids that will be overridden depending on if they were checked or not.
        objectives.forEach(
          ({ id }) => formValues[objectiveOverrideName(id)] && objectiveIDsToBeOverridden.push(id)
        );

        const feedback: Feedback = {
          id: feedbackForSubmission?.id || emptyID,
          courseID,
          studentID: emptyID,
          submissionID: emptyID,
          assignmentID,
          raterID: user.userID,
          createdAt: new Date(Date.now()),
          isDraft: true,
          comment: formValues[overallFeedbackName()],
        };

        // If there is a user and this component is setting the default assessment, adjust accordingly.
        let resultOfFeedback: Record<string, string>;
        if (studentID) {
          resultOfFeedback = await setDefaultFeedbackForSpecificStudents(
            courseID,
            assignmentID,
            feedback,
            [studentID]
          );
        } else {
          resultOfFeedback = await setDefaultFeedbackForAllStudents(
            courseID,
            assignmentID,
            feedback
          );
        }

        // set the ratings next, and choose whether to publish
        const resultOfRatings = await setDefaultRatingsForSpecificStudents(
          courseID,
          assignmentID,
          ratings,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          objectMap(resultOfFeedback, (key) => key as string),
          objectiveIDsToBeOverridden
        );

        const success = objectSize(resultOfRatings) > 0;
        const submissionIDs = objectMap(resultOfRatings, (key, value) => value);
        if (publish) publishAssessment(success, true, submissionIDs);
        else then(success);
      } else {
        const submissionID = getSubmissionID(submission);

        const ratings = createRatingsFromFormValues(
          assignmentID,
          submissionID,
          courseID,
          studentID as string,
          user.userID,
          objectives,
          mappedRatings as Map<string, Rating>,
          formValues
        );

        const feedback: Feedback = {
          id: feedbackForSubmission?.id || emptyID,
          courseID,
          studentID: studentID as string,
          submissionID,
          assignmentID,
          raterID: user.userID,
          createdAt: new Date(Date.now()),
          isDraft: true,
          comment: formValues[overallFeedbackName()],
        };

        createOrUpdateFeedback(feedback);

        // Calls the api endpoint to update ratings, which returns a promise. Use the then() method to run code
        // after the api responds, so that values will be properly updated in time.
        createOrUpdateRatings(courseID, assignmentID, [studentID as string], ratings).then(
          (success: boolean) => {
            if (publish) publishAssessment(success, true, [submissionID]);
            else then(success);
          }
        );
      }
    }
  };

  return (
    <Container className="CreateOrEditAssessment">
      <Formik
        initialValues={assessed ? initValues : {}}
        validate={validate}
        onSubmit={(formValues: FormikValues) => handleFormSubmit(formValues, false)}
      >
        {({ handleSubmit, touched, errors, values, setFieldValue, setFieldTouched }) => (
          <Form className="ui form" onSubmit={handleSubmit}>
            <FlexContainer justifyContent="space-between">
              <Header as="h2">
                {`${assessed ? "Update" : "Create"} Assessment: ${assignment.name}`}
              </Header>
              <FlexContainer gap=".5rem" className="maximize-button-container">
                <VscLayoutSidebarRight
                  onClick={() => setAssessmentLayoutPreference("sidebar")}
                  className={createClassName(
                    "maximize-button",
                    assessmentLayoutPreference === "sidebar" ? "maximize-button-sidebar" : ""
                  )}
                  size="1.5em"
                />
                <VscLayoutPanel
                  onClick={() => setAssessmentLayoutPreference("layoutPanel")}
                  className={createClassName(
                    "maximize-button",
                    assessmentLayoutPreference === "layoutPanel" ? "maximize-button-panel" : ""
                  )}
                  size="1.5em"
                />
              </FlexContainer>
            </FlexContainer>
            <FlexContainer
              flexDirection={assessmentLayoutPreference === "sidebar" ? "row" : "column"}
              gap="2.25rem"
              className={createClassName(
                "submission-details-sidebar-container",
                assessmentLayoutPreference === "layoutPanel"
                  ? "submission-details-sidebar-container-horizontal"
                  : ""
              )}
            >
              <div
                className={createClassName(
                  "submission-section",
                  assessmentLayoutPreference === "layoutPanel"
                    ? "submission-section-horizontal"
                    : ""
                )}
              >
                {displayedSubmission && submissionPackage && (
                  <AssignmentSubmission
                    submissionPackage={submissionPackage}
                    assignment={assignment}
                    showTeachingTeamUploadedFiles={false}
                  />
                )}
                {studentID && !displayedSubmission && (
                  <AssignmentNoSubmission studentID={studentID} />
                )}
                {displayedSubmission && displayedSubmission.id && (
                  <>
                    <Header as="h3">Upload Files on the Student's Behalf:</Header>
                    <HorizontalIndent>
                      <DragAndDropFile
                        uploadFileCategory={"teachingTeamUploadedSubmission"}
                        userID={user.userID}
                        courseID={courseID}
                        connectedComponentID={displayedSubmission.id}
                        showUploadComponentByDefault={true}
                      />
                    </HorizontalIndent>
                  </>
                )}
              </div>
              <div
                className={createClassName(
                  "assessment-form-section",
                  assessmentLayoutPreference === "layoutPanel"
                    ? "assessment-form-section-horizontal"
                    : ""
                )}
              >
                <FlexContainer flexDirection="column" alignItems="center">
                  <Header as="h3">Current Assessment:</Header>
                  <HorizontalIndent>
                    <AssessmentSummaryGraphic
                      data={getGraphicDataForRatings(
                        isSettingDefaultAssessment
                          ? createDefaultRatingsFromFormValues(
                              assignmentID,
                              courseID,
                              user.userID,
                              objectives,
                              values
                            )
                          : createRatingsFromFormValues(
                              assignmentID,
                              getSubmissionID(displayedSubmission),
                              courseID,
                              studentID as string,
                              user.userID,
                              objectives,
                              mappedRatings as Map<string, Rating>,
                              values
                            ),
                        objectives
                      )}
                      size={assessmentLayoutPreference === "layoutPanel" ? "24em" : "12em"}
                      unpublishedRatingIconSize={
                        assessmentLayoutPreference === "layoutPanel" ? "6.6em" : "3.3em"
                      }
                      unassessedIconSize={
                        assessmentLayoutPreference === "layoutPanel" ? "8em" : "4em"
                      }
                    />
                  </HorizontalIndent>
                </FlexContainer>
                <VerticalGap height="1.5em" />
                <HorizontalIndent>
                  {objectives?.map((obj, index) => (
                    <CourseObjective
                      backgroundColor="white"
                      enableCollapseWithArrowIcon={true}
                      collapsedByDefault={index > 0}
                      key={obj.id}
                      objective={obj}
                      isEditable={false}
                      showAssignments={false}
                      cardTypeLabel={
                        values[objectiveMasteryLevelName(obj.id)]
                          ? "Assessed Objective"
                          : "Objective To Be Assessed"
                      }
                      className="objective-container"
                    >
                      <Header as="h5">Assessment of Student's Work:</Header>
                      <HorizontalIndent>
                        {obj.masteryLevelScheme && (
                          <MasteryLevelPicker
                            masteryLevelScheme={obj.masteryLevelScheme}
                            currentMasteryLevelID={values[objectiveMasteryLevelName(obj.id)]}
                            selectMasteryLevelID={(id) => {
                              setFieldValue(objectiveMasteryLevelName(obj.id), id);
                              setFieldTouched(objectiveMasteryLevelName(obj.id), true, false); // dont revalidate or the error state will be wrong, it will call validate() without the updated mastery level id.
                            }}
                          />
                        )}
                      </HorizontalIndent>
                      <Header as="h5">Feedback:</Header>
                      <HorizontalIndent>
                        {/* TODO: Uncomment once pacs are done */}
                        {/* <PACEditor
                          courseID={courseID}
                          assignmentID={assignment.id}
                          values={values}
                          setFieldValue={setFieldValue}
                          objective={obj}
                        /> */}
                        <TextEditor
                          placeholder={"Enter feedback..."}
                          onChange={(value) => {
                            setFieldValue(objectiveCommentName(obj.id), value);
                            setFieldTouched(objectiveCommentName(obj.id), true, true); // force revalidation of the error state to enforce teaching team to rate all objectives
                          }}
                          defaultValue={values[objectiveCommentName(obj.id)]}
                          container={`editor-${objectiveCommentName(obj.id)}`}
                          smallHeight={true}
                        />
                      </HorizontalIndent>
                      {isSettingDefaultAssessment && !hideOverrideObjectivesSwitches && (
                        <Checkbox
                          disabled={!values[objectiveMasteryLevelName(obj.id)]}
                          className="objective-override-checkbox"
                          toggle
                          name={objectiveOverrideName(obj.id)}
                          label="Override existing ratings for this objective"
                          onChange={(e, data) =>
                            setFieldValue(objectiveOverrideName(obj.id), data.checked)
                          }
                        />
                      )}
                      {doesErrorExistForObjective(obj.id, errors, touched) && (
                        <Message
                          negative
                          content={errors[objectiveCommentName(obj.id)] as string}
                        />
                      )}
                    </CourseObjective>
                  ))}
                  <div className="CourseObjective overall-feedback">
                    <CardTypeLabel content="Feedback" />
                    <VerticalGap height="1rem" />
                    <Label
                      ribbon
                      className={createClassName("black", "rounded-0")}
                      data-print-id="color"
                    >
                      Overall Comments
                    </Label>
                    <Header as="h5">Feedback:</Header>
                    <HorizontalIndent>
                      {/* TODO: Uncomment once pacs are done */}
                      {/* <PACEditor
                        courseID={courseID}
                        assignmentID={assignment.id}
                        values={values}
                        setFieldValue={setFieldValue}
                      /> */}
                      <TextEditor
                        placeholder={"Enter feedback..."}
                        onChange={(value) => setFieldValue(overallFeedbackName(), value)}
                        defaultValue={values[overallFeedbackName()]}
                        container={`editor-${overallFeedbackName()}`}
                        smallHeight={true}
                      />
                    </HorizontalIndent>
                  </div>
                  <VerticalGap height="1em" />
                </HorizontalIndent>
              </div>
            </FlexContainer>
            {doErrorsExist(errors, touched) && (
              <Message negative list={objectMap(errors, (key, value) => value as string)} />
            )}
            <Button
              content="Cancel"
              type="button"
              onClick={() => onCancel()}
              color="red"
              icon="x"
            />
            <Button
              disabled={!isFormDirty(initValues, values) || doErrorsExist(errors, touched)}
              content={assessed ? "Update Assessment" : "Create Assessment"}
              type="submit"
              color="blue"
              floated="right"
              icon="checkmark"
            />
            <Button
              disabled={!isFormDirty(initValues, values) || doErrorsExist(errors, touched)}
              loading={isSubmitting}
              content={`${assessed ? "Update" : "Create"} and Publish Assessment`}
              type="button"
              onClick={() => handleFormSubmit(values, true)}
              floated="right"
              color="grey"
              icon="upload"
            />
            {canAssessmentBePublished && displayedSubmission && displayedSubmission.id && (
              <Button
                loading={isSubmitting}
                content={`Publish`}
                type="button"
                onClick={() =>
                  displayedSubmission &&
                  publishAssessment(true, false, [displayedSubmission.id as string])
                }
                floated="right"
                color="grey"
                icon="upload"
              />
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default observer(CreateOrEditAssessment);
