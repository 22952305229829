import { Ref, forwardRef } from "react";
import { Header } from "semantic-ui-react";
import ComplicatedShell from "../../_misc/ComplicatedShell";
import "./HeaderSection.css";

const HeaderSection = forwardRef<HTMLDivElement>((props, ref: Ref<HTMLDivElement>) => (
  <div id="HeaderSection" className="HeaderSection" ref={ref}>
    <div className="header-section-background" />
    <Header as="h1" className="title">
      TeachFront
    </Header>
    <ComplicatedShell />
    <div className="subtitle">
      <Header as="h2">
        the new wave of
        <br />
        equitable assessment
      </Header>
    </div>
  </div>
));

export default HeaderSection;
