import { observer } from "mobx-react-lite";
import { Icon, Modal } from "semantic-ui-react";
import { Modal as StoreModal } from "../../stores/modalStore";
import { useStore } from "../../stores/store";
import "./ModalContainer.css";

// adapted from https://github.com/TryCatchLearn/Reactivities

const ModalContainer: React.FC = () => {
  const { modalStore } = useStore();

  // Recursively gets modals to support child modals.
  const getModal = (modal: StoreModal) => (
    <Modal
      open={true}
      onClose={() => {
        // Intentionally empty to ignore clicks on the dimmer
      }}
      size="mini"
      dimmer="inverted"
      className={modal.backgroundColor}
      mountNode={document.getElementById("TeachFront")}
    >
      <div className="content">
        {modal.showCloseIcon && (
          <div
            className="x-icon"
            onClick={() => {
              modalStore.closeModal(modal.index);
              if (modal.onClose !== undefined) modal.onClose();
            }}
          >
            <Icon name="x" />
          </div>
        )}
        {modal.body}
      </div>
      {modal.child && getModal(modal.child)}
    </Modal>
  );

  if (!modalStore.modal) {
    return <Modal open={false}></Modal>;
  }

  return getModal(modalStore.modal);
};

export default observer(ModalContainer);
