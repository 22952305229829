import { Form, Formik, FormikValues } from "formik";
import { useState } from "react";
import { Button, Header } from "semantic-ui-react";
import { User } from "../../../models/User";
import { useStore } from "../../../stores/store";
import { objectEvery } from "../../../utilities/collectionUtils";
import FormLogoInput from "../../_common/form/FormLogoInput";
import VerticalGap from "../../_common/style/spacing/VerticalGap";
import "./ProfileEditModal.css";

type ProfileEditValues = {
  title: string;
  honorific: string;
  suffix: string;
  firstName: string;
  lastName: string;
  pronouns: string;
};

const characterLimits = {
  title: 200,
  honorific: 10,
  suffix: 10,
  pronouns: 50,
};

interface ProfileEditModalProps {
  userProfile: User;
}

const ProfileEditModal: React.FC<ProfileEditModalProps> = ({ userProfile }) => {
  const { userStore, modalStore } = useStore();
  const { updateUserProfile } = userStore;
  const initialValues: ProfileEditValues = {
    title: userProfile.title ?? "",
    honorific: userProfile.honorific ?? "",
    suffix: userProfile.suffix ?? "",
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    pronouns: userProfile.pronouns,
  };
  const [saving, setSaving] = useState<boolean>(false);

  const validate = (values: ProfileEditValues) => {
    const errors: FormikValues = {};

    const { title, honorific, suffix, firstName, lastName, pronouns } = values;

    if (title && title.length > characterLimits.title)
      errors.title = `Your title must be ${characterLimits.title} characters or less`;

    if (honorific && honorific.length > characterLimits.honorific)
      errors.honorific = `Your honorific must be ${characterLimits.honorific} characters or less`;

    if (suffix && suffix.length > characterLimits.suffix)
      errors.suffix = `Your suffix must be ${characterLimits.suffix} characters or less`;

    if (!firstName || firstName.length === 0)
      errors.firstName = "Your first name must not be empty";

    if (!lastName || lastName.length === 0) errors.lastName = "Your last name must not be empty";

    if (pronouns && pronouns.length > characterLimits.pronouns)
      errors.pronouns = `Your pronouns may not exceed ${characterLimits.pronouns} characters`;

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (formValues) => {
        setSaving(true);
        await updateUserProfile({
          ...userProfile,
          title: formValues.title || undefined,
          honorific: formValues.honorific || undefined,
          suffix: formValues.suffix || undefined,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          pronouns: formValues.pronouns,
        });
        setSaving(false);
        modalStore.closeModal();
      }}
      validate={validate}
    >
      {({ errors, values }) => (
        <Form className="ProfileEditModal">
          <Header content="Edit Profile" />
          <FormLogoInput
            iconName="address card outline"
            label="Honorific"
            name="honorific"
            placeholder="Enter your honorific..."
            required={false}
            type="text"
            error={errors.honorific}
          />
          <FormLogoInput
            iconName="user"
            label="First Name"
            name="firstName"
            placeholder="Enter your first name..."
            required={true}
            type="text"
            error={errors.firstName}
          />
          <FormLogoInput
            iconName="user"
            label="Last Name"
            name="lastName"
            placeholder="Enter your last name..."
            required={true}
            type="text"
            error={errors.lastName}
          />
          <FormLogoInput
            iconName="user"
            label="Suffix"
            name="suffix"
            placeholder="Enter your suffix..."
            required={false}
            type="text"
            error={errors.suffix}
          />

          <FormLogoInput
            iconName="graduation"
            label="Title"
            name="title"
            placeholder="Enter your title..."
            required={false}
            type="text"
            error={errors.title}
          />
          <FormLogoInput
            iconName="user"
            label="Pronouns"
            name="pronouns"
            placeholder="Enter your pronouns..."
            required={false}
            type="text"
            error={errors.pronouns}
          />
          <VerticalGap height="1em" />
          <div>
            <Button content="Cancel" color="grey" onClick={() => modalStore.closeModal()} />
            <Button
              content="Save"
              loading={saving}
              disabled={saving || !objectEvery(validate(values), (_, value) => !value)}
              color="blue"
              floated="right"
              type="submit"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileEditModal;
