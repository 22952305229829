import { useMemo } from "react";
import { Cell, Pie, PieChart } from "recharts";
import { User } from "../../../../../../models/User";
import { PollingActivityQuestion } from "../../../../../../models/polling/PollingActivityQuestion";
import "./InstructorPollingActivityOverviewResponseRateGraphic.css";
import FlexContainer from "../../../../../_common/style/FlexContainer";

interface InstructorPollingActivityOverviewResponseRateGraphicProps {
  pollingActivityQuestions: PollingActivityQuestion[];
  students: User[];
}

type StudentResponsePieChartData = {
  label: string;
  numStudents: number;
  color: string;
};

const InstructorPollingActivityOverviewResponseRateGraphic: React.FC<
  InstructorPollingActivityOverviewResponseRateGraphicProps
> = ({ pollingActivityQuestions, students }) => {
  const numResponsiveStudents = useMemo(
    () =>
      pollingActivityQuestions.length === 0
        ? 0
        : students.filter(({ userID }) =>
            pollingActivityQuestions.every((paq) =>
              paq.pollingActivityQuestionResponses.some((r) => r.studentID === userID)
            )
          ).length,
    [pollingActivityQuestions, students]
  );

  const numPartiallyResponsiveStudents = useMemo(
    () =>
      pollingActivityQuestions.length === 1
        ? 0
        : students.filter(({ userID }) =>
            pollingActivityQuestions.some((paq) =>
              paq.pollingActivityQuestionResponses.some((r) => r.studentID === userID)
            )
          ).length,
    [pollingActivityQuestions, students]
  );

  const data: StudentResponsePieChartData[] = [];

  data.push({
    label: pollingActivityQuestions.length > 1 ? "Fully Responded" : "Responded",
    numStudents: numResponsiveStudents,
    color: "var(--blue-6)",
  });

  if (
    numPartiallyResponsiveStudents &&
    numPartiallyResponsiveStudents - numResponsiveStudents !== 0
  )
    data.push({
      label: "Partially Responded",
      numStudents: numPartiallyResponsiveStudents - numResponsiveStudents,
      color: "var(--blue-4)",
    });

  if (numResponsiveStudents !== students.length)
    data.push({
      label: "Not Responded",
      numStudents:
        students.length - Math.max(numPartiallyResponsiveStudents, numResponsiveStudents),
      color: "var(--blue-2)",
    });

  const percentRespondedStudents = Math.round((numResponsiveStudents * 100) / students.length);

  return (
    <FlexContainer
      gap="1rem"
      alignItems="center"
      justifyContent="center"
      className="InstructorPollingActivityOverviewResponseRateGraphic"
    >
      <div className="chart-wrapper">
        <PieChart width={150} height={150} className="chart">
          <Pie
            data={data}
            dataKey={"numStudents"}
            nameKey={"label"}
            cx="50%"
            cy="50%"
            outerRadius={75}
            label={false}
            labelLine={false}
            startAngle={90}
            endAngle={-270}
            stroke={"none"}
            animationBegin={25}
            animationDuration={1000}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
        <div className="percentage">{`${percentRespondedStudents}%`}</div>
      </div>
      <div className="legend">
        {data.map((d) => (
          <FlexContainer className="row" key={d.label} alignItems="center">
            <div className="color" style={{ backgroundColor: d.color }} />
            <div className="label">
              {d.label}
              <span className="percentage">{` (${Math.round(
                (d.numStudents * 100) / students.length
              )}%)`}</span>
            </div>
          </FlexContainer>
        ))}
      </div>
    </FlexContainer>
  );
};

export default InstructorPollingActivityOverviewResponseRateGraphic;
