// eslint-disable-next-line import/no-cycle
import api, { ErrorHandlerPackage } from "../api/api";
import { GradeDistinction } from "../models/GradeDistinction";

import { StoreValue } from "./storeValue";

export default class GradeDistinctionStore {
  private gradeDistinctionsForCourseRegistry = new StoreValue<GradeDistinction[]>();

  reset = () => {
    this.gradeDistinctionsForCourseRegistry.reset();
  };

  get gradeDistinctionsForCourse() {
    return this.gradeDistinctionsForCourseRegistry.value;
  }

  hasLoadedGradeDistinctionsForCourse = () =>
    !this.gradeDistinctionsForCourseRegistry.isLoading() &&
    this.gradeDistinctionsForCourseRegistry.fresh(false);

  loadGradeDistinctionsForCourse = async (courseID: string) => {
    if (this.gradeDistinctionsForCourseRegistry.fresh(true)) return;

    this.gradeDistinctionsForCourseRegistry.setLoading(true);

    const gradeDistinctions = await api.GradeDistinctions.listForCourse(courseID);

    this.gradeDistinctionsForCourseRegistry.setValue(gradeDistinctions);

    this.gradeDistinctionsForCourseRegistry.setLoading(false);
  };

  createOrUpdateGradeDistinctions = async (
    courseID: string,
    gradeDistinctions: GradeDistinction[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.GradeDistinctions.createOrUpdate(
      courseID,
      gradeDistinctions,
      errorHandlerPackage
    );

    if (result) {
      this.gradeDistinctionsForCourseRegistry.setValue(result);
    }
  };

  deleteGradeDistinctions = async (
    courseID: string,
    gradeDistinctions: GradeDistinction[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.GradeDistinctions.delete(
      courseID,
      gradeDistinctions,
      errorHandlerPackage
    );

    if (result) {
      let newGradeDistinctions = [...(this.gradeDistinctionsForCourseRegistry.value ?? [])];

      newGradeDistinctions = newGradeDistinctions.filter(({ id }) =>
        gradeDistinctions.every((gd) => gd.id !== id)
      );

      this.gradeDistinctionsForCourseRegistry.setValue(newGradeDistinctions);
    }
  };
}
