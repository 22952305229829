import { observer } from "mobx-react-lite";
import { SyntheticEvent, useEffect } from "react";
import { Dropdown, DropdownProps, Icon } from "semantic-ui-react";
import {
  RaterOption,
  defaultRaterForAllOption,
  defaultRaterOption,
} from "./ViewAssignmentAllSubmissions";
import { useStore } from "../../../../../../stores/store";
import { concatenateUserName } from "../../../../../../utilities/utils";
import { User } from "../../../../../../models/User";

interface AssignRaterToAllDropdownProps {
  courseID: string;
  assignmentID: string;
  teachingTeamForCourse: User[];
  raterOptions: RaterOption[];
}

export const AssignRaterToAllDropdownSkeleton: React.FC = () => (
  <div className="AssignRaterToAllDropdownSkeleton">
    <Icon name={"group"} size="large" />
    <Dropdown
      fluid
      selection
      value={defaultRaterForAllOption}
      options={[
        {
          key: defaultRaterForAllOption,
          text: defaultRaterForAllOption,
          value: defaultRaterForAllOption,
        },
      ]}
      disabled={true}
    />
  </div>
);

const AssignRaterToAllDropdown: React.FC<AssignRaterToAllDropdownProps> = ({
  courseID,
  assignmentID,
  teachingTeamForCourse,
  raterOptions,
}) => {
  const { assignedRaterStore, submissionStore, toastStore } = useStore();
  const {
    submissionLogEntriesForAssignment,
    loadSubmissionLogEntriesForAssignment,
    hasLoadedSubmissionLogEntriesForAssignment,
  } = submissionStore;

  const { loadAssignedRatersByStudent, assignRaterForAllSubmissionsForAssignment } =
    assignedRaterStore;

  useEffect(() => {
    loadSubmissionLogEntriesForAssignment(assignmentID, courseID);
  }, [assignmentID, courseID]);

  if (
    !submissionLogEntriesForAssignment ||
    !hasLoadedSubmissionLogEntriesForAssignment(assignmentID)
  )
    return <AssignRaterToAllDropdownSkeleton />;

  const onAssignRaterToAllStudents = async (
    e: SyntheticEvent<HTMLElement, Event>,
    result: DropdownProps
  ) => {
    if (result.value && result.value !== defaultRaterOption) {
      await assignRaterForAllSubmissionsForAssignment(
        courseID,
        assignmentID,
        result.value.toString()
      );
      const studentIDs = submissionLogEntriesForAssignment?.map((logEntry) => logEntry.userID);
      if (studentIDs) {
        await loadAssignedRatersByStudent(courseID, assignmentID, studentIDs, {
          overrideIfFresh: true,
        });

        const ttUser = teachingTeamForCourse?.find(
          ({ userID }) => userID === result.value?.toString()
        );

        if (ttUser)
          toastStore.showToast(
            `Successfully assigned ${concatenateUserName(
              ttUser.firstName,
              ttUser.lastName,
              ttUser.honorific,
              ttUser.suffix
            )} as the rater to all submissions for this assignment.`
          );
      }
    }
  };

  return (
    <div className="AssignRaterToAllDropdown">
      <Icon name={"group"} size="large" />
      <Dropdown
        fluid
        selection
        value={defaultRaterForAllOption}
        options={[
          ...raterOptions,
          {
            key: defaultRaterForAllOption,
            value: defaultRaterForAllOption,
            text: defaultRaterForAllOption,
          },
        ]}
        onChange={onAssignRaterToAllStudents}
      />
    </div>
  );
};

export default observer(AssignRaterToAllDropdown);
