// eslint-disable-next-line import/no-cycle
import api, { ErrorHandler, ErrorHandlerPackage } from "../api/api";
import { Feedback } from "../models/Feedback";
import { emptyID } from "../utilities/submissionUtils";

import { StoreValue } from "./storeValue";

export default class FeedbackStore {
  private feedbackForSubmissionRegistry = new StoreValue<
    Feedback | null,
    { studentID: string; submissionID: string }
  >();

  hasLoadedFeedbackForSubmission = (
    studentID: string | undefined,
    submissionID: string | undefined
  ) => {
    const studID = studentID || emptyID;
    const subID = submissionID || emptyID;
    return (
      !this.feedbackForSubmissionRegistry.isLoading() &&
      this.feedbackForSubmissionRegistry.fresh(false, { studentID: studID, submissionID: subID })
    );
  };

  reset = () => {
    this.feedbackForSubmissionRegistry.reset();
  };

  get feedbackForSubmission() {
    return this.feedbackForSubmissionRegistry.value;
  }

  loadFeedbackForSubmission = async (
    submissionID: string | undefined,
    studentID: string | undefined,
    courseID: string | undefined
  ) => {
    // if any of the values are undefined, reset the stores
    if (!submissionID || !studentID || !courseID) {
      this.reset();
      this.giveFeedbackForSubmissionRegistryDefaultValues(courseID, studentID, submissionID);
      return;
    }

    // if we've already retrieved the submissions, just return
    if (this.feedbackForSubmissionRegistry.fresh(true, { submissionID, studentID })) return;

    this.feedbackForSubmissionRegistry.setLoading(true, { submissionID, studentID });

    const errorHandler: ErrorHandler = () => {
      this.giveFeedbackForSubmissionRegistryDefaultValues(courseID, studentID, submissionID);
    };

    const errorHandlerPackage: ErrorHandlerPackage = {
      400: errorHandler,
    };

    // retrieve the submissions
    const feedback = await api.Feedback.details(
      studentID,
      submissionID,
      courseID,
      errorHandlerPackage
    );

    this.feedbackForSubmissionRegistry.setAll(feedback, { submissionID, studentID });

    this.feedbackForSubmissionRegistry.setLoading(false);
  };

  createOrUpdateFeedback = async (feedback: Feedback) => {
    api.Feedback.createOrUpdate(feedback);
    this.feedbackForSubmissionRegistry.reset();
  };

  setDefaultFeedbackForAllStudents = async (
    courseID: string,
    assignmentID: string,
    feedback: Feedback
  ) => {
    const result = api.Feedback.setDefaultFeedbackForAllStudents(courseID, assignmentID, feedback);
    this.feedbackForSubmissionRegistry.reset();
    return result;
  };

  setDefaultFeedbackForSpecificStudents = (
    courseID: string,
    assignmentID: string,
    feedback: Feedback,
    studentIDs: string[]
  ) => {
    const result = api.Feedback.setDefaultFeedbackForSpecificStudents(
      courseID,
      assignmentID,
      feedback,
      studentIDs
    );
    this.feedbackForSubmissionRegistry.reset();
    return result;
  };

  private giveFeedbackForSubmissionRegistryDefaultValues = (
    courseID: string | undefined,
    studentID: string | undefined,
    submissionID: string | undefined
  ) => {
    // create some feedback
    const feedback: Feedback = {
      courseID: courseID || emptyID,
      studentID: studentID || emptyID,
      submissionID: submissionID || emptyID,
      id: emptyID,
      assignmentID: emptyID,
      raterID: emptyID,
      createdAt: new Date(Date.now()),
      isDraft: true,
    };
    // store the new object
    this.feedbackForSubmissionRegistry.setAll(feedback, {
      submissionID: feedback.submissionID,
      studentID: feedback.studentID,
    });
    this.feedbackForSubmissionRegistry.setLoading(false);
  };
}
