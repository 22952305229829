import { useMemo } from "react";
import useWindowSize from "../../../../../hooks/useWindowSize";
import { CalendarEntry } from "../../../../../models/CalendarEntry";
import { map } from "../../../../../utilities/collectionUtils";
import { createClassName } from "../../../../../utilities/utils";
import NoValuesFound from "../../../../_common/misc/NoValuesFound";
import FlexContainer from "../../../../_common/style/FlexContainer";
import CalendarEntryCard from "./CalendarEntryCard";
import "./VerticalCalendar.css";

interface VerticalCalendarProps {
  calendarEntries: CalendarEntry[];
}

function getFullDayFromDateString(dateString: string) {
  const day = dateString.substring(0, 3);

  switch (day) {
    case "Sun":
      return "Sunday";
    case "Mon":
      return "Monday";
    case "Tue":
      return "Tuesday";
    case "Wed":
      return "Wednesday";
    case "Thu":
      return "Thursday";
    case "Fri":
      return "Friday";
    default:
      return "Saturday";
  }
}

function getDateFromDateString(dateString: string) {
  return dateString.substring(4, 10);
}

function getYearFromDateString(dateString: string) {
  return dateString.substring(11, 15);
}

const VerticalCalendar: React.FC<VerticalCalendarProps> = ({ calendarEntries }) => {
  const { width } = useWindowSize([700]);

  const dateToEntryMap: Map<string, CalendarEntry[]> = useMemo(() => {
    const currentMap = new Map<string, CalendarEntry[]>();

    const calendarEntriesToUse = [...calendarEntries];

    calendarEntriesToUse.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

    calendarEntriesToUse.forEach((entry) => {
      const startKey = entry.startTime.toDateString();
      const getOrDefault = currentMap.get(startKey) ?? [];

      getOrDefault.push(entry);
      currentMap.set(startKey, getOrDefault);
    });

    return currentMap;
  }, [calendarEntries]);

  return (
    <FlexContainer
      className={createClassName("VerticalCalendar", { name: "mobile", apply: width < 700 })}
      flexDirection="column"
      gap={"4rem"}
    >
      {dateToEntryMap.size === 0 && (
        <NoValuesFound
          iconName="calendar alternate"
          text="There are no calendar events with the filter you specified!"
        />
      )}
      {map(dateToEntryMap, (dateString: string, calendarEntriesForDay: CalendarEntry[]) => (
        <div key={dateString} className="date-section">
          <FlexContainer className="date-text" flexDirection="column">
            <span className="day-name">{getFullDayFromDateString(dateString)}</span>
            <span className="date">{getDateFromDateString(dateString)}</span>
            <span className="year">{getYearFromDateString(dateString)}</span>
          </FlexContainer>
          <FlexContainer
            flexDirection="column"
            justifyContent="center"
            className="entries"
            gap="1em"
          >
            {calendarEntriesForDay.map((calendarEntry) => (
              <CalendarEntryCard calendarEntry={calendarEntry} key={calendarEntry.id} />
            ))}
          </FlexContainer>
        </div>
      ))}
    </FlexContainer>
  );
};

export default VerticalCalendar;
