import _ from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Label, Placeholder, PlaceholderLine, Table } from "semantic-ui-react";
import useValidParams from "../../../../hooks/useValidParameters";
import { Assignment } from "../../../../models/Assignment";
import { Objective } from "../../../../models/Objective";
import { RatedObjective } from "../../../../models/RatedObjective";
import { StudentGradeDistinction } from "../../../../models/StudentGradeDistinction";
import { User } from "../../../../models/User";
import { useStore } from "../../../../stores/store";
import { map } from "../../../../utilities/collectionUtils";
import {
  AssignmentRatingsMappedToMasteryLevel,
  mapNumTotalRatings,
  mapParentObjectivesToAssignments,
  recoupleRatedAssignments,
} from "../../../../utilities/gradeCalculationUtils";
import CourseRoles from "../../../../utilities/routing/components/routeAuthorizationExtensions/CourseRoles";
import TeachFrontNavLink from "../../../../utilities/routing/components/TeachFrontNavLink";
import { createClassName } from "../../../../utilities/utils";
import AssignmentDropdown from "./AssignmentDropdown";
import ExpandableOverviewColumn from "./ExpandableOverviewColumn";
import "./RosterGradebookTable.css";

// any given row in the table has/needs all this information to display itself and to be sortable
interface SortableRow {
  userID: string;
  user: User;
  name: string;
  gradeDistinction: StudentGradeDistinction | undefined;
  gradeDistinctionRelativeOrderIndex: number | undefined;
  objectiveToAssignmentMap: Map<string, Assignment<RatedObjective>[]>;
  assignmentToMasteryLevelSchemeRatings: Map<
    string,
    Map<string, AssignmentRatingsMappedToMasteryLevel>
  >;
  masteryLevelStats: Map<string, Map<string, MasteryLevelStats>>;
}

// these are the statistics for a specific mastery level within a specific parent objective
interface MasteryLevelStats {
  ratings: number;
  totalRatings: number;
  percentRatings: number;
}

// these allow us to keep track of the sortable data, which column in that data is being sorted (if any),
// and which direction it's sorted
interface StateValues {
  data: SortableRow[];
  column: string;
  direction: "ascending" | "descending" | undefined;
}

const actionColumnDelimiter = ".";

// handles sorting actions
function gradebookSortingReducer(
  state: StateValues,
  action: {
    type: string | undefined;
    column: string;
  }
): StateValues {
  switch (action.type) {
    case "CHANGE_SORT":
      // if the column that was clicked is the same as the column it's already sorted by,
      // simply reverse the results
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(), // reverse the data
          direction: state.direction === "ascending" ? "descending" : "ascending", // toggle the direction
        } as StateValues;
      }

      // if the column doesn't have the delimiter in it, then we can just return the column.
      // otherwise, we need to calculate the column
      // eslint-disable-next-line no-case-declarations
      const iteratee =
        action.column.indexOf(actionColumnDelimiter) < 0
          ? [action.column] // return the column
          : [
              // calculate the column
              function sortRows(row: SortableRow) {
                // split by delimiter
                const splits = action.column.split(actionColumnDelimiter);
                // get index 1: the objective
                const iterateeObjectiveID = splits[1] ?? "";
                // get index 2: the mastery level
                const iterateeMasteryLevelID = splits[2] ?? "";
                // return the result
                return row.masteryLevelStats.get(iterateeObjectiveID)?.get(iterateeMasteryLevelID)
                  ?.percentRatings;
              },
            ];

      // if the column that was clicked on is different than the one that the data was already sorted by,
      // sort the data, then reverse it (because we want it to sort by descending first)
      return {
        column: action.column,
        data: _.sortBy(state.data, iteratee).reverse(),
        direction: "descending",
      } as StateValues;

    // this case should never happen
    default:
      throw new Error();
  }
}

const RosterGradebookTableSkeleton: React.FC = () => (
  <div className="RosterGradebookTableSkeleton">
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
  </div>
);

const RosterGradeBookTable: React.FC = () => {
  const { courseStore, gradeStore, ratingStore, objectiveStore } = useStore();
  const { currentCourseRoster, loadCurrentCourseRoster } = courseStore;
  const { gradeCalculationsForAllStudents, loadGradeCalculationsForAllStudents } = gradeStore;
  const { ratingsForAllAssignmentsForAllStudents, loadRatingsForAllAssignmentsForAllStudents } =
    ratingStore;
  const { allObjectives, loadAllObjectives, hierarchicalObjectives, loadObjectivesHierarchical } =
    objectiveStore;

  const { courseID } = useValidParams<{ courseID: string }>();

  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [expandedColumns, setExpandedColumns] = useState<{ [parentObjectiveID: string]: boolean }>(
    {}
  );
  const [hoveredColumns, setHoveredColumns] = useState<{ [parentObjectiveID: string]: boolean }>(
    {}
  );

  useEffect(() => {
    loadCurrentCourseRoster(courseID);
    loadRatingsForAllAssignmentsForAllStudents(courseID, true);
    loadAllObjectives(courseID);
    loadObjectivesHierarchical(courseID);
  }, [
    courseID,
    loadGradeCalculationsForAllStudents,
    loadCurrentCourseRoster,
    loadRatingsForAllAssignmentsForAllStudents,
    loadAllObjectives,
    loadObjectivesHierarchical,
    ratingsForAllAssignmentsForAllStudents,
  ]);

  useEffect(() => {
    loadGradeCalculationsForAllStudents(courseID);
  }, [courseID, gradeCalculationsForAllStudents, loadGradeCalculationsForAllStudents]);

  // initial state related to sorting. No column is currently sorted, there is no data, and no direction.
  const initialStateValues = {
    column: "",
    data: [],
    direction: undefined,
  } as StateValues;

  // tell react that we want it to track changes of this state and modify the rendering appropriately
  const [state, dispatch] = React.useReducer(gradebookSortingReducer, initialStateValues);
  const { column, data, direction } = state;

  if (
    !hierarchicalObjectives ||
    !currentCourseRoster ||
    !ratingsForAllAssignmentsForAllStudents ||
    !gradeCalculationsForAllStudents ||
    !allObjectives
  )
    return <RosterGradebookTableSkeleton />;

  if (gradeCalculationsForAllStudents.size === 0) {
    return <p>There are no students enrolled in the course.</p>;
  }

  // this is the data that will be used to render and sort the table
  const getTableDataForSorting = () =>
    currentCourseRoster
      ?.filter((m) => CourseRoles.StudentRoles.includes(m.courseRole as string))
      .map((user) => {
        const { firstName, lastName, userID } = user;
        const name = `${lastName}, ${firstName}`;
        const gradeDistinction = gradeCalculationsForAllStudents?.get(userID);
        const gradeDistinctionRelativeOrderIndex =
          gradeDistinction?.gradeDistinction?.relativeOrderIndex;

        // convert the assessment ratings into a convenient format for display
        const assignmentRatings = recoupleRatedAssignments(
          userID,
          ratingsForAllAssignmentsForAllStudents,
          allObjectives
        );
        const {
          assignmentToMasteryLevelSchemeRatings,
          parentObjectiveToAssignmentMap: objectiveToAssignmentMap,
        } = assignmentRatings;

        const ratingsStats: Map<string, Map<string, MasteryLevelStats>> = new Map();

        // loop through each parent objective and add add its statistics to ratings stats
        map(hierarchicalObjectives, (parentObjectiveID, parentObjective) => {
          const { masteryLevelScheme, id } = parentObjective;

          // do more convenience grade manipulations
          const { ratings, numTotalUnexcusedRatings } = mapNumTotalRatings(
            assignmentToMasteryLevelSchemeRatings.get(id),
            masteryLevelScheme
          );

          // collate statistics for each mastery level within the parent objective
          masteryLevelScheme?.masteryLevels.forEach((masteryLevel) => {
            const numRatings = ratings[masteryLevel.id] ?? 0;
            // if there isn't already an entry for parentObjectiveID, create one
            ratingsStats.set(parentObjectiveID, ratingsStats.get(parentObjectiveID) ?? new Map());
            // set the stats
            ratingsStats.get(parentObjectiveID)?.set(masteryLevel.id, {
              ratings: numRatings,
              totalRatings: numTotalUnexcusedRatings,
              percentRatings: numTotalUnexcusedRatings
                ? Math.round((numRatings / numTotalUnexcusedRatings) * 100)
                : 0,
            } as MasteryLevelStats);
          });
        });

        // create the sortable row object from all the variables we created above
        return {
          userID,
          name,
          user,
          gradeDistinction,
          gradeDistinctionRelativeOrderIndex,
          objectiveToAssignmentMap,
          assignmentToMasteryLevelSchemeRatings,
          masteryLevelStats: ratingsStats,
        } as SortableRow;
      }) ?? [];

  // the first time this code is run, data will be an empty array because none of the required data
  // had been loaded yet. If that's the case, add the data now.
  if (data.length === 0) {
    data.length = 0;
    data.push(...getTableDataForSorting());
  }

  let parentObjectiveToAssignmentMap: undefined | Map<string, Assignment<RatedObjective>[]>;

  // get the assignments associated with a parent objective
  const getAssignments = (parentObjectiveID: string) => {
    if (parentObjectiveToAssignmentMap !== undefined)
      return parentObjectiveToAssignmentMap.get(parentObjectiveID) as Assignment<RatedObjective>[];

    parentObjectiveToAssignmentMap = mapParentObjectivesToAssignments(
      ratingsForAllAssignmentsForAllStudents,
      allObjectives
    ).parentObjectiveToAssignmentMap;

    return parentObjectiveToAssignmentMap.get(parentObjectiveID) as Assignment<RatedObjective>[];
  };

  // create one statistics cell for every mastery level for the parent objective
  const CellsForMasteryLevelTotals = (
    parentObjective: Objective,
    stats: Map<string, MasteryLevelStats>,
    size: "big" | "large"
  ) => {
    const { masteryLevelScheme } = parentObjective;

    return (
      <>
        {masteryLevelScheme?.masteryLevels
          .slice()
          .sort((a, b) => a.relativeOrderIndex - b.relativeOrderIndex)
          .map((masteryLevel, index) => {
            const { id: masteryLevelID } = masteryLevel;
            const stat = stats.get(masteryLevelID);

            let ratingFraction = "";

            if (masteryLevel.excludeInGradeCalculations) {
              ratingFraction = `${stat?.ratings ?? 0}`;
            } else {
              ratingFraction = stat && stat.ratings ? `${stat.ratings}/${stat.totalRatings}` : "0";
            }

            const ratingPercentage =
              stat && stat.ratings && !masteryLevel.excludeInGradeCalculations
                ? `(${stat.percentRatings}%)`
                : "";

            const ratingFractionClassName =
              stat && stat.ratings ? "blank bold" : "blank greyed-out";

            const ratingPercentageClassName = "blank";

            return (
              <Table.Cell
                key={`total-ratings-${masteryLevelID}`}
                textAlign="center"
                className={index === 0 ? "border-left" : ""}
              >
                <Label className={ratingFractionClassName} size={size}>
                  {ratingFraction}
                </Label>
                <Label className={ratingPercentageClassName} size={size}>
                  {ratingPercentage}
                </Label>
              </Table.Cell>
            );
          })}
      </>
    );
  };

  // return the actual gradebook table
  return (
    <div className="RosterGradebookTable">
      <Table sortable unstackable singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              rowSpan="2"
              content="Student"
              className="first-column"
              sorted={column === "name" ? direction : undefined}
              onClick={() => dispatch({ type: "CHANGE_SORT", column: "name" })}
            />
            <Table.HeaderCell
              rowSpan="2"
              content="Grade Distinction"
              sorted={column === "gradeDistinctionRelativeOrderIndex" ? direction : undefined}
              onClick={() =>
                dispatch({ type: "CHANGE_SORT", column: "gradeDistinctionRelativeOrderIndex" })
              }
            />
            {map(hierarchicalObjectives, (key, parentObjective) => {
              const { masteryLevelScheme, shortName, color } = parentObjective;
              const isExpanded = expandedColumns[parentObjective.id] === true;

              const assignments = getAssignments(parentObjective.id);

              return (
                <Table.HeaderCell
                  key={parentObjective.id}
                  textAlign="center"
                  className="border-left"
                  colSpan={
                    (masteryLevelScheme?.masteryLevels.length || 1) +
                    (isExpanded ? 2 + assignments.length : 1)
                  }
                  content={
                    <Label className={createClassName(color, "rounded-0")} content={shortName} />
                  }
                />
              );
            })}
          </Table.Row>
          <Table.Row className="mastery-level-header">
            {map(hierarchicalObjectives, (key, parentObjective) => {
              const { masteryLevelScheme } = parentObjective;

              return (
                <React.Fragment key={`${key}-mastery-level`}>
                  <>
                    {masteryLevelScheme?.masteryLevels
                      .slice()
                      .sort((a, b) => a.relativeOrderIndex - b.relativeOrderIndex)
                      .map((masteryLevel, index) => {
                        const columnName = `masteryLevel.${parentObjective.id}.${masteryLevel.id}`;
                        return (
                          <Table.HeaderCell
                            key={columnName}
                            className={`${index === 0 ? "border-left" : ""}`}
                            content={masteryLevel.name}
                            textAlign="center"
                            sorted={column === columnName ? direction : undefined}
                            onClick={() => dispatch({ type: "CHANGE_SORT", column: columnName })}
                          />
                        );
                      })}
                    {expandedColumns[parentObjective.id] === true ? (
                      <>
                        <Table.HeaderCell className="expandable-column" />
                        {getAssignments(parentObjective.id).map((assignment) => (
                          <Table.HeaderCell
                            key={assignment.id}
                            textAlign="center"
                            content={
                              <AssignmentDropdown assignment={assignment} courseID={courseID} />
                            }
                          />
                        ))}
                        <Table.HeaderCell className="expandable-column" />
                      </>
                    ) : (
                      <Table.HeaderCell className="expandable-column" />
                    )}
                  </>
                </React.Fragment>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <>
            {data?.map((sortableRow, index) => {
              const { userID, user, name, gradeDistinction } = sortableRow;

              return (
                <Table.Row
                  key={userID}
                  className={createClassName("student-row", {
                    name: "selected-row",
                    apply: selectedRow === index,
                  })}
                >
                  <Table.Cell
                    className="first-column"
                    onClick={() => setSelectedRow(selectedRow === index ? -1 : index)}
                  >
                    <TeachFrontNavLink
                      to={"CourseHomeGradeCalculationsForUserTab"}
                      params={{
                        courseID,
                        userID,
                      }}
                    >
                      {name}
                    </TeachFrontNavLink>
                  </Table.Cell>
                  <Table.Cell
                    content={
                      <Label
                        className="blank"
                        size="large"
                        content={gradeDistinction?.gradeDistinction?.name}
                      />
                    }
                    textAlign="center"
                  />
                  {map(hierarchicalObjectives, (key, parentObjective) => (
                    <React.Fragment key={`cells-for-${key}`}>
                      {CellsForMasteryLevelTotals(
                        parentObjective,
                        sortableRow.masteryLevelStats.get(key) ?? new Map(),
                        "large"
                      )}
                      <ExpandableOverviewColumn
                        key={`${parentObjective.id}-expandable-column`}
                        courseMember={user}
                        parentObjective={parentObjective}
                        expanded={expandedColumns[parentObjective.id] === true}
                        assignmentMap={sortableRow.objectiveToAssignmentMap}
                        setExpanded={(expanded: boolean) =>
                          setExpandedColumns({
                            ...expandedColumns,
                            [parentObjective.id]: expanded,
                          })
                        }
                        hovered={hoveredColumns[parentObjective.id] === true}
                        setHovered={(hovered: boolean) =>
                          setHoveredColumns({
                            ...hoveredColumns,
                            [parentObjective.id]: hovered,
                          })
                        }
                      />
                    </React.Fragment>
                  ))}
                </Table.Row>
              );
            })}
          </>
        </Table.Body>
      </Table>
    </div>
  );
};

export default observer(RosterGradeBookTable);
