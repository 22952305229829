import React, { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Icon,
  Image,
} from "semantic-ui-react";
import { extractTextContent, toSemanticWidth } from "../../../../../utilities/utils";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import { PollingActivityType } from "./PollingAssignmentDetails";
import "./PollingInstructionsModal.css";

interface PollingInstructionsModalProps {
  activityTypes: PollingActivityType[];
}

const PollingInstructionsModal: React.FC<PollingInstructionsModalProps> = ({ activityTypes }) => {
  const [activeActivityKey, setActiveActivityKey] = useState<string>("");

  return (
    <div className="PollingInstructionsModal">
      <span className="click-counter polling-popup">
        <Header as="h3">Polling Assignment Tips</Header>
        <VerticalGap height="1rem" />
      </span>
      <p>
        Polling assignments allow instructors to offer versatile in-class polls to their students,
        supporting pedagogical techniques like peer instruction, think-pair-share, exit tickets,
        etc. Instructors propose questions to students on the board, on your slides, or orally, and
        TeachFront will handle gathering and tallying student responses. Each polling activity can
        be opened or closed spontaneously, with no tedious configuration before class.
      </p>
      <p>
        Within a polling assignment, you can offer any number of polling activities, of any
        combination of types. The types of questions supported include:
      </p>

      <Accordion>
        {activityTypes.map((activityType) => (
          <div className="activity-type-accordion" key={`about-${activityType.key}`}>
            <AccordionTitle
              active={activeActivityKey === activityType.key}
              index={0}
              onClick={() => {
                setActiveActivityKey(
                  activeActivityKey === activityType.key ? "" : activityType.key
                );
              }}
            >
              <Icon name="dropdown" />
              {/* {activityType.icon} */}
              {extractTextContent(activityType.name)}
            </AccordionTitle>
            <AccordionContent active={activeActivityKey === activityType.key}>
              {activityType.description}
              {activityType.images && (
                <Grid stackable>
                  <GridRow columns={toSemanticWidth(activityType.images.length)}>
                    {activityType.images.map((image) => (
                      <GridColumn>
                        <Image src={image} key={image} />
                      </GridColumn>
                    ))}
                  </GridRow>
                </Grid>
              )}
            </AccordionContent>
          </div>
        ))}
      </Accordion>
    </div>
  );
};

export default PollingInstructionsModal;
