import { uniqueId } from "lodash";
import { makeAutoObservable } from "mobx";

export type ToastColor = "red" | "blue" | "green" | "orange";

interface Toast {
  id: string;
  message?: string;
  display: boolean;
  color: ToastColor;
  location: string | undefined;
  action?: ToastAction;
}

type ToastAction = {
  action: () => void;
  text: string;
};

type ToastOptions = {
  locationKey?: string;
  color?: ToastColor;
  action?: ToastAction;
};

const disappearDelayMS = 5000;
const defaultColor: ToastColor = "green";

export default class ToastStore {
  loading = false;

  private toast: Toast = {
    id: "",
    message: "",
    display: false,
    color: "green",
    location: "",
  };

  get toastInstance() {
    return this.toast;
  }

  constructor() {
    makeAutoObservable(this);
  }

  updateLocation = (locationKey: string) => {
    this.toast.location = locationKey;
  };

  showToast = (message: string, toastOptions?: ToastOptions) => {
    const id = uniqueId();
    this.toast.message = message;
    this.toast.location = toastOptions?.locationKey;
    this.toast.display = true;
    this.toast.id = id;
    this.toast.color = toastOptions?.color ?? defaultColor;
    this.toast.action = toastOptions?.action;

    setTimeout(() => {
      if (this.toast.id === id) {
        this.hideToast();
      }
    }, disappearDelayMS);
  };

  hideToast = () => {
    this.toast.message = undefined;
    this.toast.display = false;
    this.toast.color = defaultColor;
    this.toast.location = "";
    this.toast.id = "";
    this.toast.action = undefined;
  };
}
