import Color from "../../../models/Color";
import { useStore } from "../../../stores/store";
import ColorPickerModal from "../../_modal/ColorPickerModal";
import ColorDisplayBox from "./ColorDisplayBox";
import "./ColorPickerInput.css";

interface ColorPickerInputProps {
  currentColor: Color;
  onColorSelect: (color: Color) => void;
  childModalIndex?: number; // If this component is being used in a modal, this index should be defined
}

const ColorPickerInput: React.FC<ColorPickerInputProps> = ({
  currentColor,
  onColorSelect,
  childModalIndex = 0,
}) => {
  const { modalStore } = useStore();

  return (
    <ColorDisplayBox
      color={currentColor}
      onClick={() =>
        modalStore.openModal(
          () => (
            <ColorPickerModal
              currentColor={currentColor}
              onColorSelect={(newColor) => {
                modalStore.closeModal(childModalIndex);
                onColorSelect(newColor);
              }}
            />
          ),
          { showCloseIcon: true, isChildModal: true }
        )
      }
    />
  );
};

export default ColorPickerInput;
