import { Button, Header, Icon } from "semantic-ui-react";
import useCourseColor from "../../../../../hooks/useCourseColor";
import { AssignmentType } from "../../../../../models/AssignmentType";
import { CalendarEntryType } from "../../../../../models/CalendarEntryType";
import Color from "../../../../../models/Color";
import { useStore } from "../../../../../stores/store";
import { createClassName } from "../../../../../utilities/utils";
import FilterLabel from "../../../../_common/form/FilterLabel";
import FlexContainer from "../../../../_common/style/FlexContainer";
import RoundedSegment from "../../../../_common/style/RoundedSegment";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import "./VerticalCalendarPreferences.css";
import ExportCalendarModal from "../_modal/ExportCalendarModal";

interface VerticalCalendarPreferencesProps {
  calendarEntryTypes: CalendarEntryType[];
  assignmentTypes: AssignmentType[];
}

const VerticalCalendarPreferences: React.FC<VerticalCalendarPreferencesProps> = ({
  calendarEntryTypes,
  assignmentTypes,
}) => {
  const courseColor = useCourseColor(Color.GREY);
  const { persistentPreferenceStore, modalStore, userStore } = useStore();
  const { user } = userStore;
  const {
    calendarEntryFilter,
    updateCalendarEntryFilter,
    canClearCalendarEntrySelections,
    canResetCalendarEntrySelections,
    resetCalendarEntryFilter,
    clearCalendarEntryFilter,
  } = persistentPreferenceStore;

  const filterCalendarEntryType = (calendarEntryType: CalendarEntryType) => {
    const { id: entryTypeID } = calendarEntryType;
    const { calendarEntryTypes: cetFilter } = calendarEntryFilter;
    const newCalendarEntryTypes = [...cetFilter];

    const index = newCalendarEntryTypes.indexOf(entryTypeID);
    if (index === -1) newCalendarEntryTypes.push(entryTypeID);
    else newCalendarEntryTypes.splice(index, 1);

    updateCalendarEntryFilter({
      ...calendarEntryFilter,
      calendarEntryTypes: newCalendarEntryTypes,
    });
  };

  const filterAssignmentType = (assignmentType: AssignmentType) => {
    const { id: assignmentTypeID } = assignmentType;
    const { assignmentTypes: atFilter } = calendarEntryFilter;
    const newAssignmentTypes = [...atFilter];

    const index = newAssignmentTypes.indexOf(assignmentTypeID);
    if (index === -1) newAssignmentTypes.push(assignmentTypeID);
    else newAssignmentTypes.splice(index, 1);

    updateCalendarEntryFilter({
      ...calendarEntryFilter,
      assignmentTypes: newAssignmentTypes,
    });
  };

  return (
    <RoundedSegment className="VerticalCalendarPreferences" data-print-id="hide">
      <Header as="h3" content={"Export Calendar"} className="export-header" />
      <Button
        content={"Export Link"}
        icon={"external alternate"}
        onClick={() => {
          modalStore.openModal(<ExportCalendarModal user={user} />);
        }}
        color={"grey"}
      />
      <Header as="h3" content={"Filter Calendar"} className="" />
      <FlexContainer gap="0.5em">
        <strong
          role="button"
          className={createClassName("filter-action-button", {
            name: "disabled",
            apply: !canClearCalendarEntrySelections,
          })}
          onClick={() => canClearCalendarEntrySelections && clearCalendarEntryFilter()}
        >
          <Icon name="x" />
          Clear selections
        </strong>
        <strong
          role="button"
          className={createClassName("filter-action-button", {
            name: "disabled",
            apply: !canResetCalendarEntrySelections,
          })}
          onClick={() => canResetCalendarEntrySelections && resetCalendarEntryFilter()}
        >
          <Icon name="redo" />
          Reset selections
        </strong>
      </FlexContainer>
      <FlexContainer flexWrap="wrap" gap="1em">
        <div>
          {calendarEntryTypes.length > 0 && (
            <>
              <Header as="h4" content={"Calendar Events"} className="events-header" />
              <FlexContainer flexDirection="column" gap="0.25em">
                {calendarEntryTypes.map((calendarEntryType) => (
                  <FilterLabel
                    tag={true}
                    key={calendarEntryType.id}
                    content={calendarEntryType.name}
                    checked={calendarEntryFilter.calendarEntryTypes.includes(calendarEntryType.id)}
                    checkedColor={calendarEntryType.color ?? courseColor}
                    onClick={() => filterCalendarEntryType(calendarEntryType)}
                  />
                ))}
              </FlexContainer>
            </>
          )}
          <VerticalGap height="1em" />
          <Button
            content={
              calendarEntryFilter.showPastCalendarEntries
                ? "Hide Past Calendar Events"
                : "Show Past Calendar Events"
            }
            icon={calendarEntryFilter.showPastCalendarEntries ? "hide" : "eye"}
            onClick={() =>
              updateCalendarEntryFilter({
                ...calendarEntryFilter,
                showPastCalendarEntries: !calendarEntryFilter.showPastCalendarEntries,
              })
            }
            color={calendarEntryFilter.showPastCalendarEntries ? "grey" : "blue"}
          />
        </div>
        {assignmentTypes.length > 0 && (
          <div>
            <Header as="h4" content="Assignment Types" className="events-header" />
            <FlexContainer flexDirection="column" gap="0.25em">
              {assignmentTypes.map((assignmentType) => (
                <FilterLabel
                  tag={true}
                  key={assignmentType.id}
                  content={assignmentType.name}
                  onClick={() => filterAssignmentType(assignmentType)}
                  checked={calendarEntryFilter.assignmentTypes.includes(assignmentType.id)}
                  checkedColor={courseColor}
                />
              ))}
            </FlexContainer>
          </div>
        )}
      </FlexContainer>
    </RoundedSegment>
  );
};

export default VerticalCalendarPreferences;
