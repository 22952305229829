import DocViewer, { DocViewerRenderers, IDocument } from "@cyntler/react-doc-viewer";
import { observer } from "mobx-react-lite";
import { ComponentType, useEffect, useState } from "react";
import { Button, Loader } from "semantic-ui-react";
import { UploadedFile } from "../../../models/UploadedFile";
import { useStore } from "../../../stores/store";
import { UploadedFileCategory } from "../../../stores/uploadedFileStore";
import { getFileExtension } from "../../../utilities/utils";
import "./FileLister.css";

interface FilePreviewProps {
  file: UploadedFile;
  uploadFileCategory: UploadedFileCategory;
}

const FilePreview: React.FC<FilePreviewProps> = ({ file, uploadFileCategory }) => {
  const { uploadedFileStore } = useStore();
  const { getBlobLinkWithCategory, downloadFileWithCategory } = uploadedFileStore;

  const [docs, setDocs] = useState<IDocument[]>([]);
  const loader = <Loader active inline="centered" size="massive" />;

  useEffect(() => {
    const blobLinkPromise = getBlobLinkWithCategory(file, uploadFileCategory);

    blobLinkPromise.then((blobLink) => {
      if (blobLink) {
        const document: IDocument = {
          uri: blobLink,
          fileName: file.originalFileName,
          fileType: getFileExtension(file.originalFileName),
        } as IDocument;

        setDocs([document]);
      }
    });
  }, [file]);

  const FilePreviewSkeleton: ComponentType<{
    document: IDocument | undefined;
    fileName: string;
  }> = () => loader;

  if (docs.length === 0) {
    return loader;
  }

  const NoRenderer: ComponentType<{
    document: IDocument | undefined;
    fileName: string;
  }> = ({ fileName }) => (
    <div className="no-preview-available">
      <p>No preview of {fileName} is available.</p>
      <Button
        className="default-button shape"
        content="Download"
        onClick={() => downloadFileWithCategory(file, uploadFileCategory)}
        type="button"
      />
    </div>
  );

  if (docs.length === 0) {
    return loader;
  }

  const handleDocViewerClick = (e: React.MouseEvent) => {
    // To avoid DocViewer buttons from submitting forms, we catch any bubbled click events and cancel them if they were made on button elements.
    // To find these button elements, we use the event target and search its ancestors until a button is or isn't found.
    if (e.target) {
      const target = e.target as HTMLElement;

      const buttonTagName = "BUTTON";
      let potentialButtonElement: HTMLElement | null = target;

      // To be completely safe with the while loop, only search 10 levels up for buttons.
      let maxDepthCounter = 0;
      while (
        maxDepthCounter < 10 &&
        potentialButtonElement &&
        !potentialButtonElement.classList.contains("react-doc-viewer") && // If it is the parent doc-viewer component, stop looping
        potentialButtonElement.tagName !== buttonTagName
      ) {
        maxDepthCounter += 1;
        potentialButtonElement = potentialButtonElement.parentElement;
      }

      if (potentialButtonElement?.tagName === buttonTagName) {
        e.preventDefault();
      }
    }
  };

  return (
    <div onClick={handleDocViewerClick}>
      <DocViewer
        className="FilePreview"
        pluginRenderers={DocViewerRenderers}
        documents={docs}
        config={{
          header: { disableHeader: true },
          loadingRenderer: {
            overrideComponent: FilePreviewSkeleton,
            showLoadingTimeout: false,
          },
          noRenderer: {
            overrideComponent: NoRenderer,
          },
        }}
      />
    </div>
  );
};

export default observer(FilePreview);
