import { Ref, forwardRef, useEffect, useState } from "react";
import { Header, Icon } from "semantic-ui-react";
import "./TestimonialSection.css";
import TestimonialSlide from "./TestimontialSlide";

const people = [
  {
    id: 1,
    quote:
      "\"TeachFront has allowed me to assign, collect, and assess work in a more equitable way. When I use TeachFront instead of my university-selected LMS, students are focused on improvement and learning instead of perfection. Additionally, I've seen the mental health of my students improve vastly. They're so much less stressed even though my expectations are higher than ever.\"",
    name: "Dr. Stephanie Valentine",
    bio: "Associate Professor of Practice in Computer Science, TeachFront Founder",
  },
  {
    id: 2,
    quote:
      '"I like the ability to re-do assignments because I have made many mistakes while coding, and having the flexibility to learn and adapt the code I wrote helps me learn the most."',
    name: "Anonymous Student In A TeachFront Course",
    bio: "From a Course Evaluation in Fall 2023",
  },
];

const TestimonialSection = forwardRef<HTMLDivElement>((props, ref: Ref<HTMLDivElement>) => {
  const [index, setIndex] = useState(0);

  // Effect to ensure that the index stays within the valid range
  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  // Effect to automatically advance the slider every 5 seconds
  useEffect(() => {
    const slider = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % people.length);
    }, 10000);
    return () => clearInterval(slider);
  }, []); // Pass an empty dependency array to run this effect once when the component mounts

  return (
    <div id="TestimonialSection" className="TestimonialSection" ref={ref}>
      <Header as="h2" className="impact">
        TeachFront Users Want You to Know
      </Header>
      <Icon name="quote left" fitted className="quoteLeft" />
      <Icon name="quote right" fitted className="quoteRight" />
      {people.map((person, personIndex) => (
        <TestimonialSlide
          key={person.id}
          id={person.id}
          quote={person.quote}
          name={person.name}
          bio={person.bio}
          quoteIndex={personIndex}
          index={index}
          length={people.length}
        />
      ))}
      <button className="prev" onClick={() => setIndex(index - 1)}>
        <Icon name="angle left" fitted />
      </button>
      <button className="next" onClick={() => setIndex(index + 1)}>
        <Icon name="angle right" fitted />
      </button>
    </div>
  );
});

export default TestimonialSection;
