import { Assignment } from "./Assignment";
import { CalendarEntryLink } from "./CalendarEntryLink";
import { CalendarEntryType } from "./CalendarEntryType";
import { Objective } from "./Objective";

export enum CalendarEntryContentType {
  CALENDAR_ENTRY = 0,
  ASSIGNMENT_RELEASE_DATE = 1,
  ASSIGNMENT_DUE_DATE = 2,
  ASSIGNMENT_RESUBMISSION_DUE_DATE = 3,
}

export interface CalendarEntry {
  id: string;
  startTime: Date;
  endTime: Date;
  title: string;
  description: string;
  location: string;
  isCanceled: boolean;
  isRequired: boolean;
  courseID: string;
  links: CalendarEntryLink[];
  calendarEntryTypes: CalendarEntryType[];
  calendarEntryContentType?: CalendarEntryContentType;
  assignment?: Assignment<Objective>; // Used for calendar entries that hold assignment data
}
