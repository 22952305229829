import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Header, Image } from "semantic-ui-react";
import logo from "../../assets/logo.svg";
import useQuery from "../../hooks/useQuery";
import { useStore } from "../../stores/store";
import RoundedSegment from "../_common/style/RoundedSegment";
import "./VerifyEmailPage.css";

const VerifyEmailPage: React.FC = () => {
  const { userStore, toastStore } = useStore();
  const { verifyEmail } = userStore;
  const navigate = useNavigate();
  const query = useQuery();
  const [error, setError] = useState<string | undefined>();

  const token = query.get("token");
  const email = query.get("email")?.replaceAll(" ", "+");

  useEffect(() => {
    if (token && email) {
      verifyEmail(token, email, {
        customHandler: () =>
          setError("Something went wrong, we are resending a verification email now."),
      }).then((response) => {
        if (response) {
          navigate("/");
          toastStore.showToast(
            "Your email has been verified. Please log in with your credentials."
          );
        }
      });
    }
  }, [token, email, navigate, toastStore, verifyEmail]);

  if (!token || !email) {
    return <Navigate to={"/"} />;
  }

  return (
    <RoundedSegment className="VerifyEmailPage">
      <Image src={logo} centered size="small" />
      <Header as="h2" content="TeachFront Email Verification" textAlign="center" />
      <strong>{error ?? "Hang tight while we verify your account..."}</strong>
    </RoundedSegment>
  );
};

export default observer(VerifyEmailPage);
