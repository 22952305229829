import { Table } from "semantic-ui-react";
import "./GradeRecipesTableHeader.css";
import { GradeDistinction } from "../../../../../../../models/GradeDistinction";
import Color from "../../../../../../../models/Color";
import { createClassName } from "../../../../../../../utilities/utils";

interface GradeRecipesTableHeaderProps {
  gradeDistinctionsForCourse: GradeDistinction[];
  courseColor: Color;
}

const GradeRecipesTableHeader: React.FC<GradeRecipesTableHeaderProps> = ({
  gradeDistinctionsForCourse,
}) => (
  <Table.Row className="GradeRecipesTableHeader">
    <Table.Cell className="first-cell">To earn a(n)...</Table.Cell>
    {gradeDistinctionsForCourse.map((distinction, index) => (
      <Table.Cell
        key={distinction.id}
        className={createClassName("header data-cell", {
          name: "dark-cell-background",
          apply: index % 2 === 0,
        })}
      >
        {distinction.name}
      </Table.Cell>
    ))}
  </Table.Row>
);

export default GradeRecipesTableHeader;
