import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Icon, Label, Popup, Segment, Table } from "semantic-ui-react";
import useValidParams from "../../../../../../hooks/useValidParameters";
import { Assignment } from "../../../../../../models/Assignment";
import { MasteryLevelScheme } from "../../../../../../models/MasteryLevelScheme";
import { Objective } from "../../../../../../models/Objective";
import { RatedObjective } from "../../../../../../models/RatedObjective";
import { useStore } from "../../../../../../stores/store";
import { getGraphicDataForBestRatings } from "../../../../../../utilities/assessmentGraphicUtils";
import { forEach } from "../../../../../../utilities/collectionUtils";
import { formatDateDownToDay } from "../../../../../../utilities/dateTimeUtils";
import {
  AssignmentRatingsMappedToMasteryLevel,
  recoupleRatedAssignments,
} from "../../../../../../utilities/gradeCalculationUtils";
import TeachFrontNavLink from "../../../../../../utilities/routing/components/TeachFrontNavLink";
import AssessmentSummaryGraphic from "../../../../../_common/assessmentGraphic/AssessmentSummaryGraphic";
// import CellsForMasteryLevelTotals from "./CellsForMasteryLevelTotals";
import { areAnyAssignmentRatingsUnpublished } from "../../../../../../utilities/submissionUtils";
import "./RatedAssignmentsTable.css";

const RatingsForCurrentAssignment = ({
  userID,
  submissionID,
  currentAssignment,
  assignmentRatings,
  masteryLevelSchemes,
  allObjectives,
}: {
  userID: string;
  submissionID: string | undefined;
  currentAssignment: Assignment<RatedObjective>;
  assignmentRatings: Map<string, Map<string, AssignmentRatingsMappedToMasteryLevel>>;
  masteryLevelSchemes: MasteryLevelScheme[];
  allObjectives: Map<string, Objective>;
}) => {
  const ratings: { [masteryLevelID: string]: string[] } = {};
  let unassessed = true;

  // key is objective id of parent objective, value is the ratings
  forEach(assignmentRatings, (key, value) => {
    const theseRatingsMappedToMasteryLevel: AssignmentRatingsMappedToMasteryLevel | undefined =
      value.get(currentAssignment.id) as AssignmentRatingsMappedToMasteryLevel;

    if (theseRatingsMappedToMasteryLevel) {
      unassessed = theseRatingsMappedToMasteryLevel?.unassessed && unassessed;

      Object.keys(theseRatingsMappedToMasteryLevel?.ratings).forEach((masteryLevel) => {
        // save ratings in key
        const r = theseRatingsMappedToMasteryLevel.ratings[masteryLevel];
        if (!ratings[masteryLevel]) ratings[masteryLevel] = [];
        if (r) ratings[masteryLevel] = ratings[masteryLevel]?.concat(r) ?? [];
      });
    }
  });

  const assignmentIsAssessedWithScheme = (masteryLevelScheme: MasteryLevelScheme) =>
    currentAssignment.objectives?.find(
      (o) => o.masteryLevelScheme?.id === masteryLevelScheme.id
    ) !== undefined;

  const popupContent = (usedToAssess: boolean, masteryLevelScheme: MasteryLevelScheme) => {
    if (!usedToAssess) {
      return `${currentAssignment.name} doesn't use ${masteryLevelScheme.name} for assessment.`;
    }
    if (unassessed) {
      return `${currentAssignment.name} doesn't have any published assessments yet. Stay tuned!`;
    }

    return masteryLevelScheme.masteryLevels.map((masteryLevel) => {
      const ratingsLength = ratings[masteryLevel.id]?.length ?? 0;
      const cellValue = `${unassessed && usedToAssess ? "?" : ratingsLength} x ${
        masteryLevel.name
      }`;

      const objectives: Map<string, JSX.Element[]> = new Map();
      ratings[masteryLevel.id]?.forEach((o) => {
        const objective = allObjectives.get(o);
        if (!objective) return;
        const parentObjective = allObjectives.get(objective?.parentID ?? "") ?? objective;

        objectives.set(parentObjective.id, objectives.get(parentObjective.id) ?? []);
        objectives.get(parentObjective.id)?.push(
          <Label
            size={"small"}
            key={`${objective?.id}-${objective?.parentID}`}
            className={objective?.color}
          >
            {objective?.shortName}
          </Label>
        );
      });

      return (
        <Segment key={`${currentAssignment.id}-${masteryLevel.id}`}>
          <Label
            ribbon
            className={`mastery-level-name ${ratingsLength === 0 ? "unused-level" : ""}`}
          >
            {cellValue}
          </Label>

          {Array.from(objectives.keys()).map((o) => (
            <div className="popup-objectives" key={`${currentAssignment.id}-${o}-objectives`}>
              {objectives.get(o)?.length} x {allObjectives.get(o)?.shortName}:
              <br />
              {objectives.get(o)}
            </div>
          ))}
        </Segment>
      );
    });
  };

  const getRatingsSummaryCell = (masteryLevelScheme: MasteryLevelScheme) => {
    const usedToAssess = assignmentIsAssessedWithScheme(masteryLevelScheme);
    return (
      <Table.Cell
        key={`${currentAssignment.id} - ${masteryLevelScheme.id}`}
        textAlign="left"
        className={usedToAssess ? "" : "unused-scheme"}
      >
        <Popup
          position="right center"
          header={`Ratings Breakdown: ${currentAssignment.name}`}
          content={popupContent(usedToAssess, masteryLevelScheme)}
          trigger={
            <div className="assignment-ratings-summary">
              {masteryLevelScheme.masteryLevels.map((masteryLevel) => {
                const ratingsLength = ratings[masteryLevel.id]?.length ?? 0;
                const cellValue = `${unassessed && usedToAssess ? "?" : ratingsLength} x ${
                  masteryLevel.name
                }`;
                return (
                  <p
                    key={`${currentAssignment.id} - ${masteryLevelScheme.id} - ${masteryLevel.id}`}
                    className={`mastery-level-rating ${
                      ratingsLength === 0 ? "unused-level" : "used-level"
                    }`}
                  >
                    {cellValue}
                  </p>
                );
              })}
            </div>
          }
        />
      </Table.Cell>
    );
  };

  return (
    <>
      <Table.Cell>
        <div className="graphic-cell-container">
          {currentAssignment.objectives && (
            <AssessmentSummaryGraphic
              data={getGraphicDataForBestRatings(userID, currentAssignment.objectives)}
              size="6em"
              assignmentID={currentAssignment.id}
              userID={userID}
              submissionID={submissionID}
              linkToSubmission
              unassessedIconSize="2em"
              unpublishedRatingIconSize="1.1em"
              unpublishedRatings={areAnyAssignmentRatingsUnpublished(
                userID,
                currentAssignment.objectives
              )}
            />
          )}
        </div>
      </Table.Cell>
      {masteryLevelSchemes.map((mls) => getRatingsSummaryCell(mls))}
    </>
  );
};

interface RatedAssignmentsTableProps {
  studentID: string;
}

/**
 * Maps data from recoupled Assignments to display an accordion style table system based on
 * the various mastery schemes represented in each assessment.
 */
const RatedAssignmentsTable: React.FC<RatedAssignmentsTableProps> = ({ studentID }) => {
  const { ratingStore, objectiveStore } = useStore();

  const { courseID } = useValidParams<{ courseID: string }>();

  const {
    ratingsForAllAssignmentsForSpecificStudents,
    loadRatingsForAllAssignmentsForSpecificStudents,
  } = ratingStore;

  const { allObjectives, loadAllObjectives } = objectiveStore;

  useEffect(() => {
    loadRatingsForAllAssignmentsForSpecificStudents([studentID], courseID, true);
  }, [
    studentID,
    courseID,
    loadRatingsForAllAssignmentsForSpecificStudents,
    ratingsForAllAssignmentsForSpecificStudents,
  ]);

  useEffect(() => {
    loadAllObjectives(courseID);
  }, [courseID, allObjectives, loadAllObjectives]);

  if (!ratingsForAllAssignmentsForSpecificStudents || !allObjectives) return <></>;

  const recoupledAssignmentRatings = recoupleRatedAssignments(
    studentID,
    ratingsForAllAssignmentsForSpecificStudents,
    allObjectives
  );

  const {
    parentObjectiveMap,
    assignmentToMasteryLevelSchemeRatings,
    assignmentToLatestSubmissionID,
  } = recoupledAssignmentRatings;

  const masteryLevelSchemes: MasteryLevelScheme[] = [];

  forEach(parentObjectiveMap, (key, value) => {
    const { masteryLevelScheme } = value;

    if (
      masteryLevelScheme &&
      !masteryLevelSchemes.find((mls) => mls.id === masteryLevelScheme.id)
    ) {
      masteryLevelSchemes.push(masteryLevelScheme);
    }
  });

  const currentAssignments = ratingsForAllAssignmentsForSpecificStudents;

  return (
    <div className="RatedAssignmentsTable">
      <div className="table-container">
        <Table unstackable={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Assignment Name</Table.HeaderCell>
              <Table.HeaderCell>Due Date(s)</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Assessment</Table.HeaderCell>
              {masteryLevelSchemes.map((scheme) => (
                <Table.HeaderCell key={scheme.id} textAlign="center" className="ratings-header">
                  {scheme?.name} Ratings
                </Table.HeaderCell>
              ))}
              <Table.HeaderCell className="stretch"> </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {currentAssignments?.map((assignment, rowIndex) => (
              <Table.Row className={rowIndex % 2 ? "assignment-data-row" : ""} key={assignment.id}>
                <Table.Cell className="assignment-name-cell">
                  {" "}
                  <TeachFrontNavLink
                    to={"CourseHomeAssignmentDetailsTab"}
                    params={{ courseID, assignmentID: assignment.id }}
                    className="assignment-name"
                  >
                    {assignment.name}
                  </TeachFrontNavLink>
                </Table.Cell>
                <Table.Cell>
                  {" "}
                  <Icon name="calendar alternate outline" />
                  {assignment.dueDate && formatDateDownToDay(assignment.dueDate)}
                  <br />
                  <Icon name="redo" />
                  {assignment.resubmissionDueDate
                    ? formatDateDownToDay(assignment.resubmissionDueDate)
                    : "N/A"}
                </Table.Cell>
                <RatingsForCurrentAssignment
                  userID={studentID}
                  submissionID={assignmentToLatestSubmissionID.get(assignment.id)}
                  currentAssignment={assignment}
                  assignmentRatings={assignmentToMasteryLevelSchemeRatings}
                  masteryLevelSchemes={masteryLevelSchemes}
                  allObjectives={allObjectives}
                />
                <Table.Cell> </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default observer(RatedAssignmentsTable);
