import RoundedSegment from "../components/_common/style/RoundedSegment";
import ViewAssignmentsForCourse from "../components/courses/[courseID]/assignments/ViewAssignmentsForCourse";

const CourseHomeAssignmentsTab: React.FC = () => (
  <RoundedSegment>
    <ViewAssignmentsForCourse />
  </RoundedSegment>
);

export default CourseHomeAssignmentsTab;
