import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { UploadedFile } from "../../../models/UploadedFile";
import { useStore } from "../../../stores/store";
import { UploadedFileCategory } from "../../../stores/uploadedFileStore";
import HorizontalIndent from "../style/spacing/HorizontalIndent";
import "./FileLister.css";
import ListedFile from "./ListedFile";

interface FileListerProps {
  courseID: string;
  connectedComponentID: string;
  uploadFileCategory: UploadedFileCategory;
  userID: string;
  header?: JSX.Element;
  hideIfNoFiles?: boolean;
  noFilesMessage?: string;
  showFilePreviewer?: boolean;
}

const FileLister: React.FC<FileListerProps> = ({
  courseID,
  connectedComponentID,
  uploadFileCategory,
  userID,
  header,
  hideIfNoFiles = false,
  noFilesMessage,
  showFilePreviewer = false,
}) => {
  const { uploadedFileStore } = useStore();
  const {
    hasLoadedUploadedFiles,
    loadUploadedFilesForSubmission,
    loadUploadedFilesForAssignment,
    loadUploadedFilesForPage,
    loadUploadedFilesForOriginalDraftSubmission,
    loadUploadedFilesForTeachingTeamUploadedSubmission,
    uploadedFilesForSubmission,
    uploadedFilesForAssignment,
    uploadedFilesForPage,
    originalDraftSubmissionUploadedFiles,
    uploadedFilesForTeachingTeamUploadedSubmission,
  } = uploadedFileStore;

  let uploadedFiles: UploadedFile[] | undefined;
  switch (uploadFileCategory) {
    case "submission":
      uploadedFiles = uploadedFilesForSubmission;
      break;
    case "page":
      uploadedFiles = uploadedFilesForPage;
      break;
    case "originalDraftSubmission":
      uploadedFiles = originalDraftSubmissionUploadedFiles;
      break;
    case "teachingTeamUploadedSubmission":
      uploadedFiles = uploadedFilesForTeachingTeamUploadedSubmission;
      break;
    default:
      uploadedFiles = uploadedFilesForAssignment;
      break;
  }

  useEffect(() => {
    if (connectedComponentID && uploadFileCategory === "submission")
      loadUploadedFilesForSubmission(connectedComponentID, courseID, userID);
    else if (connectedComponentID && uploadFileCategory === "teachingTeamUploadedSubmission")
      loadUploadedFilesForTeachingTeamUploadedSubmission(connectedComponentID, courseID);
    else if (connectedComponentID && uploadFileCategory === "assignment")
      loadUploadedFilesForAssignment(connectedComponentID, courseID);
    else if (connectedComponentID && uploadFileCategory === "page")
      loadUploadedFilesForPage(connectedComponentID, courseID);
    else if (connectedComponentID && uploadFileCategory === "originalDraftSubmission")
      loadUploadedFilesForOriginalDraftSubmission(connectedComponentID, courseID, userID);
  }, [
    courseID,
    userID,
    connectedComponentID,
    uploadFileCategory,
    loadUploadedFilesForSubmission,
    loadUploadedFilesForAssignment,
    loadUploadedFilesForPage,
  ]);
  if ((uploadedFiles === null || uploadedFiles?.length === 0) && hideIfNoFiles) {
    return <></>;
  }

  // there are files to display
  return (
    <>
      {header ?? <></>}
      <HorizontalIndent>
        <div className={"FileLister"}>
          {(!uploadedFiles ||
            !hasLoadedUploadedFiles(connectedComponentID, uploadFileCategory)) && (
            <Loader active={true} inline size={"small"} />
          )}
          {noFilesMessage &&
            hasLoadedUploadedFiles(connectedComponentID, uploadFileCategory) &&
            uploadedFiles &&
            uploadedFiles.length === 0 && <>{noFilesMessage}</>}
          {hasLoadedUploadedFiles(connectedComponentID, uploadFileCategory) &&
            uploadedFiles &&
            uploadedFiles.length > 0 && (
              <>
                {uploadedFiles.map((file, index) => (
                  <ListedFile
                    key={index}
                    file={file}
                    index={index}
                    uploadFileCategory={uploadFileCategory}
                    showFilePreviewer={showFilePreviewer}
                  />
                ))}
              </>
            )}
        </div>
      </HorizontalIndent>
    </>
  );
};

export default observer(FileLister);
