import { PropsWithChildren } from "react";
import RoundedSegment from "../components/_common/style/RoundedSegment";
import SettingsView from "../components/courses/[courseID]/settings/SettingsView";
import useValidParams from "../hooks/useValidParameters";

const CourseHomeSettingsView: React.FC<PropsWithChildren> = ({ children }) => {
  const { courseID } = useValidParams<{
    courseID: string;
  }>();

  return (
    <RoundedSegment>
      <SettingsView courseID={courseID} children={children} />
    </RoundedSegment>
  );
};

export default CourseHomeSettingsView;
