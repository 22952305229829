import { Label, LabelProps, SemanticCOLORS } from "semantic-ui-react";
import Color from "../../../models/Color";
import { createClassName } from "../../../utilities/utils";
import "./FilterLabel.css";

interface FilterLabelProps extends LabelProps {
  checked: boolean;
  checkedColor: Color;
  uncheckedColor?: Color;
}

const FilterLabel: React.FC<FilterLabelProps> = ({
  checked,
  checkedColor,
  uncheckedColor = Color.GREY,
  ...props
}) => (
  <Label
    {...props}
    className={createClassName("FilterLabel")}
    color={checked ? (checkedColor as SemanticCOLORS) : (uncheckedColor as SemanticCOLORS)}
  />
);

export default FilterLabel;
