import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Header } from "semantic-ui-react";
import useValidParams from "../../../../hooks/useValidParameters";
import { useStore } from "../../../../stores/store";
import LoadingComponent from "../../../../utilities/routing/components/LoadingComponent";
import { SubmissionPackage } from "../../../../utilities/submissionUtils";
import { isQuillEmpty } from "../../../../utilities/utils";
import FileLister from "../../../_common/file/FileLister";
import CopyableField from "../../../_common/form/CopyableReadonlyField";
import HorizontalIndent from "../../../_common/style/spacing/HorizontalIndent";
import UserInputDisplay from "../../../_common/textEditing/UserInputDisplay";
import "./AssignmentSubmissionContents.css";
import "./AssignmentSubmissionForm.css";
import LinkPreview from "./LinkPreview/LinkPreview";

interface AssignmentSubmissionContentsProps {
  submissionPackage: SubmissionPackage;
  showTeachingTeamUploadedFiles?: boolean;
}

const AssignmentSubmission: React.FC<AssignmentSubmissionContentsProps> = ({
  submissionPackage,
  showTeachingTeamUploadedFiles = true,
}) => {
  const { courseID } = useValidParams<{
    courseID: string;
    assignmentID: string;
  }>();

  const { courseStore } = useStore();
  const { roster, loadCurrentCourseRoster, hasLoadedRoster } = courseStore;

  useEffect(() => {
    loadCurrentCourseRoster(courseID);
  }, [loadCurrentCourseRoster, courseID]);

  if (!roster || !hasLoadedRoster(courseID))
    return <LoadingComponent content="Loading student information..." />;

  return (
    <div className="AssignmentSubmissionContents">
      {submissionPackage.submission?.assignment?.allowedSubmissionFormat?.includes("Text") && (
        <>
          <Header as="h3">Submission Text:</Header>
          <HorizontalIndent>
            {!isQuillEmpty(submissionPackage.submission?.data) ? (
              <>
                <UserInputDisplay content={submissionPackage.submission?.data} />
              </>
            ) : (
              <>This submission has no text.</>
            )}
          </HorizontalIndent>
        </>
      )}
      {submissionPackage.submissionID &&
        submissionPackage.submission?.assignment?.allowedSubmissionFormat?.includes(
          "FileUploads"
        ) && (
          <>
            <Header as="h3">Submission Files:</Header>
            <FileLister
              userID={submissionPackage.userID}
              connectedComponentID={submissionPackage.submissionID}
              courseID={courseID}
              uploadFileCategory={"submission"}
              noFilesMessage={"This submission has no files."}
              showFilePreviewer
            />
          </>
        )}
      {submissionPackage.submission?.assignment?.allowedSubmissionFormat?.includes("URL") && (
        <>
          <Header as="h3">Submission Web Link:</Header>
          <HorizontalIndent>
            {submissionPackage.submission?.url ? (
              <>
                <CopyableField value={submissionPackage.submission?.url} />
                <LinkPreview url={submissionPackage.submission?.url ?? ""} />
              </>
            ) : (
              <>This assignment has no link.</>
            )}{" "}
          </HorizontalIndent>
        </>
      )}
      {showTeachingTeamUploadedFiles && (
        <FileLister
          userID={""}
          connectedComponentID={submissionPackage.submissionID ?? ""}
          courseID={courseID}
          uploadFileCategory="teachingTeamUploadedSubmission"
          header={<Header as="h3">Submission Files Uploaded by Teaching Team:</Header>}
          hideIfNoFiles
          showFilePreviewer
        />
      )}
    </div>
  );
};

export default observer(AssignmentSubmission);
