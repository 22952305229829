import { Button } from "semantic-ui-react";
import { MasteryLevelScheme } from "../../../../../models/MasteryLevelScheme";
import { createClassName } from "../../../../../utilities/utils";
import FlexContainer from "../../../../_common/style/FlexContainer";
import "./MasteryLevelPicker.css";

interface MasteryLevelPickerProps {
  masteryLevelScheme: MasteryLevelScheme;
  currentMasteryLevelID: string | undefined;
  selectMasteryLevelID: (masteryLevelID: string) => void;
}

const MasteryLevelPicker: React.FC<MasteryLevelPickerProps> = ({
  masteryLevelScheme,
  currentMasteryLevelID,
  selectMasteryLevelID,
}) => (
  <FlexContainer
    flexWrap="wrap"
    gap="0.1rem"
    justifyContent="space-between"
    className="MasteryLevelPicker"
  >
    {masteryLevelScheme.masteryLevels.map((ml) => (
      <Button
        color="grey"
        content={ml.name}
        type="button"
        className={createClassName("mastery-level-button", {
          name: "selected",
          apply: currentMasteryLevelID === ml.id,
        })}
        onClick={() => selectMasteryLevelID(ml.id)}
        key={ml.id}
      />
    ))}
  </FlexContainer>
);

export default MasteryLevelPicker;
