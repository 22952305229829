import { HTMLAttributes } from "react";
import { removeKeysFromObject } from "../../../utilities/utils";

interface FlexContainerProps extends HTMLAttributes<HTMLDivElement> {
  flexDirection?: "row" | "column";
  alignItems?: "center" | "end" | "start" | "end" | "stretch";
  justifyContent?: "center" | "space-between" | "right";
  flexWrap?: "wrap" | "nowrap";
  gap?: string;
}

const FlexContainer: React.FC<FlexContainerProps> = (props) => (
  <div
    style={{
      display: "flex",
      flexDirection: props.flexDirection,
      justifyContent: props.justifyContent,
      alignItems: props.alignItems,
      gap: props.gap,
      flexWrap: props.flexWrap,
    }}
    {...removeKeysFromObject(
      props,
      "flexDirection",
      "alignItems",
      "justifyContent",
      "gap",
      "flexWrap"
    )}
  >
    {props.children}
  </div>
);

export default FlexContainer;
