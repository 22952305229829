import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Accordion, Header } from "semantic-ui-react";
import { useStore } from "../../../../../../stores/store";
import { PollingActivity } from "../../../../../../models/polling/PollingActivity";
import HorizontalIndent from "../../../../../_common/style/spacing/HorizontalIndent";
import PollingActivityAccordion from "./PollingActivityAccordion";
import "./PollingActivitiesForAssignment.css";
import useCurrentUserCourseRole from "../../../../../../hooks/useCurrentUserCourseRole";

interface PollingActivitiesForAssignmentProps {
  assignmentID: string;
  courseID: string;
  activeAccordionID: string;
  setActiveAccordionID: (index: string) => void;
  activePollingActivity?: PollingActivity;
}

const PollingActivitiesForAssignmentSkeleton: React.FC = () => (
  <div className="PollingActivitiesForAssignmentSkeleton"></div>
);

const PollingActivitiesForAssignment: React.FC<PollingActivitiesForAssignmentProps> = ({
  assignmentID,
  courseID,
  activeAccordionID,
  setActiveAccordionID,
  activePollingActivity,
}) => {
  const { pollingStore, courseStore, userStore } = useStore();
  const { user } = userStore;
  const { roster, loadCurrentCourseRoster, hasLoadedRoster } = courseStore;

  const {
    pollingActivitiesForAssignment,
    loadPollingActivitiesForAssignment,
    hasLoadedPollingActivitiesForAssignment,
  } = pollingStore;
  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);

  useEffect(() => {
    loadPollingActivitiesForAssignment(courseID, assignmentID, true);
  }, [courseID, assignmentID]);

  useEffect(() => {
    if (isUserTeachingTeam) loadCurrentCourseRoster(courseID);
  }, [courseID]);

  const handleAccordionClick = (pollingActivityID: string) => {
    setActiveAccordionID(pollingActivityID === activeAccordionID ? "" : pollingActivityID);
  };

  if (
    !pollingActivitiesForAssignment ||
    !hasLoadedPollingActivitiesForAssignment(courseID, assignmentID) ||
    (isUserTeachingTeam && (!roster || !hasLoadedRoster(courseID))) ||
    !user
  )
    return <PollingActivitiesForAssignmentSkeleton />;

  const studentsToDisplay = roster ?? [user];

  return (
    <div className="PollingActivitiesForAssignment">
      <Header as="h3" content="Active Poll:" />
      <HorizontalIndent>
        {activePollingActivity && activePollingActivity.assignmentID === assignmentID ? (
          <Accordion key={activePollingActivity.id}>
            <PollingActivityAccordion
              activeAccordionID={activeAccordionID}
              handleAccordionClick={handleAccordionClick}
              courseID={courseID}
              pollingActivity={activePollingActivity}
              roster={studentsToDisplay}
              index={0}
              isActivePollingActivity={true}
              setActiveAccordionID={setActiveAccordionID}
            />
          </Accordion>
        ) : (
          <span>There is no active polling activity.</span>
        )}
      </HorizontalIndent>
      <Header as="h3" content="Closed Polls:" />
      <HorizontalIndent>
        {pollingActivitiesForAssignment.length > 0 ? (
          <Accordion>
            {pollingActivitiesForAssignment
              .filter((pa) => pa.id !== activePollingActivity?.id)
              .map((pollingActivity, index) => (
                <PollingActivityAccordion
                  key={pollingActivity.id}
                  courseID={courseID}
                  activeAccordionID={activeAccordionID}
                  handleAccordionClick={handleAccordionClick}
                  pollingActivity={pollingActivity}
                  roster={studentsToDisplay}
                  index={index + 1}
                  setActiveAccordionID={setActiveAccordionID}
                />
              ))}
          </Accordion>
        ) : (
          <span>There are no past polling activities.</span>
        )}
      </HorizontalIndent>
    </div>
  );
};

export default observer(PollingActivitiesForAssignment);
