import { observer } from "mobx-react-lite";
import { Header, Label } from "semantic-ui-react";
import RoundedSegment from "../components/_common/style/RoundedSegment";
import { useStore } from "../stores/store";
import TeachFrontNavLink from "../utilities/routing/components/TeachFrontNavLink";

const ErrorPage: React.FC = () => {
  const { commonStore } = useStore();

  return (
    <RoundedSegment textAlign="center" padded>
      <>
        <Header as="h3">
          {commonStore.error ? (
            <>
              {commonStore.error.statusCode} - {commonStore.error.message}
            </>
          ) : (
            <>An error has occurred</>
          )}
        </Header>
        <Label as={TeachFrontNavLink} params={{}} to="Landing" size="medium" className="blue-5">
          Return Home
        </Label>
      </>
    </RoundedSegment>
  );
};

export default observer(ErrorPage);
