/**
 * Convenience method to loop through the entries of a Map.
 * @param m the map to be looped through.
 * @param supplier a supplier function that allows a task to be performed with each key and value pair.
 */
export function forEach<K, V>(m: Map<K, V>, supplier: (key: K, value: V) => void) {
  Array.from(m.keys()).forEach((key) => supplier(key, m.get(key) as V));
}

/**
 * Convenience method to compute a value to a map.
 * @param m the map to be computed to.
 * @param key the key to compute with.
 * @param remappingFunction the remapping function. The second parameter of this function, the value, will either be of type V or undefined depending on if it exists or not.
 */
export function compute<K, V>(
  m: Map<K, V>,
  key: K,
  remappingFunction: (key: K, value: V | undefined) => V
) {
  m.set(key, remappingFunction(key, m.get(key)));
}

/**
 * Maps a map to an array of the mapFunction return type.
 * @param m the map to be mapped.
 * @param mapFunction the function to remap the map.
 * @returns an array of each key and value of the map remapped to type T.
 */
export function map<K, V, T>(m: Map<K, V>, mapFunction: (key: K, value: V) => T): T[] {
  const mappedValues: T[] = [];

  forEach(m, (key, value) => mappedValues.push(mapFunction(key, value)));

  return mappedValues;
}

/**
 * Gets the values of a map as an array.
 * @param m the map to get the values from.
 * @returns all values of the map.
 */
export function getMapValues<K, V>(m: Map<K, V>) {
  return map(m, (key, value) => value);
}

type ObjectKey = string | number | symbol;

/**
 * Convenience method to loop through the key-value pairs of an object.
 * @param object the object to loop through.
 * @param supplier a supplier function that allows a task to be performed with each key and value pair.
 */
export function objectForEach<V>(
  object: { [key: ObjectKey]: V },
  supplier: (key: ObjectKey, value: V) => void
) {
  Object.keys(object).forEach((key) => supplier(key, object[key] as V));
}

/**
 * Convenience method to map the key-value pairs of an object.
 * @param object the object to map.
 * @param mapFunction the remapping function that remaps each key-value pair to type T.
 * @returns a new array of type T created by each key-value pair of the object put through the mapFunction.
 */
export function objectMap<V, T>(
  object: { [key: ObjectKey]: V },
  mapFunction: (key: ObjectKey, value: V) => T
): T[] {
  const mappedValues: T[] = [];

  objectForEach(object, (key, value) => mappedValues.push(mapFunction(key, value)));

  return mappedValues;
}

/**
 * Convenience method to filter the key-value pairs of the object.
 * @param object the object to filter.
 * @param filterFunction the filter function that confirms if the key-value pair should be included in the final array.
 * @returns an array of tuple objects, each value representing a key-value pair that can be looped through.
 */
export function objectFilter<V>(
  object: { [key: ObjectKey]: V },
  filterFunction: (key: ObjectKey, value: V) => boolean
) {
  const filteredValues: { key: ObjectKey; value: V }[] = [];

  objectForEach(
    object,
    (key, value) => filterFunction(key, value) && filteredValues.push({ key, value })
  );

  return filteredValues;
}

/**
 * Convenience method to quickly and nicely get the number of keys in an object; the "size".
 */
export function objectSize(object: object) {
  return Object.keys(object).length;
}

/**
 * Convenience method to run a condition on each key-value pair of an object.
 * @param object the object to be conditioned.
 * @param predicateFunction the predicate function, supplying each key-value pair.
 * @returns true if every predicateFunction returned true for each key-value pair.
 */
export function objectEvery<V>(
  object: { [key: ObjectKey]: V },
  predicateFunction: (key: ObjectKey, value: V) => boolean
): boolean {
  let every = true;

  objectForEach(object, (key, value) => {
    every = every && predicateFunction(key, value);
  });

  return every;
}

/**
 * Convenience method to run a condition on each key-value pair of an object.
 * @param object the object to be conditioned.
 * @param predicateFunction the predicate function, supplying each key-value pair.
 * @returns true if one or more predicateFunction returned true for a key-value pair.
 */
export function objectSome<V>(
  object: { [key: ObjectKey]: V },
  predicateFunction: (key: ObjectKey, value: V) => boolean
): boolean {
  const keys = Object.keys(object);

  for (let i = 0; i < objectSize(object); i += 1) {
    if (predicateFunction(keys[i] as ObjectKey, object[keys[i] as ObjectKey] as V)) return true;
  }

  return false;
}

/**
 * Deep compares two objects recursively.
 * @returns true if the two objects are deeply equal.
 */
export function deepCompareValues(value1: unknown, value2: unknown): boolean {
  // If the two values are primitive or the same reference, they are equal. Return true.
  if (value1 === value2) {
    return true;
  }

  // eslint-disable-next-line prettier/prettier
  const isObject = (o: unknown) => o && typeof o === "object";

  // Any comparison here on should be made between two objects.
  if (!isObject(value1) || !isObject(value2)) return false;

  const isObject1ADate = value1 instanceof Date;
  const isObject2ADate = value2 instanceof Date;

  if (isObject1ADate && isObject2ADate) {
    const date1 = value1 as Date;
    const date2 = value2 as Date;

    return date1.getTime() === date2.getTime();
  }

  const isObject1AnArray = Array.isArray(value1);
  const isObject2AnArray = Array.isArray(value1);

  // Deeply compare arrays
  if (isObject1AnArray || isObject2AnArray) {
    if ((!isObject1AnArray && isObject2AnArray) || (isObject1AnArray && !isObject2AnArray))
      return false;

    const array1 = value1 as unknown[];
    const array2 = value2 as unknown[];

    return (
      array1.length === array2.length &&
      array1.every((value, index) => deepCompareValues(value, array2[index]))
    );
  }

  const object1 = value1 as object;
  const object2 = value2 as object;

  const objKeys1 = Object.keys(object1 as object);
  const objKeys2 = Object.keys(object2 as object);

  if (objKeys1.length !== objKeys2.length) return false;

  return objKeys1.every((key) => {
    // Typescript doesn't allow just using key for accessing an element,
    // so "as keyof typeof" must be used for safety.
    // eslint-disable-next-line prettier/prettier
    const currentValue1 = object1[key as keyof typeof object1];
    // eslint-disable-next-line prettier/prettier
    const currentValue2 = object2[key as keyof typeof object2];

    return deepCompareValues(currentValue1, currentValue2);
  });
}

/**
 * Convenience method to create and map an array based off just a number.
 * This function will create numElements amount of elements that will be mapped by the mapFunction.
 * @param numElements The number of elements to create.
 * @param mapFunction The map function to turn an index into a value.
 * @returns a mapped array.
 */
export function createAndMapElements<T>(numElements: number, mapFunction: (index: number) => T) {
  const elements: T[] = [];

  for (let i = 0; i < numElements; i += 1) {
    elements.push(mapFunction(i));
  }

  return elements;
}

/**
 * Convenience method that joins strings from an array into a comma separated list, utilizing the oxford comma.
 * @param items The items to be joined in the comma separated list.
 * @returns A comma-separated list of the items in the param array.
 */
export function getCommaSeparatedList(items: string[], andString = "and"): string {
  switch (items.length) {
    case 0:
      return "";
    case 1:
      return items[0] ?? "";
    case 2:
      return items.join(` ${andString} `);
    default:
      return `${items.slice(0, -1).join(", ")}, ${andString} ${items[items.length - 1]}`;
  }
}

/**
 * Performs a deep copy of an object.
 * @param object the object to be deep copied.
 * @returns a deep copy of the object.
 */
export function deepCopy<T>(obj: T): T {
  if (obj instanceof Map) {
    return new Map(Array.from(obj.entries()).map(([key, value]) => [key, deepCopy(value)])) as T;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item)) as unknown as T;
  }
  if (obj instanceof Date) {
    return new Date(obj.getTime()) as unknown as T;
  }
  if (typeof obj === "object" && obj !== null) {
    const copiedObj: { [key: string]: unknown } = {};
    Object.keys(obj).forEach((key) => {
      copiedObj[key] = deepCopy((obj as { [key: string]: unknown })[key]);
    });
    return copiedObj as T;
  }
  // Primitive value (string, number, symbol, null, undefined, boolean)
  return obj;
}

/**
 * Inserts or replaces an object in an object array based off a key of the object.
 * @param array the array to have replacement done.
 * @param object the object to be inserted or replaced.
 * @param comparisonKey a key of the object to be compared, probably an id.
 */
export function insertObjectInArrayOfObjects<T extends object>(
  array: T[],
  object: T,
  comparisonKey: keyof T
): void {
  const ac = array;
  const index = array.findIndex((a) => a[comparisonKey] === object[comparisonKey]);

  if (index === -1) array.push(object);
  else ac[index] = object;
}

/**
 * Sorts an array of objects by date, assuming the object has a property that is a date.
 * @param array the array of objects to sort by date.
 * @param dateKey the key of the date in the object
 * @param descending determines if the array should be sorted from earliest to latest if false, or latest to earliest if true
 */
export function sortArrayOfObjectsByDate<T extends object>(
  array: T[],
  dateKey: keyof T,
  descending?: boolean
) {
  array.sort((a, b) => {
    const aDate = a[dateKey] as Date;
    const bDate = b[dateKey] as Date;

    return (aDate.getTime() - bDate.getTime()) * (descending ? -1 : 1);
  });
}
