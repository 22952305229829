import { Icon, SemanticICONS } from "semantic-ui-react";
import FlexContainer from "../style/FlexContainer";
import "./TableSortLabel.css";

interface TableSortLabelProps {
  columnName: string;
  icon?: SemanticICONS;
  text?: string;
}

const TableSortLabel: React.FC<TableSortLabelProps> = ({ icon, text = " ", columnName }) => (
  <FlexContainer className="TableSortLabel" alignItems="center" gap="0.5rem">
    {columnName}
    <FlexContainer className="status" alignItems="center">
      {icon && <Icon name={icon} fitted />}
      {text}
    </FlexContainer>
  </FlexContainer>
);

export default TableSortLabel;
