import { autorun } from "mobx";
import { useEffect, useState } from "react";
import { useStore } from "../stores/store";
import Color from "../models/Color";

export default function useCourseColor(defaultColor: Color): Color {
  const { courseStore } = useStore();
  const [color, setColor] = useState<Color>(
    courseStore.currentCourse?.color ?? defaultColor ?? Color.GREY
  );

  useEffect(() => {
    autorun(() => setColor(courseStore.currentCourse?.color ?? defaultColor ?? Color.GREY));
  }, []);

  return color;
}
