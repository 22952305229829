// heavily adapted from
// https://github.com/Dhaiwat10/react-link-preview/blob/master/src/components/LinkPreview/LinkPreview.tsx

import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Header } from "semantic-ui-react";
import { LinkPreviewMetadata } from "../../../../../models/LinkPreviewMetadata";
import { useStore } from "../../../../../stores/store";
import "./LinkPreview.css";
import URLPreviewSkeleton from "./URLPreviewSkeleton";

export interface LinkPreviewProps {
  url: string;
  showLoading?: boolean;
  className?: string;
  width?: string | number;
  height?: string | number;
  openInNewTab?: boolean;
  onSuccess?: (metadata: LinkPreviewMetadata | null) => void;
}

const LinkPreview: React.FC<LinkPreviewProps> = ({
  url,
  showLoading = false,
  className = "",
  width,
  height,
  openInNewTab = true,
}) => {
  const { linkPreviewStore } = useStore();
  const {
    loadLinkPreviewMetadata,
    hasLoadedLinkPreviewMetadata,
    getLinkPreviewMetadata,
    linkPreviewData,
  } = linkPreviewStore;

  useEffect(() => {
    if (!hasLoadedLinkPreviewMetadata(url)) {
      loadLinkPreviewMetadata(url);
    }
  }, [url, linkPreviewData]);

  const metadata = getLinkPreviewMetadata(url);

  if (showLoading || !metadata || metadata.loading) {
    return <URLPreviewSkeleton width={width} />;
  }

  const { image, description, title, siteName, hostname } = metadata;

  const onClick = () => {
    const browserTarget = openInNewTab ? "_blank" : "_self";
    window.open(url, browserTarget);
  };

  return (
    <div
      onClick={onClick}
      className={`LinkPreview link-preview-container ${className}`}
      style={{ width, height }}
    >
      {image && (
        <div
          style={{
            backgroundImage: `url(${image})`,
          }}
          className="site-preview-image"
        ></div>
      )}
      <div className="lower-container">
        <Header as="h3" className="title">
          {title}
        </Header>
        {description && <span className="description">{description}</span>}
        <div className="site-details">
          {siteName && <>{siteName}</>}
          {siteName && hostname && <> • </>}
          {hostname && <>{hostname}</>}
        </div>
      </div>
    </div>
  );
};

export default observer(LinkPreview);
