import { observer } from "mobx-react-lite";
import { AiOutlineFontSize } from "react-icons/ai";
import { Dropdown, Icon, Menu } from "semantic-ui-react";
import { useStore } from "../../stores/store";
import "./TextSizePickerMenuItem.css";

const trigger = (
  <span>
    <AiOutlineFontSize />
  </span>
);

const TextSizePickerMenuItem: React.FC = () => {
  const { commonStore } = useStore();
  const { fontOptions, fontOption, setFontOption } = commonStore;

  return (
    <Menu.Item className="TextSizePickerMenuItem">
      <Dropdown pointing="top right" trigger={trigger}>
        <Dropdown.Menu>
          {fontOptions.map((fontMenuOption) => (
            <Dropdown.Item
              className={fontMenuOption.className}
              onClick={() => setFontOption(fontMenuOption.className)}
              key={fontMenuOption.className}
            >
              <Icon name={fontOption === fontMenuOption.className ? "check circle" : "circle"} />
              {fontMenuOption.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  );
};

export default observer(TextSizePickerMenuItem);
