// eslint-disable-next-line import/no-cycle
import api from "../api/api";
import { ObjectiveConstraint } from "../models/ObjectiveConstraint";
import { StudentGradeDistinction } from "../models/StudentGradeDistinction";

import { StoreValue } from "./storeValue";

export default class GradeStore {
  private gradeCalculationsForSpecificStudentsRegistry = new StoreValue<
    Map<string, StudentGradeDistinction>,
    { studentIDs: string[] }
  >();

  private gradeCalculationsForAllStudentsRegistry = new StoreValue<
    Map<string, StudentGradeDistinction>
  >();

  private gradeObjectiveConstraintsForCourseRegistry = new StoreValue<ObjectiveConstraint[]>();

  getGradeObjectiveConstraintsForCourse = () =>
    this.gradeObjectiveConstraintsForCourseRegistry.value;

  hasLoadedGradeCalculationsForSpecificStudents = (studentIDs: string[]) =>
    !this.gradeCalculationsForSpecificStudentsRegistry.isLoading() &&
    this.gradeCalculationsForSpecificStudentsRegistry.fresh(false, { studentIDs });

  hasLoadedGradeCalculationsForAllStudents = () =>
    !this.gradeCalculationsForAllStudentsRegistry.isLoading() &&
    this.gradeCalculationsForAllStudentsRegistry.fresh(false);

  hasLoadedGradeObjectiveConstraintsForCourse = () =>
    !this.gradeObjectiveConstraintsForCourseRegistry.isLoading() &&
    this.gradeObjectiveConstraintsForCourseRegistry.fresh(false);

  get gradeCalculationsForSpecificStudents() {
    return this.gradeCalculationsForSpecificStudentsRegistry.value;
  }

  get gradeCalculationsForAllStudents() {
    return this.gradeCalculationsForAllStudentsRegistry.value;
  }

  reset = () => {
    this.gradeCalculationsForSpecificStudentsRegistry.reset();
    this.gradeObjectiveConstraintsForCourseRegistry.reset();
  };

  resetGradeDistinctions = () => {
    this.gradeCalculationsForAllStudentsRegistry.reset();
    this.gradeCalculationsForSpecificStudentsRegistry.reset();
  };

  loadGradeCalculationsForSpecificStudents = async (courseID: string, studentIDs: string[]) => {
    if (this.gradeCalculationsForSpecificStudentsRegistry.fresh(true, { studentIDs })) return;

    this.gradeCalculationsForSpecificStudentsRegistry.setLoading(true, { studentIDs });

    const calculations = await api.GradeDistinctions.calculateForStudents(courseID, studentIDs);

    this.gradeCalculationsForSpecificStudentsRegistry.setAll(
      new Map<string, StudentGradeDistinction>(),
      { studentIDs },
      (v) => calculations.forEach((c) => v.set(c.studentID, c))
    );

    this.gradeCalculationsForSpecificStudentsRegistry.setLoading(false);
  };

  loadGradeCalculationsForAllStudents = async (courseID: string) => {
    if (this.gradeCalculationsForAllStudentsRegistry.fresh(true)) return;

    this.gradeCalculationsForAllStudentsRegistry.setLoading(true);

    const calculations = await api.GradeDistinctions.calculateForAllStudents(courseID);

    this.gradeCalculationsForAllStudentsRegistry.setValue(
      new Map<string, StudentGradeDistinction>(),
      (v) => calculations.forEach((c) => v.set(c.studentID, c))
    );

    this.gradeCalculationsForAllStudentsRegistry.setLoading(false);
  };

  loadGradeObjectiveConstraintsForCourse = async (courseID: string) => {
    if (this.gradeObjectiveConstraintsForCourseRegistry.fresh(true)) return;

    this.gradeObjectiveConstraintsForCourseRegistry.setLoading(true);

    const objectiveConstraints = await api.ObjectiveConstraints.listForCourse(courseID);

    this.gradeObjectiveConstraintsForCourseRegistry.setValue(objectiveConstraints);

    this.gradeObjectiveConstraintsForCourseRegistry.setLoading(false);
  };
}
