import { Label, SemanticCOLORS, Table } from "semantic-ui-react";
import { Objective } from "../../../../../../../models/Objective";
import "./GradeRecipesTableObjectiveRow.css";
import { createClassName } from "../../../../../../../utilities/utils";

interface GradeRecipesTableObjectiveRowProps {
  objective: Objective;
  numberOfGradeDistinctions: number;
}

const GradeRecipesTableObjectiveRow: React.FC<GradeRecipesTableObjectiveRowProps> = ({
  objective,
  numberOfGradeDistinctions,
}) => (
  <Table.Row className="GradeRecipesTableObjectiveRow">
    <Table.Cell>
      <Label ribbon color={objective.color as SemanticCOLORS} content={objective.shortName} />
    </Table.Cell>
    {/* Create empty placeholder cells from the number of grade distinctions */}
    {Array.from(Array(numberOfGradeDistinctions)).map((_, index) => (
      <Table.Cell
        key={index}
        className={createClassName("data-cell", {
          name: "dark-cell-background",
          apply: index % 2 === 0,
        })}
      ></Table.Cell>
    ))}
  </Table.Row>
);

export default GradeRecipesTableObjectiveRow;
