import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Header, Icon } from "semantic-ui-react";
import { AppSkeleton } from "../App";
import { useStore } from "../stores/store";
import { emptyID } from "../utilities/submissionUtils";
import "./AuthenticatedLandingPage.css";
import CourseCard from "./_common/cards/CourseCard";
import RoundedSegment from "./_common/style/RoundedSegment";
import VerticalGap from "./_common/style/spacing/VerticalGap";
import CourseRegistrationModal from "./_modal/CourseRegistrationModal";
import CreateCourseModal from "./_modal/CreateCourseModal";

const AuthenticatedLandingPage: React.FC = () => {
  const { courseStore, userStore, modalStore } = useStore();
  const { user } = userStore;
  const { coursesForUser, loadCoursesForUser } = courseStore;

  useEffect(() => {
    if (user) loadCoursesForUser(emptyID, user.userID);
  }, [coursesForUser, loadCoursesForUser, user]);

  if (!coursesForUser) return <AppSkeleton />;

  const activeCourses = coursesForUser.filter((course) => new Date(course.endDate) >= new Date());
  const pastCourses = coursesForUser
    .filter((course) => new Date(course.endDate) < new Date())
    .sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime());

  return (
    <RoundedSegment className="AuthenticatedLandingPage">
      <Header as="h2" content="Dashboard" />
      {activeCourses.length > 0 && (
        <div>
          <Header as="h3" content="Current Courses" />
          <div className="cards">
            {activeCourses.map((course) => (
              <CourseCard course={course} key={course.id} />
            ))}
          </div>
        </div>
      )}
      <VerticalGap height="2em" />

      <Header as="h3" content="New Courses" />
      <div className="cards">
        <div
          role="button"
          className="join-course rounded-1 ui card-box-shadow card-box-shadow-hover new-course"
          onClick={() => modalStore.openModal(<CourseRegistrationModal />, { showCloseIcon: true })}
        >
          <Icon className="course-icon" name="user plus" size="huge" />
          <Header className="new-course-header" as="h2" content="Join Course" />
        </div>
        <div
          role="button"
          className="rounded-1 ui card-box-shadow card-box-shadow-hover new-course"
          onClick={() => modalStore.openModal(<CreateCourseModal />)}
        >
          <Icon className="course-icon" name="university" size="huge" />
          <Header as="h2" content="Create Course" />
        </div>
      </div>

      <VerticalGap height="4em" />
      {pastCourses.length > 0 && (
        <div>
          <Header as="h3" content="Past Courses" />
          <div className="cards">
            {pastCourses.map((course) => (
              <CourseCard course={course} key={course.id} />
            ))}
          </div>
        </div>
      )}
    </RoundedSegment>
  );
};

export default observer(AuthenticatedLandingPage);
