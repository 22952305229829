import { Form, Formik, FormikErrors, FormikValues } from "formik";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import {
  Button,
  Label,
  Message,
  Placeholder,
  PlaceholderLine,
  SemanticCOLORS,
} from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import useBooleanState from "../../../../../../hooks/useBooleanState";
import useValidParams from "../../../../../../hooks/useValidParameters";
import { GradeRecipeConstraint } from "../../../../../../models/GradeRecipeConstraint";
import GradeRecipeConstraintExtension from "../../../../../../models/GradeRecipeConstraintExtension";
import GradeRecipeConstraintQuantifier from "../../../../../../models/GradeRecipeConstraintQuantifier";
import GradeRecipeConstraintQuantityUnit from "../../../../../../models/GradeRecipeConstraintQuantityUnit";
import { MasteryLevelScheme } from "../../../../../../models/MasteryLevelScheme";
import { Objective } from "../../../../../../models/Objective";
import { useStore } from "../../../../../../stores/store";
import { objectMap, objectSize } from "../../../../../../utilities/collectionUtils";
import { isFormDirty } from "../../../../../../utilities/formUtils";
import { emptyID } from "../../../../../../utilities/submissionUtils";
import CardTypeLabel from "../../../../../_common/cards/_common/CardTypeLabel";
import FlexContainer from "../../../../../_common/style/FlexContainer";
import VerticalGap from "../../../../../_common/style/spacing/VerticalGap";
import ObjectiveGradeConstraintRow from "./ObjectiveGradeConstraintRow";
import "./ObjectiveGradeIngredientForm.css";

interface ObjectiveGradeIngredientFormProps {
  objective: Objective;
  constraintsForCourse: GradeRecipeConstraint[];
}

interface ObjectiveGradeIngredientFormValues extends FormikValues {
  constraintIDs: string[];
}

export const ObjectiveGradeIngredientPlaceholder: React.FC = () => (
  <div className="ObjectiveGradeIngredientForm">
    <CardTypeLabel content="Grade Ingredients For Objective" />
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
  </div>
);

const ObjectiveGradeIngredientForm: React.FC<ObjectiveGradeIngredientFormProps> = ({
  objective,
  constraintsForCourse,
}) => {
  const { courseID } = useValidParams<{ courseID: string }>();
  const { gradeRecipeConstraintStore, toastStore, modalStore } = useStore();
  const { createOrUpdateGradeRecipeConstraints, deleteGradeRecipeConstraints } =
    gradeRecipeConstraintStore;
  const [isSubmitting, setIsSubmitting] = useBooleanState();

  const constraintsForObjective = useMemo(
    () =>
      constraintsForCourse
        .filter((constraint) => constraint.objectiveID === objective.id)
        .map((c) => ({ ...c })),
    [constraintsForCourse]
  );

  const initialValues: ObjectiveGradeIngredientFormValues = {
    constraintIDs: constraintsForObjective.map(({ id }) => id),
  };

  constraintsForObjective.forEach((constraint) => {
    initialValues[`${constraint.id}-quantifier`] = constraint.quantifier;
    initialValues[`${constraint.id}-quantityUnit`] = constraint.quantityUnit;
    initialValues[`${constraint.id}-masteryLevelID`] = constraint.masteryLevelID;
    initialValues[`${constraint.id}-extension`] = constraint.extension;
  });

  const removeConstraintFromValues = (
    constraintID: string,
    values: ObjectiveGradeIngredientFormValues
  ) => {
    const newValues = values;
    delete newValues[`${constraintID}-quantifier`];
    delete newValues[`${constraintID}-quantityUnit`];
    delete newValues[`${constraintID}-masteryLevelID`];
    delete newValues[`${constraintID}-extension`];

    return newValues;
  };

  const handleSubmit = async (values: ObjectiveGradeIngredientFormValues) => {
    const constraintsToSave: GradeRecipeConstraint[] = [];
    const constraintsToDelete: GradeRecipeConstraint[] = [];

    values.constraintIDs.forEach((constraintID) => {
      const constraint: GradeRecipeConstraint = {
        courseID,
        gradeRecipeIngredients: [],
        id: constraintsForObjective.find(({ id }) => id === constraintID)?.id ?? emptyID,
        masteryLevelID: values[`${constraintID}-masteryLevelID`],
        objectiveID: objective.id,
        quantifier: values[`${constraintID}-quantifier`] as GradeRecipeConstraintQuantifier,
        quantityUnit: values[`${constraintID}-quantityUnit`] as GradeRecipeConstraintQuantityUnit,
        extension: values[`${constraintID}-extension`] as GradeRecipeConstraintExtension,
      };
      constraintsToSave.push(constraint);
    });

    constraintsForObjective
      .filter((c) => !values.constraintIDs.includes(c.id))
      .forEach((constraint) => constraintsToDelete.push(constraint));

    setIsSubmitting(true);

    const databaseCalls = [];

    if (constraintsToSave.length > 0)
      databaseCalls.push(createOrUpdateGradeRecipeConstraints(courseID, constraintsToSave));
    if (constraintsToDelete.length > 0)
      databaseCalls.push(deleteGradeRecipeConstraints(courseID, constraintsToDelete));

    await Promise.allSettled(databaseCalls);

    toastStore.showToast("Successfully saved grade ingredients");

    setIsSubmitting(false);
  };

  const handleNewConstraintButtonClicked = (
    values: ObjectiveGradeIngredientFormValues,
    setValues: (values: React.SetStateAction<ObjectiveGradeIngredientFormValues>) => void
  ) => {
    const id = uuidv4();
    const newValues: ObjectiveGradeIngredientFormValues = {
      ...values,
      constraintIDs: [...values.constraintIDs, id],
    };

    newValues[`${id}-quantifier`] = "unselected";
    newValues[`${id}-quantityUnit`] = "unselected";
    newValues[`${id}-masteryLevelID`] = "unselected";
    newValues[`${id}-extension`] = "unselected";

    setValues(newValues);
  };

  const handleDeleteConstraintButtonClicked = async (
    id: string,
    values: ObjectiveGradeIngredientFormValues,
    setValues: (values: React.SetStateAction<ObjectiveGradeIngredientFormValues>) => void
  ) => {
    const existingConstraint = constraintsForObjective.find(({ id: cID }) => cID === id);

    // If the constraint isn't new and it has ingredients, then warn the user before deleting it locally.
    if (existingConstraint && existingConstraint.gradeRecipeIngredients.length > 0) {
      const confirmDelete = await modalStore.openConfirmationModal(
        'Deleting this constraint will remove all of its ingredients for each grade distinction. Are you sure you want to do this? You can undo this change before saving by clicking the "Cancel" button.'
      );

      if (!confirmDelete) {
        return;
      }
    }

    const newValues: ObjectiveGradeIngredientFormValues = removeConstraintFromValues(id, {
      ...values,
      constraintIDs: [...values.constraintIDs].filter((existingID) => existingID !== id),
    });

    setValues(newValues);
  };

  const validate = (
    values: ObjectiveGradeIngredientFormValues
  ): FormikErrors<ObjectiveGradeIngredientFormValues> => {
    const errors: FormikErrors<ObjectiveGradeIngredientFormValues> = {};

    values.constraintIDs.forEach((constraintID) => {
      const quantifier = values[`${constraintID}-quantifier`];
      const quantityUnit = values[`${constraintID}-quantityUnit`];
      const masteryLevelID = values[`${constraintID}-masteryLevelID`];
      const extension = values[`${constraintID}-extension`];

      if (!quantifier || quantifier === "unselected")
        errors.quantifier = "You must select a quantifier for each constraint";

      if (!quantityUnit || quantityUnit === "unselected")
        errors.quantityUnit = "You must select a quantity unit for each constraint";

      if (!masteryLevelID || masteryLevelID === "unselected")
        errors.masteryLevelID = "You must select a mastery level for each constraint";

      if (!extension || extension === "unselected")
        errors.extension = "You must select an extension for each constraint";
    });

    return errors;
  };

  const shouldShowEditButtons = (values: ObjectiveGradeIngredientFormValues) =>
    values.isDraft || isFormDirty(initialValues, values);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      enableReinitialize
    >
      {({ values, setValues, errors }) => (
        <Form className="ObjectiveGradeIngredientForm">
          <CardTypeLabel content="Grade Ingredients For Objective" />
          <Label content={objective.shortName} ribbon color={objective.color as SemanticCOLORS} />
          <VerticalGap height="0.5rem" />
          <FlexContainer flexDirection="column" gap="1rem">
            {values.constraintIDs.map((id) => (
              <ObjectiveGradeConstraintRow
                key={id}
                constraintID={id}
                masteryLevelScheme={objective.masteryLevelScheme as MasteryLevelScheme}
                onDelete={() => handleDeleteConstraintButtonClicked(id, values, setValues)}
              />
            ))}
          </FlexContainer>
          {values.constraintIDs.length > 0 && <VerticalGap height="1rem" />}
          <Button
            type="button"
            content="Add Ingredient"
            icon="plus"
            className="white"
            onClick={() => handleNewConstraintButtonClicked(values, setValues)}
          />
          {errors && objectSize(errors) > 0 && (
            <Message
              negative
              list={objectMap(errors, (key, value) => (
                <li key={key as string}>{value as string}</li>
              ))}
            />
          )}
          {shouldShowEditButtons(values) && (
            <>
              <Button
                color="blue"
                type="submit"
                content={"Save Changes"}
                icon={"checkmark"}
                disabled={objectSize(errors) > 0}
                floated="right"
                loading={isSubmitting}
              />
              <Button
                color="red"
                content="Cancel"
                icon="x"
                onClick={() => setValues(initialValues)}
                type="button"
                floated="right"
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default observer(ObjectiveGradeIngredientForm);
