// eslint-disable-next-line import/no-cycle
import api, { ErrorHandlerPackage } from "../api/api";
import { GradeRecipeConstraint } from "../models/GradeRecipeConstraint";

import { StoreValue } from "./storeValue";

const cleanGradeRecipeConstraintForDatabaseCall = (constraint: GradeRecipeConstraint) => {
  const copy: GradeRecipeConstraint = { ...constraint };

  delete copy.masteryLevel;
  delete copy.objective;

  return copy;
};

export default class GradeRecipeConstraintStore {
  private gradeRecipeConstraintsRegistry = new StoreValue<
    GradeRecipeConstraint[],
    { courseID: string }
  >();

  hasLoadedGradeRecipeConstraints = (courseID: string) =>
    this.gradeRecipeConstraintsRegistry.fresh(false, { courseID });

  get gradeRecipeConstraints() {
    return this.gradeRecipeConstraintsRegistry.value;
  }

  reset = () => {
    this.gradeRecipeConstraintsRegistry.reset();
  };

  loadGradeRecipeConstraints = async (
    courseID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    if (this.gradeRecipeConstraintsRegistry.fresh(true, { courseID })) return;

    this.gradeRecipeConstraintsRegistry.setLoading(true, { courseID });

    const result = await api.GradeRecipeConstraints.listForCourse(courseID, errorHandlerPackage);

    this.gradeRecipeConstraintsRegistry.setAll(result, { courseID });

    this.gradeRecipeConstraintsRegistry.setLoading(false);
  };

  deleteGradeRecipeConstraints = async (
    courseID: string,
    constraints: GradeRecipeConstraint[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.GradeRecipeConstraints.delete(
      courseID,
      constraints.map(cleanGradeRecipeConstraintForDatabaseCall),
      errorHandlerPackage
    );

    if (result) {
      const deletedConstraintIDs = constraints.map(({ id }) => id);
      const newConstraints = [...(this.gradeRecipeConstraintsRegistry.value ?? [])].filter(
        ({ id }) => !deletedConstraintIDs.includes(id)
      );

      this.gradeRecipeConstraintsRegistry.setAll(newConstraints, { courseID });
    }
  };

  createOrUpdateGradeRecipeConstraints = async (
    courseID: string,
    constraints: GradeRecipeConstraint[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.GradeRecipeConstraints.createOrUpdate(
      courseID,
      constraints.map(cleanGradeRecipeConstraintForDatabaseCall),
      errorHandlerPackage
    );

    if (result) {
      const newConstraints = [...(this.gradeRecipeConstraintsRegistry.value ?? [])];

      result.forEach((newConstraint) => {
        const index = newConstraints.findIndex(({ id }) => id === newConstraint.id);

        if (index === -1) newConstraints.push(newConstraint);
        else newConstraints[index] = newConstraint;
      });

      this.gradeRecipeConstraintsRegistry.setAll(newConstraints, { courseID });
    }
  };
}
