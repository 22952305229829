import { observer } from "mobx-react-lite";
import { PropsWithChildren, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useStore } from "../../../../stores/store";
import { AppSkeleton } from "../../../../App";
import useValidParams from "../../../../hooks/useValidParameters";
import CourseRoles, { CourseRoleGroup } from "./CourseRoles";
import TEACHFRONT_PATHS from "../../../../paths";
import { User } from "../../../../models/User";

type RequireCourseRosterAndGroupSetsProps = {
  selectedCourseRoleGroup?: CourseRoleGroup;
  ensureValidGroupSetID?: boolean;
};

export function doesRosterHaveCourseRole(roster: User[], courseRoleGroup: CourseRoleGroup) {
  switch (courseRoleGroup) {
    case CourseRoleGroup.STUDENTS:
      return roster.some((u) => u.courseRole && CourseRoles.StudentRoles.includes(u.courseRole));
    case CourseRoleGroup.INSTRUCTORS:
      return roster.some((u) => u.courseRole && CourseRoles.InstructorRoles.includes(u.courseRole));
    case CourseRoleGroup.TEACHING_ASSISTANTS:
      return roster.some(
        (u) =>
          u.courseRole &&
          !CourseRoles.InstructorRoles.includes(u.courseRole) &&
          CourseRoles.TeachingTeamRoles.includes(u.courseRole)
      );
    case CourseRoleGroup.TEACHING_TEAM:
      return roster.some(
        (u) => u.courseRole && CourseRoles.TeachingTeamRoles.includes(u.courseRole)
      );
    default:
      return true;
  }
}

const RequireCourseRosterAndGroupSets: React.FC<
  PropsWithChildren<RequireCourseRosterAndGroupSetsProps>
> = ({ children, ensureValidGroupSetID, selectedCourseRoleGroup }) => {
  const { courseID } = useValidParams<{ courseID: string }>();
  const { groupSetID } = useParams<{ groupSetID: string }>();
  const { courseStore, groupSetsStore } = useStore();
  const { roster, loadCurrentCourseRoster, hasLoadedRoster } = courseStore;
  const { groupSets, loadGroupSets, hasLoadedGroupSets } = groupSetsStore;

  useEffect(() => {
    loadCurrentCourseRoster(courseID);
  }, [courseID]);

  useEffect(() => {
    loadGroupSets(courseID);
  }, [courseID]);

  if (!roster || !groupSets || !hasLoadedRoster(courseID) || !hasLoadedGroupSets(courseID)) {
    return <AppSkeleton />;
  }

  const validateRosterHasCourseRole = (): boolean => {
    if (selectedCourseRoleGroup === undefined || selectedCourseRoleGroup === CourseRoleGroup.ALL)
      return true;

    // If the roster doesn't have the required course role, redirect
    return doesRosterHaveCourseRole(roster, selectedCourseRoleGroup);
  };

  const validateGroupSetID = () => {
    if (!ensureValidGroupSetID) return true;

    if (!groupSetID) return false;

    // If there are no group sets with this ID, redirect
    return groupSets.find((s) => s.id === groupSetID);
  };

  if (!validateRosterHasCourseRole()) {
    return <Navigate to={TEACHFRONT_PATHS.CourseHomeRosterTab.replace(":courseID", courseID)} />;
  }

  if (!validateGroupSetID()) {
    return (
      <Navigate
        to={TEACHFRONT_PATHS.CourseHomeRosterTabGroupSetNotFound.replace(":courseID", courseID)}
      ></Navigate>
    );
  }

  return <>{children}</>;
};

export default observer(RequireCourseRosterAndGroupSets);
