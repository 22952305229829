import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Icon, Placeholder, PlaceholderLine } from "semantic-ui-react";
import TEACHFRONT_PATHS from "../../paths";
import { useStore } from "../../stores/store";
import { concatenateUserName } from "../../utilities/utils";
import RoundedSegment from "../_common/style/RoundedSegment";
import ProfilePhoto from "../_common/user/ProfilePhoto";
import "./ProfilePage.css";
import ProfilePageSection from "./_common/ProfilePageSection";
import ColorPickerModal from "../_modal/ColorPickerModal";
import Color from "../../models/Color";
import ProfileEditModal from "./_modal/ProfileEditModal";
import UserIconGrey from "../../assets/UesrIconGrey";
import HorizontalIndent from "../_common/style/spacing/HorizontalIndent";

const ProfilePageSkeleton: React.FC = () => (
  <RoundedSegment className="ProfilePageSkeleton">
    <div className="banner">
      <div className="profile-picture">
        <UserIconGrey />
      </div>
    </div>
    <div className="banner-under-filler" />
    <HorizontalIndent indent="2rem">
      <Placeholder>
        <PlaceholderLine />
        <PlaceholderLine />
        <PlaceholderLine />
        <PlaceholderLine />
      </Placeholder>
    </HorizontalIndent>
  </RoundedSegment>
);

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { userStore, modalStore } = useStore();
  const { user, userProfile, loadUserProfile, hasLoadedUserProfile, updateUserProfile } = userStore;
  const { userID } = useParams<{ userID: string }>();

  useEffect(() => {
    loadUserProfile(userID ?? (user?.userID as string), {
      403: () => {
        navigate(TEACHFRONT_PATHS.NotFound);
      },
    });
  }, [loadUserProfile, userID, user, navigate]);

  if (!user || !hasLoadedUserProfile(userID ?? (user?.userID as string)) || !userProfile) {
    return <ProfilePageSkeleton />;
  }

  const userCanEdit = user.userID === userProfile.userID;
  const favoriteColor = userProfile.favoriteColor ?? Color.BLUE;

  return (
    <RoundedSegment className="ProfilePage">
      <div className="profile-section">
        <div
          className="banner"
          style={{
            backgroundColor: `var(--${favoriteColor}-5)`,
          }}
        >
          <div className="profile-picture">
            <ProfilePhoto user={userProfile} enableEditing={userCanEdit} />
          </div>
        </div>
        <div className="banner-under-filler" />
        <div className="profile-section-content">
          {userCanEdit && (
            <Dropdown
              className="edit-profile-button"
              icon={"ellipsis vertical"}
              options={[
                {
                  key: "edit",
                  content: "Edit Profile",
                  icon: "edit",
                  onClick: () => {
                    modalStore.openModal(<ProfileEditModal userProfile={userProfile} />, {
                      showCloseIcon: true,
                    });
                  },
                },
                {
                  key: "color",
                  content: "Change Profile Color",
                  icon: "paint brush",
                  onClick: () => {
                    modalStore.openModal(
                      <ColorPickerModal
                        currentColor={favoriteColor}
                        onColorSelect={async (newColor) => {
                          modalStore.closeModal();
                          updateUserProfile({ ...userProfile, favoriteColor: newColor });
                        }}
                      />,
                      { showCloseIcon: true }
                    );
                  },
                },
              ]}
              direction="left"
            />
          )}
          <div className="full-name">
            {concatenateUserName(
              userProfile.firstName,
              userProfile.lastName,
              userProfile.honorific,
              userProfile.suffix
            )}
          </div>
          {userProfile.title && <div>{userProfile.title}</div>}
          <i className="pronouns">{userProfile.pronouns}</i>
          {userProfile.email && (
            <div className="email">
              <Icon name="mail outline" /> {userProfile.email}
            </div>
          )}
        </div>
      </div>
      {(userCanEdit || userProfile.aboutMe !== "") && (
        <ProfilePageSection
          label="About Me"
          value={userProfile.aboutMe}
          editable={userCanEdit}
          onSave={(newAboutMe) => {
            updateUserProfile({
              ...userProfile,
              aboutMe: newAboutMe,
            });
          }}
        />
      )}
    </RoundedSegment>
  );
};

export default observer(ProfilePage);
