import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Header } from "semantic-ui-react";
import { useStore } from "../../../../../stores/store";
import useCurrentUserCourseRole from "../../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../../hooks/useValidParameters";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import PollingResponseForm from "./_common/PollingResponseForm";
import "./PollingActivityModal.css";
import { PollingActivity } from "../../../../../models/polling/PollingActivity";
import FlexContainer from "../../../../_common/style/FlexContainer";
import TEACHFRONT_PATHS from "../../../../../paths";

interface PollingActivityModalProps {
  activePollingActivity: PollingActivity;
}

const PollingActivityModal: React.FC<PollingActivityModalProps> = ({ activePollingActivity }) => {
  const { courseID } = useValidParams<{ courseID: string }>();
  const navigate = useNavigate();
  const { pollingStore, userStore, modalStore, toastStore } = useStore();
  const {
    pollingActivityQuestionResponsesForSelf,
    loadPollingActivityQuestionResponseForSelf,
    hasLoadedPollingActivityQuestionResponseForSelf,
  } = pollingStore;
  const { user } = userStore;
  const { isUserStudent } = useCurrentUserCourseRole(courseID);

  const { pollingActivityQuestions } = activePollingActivity;

  const [currentQuestionID, setCurrentQuestionID] = useState<string | undefined>();

  useEffect(() => {
    const firstQuestionID = pollingActivityQuestions ? pollingActivityQuestions[0]?.id : undefined;
    setCurrentQuestionID(firstQuestionID);
  }, [activePollingActivity]);

  useEffect(() => {
    if (activePollingActivity && user && isUserStudent && currentQuestionID) {
      loadPollingActivityQuestionResponseForSelf(
        activePollingActivity.courseID,
        activePollingActivity.id,
        user?.userID
      );
    }
  }, [activePollingActivity, currentQuestionID]);

  if (
    !currentQuestionID ||
    !user ||
    !pollingActivityQuestions ||
    pollingActivityQuestions.length === 0
  )
    return <></>;

  const questionIndex = (id: string) => pollingActivityQuestions.findIndex((x) => x.id === id);
  const currentQuestion = pollingActivityQuestions[questionIndex(currentQuestionID)];

  if (!currentQuestion) return <></>;

  if (
    activePollingActivity &&
    isUserStudent &&
    (!pollingActivityQuestionResponsesForSelf ||
      !hasLoadedPollingActivityQuestionResponseForSelf(
        activePollingActivity.courseID,
        activePollingActivity.id,
        user.userID
      ))
  )
    return <></>;

  const response = pollingActivityQuestionResponsesForSelf?.find(
    (r) => r.pollingActivityQuestionID === currentQuestionID
  );

  const onFirstQuestion = currentQuestionID === pollingActivityQuestions[0]?.id;
  const onLastQuestion =
    currentQuestionID === pollingActivityQuestions[pollingActivityQuestions.length - 1]?.id;

  const handleNextQuestion = () => {
    if (!onLastQuestion) {
      const currentQuestionIndex = questionIndex(currentQuestionID);
      const nextQuestion = pollingActivityQuestions[currentQuestionIndex + 1];
      if (nextQuestion) {
        setCurrentQuestionID(nextQuestion.id);
      }
    } else {
      modalStore.closeModal();
      toastStore.showToast("Polling question successfully answered.", {
        color: "green",
        action: {
          text: "View Assignment",
          action: () => {
            navigate(
              TEACHFRONT_PATHS.CourseHomeAssignmentDetailsTab.replace(
                ":courseID",
                activePollingActivity.courseID
              ).replace(":assignmentID", activePollingActivity.assignmentID)
            );
          },
        },
      });
    }
  };
  const handlePreviousQuestion = () => {
    if (!onFirstQuestion) {
      const currentQuestionIndex = questionIndex(currentQuestionID);
      const previousQuestion = pollingActivityQuestions[currentQuestionIndex - 1];
      if (previousQuestion) {
        setCurrentQuestionID(previousQuestion.id);
      }
    }
  };

  return (
    <div className="PollingActivityModal">
      <Header as="h3" content="Polling Activity" className="header" />
      <div className="question-index">
        Question {questionIndex(currentQuestionID) + 1} of {pollingActivityQuestions.length}
      </div>
      <VerticalGap height="1.5rem" />
      <FlexContainer justifyContent="center" alignItems="center">
        <PollingResponseForm
          question={currentQuestion}
          response={response}
          handleNavigateToNextQuestion={handleNextQuestion}
          handleNavigateToPreviousQuestion={handlePreviousQuestion}
          disableNextButton={onLastQuestion}
          disablePreviousButton={onFirstQuestion}
        />
      </FlexContainer>
      <VerticalGap height="1rem" />
    </div>
  );
};

export default observer(PollingActivityModal);
