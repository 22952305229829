import { makeAutoObservable } from "mobx";
import { AssignmentType } from "../models/AssignmentType";
import { CalendarEntryType } from "../models/CalendarEntryType";
import { deepCopy, insertObjectInArrayOfObjects } from "../utilities/collectionUtils";
import { StoreValue } from "./storeValue";

export type CalendarEntryFilter = {
  calendarEntryTypes: string[]; // IDs of calendarEntryTypes
  assignmentTypes: string[]; // IDs of assignment types
  showPastCalendarEntries: boolean;
};

export type AssignmentFilter = {
  assignments: string[]; // IDs of assignments
  assignmentTypes: string[]; // IDs of assignment types
  showPastAssignments: boolean;
  searchValue: string; // the user's input for searching for an assignment
};

const defaultCalendarEntryFilter: CalendarEntryFilter = {
  calendarEntryTypes: [],
  assignmentTypes: [],
  showPastCalendarEntries: false,
};

const defaultAssignmentFilter: AssignmentFilter = {
  assignments: [],
  assignmentTypes: [],
  showPastAssignments: false,
  searchValue: "",
};

export type SortDirection = "up" | "down" | "unset";
const sortDirections: SortDirection[] = ["up", "down", "unset"];

const getNextSortDirection = (d: SortDirection) => {
  const index = sortDirections.indexOf(d);
  const newIndex = index + 1;

  return sortDirections[newIndex === sortDirections.length ? 0 : newIndex] as SortDirection;
};

export type ColumnSortState = {
  sortDirection: SortDirection;
  sortTime: number;
  isSet: boolean;
};

export default class PersistentPreferenceStore {
  private calendarEntryFilterRegistry = new StoreValue<CalendarEntryFilter>();

  private assignmentFilterRegistry = new StoreValue<AssignmentFilter>();

  private calendarEntryTypesCache: CalendarEntryType[] = [];

  private assignmentTypesCache: AssignmentType[] = [];

  private assignmentSubmissionsTableAssessSortCache: ColumnSortState = {
    sortDirection: "down",
    sortTime: Date.now() + 1, // Add 1 to this date to sort assigned to column first
    isSet: true,
  };

  private assignmentSubmissionsTableAssignSortCache: ColumnSortState = {
    sortDirection: "up",
    sortTime: Date.now(),
    isSet: true,
  };

  constructor() {
    makeAutoObservable(this);
    this.calendarEntryFilterRegistry.setValue(deepCopy(defaultCalendarEntryFilter));
    this.assignmentFilterRegistry.setValue(deepCopy(defaultAssignmentFilter));
  }

  setAssignmentTypes = (assignmentTypes: AssignmentType[]) => {
    this.assignmentTypesCache = [...assignmentTypes];
    this.updateCalendarEntryFilter({
      ...this.calendarEntryFilter,
      assignmentTypes: this.assignmentTypesCache.map(({ id }) => id),
    });
    this.updateAssignmentFilter({
      ...this.assignmentFilter,
      assignmentTypes: this.assignmentTypesCache.map(({ id }) => id),
    });
  };

  setCalendarEntryTypes = (calendarEntryTypes: CalendarEntryType[]) => {
    this.calendarEntryTypesCache = [...calendarEntryTypes];
    this.updateCalendarEntryFilter({
      ...(this.calendarEntryFilterRegistry.value as CalendarEntryFilter),
      calendarEntryTypes: this.calendarEntryTypesCache.map(({ id }) => id),
    });
  };

  setNextAssignmentSubmissionsTableAssessSortDirection = () => {
    const sortDirection = getNextSortDirection(
      this.assignmentSubmissionsTableAssessSortCache.sortDirection
    );
    this.assignmentSubmissionsTableAssessSortCache = {
      ...this.assignmentSubmissionsTableAssessSortCache,
      sortDirection,
      isSet: sortDirection !== "unset",
      sortTime: Date.now(),
    };
  };

  setNextAssignmentSubmissionsTableAssignSortDirection = () => {
    const sortDirection = getNextSortDirection(
      this.assignmentSubmissionsTableAssignSortCache.sortDirection
    );
    this.assignmentSubmissionsTableAssignSortCache = {
      ...this.assignmentSubmissionsTableAssignSortCache,
      sortDirection,
      isSet: sortDirection !== "unset",
      sortTime: Date.now(),
    };
  };

  get assignmentSubmissionsTableAssessSort() {
    return this.assignmentSubmissionsTableAssessSortCache;
  }

  get assignmentSubmissionsTableAssignSort() {
    return this.assignmentSubmissionsTableAssignSortCache;
  }

  get calendarEntryFilter() {
    return this.calendarEntryFilterRegistry.value as CalendarEntryFilter;
  }

  get assignmentFilter() {
    return this.assignmentFilterRegistry.value as AssignmentFilter;
  }

  get canClearCalendarEntrySelections() {
    const filter = this.calendarEntryFilter;

    return filter.assignmentTypes.length > 0 || filter.calendarEntryTypes.length > 0;
  }

  get canResetCalendarEntrySelections() {
    const filter = this.calendarEntryFilter;

    return (
      filter.assignmentTypes.length !== this.assignmentTypesCache.length ||
      filter.calendarEntryTypes.length !== this.calendarEntryTypesCache.length
    );
  }

  /**
   * Adds a calendar entry type to the preferences and selects it if it doesn't exist,
   * otherwise this method will update the calendar entry type in the cache
   * @param calendarEntryType
   */
  addOrUpdateCalendarEntryType = (calendarEntryType: CalendarEntryType) => {
    const tempCache = [...this.calendarEntryTypesCache];
    const cacheIndex = tempCache.findIndex(({ id }) => id === calendarEntryType.id);

    if (cacheIndex === -1) tempCache.push(calendarEntryType);
    else tempCache[cacheIndex] = calendarEntryType;

    this.calendarEntryTypesCache = tempCache;

    const filter = this.calendarEntryFilterRegistry.value;

    if (filter) {
      const tempCalendarEntryTypes = [...filter.calendarEntryTypes];
      const filterIndex = tempCalendarEntryTypes.indexOf(calendarEntryType.id);

      if (filterIndex === -1) {
        tempCalendarEntryTypes.push(calendarEntryType.id);
        this.updateCalendarEntryFilter({
          ...filter,
          calendarEntryTypes: tempCalendarEntryTypes,
        });
      }
    }
  };

  addOrUpdateAssignmentType = (assignmentType: AssignmentType) => {
    const tempCache = this.assignmentTypesCache;

    insertObjectInArrayOfObjects(tempCache, assignmentType, "id");

    this.assignmentTypesCache = tempCache;

    const filter = this.assignmentFilter;

    if (filter) {
      const tempAssignmentTypes = [...filter.assignmentTypes];
      if (!tempAssignmentTypes.includes(assignmentType.id)) {
        tempAssignmentTypes.push(assignmentType.id);
        this.updateAssignmentFilter({
          ...filter,
          assignmentTypes: tempAssignmentTypes,
        });
      }
    }
  };

  updateCalendarEntryFilter = (newFilter: CalendarEntryFilter) => {
    this.calendarEntryFilterRegistry.setValue(deepCopy(newFilter));
  };

  updateAssignmentFilter = (newFilter: AssignmentFilter) => {
    this.assignmentFilterRegistry.setValue(deepCopy(newFilter));
  };

  clearCalendarEntryFilter = () => {
    this.calendarEntryFilterRegistry.setValue({ ...defaultCalendarEntryFilter });
  };

  resetCalendarEntryFilter = () => {
    this.updateCalendarEntryFilter({
      assignmentTypes: this.assignmentTypesCache.map(({ id }) => id),
      calendarEntryTypes: this.calendarEntryTypesCache.map(({ id }) => id),
      showPastCalendarEntries: false,
    });
  };
}
