import { observer } from "mobx-react-lite";
import { Header, Icon, Popup, Table } from "semantic-ui-react";
import { Assignment } from "../../../../models/Assignment";
import { Objective } from "../../../../models/Objective";
import { RatedObjective } from "../../../../models/RatedObjective";
import { formatDateDownToMinutes } from "../../../../utilities/dateTimeUtils";
import AssessmentGraphicWithFunctionality from "../../../_common/assessmentGraphic/AssessmentGraphicWithFunctionality";
import "./ExpandableOverviewColumn.css";
import { User } from "../../../../models/User";

interface ExpandableOverviewColumnProps {
  courseMember: User;
  parentObjective: Objective;
  assignmentMap: Map<string, Assignment<RatedObjective>[]>;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  hovered: boolean;
  setHovered: (expanded: boolean) => void;
}

const ExpandableOverviewColumn: React.FC<ExpandableOverviewColumnProps> = ({
  courseMember,
  parentObjective,
  assignmentMap,
  expanded,
  setExpanded,
  hovered,
  setHovered,
}) => {
  const assignments = assignmentMap.get(parentObjective.id);

  if (!assignments) return <Table.Cell />;

  const expandableCell = (arrowOrAngle: string) => (
    <Table.Cell
      onClick={() => {
        setExpanded(!expanded);
        setHovered(false);
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={`expand-button ${hovered ? "highlighted-column" : ""}`}
    >
      <Icon
        name={arrowOrAngle === "arrow" ? "arrows alternate horizontal" : "angle double left"}
        fitted
      />
    </Table.Cell>
  );

  return (
    <>
      {!expanded && expandableCell("arrow")}
      {expanded && expandableCell("angle")}
      {expanded &&
        assignments
          .filter((a) => a.objectives)
          .map((assignment) => (
            <Table.Cell className="assignment-popup-cell" key={assignment.id}>
              <Popup
                key={assignment.id}
                className="assignment-popup"
                content={
                  <div className="assignment-popup-card">
                    <Header as="h4">Assignment: {assignment.name}</Header>
                    {assignment.description && assignment.description}
                    <Icon name="calendar alternate outline" />
                    {assignment.dueDate && formatDateDownToMinutes(assignment?.dueDate)}
                    <p>
                      {" "}
                      <Icon name="redo" />
                      {assignment.resubmissionDueDate
                        ? formatDateDownToMinutes(assignment.resubmissionDueDate)
                        : "N/A"}
                    </p>
                  </div>
                }
                position="top center"
                trigger={
                  <AssessmentGraphicWithFunctionality
                    assignment={assignment}
                    userID={courseMember.userID}
                    alignStar="center"
                  />
                }
              />
            </Table.Cell>
          ))}
      {expanded && expandableCell("angle")}
    </>
  );
};

export default observer(ExpandableOverviewColumn);
