import { observer } from "mobx-react-lite";
import { Dropdown, Icon } from "semantic-ui-react";
import { AppSkeleton } from "../../../../App";
import useBooleanState from "../../../../hooks/useBooleanState";
import useCurrentUserCourseRole from "../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../hooks/useValidParameters";
import { Directory } from "../../../../models/Directory";
import { useStore } from "../../../../stores/store";
import { createClassName } from "../../../../utilities/utils";
import DragAndDropFile from "../../../_common/file/DragAndDropFile";
import "../../../_common/file/FileLister.css";
import HorizontalIndent from "../../../_common/style/spacing/HorizontalIndent";
import CourseFile from "./CourseFile";

interface CourseDirectoryProps {
  directory: Directory;
  preventCollapsing?: boolean;
  editable?: boolean;
}

const CourseDirectory: React.FC<CourseDirectoryProps> = ({
  directory,
  preventCollapsing = false,
  editable = false,
}) => {
  const { uploadedCourseFileStore, courseStore, userStore, toastStore, modalStore } = useStore();
  const { deleteDirectory, createOrUpdateDirectory, mostRecentlyUploadedCourseFileID } =
    uploadedCourseFileStore;
  const { course } = courseStore;
  const { user } = userStore;
  const { courseID } = useValidParams<{ courseID: string }>();

  const [collapsed, setCollapsed] = useBooleanState(!preventCollapsing);

  const { isUserTeachingTeam, isUserStudent } = useCurrentUserCourseRole(courseID);

  if (!user) return <AppSkeleton />;

  const directoryName = directory.name === "" ? course?.shortName : directory.name;

  const handleCollapsedToggleClick = async () => {
    if (!preventCollapsing) setCollapsed(!collapsed);
  };

  const handleRenameDirectoryClick = async () => {
    const newDirectoryName = await modalStore.openInputDialogModal(
      <>Please enter a new name for {directoryName}</>,
      { confirmButtonText: "Rename Folder", defaultValue: directoryName }
    );

    if (newDirectoryName) {
      const updatedDirectory: Directory = {
        id: directory.id,
        courseID: directory.courseID,
        parentID: directory.parentID,
        name: newDirectoryName,
        uploadedFiles: [],
        childDirectories: [],
      };
      const createdDirectory = await createOrUpdateDirectory(updatedDirectory);
      toastStore.showToast(`Successfully updated ${createdDirectory?.name}`);
    }
  };

  const handleDeleteDirectoryClick = async () => {
    const confirmDelete = await modalStore.openConfirmationModal(
      `Are you sure you want to delete ${directoryName}?`
    );
    if (confirmDelete) {
      await deleteDirectory(directory);
      toastStore.showToast(`Successfully deleted ${directoryName}`);
    }
  };

  const handleAddNewDirectoryClick = async () => {
    const newDirectoryName = await modalStore.openInputDialogModal(
      <>Please enter a name for the new folder.</>,
      {
        confirmButtonText: "Create Folder",
      }
    );

    if (newDirectoryName) {
      const newDirectory: Directory = {
        courseID: directory.courseID,
        parentID: directory.id,
        name: newDirectoryName,
        uploadedFiles: [],
        childDirectories: [],
      };
      const createdDirectory = await createOrUpdateDirectory(newDirectory);
      toastStore.showToast(`Successfully created ${createdDirectory?.name}`);
    }
  };

  const getDropdownOptions = () => {
    const dropdownOptions = [];

    if (collapsed) {
      dropdownOptions.push({
        key: "upload",
        content: "Upload New File",
        icon: "upload",
        onClick: () => handleCollapsedToggleClick(),
      });
    }

    dropdownOptions.push({
      key: "add",
      content: "Add Nested Folder",
      icon: "plus",
      onClick: () => handleAddNewDirectoryClick(),
    });

    if (editable) {
      dropdownOptions.push(
        {
          key: "edit",
          content: "Rename This Folder",
          icon: "pencil",
          onClick: async () => handleRenameDirectoryClick(),
        },
        {
          key: "delete",
          content: "Delete This Folder",
          icon: "trash",
          onClick: () => handleDeleteDirectoryClick(),
        }
      );
    }

    return dropdownOptions;
  };

  // there are files to display
  return (
    <div
      className={createClassName("CourseDirectory", {
        name: "expanded-directory",
        apply: !collapsed,
        else: "collapsed-directory",
      })}
    >
      <div
        className={createClassName("existing-file", "directory", {
          name: "newest-item",
          apply: directory.id === mostRecentlyUploadedCourseFileID,
        })}
      >
        <div
          className={createClassName("directory-meta", {
            name: "pointer",
            apply: !preventCollapsing,
          })}
          onClick={handleCollapsedToggleClick}
        >
          {!preventCollapsing && <Icon name={collapsed ? "caret right" : "caret down"} />}
          <Icon className="folder-icon" name={collapsed ? "folder" : "folder open"} size="large" />
          {directoryName}
        </div>
        {isUserTeachingTeam && (
          <Dropdown
            className="options icons"
            icon={"ellipsis vertical"}
            options={getDropdownOptions()}
            direction="left"
          />
        )}
      </div>
      {!collapsed && (
        <HorizontalIndent indent="1.5em" className="file-lister-indent">
          {isUserTeachingTeam && (
            <DragAndDropFile
              uploadFileCategory="course"
              userID={user.userID}
              courseID={courseID}
              directoryID={directory.id}
              directoryName={directoryName}
              connectedComponentID={courseID}
              showUploadComponentByDefault
              showFilesComponent={false}
            />
          )}
          {isUserStudent &&
            directory.childDirectories.length === 0 &&
            directory.uploadedFiles.length === 0 && <div className="no-files">No files.</div>}
          {directory.childDirectories.map((childDirectory) => (
            <CourseDirectory key={childDirectory.id} directory={childDirectory} editable />
          ))}
          {directory.uploadedFiles.map((file) => (
            <CourseFile key={file.id} file={file} />
          ))}
        </HorizontalIndent>
      )}
    </div>
  );
};

export default observer(CourseDirectory);
