const TeachingTeamRoles: string[] = [
  "Instructor",
  "Instructor-Owner",
  "TA",
  "TA (Resubmission Manager)",
];
const InstructorRoles: string[] = ["Instructor", "Instructor-Owner"];
const StudentRoles: string[] = ["Student"];
const ResubmissionManagerRoles: string[] = ["TA (Resubmission Manager)"];
const AllRoles: string[] = [
  "Instructor",
  "Instructor-Owner",
  "TA",
  "TA (Resubmission Manager)",
  "Student",
];

export enum CourseRoleGroup {
  ALL,
  STUDENTS,
  INSTRUCTORS,
  RESUBMISSION_MANAGERS,
  TEACHING_ASSISTANTS,
  TEACHING_TEAM,
}

export default {
  InstructorRoles,
  TeachingTeamRoles,
  ResubmissionManagerRoles,
  StudentRoles,
  AllRoles,
} as const;
