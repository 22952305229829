import { Link, LinkProps } from "react-router-dom";
import TEACHFRONT_PATHS from "../../../paths";
import buildPath from "../buildPath";
import { TeachFrontPageName, TeachFrontPathParams } from "../types";

interface Props<TPage extends TeachFrontPageName> extends LinkProps {
  to: TPage;
  // eslint-disable-next-line prettier/prettier
  params: TeachFrontPathParams<typeof TEACHFRONT_PATHS[TPage]>;
}

const TeachFrontLink = <TPage extends TeachFrontPageName>(props: Props<TPage>) => (
  <Link {...props} to={buildPath(props.to, props.params)}>
    {props.children}
  </Link>
);

export default TeachFrontLink;
