import { Form, Formik, FormikErrors, FormikTouched, FormikValues } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Checkbox, Container, Header, Label, Message } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../../hooks/useCurrentUserCourseRole";
import { Assignment } from "../../../../../models/Assignment";
import { Feedback } from "../../../../../models/Feedback";
import { Objective } from "../../../../../models/Objective";
import { RatedObjective } from "../../../../../models/RatedObjective";
import { Rating } from "../../../../../models/Rating";
import { ReassessmentRequest } from "../../../../../models/ReassessmentRequest";
import { Submission } from "../../../../../models/Submission";
import { User } from "../../../../../models/User";
import { useStore } from "../../../../../stores/store";
import { getGraphicDataForRatings } from "../../../../../utilities/assessmentGraphicUtils";
import { forEach, objectMap } from "../../../../../utilities/collectionUtils";
import { isFormDirty } from "../../../../../utilities/formUtils";
import LoadingComponent from "../../../../../utilities/routing/components/LoadingComponent";
import {
  emptyID,
  getRatingsForSubmissionFromRatedObjectives,
  getSubmissionPackage,
  isFirstSubmission,
  isSubmissionAssessed,
  isSubmissionAssessmentPublished,
} from "../../../../../utilities/submissionUtils";
import { createClassName } from "../../../../../utilities/utils";
import AssessmentSummaryGraphic from "../../../../_common/assessmentGraphic/AssessmentSummaryGraphic";
import CardTypeLabel from "../../../../_common/cards/_common/CardTypeLabel";
import DragAndDropFile from "../../../../_common/file/DragAndDropFile";
import HorizontalIndent from "../../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import TextEditor from "../../../../_common/textEditing/TextEditor";
import PublishModal from "../../../../_modal/PublishModal";
import AssignmentSubmission from "../../_common/AssignmentSubmission";
import CourseObjective from "../../syllabus/CourseObjective";
import "./CreateOrEditReassessment.css";
import MasteryLevelPicker from "./MasteryLevelPicker";
import PastRatingsForSubmission from "./PastRatingsForSubmission";
import FlexContainer from "../../../../_common/style/FlexContainer";

// Name for mastery levels to be used in the form
export const objectiveMasteryLevelName = (objectiveID: string) => `${objectiveID}-masteryLevel`;
// Name for comments to be used in the form
export const objectiveFeedbackName = (objectiveID: string) => `${objectiveID}-feedback`;
const objectiveRateName = (objectiveID: string) => `${objectiveID}-rate`;
const overallFeedbackName = () => `overall-feedback`;

// Checks if errors exist in the form
export function doErrorsExist(
  errors: FormikErrors<FormikValues>,
  touched: FormikTouched<FormikValues>
) {
  return Object.keys(errors).length > 0 && Object.keys(touched).length > 0;
}

// Checks if errors exist for an objective by checking the form values.
const doesErrorExistForObjective = (
  objectiveID: string,
  errors: FormikErrors<FormikValues>,
  touched: FormikTouched<FormikValues>
) =>
  doErrorsExist(errors, touched) &&
  errors[objectiveFeedbackName(objectiveID)] &&
  (touched[objectiveFeedbackName(objectiveID)] || touched[objectiveMasteryLevelName(objectiveID)]);

function didUserReassessAll(values: FormikValues, reassessmentRequests: ReassessmentRequest[]) {
  return reassessmentRequests.every((rr) => values[objectiveMasteryLevelName(rr.objectiveID)]);
}

// Creates an array of ratings from form values and existing ratings.
function createRatingsFromFormValues(
  assignmentID: string,
  submissionID: string,
  courseID: string,
  studentID: string,
  raterID: string,
  objectives: Objective[],
  ratings: Map<string, Rating>,
  formValues: FormikValues,
  reassessmentRequests: ReassessmentRequest[]
) {
  const newRatings: Rating[] = [];

  objectives
    .filter(
      ({ id }) =>
        reassessmentRequests.some((rr) => rr.objectiveID === id) ||
        formValues[objectiveRateName(id)] // only check requested objectives or objectives that were checked
    )
    .filter((objective) => formValues[objectiveMasteryLevelName(objective.id)])
    .forEach(({ id }) => {
      const masteryLevelID = formValues[objectiveMasteryLevelName(id)];
      const comment = formValues[objectiveFeedbackName(id)];
      const existingRating = ratings.get(id);

      newRatings.push({
        id: existingRating?.id || emptyID,
        studentID,
        submissionID,
        assignmentID,
        raterID,
        objectiveID: id,
        createdAt: existingRating?.createdAt || new Date(Date.now()),
        isDraft: true,
        masteryLevelID,
        comment,
        courseID,
      });
    });

  return newRatings;
}

function getSubmissionID(submission?: Submission): string {
  return (submission as Submission).id as string;
}

interface CreateOrEditReassessmentProps {
  assignment: Assignment<RatedObjective>;
  courseID: string;
  user: User;
  userID: string;
  submission: Submission;
  onCancel: () => void;
  onSubmit: (changesMade: boolean) => void;
}

const CreateOrEditReassessment: React.FC<CreateOrEditReassessmentProps> = ({
  assignment,
  courseID,
  userID,
  submission,
  user,
  onCancel,
  onSubmit,
}) => {
  const { courseStore, submissionStore, ratingStore, modalStore, feedbackStore } = useStore();
  const { roster, loadCurrentCourseRoster, hasLoadedRoster } = courseStore;
  const { createOrUpdateRatings } = ratingStore;
  const { submissionsForAssignment, loadSubmissionsForAssignment } = submissionStore;
  const { isUserStudent } = useCurrentUserCourseRole(courseID);
  const { objectives, id: assignmentID } = assignment;
  const { reassessmentRequests } = submission;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    feedbackForSubmission,
    loadFeedbackForSubmission,
    hasLoadedFeedbackForSubmission,
    createOrUpdateFeedback,
  } = feedbackStore;

  useEffect(() => {
    loadSubmissionsForAssignment(assignmentID, userID, courseID);
  }, [loadSubmissionsForAssignment, assignmentID, userID, courseID]);

  useEffect(() => {
    loadCurrentCourseRoster(courseID);
  }, [courseID, loadCurrentCourseRoster]);

  useEffect(() => {
    loadFeedbackForSubmission(submission?.id, userID, courseID);
  }, [userID, submission, courseID]);

  if (!reassessmentRequests?.length)
    return <>There are no reassessment requests for this submission.</>;

  if (!objectives) return <>This assignment has no objectives.</>;

  if (!roster || !hasLoadedRoster(courseID))
    return <LoadingComponent content="Loading course roster..." />;

  if (!submissionsForAssignment || !hasLoadedFeedbackForSubmission(userID, submission?.id))
    return <LoadingComponent content="Loading submissions..." />;

  const mappedRatings = getRatingsForSubmissionFromRatedObjectives(
    userID as string,
    getSubmissionID(submission),
    objectives
  );

  const initValues: FormikValues = {};

  const canAssessmentBePublished =
    submission !== undefined &&
    submission.id &&
    userID &&
    assignment.objectives &&
    feedbackForSubmission &&
    isSubmissionAssessed(userID, submission.id, assignment.objectives) &&
    !isSubmissionAssessmentPublished(
      submission.userID,
      submission.id,
      assignment.objectives,
      feedbackForSubmission
    );

  // If there are any ratings, then the submission is assessed.
  const assessed = mappedRatings !== undefined && mappedRatings.size > 0;

  // Currently only ensures that if an object has a comment, it must have a mastery level selected.
  const validate = (values: FormikValues) => {
    const errors: FormikValues = {};

    // Set error messages for requested objectives
    objectives
      ?.filter(({ id }) => reassessmentRequests.some((rr) => rr.objectiveID === id))
      .forEach(({ id, shortName }) => {
        if (!values[objectiveMasteryLevelName(id)])
          errors[
            objectiveFeedbackName(id)
          ] = `You must select a mastery level rating for ${shortName} because the student requested reassessment for this objective`;
      });

    // Set error messages for all objectives if they have feedback with no mastery level rating
    objectives
      ?.filter(
        ({ id }) =>
          reassessmentRequests.some((rr) => rr.objectiveID === id) || values[objectiveRateName(id)] // only check requested objectives or objectives that were checked
      )
      .forEach((objective) => {
        const { id } = objective;
        const masteryLevelID = values[objectiveMasteryLevelName(id)];
        const comment = values[objectiveFeedbackName(id)];

        if (comment && (!masteryLevelID || masteryLevelID === "none")) {
          errors[
            objectiveFeedbackName(id)
          ] = `You must select a mastery level rating for ${objective.shortName}`;
        }
      });

    return errors;
  };

  // Initialize default values for existing ratings and feedback.
  if (assessed) {
    forEach(mappedRatings, (objectiveID, rating) => {
      initValues[objectiveMasteryLevelName(objectiveID)] = rating.masteryLevelID;
      if (rating.comment) initValues[objectiveFeedbackName(objectiveID)] = rating.comment;
    });

    if (feedbackForSubmission) {
      initValues[overallFeedbackName()] = feedbackForSubmission.comment;
    }
  }

  const then = (success: boolean) => {
    ratingStore.reset();
    onSubmit(success);

    setIsSubmitting(false);
  };

  const publishAssessment = (success: boolean, resetStoresOnClose: boolean) => {
    modalStore.openModal(
      () => (
        <PublishModal
          assignmentID={assignmentID}
          assignmentName={assignment.name ?? ""}
          courseID={courseID}
          disableToast={true}
          submissionIDs={[submission.id as string]}
          then={() => then(success)}
        />
      ),
      {
        showCloseIcon: true,
        onClose: () => resetStoresOnClose && then(success),
        isChildModal: true,
      }
    );
  };

  const handleFormSubmit = (formValues: FormikValues, publish: boolean) => {
    if (formValues) {
      const ratings = createRatingsFromFormValues(
        assignmentID,
        getSubmissionID(submission),
        courseID,
        userID,
        user.userID,
        objectives,
        mappedRatings,
        formValues,
        reassessmentRequests
      );

      setIsSubmitting(true);

      const feedback: Feedback = {
        id: feedbackForSubmission?.id || emptyID,
        courseID,
        studentID: userID,
        submissionID: getSubmissionID(submission),
        assignmentID,
        raterID: user.userID,
        createdAt: new Date(Date.now()),
        isDraft: true,
        comment: formValues[overallFeedbackName()],
      };

      createOrUpdateFeedback(feedback);

      // Calls the api endpoint to update ratings, which returns a promise. Use the then() method to run code
      // after the api responds, so that values will be properly updated in time.
      createOrUpdateRatings(courseID, assignmentID, [userID], ratings).then((success: boolean) => {
        if (publish && submission.id) {
          publishAssessment(success, true);
        } else then(success);
      });
    }
  };

  const submissionPackage = getSubmissionPackage(
    submission.userID,
    submission,
    assignment,
    isUserStudent,
    isFirstSubmission(submission, submissionsForAssignment)
  );

  return (
    <Container className="CreateOrEditReassessment">
      <Formik
        enableReinitialize
        initialValues={initValues}
        validate={validate}
        onSubmit={(formValues) => handleFormSubmit(formValues, false)}
      >
        {({ handleSubmit, touched, errors, values, setFieldValue }) => (
          <Form className="ui form" onSubmit={handleSubmit}>
            <Header className="reassessment-form-header" as="h2">
              {`${assessed ? "Update" : "Create"} Reassessment: ${assignment.name}`}
            </Header>{" "}
            <FlexContainer className="reassessment-details-container" gap="2.25rem">
              <div className="submission-details-section">
                {submission && submission.id && (
                  <>
                    <AssignmentSubmission
                      submissionPackage={submissionPackage}
                      assignment={assignment}
                      showTeachingTeamUploadedFiles={false}
                    />
                    <Header as="h3">Upload Files on the Student's Behalf:</Header>
                    <HorizontalIndent>
                      <DragAndDropFile
                        uploadFileCategory={"teachingTeamUploadedSubmission"}
                        userID={user.userID}
                        courseID={courseID}
                        connectedComponentID={submission.id}
                        showUploadComponentByDefault={true}
                      />
                    </HorizontalIndent>
                  </>
                )}
              </div>
              <div className="assessment-details-section">
                <HorizontalIndent>
                  <FlexContainer flexDirection="column" alignItems="center">
                    <Header as="h3">Current Assessment:</Header>

                    <AssessmentSummaryGraphic
                      data={getGraphicDataForRatings(
                        createRatingsFromFormValues(
                          assignmentID,
                          getSubmissionID(submission),
                          courseID,
                          userID as string,
                          user.userID,
                          objectives,
                          mappedRatings as Map<string, Rating>,
                          values,
                          reassessmentRequests
                        ),
                        objectives
                      )}
                      size="12em"
                      unpublishedRatingIconSize="3.3em"
                      unassessedIconSize="4em"
                    />
                  </FlexContainer>
                </HorizontalIndent>
                <Header as="h3" content="Reassessment was requested for these objectives:" />
                <div className="objective-container">
                  {objectives // Reassessment requested objectives
                    .filter(({ id }) => reassessmentRequests.some((rr) => rr.objectiveID === id))
                    .map((objective) => (
                      <CourseObjective
                        backgroundColor="white"
                        objective={objective}
                        key={objective.id}
                        className={"objective-container"}
                        cardTypeLabel="Objective to be reassessed"
                        isEditable={false}
                        showAssignments={false}
                        showChildObjectives={false}
                      >
                        <PastRatingsForSubmission
                          allowedSubmissionFormats={assignment.allowedSubmissionFormat ?? []}
                          color={objective.color}
                          masteryLevelScheme={objective.masteryLevelScheme}
                          selectedMasteryLevelID={values[objectiveMasteryLevelName(objective.id)]}
                          ratingsForStudents={objective.ratingsForStudents}
                          roster={roster}
                          submission={submission}
                          submissionsForAssignment={submissionsForAssignment}
                          userID={userID}
                          objectiveID={objective.id}
                          defaultValue={values[objectiveFeedbackName(objective.id)]}
                          setFieldValue={setFieldValue}
                        />
                        {doesErrorExistForObjective(objective.id, errors, touched) && (
                          <Message
                            negative
                            content={errors[objectiveFeedbackName(objective.id)] as string}
                          />
                        )}
                      </CourseObjective>
                    ))}
                  <div className="CourseObjective overall-feedback">
                    <CardTypeLabel content="Feedback" />
                    <VerticalGap height="1rem" />
                    <HorizontalIndent>
                      <Label
                        ribbon
                        className={createClassName("black", "overall-feedback-label")}
                        data-print-id="color"
                      >
                        Overall Comments
                      </Label>
                      <VerticalGap height={"1em"} />
                      <TextEditor
                        placeholder={"Enter feedback..."}
                        onChange={(value) => setFieldValue(overallFeedbackName(), value)}
                        defaultValue={values[overallFeedbackName()]}
                        container={`editor-${overallFeedbackName()}`}
                        smallHeight={true}
                      />
                    </HorizontalIndent>
                  </div>
                </div>
                <Header as="h3" content="Reassessment was not requested for these objectives:" />
                <div className="objective-container">
                  {objectives // Reassessment requested objectives
                    .filter(({ id }) => reassessmentRequests.every((rr) => rr.objectiveID !== id))
                    .map((objective) => (
                      <CourseObjective
                        backgroundColor="white"
                        objective={objective}
                        key={objective.id}
                        className={"objective-container"}
                        cardTypeLabel="Objective"
                        isEditable={false}
                        showAssignments={false}
                        showChildObjectives={false}
                      >
                        <Container>
                          <Checkbox
                            className="objective-rate-checkbox"
                            toggle
                            name={objectiveRateName(objective.id)}
                            label="This objective was not requested to be reassessed. Assess anyway?"
                            onChange={(e, data) =>
                              setFieldValue(objectiveRateName(objective.id), data.checked)
                            }
                            checked={!!values[objectiveRateName(objective.id)]}
                          />
                        </Container>
                        {values[objectiveRateName(objective.id)] && (
                          <>
                            <VerticalGap height="0.5rem" />
                            {objective.masteryLevelScheme && (
                              <MasteryLevelPicker
                                currentMasteryLevelID={
                                  values[objectiveMasteryLevelName(objective.id)]
                                }
                                masteryLevelScheme={objective.masteryLevelScheme}
                                selectMasteryLevelID={(id) =>
                                  setFieldValue(objectiveMasteryLevelName(objective.id), id)
                                }
                              />
                            )}
                            <VerticalGap height={"0.5em"} />
                            <TextEditor
                              defaultValue={values[objectiveFeedbackName(objective.id)]}
                              onChange={(value) =>
                                setFieldValue(objectiveFeedbackName(objective.id), value)
                              }
                              placeholder={"Enter feedback..."}
                              container={`editor-${objectiveFeedbackName(objective.id)}`}
                            />
                            {doesErrorExistForObjective(objective.id, errors, touched) && (
                              <Message
                                negative
                                content={errors[objectiveFeedbackName(objective.id)] as string}
                              />
                            )}
                          </>
                        )}
                      </CourseObjective>
                    ))}
                </div>
              </div>
            </FlexContainer>
            {doErrorsExist(errors, touched) && (
              <Message negative list={objectMap(errors, (key, value) => value as string)} />
            )}
            <Button
              content="Cancel"
              type="button"
              onClick={() => onCancel()}
              color="red"
              icon="x"
            />
            <Button
              disabled={
                !didUserReassessAll(values, reassessmentRequests) ||
                !isFormDirty(initValues, values) ||
                doErrorsExist(errors, touched)
              }
              loading={isSubmitting}
              content={assessed ? "Update Assessment" : "Create Assessment"}
              type="submit"
              color="blue"
              floated="right"
              icon="checkmark"
            />
            <Button
              disabled={
                !didUserReassessAll(values, reassessmentRequests) ||
                !isFormDirty(initValues, values) ||
                doErrorsExist(errors, touched)
              }
              loading={isSubmitting}
              content={`${assessed ? "Update" : "Create"} and Publish Assessment`}
              type="button"
              onClick={() => handleFormSubmit(values, true)}
              floated="right"
              icon="upload"
              color="grey"
            />
            {canAssessmentBePublished && submission && submission.id && (
              <Button
                loading={isSubmitting}
                color={"grey"}
                content={"Publish"}
                icon="upload"
                type="button"
                onClick={() => publishAssessment(true, false)}
                floated="right"
              />
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default observer(CreateOrEditReassessment);
