import { Label, Icon, SemanticICONS } from "semantic-ui-react";
import "./RoundLabelWithIcon.css";

interface RoundLabelWithIconProps {
  icon: SemanticICONS;
  value: string;
}

const RoundLabelWithIcon: React.FC<RoundLabelWithIconProps> = ({ icon, value }) => (
  <Label basic color={"blue"} className="RoundLabelWithIcon">
    <Icon name={icon} fitted /> {value}
  </Label>
);

export default RoundLabelWithIcon;
