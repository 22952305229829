import { Form, Formik } from "formik";
import React from "react";
import { Button, Header, SemanticICONS } from "semantic-ui-react";
import useBooleanState from "../../../../../../hooks/useBooleanState";
import { CalendarLinkType } from "../../../../../../models/CalendarLinkType";
import { useStore } from "../../../../../../stores/store";
import { emptyID } from "../../../../../../utilities/submissionUtils";
import FormLogoInput from "../../../../../_common/form/FormLogoInput";
import IconPicker from "../../../../../_common/form/IconPicker";
import VerticalGap from "../../../../../_common/style/spacing/VerticalGap";
import "./QuickLinkTypeModal.css";

interface QuickLinkTypeModalProps {
  onLinkCreated?: (newLink: CalendarLinkType) => void;
  modalIndex: number;
  courseID: string;
}

type QuickLinkTypeFormValues = {
  name: string;
  icon: SemanticICONS | undefined;
};

const QuickLinkTypeModal: React.FC<QuickLinkTypeModalProps> = ({
  onLinkCreated,
  modalIndex = 0,
  courseID,
}) => {
  const { modalStore, calendarStore } = useStore();
  const [loading, setLoading] = useBooleanState();

  const handleSubmit = async (formValues: QuickLinkTypeFormValues) => {
    const newLinkType: CalendarLinkType = {
      courseID,
      icon: formValues.icon as SemanticICONS,
      id: emptyID,
      name: formValues.name,
    };

    setLoading(true);

    const result = await calendarStore.createOrUpdateCalendarLinkType(newLinkType);

    setLoading(false);

    if (result && onLinkCreated) {
      onLinkCreated(result);
      modalStore.closeModal(modalIndex);
    }
  };

  const initialValues: QuickLinkTypeFormValues = {
    icon: undefined,
    name: "",
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <Header as="h3" content="New Quick Link Type" />
          <FormLogoInput
            iconName="calendar plus"
            label={`Name (e.g. "Slides")`}
            placeholder="Enter quick link type name..."
            required={true}
            type="text"
            name="name"
          />
          <VerticalGap height="1em" />
          <IconPicker
            onChange={(newIcon) => setFieldValue("icon", newIcon)}
            currentIcon={values.icon}
          />
          <VerticalGap height="1em" />
          <Button
            content="Cancel"
            color="red"
            icon="x"
            onClick={() => modalStore.closeModal(modalIndex)}
          />
          <Button
            content="Save"
            color="grey"
            icon="save"
            type="submit"
            disabled={!values.name || !values.icon}
            floated="right"
            loading={loading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default QuickLinkTypeModal;
