import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import "semantic-ui-less/semantic.less";
import { Header, Loader } from "semantic-ui-react";
import "./App.css";
import BlueLoadingLogo from "./components/_common/skeleton/BlueLoadingLogo";
import FlexContainer from "./components/_common/style/FlexContainer";
import AssessmentTaskHubObserver from "./hubObservers/assessmentTasksHubObserver";
import PollingHubObserver from "./hubObservers/pollingHubObserver";
import AppLayout from "./layouts/AppLayout";
import { useStore } from "./stores/store";
import ScrollToTop from "./utilities/routing/components/ScrollToTop";
import PredefinedAssessmentCommentsHubObserver from "./hubObservers/predefinedAssessmentCommentsHubObserver";
import AssignmentsHubObserver from "./hubObservers/AssignmentsHubObserver";

export const AppSkeleton: React.FC = () => (
  <FlexContainer
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    className="AppSkeleton"
  >
    <BlueLoadingLogo />
    <Header as="h1">TeachFront</Header>
    <Loader active={true} inline />
  </FlexContainer>
);

const App = () => {
  // reset the current user based on the JWT token.
  const { commonStore, userStore, courseStore } = useStore();
  const { courseID } = useParams<{ courseID: string }>();
  const location = useLocation();
  const { setCurrentLocation, loadFontOption } = commonStore;

  useEffect(() => {
    // ensure that the the hub observers for each feature are registered as observers of the hub
    // eslint-disable-next-line no-new
    new AssessmentTaskHubObserver();
    // eslint-disable-next-line no-new
    new AssignmentsHubObserver();
    // eslint-disable-next-line no-new
    new PollingHubObserver();
    // eslint-disable-next-line no-new
    new PredefinedAssessmentCommentsHubObserver();
  }, []);

  useEffect(() => {
    if (commonStore.token && !userStore.user) {
      userStore.getUser().finally(() => commonStore.setAppLoading(false));
    } else {
      commonStore.setAppLoading(false);
    }
  }, [commonStore, userStore, courseStore, courseID]);

  useEffect(() => {
    if (!commonStore.loading && !userStore.user) {
      courseStore.reset();
    }
  }, [commonStore.loading, userStore.user, courseStore]);

  setCurrentLocation(location.key);

  // respect font choice. This method only needs to be loaded off the application start.
  useEffect(() => loadFontOption(), []);

  // Don't load the rest of the app unless the user is loaded.
  if (commonStore.loading) return <AppSkeleton />;

  return (
    <>
      <ScrollToTop />
      <AppLayout />
    </>
  );
};

export default observer(App);
