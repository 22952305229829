import { observer } from "mobx-react-lite";
import { Header } from "semantic-ui-react";
import RosterGradebookTable from "./RosterGradebookTable";

const ViewInstructorGradebookForCourse: React.FC = () => (
  <>
    <Header as="h2">Course Gradebook</Header>
    <RosterGradebookTable />
  </>
);

export default observer(ViewInstructorGradebookForCourse);
