import { Form } from "react-router-dom";
import { Formik, FormikValues } from "formik";
import { Button, Header, Image } from "semantic-ui-react";
import { useState } from "react";
import { useStore } from "../../stores/store";
import { isValidEmail } from "../../utilities/utils";
import logo from "../../assets/logo.svg";
import FormLogoInput from "../_common/form/FormLogoInput";
import { objectEvery } from "../../utilities/collectionUtils";
import "./ForgotPasswordPage.css";

const ForgotPasswordPage: React.FC = () => {
  const { userStore, toastStore } = useStore();
  const [sentLink, setSentLink] = useState<boolean>(false);

  const validate = (values: FormikValues) => {
    const errors: {
      email?: string;
      errors?: string;
    } = {};
    if (!isValidEmail(values.email)) errors.email = "You must enter a valid email";

    return errors;
  };

  return (
    <Formik
      validate={validate}
      initialTouched={{}}
      initialErrors={{}}
      initialValues={{ email: "", error: null }}
      onSubmit={(values, { setErrors }) =>
        userStore
          .sendResetEmail(values.email, {
            401: (status, message) => setErrors({ error: message }),
          })
          .then((token) => {
            if (token) {
              setSentLink(true);
              toastStore.showToast(
                "If an account is associated with this email, an email has been sent."
              );
            }
          })
      }
    >
      {({ handleSubmit, isSubmitting, errors, touched, values }) => (
        <Form className="ForgotPasswordPage ui form" onSubmit={handleSubmit} autoComplete="on">
          <Image src={logo} centered size="small" />
          <Header as="h2" content="Request Password Reset" textAlign="center" />
          <div className="content">
            <FormLogoInput
              type={"email"}
              label={"Email"}
              name={"email"}
              placeholder={"Enter your email..."}
              iconName={"mail"}
              required={true}
              error={touched.email && errors.email}
            />
            {errors.error && <span className="error">Error: {errors.error}</span>}
            <Button
              loading={isSubmitting}
              content="Send Link"
              type="submit"
              color="grey"
              fluid
              disabled={!objectEvery(validate(values), (_, value) => !value) || sentLink}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default ForgotPasswordPage;
