import VerticalGap from "../../_common/style/spacing/VerticalGap";
import UserInputDisplay from "../../_common/textEditing/UserInputDisplay";

interface ProfilePageSectionProps {
  label: string;
  value: string | number | undefined;
  editable?: boolean;
  onSave?: (content: string) => void;
}

const ProfilePageSection: React.FC<ProfilePageSectionProps> = ({
  label,
  value,
  onSave,
  editable,
}) => (
  <div className="profile-section">
    <div className="profile-section-content">
      <label>{label}</label>
      <VerticalGap height="2em" />
      <UserInputDisplay
        placeholder="Tell us about yourself..."
        editable={editable}
        content={(value ?? "").toString()}
        onSave={(content) => onSave && onSave(content)}
      />
    </div>
  </div>
);

export default ProfilePageSection;
