const TEACHFRONT_PATHS = {
  Landing: "/",
  Login: "/login",
  ForgotPassword: "/forgotPassword",
  ResetPassword: "/ResetPassword",
  Register: "/register",
  ConfirmEmail: "/confirmEmail",
  ValidateEmail: "/ValidateEmail",
  ProfileForSelf: "/profile",
  ProfileForOther: "/profile/:userID",
  NotFound: "/not-found",
  CourseHomeAssessmentTasksTab: "/courses/:courseID/dashboard/:taskType",
  CourseHomeAssignmentsTab: "/courses/:courseID/assignments",
  CourseHomeAssignmentDetailsTab: "/courses/:courseID/assignments/:assignmentID",
  CourseHomeAssignmentSubmissionTab:
    "/courses/:courseID/assignments/:assignmentID/user/:userID/submission/:submissionID",
  CourseHomeAssignmentAllSubmissionsTab:
    "/courses/:courseID/assignments/:assignmentID/submissions/",
  CourseHomeCalendarTab: "/courses/:courseID/calendar",
  CourseHomeCreateOrUpdateAssessmentTab:
    "/courses/:courseID/assignments/:assignmentID/assess/user/:userID/submission/:submissionID",
  CourseHomeGradebookTab: "/courses/:courseID/gradebook",
  CourseHomeGradeCalculationsTab: "/courses/:courseID/grade-calculations",
  CourseHomeGradeCalculationsForUserTab: "/courses/:courseID/grade-calculations/user/:userID",
  CourseHomeSyllabusTab: "/courses/:courseID/syllabus",
  CourseHomePageTab: "/courses/:courseID/page/:pageID",
  CourseHomeFilesTab: "/courses/:courseID/files",
  CourseHomeSettingsTab: "/courses/:courseID/settings",
  CourseHomeSettingsTabMasteryLevels: "/courses/:courseID/settings/mastery-levels",
  CourseHomeSettingsTabGradeDistinctions: "/courses/:courseID/settings/grade-distinctions",
  CourseHomeSettingsTabGradeRecipes: "/courses/:courseID/settings/grade-recipes",
  CourseHomeSettingsTabGradeIngredients: "/courses/:courseID/settings/grade-ingredients",
  CourseHomeSettingsTabObjectives: "/courses/:courseID/settings/objectives",
  CourseHomeRosterTab: "/courses/:courseID/roster",
  CourseHomeRosterTabStudents: "/courses/:courseID/roster/students",
  CourseHomeRosterTabTeachingTeam: "/courses/:courseID/roster/teaching-team",
  CourseHomeRosterTabInstructors: "/courses/:courseID/roster/instructors",
  CourseHomeRosterTabTeachingAssistants: "/courses/:courseID/roster/teaching-assistants",
  CourseHomeRosterTabGroupSet: "/courses/:courseID/roster/group-set/:groupSetID",
  CourseHomeRosterTabNewGroupSet: "/courses/:courseID/roster/group-set/new",
  CourseHomeRosterTabGroupSetNotFound: "/courses/:courseID/roster/group-set/not-found",
};

export default TEACHFRONT_PATHS;
