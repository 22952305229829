import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { Header } from "semantic-ui-react";
import { chartColors, getHistogramChartDataForNumericValue } from "../../../utilities/chartUtils";
import "./HistogramChart.css";

export type HistogramChartData = {
  bin: string | undefined;
  count: number;
};

interface HistogramChartProps {
  // how tall should the chart be? (the width will be automatically responsive to the width of its container)
  height: number;
  // Array of objects from which to build the histogram.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  // The key in the data object that should be counted in the histogram.
  dataKey: string;
  // the title for the chart. if omitted, no title will be added.
  chartTitle?: string;
  // the minimum value to consider in the histogram bins. default is min value in data[dataKey].
  minBinValue?: number;
  // the maximum value to consider in the histogram bins. default is max value in data[dataKey].
  maxBinValue?: number;
  // the number of bins to consider for the histogram (one bar per bin).
  // default is the number of bin labels or the number of unique values in data[dataKey].
  numberOfBins?: number;
  // the labels that should be displayed in the histogram for each bin.
  binLabels?: string[];
  // the unit associated with the labeled bin (e.g. hours).
  labelUnit?: string;
  // the unit associated with the counts for the histogram (e.g. students).
  countUnit?: string;
  // set a max value for the variable axis (the one the changes with the counts).
  // this allows a set of histograms to have the same axis.
  // default value is the max count of elements in a bin.
  variableAxisMax?: number;
  // this should be true if the bars should be vertical, false if horizontal.
  // default is false.
  verticalBars?: boolean;
  // the length of the line under the title
  lineLength?: string;
  // the x position of the title
  titlePosition?: string;
  // the width of the labels for the histogram
  labelWidth?: number;
  // if defined, this function will be evoked for each bin and the returned number will be that bin's numeric amount
  binDataMapper?: (binName: string) => number;
}

const HistogramChart: React.FC<HistogramChartProps> = ({
  height,
  data,
  dataKey,
  chartTitle,
  minBinValue,
  maxBinValue,
  numberOfBins = -1,
  binLabels = [],
  labelUnit,
  countUnit,
  variableAxisMax,
  verticalBars = false,
  lineLength = "250",
  titlePosition = "20",
  labelWidth,
  binDataMapper,
}) => {
  // get the chart data
  let chartData: HistogramChartData[];

  if (binDataMapper) {
    chartData = [];
    binLabels.forEach((label) => {
      chartData.push({
        bin: label,
        count: binDataMapper(label),
      });
    });
  } else {
    chartData = getHistogramChartDataForNumericValue(
      data,
      dataKey,
      numberOfBins,
      minBinValue,
      maxBinValue,
      binLabels
    );
  }

  // misc styling variables
  const variableAxisMaxValue = variableAxisMax ?? Math.max(...chartData.map((item) => item.count));
  const barColors = chartColors;
  const labelPosition = verticalBars ? "top" : "right";
  const margins = verticalBars
    ? { top: 50, right: 20, left: 10, bottom: 5 }
    : { top: 35, right: 20, left: 10, bottom: 5 };
  // this seems backward, but for some reason recharts considers charts with vertical bars "horizontal"
  const layout = verticalBars ? "horizontal" : "vertical";

  const HistogramTooltip: React.FC<TooltipProps<ValueType, NameType>> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload[0] && payload[0].value && payload.length) {
      return (
        <div className="HistogramTooltip">
          <Header as="h4" className="tooltipLabel">
            {label} {labelUnit || ""}
          </Header>
          <p className="count">
            {`Selected by ${payload[0].value}`} {countUnit || ""}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={height} className={`HistogramChart ${layout}`}>
      <BarChart className="chart" data={chartData} layout={layout} margin={margins}>
        {chartTitle !== undefined ? (
          <>
            <text x={titlePosition} y={20} className="chartTitle" textAnchor="start">
              {chartTitle}
            </text>
            <line x1="0" y1="28" x2={lineLength} y2="28" stroke="black" stroke-width=".5pt" />
          </>
        ) : (
          ""
        )}
        {verticalBars ? (
          <>
            <XAxis type="category" dataKey="bin" />
            <YAxis type="number" hide domain={[0, variableAxisMaxValue]} />
          </>
        ) : (
          <>
            <XAxis type="number" hide domain={[0, variableAxisMaxValue]} />
            {labelWidth ? (
              <YAxis width={labelWidth} type="category" dataKey="bin" />
            ) : (
              <YAxis type="category" dataKey="bin" />
            )}
          </>
        )}
        <Tooltip content={<HistogramTooltip />} />{" "}
        <Bar
          dataKey="count"
          label={{ position: labelPosition }}
          radius={verticalBars ? [5, 5, 0, 0] : [0, 5, 5, 0]}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HistogramChart;
