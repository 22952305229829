import useLocationListener from "../../../hooks/useLocationListener";
import useReloadListener from "../../../hooks/useReloadListener";

interface PageReloadOrRedirectHandlerProps {
  onReload: () => void;
}

/**
 * Ghost component that is intended to be used as a child component to perform some action when a
 * page reload or navigation occurs.
 * @param onReload a function to be called on page reload or navigation.
 */
const PageReloadOrRedirectHandler: React.FC<PageReloadOrRedirectHandlerProps> = ({ onReload }) => {
  useReloadListener(onReload);
  useLocationListener(onReload);

  return <></>;
};

export default PageReloadOrRedirectHandler;
