import { useEffect } from "react";

/**
 * Hook that listens to page reloads and runs code right before.
 * @param onReload a function to call when a page is being reloaded.
 */
export default function useReloadListener(onReload: () => void) {
  const callFunction = (e: BeforeUnloadEvent) => {
    e.preventDefault();

    onReload();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", callFunction);
    return () => {
      window.removeEventListener("beforeunload", callFunction);
    };
    // Only load this once, on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onReload]);
}
