import { observer } from "mobx-react-lite";
import { Dropdown, DropdownProps, Placeholder, PlaceholderLine, Table } from "semantic-ui-react";
import { action } from "mobx";
import { SyntheticEvent, useEffect, useMemo } from "react";
import TeachFrontNavLink from "../../../../../../utilities/routing/components/TeachFrontNavLink";
import { useStore } from "../../../../../../stores/store";
import AssessmentGraphicWithFunctionality from "../../../../../_common/assessmentGraphic/AssessmentGraphicWithFunctionality";
import { RaterOption, defaultRaterOption } from "./ViewAssignmentAllSubmissions";
import { Assignment } from "../../../../../../models/Assignment";
import { RatedObjective } from "../../../../../../models/RatedObjective";
import TableSortLabel from "../../../../../_common/table/TableSortLabel";
import { SortDirection } from "../../../../../../stores/persistentPreferenceStore";
import { sortAssignmentSubmissionsTableLogs } from "../../../../../../utilities/preferenceUtils";
import { User } from "../../../../../../models/User";

interface AssignmentSubmissionsTableProps {
  assignmentID: string;
  courseID: string;
  raterOptions: RaterOption[];
  assignmentWithRatings: Assignment<RatedObjective>;
  roster: User[];
}

interface AssignmentSubmissionsTableSkeletonProps {
  numStudents?: number;
}

type ColumnStateName = {
  [direction in SortDirection]: string;
};

const assignSortStateNames: ColumnStateName = {
  down: "Others",
  unset: "Any",
  up: "Me",
};

const assessSortStateNames: ColumnStateName = {
  down: "No",
  unset: "Any",
  up: "Yes",
};

export const AssignmentSubmissionsTableSkeleton: React.FC<
  AssignmentSubmissionsTableSkeletonProps
> = ({ numStudents = 10 }) => (
  <div className="AssignmentSubmissionsTable">
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>First Name</Table.HeaderCell>
          <Table.HeaderCell>Last Name</Table.HeaderCell>
          <Table.HeaderCell>Submission Count</Table.HeaderCell>
          <Table.HeaderCell>Latest Submission</Table.HeaderCell>
          <Table.HeaderCell>Assess</Table.HeaderCell>
          <Table.HeaderCell>Assessment Assigned To</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {/* Show numStudents rows */}
        {Array.from(Array(numStudents)).map((_, index) => (
          <Table.Row key={index}>
            {/* Show 6 cells in each row */}
            {Array.from(Array(6)).map((__, cellIndex) => (
              <Table.Cell key={cellIndex} height={90}>
                <Placeholder>
                  <PlaceholderLine />
                </Placeholder>
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </div>
);

const AssignmentSubmissionsTable: React.FC<AssignmentSubmissionsTableProps> = ({
  assignmentID,
  courseID,
  raterOptions,
  assignmentWithRatings,
  roster,
}) => {
  const { submissionStore, assignedRaterStore, userStore, persistentPreferenceStore } = useStore();
  const {
    loadSubmissionLogEntriesForAssignment,
    hasLoadedSubmissionLogEntriesForAssignment,
    submissionLogEntriesForAssignment,
  } = submissionStore;
  const {
    assignRater,
    assignedRatersByStudent,
    loadAssignedRatersByStudent,
    hasLoadedAssignedRatersByStudent,
  } = assignedRaterStore;
  const {
    assignmentSubmissionsTableAssessSort: assessedSort,
    assignmentSubmissionsTableAssignSort: assignSort,
    setNextAssignmentSubmissionsTableAssessSortDirection: updateAssessedSortDirection,
    setNextAssignmentSubmissionsTableAssignSortDirection: updateAssignSortDirection,
  } = persistentPreferenceStore;
  const { user } = userStore;

  useEffect(() => {
    loadSubmissionLogEntriesForAssignment(assignmentID, courseID);

    if (hasLoadedSubmissionLogEntriesForAssignment(assignmentID)) {
      const studentIDs = submissionLogEntriesForAssignment?.map((logEntry) => logEntry.userID);

      if (studentIDs) loadAssignedRatersByStudent(courseID, assignmentID, studentIDs);
    }
  }, [assignmentID, courseID, submissionLogEntriesForAssignment]);

  const sortedSubmissionLogEntries = useMemo(() => {
    if (!submissionLogEntriesForAssignment || !assignedRatersByStudent || !user) return undefined;

    return sortAssignmentSubmissionsTableLogs(
      submissionLogEntriesForAssignment,
      assignedRatersByStudent,
      assignmentWithRatings,
      user,
      assignSort,
      assessedSort
    );
  }, [
    submissionLogEntriesForAssignment,
    assignmentWithRatings,
    assignedRatersByStudent,
    assessedSort,
    assignSort,
    user,
  ]);

  if (
    !sortedSubmissionLogEntries ||
    !submissionLogEntriesForAssignment ||
    !hasLoadedSubmissionLogEntriesForAssignment(assignmentID) ||
    !assignedRatersByStudent ||
    !hasLoadedAssignedRatersByStudent(courseID, assignmentID)
  )
    return <AssignmentSubmissionsTableSkeleton numStudents={roster.length} />;

  const handleAssignedRaterChanged = action(
    (e: SyntheticEvent<HTMLElement, Event>, result: DropdownProps) => {
      const student = submissionLogEntriesForAssignment?.find(
        (courseMember) => courseMember.userID === result.studentid
      );
      if (student && result.value && result.value !== defaultRaterOption) {
        assignRater(courseID, assignmentID, [student.userID], result.value.toString());
        const assignedRater = assignedRatersByStudent?.get(student.userID);
        if (assignedRater) assignedRater.raterID = result.value.toString();
        else
          assignedRatersByStudent?.set(student.userID, {
            courseID,
            assignmentID,
            studentID: student.userID,
            raterID: result.value.toString(),
          });
      }
    }
  );

  return (
    <div className="AssignmentSubmissionsTable">
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>Submission Count</Table.HeaderCell>
            <Table.HeaderCell>Latest Submission</Table.HeaderCell>
            <Table.HeaderCell className="clickable" onClick={updateAssessedSortDirection}>
              <TableSortLabel
                columnName="Assessed?"
                text={assessSortStateNames[assessedSort.sortDirection]}
              />
            </Table.HeaderCell>
            <Table.HeaderCell className="clickable" onClick={updateAssignSortDirection}>
              <TableSortLabel
                //   icon={sortByAssignedToMe ? "caret up" : "circle thin"}
                columnName={"Assessment Assigned To"}
                text={assignSortStateNames[assignSort.sortDirection]}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sortedSubmissionLogEntries.map((u) => (
            <Table.Row key={u.userID}>
              <Table.Cell>{u.firstName}</Table.Cell>
              <Table.Cell>{u.lastName}</Table.Cell>
              <Table.Cell>{u.countSubmissions}</Table.Cell>
              <Table.Cell>
                {u.countSubmissions > 0 && (
                  <TeachFrontNavLink
                    to={"CourseHomeAssignmentSubmissionTab"}
                    params={{
                      assignmentID: u.assignmentID,
                      courseID,
                      userID: u.userID,
                      submissionID: u.mostRecentSubmissionID,
                    }}
                  >
                    View
                  </TeachFrontNavLink>
                )}
              </Table.Cell>
              <Table.Cell>
                <AssessmentGraphicWithFunctionality
                  assignment={assignmentWithRatings}
                  userID={u.userID}
                  alignStar="left"
                />
              </Table.Cell>
              <Table.Cell>
                <Dropdown
                  fluid
                  selection
                  studentid={u.userID}
                  placeholder={defaultRaterOption}
                  value={assignedRatersByStudent.get(u.userID)?.raterID ?? defaultRaterOption}
                  options={raterOptions}
                  onChange={action(handleAssignedRaterChanged)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default observer(AssignmentSubmissionsTable);
