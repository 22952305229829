import { Link } from "react-router-dom";
import { Header } from "semantic-ui-react";
import { Course } from "../../../models/Course";
import "./CourseCard.css";

const CourseCard: React.FC<{ course: Course }> = ({ course }) => (
  <Link
    className="rounded-1 CourseCard ui card-box-shadow card-box-shadow-hover"
    to={`/courses/${course.id}/assignments`}
    key={course.id}
  >
    {course.backgroundPhotoBlobLink ? (
      <div className={`background-photo-container ui label border-top rounded-1`}>
        <div
          className={"background-photo ui label border-top rounded-1"}
          style={{
            backgroundImage: `url(${course.backgroundPhotoBlobLink})`,
          }}
        />
        <div className={`color-mask ui label border-top rounded-1 ${course.color}`} />
      </div>
    ) : (
      <div className={`color-background ui label border-top rounded-1 ${course.color}`} />
    )}
    <div className="card-information">
      <Header
        as="h3"
        className="blank bold"
        subheader={course.shortName}
        content={course.longName}
      />
      <p>{course.term}</p>
    </div>
  </Link>
);

export default CourseCard;
