import { Icon, SemanticICONS } from "semantic-ui-react";
import { createClassName } from "../../../utilities/utils";
import FlexContainer from "../style/FlexContainer";
import "./InformationWidget.css";

export type InformationWidgetType = "information" | "warning" | "error";

interface InformationWidgetProps {
  message: string;
  type: InformationWidgetType;
}

const getIconFromWidgetType = (type: InformationWidgetType): SemanticICONS => {
  switch (type) {
    case "error":
      return "warning";
    case "warning":
      return "warning sign";
    default:
      return "info circle";
  }
};

const InformationWidget: React.FC<InformationWidgetProps> = ({ type, message }) => (
  <FlexContainer className={createClassName("InformationWidget", type)} gap="1rem">
    <Icon name={getIconFromWidgetType(type)} size="large" />
    {message}
  </FlexContainer>
);

export default InformationWidget;
