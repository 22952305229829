import { observer } from "mobx-react-lite";
import useValidParams from "../../../../../hooks/useValidParameters";

const NewGroupSet: React.FC = () => {
  const { courseID, groupSetID } = useValidParams<{ courseID: string; groupSetID: string }>();

  // TODO remove these in future work
  console.log(courseID, groupSetID);
  return <div>New Group Set</div>;
};

export default observer(NewGroupSet);
