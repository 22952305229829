import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { AssignedRater } from "../models/AssignedRater";
import { Assignment } from "../models/Assignment";
import { AssignmentSurveySubmission } from "../models/AssignmentSurveySubmission";
import { AssignmentType } from "../models/AssignmentType";
import { CalendarEntry } from "../models/CalendarEntry";
import { CalendarEntryType } from "../models/CalendarEntryType";
import { CalendarLinkType } from "../models/CalendarLinkType";
import { Course } from "../models/Course";
import { Directory } from "../models/Directory";
import { Feedback as FeedbackComment } from "../models/Feedback";
import { GradeDistinction } from "../models/GradeDistinction";
import { GradeRecipeConstraint } from "../models/GradeRecipeConstraint";
import { GradeRecipeIngredient } from "../models/GradeRecipeIngredient";
import { Login } from "../models/Login";
import { MasteryLevelScheme } from "../models/MasteryLevelScheme";
import { Objective } from "../models/Objective";
import { ObjectiveConstraint } from "../models/ObjectiveConstraint";
import { Page } from "../models/Page";
import { PollingAssignmentAssessmentRule } from "../models/PollingAssignmentAssessmentRule";
import PredefinedAssessmentComment from "../models/PredefinedAssessmentComment";
import { RatedObjective } from "../models/RatedObjective";
import { Rating } from "../models/Rating";
import { Register } from "../models/Register";
import { StudentGradeDistinction } from "../models/StudentGradeDistinction";
import { Submission } from "../models/Submission";
import { SubmissionLogEntry } from "../models/SubmissionLogEntry";
import { UploadedFile } from "../models/UploadedFile";
import { UploadedFileBlobLink } from "../models/UploadedFileBlobLink";
import { User } from "../models/User";
import { PollingActivity } from "../models/polling/PollingActivity";
import { PollingActivityQuestionResponse } from "../models/polling/PollingActivityQuestionResponse";
import { PollingActivityTemplate } from "../models/polling/PollingActivityTemplate";
import { PollingQuestionTemplate } from "../models/polling/PollingQuestionTemplate";
import ModalStore from "../stores/modalStore";
// eslint-disable-next-line import/no-cycle
import { store } from "../stores/store";
import ToastStore from "../stores/toastStore";
import { objectSize } from "../utilities/collectionUtils";
import { router } from "../utilities/routing/Routes";
import { PollingAssignmentAssessmentDefaultRule } from "../models/PollingAssignmentAssessmentDefaultRule";
import { GroupSet } from "../models/GroupSet";

export type ErrorHandler = (
  statusCode: number,
  data: string,
  toastStore: ToastStore,
  modalStore: ModalStore
) => void;

export type StudentID = string;
export type CourseID = string;
export type AssignmentID = string;
export type UserID = string;
export type SubmissionID = string;
export type ObjectiveID = string;
export type RatingID = string;

/**
 * This interface defines handlers for custom exception handling within this api class.
 */
export interface ErrorHandlerPackage {
  [statusCode: number]: ErrorHandler;
  customHandler?: ErrorHandler;
}

export interface ApiCallOptions {
  errorHandlerPackage?: ErrorHandlerPackage;
  overrideIfFresh?: boolean;
}

function defaultErrorHandler(response: AxiosResponse): void {
  const { status, data } = response;

  switch (status) {
    case 404:
      router.navigate("/not-found");
      store.modalStore.closeModal();
      break;
    default:
      // eslint-disable-next-line prettier/prettier
      if (typeof data === "string") {
        let message = "";

        if (status === 500) {
          message =
            "Something went wrong on the server. Please report this error to an administrator.";
        } else if (data) {
          message = data as string;
        } else {
          message = `${status} Error`;
        }

        store.toastStore.showToast(message, {
          color: "red",
        });
      }
      break;
  }
}

function handleResponse<T>(
  response: AxiosResponse<T>,
  errorHandlerPackage?: ErrorHandlerPackage
): T {
  const { status, data } = response;
  const isError = status < 200 || status >= 400;

  // If there is no error, return the data.
  if (!isError) {
    return data;
  }

  let message = "";

  if (status === 500) {
    message = "Something went wrong on the server. Please report this error to an administrator.";
  } else if (data) {
    // eslint-disable-next-line prettier/prettier
    if (typeof data === "string") {
      message = data;
    }
    // eslint-disable-next-line prettier/prettier
    else if (typeof data === "object" && Object.hasOwn(data, "message")) {
      const any = data as { [key: string]: string | number };
      message = any.message?.toString() ?? "";
    }
  }

  if (message === "") {
    message = "Something went wrong.";
  }

  // If there is no error handler package, or the package has no attributes, or the package has no custom handler
  if (!errorHandlerPackage || objectSize(errorHandlerPackage) === 0) {
    defaultErrorHandler(response);
    throw new Error(message);
  }

  const existingHandler = errorHandlerPackage[status];

  if (existingHandler) {
    existingHandler(status, data as string, store.toastStore, store.modalStore);
    throw new Error(message);
  }

  if (errorHandlerPackage.customHandler) {
    errorHandlerPackage.customHandler(status, data as string, store.toastStore, store.modalStore);
    throw new Error(message);
  }

  defaultErrorHandler(response);
  throw new Error(message);
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const { token } = store.commonStore;
  // eslint-disable-next-line no-param-reassign
  if (token && config && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const requests = {
  get: <T>(
    url: string,
    params: URLSearchParams = new URLSearchParams(),
    errorHandlerPackage?: ErrorHandlerPackage
  ): Promise<T> =>
    axios
      .get<T>(url, { params })
      .then((response) => handleResponse(response, errorHandlerPackage))
      .catch((error) => handleResponse(error.response, errorHandlerPackage)),
  post: <T>(
    url: string,
    body: unknown,
    errorHandlerPackage?: ErrorHandlerPackage,
    config?: AxiosRequestConfig<unknown> | undefined
  ): Promise<T> =>
    axios
      .post<T>(url, body, config)
      .then((response) => handleResponse(response, errorHandlerPackage))
      .catch((error) => handleResponse(error.response, errorHandlerPackage)),
  put: <T>(url: string, body: unknown, errorHandlerPackage?: ErrorHandlerPackage): Promise<T> =>
    axios
      .put<T>(url, body)
      .then((response) => handleResponse(response, errorHandlerPackage))
      .catch((error) => handleResponse(error.response, errorHandlerPackage)),
  delete: <T>(url: string, body: unknown, errorHandlerPackage?: ErrorHandlerPackage): Promise<T> =>
    axios
      .delete<T>(url, { data: body })
      .then((response) => handleResponse(response, errorHandlerPackage))
      .catch((error) => handleResponse(error.response, errorHandlerPackage)),
};

const Account = {
  login: (user: Login, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<User>("/account/login", user, errorHandlerPackage, { validateStatus: null }),
  current: (errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.get<User>("/account", undefined, errorHandlerPackage),
  register: (register: Register, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<string>("/account/register", register, errorHandlerPackage),
  userProfile: (userID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<User>("/account/userProfile", { userID }, errorHandlerPackage),
  updateUserProfile: (userProfile: User, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<boolean>("/account/updateUserProfile", userProfile, errorHandlerPackage),
  verifyEmail: (token: string, email: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<User>("/account/verifyEmail", { token, email }, errorHandlerPackage),
  passwordChangeRequest: (email: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<string>("/account/passwordChangeRequest", { email }, errorHandlerPackage),
  passwordChange: (
    email: string,
    token: string,
    password: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<string>(
      "/account/changePassword",
      { email, token, password },
      errorHandlerPackage
    ),
};

const AssignedRaters = {
  createOrUpdate: (
    courseID: string,
    assignmentID: string,
    studentIDs: string[],
    raterID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<AssignedRater[]>(
      "/assignedraters/createOrUpdate",
      {
        courseID,
        assignmentID,
        studentIDs,
        raterID,
      },
      errorHandlerPackage
    ),
  setAssignedRaterForAssignment: (
    courseID: string,
    assignmentID: string,
    raterID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<boolean>(
      "/assignedraters/setAssignedRaterForAssignment",
      {
        courseID,
        assignmentID,
        raterID,
      },
      errorHandlerPackage
    ),
  listAssignedRatersForStudents: (
    courseID: string,
    assignmentID: string,
    studentIDs: string[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<AssignedRater[]>(
      "/assignedraters/list",
      { courseID, assignmentID, studentIDs },
      errorHandlerPackage
    ),
};

const Assignments = {
  listForCourse: (courseID: CourseID, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<Assignment<Objective>[]>(
      "/assignments/listForCourse",
      { courseID },
      errorHandlerPackage
    ),
  listForObjective: (
    objectiveID: ObjectiveID,
    courseID: CourseID,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<Assignment<Objective>[]>(
      "/assignments/listForObjective",
      {
        objectiveID,
        courseID,
      },
      errorHandlerPackage
    ),
  details: (
    assignmentID: AssignmentID,
    courseID: CourseID,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<Assignment<Objective>>(
      "/assignments/details",
      {
        assignmentID,
        courseID,
      },
      errorHandlerPackage
    ),
  createOrUpdate: (assignment: Assignment<Objective>, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<Assignment<Objective>>(
      "/assignments/createOrUpdate",
      assignment,
      errorHandlerPackage
    ),
  delete: (courseID: string, assignmentID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<boolean>("/assignments/delete", { courseID, assignmentID }, errorHandlerPackage),
};

const AssignmentSurveySubmissions = {
  listForAssignment: (
    assignmentID: AssignmentID,
    courseID: CourseID,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<AssignmentSurveySubmission[]>(
      "/assignmentSurveySubmissions/listForAssignment",
      { courseID, assignmentID },
      errorHandlerPackage
    ),
  details: (
    userID: UserID,
    assignmentID: AssignmentID,
    courseID: CourseID,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<AssignmentSurveySubmission>(
      "/assignmentSurveySubmissions/details",
      { userID, assignmentID, courseID },
      errorHandlerPackage
    ),
  createOrUpdate: (survey: AssignmentSurveySubmission, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<boolean>(
      "/assignmentSurveySubmissions/createOrUpdate",
      survey,
      errorHandlerPackage
    ),
};

const AssignmentTypes = {
  listForCourse: (courseID: CourseID, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<AssignmentType[]>(
      "/assignmentTypes/listForCourse",
      { courseID },
      errorHandlerPackage
    ),
  createOrUpdate: (assignmentType: AssignmentType, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<AssignmentType>(
      "/assignmentTypes/createOrUpdate",
      assignmentType,
      errorHandlerPackage
    ),
};

const CalendarEntries = {
  listForCourse: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<CalendarEntry[]>(
      "/calendarEntries/listForCourse",
      { courseID },
      errorHandlerPackage
    ),
  createOrUpdate: (calendarEntry: CalendarEntry, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<CalendarEntry>(
      "/calendarEntries/createOrUpdate",
      calendarEntry,
      errorHandlerPackage
    ),
  createOrUpdateCourseEntries: (
    courseID: string,
    calendarEntries: CalendarEntry[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<CalendarEntry[]>(
      "/calendarEntries/createOrUpdateAllForCourse",
      {
        courseID,
        calendarEntries,
      },
      errorHandlerPackage
    ),
  delete: (calendarEntry: CalendarEntry, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<CalendarEntry>("/calendarEntries/delete", calendarEntry, errorHandlerPackage),
};

const CalendarEntryTypes = {
  listForCourse: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<CalendarEntryType[]>(
      "/calendarEntryTypes/listForCourse",
      { courseID },
      errorHandlerPackage
    ),
  createOrUpdate: (
    calendarEntryType: CalendarEntryType,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<CalendarEntryType>(
      "/calendarEntryTypes/createOrUpdate",
      calendarEntryType,
      errorHandlerPackage
    ),
  delete: (
    courseID: string,
    calendarEntryTypes: CalendarEntryType,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<boolean>(
      "/calendarEntryTypes/delete",
      { courseID, calendarEntryTypes },
      errorHandlerPackage
    ),
};

const CalendarLinkTypes = {
  listForCourse: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<CalendarLinkType[]>(
      "/calendarLinkTypes/listForCourse",
      { courseID },
      errorHandlerPackage
    ),
  createOrUpdate: (calendarLinkType: CalendarLinkType, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<CalendarLinkType>(
      "/calendarLinkTypes/createOrUpdate",
      calendarLinkType,
      errorHandlerPackage
    ),
  createOrUpdateForCourse: (
    courseID: string,
    calendarLinkTypes: CalendarLinkType[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<CalendarLinkType[]>(
      "/calendarLinkTypes/createOrUpdateForCourse",
      { courseID, calendarLinkTypes },
      errorHandlerPackage
    ),
};

const Courses = {
  listForCurrentUser: (errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<Course[]>("/courses/listForCurrentUser", {}, errorHandlerPackage),
  courseMemberDetails: (
    userID: string,
    courseID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => requests.post<User>("/courses/courseUserDetails", { userID, courseID }, errorHandlerPackage),
  instructors: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<User[]>("/courses/instructors", { courseID }, errorHandlerPackage),
  teachingTeam: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<User[]>("/courses/teachingTeam", { courseID }, errorHandlerPackage),
  details: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<Course>("/courses/details", { courseID }, errorHandlerPackage),
  roster: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<User[]>("/courses/roster", { courseID }, errorHandlerPackage),
  updateRole: (courseMember: User, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.put<boolean>("/courses/updateRole/", { ...courseMember }, errorHandlerPackage),
  unenroll: (courseMember: User, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<boolean>("/courses/unenroll/", { ...courseMember }, errorHandlerPackage),
  registerSelfForCourse: (
    userID: string,
    registrationCode: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<boolean>(
      "/courses/userRegistration",
      { userID, registrationCode },
      errorHandlerPackage
    ),
  createOrUpdate: (userID: string, course: Course, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<Course>("/courses/createOrUpdate", { userID, course }, errorHandlerPackage),
  verifyRegistrationCode: (registrationCode: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<boolean>(
      "/courses/verifyRegistrationCode",
      { registrationCode },
      errorHandlerPackage
    ),
};

const Directories = {
  getForCourse: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<Directory>(
      "/directories/getForCourse",
      {
        courseID,
      },
      errorHandlerPackage
    ),
  createOrUpdate: (directory: Directory, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<Directory>("/directories/createOrUpdate", directory, errorHandlerPackage),
  delete: (courseID: string, directoryID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<Directory>(
      "/directories/delete",
      { courseID, id: directoryID },
      errorHandlerPackage
    ),
};

const Objectives = {
  list: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<Objective[]>(
      "/objectives/listWithMasteryLevelsForCourse",
      { courseID },
      errorHandlerPackage
    ),
  listHierarchical: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<Objective[]>(
      "/objectives/listHierarchicalForCourse",
      { courseID },
      errorHandlerPackage
    ),
  listHierarchicalWithAssignments: (
    courseID: CourseID,
    errorhandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<Objective[]>(
      "/objectives/listHierarchicalWithAssignmentsForCourse",
      {
        courseID,
      },
      errorhandlerPackage
    ),
  createOrUpdate: (
    courseID: string,
    objectives: Objective[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<Objective[]>(
      "/objectives/createOrUpdate",
      { courseID, objectives },
      errorHandlerPackage
    ),
  delete: (courseID: string, objectives: Objective[], errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<boolean>("/objectives/delete", { courseID, objectives }, errorHandlerPackage),
};

const Pages = {
  createOrUpdate: (page: Page, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<Page>("/pages/createOrUpdate", page, errorHandlerPackage),
  details: (pageID: string, courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<Page>("/pages/details", { id: pageID, courseID }, errorHandlerPackage),
};

const createPollingActivityFromTemplate = (
  path: string,
  courseID: string,
  assignmentID: string,
  errorHandlerPackage?: ErrorHandlerPackage
) =>
  requests.post<PollingActivity>(
    `/pollingActivity/${path}`,
    { courseID, assignmentID },
    errorHandlerPackage
  );

const PollingActivities = {
  getForAssignment: (
    courseID: string,
    assignmentID: string,
    excludeActivePollingActivity: boolean,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<PollingActivity[]>(
      "/pollingActivity/getForAssignment",
      { courseID, assignmentID, excludeActivePollingActivity },
      errorHandlerPackage
    ),
  close: (
    courseID: string,
    assignmentID: string,
    pollingActivityID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<PollingActivity>(
      "/pollingActivity/close",
      { courseID, assignmentID, pollingActivityID },
      errorHandlerPackage
    ),
  delete: (
    courseID: string,
    activityID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<boolean>(
      "/pollingActivity/deleteActivity",
      { courseID, activityID, assignmentID },
      errorHandlerPackage
    ),
  createMultipleChoiceFromTemplate: (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    createPollingActivityFromTemplate(
      "createMultipleChoice",
      courseID,
      assignmentID,
      errorHandlerPackage
    ),
  createMultipleChoicePlusConfidenceCheckFromTemplate: (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    createPollingActivityFromTemplate(
      "createMultipleChoicePlusConfidenceCheck",
      courseID,
      assignmentID,
      errorHandlerPackage
    ),
  createShortAnswerFromTemplate: (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    createPollingActivityFromTemplate(
      "createShortAnswer",
      courseID,
      assignmentID,
      errorHandlerPackage
    ),
  createShortAnswerPlusConfidenceCheckFromTemplate: (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    createPollingActivityFromTemplate(
      "createShortAnswerPlusConfidenceCheck",
      courseID,
      assignmentID,
      errorHandlerPackage
    ),
  createCompletionCheckFromTemplate: (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    createPollingActivityFromTemplate(
      "createCompletionCheck",
      courseID,
      assignmentID,
      errorHandlerPackage
    ),
  createCompletionCheckPlusConfidenceCheckFromTemplate: (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    createPollingActivityFromTemplate(
      "createCompletionCheckPlusConfidenceCheck",
      courseID,
      assignmentID,
      errorHandlerPackage
    ),
  createExitTicketFromTemplate: (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    createPollingActivityFromTemplate(
      "createExitTicket",
      courseID,
      assignmentID,
      errorHandlerPackage
    ),
  createConfidenceCheckFromTemplate: (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    createPollingActivityFromTemplate(
      "createConfidenceCheck",
      courseID,
      assignmentID,
      errorHandlerPackage
    ),
  createWordCloudFromTemplate: (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    createPollingActivityFromTemplate(
      "createWordCloud",
      courseID,
      assignmentID,
      errorHandlerPackage
    ),
};

const PollingActivityQuestionResponses = {
  getForQuestion: (
    courseID: string,
    pollingActivityQuestionID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<PollingActivityQuestionResponse[]>(
      "/pollingActivityQuestionResponse/getForQuestion",
      { courseID, pollingActivityQuestionID },
      errorHandlerPackage
    ),
  createOrUpdate: (
    pollingActivityQuestionResponse: PollingActivityQuestionResponse,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<PollingActivityQuestionResponse>(
      "/pollingActivityQuestionResponse/createOrUpdate",
      pollingActivityQuestionResponse,
      errorHandlerPackage
    ),
  getForSelfForActivity: (
    courseID: string,
    pollingActivityID: string,
    userID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<PollingActivityQuestionResponse[]>(
      "/pollingActivityQuestionResponse/getForSelfForActivity",
      { courseID, pollingActivityID, userID },
      errorHandlerPackage
    ),
  getLearningLog: (courseID: string, userID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<PollingActivityQuestionResponse[]>(
      "/pollingActivityQuestionResponse/getLearningLog",
      { courseID, userID },
      errorHandlerPackage
    ),
};

const PollingActivityTemplates = {
  list: (errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<PollingActivityTemplate[]>(
      "/pollingActivityTemplates/list",
      undefined,
      errorHandlerPackage
    ),
  get: (pollingActivityTemplateID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<PollingActivityTemplate>(
      "/pollingActivityTemplates/list",
      {
        pollingActivityTemplateID,
      },
      errorHandlerPackage
    ),
};

const PollingAssignmentAssessmentDefaultRules = {
  list: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<PollingAssignmentAssessmentDefaultRule[]>(
      "/pollingAssignmentAssessmentDefaultRules/list",
      { courseID },
      errorHandlerPackage
    ),
  createOrUpdate: (
    courseID: string,
    rules: PollingAssignmentAssessmentDefaultRule[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<PollingAssignmentAssessmentDefaultRule[]>(
      "/pollingAssignmentAssessmentDefaultRules/createOrUpdate",
      { courseID, rules },
      errorHandlerPackage
    ),
  delete: (courseID: string, ruleID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<boolean>(
      "/pollingAssignmentAssessmentDefaultRules/createOrUpdate",
      { courseID, ruleID },
      errorHandlerPackage
    ),
};

const PollingAssignmentAssessmentRules = {
  list: (courseID: string, assignmentID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<PollingAssignmentAssessmentRule[]>(
      "/pollingAssignmentAssessmentRules/list",
      { courseID, assignmentID },
      errorHandlerPackage
    ),
  createOrUpdate: (
    pollingAssignmentAssessmentRule: PollingAssignmentAssessmentRule,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<boolean>(
      "/pollingAssignmentAssessmentRules/createOrUpdate",
      pollingAssignmentAssessmentRule,
      errorHandlerPackage
    ),
  delete: (courseID: string, ruleID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<boolean>(
      "/pollingAssignmentAssessmentRules/delete",
      { courseID, ruleID },
      errorHandlerPackage
    ),
};

const PollingQuestionTemplates = {
  details: (pollingQuestionTemplateID: string, errorhandlerPackage?: ErrorHandlerPackage) =>
    requests.post<PollingQuestionTemplate>(
      "/pollingQuestionTemplates/details",
      { pollingQuestionTemplateID },
      errorhandlerPackage
    ),
};

const Ratings = {
  listByObjectivesForSpecificStudentsOneAssignment: (
    assignmentIDs: AssignmentID[],
    studentIDs: StudentID[],
    courseID: CourseID,
    highestRatingOnly: boolean,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<RatedObjective[]>(
      "/ratings/listByObjectivesForSpecificStudentsOneAssignment",
      {
        assignmentIDs,
        studentIDs,
        courseID,
        highestRatingOnly,
      },
      errorHandlerPackage
    ),
  listByObjectivesForAllStudentsOneAssignment: (
    assignmentIDs: AssignmentID[],
    courseID: CourseID,
    highestRatingOnly: boolean,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<RatedObjective[]>(
      "/ratings/listByObjectivesForAllStudentsOneAssignment",
      {
        assignmentIDs,
        courseID,
        highestRatingOnly,
      },
      errorHandlerPackage
    ),
  listAllAssignmentsInCourseWithObjectiveRatingsForSpecificStudents: (
    studentIDs: StudentID[],
    courseID: CourseID,
    highestRatingOnly: boolean,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<Assignment<RatedObjective>[]>(
      "/ratings/listAllAssignmentsInCourseWithObjectiveRatingsForSpecificStudents",
      {
        studentIDs,
        courseID,
        highestRatingOnly,
      },
      errorHandlerPackage
    ),
  listAllAssignmentsInCourseWithObjectiveRatingsForAllStudents: (
    courseID: string,
    highestRatingOnly: boolean,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<Assignment<RatedObjective>[]>(
      "/ratings/listAllAssignmentsInCourseWithObjectiveRatingsForAllStudents",
      {
        courseID,
        highestRatingOnly,
      },
      errorHandlerPackage
    ),
  createOrUpdate(
    courseID: CourseID,
    assignmentID: AssignmentID,
    studentIDs: StudentID[],
    ratings: Rating[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) {
    return requests.post<boolean>(
      "/ratings/createOrUpdate",
      {
        courseID,
        assignmentID,
        studentIDs,
        ratings,
      },
      errorHandlerPackage
    );
  },
  setDefaultRatingsForAllStudents(
    courseID: CourseID,
    assignmentID: AssignmentID,
    ratings: Rating[],
    objectiveIDsToBeOverridden?: ObjectiveID[], // optional parameter. Ratings sent to the backend that have objectiveIDs that exist in this array will override past ratings.
    errorHandlerPackage?: ErrorHandlerPackage
  ) {
    return requests.post<Record<StudentID, SubmissionID>>(
      "/ratings/setDefaultRatingsForAllStudents",
      {
        courseID,
        assignmentID,
        ratings,
        objectiveIDsToBeOverridden,
      },
      errorHandlerPackage
    );
  },
  setDefaultRatingsForSpecificStudents(
    courseID: CourseID,
    assignmentID: AssignmentID,
    ratings: Rating[],
    studentIDs: StudentID[],
    objectiveIDsToBeOverridden?: ObjectiveID[], // optional parameter. Ratings sent to the backend that have objectiveIDs that exist in this array will override past ratings.
    errorHandlerPackage?: ErrorHandlerPackage
  ) {
    return requests.post<Record<StudentID, SubmissionID>>(
      "/ratings/setDefaultRatingsForSpecificStudents",
      {
        courseID,
        assignmentID,
        ratings,
        studentIDs,
        objectiveIDsToBeOverridden,
      },
      errorHandlerPackage
    );
  },
  publish(
    courseID: CourseID,
    assignmentID: AssignmentID,
    notifyOfChanges: boolean,
    submissionIDs?: SubmissionID[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) {
    return requests.post<boolean>(
      "/ratings/publish",
      {
        courseID,
        assignmentIDs: [assignmentID],
        notifyOfChanges,
        submissionIDs,
      },
      errorHandlerPackage
    );
  },
};

const PredefinedAssessmentComments = {
  list: (
    courseID: CourseID,
    assignmentID: AssignmentID,
    objectiveID?: ObjectiveID,
    masteryLevelID?: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<PredefinedAssessmentComment[]>(
      "/predefinedAssessmentComments/list",
      {
        courseID,
        assignmentID,
        objectiveID,
        masteryLevelID,
      },
      errorHandlerPackage
    ),
  createOrUpdate: (
    courseID: CourseID,
    comment: PredefinedAssessmentComment,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<PredefinedAssessmentComment[]>(
      "/predefinedAssessmentComments/createOrUpdate",
      {
        courseID,
        comment,
      },
      errorHandlerPackage
    ),
  getUniqueComments: (
    courseID: CourseID,
    assignmentID: AssignmentID,
    studentIDs?: string[],
    objectiveID?: ObjectiveID,
    masteryLevelID?: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<PredefinedAssessmentComment[]>(
      "/predefinedAssessmentComments/getUniqueComments",
      {
        courseID,
        assignmentID,
        objectiveID,
        masteryLevelID,
        studentIDs,
      },
      errorHandlerPackage
    ),
  delete: (
    courseID: CourseID,
    comment: PredefinedAssessmentComment,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<PredefinedAssessmentComment[]>(
      "/predefinedAssessmentComments/delete",
      {
        courseID,
        comment,
      },
      errorHandlerPackage
    ),
  getNumberOfAssessmentComments: (
    commentID: string,
    courseID: CourseID,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<number>(
      "/predefinedAssessmentComments/getNumberOfAssessmentComments",
      {
        commentID,
        courseID,
      },
      errorHandlerPackage
    ),
};

const Feedback = {
  details: (
    studentID: StudentID,
    submissionID: SubmissionID,
    courseID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<FeedbackComment>(
      "/feedback/details",
      {
        studentID,
        submissionID,
        courseID,
      },
      errorHandlerPackage
    ),
  createOrUpdate(feedback: FeedbackComment, errorHandlerPackage?: ErrorHandlerPackage) {
    return requests.post<boolean>(
      "/feedback/createOrUpdate",
      {
        ...feedback,
      },
      errorHandlerPackage
    );
  },
  setDefaultFeedbackForAllStudents(
    courseID: CourseID,
    assignmentID: AssignmentID,
    feedback: FeedbackComment,
    errorHandlerPackage?: ErrorHandlerPackage
  ) {
    return requests.post<Record<StudentID, SubmissionID>>(
      "/feedback/setDefaultFeedbackForAllStudents",
      {
        courseID,
        assignmentID,
        feedback,
      },
      errorHandlerPackage
    );
  },
  setDefaultFeedbackForSpecificStudents(
    courseID: CourseID,
    assignmentID: AssignmentID,
    feedback: FeedbackComment,
    studentIDs: StudentID[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) {
    return requests.post<Record<StudentID, SubmissionID>>(
      "/feedback/setDefaultFeedbackForSpecificStudents",
      {
        courseID,
        assignmentID,
        feedback,
        studentIDs,
      },
      errorHandlerPackage
    );
  },
};

const Submissions = {
  list: (
    studentID: string,
    assignmentID: string,
    courseID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<Submission[]>(
      "/submissions/listForAssignment",
      {
        studentID,
        assignmentID,
        courseID,
      },
      errorHandlerPackage
    ),
  createOrUpdate: (
    submission: Submission,
    courseID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => requests.post<Submission>("/submissions", { ...submission, courseID }, errorHandlerPackage),
  details: (
    submissionID: string,
    assignmentID: string,
    courseID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<Submission>(
      `/submissions/details`,
      { submissionID, assignmentID, courseID },
      errorHandlerPackage
    ),
  logEntriesForAssignment: (
    assignmentID: AssignmentID,
    courseID: CourseID,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<SubmissionLogEntry[]>(
      "/submissions/logEntriesForAssignment",
      {
        assignmentID,
        courseID,
      },
      errorHandlerPackage
    ),
  autoGenerateSubmissionsForSpecificStudents: (
    assignmentID: AssignmentID,
    courseID: CourseID,
    studentIDs: UserID[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<SubmissionLogEntry[]>(
      "/submissions/autoGenerateSubmissionsForSpecificStudents",
      {
        assignmentID,
        courseID,
        studentIDs,
      },
      errorHandlerPackage
    ),
  downloadAllSubmissionsForAssignment: (
    assignmentID: AssignmentID,
    courseID: CourseID,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const config: AxiosRequestConfig = {
      responseType: "blob", // This will treat the response as a Blob
      transformResponse: (data) => data, // This will ensure we have access to all headers
    };
    return requests.post<Blob>(
      "/submissions/downloadAllSubmissionsForAssignment",
      {
        assignmentID,
        courseID,
      },
      errorHandlerPackage,
      config
    );
  },
};

const GradeDistinctions = {
  details: (
    courseID: CourseID,
    gradeDistinctionID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<GradeDistinction>(
      "/gradeDistinctions/details",
      {
        courseID,
        gradeDistinctionID,
      },
      errorHandlerPackage
    ),
  listForCourse: (courseID: CourseID, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<GradeDistinction[]>(
      "/gradeDistinctions/listForCourse",
      { courseID },
      errorHandlerPackage
    ),
  createOrUpdate: (
    courseID: CourseID,
    gradeDistinctions: GradeDistinction[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<GradeDistinction[]>(
      "/gradeDistinctions/createOrUpdate",
      {
        courseID,
        gradeDistinctions,
      },
      errorHandlerPackage
    ),
  delete: (
    courseID: CourseID,
    gradeDistinctions: GradeDistinction[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<boolean>(
      "/gradeDistinctions/delete",
      { courseID, gradeDistinctions },
      errorHandlerPackage
    ),
  calculateForStudents: (
    courseID: string,
    studentIDs: string[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<StudentGradeDistinction[]>(
      "/gradeDistinctions/calculateForStudents",
      {
        courseID,
        studentIDs,
      },
      errorHandlerPackage
    ),
  calculateForAllStudents: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<StudentGradeDistinction[]>(
      "/gradeDistinctions/calculateForAllStudentsInCourse",
      {
        courseID,
      },

      errorHandlerPackage
    ),
};

const GradeRecipeConstraints = {
  details: (
    courseID: string,
    gradeRecipeConstraintID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<GradeRecipeConstraint>(
      "/gradeRecipeConstraints/details",
      {
        courseID,
        gradeRecipeConstraintID,
      },
      errorHandlerPackage
    ),
  listForCourse: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<GradeRecipeConstraint[]>(
      "/gradeRecipeConstraints/listForCourse",
      { courseID },
      errorHandlerPackage
    ),
  createOrUpdate: (
    courseID: string,
    gradeRecipeConstraints: GradeRecipeConstraint[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<GradeRecipeConstraint[]>(
      "/gradeRecipeConstraints/createOrUpdate",
      {
        courseID,
        gradeRecipeConstraints,
      },
      errorHandlerPackage
    ),
  delete: (
    courseID: string,
    gradeRecipeConstraints: GradeRecipeConstraint[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<boolean>(
      "/gradeRecipeConstraints/delete",
      {
        courseID,
        gradeRecipeConstraints,
      },
      errorHandlerPackage
    ),
};

const GradeRecipeIngredients = {
  details: (
    courseID: string,
    gradeRecipeIngredientID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<GradeRecipeIngredient>(
      "/gradeRecipeIngredients/details",
      {
        courseID,
        gradeRecipeIngredientID,
      },
      errorHandlerPackage
    ),
  listForCourse: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<GradeRecipeIngredient[]>(
      "/gradeRecipeIngredients/listForCourse",
      { courseID },
      errorHandlerPackage
    ),
  createOrUpdate: (
    courseID: string,
    gradeRecipeIngredients: GradeRecipeIngredient[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<GradeRecipeIngredient[]>(
      "/gradeRecipeIngredients/createOrUpdate",
      {
        courseID,
        gradeRecipeIngredients,
      },
      errorHandlerPackage
    ),
  delete: (
    courseID: string,
    gradeRecipeIngredients: GradeRecipeIngredient[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<boolean>(
      "/gradeRecipeIngredients/delete",
      {
        courseID,
        gradeRecipeIngredients,
      },
      errorHandlerPackage
    ),
};

const GroupSets = {
  listForCourse: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<GroupSet[]>(
      "/groupSets/listForCourse",
      {
        courseID,
      },
      errorHandlerPackage
    ),
  getForAssignment: (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<GroupSet>(
      "/groupSets/getForAssignment",
      {
        courseID,
        assignmentID,
      },
      errorHandlerPackage
    ),
  createOrUpdate: (groupSet: GroupSet, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<GroupSet>("/groupSets/createOrUpdate", groupSet, errorHandlerPackage),
  delete: (courseID: string, groupID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<GroupSet>(
      "/groupSets/delete",
      {
        courseID,
        groupID,
      },
      errorHandlerPackage
    ),
  ableToDelete: (groupSet: GroupSet, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<GroupSet>("/groupSets/deleteable", groupSet, errorHandlerPackage),
};

const ObjectiveConstraints = {
  listForCourse: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<ObjectiveConstraint[]>(
      "/objectiveConstraints/listForCourse",
      { courseID },
      errorHandlerPackage
    ),
};

const MasteryLevelSchemes = {
  listForCourse: (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<MasteryLevelScheme[]>(
      "/masteryLevelSchemes/listForCourse",
      { courseID },
      errorHandlerPackage
    ),
  createOrUpdate: (
    masteryLevelScheme: MasteryLevelScheme,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<MasteryLevelScheme[]>(
      "/masteryLevelSchemes/createOrUpdate",
      masteryLevelScheme,
      errorHandlerPackage
    ),
  delete: (
    courseID: string,
    masteryLevelSchemeID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<boolean>(
      "/masteryLevelSchemes/delete",
      { courseID, masteryLevelSchemeID },
      errorHandlerPackage
    ),
};

const UploadedFiles = {
  uploadFile: (uploadedFile: UploadedFile, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<UploadedFile>("/uploadedFiles/upload", { ...uploadedFile }, errorHandlerPackage, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  uploadCourseFile: (uploadedFile: UploadedFile, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<UploadedFile>(
      "/uploadedFiles/uploadCourseFile",
      { ...uploadedFile },
      errorHandlerPackage,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ),
  deleteFile: (
    userID: string,
    courseID: string,
    fileID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<boolean>(
      "/uploadedFiles/delete",
      { userID, courseID, id: fileID },
      errorHandlerPackage
    ),
  updateFile: (uploadedFile: UploadedFile, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<UploadedFile>("/uploadedFiles/update", { ...uploadedFile }, errorHandlerPackage),
  deleteCourseFile: (courseID: string, fileID: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<boolean>(
      "/uploadedFiles/deleteCourseFile",
      { courseID, id: fileID },
      errorHandlerPackage
    ),
  deleteProfilePhoto: (userID: string, errorHandlerPackage?: ErrorHandlerPackage) => {
    requests.post<void>("/uploadedFiles/deleteProfilePhoto", { userID }, errorHandlerPackage);
  },
  detailsBySubmission: (
    connectedComponentID: string,
    courseID: string,
    userID?: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<UploadedFile[]>(
      "/uploadedFiles/connectedTo",
      {
        userID,
        courseID,
        connectedComponentID,
      },
      errorHandlerPackage
    ),
  detailsByTeachingTeamUploadedSubmission: (
    connectedComponentID: string,
    courseID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<UploadedFile[]>(
      "/uploadedFiles/getUploadedFilesConnectedToSubmissionByTeachingTeam",
      {
        courseID,
        connectedComponentID,
      },
      errorHandlerPackage
    ),
  detailsByAssignment: (
    connectedComponentID: string,
    courseID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<UploadedFile[]>(
      "/uploadedFiles/connectedToAssignment",
      {
        courseID,
        connectedComponentID,
      },
      errorHandlerPackage
    ),
  detailsByPage: (
    connectedComponentID: string,
    courseID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<UploadedFile[]>(
      "/uploadedFiles/connectedToPage",
      {
        courseID,
        connectedComponentID,
      },
      errorHandlerPackage
    ),
  detailsByCourse: (
    connectedComponentID: string,
    courseID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<UploadedFile[]>(
      "/uploadedFiles/connectedToCourse",
      {
        courseID,
        connectedComponentID,
      },
      errorHandlerPackage
    ),
  getBlobLinkForSubmissionFile: (
    userID: string,
    courseID: string,
    fileID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<UploadedFileBlobLink>(
      "/uploadedFiles/blobLink",
      { userID, courseID, id: fileID },
      errorHandlerPackage
    ),
  getBlobLinkForTeachingTeamUploadedSubmissionFile: (
    courseID: string,
    connectedComponentID: string,
    fileID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<UploadedFileBlobLink>(
      "/uploadedFiles/blobLinkForSubmissionByTeachingTeamFile",
      { connectedComponentID, courseID, id: fileID },
      errorHandlerPackage
    ),
  getBlobLinkForAssignmentFile: (
    courseID: string,
    connectedComponentID: string,
    fileID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<UploadedFileBlobLink>(
      "/uploadedFiles/blobLinkForAssignmentFile",
      { connectedComponentID, courseID, id: fileID },
      errorHandlerPackage
    ),
  getBlobLinkForPageFile: (
    courseID: string,
    connectedComponentID: string,
    fileID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<UploadedFileBlobLink>(
      "/uploadedFiles/blobLinkForPageFile",
      { connectedComponentID, courseID, id: fileID },
      errorHandlerPackage
    ),
  getBlobLinkForCourseFile: (
    courseID: string,
    connectedComponentID: string,
    fileID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    requests.post<UploadedFileBlobLink>(
      "/uploadedFiles/blobLinkForCourseFile",
      { connectedComponentID, courseID, id: fileID },
      errorHandlerPackage
    ),
  uploadProfilePhoto: (uploadedFile: UploadedFile, errorHandlerPackage?: ErrorHandlerPackage) =>
    requests.post<UploadedFile>(
      "/uploadedFiles/uploadProfilePhoto",
      { ...uploadedFile },
      errorHandlerPackage,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ),
};

const api = {
  Account,
  AssignedRaters,
  Assignments,
  AssignmentSurveySubmissions,
  AssignmentTypes,
  CalendarEntries,
  CalendarEntryTypes,
  CalendarLinkTypes,
  Courses,
  Directories,
  Feedback,
  GradeDistinctions,
  GradeRecipeConstraints,
  GradeRecipeIngredients,
  GroupSets,
  MasteryLevelSchemes,
  ObjectiveConstraints,
  Objectives,
  Pages,
  PollingActivities,
  PollingActivityQuestionResponses,
  PollingActivityTemplates,
  PollingAssignmentAssessmentDefaultRules,
  PollingAssignmentAssessmentRules,
  PollingQuestionTemplates,
  PredefinedAssessmentComments,
  Ratings,
  Submissions,
  UploadedFiles,
};

export default api;
