import { Form, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Checkbox, Header, Icon, Loader, SemanticICONS } from "semantic-ui-react";
import useActionBuffer from "../../../../hooks/useActionBuffer";
import useBooleanState from "../../../../hooks/useBooleanState";
import Color from "../../../../models/Color";
import { Course } from "../../../../models/Course";
import { UploadedFile } from "../../../../models/UploadedFile";
import { useStore } from "../../../../stores/store";
import { objectSize } from "../../../../utilities/collectionUtils";
import { isFormDirty } from "../../../../utilities/formUtils";
import { emptyID } from "../../../../utilities/submissionUtils";
import CourseCard from "../../../_common/cards/CourseCard";
import ColorPickerInput from "../../../_common/form/ColorPickerInput";
import FormDateInput from "../../../_common/form/FormDateInput";
import FormFileInput from "../../../_common/form/FormFileInput";
import FormLogoInput from "../../../_common/form/FormLogoInput";
import FlexContainer from "../../../_common/style/FlexContainer";
import VerticalGap from "../../../_common/style/spacing/VerticalGap";
import "./CourseSettingsForm.css";
import { getDateOnly } from "../../../../utilities/dateTimeUtils";

type CourseSettingsFormValues = {
  shortName: string;
  longName: string;
  term: string;
  uniqueRegistrationCode: string;
  color: Color;
  startDate: Date | undefined;
  endDate: Date | undefined;
  anonymousMode: boolean;
};

interface VerifyCodeMessageProps {
  message: string;
  className: string;
  icon: SemanticICONS | "loading";
}

const VerifyCodeMessage: React.FC<VerifyCodeMessageProps> = ({ message, className, icon }) => (
  <FlexContainer className={`VerifyCodeMessage ${className}`} alignItems="center" gap="0.5rem">
    {icon === "loading" ? (
      <Loader inline size="small" active={true} />
    ) : (
      <Icon name={icon} fitted />
    )}
    <span>{message}</span>
  </FlexContainer>
);

const CourseSettingsForm: React.FC = () => {
  const { courseStore, userStore, uploadedFileStore, toastStore } = useStore();
  const { user } = userStore;
  const { uploadCourseBackgroundPhoto } = uploadedFileStore;
  const { createOrUpdateCourse, verifyRegistrationCode, currentCourse } = courseStore;
  const { setAction } = useActionBuffer(1500);
  const [hasAttemptedCodeVerification, setHasAttemptedCodeVerification] = useBooleanState(false);
  const [verifyingCode, setVerifyingCode] = useBooleanState(false);
  const [submittingForm, setSubmittingForm] = useBooleanState(false);
  const [isCodeTaken, setCodeTaken] = useBooleanState(false);
  const [uploadingBackgroundPhoto, setUploadingBackgroundPhoto] = useBooleanState();

  if (!user || !currentCourse) return <></>;

  const handleSubmit = async (values: CourseSettingsFormValues) => {
    if (!values.startDate || !values.endDate || !user) return;

    const newCourse: Course = {
      ...currentCourse,
      color: values.color,
      shortName: values.shortName,
      longName: values.longName,
      term: values.term,
      registrationCode: values.uniqueRegistrationCode,
      endDate: getDateOnly(values.endDate), // only get the date, not the time
      startDate: getDateOnly(values.startDate), // only get the date, not the time
      anonymousMode: values.anonymousMode,
    };

    setSubmittingForm(true);

    await createOrUpdateCourse(user.userID, newCourse);

    setSubmittingForm(false);
  };

  const cleanRegistrationCode = (code: string): string => {
    let copy = code;

    copy = copy.replaceAll(" ", "_").toUpperCase();

    return copy;
  };

  const initialValues: CourseSettingsFormValues = {
    color: currentCourse.color,
    startDate: new Date(currentCourse.startDate),
    endDate: new Date(currentCourse.endDate),
    longName: currentCourse.longName,
    shortName: currentCourse.shortName,
    term: currentCourse.term,
    uniqueRegistrationCode: cleanRegistrationCode(currentCourse.registrationCode ?? user.lastName),
    anonymousMode: currentCourse.anonymousMode,
  };

  const getUniqueRegistrationCodeFromValues = (values: CourseSettingsFormValues): string => {
    const codeValues = [user.lastName];

    if (values.shortName) codeValues.push(values.shortName);
    if (values.term) codeValues.push(values.term);

    return codeValues.join("_");
  };

  const hasUserChangedRegistrationCode = (values: CourseSettingsFormValues): boolean =>
    values.uniqueRegistrationCode !==
    cleanRegistrationCode(getUniqueRegistrationCodeFromValues(values));

  const isCodeValid = (code: string | undefined) => code && code.length >= 3;

  const validate = (values: CourseSettingsFormValues) => {
    const errors: FormikErrors<CourseSettingsFormValues> = {};

    if (!values.shortName) errors.shortName = "You must specify the course number";
    if (!values.term) errors.term = "You must specify the course term";
    if (!values.longName) errors.longName = "You must specify the course name";
    if (!isCodeValid(values.uniqueRegistrationCode))
      errors.uniqueRegistrationCode = "Registration code must contain at least 3 characters";
    if (!values.startDate) errors.startDate = "You must specify a start date for the course";
    if (!values.endDate) errors.endDate = "You must specify an end date for the course";
    if (values.startDate && values.endDate && values.endDate < values.startDate)
      errors.endDate = "The course's end date must be later than the start date";

    return errors;
  };

  const onFormValueChange = (
    target: EventTarget,
    values: CourseSettingsFormValues,
    setFieldValue: (field: string, message: string | undefined) => void
  ) => {
    const fieldsToListenTo = ["shortName", "term"];

    let newCode: string | undefined;

    if (target instanceof HTMLInputElement) {
      if (fieldsToListenTo.includes(target.name) && !hasUserChangedRegistrationCode(values)) {
        const registrationCode = getUniqueRegistrationCodeFromValues({
          ...values,
          [target.name]: target.value,
        });

        newCode = cleanRegistrationCode(registrationCode);
      } else if (target.name === "uniqueRegistrationCode") {
        newCode = cleanRegistrationCode(target.value);
      }
    }

    if (newCode === currentCourse.registrationCode) {
      setFieldValue("uniqueRegistrationCode", newCode);
      setVerifyingCode(false);
      setAction(() => {});
      setHasAttemptedCodeVerification(false);
    } else if (isCodeValid(newCode)) {
      setFieldValue("uniqueRegistrationCode", newCode);
      setHasAttemptedCodeVerification(true);
      setVerifyingCode(true);

      setAction(async () => {
        const isCodeAvailable = await verifyRegistrationCode(newCode as string);

        if (!isCodeAvailable) {
          setCodeTaken(true);
        } else {
          setCodeTaken(false);
        }

        setVerifyingCode(false);
      });
    } else if (newCode !== undefined) {
      setHasAttemptedCodeVerification(true);
      setFieldValue("uniqueRegistrationCode", newCode);
      setVerifyingCode(false);
      setAction(() => {});
    }
  };

  const handleCourseBackgroundPhotoUpload = async (file: File) => {
    const uploadedFile: UploadedFile = {
      id: emptyID,
      connectedComponentID: currentCourse.id,
      createdAt: new Date(Date.now()),
      originalFileName: file.name,
      uploadedFile: file,
      contentType: file.type,
      userID: user.userID,
      courseID: currentCourse.id,
    };

    setUploadingBackgroundPhoto(true);

    const result = await uploadCourseBackgroundPhoto(uploadedFile, {
      customHandler: () => {
        toastStore.showToast("Something went wrong when uploading the course background photo", {
          color: "red",
        });
        setUploadingBackgroundPhoto(false);
      },
    });

    if (Object.hasOwn(result, "id")) {
      const newCourse: Course = {
        ...currentCourse,
        endDate: getDateOnly(currentCourse.endDate), // only get the date, not the time
        startDate: getDateOnly(currentCourse.startDate), // only get the date, not the time
        backgroundPhotoUploadedFileID: result.id,
      };

      await createOrUpdateCourse(user.userID, newCourse);

      toastStore.showToast("Successfully updated the course background photo");

      setUploadingBackgroundPhoto(false);
    }
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validate={validate}>
      {({ values, setFieldValue, touched, errors, setFieldTouched }) => (
        <>
          <FlexContainer
            className="CourseSettingsForm"
            flexWrap="wrap"
            gap="3rem"
            flexDirection="row"
          >
            <Form onChange={({ target }) => onFormValueChange(target, values, setFieldValue)}>
              <Header as="h3" content={"Edit Course"} />
              <FormLogoInput
                iconName="hashtag"
                label="Course Number"
                name="shortName"
                placeholder="e.g. FRNT 101"
                required={true}
                type="text"
                error={touched.shortName && errors.shortName}
                maxWidth="100%"
              />
              <VerticalGap height="1rem" />
              <FormLogoInput
                iconName="file alternate outline"
                label="Course Name"
                name="longName"
                placeholder="e.g. Introduction to TeachFront"
                required={true}
                type="text"
                maxWidth="100%"
              />
              <VerticalGap height="1rem" />
              <FormLogoInput
                iconName="calendar plus outline"
                label="Term"
                name="term"
                placeholder={`e.g. Fall ${new Date(Date.now()).getFullYear()}`}
                required={true}
                type="text"
                maxWidth="100%"
              />
              <VerticalGap height="2rem" />
              <div className="date-pickers">
                <Header as="h4" content="Course Dates:" />
                <FormDateInput
                  currentDate={values.startDate}
                  icon="calendar alternate outline"
                  label="Course Start Date"
                  onDateChange={(date) => {
                    setFieldValue("startDate", date);
                    setFieldTouched("startDate", true, false);
                  }}
                  datePickerProps={{
                    shouldCloseOnSelect: true,
                  }}
                  required={true}
                  error={touched.startDate && errors.startDate}
                />
                <VerticalGap height="1rem" />
                <FormDateInput
                  currentDate={values.endDate}
                  disabled={!values.startDate}
                  icon={"calendar alternate outline"}
                  label="Course End Date"
                  onDateChange={(date) => {
                    setFieldValue("endDate", date);
                    setFieldTouched("endDate", true, false);
                  }}
                  datePickerProps={{
                    minDate: values.startDate,
                    shouldCloseOnSelect: true,
                  }}
                  required={true}
                  error={touched.endDate && errors.endDate}
                />
              </div>
              <VerticalGap height="2rem" />
              <div className="choose-course-color">
                <Header
                  as="h4"
                  className="choose-color-header"
                  content={
                    <>
                      <span>Choose Course Color:</span>
                      <ColorPickerInput
                        currentColor={values.color}
                        onColorSelect={(newColor) => setFieldValue("color", newColor)}
                        childModalIndex={0}
                      />
                    </>
                  }
                />
              </div>
              <Header as="h4" content={"Upload Background Photo:"} />
              <FlexContainer gap="1rem">
                <FormFileInput
                  className="background-photo-input"
                  dragActiveClassName="drag-active"
                  onFileUploaded={handleCourseBackgroundPhotoUpload}
                  loading={uploadingBackgroundPhoto}
                  loaderMessage="Uploading Course Photo..."
                  displayMessage="Upload Background Photo"
                  inputProps={{
                    accept: "image/*",
                  }}
                />
              </FlexContainer>
              <VerticalGap height="2rem" />
              <FormLogoInput
                iconName="lock"
                label="Course Registration Code"
                name="uniqueRegistrationCode"
                placeholder={`e.g. teachfront_fall_${new Date(Date.now()).getFullYear()}`}
                required={true}
                type="text"
                tooltip="The course registration code is a unique code that can be used by students to join your course. It can be edited once you have filled in the course term and number."
                disabled={
                  !hasUserChangedRegistrationCode(values) && (!values.term || !values.shortName)
                }
                maxWidth="100%"
              />
              {hasAttemptedCodeVerification && (
                <>
                  {verifyingCode && (
                    <VerifyCodeMessage
                      message="Verifying registration code..."
                      className="verifying"
                      icon="loading"
                    />
                  )}
                  {!verifyingCode && (isCodeTaken || errors.uniqueRegistrationCode) && (
                    <VerifyCodeMessage
                      message={errors.uniqueRegistrationCode ?? "Registration code unavailable"}
                      className="error"
                      icon="x"
                    />
                  )}
                  {!verifyingCode && !isCodeTaken && !errors.uniqueRegistrationCode && (
                    <VerifyCodeMessage
                      message="Registration code is available!"
                      className="success"
                      icon="checkmark"
                    />
                  )}
                </>
              )}
              <Header as="h4" content={"Anonymous Assessment:"} />
              <Checkbox
                toggle
                label="Anonymize student roster data for all members of the course."
                checked={values.anonymousMode}
                onClick={(e, { checked }) => setFieldValue("anonymousMode", checked)}
              />
              <VerticalGap height="2rem" />
              <Button
                content="Update Course"
                icon="edit"
                type="submit"
                disabled={
                  !isFormDirty(initialValues, values) ||
                  (errors && objectSize(errors) !== 0) ||
                  verifyingCode ||
                  isCodeTaken
                }
                color="blue"
                loading={submittingForm}
              />
            </Form>
            <CourseCard
              course={
                {
                  ...currentCourse,
                  ...values,
                } as Course
              }
            />
          </FlexContainer>
        </>
      )}
    </Formik>
  );
};

export default observer(CourseSettingsForm);
