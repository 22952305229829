import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import AssignmentSubmissionDetails from "../components/courses/[courseID]/_common/AssignmentSubmissionDetails";
import RoundedSegment from "../components/_common/style/RoundedSegment";
import { useStore } from "../stores/store";
import useValidParams from "../hooks/useValidParameters";
import LoadingComponent from "../utilities/routing/components/LoadingComponent";

const CourseHomeAssignmentSubmissionTab: React.FC = () => {
  const { assignmentStore, submissionStore, courseStore, userStore } = useStore();
  const { loadAssignmentWithObjectivesAndMasteryLevels, assignmentWithObjectivesAndMasteryLevels } =
    assignmentStore;
  const { loadSubmissionsForAssignment, submissionsForAssignment } = submissionStore;
  const { course } = courseStore;
  const { user } = userStore;
  const { courseID, assignmentID, userID } = useValidParams<{
    courseID: string;
    assignmentID: string;
    userID: string;
  }>();

  useEffect(() => {
    if (assignmentID !== "new") {
      loadAssignmentWithObjectivesAndMasteryLevels(assignmentID, courseID);
      if (user) {
        loadSubmissionsForAssignment(assignmentID, userID, courseID);
      }
    }
  }, [
    assignmentWithObjectivesAndMasteryLevels,
    loadAssignmentWithObjectivesAndMasteryLevels,
    course,
    assignmentID,
    loadSubmissionsForAssignment,
    userID,
    submissionsForAssignment,
    courseID,
    user,
  ]);

  if (!assignmentWithObjectivesAndMasteryLevels)
    return <LoadingComponent content={"Loading Assignment..."} />;

  return (
    <RoundedSegment>
      <AssignmentSubmissionDetails
        assignmentWithObjectives={assignmentWithObjectivesAndMasteryLevels}
        courseID={courseID}
        userID={userID}
      />
    </RoundedSegment>
  );
};

export default observer(CourseHomeAssignmentSubmissionTab);
