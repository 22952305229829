import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useStore } from "../../../../stores/store";
import CourseRoles from "./CourseRoles";
import { AppSkeleton } from "../../../../App";

// inspired by https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

interface RequireCourseRoleAuthorizationProps {
  requiredRoleSet: string;
  children: JSX.Element;
}

const RequireCourseRoleAuthorization: React.FC<RequireCourseRoleAuthorizationProps> = (props) => {
  const { userStore, courseStore } = useStore();
  const { user, getUser } = userStore;
  const { loadCourseRoleForCurrentUser, courseRoleForCurrentUser } = courseStore;
  const { courseID } = useParams<{ courseID: string }>();

  useEffect(() => {
    if (!user) getUser();
    if (user && !courseRoleForCurrentUser && courseID)
      loadCourseRoleForCurrentUser(courseID, user.userID);
  }, [courseRoleForCurrentUser, loadCourseRoleForCurrentUser, courseID, user, getUser]);

  const location = useLocation();

  let requiredRoles: string[];
  switch (props.requiredRoleSet) {
    case "TeachingTeamRoles":
      requiredRoles = CourseRoles.TeachingTeamRoles;
      break;
    case "StudentRoles":
      requiredRoles = CourseRoles.StudentRoles;
      break;
    case "AllRoles":
      requiredRoles = CourseRoles.AllRoles;
      break;
    default:
      requiredRoles = [];
  }

  if (!user || !courseRoleForCurrentUser) {
    return <AppSkeleton />;
  }
  if (!requiredRoles.includes(courseRoleForCurrentUser)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/404" state={{ from: location }} replace />;
  }

  return props.children;
};

export default observer(RequireCourseRoleAuthorization);
