import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Container, Header } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../hooks/useValidParameters";
import { useStore } from "../../../../stores/store";
import LoadingComponent from "../../../../utilities/routing/components/LoadingComponent";
import UserCard from "../../../_common/cards/UserCard";
import HorizontalIndent from "../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../_common/style/spacing/VerticalGap";
import "./AssignmentSubmissionDetails.css";
import "./AssignmentSubmissionForm.css";

interface AssignmentSubmissionProps {
  studentID: string;
}

const AssignmentNoSubmission: React.FC<AssignmentSubmissionProps> = ({ studentID }) => {
  const { courseID } = useValidParams<{
    courseID: string;
    assignmentID: string;
  }>();
  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);

  const { courseStore } = useStore();
  const { roster, loadCurrentCourseRoster, hasLoadedRoster } = courseStore;

  useEffect(() => {
    loadCurrentCourseRoster(courseID);
  }, [loadCurrentCourseRoster, courseID]);

  if (!roster || !hasLoadedRoster(courseID))
    return <LoadingComponent content="Loading student information..." />;

  const student = roster?.find((cm) => cm.userID === studentID);

  return (
    <div className="AssignmentNoSubmission">
      <Header as="h3">Submission Details:</Header>
      <HorizontalIndent>
        {isUserTeachingTeam && student !== undefined && (
          <>
            <UserCard user={student} key={studentID} backgroundColor="white" />
            <VerticalGap height="1em" />
          </>
        )}
        <Container className="submission-details">Status: Did Not Submit</Container>
      </HorizontalIndent>
    </div>
  );
};

export default observer(AssignmentNoSubmission);
