import { useMemo } from "react";
import { Cell, Pie, PieChart } from "recharts";
import FlexContainer from "../../../../../_common/style/FlexContainer";
import { InstructorPollingActivityOverviewIndividualQuestionGraphicData } from "./InstructorPollingActivityOverviewIndividualQuestionGraphic";
import { User } from "../../../../../../models/User";
import "./InstructorPollingActivityOverviewIndividualQuestionGraphicMobile.css";
import useWindowSize from "../../../../../../hooks/useWindowSize";

interface InstructorPollingActivityOverviewIndividualQuestionGraphicMobileProps {
  data: InstructorPollingActivityOverviewIndividualQuestionGraphicData[];
  students: User[];
}

type StudentResponsePieChartData = {
  label: string;
  numStudents: number;
  color: string;
};

const InstructorPollingActivityOverviewIndividualQuestionGraphicMobile: React.FC<
  InstructorPollingActivityOverviewIndividualQuestionGraphicMobileProps
> = ({ data, students }) => {
  const { width } = useWindowSize([325]);
  const numTotalResponses = useMemo(() => {
    let total = 0;
    data.forEach((d) => {
      total += d.numResponses;
    });
    return total;
  }, [data]);
  const pieChartData: StudentResponsePieChartData[] = data.map(({ name, numResponses }, index) => ({
    color: `var(--blue-${Math.min(index + 1, 6)})`,
    label: name,
    numStudents: numResponses,
  }));

  let totalNumResponses = 0;
  pieChartData.forEach(({ numStudents }) => {
    totalNumResponses += numStudents;
  });

  const noResponseLabel = "No Response";

  if (totalNumResponses < students.length) {
    pieChartData.push({
      color: "var(--grey-5)",
      label: noResponseLabel,
      numStudents: students.length - totalNumResponses,
    });
  }

  const responsePercentage = Math.round((totalNumResponses * 100) / students.length);

  function getDataLabelNumStudents(numStudents: number, includePercentage: boolean) {
    const percentage = `${Math.round((numStudents * 100) / numTotalResponses)}%`;
    if (width < 325) {
      return ` (${percentage})`;
    }

    if (includePercentage) {
      return ` (${percentage}, ${numStudents} student${numStudents === 1 ? "" : "s"})`;
    }

    return ` (${numStudents} student${numStudents === 1 ? "" : "s"})`;
  }

  return (
    <FlexContainer
      gap="1rem"
      alignItems="center"
      justifyContent="center"
      className="InstructorPollingActivityOverviewIndividualQuestionGraphicMobile
    "
    >
      <div className="chart-wrapper">
        <PieChart width={150} height={150} className="chart">
          <Pie
            data={pieChartData}
            dataKey={"numStudents"}
            nameKey={"label"}
            cx="50%"
            cy="50%"
            outerRadius={75}
            label={false}
            labelLine={false}
            startAngle={90}
            endAngle={-270}
            stroke={"none"}
            animationBegin={25}
            animationDuration={1000}
          >
            {pieChartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
        <div className="percentage">{`${responsePercentage}%`}</div>
      </div>
      <div className="legend">
        {pieChartData.map((d) => (
          <FlexContainer className="row" key={d.label} alignItems="center">
            <div className="color" style={{ backgroundColor: d.color }} />
            <div className="label">
              {d.label}
              <span className="percentage">
                {getDataLabelNumStudents(d.numStudents, d.label !== noResponseLabel)}
              </span>
            </div>
          </FlexContainer>
        ))}
      </div>
    </FlexContainer>
  );
};

export default InstructorPollingActivityOverviewIndividualQuestionGraphicMobile;
