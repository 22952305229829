import imageCompression from "browser-image-compression";
// eslint-disable-next-line import/no-cycle
import api, { ApiCallOptions, ErrorHandlerPackage } from "../api/api";
import { UploadedFile } from "../models/UploadedFile";
import { UploadedFileBlobLink } from "../models/UploadedFileBlobLink";
import { store } from "./store";
import { StoreValue } from "./storeValue";

export type UploadedFileCategory =
  | "submission"
  | "teachingTeamUploadedSubmission"
  | "assignment"
  | "page"
  | "originalDraftSubmission"
  | "course";

export default class UploadedFileStore {
  readonly maxFileSizeInBytes = 10500000;

  readonly maxFileSizeInMegabytes = this.maxFileSizeInBytes / 1000000;

  private submissionUploadedFilesRegistry = new StoreValue<
    UploadedFile[],
    { connectedComponentID: string }
  >();

  private teachingTeamUploadedSubmissionUploadedFilesRegistry = new StoreValue<
    UploadedFile[],
    { connectedComponentID: string }
  >();

  private originalDraftSubmissionUploadedFileRegistry = new StoreValue<
    UploadedFile[],
    { connectedComponentID: string }
  >(); // Used to cache files for original draft submissions shown in SubmissionCommentModal.tsx

  private assignmentUploadedFilesRegistry = new StoreValue<
    UploadedFile[],
    { connectedComponentID: string }
  >();

  private pageUploadedFilesRegistry = new StoreValue<
    UploadedFile[],
    { connectedComponentID: string }
  >();

  resetUploadedFilesCategory = (category: UploadedFileCategory) =>
    this.getUploadedFileRegistry(category).reset();

  uploadingProfilePhoto = false;

  private getTemporaryID = (
    storeValue: StoreValue<UploadedFile[], { connectedComponentID: string }>
  ) => {
    let randomID = 0;

    // eslint-disable-next-line @typescript-eslint/no-loop-func
    while (storeValue.value?.some((u) => u.id === `${randomID}`)) {
      randomID += 1;
    }

    return `${randomID}`;
  };

  private deleteUploadedFileFromCache = (
    fileID: string,
    userID: string,
    connectedComponentID: string | undefined,
    category: UploadedFileCategory | undefined,
    previousID?: string
  ) => {
    if (!category || !connectedComponentID) return "";

    const storeValue = this.getUploadedFileRegistry(category);

    if (!storeValue.fresh(false, { connectedComponentID })) return "";

    const file = storeValue.value?.find((uf) => uf.id === fileID || uf.id === previousID);

    if (!file) return "";

    const newFile = { ...file };

    if (newFile.id === fileID) {
      newFile.id = this.getTemporaryID(storeValue);
      storeValue.setValue([...(storeValue.value || []).filter((uf) => uf.id !== fileID), newFile]);
      return newFile.id;
    }

    storeValue.setValue([...(storeValue.value || []).filter((uf) => uf.id !== previousID)]);
    return "";
  };

  private addTemporaryUploadedFile = (
    category: UploadedFileCategory | undefined,
    file: UploadedFile,
    previousID?: string
  ): string => {
    if (!category) return "";

    const temporaryFile = { ...file };

    const { connectedComponentID } = temporaryFile;
    const storeValue = this.getUploadedFileRegistry(category);

    if (!storeValue.fresh(false, { connectedComponentID })) return "";

    if (!temporaryFile.id) {
      temporaryFile.id = this.getTemporaryID(storeValue);
    }

    storeValue.setAll(
      [...(storeValue.value ?? []).filter((uf) => uf.id !== previousID), temporaryFile],
      {
        connectedComponentID,
      }
    );

    return temporaryFile.id;
  };

  hasLoadedUploadedFiles = (
    connectedComponentID: string,
    category: UploadedFileCategory
  ): boolean =>
    !this.getUploadedFileRegistry(category).isLoading() &&
    this.getUploadedFileRegistry(category).fresh(false, { connectedComponentID });

  get uploadedFilesForSubmission() {
    return this.submissionUploadedFilesRegistry.value;
  }

  get uploadedFilesForTeachingTeamUploadedSubmission() {
    return this.teachingTeamUploadedSubmissionUploadedFilesRegistry.value;
  }

  get uploadedFilesForAssignment() {
    return this.assignmentUploadedFilesRegistry.value;
  }

  get uploadedFilesForPage() {
    return this.pageUploadedFilesRegistry.value;
  }

  get originalDraftSubmissionUploadedFiles() {
    return this.originalDraftSubmissionUploadedFileRegistry.value;
  }

  reset = () => {
    this.submissionUploadedFilesRegistry.reset();
    this.pageUploadedFilesRegistry.reset();
    this.assignmentUploadedFilesRegistry.reset();
    this.teachingTeamUploadedSubmissionUploadedFilesRegistry.reset();
    this.originalDraftSubmissionUploadedFileRegistry.reset();
  };

  uploadCourseBackgroundPhoto = (
    uploadedFile: UploadedFile,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => api.UploadedFiles.uploadCourseFile(uploadedFile, errorHandlerPackage);

  handleUploadedFileError = (
    message: string,
    indexOfFile: string,
    uploadedFile: UploadedFile,
    category?: UploadedFileCategory
  ) => {
    // show the toast
    store.toastStore.showToast(message, { color: "red" });

    // if we don't have a category, ignore this.
    if (!category) return;

    // so we have a category, let's get the store for that registry
    const storeValue = this.getUploadedFileRegistry(category);

    // make sure the store holds files for the same connected component id before modifying it
    if (!storeValue.fresh(false, { connectedComponentID: uploadedFile.connectedComponentID }))
      return;

    // actually remove the value
    storeValue.setValue([...(storeValue.value || []).filter((uf) => uf.id !== indexOfFile)]);
  };

  errorMessage413 = (fileName: string) =>
    `The file ${fileName} is larger than ${this.maxFileSizeInMegabytes}MB, so it can't be uploaded.`;

  errorMessage415 = (fileName: string) =>
    `The file ${fileName} has a file type TeachFront can't accept. We do accept these types: .doc, .docx, .gif, .java, .jpeg, .jpg, .md, .mov”, .mp3, .mp4, .pdf, .png, .ppt, .pptx, .txt, .xls, and .xlsx.`;

  errorMessage400 = (fileName: string) =>
    `The file ${fileName} seems to be corrupt, so it can't be uploaded.`;

  uploadFile = async (uploadedFile: UploadedFile, category?: UploadedFileCategory) => {
    // if this is an image, we want to compress it
    // eslint-disable-next-line no-param-reassign
    uploadedFile.uploadedFile = await this.compressFile(uploadedFile.uploadedFile);

    // ensure the file size is acceptable
    if (uploadedFile.uploadedFile.size <= this.maxFileSizeInBytes) {
      // handle upload to server and blob store
      const index = this.addTemporaryUploadedFile(category, uploadedFile);

      // upload and tell the api how to handle any errors
      try {
        const result = await api.UploadedFiles.uploadFile(uploadedFile, {
          // request too large error (hopefully we've prevented this, but just in case)
          413: () => {
            this.handleUploadedFileError(
              this.errorMessage413(uploadedFile.originalFileName),
              index,
              uploadedFile,
              category
            );
          },
          // invalid file type
          415: () => {
            this.handleUploadedFileError(
              this.errorMessage415(uploadedFile.originalFileName),
              index,
              uploadedFile,
              category
            );
          },
          // this file is corrupt for some reason
          400: () => {
            this.handleUploadedFileError(
              this.errorMessage400(uploadedFile.originalFileName),
              index,
              uploadedFile,
              category
            );
          },
        } as ErrorHandlerPackage);

        // transition the temporary uploaded file to a new file
        this.addTemporaryUploadedFile(category, result, index);
      } catch (error) {
        // do nothing. the errorHandler should handle the errors
      }
    }

    // the file is too large, so we won't upload it, we'll just give the user the error toast
    else {
      store.toastStore.showToast(this.errorMessage413(uploadedFile.originalFileName), {
        color: "red",
      });
    }
  };

  deleteFile = async (
    userID: string,
    courseID: string,
    fileID: string,
    connectedComponentID?: string,
    category?: UploadedFileCategory
  ) => {
    const previousID = this.deleteUploadedFileFromCache(
      fileID,
      userID,
      connectedComponentID,
      category
    );

    await api.UploadedFiles.deleteFile(userID, courseID, fileID);

    this.deleteUploadedFileFromCache(fileID, userID, connectedComponentID, category, previousID);
  };

  getBlobLinkForSubmissionFile = async (file: UploadedFile) => {
    const { id, courseID, userID } = file;

    if (!id || !courseID) return undefined;

    const result: UploadedFileBlobLink = await api.UploadedFiles.getBlobLinkForSubmissionFile(
      userID,
      courseID,
      id
    );

    return result.blobLink;
  };

  getBlobLinkForAssignmentFile = async (file: UploadedFile) => {
    const { id, connectedComponentID: assignmentID, courseID } = file;

    if (!id || !courseID) return undefined;

    const result: UploadedFileBlobLink = await api.UploadedFiles.getBlobLinkForAssignmentFile(
      courseID,
      assignmentID,
      id
    );

    return result.blobLink;
  };

  getBlobLinkForPageFile = async (file: UploadedFile) => {
    const { id, connectedComponentID: pageID, courseID } = file;

    if (!id || !courseID) return undefined;

    const result: UploadedFileBlobLink = await api.UploadedFiles.getBlobLinkForPageFile(
      courseID,
      pageID,
      id
    );

    return result.blobLink;
  };

  getBlobLinkForOriginalDraftSubmissionFile = async (file: UploadedFile) => {
    const { id, courseID, userID } = file;

    if (!id || !courseID) return undefined;

    const result: UploadedFileBlobLink = await api.UploadedFiles.getBlobLinkForSubmissionFile(
      userID,
      courseID,
      id
    );

    return result.blobLink;
  };

  getBlobLinkForTeachingTeamUploadedSubmissionFile = async (file: UploadedFile) => {
    const { id, courseID, connectedComponentID: submissionID } = file;

    if (!id || !courseID) return undefined;

    const result: UploadedFileBlobLink =
      await api.UploadedFiles.getBlobLinkForTeachingTeamUploadedSubmissionFile(
        courseID,
        submissionID,
        id
      );

    return result.blobLink;
  };

  getBlobLinkWithCategory = async (
    uploadedFile: UploadedFile,
    uploadFileCategory: UploadedFileCategory
  ) => {
    if (uploadFileCategory === "submission" && uploadedFile.id) {
      return this.getBlobLinkForSubmissionFile(uploadedFile);
    }
    if (uploadFileCategory === "teachingTeamUploadedSubmission" && uploadedFile.id) {
      return this.getBlobLinkForTeachingTeamUploadedSubmissionFile(uploadedFile);
    }
    if (uploadFileCategory === "assignment" && uploadedFile.id) {
      return this.getBlobLinkForAssignmentFile(uploadedFile);
    }
    if (uploadFileCategory === "page" && uploadedFile.id) {
      return this.getBlobLinkForPageFile(uploadedFile);
    }
    if (uploadFileCategory === "originalDraftSubmission" && uploadedFile.id) {
      return this.getBlobLinkForOriginalDraftSubmissionFile(uploadedFile);
    }
    return undefined;
  };

  downloadSubmissionFile = async (file: UploadedFile) => {
    const { id, courseID, userID } = file;

    if (!id || !courseID) return;

    const result: UploadedFileBlobLink = await api.UploadedFiles.getBlobLinkForSubmissionFile(
      userID,
      courseID,
      id
    );

    this.performDownloadWithLink(file, result);
  };

  downloadAllSubmissionFiles = async (courseID: string, assignmentID: string) => {
    const response: Blob = await api.Submissions.downloadAllSubmissionsForAssignment(
      assignmentID,
      courseID
    );

    const fileName = "all_submissions.zip";

    const file: UploadedFile = {
      originalFileName: fileName,
      userID: "",
      connectedComponentID: "",
      uploadedFile: new File([response], fileName),
      createdAt: new Date(),
      contentType: "application/zip",
    };

    this.performDownloadWithBlob(file, response);
  };

  downloadAssignmentFile = async (file: UploadedFile) => {
    const { id, connectedComponentID: assignmentID, courseID } = file;

    if (!id || !courseID) return;

    const result: UploadedFileBlobLink = await api.UploadedFiles.getBlobLinkForAssignmentFile(
      courseID,
      assignmentID,
      id
    );

    this.performDownloadWithLink(file, result);
  };

  downloadPageFile = async (file: UploadedFile) => {
    const { id, connectedComponentID: pageID, courseID } = file;

    if (!id || !courseID) return;

    const result: UploadedFileBlobLink = await api.UploadedFiles.getBlobLinkForPageFile(
      courseID,
      pageID,
      id
    );

    this.performDownloadWithLink(file, result);
  };

  downloadOriginalDraftSubmissionFile = async (file: UploadedFile) => {
    const { id, courseID, userID } = file;

    if (!id || !courseID) return;

    const result: UploadedFileBlobLink = await api.UploadedFiles.getBlobLinkForSubmissionFile(
      userID,
      courseID,
      id
    );

    this.performDownloadWithLink(file, result);
  };

  downloadTeachingTeamUploadedSubmissionFile = async (file: UploadedFile) => {
    const { id, courseID, connectedComponentID: submissionID } = file;

    if (!id || !courseID) return;

    const result: UploadedFileBlobLink =
      await api.UploadedFiles.getBlobLinkForTeachingTeamUploadedSubmissionFile(
        courseID,
        submissionID,
        id
      );

    this.performDownloadWithLink(file, result);
  };

  downloadFileWithCategory = (file: UploadedFile, uploadFileCategory: UploadedFileCategory) => {
    if (uploadFileCategory === "submission" && file.id) {
      this.downloadSubmissionFile(file);
    } else if (uploadFileCategory === "teachingTeamUploadedSubmission" && file.id) {
      this.downloadTeachingTeamUploadedSubmissionFile(file);
    } else if (uploadFileCategory === "assignment" && file.id) {
      this.downloadAssignmentFile(file);
    } else if (uploadFileCategory === "page" && file.id) {
      this.downloadPageFile(file);
    } else if (uploadFileCategory === "originalDraftSubmission" && file.id) {
      this.downloadOriginalDraftSubmissionFile(file);
    }
  };

  deleteProfilePhoto = async (userID: string, errorHandlerPackage?: ErrorHandlerPackage) => {
    try {
      api.UploadedFiles.deleteProfilePhoto(userID, errorHandlerPackage);
    } catch (e) {
      // do nothing
    }
  };

  uploadProfilePhoto = async (
    userID: string,
    file: File,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const compressedFile = await this.compressFile(file, 0.25);

    const uploadedFile: UploadedFile = {
      connectedComponentID: userID,
      userID,
      uploadedFile: compressedFile,
      contentType: compressedFile.type,
      createdAt: new Date(Date.now()),
      originalFileName: file.name,
    };

    this.uploadingProfilePhoto = true;

    await api.UploadedFiles.uploadProfilePhoto(uploadedFile, errorHandlerPackage);

    this.uploadingProfilePhoto = false;
  };

  performDownloadWithBlobPart = async (file: UploadedFile, result: BlobPart) => {
    const blob = new Blob([result]);
    const href = URL.createObjectURL(blob);
    this.performDownload(file, href);
  };

  performDownloadWithBlob = async (file: UploadedFile, blob: Blob) => {
    const href = URL.createObjectURL(blob);
    this.performDownload(file, href);
  };

  performDownloadWithLink = async (file: UploadedFile, blobLink: UploadedFileBlobLink) => {
    const href = blobLink.blobLink;
    this.performDownload(file, href);
  };

  performDownload = async (file: UploadedFile, href: string) => {
    const { originalFileName } = file;
    const aElement = document.createElement("a");
    aElement.setAttribute("download", originalFileName);
    aElement.href = href;
    aElement.setAttribute("target", "_blank");
    aElement.click();
    URL.revokeObjectURL(href);
  };

  loadUploadedFilesForTeachingTeamUploadedSubmission = async (
    submissionID: string,
    courseID: string
  ) => {
    const registry = this.getUploadedFileRegistry("teachingTeamUploadedSubmission");

    if (registry.fresh(true, { connectedComponentID: submissionID })) return;

    registry.setLoading(true, { connectedComponentID: submissionID });

    const uploadedFiles = await api.UploadedFiles.detailsByTeachingTeamUploadedSubmission(
      submissionID,
      courseID
    );

    registry.setAll(uploadedFiles, { connectedComponentID: submissionID });

    registry.setLoading(false);
  };

  loadUploadedFilesForSubmission = async (
    submissionID: string,
    courseID: string,
    userID: string
  ) => {
    const registry = this.getUploadedFileRegistry("submission");

    if (registry.fresh(true, { connectedComponentID: submissionID })) return;

    registry.setLoading(true, { connectedComponentID: submissionID });

    const uploadedFiles = await api.UploadedFiles.detailsBySubmission(
      submissionID,
      courseID,
      userID
    );

    registry.setAll(uploadedFiles, { connectedComponentID: submissionID });

    registry.setLoading(false);
  };

  loadUploadedFilesForOriginalDraftSubmission = async (
    submissionID: string,
    courseID: string,
    userID: string
  ) => {
    const registry = this.getUploadedFileRegistry("originalDraftSubmission");

    if (registry.fresh(true, { connectedComponentID: submissionID })) return;

    registry.setLoading(true, { connectedComponentID: submissionID });

    const uploadedFiles = await api.UploadedFiles.detailsBySubmission(
      submissionID,
      courseID,
      userID
    );

    registry.setAll(uploadedFiles, { connectedComponentID: submissionID });

    registry.setLoading(false);
  };

  loadUploadedFilesForAssignment = async (
    assignmentID: string,
    courseID: string,
    apiCallOptions?: ApiCallOptions
  ) => {
    const registry = this.getUploadedFileRegistry("assignment");

    if (
      !apiCallOptions?.overrideIfFresh &&
      registry.fresh(true, { connectedComponentID: assignmentID })
    )
      return;

    registry.setLoading(true, { connectedComponentID: assignmentID });

    const uploadedFiles = await api.UploadedFiles.detailsByAssignment(assignmentID, courseID);

    registry.setAll(uploadedFiles, { connectedComponentID: assignmentID });

    registry.setLoading(false);
  };

  loadUploadedFilesForPage = async (pageID: string, courseID: string) => {
    const registry = this.getUploadedFileRegistry("page");

    if (registry.fresh(true, { connectedComponentID: pageID })) return;

    registry.setLoading(true, { connectedComponentID: pageID });

    const uploadedFiles = await api.UploadedFiles.detailsByPage(pageID, courseID);

    registry.setAll(uploadedFiles, { connectedComponentID: pageID });

    registry.setLoading(false);
  };

  compressFile = async (file: File, maxSizeMB = this.maxFileSizeInMegabytes) => {
    if (file.type.startsWith("image/")) {
      // see if we need to compress at all
      if (file.size < this.maxFileSizeInBytes) {
        return file;
      }

      // set the options for the compression
      const compressionOptions = {
        maxSizeMB, // (maximum file size in MB)
        maxWidthOrHeight: 1920, // maximum width or height
        maxIteration: 15,
        useWebWorker: true,
      };

      // actually compress the image
      const compressedBlob = await imageCompression(file, compressionOptions);
      const compressedFile = new File([compressedBlob], file.name, { type: compressedBlob.type });

      return compressedFile;
    }

    return file;
  };

  private getUploadedFileRegistry(category: UploadedFileCategory) {
    switch (category) {
      case "assignment":
        return this.assignmentUploadedFilesRegistry;
      case "page":
        return this.pageUploadedFilesRegistry;
      case "originalDraftSubmission":
        return this.originalDraftSubmissionUploadedFileRegistry;
      case "teachingTeamUploadedSubmission":
        return this.teachingTeamUploadedSubmissionUploadedFilesRegistry;
      default:
        return this.submissionUploadedFilesRegistry;
    }
  }
}
