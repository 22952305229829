// eslint-disable-next-line import/no-cycle
import api, { ApiCallOptions, ErrorHandlerPackage } from "../api/api";
// eslint-disable-next-line import/no-cycle
import Hub from "../api/hub";
import { Course } from "../models/Course";
import { User } from "../models/User";

import { StoreValue } from "./storeValue";

export default class CourseStore {
  private courseRegistry = new StoreValue<Course, { courseID: string }>();

  private courseMemberDetailsForCurrentUserRegistry = new StoreValue<
    User,
    { courseID: string; userID: string }
  >();

  private courseMemberDetailsForVisibleUserRegistry = new StoreValue<
    User,
    { courseID: string; userID: string }
  >();

  private instructorsForCourseRegistry = new StoreValue<User[], { courseID: string }>();

  private teachingTeamForCourseRegistry = new StoreValue<User[], { courseID: string }>();

  private coursesForUserRegistry = new StoreValue<Course[], { courseID: string; userID: string }>();

  private rosterRegistry = new StoreValue<User[], { courseID: string }>();

  private registerSelfForCourseRegistry = new StoreValue<
    boolean,
    { userID: string; registrationCode: string }
  >();

  private hub: Hub | undefined;

  hasLoadedCourse = (courseID: string) =>
    !this.courseRegistry.isLoading() && this.courseRegistry.fresh(false, { courseID });

  hasLoadedCourseMemberDetailsForCurrentUser = (courseID: string, userID: string) =>
    !this.courseMemberDetailsForCurrentUserRegistry.isLoading() &&
    this.courseMemberDetailsForCurrentUserRegistry.fresh(false, { courseID, userID });

  hasLoadedCourseMemberDetailsForVisibleUser = (courseID: string, userID: string) =>
    !this.courseMemberDetailsForVisibleUserRegistry.isLoading() &&
    this.courseMemberDetailsForVisibleUserRegistry.fresh(false, { courseID, userID });

  hasLoadedInstructorsForCourse = (courseID: string) =>
    !this.instructorsForCourseRegistry.isLoading() &&
    this.instructorsForCourseRegistry.fresh(false, { courseID });

  hasLoadedTeachingTeamForCourse = (courseID: string) =>
    !this.teachingTeamForCourseRegistry.isLoading() &&
    this.teachingTeamForCourseRegistry.fresh(false, { courseID });

  hasLoadedCoursesForUser = (courseID: string, userID: string) =>
    !this.coursesForUserRegistry.isLoading() &&
    this.coursesForUserRegistry.fresh(false, { courseID, userID });

  hasLoadedRoster = (courseID: string) =>
    !this.rosterRegistry.isLoading() && this.rosterRegistry.fresh(false, { courseID });

  reset = () => {
    this.courseRegistry.reset();
    this.courseMemberDetailsForCurrentUserRegistry.reset();
    this.courseMemberDetailsForVisibleUserRegistry.reset();
    this.instructorsForCourseRegistry.reset();
    this.coursesForUserRegistry.reset();
    this.rosterRegistry.reset();
    this.teachingTeamForCourseRegistry.reset();
  };

  resetCourseMemberDetailsForVisibleUser = () => {
    this.courseMemberDetailsForVisibleUserRegistry.reset();
  };

  get course() {
    return this.courseRegistry.value;
  }

  get currentCourse() {
    return this.courseRegistry.value;
  }

  get courseMemberDetailsForCurrentUser() {
    return this.courseMemberDetailsForCurrentUserRegistry.value;
  }

  get courseMemberRoleForCurrentUser() {
    return this.courseMemberDetailsForCurrentUserRegistry.value?.courseRole;
  }

  get courseMemberDetailsForVisibleUser() {
    return this.courseMemberDetailsForVisibleUserRegistry.value;
  }

  get instructorsForCourse() {
    return this.instructorsForCourseRegistry.value;
  }

  get teachingTeamForCourse() {
    return this.teachingTeamForCourseRegistry.value;
  }

  get roster() {
    return this.rosterRegistry.value;
  }

  get currentCourseRoster() {
    return this.rosterRegistry.value;
  }

  get courseRoleForCurrentUser() {
    return this.courseMemberDetailsForCurrentUserRegistry.value?.courseRole;
  }

  get coursesForUser() {
    return this.coursesForUserRegistry.value;
  }

  loadCurrentCourse = async (courseID: string, options?: ApiCallOptions) => {
    if (!options?.overrideIfFresh && this.courseRegistry.fresh(true, { courseID })) return;

    this.courseRegistry.setLoading(true, { courseID });

    const course = await api.Courses.details(courseID, options?.errorHandlerPackage);
    this.setDatesForCourse(course);
    const instructorsForCourse = await api.Courses.instructors(courseID);

    this.courseRegistry.setAll(course, { courseID });
    this.instructorsForCourseRegistry.setAll(instructorsForCourse, { courseID });

    // either get the existing hub with this courseID, or let the Hub class create/connect to a hub with this courseID
    // it is this line that creates the hub for all other features when a new course is selected
    this.hub = Hub.getInstance(courseID);

    this.courseRegistry.setLoading(false);
  };

  loadCurrentCourseRoster = async (courseID: string) => {
    if (this.rosterRegistry.fresh(true, { courseID })) return;

    this.rosterRegistry.setLoading(true, { courseID });

    const roster = await api.Courses.roster(courseID);

    this.rosterRegistry.setAll(roster, { courseID });

    this.rosterRegistry.setLoading(false);
  };

  loadTeachingTeamForCourse = async (courseID: string) => {
    if (this.teachingTeamForCourseRegistry.fresh(true, { courseID })) return;

    this.teachingTeamForCourseRegistry.setLoading(true, { courseID });

    const teachingTeam = await api.Courses.teachingTeam(courseID);

    this.teachingTeamForCourseRegistry.setAll(teachingTeam, { courseID });

    this.teachingTeamForCourseRegistry.setLoading(false);
  };

  loadCourseRoleForCurrentUser = async (courseID: string, userID: string) => {
    if (this.courseMemberDetailsForCurrentUserRegistry.fresh(true, { courseID, userID })) return;

    this.courseMemberDetailsForCurrentUserRegistry.setLoading(true, { courseID, userID });

    const courseMemberDetails = await api.Courses.courseMemberDetails(userID, courseID);

    this.courseMemberDetailsForCurrentUserRegistry.setAll(courseMemberDetails, {
      courseID,
      userID,
    });

    this.courseMemberDetailsForCurrentUserRegistry.setLoading(false);
  };

  loadCourseMemberDetailsForVisibleUser = async (courseID: string, userID: string) => {
    if (this.courseMemberDetailsForVisibleUserRegistry.fresh(true, { courseID, userID })) return;

    this.courseMemberDetailsForVisibleUserRegistry.setLoading(true, { courseID, userID });

    const courseMemberDetails = await api.Courses.courseMemberDetails(userID, courseID);

    this.courseMemberDetailsForVisibleUserRegistry.setAll(courseMemberDetails, {
      courseID,
      userID,
    });

    this.courseMemberDetailsForVisibleUserRegistry.setLoading(false);
  };

  // courseID added as a param so when the user switches courses, the fresh function detects a change
  // and continues on to load the courses rather than returning nothing. Without the courseID
  // param, the fresh function would detect no change since the userID does not change after switching courses.
  // (Adding courseID as a param fixed this issue: After the user switches the course they are viewing,
  // the course dropdown doesn't display the user's courses.)
  loadCoursesForUser = async (courseID: string, userID: string) => {
    if (this.coursesForUserRegistry.fresh(true, { courseID, userID })) return;

    this.coursesForUserRegistry.setLoading(true, { courseID, userID });

    const courses = await api.Courses.listForCurrentUser();

    courses.forEach(this.setDatesForCourse);

    this.coursesForUserRegistry.setAll(courses, { courseID, userID });

    this.coursesForUserRegistry.setLoading(false);
  };

  registerSelfForCourse = async (
    userID: string,
    registrationCode: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ): Promise<boolean | undefined> => {
    if (this.registerSelfForCourseRegistry.isLoading({ userID, registrationCode })) {
      return undefined;
    }

    try {
      this.registerSelfForCourseRegistry.setLoading(true, { userID, registrationCode });
      const response = await api.Courses.registerSelfForCourse(
        userID,
        registrationCode,
        errorHandlerPackage
      );
      this.registerSelfForCourseRegistry.setLoading(false);
      return response;
    } catch (error) {
      this.registerSelfForCourseRegistry.setLoading(false);
      return false;
    }
  };

  createOrUpdateCourse = async (userID: string, course: Course) => {
    const returnedCourse = await api.Courses.createOrUpdate(userID, course);

    if (returnedCourse) this.setDatesForCourse(returnedCourse);

    if (
      returnedCourse &&
      this.courseRegistry.value &&
      this.courseRegistry.value.id === returnedCourse.id
    )
      this.courseRegistry.setAll(returnedCourse, { courseID: returnedCourse.id });

    if (returnedCourse) {
      const coursesForUser = [...(this.coursesForUserRegistry.value ?? [])];

      const index = coursesForUser?.findIndex(({ id }) => id === returnedCourse.id);

      if (index === -1) coursesForUser?.push(returnedCourse);
      else coursesForUser[index] = returnedCourse;

      this.coursesForUserRegistry.setAll(coursesForUser, { courseID: returnedCourse.id, userID });
    }

    this.rosterRegistry.reset();
  };

  updateUserRole = async (courseMember: User) => {
    try {
      // actually update role
      const success = await api.Courses.updateRole(courseMember);

      if (!success) {
        return;
      }

      // is the person whose role is changing an instructor? If so, update course instructors
      const instructor = this.instructorsForCourseRegistry.value?.find(
        (i) => i.userID === courseMember.userID
      );
      const index = instructor ? this.instructorsForCourseRegistry.value?.indexOf(instructor) : -1;
      if (courseMember.courseRole === "Instructor" && index && index === -1) {
        this.instructorsForCourseRegistry.ifPresent((v) => v.push(courseMember));
      } else if (courseMember.courseRole !== "Instructor" && index !== undefined && index > -1) {
        this.instructorsForCourseRegistry.ifPresent((v) => v.splice(index, 1));
      }
    } catch (error) {
      // let api.ts handle errors
    }
  };

  unenroll = async (courseMember: User) => {
    try {
      // actually unenroll
      const success = await api.Courses.unenroll(courseMember);

      if (!success) {
        return;
      }

      // is the person whose role is changing an instructor?
      const instructor = this.instructorsForCourseRegistry.value?.find(
        (i) => i.userID === courseMember.userID
      );
      // if this is an instructor, find the instructor from the list of instructors
      const indexInInstructors = instructor
        ? this.instructorsForCourseRegistry.value?.indexOf(instructor)
        : -1;
      // if this is an instructor, actually remove them from the list of instructors
      if (
        courseMember.courseRole === "Instructor" &&
        indexInInstructors !== undefined &&
        indexInInstructors > -1
      ) {
        this.instructorsForCourseRegistry.ifPresent((v) => v.splice(indexInInstructors, 1));
      }

      // find the user in the roster
      const rosterUser = this.rosterRegistry.value?.find((i) => i.userID === courseMember.userID);
      // find the index of the user in the roster
      const indexInRoster = rosterUser ? this.rosterRegistry.value?.indexOf(rosterUser) : -1;
      // actually remove from the roster
      if (indexInRoster !== undefined && indexInRoster > -1) {
        this.rosterRegistry.ifPresent((v) => v.splice(indexInRoster, 1));
      }
    } catch (error) {
      // let api.ts handle errors
    }
  };

  verifyRegistrationCode = (registrationCode: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    api.Courses.verifyRegistrationCode(registrationCode, errorHandlerPackage);

  setDatesForCourse = (course: Course) => {
    const clone = course;
    clone.startDate = new Date(course.startDate);
    clone.endDate = new Date(course.endDate);
  };
}
