import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import "../_common/assessmentGraphic/AssessmentSummaryGraphic.css";
import "./ComplicatedShell.css";

const ComplicatedShell = () => {
  const ref = useRef<SVGSVGElement | null>(null);

  const colors = [
    "orange",
    "green",
    "red",
    "teal",
    "olive",
    "purple",
    "pink",
    "blue",
    "yellow",
    "brown",
    "violet",
    "indigo",
  ];
  const possibleClasses = [
    "hasnt-earned-level-0-from-top-yet",
    "hasnt-earned-level-1-from-top-yet",
    "hasnt-earned-level-2-from-top-yet",
    "hasnt-earned-level-3-from-top-yet",
    "hasnt-earned-level-4-from-top-yet",
    "hasnt-earned-level-5-from-top-yet",
    "hasnt-earned-level-6-from-top-yet",
  ] as const;

  // eslint-disable-next-line prettier/prettier
  type PossibleClass = typeof possibleClasses[number];

  // All <use> elements start with a predetermined set of classes
  const hardcodedClasses: PossibleClass[][] = [
    // first <use>
    ["hasnt-earned-level-0-from-top-yet"],
    // second <use>
    [
      "hasnt-earned-level-0-from-top-yet",
      "hasnt-earned-level-1-from-top-yet",
      "hasnt-earned-level-2-from-top-yet",
    ],
    // For the third <use>
    [
      "hasnt-earned-level-0-from-top-yet",
      "hasnt-earned-level-1-from-top-yet",
      "hasnt-earned-level-2-from-top-yet",
      "hasnt-earned-level-3-from-top-yet",
    ],
    // For the fourth <use>
    ["hasnt-earned-level-0-from-top-yet", "hasnt-earned-level-1-from-top-yet"],
    // For the fifth <use>
    [
      "hasnt-earned-level-0-from-top-yet",
      "hasnt-earned-level-1-from-top-yet",
      "hasnt-earned-level-2-from-top-yet",
      "hasnt-earned-level-3-from-top-yet",
      "hasnt-earned-level-4-from-top-yet",
      "hasnt-earned-level-5-from-top-yet",
    ],
    // For the sixth <use>
    ["hasnt-earned-level-0-from-top-yet", "hasnt-earned-level-1-from-top-yet"],
    // For the seventh <use>
    [
      "hasnt-earned-level-0-from-top-yet",
      "hasnt-earned-level-1-from-top-yet",
      "hasnt-earned-level-2-from-top-yet",
    ],
    // For the eighth <use>
    ["hasnt-earned-level-0-from-top-yet", "hasnt-earned-level-1-from-top-yet"],
    // For the ninth <use>
    [
      "hasnt-earned-level-0-from-top-yet",
      "hasnt-earned-level-1-from-top-yet",
      "hasnt-earned-level-2-from-top-yet",
      "hasnt-earned-level-3-from-top-yet",
    ],
    // For the tenth <use>
    ["hasnt-earned-level-0-from-top-yet"],
    // For the eleventh <use>
    ["hasnt-earned-level-0-from-top-yet", "hasnt-earned-level-1-from-top-yet"],
    // For the twelfth <use>
    [
      "hasnt-earned-level-0-from-top-yet",
      "hasnt-earned-level-1-from-top-yet",
      "hasnt-earned-level-2-from-top-yet",
    ],
  ];

  const [useClasses, setUseClasses] = useState<PossibleClass[][]>(hardcodedClasses);

  useEffect(() => {
    const interval = setInterval(() => {
      const indices = Array.from({ length: 12 }, (_, i) => i);
      const shuffledIndices = indices.sort(() => 0.5 - Math.random());

      const selectedUseIndex = shuffledIndices.find((index) => {
        const useClassesAtIndex = useClasses[index];
        return Array.isArray(useClassesAtIndex) && useClassesAtIndex.length > 0;
      });

      // If all uses have 0 classes, reset to default
      // eslint-disable-next-line prettier/prettier
      if (typeof selectedUseIndex === "undefined") {
        setUseClasses(hardcodedClasses);
        return;
      }

      const classesForSelectedUse = useClasses[selectedUseIndex];

      if (!classesForSelectedUse || classesForSelectedUse.length === 0) {
        return;
      }

      // Randomly select a class to remove from the selected <use>
      const classToRemove = classesForSelectedUse[classesForSelectedUse.length - 1];

      if (!classToRemove) {
        return;
      }

      const updatedClasses = [...useClasses];
      const updatedClassesAtSelectedUseIndex = updatedClasses[selectedUseIndex];
      if (updatedClassesAtSelectedUseIndex !== undefined) {
        updatedClasses[selectedUseIndex] = updatedClassesAtSelectedUseIndex.filter(
          (c) => c !== classToRemove
        );
        setUseClasses(updatedClasses);
      }
    }, 1500);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [useClasses]);

  // create a state variable that keeps track of whether the shell is visible
  const [isVisible, setIsVisible] = useState(false);

  // this basically
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      ([entry]) => {
        // the entry is kind of like an event object
        // that gives details about when an object enters or exits the viewport
        if (entry) {
          setIsVisible(entry.isIntersecting);
        }
      },
      {
        // an object is considered visible if at least 10% of the object is within the viewport
        threshold: 0.1,
      }
    );

    if (ref.current) {
      // the ref is the reference to this object. We want to observe when it enters and exits the viewport.
      intersectionObserver.observe(ref.current);
    }

    // Specify how to clean up after this effect (prevent memory leaks)
    return () => {
      // This function is called when the component unmounts or before the effect runs again
      if (ref.current) {
        // Stop observing the element
        intersectionObserver.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      setUseClasses(hardcodedClasses); // Reset to the initial state
    }
  }, [isVisible]);

  return (
    <svg
      ref={ref}
      version="1.1"
      className="ComplicatedShell shell-graphic teachfront-header-img"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      xmlSpace="preserve"
      data-print-id="hide"
      viewBox="0 0 1000 710"
    >
      <defs>
        <symbol id={`obj-0-of-12-with-8-mls-in-${colors[0]}`}>
          <path
            className=" level-0-from-top"
            fill="#000"
            d="M 450.75 706.02 70.48 296.31 C 28.22 250.79 19.98 270.05 7.53 310.17 S 0.01 372.82 45.08 406.14 L 450.75 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[0]}-7, #000)` }}
          />
          <path
            className=" level-1-from-top"
            fill="#000"
            d="M 450.75 706.02 118.24 346.86 C 81.30 306.95 73.98 324.04 62.93 359.65 S 56.26 415.25 95.71 444.33 L 450.75 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[0]}-6, #000)` }}
          />
          <path
            className=" level-2-from-top"
            fill="#000"
            d="M 450.75 706.02 166.01 397.40 C 134.37 363.11 127.99 378.04 118.34 409.13 S 112.51 457.68 146.33 482.52 L 450.75 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[0]}-5, #000)` }}
          />
          <path
            className=" level-3-from-top"
            fill="#000"
            d="M 450.75 706.02 213.78 447.95 C 187.45 419.27 181.99 432.03 173.74 458.61 S 168.76 500.12 196.96 520.71 L 450.75 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[0]}-4, #000)` }}
          />
          <path
            className=" level-4-from-top"
            fill="#000"
            d="M 450.75 706.02 261.55 498.49 C 240.52 475.44 235.99 486.03 229.14 508.09 S 225.00 542.55 247.58 558.90 L 450.75 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[0]}-3, #000)` }}
          />
          <path
            className=" level-5-from-top"
            fill="#000"
            d="M 450.75 706.02 309.31 549.04 C 293.60 531.60 289.99 540.02 284.54 557.57 S 281.25 584.98 298.20 597.09 L 450.75 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[0]}-2, #000)` }}
          />
          <path
            className=" level-6-from-top"
            fill="#000"
            d="M 450.75 706.02 357.08 599.58 C 346.67 587.76 343.99 594.02 339.95 607.05 S 337.50 627.42 348.83 635.28 L 450.75 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[0]}-1, #000)` }}
          />
        </symbol>
        <symbol id={`obj-1-of-12-with-8-mls-in-${colors[1]}`}>
          <path
            className=" level-0-from-top"
            fill="#000"
            d="M 456.23 694.37 137.71 202.37 C 102.31 147.70 88.30 162.20 62.31 193.70 S 30.08 246.89 72.70 291.63 L 456.23 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[1]}-7, #000)` }}
          />
          <path
            className=" level-1-from-top"
            fill="#000"
            d="M 456.23 694.37 177.97 263.35 C 147.05 215.46 134.62 228.33 111.55 256.29 S 82.95 303.49 120.28 342.57 L 456.23 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[1]}-6, #000)` }}
          />
          <path
            className=" level-2-from-top"
            fill="#000"
            d="M 456.23 694.37 218.24 324.34 C 191.79 283.22 180.93 294.46 160.79 318.87 S 135.82 360.09 167.86 393.52 L 456.23 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[1]}-5, #000)` }}
          />
          <path
            className=" level-3-from-top"
            fill="#000"
            d="M 456.23 694.37 258.50 385.32 C 236.53 350.98 227.25 360.58 210.03 381.45 S 188.68 416.69 215.44 444.46 L 456.23 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[1]}-4, #000)` }}
          />
          <path
            className=" level-4-from-top"
            fill="#000"
            d="M 456.23 694.37 298.77 446.30 C 281.27 418.74 273.56 426.71 259.27 444.04 S 241.55 473.29 263.01 495.40 L 456.23 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[1]}-3, #000)` }}
          />
          <path
            className=" level-5-from-top"
            fill="#000"
            d="M 456.23 694.37 339.03 507.28 C 326.01 486.50 319.88 492.84 308.51 506.62 S 294.41 529.89 310.59 546.34 L 456.23 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[1]}-2, #000)` }}
          />
          <path
            className=" level-6-from-top"
            fill="#000"
            d="M 456.23 694.37 379.30 568.27 C 370.75 554.25 366.20 558.97 357.75 569.20 S 347.28 586.49 358.17 597.28 L 456.23 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[1]}-1, #000)` }}
          />
        </symbol>
        <symbol id={`obj-2-of-12-with-8-mls-in-${colors[2]}`}>
          <path
            className=" level-0-from-top"
            fill="#000"
            d="M 465.05 685.68 225.00 136.92 C 198.33 75.95 182.20 85.49 150.51 106.82 S 105.29 144.81 141.26 198.90 L 465.05 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[2]}-7, #000)` }}
          />
          <path
            className=" level-1-from-top"
            fill="#000"
            d="M 465.05 685.68 255.54 205.17 C 232.26 151.78 217.96 160.25 189.82 179.18 S 149.69 212.89 181.23 260.17 L 465.05 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[2]}-6, #000)` }}
          />
          <path
            className=" level-2-from-top"
            fill="#000"
            d="M 465.05 685.68 286.09 273.42 C 266.20 227.61 253.71 235.00 229.14 251.54 S 194.10 280.98 221.19 321.45 L 465.05 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[2]}-5, #000)` }}
          />
          <path
            className=" level-3-from-top"
            fill="#000"
            d="M 465.05 685.68 316.63 341.66 C 300.14 303.44 289.46 309.76 268.46 323.90 S 238.50 349.06 261.16 382.72 L 465.05 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[2]}-4, #000)` }}
          />
          <path
            className=" level-4-from-top"
            fill="#000"
            d="M 465.05 685.68 347.18 409.91 C 334.08 379.27 325.21 384.52 307.78 396.25 S 282.91 417.15 301.12 444.00 L 465.05 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[2]}-3, #000)` }}
          />
          <path
            className=" level-5-from-top"
            fill="#000"
            d="M 465.05 685.68 377.72 478.16 C 368.02 455.10 360.96 459.28 347.10 468.61 S 327.31 485.23 341.09 505.27 L 465.05 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[2]}-2, #000)` }}
          />
          <path
            className=" level-6-from-top"
            fill="#000"
            d="M 465.05 685.68 408.27 546.41 C 401.96 530.93 396.72 534.03 386.41 540.97 S 371.72 553.31 381.05 566.55 L 465.05 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[2]}-1, #000)` }}
          />
        </symbol>
        <symbol id={`obj-3-of-12-with-8-mls-in-${colors[3]}`}>
          <path
            className=" level-0-from-top"
            fill="#000"
            d="M 475.06 680.00 317.18 96.28 C 299.63 31.43 283.31 37.04 250.60 49.98 S 201.68 74.06 229.01 134.65 L 475.06 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[3]}-7, #000)` }}
          />
          <path
            className=" level-1-from-top"
            fill="#000"
            d="M 475.06 680.00 337.46 169.04 C 322.18 112.27 307.69 117.25 278.66 128.73 S 235.24 150.10 259.22 203.09 L 475.06 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[3]}-6, #000)` }}
          />
          <path
            className=" level-2-from-top"
            fill="#000"
            d="M 475.06 680.00 357.75 241.79 C 344.72 193.11 332.07 197.46 306.72 207.48 S 268.80 226.15 289.43 271.53 L 475.06 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[3]}-5, #000)` }}
          />
          <path
            className=" level-3-from-top"
            fill="#000"
            d="M 475.06 680.00 378.04 314.55 C 367.26 273.95 356.45 277.67 334.77 286.24 S 302.36 302.19 319.63 339.97 L 475.06 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[3]}-4, #000)` }}
          />
          <path
            className=" level-4-from-top"
            fill="#000"
            d="M 475.06 680.00 398.32 387.31 C 389.80 354.78 380.82 357.87 362.83 364.99 S 335.92 378.23 349.84 408.41 L 475.06 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[3]}-3, #000)` }}
          />
          <path
            className=" level-5-from-top"
            fill="#000"
            d="M 475.06 680.00 418.61 460.06 C 412.34 435.62 405.20 438.08 390.89 443.74 S 369.48 454.28 380.04 476.85 L 475.06 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[3]}-2, #000)` }}
          />
          <path
            className=" level-6-from-top"
            fill="#000"
            d="M 475.06 680.00 438.90 532.82 C 434.88 516.46 429.58 518.29 418.95 522.49 S 403.04 530.32 410.25 545.29 L 475.06 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[3]}-1, #000)` }}
          />
        </symbol>
        <symbol id={`obj-4-of-12-with-8-mls-in-${colors[4]}`}>
          <path
            className=" level-0-from-top"
            fill="#000"
            d="M 485.17 676.69 408.37 74.51 C 399.84 7.60 383.83 10.26 351.66 16.88 S 303.01 30.33 321.22 94.97 L 485.17 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[4]}-7, #000)` }}
          />
          <path
            className=" level-1-from-top"
            fill="#000"
            d="M 485.17 676.69 418.51 149.68 C 411.10 91.12 396.90 93.48 368.35 99.36 S 325.17 111.29 341.17 167.83 L 485.17 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[4]}-6, #000)` }}
          />
          <path
            className=" level-2-from-top"
            fill="#000"
            d="M 485.17 676.69 428.65 224.85 C 422.37 174.64 409.97 176.70 385.04 181.83 S 347.33 192.26 361.11 240.70 L 485.17 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[4]}-5, #000)` }}
          />
          <path
            className=" level-3-from-top"
            fill="#000"
            d="M 485.17 676.69 438.79 300.01 C 433.64 258.16 423.04 259.92 401.73 264.31 S 369.49 273.22 381.06 313.57 L 485.17 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[4]}-4, #000)` }}
          />
          <path
            className=" level-4-from-top"
            fill="#000"
            d="M 485.17 676.69 448.94 375.18 C 444.91 341.68 436.11 343.14 418.41 346.79 S 391.65 354.18 401.00 386.43 L 485.17 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[4]}-3, #000)` }}
          />
          <path
            className=" level-5-from-top"
            fill="#000"
            d="M 485.17 676.69 459.08 450.35 C 456.18 425.20 449.18 426.36 435.10 429.26 S 413.82 435.15 420.95 459.30 L 485.17 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[4]}-2, #000)` }}
          />
          <path
            className=" level-6-from-top"
            fill="#000"
            d="M 485.17 676.69 469.22 525.52 C 467.45 508.72 462.25 509.59 451.79 511.74 S 435.98 516.11 440.90 532.17 L 485.17 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[4]}-1, #000)` }}
          />
        </symbol>
        <symbol id={`obj-5-of-12-with-8-mls-in-${colors[5]}`}>
          <path
            className=" level-0-from-top"
            fill="#000"
            d="M 495.09 675.18 498.04 67.52 C 498.36 0.00 482.54 0.23 450.87 1.81 S 403.14 7.10 412.34 73.91 L 495.09 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[5]}-7, #000)` }}
          />
          <path
            className=" level-1-from-top"
            fill="#000"
            d="M 495.09 675.18 498.20 143.46 C 498.55 84.38 484.51 84.58 456.40 85.99 S 414.04 90.68 422.14 149.13 L 495.09 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[5]}-6, #000)` }}
          />
          <path
            className=" level-2-from-top"
            fill="#000"
            d="M 495.09 675.18 498.37 219.39 C 498.73 168.75 486.47 168.93 461.92 170.16 S 424.93 174.26 431.95 224.35 L 495.09 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[5]}-5, #000)` }}
          />
          <path
            className=" level-3-from-top"
            fill="#000"
            d="M 495.09 675.18 498.53 295.33 C 498.92 253.13 488.44 253.28 467.45 254.33 S 435.83 257.83 441.76 299.57 L 495.09 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[5]}-4, #000)` }}
          />
          <path
            className=" level-4-from-top"
            fill="#000"
            d="M 495.09 675.18 498.70 371.27 C 499.10 337.50 490.40 337.63 472.98 338.50 S 446.73 341.41 451.56 374.78 L 495.09 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[5]}-3, #000)` }}
          />
          <path
            className=" level-5-from-top"
            fill="#000"
            d="M 495.09 675.18 498.86 447.21 C 499.28 421.88 492.36 421.98 478.51 422.67 S 457.62 424.98 461.37 450.00 L 495.09 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[5]}-2, #000)` }}
          />
          <path
            className=" level-6-from-top"
            fill="#000"
            d="M 495.09 675.18 499.03 523.14 C 499.47 506.25 494.33 506.32 484.03 506.84 S 468.52 508.56 471.18 525.22 L 495.09 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[5]}-1, #000)` }}
          />
        </symbol>
        <symbol id={`obj-6-of-12-with-8-mls-in-${colors[6]}`}>
          <path
            className=" level-0-from-top"
            fill="#000"
            d="M 504.91 675.18 501.96 67.52 C 501.64 0.00 517.46 0.23 549.13 1.81 S 596.86 7.10 587.66 73.91 L 504.91 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[6]}-7, #000)` }}
          />
          <path
            className=" level-1-from-top"
            fill="#000"
            d="M 504.91 675.18 501.80 143.46 C 501.45 84.38 515.49 84.58 543.60 85.99 S 585.96 90.68 577.86 149.13 L 504.91 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[6]}-6, #000)` }}
          />
          <path
            className=" level-2-from-top"
            fill="#000"
            d="M 504.91 675.18 501.63 219.39 C 501.27 168.75 513.53 168.93 538.08 170.16 S 575.07 174.26 568.05 224.35 L 504.91 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[6]}-5, #000)` }}
          />
          <path
            className=" level-3-from-top"
            fill="#000"
            d="M 504.91 675.18 501.47 295.33 C 501.08 253.13 511.56 253.28 532.55 254.33 S 564.17 257.83 558.24 299.57 L 504.91 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[6]}-4, #000)` }}
          />
          <path
            className=" level-4-from-top"
            fill="#000"
            d="M 504.91 675.18 501.30 371.27 C 500.90 337.50 509.60 337.63 527.02 338.50 S 553.27 341.41 548.44 374.78 L 504.91 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[6]}-3, #000)` }}
          />
          <path
            className=" level-5-from-top"
            fill="#000"
            d="M 504.91 675.18 501.14 447.21 C 500.72 421.88 507.64 421.98 521.49 422.67 S 542.38 424.98 538.63 450.00 L 504.91 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[6]}-2, #000)` }}
          />
          <path
            className=" level-6-from-top"
            fill="#000"
            d="M 504.91 675.18 500.97 523.14 C 500.53 506.25 505.67 506.32 515.97 506.84 S 531.48 508.56 528.82 525.22 L 504.91 675.18"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[6]}-1, #000)` }}
          />
        </symbol>
        <symbol id={`obj-7-of-12-with-8-mls-in-${colors[7]}`}>
          <path
            className=" level-0-from-top"
            fill="#000"
            d="M 514.83 676.69 591.63 74.51 C 600.16 7.60 616.17 10.26 648.34 16.88 S 696.99 30.33 678.78 94.97 L 514.83 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[7]}-7, #000)` }}
          />
          <path
            className=" level-1-from-top"
            fill="#000"
            d="M 514.83 676.69 581.49 149.68 C 588.90 91.12 603.10 93.48 631.65 99.36 S 674.83 111.29 658.83 167.83 L 514.83 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[7]}-6, #000)` }}
          />
          <path
            className=" level-2-from-top"
            fill="#000"
            d="M 514.83 676.69 571.35 224.85 C 577.63 174.64 590.03 176.70 614.96 181.83 S 652.67 192.26 638.89 240.70 L 514.83 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[7]}-5, #000)` }}
          />
          <path
            className=" level-3-from-top"
            fill="#000"
            d="M 514.83 676.69 561.21 300.01 C 566.36 258.16 576.96 259.92 598.27 264.31 S 630.51 273.22 618.94 313.57 L 514.83 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[7]}-4, #000)` }}
          />
          <path
            className=" level-4-from-top"
            fill="#000"
            d="M 514.83 676.69 551.06 375.18 C 555.09 341.68 563.89 343.14 581.59 346.79 S 608.35 354.18 599.00 386.43 L 514.83 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[7]}-3, #000)` }}
          />
          <path
            className=" level-5-from-top"
            fill="#000"
            d="M 514.83 676.69 540.92 450.35 C 543.82 425.20 550.82 426.36 564.90 429.26 S 586.18 435.15 579.05 459.30 L 514.83 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[7]}-2, #000)` }}
          />
          <path
            className=" level-6-from-top"
            fill="#000"
            d="M 514.83 676.69 530.78 525.52 C 532.55 508.72 537.75 509.59 548.21 511.74 S 564.02 516.11 559.10 532.17 L 514.83 676.69"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[7]}-1, #000)` }}
          />
        </symbol>
        <symbol id={`obj-8-of-12-with-8-mls-in-${colors[8]}`}>
          <path
            className=" level-0-from-top"
            fill="#000"
            d="M 524.94 680.00 682.82 96.28 C 700.37 31.43 716.69 37.04 749.40 49.98 S 798.32 74.06 770.99 134.65 L 524.94 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[8]}-7, #000)` }}
          />
          <path
            className=" level-1-from-top"
            fill="#000"
            d="M 524.94 680.00 662.54 169.04 C 677.82 112.27 692.31 117.25 721.34 128.73 S 764.76 150.10 740.78 203.09 L 524.94 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[8]}-6, #000)` }}
          />
          <path
            className=" level-2-from-top"
            fill="#000"
            d="M 524.94 680.00 642.25 241.79 C 655.28 193.11 667.93 197.46 693.28 207.48 S 731.20 226.15 710.57 271.53 L 524.94 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[8]}-5, #000)` }}
          />
          <path
            className=" level-3-from-top"
            fill="#000"
            d="M 524.94 680.00 621.96 314.55 C 632.74 273.95 643.55 277.67 665.23 286.24 S 697.64 302.19 680.37 339.97 L 524.94 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[8]}-4, #000)` }}
          />
          <path
            className=" level-4-from-top"
            fill="#000"
            d="M 524.94 680.00 601.68 387.31 C 610.20 354.78 619.18 357.87 637.17 364.99 S 664.08 378.23 650.16 408.41 L 524.94 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[8]}-3, #000)` }}
          />
          <path
            className=" level-5-from-top"
            fill="#000"
            d="M 524.94 680.00 581.39 460.06 C 587.66 435.62 594.80 438.08 609.11 443.74 S 630.52 454.28 619.96 476.85 L 524.94 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[8]}-2, #000)` }}
          />
          <path
            className=" level-6-from-top"
            fill="#000"
            d="M 524.94 680.00 561.10 532.82 C 565.12 516.46 570.42 518.29 581.05 522.49 S 596.96 530.32 589.75 545.29 L 524.94 680.00"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[8]}-1, #000)` }}
          />
        </symbol>
        <symbol id={`obj-9-of-12-with-8-mls-in-${colors[9]}`}>
          <path
            className=" level-0-from-top"
            fill="#000"
            d="M 534.95 685.68 775.00 136.92 C 801.67 75.95 817.80 85.49 849.49 106.82 S 894.71 144.81 858.74 198.90 L 534.95 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[9]}-7, #000)` }}
          />
          <path
            className=" level-1-from-top"
            fill="#000"
            d="M 534.95 685.68 744.46 205.17 C 767.74 151.78 782.04 160.25 810.18 179.18 S 850.31 212.89 818.77 260.17 L 534.95 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[9]}-6, #000)` }}
          />
          <path
            className=" level-2-from-top"
            fill="#000"
            d="M 534.95 685.68 713.91 273.42 C 733.80 227.61 746.29 235.00 770.86 251.54 S 805.90 280.98 778.81 321.45 L 534.95 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[9]}-5, #000)` }}
          />
          <path
            className=" level-3-from-top"
            fill="#000"
            d="M 534.95 685.68 683.37 341.66 C 699.86 303.44 710.54 309.76 731.54 323.90 S 761.50 349.06 738.84 382.72 L 534.95 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[9]}-4, #000)` }}
          />
          <path
            className=" level-4-from-top"
            fill="#000"
            d="M 534.95 685.68 652.82 409.91 C 665.92 379.27 674.79 384.52 692.22 396.25 S 717.09 417.15 698.88 444.00 L 534.95 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[9]}-3, #000)` }}
          />
          <path
            className=" level-5-from-top"
            fill="#000"
            d="M 534.95 685.68 622.28 478.16 C 631.98 455.10 639.04 459.28 652.90 468.61 S 672.69 485.23 658.91 505.27 L 534.95 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[9]}-2, #000)` }}
          />
          <path
            className=" level-6-from-top"
            fill="#000"
            d="M 534.95 685.68 591.73 546.41 C 598.04 530.93 603.28 534.03 613.59 540.97 S 628.28 553.31 618.95 566.55 L 534.95 685.68"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[9]}-1, #000)` }}
          />
        </symbol>
        <symbol id={`obj-10-of-12-with-8-mls-in-${colors[10]}`}>
          <path
            className=" level-0-from-top"
            fill="#000"
            d="M 543.77 694.37 862.29 202.37 C 897.69 147.70 911.70 162.20 937.69 193.70 S 969.92 246.89 927.30 291.63 L 543.77 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[10]}-7, #000)` }}
          />
          <path
            className=" level-1-from-top"
            fill="#000"
            d="M 543.77 694.37 822.03 263.35 C 852.95 215.46 865.38 228.33 888.45 256.29 S 917.05 303.49 879.72 342.57 L 543.77 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[10]}-6, #000)` }}
          />
          <path
            className=" level-2-from-top"
            fill="#000"
            d="M 543.77 694.37 781.76 324.34 C 808.21 283.22 819.07 294.46 839.21 318.87 S 864.18 360.09 832.14 393.52 L 543.77 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[10]}-5, #000)` }}
          />
          <path
            className=" level-3-from-top"
            fill="#000"
            d="M 543.77 694.37 741.50 385.32 C 763.47 350.98 772.75 360.58 789.97 381.45 S 811.32 416.69 784.56 444.46 L 543.77 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[10]}-4, #000)` }}
          />
          <path
            className=" level-4-from-top"
            fill="#000"
            d="M 543.77 694.37 701.23 446.30 C 718.73 418.74 726.44 426.71 740.73 444.04 S 758.45 473.29 736.99 495.40 L 543.77 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[10]}-3, #000)` }}
          />
          <path
            className=" level-5-from-top"
            fill="#000"
            d="M 543.77 694.37 660.97 507.28 C 673.99 486.50 680.12 492.84 691.49 506.62 S 705.59 529.89 689.41 546.34 L 543.77 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[10]}-2, #000)` }}
          />
          <path
            className=" level-6-from-top"
            fill="#000"
            d="M 543.77 694.37 620.70 568.27 C 629.25 554.25 633.80 558.97 642.25 569.20 S 652.72 586.49 641.83 597.28 L 543.77 694.37"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[10]}-1, #000)` }}
          />
        </symbol>
        <symbol id={`obj-11-of-12-with-8-mls-in-${colors[11]}`}>
          <path
            className=" level-0-from-top"
            fill="#000"
            d="M 549.25 706.02 929.52 296.31 C 971.78 250.79 980.02 270.05 992.47 310.17 S 999.99 372.82 954.92 406.14 L 549.25 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[11]}-7, #000)` }}
          />
          <path
            className=" level-1-from-top"
            fill="#000"
            d="M 549.25 706.02 881.76 346.86 C 918.70 306.95 926.02 324.04 937.07 359.65 S 943.74 415.25 904.29 444.33 L 549.25 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[11]}-6, #000)` }}
          />
          <path
            className=" level-2-from-top"
            fill="#000"
            d="M 549.25 706.02 833.99 397.40 C 865.63 363.11 872.01 378.04 881.66 409.13 S 887.49 457.68 853.67 482.52 L 549.25 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[11]}-5, #000)` }}
          />
          <path
            className=" level-3-from-top"
            fill="#000"
            d="M 549.25 706.02 786.22 447.95 C 812.55 419.27 818.01 432.03 826.26 458.61 S 831.24 500.12 803.04 520.71 L 549.25 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[11]}-4, #000)` }}
          />
          <path
            className=" level-4-from-top"
            fill="#000"
            d="M 549.25 706.02 738.45 498.49 C 759.48 475.44 764.01 486.03 770.86 508.09 S 775.00 542.55 752.42 558.90 L 549.25 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[11]}-3, #000)` }}
          />
          <path
            className=" level-5-from-top"
            fill="#000"
            d="M 549.25 706.02 690.69 549.04 C 706.40 531.60 710.01 540.02 715.46 557.57 S 718.75 584.98 701.80 597.09 L 549.25 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[11]}-2, #000)` }}
          />
          <path
            className=" level-6-from-top"
            fill="#000"
            d="M 549.25 706.02 642.92 599.58 C 653.33 587.76 656.01 594.02 660.05 607.05 S 662.50 627.42 651.17 635.28 L 549.25 706.02"
            stroke="white"
            stroke-width="0"
            style={{ fill: `var(--${colors[11]}-1, #000)` }}
          />
        </symbol>
      </defs>
      {useClasses.map((classes, index) => (
        <use
          key={index}
          className={classes.join(" ")}
          xlinkHref={`#obj-${index}-of-12-with-8-mls-in-${colors[index]}`}
          x="0"
          y="0"
        />
      ))}
    </svg>
  );
};

export default observer(ComplicatedShell);
