// eslint-disable-next-line import/no-cycle
import api, { ErrorHandlerPackage } from "../api/api";
import { PollingAssignmentAssessmentDefaultRule } from "../models/PollingAssignmentAssessmentDefaultRule";
import { PollingActivity } from "../models/polling/PollingActivity";
import { PollingActivityQuestionResponse } from "../models/polling/PollingActivityQuestionResponse";
import { PollingActivityTemplate } from "../models/polling/PollingActivityTemplate";
import { PollingQuestionTemplate } from "../models/polling/PollingQuestionTemplate";
import {
  deepCopy,
  insertObjectInArrayOfObjects,
  sortArrayOfObjectsByDate,
} from "../utilities/collectionUtils";
import { getLocalDate } from "../utilities/dateTimeUtils";
import { StoreValue } from "./storeValue";

class PollingStore {
  private pollingActivitiesForAssignmentRegistry = new StoreValue<
    PollingActivity[],
    { courseID: string; assignmentID: string }
  >();

  private activePollingActivityRegistry = new StoreValue<
    PollingActivity,
    { courseID: string; pollingActivityID: string }
  >();

  private pollingActivityQuestionResponsesForQuestionRegistry = new StoreValue<
    PollingActivityQuestionResponse[],
    { courseID: string; pollingActivityQuestionID: string }
  >();

  private pollingActivityTemplatesRegistry = new StoreValue<PollingActivityTemplate[]>();

  private pollingActivityTemplateForIDRegistry = new StoreValue<
    PollingActivityTemplate,
    { pollingActivityTemplateID: string }
  >();

  private pollingActivityQuestionResponsesForSelfRegistry = new StoreValue<
    PollingActivityQuestionResponse[] | null,
    { courseID: string; pollingActivityID: string; userID: string }
  >();

  private learningLogRegistry = new StoreValue<
    PollingActivityQuestionResponse[],
    { courseID: string; userID: string }
  >();

  private pollingQuestionTemplateRegistry = new StoreValue<
    PollingQuestionTemplate,
    { pollingQuestionTemplateID: string }
  >();

  private defaultRulesRegistry = new StoreValue<
    PollingAssignmentAssessmentDefaultRule[],
    { courseID: string }
  >();

  get pollingQuestionTemplate() {
    return this.pollingQuestionTemplateRegistry.value;
  }

  get activePollingActivity() {
    return this.activePollingActivityRegistry.value;
  }

  get pollingActivityQuestionResponsesForQuestion() {
    return this.pollingActivityQuestionResponsesForQuestionRegistry.value;
  }

  get pollingActivityTemplates() {
    return this.pollingActivityTemplatesRegistry.value;
  }

  get pollingActivityTemplateForID() {
    return this.pollingActivityTemplateForIDRegistry.value;
  }

  get pollingActivitiesForAssignment() {
    return this.pollingActivitiesForAssignmentRegistry.value;
  }

  get pollingActivityQuestionResponsesForSelf() {
    return this.pollingActivityQuestionResponsesForSelfRegistry.value;
  }

  get learningLog() {
    return this.learningLogRegistry.value;
  }

  get defaultRules() {
    return this.defaultRulesRegistry.value;
  }

  hasLoadedPollingQuestionTemplate = (pollingQuestionTemplateID: string) =>
    this.pollingQuestionTemplateRegistry.fresh(false, { pollingQuestionTemplateID });

  hasLoadedPollingActivityTemplates = () => this.pollingActivityTemplatesRegistry.fresh(false);

  hasLoadedPollingActivityTemplateForID = (pollingActivityTemplateID: string) =>
    this.pollingActivityTemplateForIDRegistry.fresh(false, { pollingActivityTemplateID });

  hasLoadedPollingActivitiesForAssignment = (courseID: string, assignmentID: string) =>
    this.pollingActivitiesForAssignmentRegistry.fresh(false, { courseID, assignmentID });

  hasLoadedPollingActivityQuestionResponsesForQuestion = (
    courseID: string,
    pollingActivityQuestionID: string
  ) =>
    this.pollingActivityQuestionResponsesForQuestionRegistry.fresh(false, {
      courseID,
      pollingActivityQuestionID,
    });

  hasLoadedPollingActivityQuestionResponseForSelf = (
    courseID: string,
    pollingActivityID: string,
    userID: string
  ) =>
    this.pollingActivityQuestionResponsesForSelfRegistry.fresh(false, {
      courseID,
      pollingActivityID,
      userID,
    });

  hasLoadedLearningLog = (courseID: string, userID: string) =>
    this.learningLogRegistry.fresh(false, {
      courseID,
      userID,
    });

  hasLoadedDefaultRules = (courseID: string) =>
    this.defaultRulesRegistry.fresh(false, { courseID });

  reset = () => {
    this.activePollingActivityRegistry.reset();
    this.pollingActivitiesForAssignmentRegistry.reset();
    this.pollingActivityTemplateForIDRegistry.reset();
    this.pollingActivityQuestionResponsesForQuestionRegistry.reset();
    this.pollingActivityTemplatesRegistry.reset();
    this.defaultRulesRegistry.reset();
  };

  loadPollingQuestionTemplate = async (
    pollingQuestionTemplateID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    if (this.pollingQuestionTemplateRegistry.fresh(true, { pollingQuestionTemplateID })) return;

    this.pollingQuestionTemplateRegistry.setLoading(true, { pollingQuestionTemplateID });

    const result = await api.PollingQuestionTemplates.details(
      pollingQuestionTemplateID,
      errorHandlerPackage
    );

    this.pollingQuestionTemplateRegistry.setAll(result, { pollingQuestionTemplateID });

    this.pollingQuestionTemplateRegistry.setLoading(false);
  };

  loadPollingActivitiesForAssignment = async (
    courseID: string,
    assignmentID: string,
    excludeActivePollingActivity: boolean,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    if (this.pollingActivitiesForAssignmentRegistry.fresh(true, { courseID, assignmentID })) return;

    this.pollingActivitiesForAssignmentRegistry.setLoading(true, { courseID, assignmentID });

    const result = await api.PollingActivities.getForAssignment(
      courseID,
      assignmentID,
      excludeActivePollingActivity,
      errorHandlerPackage
    );

    result.forEach((pa) => this.setPollingActivityDates(pa));

    this.pollingActivitiesForAssignmentRegistry.setAll(result, { courseID, assignmentID });

    this.pollingActivitiesForAssignmentRegistry.setLoading(false);
  };

  closePollingActivity = async (
    courseID: string,
    assignmentID: string,
    pollingActivityID: string | undefined,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    if (pollingActivityID) {
      const result = await api.PollingActivities.close(
        courseID,
        assignmentID,
        pollingActivityID,
        errorHandlerPackage
      );

      const newActivities = this.pollingActivitiesForAssignment ?? [];

      if (result) {
        // Set the dates
        this.setPollingActivityDates(result);
        result.pollingActivityQuestions?.forEach((paq) =>
          paq.pollingActivityQuestionResponses.forEach(this.setPollingActivityQuestionResponseDates)
        );

        // if the closed poll is for the correct assignment
        if (
          this.pollingActivitiesForAssignmentRegistry.fresh(false, {
            courseID: result.courseID,
            assignmentID: result.assignmentID,
          })
        ) {
          insertObjectInArrayOfObjects(newActivities, result, "id");
          sortArrayOfObjectsByDate(newActivities, "createdTime", true);
          this.pollingActivitiesForAssignmentRegistry.setValue(newActivities);
        }
        this.activePollingActivityRegistry.reset();
      }
    }
  };

  loadPollingActivityQuestionResponsesForQuestion = async (
    courseID: string,
    pollingActivityQuestionID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    if (
      this.pollingActivityQuestionResponsesForQuestionRegistry.fresh(true, {
        courseID,
        pollingActivityQuestionID,
      })
    )
      return;

    this.pollingActivityQuestionResponsesForQuestionRegistry.setLoading(true, {
      courseID,
      pollingActivityQuestionID,
    });

    const result = await api.PollingActivityQuestionResponses.getForQuestion(
      courseID,
      pollingActivityQuestionID,
      errorHandlerPackage
    );

    this.pollingActivityQuestionResponsesForQuestionRegistry.setAll(result, {
      courseID,
      pollingActivityQuestionID,
    });

    this.pollingActivityQuestionResponsesForQuestionRegistry.setLoading(false);
  };

  recordNewPollingActivityQuestionResponseForQuestion = async (
    pollingActivityQuestionResponse: PollingActivityQuestionResponse
  ) => {
    const { pollingActivityQuestionID } = pollingActivityQuestionResponse;

    const pollingActivity = this.activePollingActivity;

    if (!pollingActivity) return;

    const pollingActivityClone = deepCopy(pollingActivity) as PollingActivity;
    const pollingActivityQuestions = pollingActivityClone?.pollingActivityQuestions;

    if (!pollingActivityQuestions) return;

    const questionForResponse = pollingActivityQuestions.find(
      (q) => q.id === pollingActivityQuestionID
    );

    if (!questionForResponse) return;

    const { pollingActivityQuestionResponses } = questionForResponse;

    this.setPollingActivityQuestionResponseDates(pollingActivityQuestionResponse);

    insertObjectInArrayOfObjects(
      pollingActivityQuestionResponses,
      pollingActivityQuestionResponse,
      "id"
    );

    this.activePollingActivityRegistry.setValue(pollingActivityClone);
  };

  createOrUpdatePollingActivityQuestionResponse = async (
    pollingActivityQuestionResponse: PollingActivityQuestionResponse,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.PollingActivityQuestionResponses.createOrUpdate(
      pollingActivityQuestionResponse,
      errorHandlerPackage
    );

    if (result) {
      const responsesForSelf = [
        ...(this.pollingActivityQuestionResponsesForSelfRegistry.value ?? []),
      ];

      const index = responsesForSelf.findIndex((r) => r.id === result.id);

      if (index === -1) responsesForSelf.push(result);
      else responsesForSelf[index] = result;

      this.pollingActivityQuestionResponsesForSelfRegistry.setValue(responsesForSelf);

      this.recordNewPollingActivityQuestionResponseForQuestion(result);
    }

    return result;
  };

  loadPollingActivityTemplates = async (errorHandlerPackage?: ErrorHandlerPackage) => {
    if (this.pollingActivityTemplatesRegistry.fresh(true)) return;

    this.pollingActivityTemplatesRegistry.setLoading(true);

    const result = await api.PollingActivityTemplates.list(errorHandlerPackage);

    this.pollingActivityTemplatesRegistry.setValue(result);

    this.pollingActivityTemplatesRegistry.setLoading(false);
  };

  loadPollingActivityTemplateForID = async (
    pollingActivityTemplateID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    if (this.pollingActivityTemplateForIDRegistry.fresh(true, { pollingActivityTemplateID }))
      return;

    this.pollingActivityTemplateForIDRegistry.setLoading(true, { pollingActivityTemplateID });

    const result = await api.PollingActivityTemplates.get(
      pollingActivityTemplateID,
      errorHandlerPackage
    );

    this.pollingActivityTemplateForIDRegistry.setAll(result, { pollingActivityTemplateID });

    this.pollingActivityTemplateForIDRegistry.setLoading(false);
  };

  setActivePollingActivity = (activeActivity: PollingActivity) => {
    // ensure any previous poll is closed
    if (
      !this.activePollingActivityRegistry.fresh(false, {
        courseID: activeActivity.courseID,
        pollingActivityID: activeActivity.id,
      })
    ) {
      this.closeActivePollingActivity(this.activePollingActivity?.id);
    }

    this.setPollingActivityDates(activeActivity);

    // set the active polling activity
    this.activePollingActivityRegistry.setAll(activeActivity, {
      courseID: activeActivity.courseID,
      pollingActivityID: activeActivity.id,
    });
  };

  closeActivePollingActivity = (activityID: string | undefined) => {
    if (
      activityID &&
      this.activePollingActivity &&
      // if the closed poll is for the right course
      this.activePollingActivityRegistry.fresh(false, {
        courseID: this.activePollingActivity?.courseID,
        pollingActivityID: activityID,
      }) &&
      // if the closed poll is for the right assignment
      this.pollingActivitiesForAssignmentRegistry.fresh(false, {
        courseID: this.activePollingActivity?.courseID,
        assignmentID: this.activePollingActivity.assignmentID,
      })
    ) {
      // Add the closed activity to the list
      this.pollingActivitiesForAssignmentRegistry.setValue([
        ...(this.pollingActivitiesForAssignmentRegistry.value ?? []),
        this.activePollingActivity,
      ]);

      // Reset the active polling activity registry
      this.activePollingActivityRegistry.reset();
    }

    // this function currently just resets the registry, but later it will probably want to
    // move the active activity to the list of activities associated with the assignment, etc.
  };

  deletePollingActivity = async (
    courseID: string,
    activityID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.PollingActivities.delete(
      courseID,
      activityID,
      assignmentID,
      errorHandlerPackage
    );

    if (result) {
      const newPollingActivitiesForAssignment =
        this.pollingActivitiesForAssignmentRegistry.value?.filter((pa) => pa.id !== activityID);

      this.pollingActivitiesForAssignmentRegistry.setValue(newPollingActivitiesForAssignment ?? []);
    }

    return result;
  };

  removePollingActivity = async (activityID: string) => {
    const newPollingActivitiesForAssignment =
      this.pollingActivitiesForAssignmentRegistry.value?.filter((pa) => pa.id !== activityID);

    this.pollingActivitiesForAssignmentRegistry.setValue(newPollingActivitiesForAssignment ?? []);
  };

  loadPollingActivityQuestionResponseForSelf = async (
    courseID: string,
    pollingActivityID: string,
    userID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    if (
      this.pollingActivityQuestionResponsesForSelfRegistry.fresh(true, {
        courseID,
        pollingActivityID,
        userID,
      })
    )
      return;

    this.pollingActivityQuestionResponsesForSelfRegistry.setLoading(true, {
      courseID,
      pollingActivityID,
      userID,
    });

    const result = await api.PollingActivityQuestionResponses.getForSelfForActivity(
      courseID,
      pollingActivityID,
      userID,
      errorHandlerPackage
    );

    if (result) {
      result.forEach(this.setPollingActivityQuestionResponseDate);
      this.pollingActivityQuestionResponsesForSelfRegistry.setAll(result, {
        courseID,
        pollingActivityID,
        userID,
      });
    }

    this.pollingActivityQuestionResponsesForSelfRegistry.setLoading(false);
  };

  loadLearningLog = async (
    courseID: string,
    userID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    if (
      this.learningLogRegistry.fresh(true, {
        courseID,
        userID,
      })
    )
      return;

    this.learningLogRegistry.setLoading(true, {
      courseID,
      userID,
    });

    const result = await api.PollingActivityQuestionResponses.getLearningLog(
      courseID,
      userID,
      errorHandlerPackage
    );

    if (result) {
      result.forEach(this.setPollingActivityQuestionResponseDate);
      this.learningLogRegistry.setAll(result, {
        courseID,
        userID,
      });
    }

    this.learningLogRegistry.setLoading(false);
  };

  loadDefaultRules = async (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) => {
    if (this.defaultRulesRegistry.fresh(true, { courseID })) return;

    this.defaultRulesRegistry.setLoading(true, {
      courseID,
    });

    const result = await api.PollingAssignmentAssessmentDefaultRules.list(
      courseID,
      errorHandlerPackage
    );

    if (result) {
      this.defaultRulesRegistry.setAll(result, {
        courseID,
      });
    }

    this.defaultRulesRegistry.setLoading(false);
  };

  createOrUpdateDefaultRules = async (
    courseID: string,
    rules: PollingAssignmentAssessmentDefaultRule[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.PollingAssignmentAssessmentDefaultRules.createOrUpdate(
      courseID,
      rules,
      errorHandlerPackage
    );

    if (result) {
      const updatedDefaultRules = [...(this.defaultRulesRegistry.value ?? [])];

      result.forEach((newRule) => insertObjectInArrayOfObjects(updatedDefaultRules, newRule, "id"));

      this.defaultRulesRegistry.setValue(updatedDefaultRules);
    }

    return result;
  };

  deleteDefaultRule = async (
    courseID: string,
    ruleID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.PollingAssignmentAssessmentDefaultRules.delete(
      courseID,
      ruleID,
      errorHandlerPackage
    );

    if (result) {
      const updatedDefaultRules = [...(this.defaultRulesRegistry.value ?? [])].filter(
        (rule) => rule.id !== ruleID
      );

      this.defaultRulesRegistry.setValue(updatedDefaultRules);
    }

    return result;
  };

  setPollingActivityQuestionResponseDate(a: PollingActivityQuestionResponse) {
    const pollingActivity = a;
    pollingActivity.responseTime = pollingActivity.responseTime
      ? getLocalDate(pollingActivity.responseTime)
      : pollingActivity.responseTime;
  }

  setPollingActivityDates = (pollingActivity: PollingActivity) => {
    const pa = pollingActivity;

    pa.openTime = getLocalDate(pa.openTime);
    pa.closeTime = pa.closeTime ? getLocalDate(pa.closeTime) : undefined;
    pa.createdTime = getLocalDate(pa.createdTime);
  };

  setPollingActivityQuestionResponseDates = (
    pollingActivityQuestionResponse: PollingActivityQuestionResponse
  ) => {
    const paqr = pollingActivityQuestionResponse;
    paqr.responseTime = getLocalDate(paqr.responseTime);
  };
}

export default PollingStore;
