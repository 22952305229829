import React from "react";
import { Button, Header } from "semantic-ui-react";
import { Objective } from "../../../../../models/Objective";
import FlexContainer from "../../../../_common/style/FlexContainer";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";

interface ObjectivePopUpProps {
  newObjective: Objective;
  previousObjective: Objective;
  handleSelectPreviousObjective: (objective: Objective) => void;
  handleSelectNewObjective: (objective: Objective) => void;
}

const ObjectivePopUp: React.FC<ObjectivePopUpProps> = ({
  previousObjective,
  handleSelectPreviousObjective,
  newObjective,
  handleSelectNewObjective,
}) => (
  <>
    <span className="click-counter polling-popup">
      <Header as="h4">
        Polling Assignments can only have one objective. Which objective would you like to keep?
      </Header>
      <VerticalGap height="1rem" />
    </span>
    <FlexContainer gap=".5rem" justifyContent="center" alignItems="end">
      <Button
        icon="undo"
        color="blue"
        className="previous-objective-button"
        type="button"
        onClick={() => handleSelectPreviousObjective(previousObjective)}
        content={`Keep Previous Objective: ${previousObjective?.shortName}`}
      />
      <Button
        icon="checkmark"
        color="grey"
        className="previous-objective-button"
        type="button"
        content={`Keep New Objective: ${newObjective?.shortName}`}
        onClick={() => handleSelectNewObjective(newObjective)}
      />
    </FlexContainer>
  </>
);

export default ObjectivePopUp;
