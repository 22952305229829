import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Icon, Label } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../hooks/useCurrentUserCourseRole";
import { RatedObjective } from "../../../../models/RatedObjective";
import { Submission } from "../../../../models/Submission";
import { useStore } from "../../../../stores/store";
import { getGraphicDataForSubmission } from "../../../../utilities/assessmentGraphicUtils";
import { formatDateDownToDayNoWeekday } from "../../../../utilities/dateTimeUtils";
import buildPath from "../../../../utilities/routing/buildPath";
import {
  SubmissionPackage,
  isSubmissionAssessed,
  isSubmissionAssessmentPublished,
} from "../../../../utilities/submissionUtils";
import { createClassName } from "../../../../utilities/utils";
import AssessmentSummaryGraphic from "../../../_common/assessmentGraphic/AssessmentSummaryGraphic";
import SubmissionCommentModal from "../_modal/SubmissionCommentModal";
import "./SubmissionsMenu.css";

interface SubmissionsMenuProps {
  courseID: string;
  assignmentID: string;
  submissionPackages: SubmissionPackage[];
  ratings: RatedObjective[] | undefined;
  userID: string;
  setSubmissionID: (submissionID: string | undefined) => void;
  showingInAssignmentDetails?: boolean;
  originalDraftSubmission?: Submission;
}

const SubmissionsMenu: React.FC<SubmissionsMenuProps> = ({
  courseID,
  assignmentID,
  submissionPackages,
  ratings,
  userID,
  setSubmissionID,
  showingInAssignmentDetails,
  originalDraftSubmission,
}) => {
  const navigate = useNavigate();
  const { isUserStudent } = useCurrentUserCourseRole(courseID);
  const { modalStore } = useStore();
  const { openModal } = modalStore;

  if (submissionPackages.length === 1 && submissionPackages[0]?.isDraft) return <></>;

  const redirect = showingInAssignmentDetails
    ? (sID: string) => setSubmissionID(sID)
    : (sID: string) =>
        navigate(
          buildPath("CourseHomeAssignmentSubmissionTab", {
            courseID,
            assignmentID,
            submissionID: sID,
            userID,
          })
        );

  const newSubmissionPackage: SubmissionPackage | undefined = submissionPackages.find(
    (ps) => ps.isDraft || (!ps.submissionID && !ps.submission)
  );

  return (
    <div className="SubmissionsMenu horizontal-scroll">
      <>
        {originalDraftSubmission && originalDraftSubmission.id && isUserStudent && (
          <div
            className={createClassName("menu-item original-draft-menu-item", {
              name: "active",
              apply: false,
            })}
            key="original-draft"
            onClick={() =>
              openModal(
                <SubmissionCommentModal
                  submission={originalDraftSubmission}
                  modalIndex={0}
                  isFirstSubmission={true}
                />
              )
            }
            role="button"
          >
            <Label className="blank bold original-draft-menu-item-label">
              <Icon name="warning circle" size="big" fitted />
              <span className="unsubmitted-draft">Unsubmitted Draft</span>
              <i className="hidden-from-instructors">{"(Hidden from Instructors)"}</i>
            </Label>
          </div>
        )}
        {submissionPackages
          .filter((sp) => sp.submissionID && sp.submission && !sp.isDraft)
          .map((s) => {
            const { selected, submissionID, submissionType, submission } = s;

            if (!submission) {
              return <></>;
            }

            return (
              <div
                key={submissionID}
                className={createClassName(
                  "menu-item",
                  { name: "active", apply: selected },
                  {
                    name: "reassessment-submission",
                    apply: submissionType === "resubmission",
                  }
                )}
                onClick={() => redirect(submissionID as string)}
                role="button"
              >
                <Label className="blank">
                  {formatDateDownToDayNoWeekday(submission.createdAt)}
                  {ratings && (
                    <AssessmentSummaryGraphic
                      data={getGraphicDataForSubmission(
                        submission as Submission,
                        submissionPackages.map((sp) => sp.submission as Submission),
                        ratings
                      )}
                      size={"6em"}
                      unpublishedRatingIconSize={"1.1em"}
                      unassessedIconSize="2em"
                      unpublishedRatings={
                        isSubmissionAssessed(submission.userID, submissionID ?? "", ratings) &&
                        !isSubmissionAssessmentPublished(submission.userID, submissionID, ratings)
                      }
                    />
                  )}
                </Label>
              </div>
            );
          })}
        {newSubmissionPackage &&
          isUserStudent &&
          newSubmissionPackage.submissionType === "resubmission" && (
            <div
              role="button"
              className={createClassName("menu-item reassess-menu-item", {
                name: "active",
                apply: newSubmissionPackage.selected,
              })}
              key="reassess"
              onClick={() => redirect(newSubmissionPackage.submissionID ?? "new")}
            >
              <Label className="blank bold reassess-menu-item-label">
                <Icon name="redo" size="large" fitted />
                <span>Request</span>
                <span>Reassessment</span>
              </Label>
            </div>
          )}
        {newSubmissionPackage &&
          isUserStudent &&
          newSubmissionPackage.submissionType === "submission" && (
            <div
              role="button"
              key="reassess"
              className={createClassName("menu-item reassess-menu-item", {
                name: "active",
                apply: newSubmissionPackage.selected,
              })}
              onClick={() => redirect(newSubmissionPackage.submissionID ?? "new")}
            >
              <Label className="blank bold reassess-menu-item-label">
                <Icon name="plus" size="large" fitted />
                <span>Submit</span>
                <span>Assignment</span>
              </Label>
            </div>
          )}
      </>
    </div>
  );
};

export default observer(SubmissionsMenu);
