import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Button, Container, Menu } from "semantic-ui-react";
import logo from "../../assets/logo.svg";
import useWindowSize from "../../hooks/useWindowSize";
import TEACHFRONT_PATHS from "../../paths";
import "./NavBar.css";

export interface NavBarHomePageProps {
  divCurrentlyInViewbox: string | null;
  scrollToDiv: (divId: string) => void;
}

const links = [
  {
    refName: "TestimonialSection",
    linkText: "Testimonials",
  },
  {
    refName: "MissionStatement",
    linkText: "Our Mission",
  },
  {
    refName: "MasteryGradingSection",
    linkText: "Mastery Grading 101",
  },
  {
    refName: "FeatureSection",
    linkText: "Features",
  },
];

const NavBarHomePageDesktop: React.FC<NavBarHomePageProps> = ({
  divCurrentlyInViewbox,
  scrollToDiv,
}) => {
  const { isMobile } = useWindowSize();

  const handleClick = (divId: string) => {
    scrollToDiv(divId);
  };

  return (
    <>
      <Menu inverted fixed="top" color={"blue"} className="NavBar" data-print-id="hide">
        <Container>
          <Menu.Item onClick={() => handleClick("HeaderSection")}>
            <img
              className="teachfront-header-img"
              src={logo}
              alt="TeachFront Wave Logo"
              style={{ marginRight: "10px" }}
            />
            <span id="teachfront-header-logo-text">TeachFront</span>
          </Menu.Item>

          {!isMobile &&
            links.map((link) => (
              <Menu.Item
                key={link.refName}
                className="homepage-navigation-menu-item"
                onClick={() => handleClick(link.refName)}
              >
                <div
                  className={`homepage-navigation-link ${
                    divCurrentlyInViewbox === link.refName ? "active" : ""
                  }`}
                >
                  {link.linkText}
                </div>
              </Menu.Item>
            ))}
          <Menu.Item position="right" className="action-item">
            <Button as={Link} to={TEACHFRONT_PATHS.Login} className="green" name="connect" inverted>
              Connect
            </Button>
          </Menu.Item>
          <Menu.Item className="action-item">
            <Button as={Link} to={TEACHFRONT_PATHS.Login} name="login" inverted>
              Login
            </Button>
          </Menu.Item>
        </Container>
      </Menu>
    </>
  );
};

export default observer(NavBarHomePageDesktop);
