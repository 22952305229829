import { observer } from "mobx-react-lite";
import "./BackgroundWaves.css";
import useWindowSize from "../../hooks/useWindowSize";

interface BackgroundWaveProps {
  fixed?: boolean;
  viewBox?: string;
}

const BackgroundWaves: React.FC<BackgroundWaveProps> = ({ fixed }) => {
  const { width } = useWindowSize();
  return (
    <svg
      version="1.1"
      id="BackgroundWaves"
      className={fixed ? "fixed" : ""}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      xmlSpace="preserve"
      preserveAspectRatio="none"
      data-print-id="hide"
      viewBox={width < 450 ? "0 0 1300 1485.2" : "0 0 2600 1485.2"}
    >
      <g className="bg">
        <path
          className="course-color-7"
          d="M456.4,243C679.3,239.5,793.7,96.2,975,126.7c189.9,31.9,293.9,118.2,402.8,120.9c91.7,2.3,181.4-40.5,264.9-87.2
		c115.4-64.6,231.9-78.3,354.4-36.9c93.7,31.7,173.3,99,271.8,114.7c113.2,18,233-14,331.1-86.7V0H0v103.9
		C153.7,105.1,247.8,246.3,456.4,243z"
        />
        <path
          className="course-color-6"
          d="M308.5,264c107.3,26.3,197.8,77.5,307,91.7c271.2,35.2,315.9-118.2,547.9-113.1
		c197.1,4.3,279.2,112.7,443.6,118.8c186,6.9,271.7-136.4,489.8-127.7c222.9,8.8,326.9,137.9,503.2,104V151.5
		c-98.1,72.7-217.9,104.7-331.1,86.7c-98.5-15.7-178.1-83-271.8-114.7c-122.5-41.4-239-27.7-354.4,36.9
		c-83.5,46.7-173.2,89.5-264.9,87.2c-108.9-2.7-212.9-89-402.8-120.9C793.7,96.2,679.3,239.5,456.4,243
		C247.8,246.3,153.7,105.1,0,103.9v195.8C84.2,255.5,204.8,238.7,308.5,264z"
        />
        <path
          className="course-color-5"
          d="M2096.8,233.7C1878.7,225,1793,368.3,1607,361.4c-164.4-6.1-246.5-114.5-443.6-118.8
		c-232-5.1-276.7,148.3-547.9,113.1c-109.2-14.2-199.7-65.4-307-91.7C204.8,238.7,84.2,255.5,0,299.7v213.1
		c19.6,6.8,39,11.5,58.2,14.5c34.7,5.4,68.7,4.9,102.2,0c65.9-9.7,130-36.6,194.1-70.8c106-56.4,223.8-91.9,337.9-58
		c105.7,31.5,203.8,92.6,313.8,106.7c251.7,23.9,326.3-118,518.1-119.9c168.8-1.7,284.9,98.6,432.3,119.9
		c227,32.8,361.5-186.5,643.3-127.9v-39.6C2423.7,371.6,2319.7,242.5,2096.8,233.7z"
        />
        <path
          className="course-color-4"
          d="M1956.7,505.2c-147.4-21.3-263.5-121.6-432.3-119.9c-191.8,1.9-266.4,143.8-518.1,119.9
		c-110-14.1-208.1-75.2-313.8-106.7c-114.1-33.9-231.9,1.6-337.9,58c-64.1,34.2-128.2,61.1-194.1,70.8c-33.6,4.9-67.6,5.4-102.2,0
		c-19.2-3-38.6-7.7-58.2-14.5v14.5v140.3c96.1-50,189.9-116,296.5-117.7c211.5-3.4,324.2,127.8,473,127.9
		C938.8,678,1098,547.7,1272,552.7c111.1,3.2,203.2,65.5,306.2,107.6c120,49,229.6,33.2,348.1-16.2c117.3-49,220.1-112.4,347.2-86.9
		c114.1,22.9,216.1,78.3,326.5,78.3V527.3v-150C2318.2,318.7,2183.7,538,1956.7,505.2z"
        />
        <path
          className="course-color-3"
          d="M599,674.3c106.6,38.8,200.8,113.9,314,123c126.7,10.3,214-46.8,328-101.8c107.1-51.7,228.3-58.9,337.3-12.4
		c104.6,44.5,198.4,108.1,311.4,115c123.9,7.6,227.7-66.6,339.2-122.4c113.9-53.4,264.2-16.8,371.1,44.5v-84.7
		c-110.4,0-212.4-55.4-326.5-78.3c-127.1-25.5-229.9,37.9-347.2,86.9c-118.5,49.4-228.1,65.2-348.1,16.2
		c-103-42.1-195.1-104.4-306.2-107.6c-174-5-333.2,125.3-502.5,125.1c-148.8-0.1-261.5-131.3-473-127.9
		C189.9,551.6,96.1,617.6,0,667.6v95.6C213.5,779.6,379.9,594.4,599,674.3z"
        />
        <path
          className="course-color-2"
          d="M294.9,778.2c117.9,6.8,204,93.5,316.2,121.6c314,78.7,365.9-131.3,658.5-97
		c232.4,27.3,380.8,211.4,676.7,38.2c101.7-59.5,221.2-83.7,332.9-56.8C2390.6,811,2485.4,897,2600,909V720.2
		c-106.9-61.3-257.2-97.9-371.1-44.5c-111.5,55.8-215.3,130-339.2,122.4c-113-6.9-206.8-70.5-311.4-115
		c-109-46.5-230.2-39.3-337.3,12.4c-114,55-201.3,112.1-328,101.8c-113.2-9.1-207.4-84.2-314-123C379.9,594.4,213.5,779.6,0,763.2
		v95.5C95.8,814.9,183.6,771.8,294.9,778.2z"
        />
        <path
          className="course-color-1"
          d="M0,919.3c119.6-34.4,242.7,5.2,352.7,62.2c104.6,54.3,206.4,71.6,319.9,52.4c56.2-9.4,109.7-52.3,163.2-75.6
		c55.7-24.3,113.6-42.8,172.8-49.3c113-12.5,200,33.4,300.5,85c110.9,56.9,232.6,65.3,347.2,21.3
		c107.9-41.5,205.8-109.5,322.2-107.9c117.6,1.6,207.3,63.9,316.2,103.4c98.3,35.8,206.2,44.3,305.3,7.8V909
		c-114.6-12-209.4-98-320.8-124.8c-111.7-26.9-231.2-2.7-332.9,56.8c-295.9,173.2-444.3-10.9-676.7-38.2
		c-292.6-34.3-344.5,175.7-658.5,97C498.9,871.7,412.8,785,294.9,778.2C183.6,771.8,95.8,814.9,0,858.7V1102c0,0,0,0,0,0V919.3z"
        />
        <path
          className="course-color-1-translucent"
          d="M478.8,1158.2c112.8-11,206-79.3,439.8-94.1c147.9-9.4,261.8,25.2,393.4,64.1
		c145.1,42.9,304.4,49.3,454.4,16.1c141.3-31.2,269.4-82.5,421.7-81.3c153.2,1,270.3,47.6,411.9,77.4v-121.8
		c-99.1,36.5-207,28-305.3-7.8c-108.9-39.5-198.6-101.8-316.2-103.4c-116.4-1.6-214.3,66.4-322.2,107.9
		c-114.6,44-236.3,35.6-347.2-21.3c-100.5-51.6-187.5-97.5-300.5-85c-59.2,6.5-117.1,25-172.8,49.3c-53.5,23.3-107,66.2-163.2,75.6
		c-113.5,19.2-215.3,1.9-319.9-52.4c-110-57-233.1-96.6-352.7-62.2V1102C91,1125.7,235.1,1181.9,478.8,1158.2z"
        />
      </g>
    </svg>
  );
};

export default observer(BackgroundWaves);
