import { makeAutoObservable, runInAction } from "mobx";
// eslint-disable-next-line import/no-cycle
import api, { ErrorHandlerPackage } from "../api/api";
import { Login } from "../models/Login";
import { Register } from "../models/Register";
import { User } from "../models/User";
// eslint-disable-next-line import/no-cycle
import Hub from "../api/hub";
import { store } from "./store";
import { StoreValue } from "./storeValue";

export default class UserStore {
  user: User | null = null;

  // Email user used to register, saved so it can be displayed on ConfirmEmailPage.tsx
  registerEmail: string | null = null;

  private userProfileRegistry = new StoreValue<User, { userID: string }>();

  get userProfile() {
    return this.userProfileRegistry.value;
  }

  hasLoadedUserProfile = (userID: string) => this.userProfileRegistry.fresh(false, { userID });

  loading = false;

  private emailVerificationRegistry = new StoreValue<User, { token: string; email: string }>();

  hasAttemptedEmailVerification = (token: string, email: string) =>
    this.emailVerificationRegistry.fresh(false, { token, email });

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.user = null;
  };

  get isLoggedIn() {
    return !!this.user;
  }

  logout = () => {
    // if the Hub is connected to anything, disconnect it.
    Hub.getCurrentInstance()?.disconnect();

    store.commonStore.setToken(null);
    window.localStorage.removeItem("jwt");
    this.user = null;
    store.reset();
  };

  login = async (values: Login, errorHandlerPackage?: ErrorHandlerPackage): Promise<boolean> => {
    try {
      this.loading = true;
      const user = await api.Account.login(values, errorHandlerPackage);
      store.commonStore.setToken(user.token);
      runInAction(() => {
        this.user = user;
        this.loading = false;
      });
      // return success
      return true;
    } catch (error) {
      // let api.ts handle errors
      this.loading = false;
      // return failure
      return false;
    }
  };

  register = async (
    values: Register,
    errorHandlerPackage?: ErrorHandlerPackage
  ): Promise<string | undefined> => {
    try {
      this.loading = true;
      const response = await api.Account.register(values, errorHandlerPackage);
      runInAction(() => {
        this.loading = false;
        this.registerEmail = values.email;
      });
      // return success
      return response;
    } catch (error) {
      // let api.ts handle errors
      this.loading = false;
      // return failure
      return undefined;
    }
  };

  verifyEmail = async (
    token: string,
    email: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ): Promise<User | undefined> => {
    if (this.emailVerificationRegistry.fresh(true, { token, email })) {
      return undefined;
    }

    try {
      this.emailVerificationRegistry.setLoading(true, { token, email });
      const user = await api.Account.verifyEmail(token, email, errorHandlerPackage);

      this.emailVerificationRegistry.setLoading(false);
      return user;
    } catch (error) {
      this.emailVerificationRegistry.setLoading(false);
      return undefined;
    }
  };

  sendResetEmail = async (email: string, errorHandlerPackage?: ErrorHandlerPackage) =>
    api.Account.passwordChangeRequest(email, errorHandlerPackage);

  passwordReset = async (
    email: string,
    token: string,
    password: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => api.Account.passwordChange(email, token, password, errorHandlerPackage);

  getUser = async () => {
    if (this.loading) {
      return;
    }

    try {
      this.loading = true;
      const user = await api.Account.current();
      store.commonStore.setToken(user.token);
      runInAction(() => {
        this.user = user;
        this.loading = false;
      });
    } catch (error) {
      // let api.ts handle errors
      this.loading = false;
    }
  };

  loadUserProfile = async (
    userID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ): Promise<User | undefined> => {
    if (this.userProfileRegistry.isLoading()) {
      return undefined;
    }
    try {
      this.userProfileRegistry.setLoading(true, { userID });
      const response = await api.Account.userProfile(userID, errorHandlerPackage);
      runInAction(() => {
        this.userProfileRegistry.setLoading(false, { userID });
        this.userProfileRegistry.setAll(response, { userID });
      });
      // return success
      return response;
    } catch (error) {
      // let api.ts handle errors
      this.userProfileRegistry.setLoading(false, { userID });
      // return failure
      return undefined;
    }
  };

  updateUserProfile = async (
    userProfile: User,
    errorHandlerPackage?: ErrorHandlerPackage
  ): Promise<boolean> => {
    try {
      this.loading = true;
      const response = await api.Account.updateUserProfile(userProfile, errorHandlerPackage);
      runInAction(() => {
        this.loading = false;
      });
      // return success
      return response;
    } catch (error) {
      // let api.ts handle errors
      this.loading = false;
      // return failure
      return false;
    }
  };
}
