import { Field } from "formik";
import { HTMLInputTypeAttribute, useState } from "react";
import { Header, Icon, SemanticICONS } from "semantic-ui-react";
import { createClassName } from "../../../utilities/utils";
import Tooltip from "../misc/Tooltip";
import "./FormLogoInput.css";

interface FormLogoInputProps {
  label?: string;
  name: string;
  placeholder: string;
  error?: string | undefined | boolean;
  iconName: SemanticICONS;
  required: boolean;
  type: HTMLInputTypeAttribute;
  textarea?: boolean;
  tooltip?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  maxWidth?: string;
}

const FormLogoInput: React.FC<FormLogoInputProps> = ({
  label,
  name,
  placeholder,
  iconName,
  required,
  type,
  error,
  textarea,
  tooltip,
  disabled,
  onBlur,
  onFocus,
  maxWidth,
}) => {
  const [focused, setFocused] = useState<boolean>(false);

  return (
    <div
      className={createClassName(
        "FormLogoInput",
        { name: "textarea", apply: !!textarea },
        { name: "disabled", apply: !!disabled }
      )}
      style={{ maxWidth: maxWidth ?? (textarea ? "unset" : "25rem") }}
    >
      {label && (
        <Header as="h4">
          {label}
          {required && <span className={"required"}> *</span>}
        </Header>
      )}
      <div
        className={createClassName("input-box", { name: "focused", apply: focused })}
        onFocus={() => {
          setFocused(true);
          if (onFocus) onFocus();
        }}
        onBlur={() => {
          setFocused(false);
          if (onBlur) onBlur();
        }}
      >
        <Icon name={iconName} fitted={true} />
        <Field
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          type={type}
          component={textarea ? "textarea" : "input"}
        />
      </div>
      {error && <span className="input-error">{error}</span>}
      {tooltip && (
        <div className="tooltip-container">
          <Tooltip tooltip={tooltip} />
        </div>
      )}
    </div>
  );
};

export default FormLogoInput;
