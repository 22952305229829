import React, { useMemo } from "react";
import { Dropdown, Icon, Label, SemanticICONS } from "semantic-ui-react";
import icons from "../../../utilities/semanticIcons";
import FlexContainer from "../style/FlexContainer";
import "./IconPicker.css";

interface IconPickerProps {
  currentIcon: SemanticICONS | undefined;
  onChange: (newIcon: SemanticICONS) => void;
}

const IconPicker: React.FC<IconPickerProps> = ({ currentIcon, onChange }) => {
  const dropdown = useMemo(
    () => (
      <Dropdown
        placeholder="Select an icon..."
        fluid
        selection
        search
        clearable
        selectOnBlur={false}
        value={currentIcon}
        options={icons.map((icon) => ({
          key: icon,
          text: icon,
          value: icon,
          icon,
        }))}
        onChange={(_, { value }) => onChange(value as SemanticICONS)}
      />
    ),
    [currentIcon]
  );

  return (
    <FlexContainer flexWrap="wrap" gap="1em">
      <Label
        className="blank bold"
        content={
          <>
            Current icon:{"    "}
            <Icon name={currentIcon} size="big" />
          </>
        }
      />
      {dropdown}
    </FlexContainer>
  );
};

export default IconPicker;
