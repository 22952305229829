import { observer } from "mobx-react-lite";
import React from "react";
import { Header, Label, SemanticCOLORS } from "semantic-ui-react";
import { AssessmentSummaryGraphicData } from "../../../utilities/assessmentGraphicUtils";
import FlexContainer from "../style/FlexContainer";
import "./AssessmentGraphicToolTip.css";
import { Objective } from "../../../models/Objective";

interface AugmentedAssessmentSummaryGraphicData extends AssessmentSummaryGraphicData {
  index?: number;
}

interface TooltipProps {
  objectives?: Objective[];
  data: AugmentedAssessmentSummaryGraphicData;
  show: boolean;
}

const AssessmentGraphicToolTip: React.FC<TooltipProps> = ({ data, show, objectives }) => {
  if (!show || !objectives?.length || data.index === undefined) {
    return null;
  }

  const objective = objectives[data.index];
  const description = objective?.longName;

  const descriptionStyle =
    description && description.length >= 125
      ? {
          whiteSpace: "wrap",
          minWidth: "65rem",
        }
      : {};

  return (
    <div className="AssessmentGraphicToolTip">
      <FlexContainer flexDirection="column" alignItems="start" gap=".5rem">
        <Label color={objective?.color as SemanticCOLORS} ribbon={true} className="tool-tip-rating">
          {objective?.shortName}
        </Label>
        <span className="tool-tip-description" style={descriptionStyle}>
          {description}
        </span>
        <Header as="h5" className="rating-header tool-tip-rating-header">
          Rating:
          <Label horizontal className={`mastery-level ${objective?.color as SemanticCOLORS}`}>
            {objective?.masteryLevelScheme?.masteryLevels[data.assessmentScore]?.name}
          </Label>
        </Header>
      </FlexContainer>
    </div>
  );
};

export default observer(AssessmentGraphicToolTip);
