import React from "react";
import "./CardTypeLabel.css";

interface CardTypeLabelProps {
  content: string;
}

const CardTypeLabel: React.FC<CardTypeLabelProps> = ({ content }) => (
  <span className="CardTypeLabel">{content}</span>
);

export default CardTypeLabel;
