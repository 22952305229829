import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button } from "semantic-ui-react";
import useToast from "../../../../../hooks/useToast";
import useValidParams from "../../../../../hooks/useValidParameters";
import { Assignment } from "../../../../../models/Assignment";
import { RatedObjective } from "../../../../../models/RatedObjective";
import { useStore } from "../../../../../stores/store";
import LoadingComponent from "../../../../../utilities/routing/components/LoadingComponent";
import { isSubmissionRequestingReassessment } from "../../../../../utilities/submissionUtils";
import "./AssessmentModal.css";
import CreateOrEditAssessment from "./CreateOrEditAssessment";
import CreateOrEditReassessment from "./CreateOrEditReassessment";

interface AssessmentModalProps {
  assignment: Assignment<RatedObjective>;
  userID: string;
  submissionID?: string;
}

const AssessmentModal: React.FC<AssessmentModalProps> = ({ assignment, userID, submissionID }) => {
  const { userStore, submissionStore, modalStore, gradeStore, courseStore, confettiStore } =
    useStore();
  const { user } = userStore;
  const {
    submissionsForAssignment,
    loadSubmissionsForAssignment,
    hasLoadedSubmissionsForAssignment,
  } = submissionStore;
  const { roster, loadCurrentCourseRoster, hasLoadedRoster } = courseStore;
  const { courseID } = useValidParams<{ courseID: string; userID: string }>();
  const setToast = useToast();

  useEffect(() => {
    loadCurrentCourseRoster(courseID);
  }, [loadCurrentCourseRoster, courseID]);

  useEffect(() => {
    loadSubmissionsForAssignment(assignment.id, userID, courseID);
  }, [assignment, userID, courseID, submissionsForAssignment, loadSubmissionsForAssignment]);

  if (
    !user ||
    !submissionsForAssignment ||
    !hasLoadedSubmissionsForAssignment(userID, assignment.id)
  )
    return <LoadingComponent />;

  if (!roster || !hasLoadedRoster(courseID))
    return <LoadingComponent content="Loading roster..." />;

  const student = roster.find((cm) => cm.userID === userID);

  if (!student) return <>The requested student does not exist.</>;

  const { firstName, lastName } = student;

  const submission = submissionID
    ? submissionsForAssignment.find(({ id }) => id === submissionID)
    : submissionsForAssignment[submissionsForAssignment.length - 1];

  // Open reassessment if the submission has reassessment requests
  if (submission && isSubmissionRequestingReassessment(submission)) {
    return (
      <div className="AssessmentModal">
        <CreateOrEditReassessment
          assignment={assignment}
          user={user}
          submission={submission}
          onCancel={() => modalStore.closeModal()}
          userID={userID}
          courseID={courseID}
          onSubmit={(success) => {
            modalStore.closeModal();
            if (success) {
              setToast(
                `Successfully assessed assignment: ${assignment.name} for ${firstName} ${lastName}.`,
                "green"
              );
              confettiStore.addConfetti();
            } else {
              setToast(
                `No ratings were updated for ${firstName} ${lastName} for assignment: ${assignment.name}.`,
                "orange"
              );
            }
          }}
        />
      </div>
    );
  }

  if (!submission && assignment.dueDate && assignment.dueDate > new Date())
    return (
      <div className="no-submissions-message">
        <div>
          <p>
            {firstName} {lastName} has not submitted this assignment, yet.
          </p>
          <p>Check back after the due date to make your assessment.</p>
        </div>
        <Button content="Okay" color="blue" onClick={() => modalStore.closeModal()} />
      </div>
    );

  if (!submission || !submission.id)
    return (
      <div className="no-submissions-message">
        <div>
          {firstName} {lastName} did not submit this assignment, but, if you want, TeachFront will
          create a submission for you to assess.
        </div>
        <Button
          content="Great, do that."
          color="blue"
          onClick={() =>
            modalStore.openModal(
              <CreateOrEditAssessment
                assignment={assignment}
                courseID={courseID}
                onCancel={() => modalStore.closeModal()}
                onSubmit={(success: boolean) => {
                  submissionStore.reset();
                  gradeStore.resetGradeDistinctions();
                  modalStore.closeModal();
                  if (success) {
                    setToast(
                      `Successfully assessed assignment: ${assignment.name} for ${firstName} ${lastName}.`,
                      "green"
                    );
                    confettiStore.addConfetti();
                  } else {
                    setToast(
                      `No ratings were updated for ${firstName} ${lastName} for assignment: ${assignment.name}.`,
                      "orange"
                    );
                  }
                }}
                studentID={userID}
                isSettingDefaultAssessment
                hideOverrideObjectivesSwitches
                generateSubmissionIfNoneExist
                user={user}
              />,
              { showCloseIcon: true }
            )
          }
        />
      </div>
    );

  return (
    <div className="AssessmentModal">
      <CreateOrEditAssessment
        assignment={assignment}
        user={user}
        submission={submission}
        onCancel={() => modalStore.closeModal()}
        studentID={userID}
        courseID={courseID}
        onSubmit={(success: boolean) => {
          modalStore.closeModal();
          if (success) {
            setToast(
              `Successfully assessed assignment: ${assignment.name} for ${firstName} ${lastName}.`,
              "green"
            );
            confettiStore.addConfetti();
          } else {
            setToast(
              `No ratings were updated for ${firstName} ${lastName} for assignment: ${assignment.name}.`,
              "orange"
            );
          }
        }}
      />
    </div>
  );
};

export default observer(AssessmentModal);
