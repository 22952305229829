import { Form, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Header } from "semantic-ui-react";
import useBooleanState from "../../../../../../hooks/useBooleanState";
import useValidParams from "../../../../../../hooks/useValidParameters";
import { GradeDistinction } from "../../../../../../models/GradeDistinction";
import { useStore } from "../../../../../../stores/store";
import { objectSize } from "../../../../../../utilities/collectionUtils";
import { isFormDirty } from "../../../../../../utilities/formUtils";
import { emptyID } from "../../../../../../utilities/submissionUtils";
import GradeDistinctionCard, {
  GradeDistinctionCardSkeleton,
} from "../../../../../_common/cards/GradeDistinctionCard";
import FormLogoInput from "../../../../../_common/form/FormLogoInput";
import InformationWidget from "../../../../../_common/misc/InformationWidget";
import FlexContainer from "../../../../../_common/style/FlexContainer";
import VerticalGap from "../../../../../_common/style/spacing/VerticalGap";
import "./GradeDistinctionsSettingsForm.css";

type GradeDistinctionSettingsFormValues = {
  gradeDistinctions: GradeDistinction[];
  updatedGradeDistinctionIndex?: number;
  newGradeDistinctionNames: string;
};

const GradeDistinctionSettingsFormSkeleton: React.FC = () => (
  <>
    <Header as="h3" content="Grade Distinctions" />
    <p>
      The grade distinctions for your course are the final "grades" you'll issue at the end of the
      term (e.g. A, B, C, D, E, F or 4.0, 3.9, 3.8, 3.7, etc...)
    </p>
    <Header as="h3" content="Create and Update Grade Distinctions" />
    <VerticalGap height="1rem" />
    <FlexContainer gap="1rem" flexDirection="column">
      <GradeDistinctionCardSkeleton />
      <GradeDistinctionCardSkeleton />
      <GradeDistinctionCardSkeleton />
      <GradeDistinctionCardSkeleton />
      <GradeDistinctionCardSkeleton />
    </FlexContainer>
  </>
);

const GradeDistinctionsSettingsForm: React.FC = () => {
  const { courseID } = useValidParams<{ courseID: string }>();
  const { gradeDistinctionStore, gradeRecipeIngredientStore, toastStore } = useStore();
  const {
    gradeDistinctionsForCourse,
    loadGradeDistinctionsForCourse,
    hasLoadedGradeDistinctionsForCourse,
    createOrUpdateGradeDistinctions,
    deleteGradeDistinctions,
  } = gradeDistinctionStore;
  const [loading, setLoading] = useBooleanState();

  useEffect(() => {
    loadGradeDistinctionsForCourse(courseID);
  }, [courseID]);

  if (!gradeDistinctionsForCourse || !hasLoadedGradeDistinctionsForCourse())
    return <GradeDistinctionSettingsFormSkeleton />;

  const setRelativeOrderIndexOfGradeDistinctions = (gradeDistinctions: GradeDistinction[]) => {
    gradeDistinctions.forEach((gd, index) => {
      const gradeDistinction = gd;
      gradeDistinction.relativeOrderIndex = index;
    });
  };

  const handleMoveGradeDistinctionUp = (
    gradeDistinction: GradeDistinction,
    allGradeDistinctions: GradeDistinction[],
    setFieldValue: (field: string, value: unknown) => void
  ) => {
    const previousGradeDistinction = allGradeDistinctions[gradeDistinction.relativeOrderIndex - 1];
    const currentGradeDistinction = gradeDistinction;

    if (!previousGradeDistinction) return;

    previousGradeDistinction.relativeOrderIndex += 1;
    currentGradeDistinction.relativeOrderIndex -= 1;

    const newGradeDistinctions = [...allGradeDistinctions];

    newGradeDistinctions[previousGradeDistinction.relativeOrderIndex] = previousGradeDistinction;
    newGradeDistinctions[currentGradeDistinction.relativeOrderIndex] = currentGradeDistinction;

    setFieldValue("gradeDistinctions", newGradeDistinctions);
    setFieldValue("updatedGradeDistinctionIndex", currentGradeDistinction.relativeOrderIndex);
  };

  const handleMoveGradeDistinctionDown = (
    gradeDistinction: GradeDistinction,
    allGradeDistinctions: GradeDistinction[],
    setFieldValue: (field: string, value: unknown) => void
  ) => {
    const previousGradeDistinction = allGradeDistinctions[gradeDistinction.relativeOrderIndex + 1];
    const currentGradeDistinction = gradeDistinction;

    if (!previousGradeDistinction) return;

    previousGradeDistinction.relativeOrderIndex -= 1;
    currentGradeDistinction.relativeOrderIndex += 1;

    const newGradeDistinctions = [...allGradeDistinctions];

    newGradeDistinctions[previousGradeDistinction.relativeOrderIndex] = previousGradeDistinction;
    newGradeDistinctions[currentGradeDistinction.relativeOrderIndex] = currentGradeDistinction;

    setFieldValue("gradeDistinctions", newGradeDistinctions);
    setFieldValue("updatedGradeDistinctionIndex", currentGradeDistinction.relativeOrderIndex);
  };

  const handleGradeDistinctionNameChanged = (
    gradeDistinction: GradeDistinction,
    newName: string,
    allGradeDistinctions: GradeDistinction[],
    setFieldValue: (field: string, value: unknown) => void
  ) => {
    const newGradeDistinctions = [...allGradeDistinctions];

    newGradeDistinctions[gradeDistinction.relativeOrderIndex] = {
      ...gradeDistinction,
      name: newName,
    };

    setFieldValue("gradeDistinctions", newGradeDistinctions);
  };

  const handleGradeDistinctionDeleted = (
    gradeDistinction: GradeDistinction,
    allGradeDistinctions: GradeDistinction[],
    setFieldValue: (field: string, value: unknown) => void
  ) => {
    const newGradeDistinctions = [...allGradeDistinctions];

    newGradeDistinctions.splice(gradeDistinction.relativeOrderIndex, 1);

    setRelativeOrderIndexOfGradeDistinctions(newGradeDistinctions);

    setFieldValue("gradeDistinctions", newGradeDistinctions);
  };

  const parseNewGradeDistinctionNames = (names: string): string[] =>
    names
      .split(",")
      .map((s) => s.trim())
      .filter((name) => name.length !== 0 && name !== " ");

  const handleNewGradeDistinctionButtonClicked = (
    values: GradeDistinctionSettingsFormValues,
    setValues: (values: React.SetStateAction<GradeDistinctionSettingsFormValues>) => void
  ) => {
    const parsedGradeDistinctionNames = parseNewGradeDistinctionNames(
      values.newGradeDistinctionNames
    );

    const newGradeDistinctions: GradeDistinction[] = parsedGradeDistinctionNames.map(
      (name, index) => ({
        courseID,
        id: emptyID,
        name,
        relativeOrderIndex: values.gradeDistinctions.length + index,
      })
    );

    setValues({
      gradeDistinctions: [...values.gradeDistinctions, ...newGradeDistinctions],
      newGradeDistinctionNames: "",
    });
  };

  const initialValues: GradeDistinctionSettingsFormValues = {
    gradeDistinctions: gradeDistinctionsForCourse?.map((d) => ({ ...d })),
    newGradeDistinctionNames: "",
  };

  const handleSubmit = async (values: GradeDistinctionSettingsFormValues) => {
    const gradeDistinctionsToSave = values.gradeDistinctions;
    // Creates a list of all existing grade distinctions for the course that are not in the grade distinctions to save
    const gradeDistinctionsToDelete = gradeDistinctionsForCourse.filter((gdc) =>
      values.gradeDistinctions.every((gd) => gd.id !== gdc.id)
    );

    setRelativeOrderIndexOfGradeDistinctions(gradeDistinctionsToSave);

    setLoading(true);

    const databaseCalls = [];

    if (gradeDistinctionsToSave.length > 0)
      databaseCalls.push(createOrUpdateGradeDistinctions(courseID, gradeDistinctionsToSave));
    if (gradeDistinctionsToDelete.length > 0)
      databaseCalls.push(deleteGradeDistinctions(courseID, gradeDistinctionsToDelete));

    if (databaseCalls.length > 0) {
      await Promise.allSettled(databaseCalls);
    }

    toastStore.showToast("Successfully saved grade distinctions");

    gradeRecipeIngredientStore.reset();

    setLoading(false);
  };

  const getAddNewGradeDistinctionButtonContent = (newGradeDistinctionNames: string) => {
    const parsedNames = parseNewGradeDistinctionNames(newGradeDistinctionNames);

    if (parsedNames.length > 0) {
      return `Add (${parsedNames.join(", ")}) as new grade distinction${
        parsedNames.length !== 1 ? "s" : ""
      }`;
    }

    return `Add`;
  };

  const validate = (values: GradeDistinctionSettingsFormValues) => {
    const errors: FormikErrors<GradeDistinctionSettingsFormValues> = {};

    const areAnyGradeDistinctionNamesEmpty = values.gradeDistinctions.some((gd) => !gd.name.trim());

    if (areAnyGradeDistinctionNamesEmpty) {
      errors.gradeDistinctions = "No grade distinctions can have empty names.";
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, setValues, errors }) => (
        <Form className="GradeDistinctionSettingsForm">
          <Header as="h3" content="Grade Distinctions" />
          <p>
            The grade distinctions for your course are the final "grades" you'll issue at the end of
            the term (e.g. A, B, C, D, E, F or 4.0, 3.9, 3.8, 3.7, etc...)
          </p>
          <Header as="h3" content="Create and Update Grade Distinctions" />
          <Header as="h4" content="Specify your grade distinctions, separated by commas:" />
          <FlexContainer gap="0.5rem" flexWrap="wrap">
            <FormLogoInput
              iconName="list"
              name="newGradeDistinctionNames"
              placeholder="(e.g. A, B, C, D, E, F)"
              required={false}
              type="text"
              maxWidth="fit-content"
            />
            <Button
              type="button"
              color="blue"
              icon="plus"
              content={getAddNewGradeDistinctionButtonContent(values.newGradeDistinctionNames)}
              disabled={!values.newGradeDistinctionNames}
              onClick={() => handleNewGradeDistinctionButtonClicked(values, setValues)}
            />
          </FlexContainer>

          {isFormDirty(initialValues, values, [
            "newGradeDistinctionNames",
            "updatedGradeDistinctionIndex",
          ]) &&
            objectSize(errors) === 0 && (
              <>
                <VerticalGap height="1rem" />
                <InformationWidget type="warning" message="You have unsaved changes!" />
              </>
            )}
          <VerticalGap height="1rem" />
          <FlexContainer flexDirection="column" gap="1rem">
            {values.gradeDistinctions.map((gradeDistinction) => (
              <GradeDistinctionCard
                key={gradeDistinction.relativeOrderIndex}
                showAnimation={
                  gradeDistinction.relativeOrderIndex === values.updatedGradeDistinctionIndex
                }
                gradeDistinction={gradeDistinction}
                totalNumGradeDistinctions={values.gradeDistinctions.length}
                moveGradeDistinctionDown={() =>
                  handleMoveGradeDistinctionDown(
                    gradeDistinction,
                    values.gradeDistinctions,
                    setFieldValue
                  )
                }
                moveGradeDistinctionUp={() =>
                  handleMoveGradeDistinctionUp(
                    gradeDistinction,
                    values.gradeDistinctions,
                    setFieldValue
                  )
                }
                onDelete={() =>
                  handleGradeDistinctionDeleted(
                    gradeDistinction,
                    values.gradeDistinctions,
                    setFieldValue
                  )
                }
                onNameChange={(newName: string) =>
                  handleGradeDistinctionNameChanged(
                    gradeDistinction,
                    newName,
                    values.gradeDistinctions,
                    setFieldValue
                  )
                }
              />
            ))}
          </FlexContainer>
          <VerticalGap height="1rem" />
          <Button
            type="submit"
            content="Save Changes"
            icon="checkmark"
            color="blue"
            loading={loading}
            disabled={
              !isFormDirty(initialValues, values, [
                "newGradeDistinctionNames",
                "updatedGradeDistinctionIndex",
              ]) || objectSize(errors) > 0
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default observer(GradeDistinctionsSettingsForm);
