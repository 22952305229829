import "./Or.css";

const Or: React.FC = () => (
  <div className={"Or"}>
    <div className={"divider"} />
    <div>or</div>
    <div className={"divider"} />
  </div>
);

export default Or;
