import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Accordion, Icon, Label, Placeholder, PlaceholderLine } from "semantic-ui-react";
import { useStore } from "../../../../../../stores/store";
import { formatDateDownToDayNoYear } from "../../../../../../utilities/dateTimeUtils";
import UserInputDisplay from "../../../../../_common/textEditing/UserInputDisplay";
import "./StudentLearningLog.css";

interface StudentLearningLogProps {
  courseID: string;
  userID: string;
}

const StudentLearningLogSkeleton: React.FC<{ color: string }> = (props) => (
  <div className="LearningLogSkeleton">
    <Label ribbon size="medium" className={props.color}>
      <>Learning Log:</>
    </Label>
    <div className="Placeholder">
      <Placeholder>
        <PlaceholderLine />
      </Placeholder>
    </div>
  </div>
);

const StudentLearningLog: React.FC<StudentLearningLogProps> = ({ courseID, userID }) => {
  const { courseStore, pollingStore } = useStore();
  const { course } = courseStore;

  const [activeIndex, setActiveIndex] = useState<boolean | null>(true);
  const handleAccordionClick = () => {
    setActiveIndex(!activeIndex);
  };

  const { learningLog, hasLoadedLearningLog, loadLearningLog } = pollingStore;
  useEffect(() => {
    loadLearningLog(courseID, userID);
  }, [courseID, userID]);

  // Skeleton Loading Component
  if (!learningLog || !hasLoadedLearningLog(courseID, userID)) {
    return <StudentLearningLogSkeleton color={course?.color || "grey"} />;
  }

  if (learningLog.length === 0) {
    return <></>;
  }

  // Actual Component
  return (
    <div className="StudentLearningLog">
      <Accordion styled>
        <Accordion.Title active={activeIndex !== false} onClick={() => handleAccordionClick()}>
          <Label ribbon size="medium" className={course?.color}>
            <Icon name="dropdown" />
            <>Learning Log:</>
          </Label>
        </Accordion.Title>
        <Accordion.Content active={activeIndex !== false}>
          {learningLog.map((response) => (
            <div className="Responses" key={response.id}>
              {formatDateDownToDayNoYear(response.responseTime)}:{" "}
              <UserInputDisplay content={response.text} />
            </div>
          ))}
        </Accordion.Content>
      </Accordion>
    </div>
  );
};
export default observer(StudentLearningLog);
