import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, DropdownItemProps, Icon } from "semantic-ui-react";
import { User } from "../../../models/User";
import { useStore } from "../../../stores/store";
import UploadProfilePhotoModal from "../../_modal/UploadProfilePhotoModal";
import "./ProfilePhoto.css";
import UserIcon from "../../../assets/UserIcon";
import UserIconGrey from "../../../assets/UesrIconGrey";

interface ProfilePhotoProps {
  user: User;
  enableEditing?: boolean;
  size?: string;
  color?: "white" | "grey";
  preventNavigation?: boolean;
}

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({
  user,
  enableEditing,
  size,
  color,
  preventNavigation = false,
}) => {
  const { modalStore, uploadedFileStore, userStore } = useStore();
  const navigate = useNavigate();
  const [imageLoadingState, setImageLoadingState] = useState<"dormant" | "loading" | "loaded">(
    "dormant"
  );

  const { profilePhotoBlobLink } = user;

  useEffect(() => {
    setImageLoadingState("dormant");

    if (profilePhotoBlobLink) {
      setImageLoadingState("loading");
      const image = new Image();
      image.src = profilePhotoBlobLink;

      image.onload = () => {
        setImageLoadingState("loaded");
      };

      return () => {
        // Cleanup: remove event listener if component unmounts before the image is loaded
        image.onload = null;
      };
    }

    return undefined;
  }, [profilePhotoBlobLink]);

  function openUploadModal() {
    modalStore.openModal(<UploadProfilePhotoModal userID={user.userID} />, { showCloseIcon: true });
  }

  const getDropdownOptions = () => {
    const options: DropdownItemProps[] = [
      {
        key: "upload",
        text: "Upload Photo",
        value: "Upload Photo",
        onClick: () => {
          openUploadModal();
        },
      },
    ];

    if (user.profilePhotoBlobLink) {
      options.push({
        key: "remove",
        text: "Remove Current Photo",
        value: "Remove Current Photo",
        onClick: async () => {
          // eslint-disable-next-line no-alert
          const confirm = await modalStore.openConfirmationModal(
            "Are you sure you want to remove your profile photo?"
          );
          if (confirm) {
            await uploadedFileStore.deleteProfilePhoto(user.userID);
            userStore.loadUserProfile(user.userID);
          }
        },
      });
    }

    return options;
  };

  if (!profilePhotoBlobLink || imageLoadingState === "loading") {
    return (
      <div
        className="ProfilePhoto default"
        style={{
          minWidth: size ?? "100%",
          minHeight: size ?? "100%",
          maxWidth: size ?? "100%",
          maxHeight: size ?? "100%",
          cursor: !enableEditing && !preventNavigation ? "pointer" : "auto",
        }}
        onClick={(e) => {
          if (!enableEditing && !preventNavigation) {
            e.stopPropagation();
            modalStore.closeModal();
            navigate(`/profile/${user.userID}`);
          }
        }}
      >
        {color === "white" ? <UserIcon /> : <UserIconGrey />}
        {enableEditing && (
          <div className="edit-button" onClick={openUploadModal}>
            <div role="button">
              <Icon name="camera" size="large" fitted />
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className="ProfilePhoto"
      style={{
        backgroundImage: `url(${profilePhotoBlobLink})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minWidth: size ?? "100%",
        minHeight: size ?? "100%",
        maxWidth: size ?? "100%",
        maxHeight: size ?? "100%",
        cursor: !enableEditing && !preventNavigation ? "pointer" : "auto",
      }}
      onClick={(e) => {
        if (!enableEditing && !preventNavigation) {
          e.stopPropagation();
          modalStore.closeModal();
          navigate(`/profile/${user.userID}`);
        }
      }}
    >
      {enableEditing && (
        <Dropdown
          className="edit-button"
          icon={null}
          defaultValue="0"
          trigger={
            <div role="button">
              <Icon name="camera" size="large" fitted />
            </div>
          }
          options={getDropdownOptions()}
        />
      )}
    </div>
  );
};

export default ProfilePhoto;
