const UserIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    stroke="black"
    stroke-width="30"
    fill="none"
    viewBox="0 0 600 600"
  >
    <title>Default User Icon</title>

    <g>
      <title>User Icon</title>
      <g stroke="null" id="svg_4">
        <circle stroke="#ffffff" fill="none" cx="300" cy="300.24998" r="284" id="svg_1" />
        <circle stroke="#ffffff" fill="none" cx="300" cy="225.23112" r="123.24529" id="svg_2" />
        <path
          stroke="#ffffff"
          fill="none"
          d="m92.9679,494.65601a219.6981,219.6981 1 0 1 414.0642,0"
          id="svg_3"
        />
      </g>
    </g>
  </svg>
);

export default UserIcon;
