import { Icon, Loader, SemanticICONS } from "semantic-ui-react";
import { ChangeEvent, DragEvent, useState } from "react";
import "./FormFileInput.css";
import FlexContainer from "../style/FlexContainer";
import { createClassName } from "../../../utilities/utils";

interface FormFileInputProps {
  onFileUploaded: (file: File) => void;
  className?: string;
  // ClassName that is applied when a user drags a file over the input
  dragActiveClassName?: string;
  loading?: boolean;
  loaderMessage?: string;
  icon?: SemanticICONS;
  displayMessage?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

const FormFileInput: React.FC<FormFileInputProps> = ({
  onFileUploaded,
  className,
  dragActiveClassName,
  loading,
  loaderMessage = "Uploading File...",
  icon = "upload",
  displayMessage = "Upload File",
  inputProps,
}) => {
  const [dragActive, setDragActive] = useState<boolean>(false);

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: ChangeEvent | DragEvent, droppedFiles: FileList | null) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (droppedFiles && droppedFiles[0]) {
      const file = droppedFiles[0] as File;

      onFileUploaded(file);
    }
  };

  return (
    <FlexContainer
      className={createClassName("FormFileInput", className, {
        name: dragActiveClassName ?? "",
        apply: dragActive,
      })}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="0.5rem"
    >
      <input
        {...inputProps}
        type="file"
        multiple={true}
        onChange={(e) => {
          handleDrop(e, e.target.files);
          e.target.value = "";
        }}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={(e) => handleDrop(e, e.dataTransfer.files)}
      />
      {!loading && (
        <>
          <Icon name={icon} />
          {displayMessage}
        </>
      )}
      {loading && <Loader active={true} size="small" inline={true} content={loaderMessage} />}
    </FlexContainer>
  );
};

export default FormFileInput;
