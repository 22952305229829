import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { ChangeEvent, DragEvent, useEffect, useState } from "react";
import { Icon, Loader } from "semantic-ui-react";
import { UploadedFile } from "../../../models/UploadedFile";
import { useStore } from "../../../stores/store";
import { UploadedFileCategory } from "../../../stores/uploadedFileStore";
import { createClassName, isValidID } from "../../../utilities/utils";
import "./DragAndDropFile.css";

interface DragAndDropFileProps {
  uploadFileCategory: UploadedFileCategory;
  userID: string;
  courseID: string;
  connectedComponentID: string;
  directoryID?: string;
  directoryName?: string;
  showUploadComponentByDefault?: boolean;
  showFilesComponent?: boolean;
}

const DragAndDropFile: React.FC<DragAndDropFileProps> = ({
  uploadFileCategory,
  courseID,
  userID,
  connectedComponentID,
  directoryID,
  directoryName,
  showUploadComponentByDefault = false,
  showFilesComponent = true,
}) => {
  const { uploadedFileStore, uploadedCourseFileStore } = useStore();

  const { uploadCourseFile, deleteCourseFile, loadUploadedFilesForCourse } =
    uploadedCourseFileStore;
  const {
    uploadFile,
    deleteFile,
    uploadedFilesForSubmission,
    uploadedFilesForTeachingTeamUploadedSubmission,
    uploadedFilesForAssignment,
    uploadedFilesForPage,
    hasLoadedUploadedFiles,
    loadUploadedFilesForSubmission,
    loadUploadedFilesForTeachingTeamUploadedSubmission,
    loadUploadedFilesForAssignment,
    loadUploadedFilesForPage,
    downloadAssignmentFile,
    downloadSubmissionFile,
    downloadTeachingTeamUploadedSubmissionFile,
    downloadPageFile,
  } = uploadedFileStore;
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [showUploadComponent, setShowUploadComponent] = useState<boolean>(
    showUploadComponentByDefault ?? false
  );

  let uploadedFiles: UploadedFile[] | undefined;
  switch (uploadFileCategory) {
    case "submission":
      uploadedFiles = uploadedFilesForSubmission;
      break;
    case "teachingTeamUploadedSubmission":
      uploadedFiles = uploadedFilesForTeachingTeamUploadedSubmission;
      break;
    case "page":
      uploadedFiles = uploadedFilesForPage;
      break;
    default:
      uploadedFiles = uploadedFilesForAssignment;
      break;
  }

  useEffect(() => {
    if (
      connectedComponentID &&
      hasLoadedUploadedFiles(connectedComponentID, uploadFileCategory) &&
      uploadedFiles &&
      uploadedFiles.length > 0
    )
      setShowUploadComponent(true);
  }, [
    uploadedFiles,
    connectedComponentID,
    userID,
    hasLoadedUploadedFiles,
    setShowUploadComponent,
    uploadFileCategory,
  ]);

  useEffect(() => {
    if (connectedComponentID && uploadFileCategory === "submission") {
      loadUploadedFilesForSubmission(connectedComponentID, courseID, userID);
    } else if (connectedComponentID && uploadFileCategory === "teachingTeamUploadedSubmission") {
      loadUploadedFilesForTeachingTeamUploadedSubmission(connectedComponentID, courseID);
    } else if (connectedComponentID && uploadFileCategory === "assignment") {
      loadUploadedFilesForAssignment(connectedComponentID, courseID);
    } else if (connectedComponentID && uploadFileCategory === "page") {
      loadUploadedFilesForPage(connectedComponentID, courseID);
    }
  }, [
    courseID,
    userID,
    connectedComponentID,
    uploadFileCategory,
    loadUploadedFilesForSubmission,
    loadUploadedFilesForAssignment,
    loadUploadedFilesForPage,
    loadUploadedFilesForCourse,
  ]);

  if (!connectedComponentID || !showUploadComponent) {
    return (
      <div
        role={"button"}
        onClick={() => {
          setShowUploadComponent(true);
        }}
        className={"ui default-button attach-files"}
      >
        <Icon name={"attach"} fitted /> {"Attach Files"}
      </div>
    );
  }

  const uploadNewFile = async (file: File) => {
    runInAction(async () => {
      const uploadedFile: UploadedFile = {
        connectedComponentID,
        userID,
        courseID,
        directoryID,
        uploadedFile: file,
        contentType: "",
        createdAt: new Date(Date.now()),
        originalFileName: file.name,
      };

      if (uploadFileCategory !== "course") await uploadFile(uploadedFile, uploadFileCategory);
      else await uploadCourseFile(uploadedFile);
    });
  };

  const deleteCurrentFile = async (file: UploadedFile) => {
    runInAction(async () => {
      if (!file.id) return;

      if (uploadFileCategory !== "course")
        await deleteFile(userID, courseID, file.id, connectedComponentID, uploadFileCategory);
      else await deleteCourseFile(file.id, courseID);
    });
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: ChangeEvent | DragEvent, droppedFiles: FileList | null) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (droppedFiles && droppedFiles[0]) {
      const newFiles: File[] = [];

      for (let i = 0; i < droppedFiles.length; i += 1) newFiles.push(droppedFiles[i] as File);

      newFiles.forEach((f) => uploadNewFile(f));
    }
  };

  return (
    <div className="DragAndDropFile">
      {showFilesComponent &&
        uploadFileCategory !== "course" &&
        hasLoadedUploadedFiles(connectedComponentID, uploadFileCategory) &&
        uploadedFiles &&
        uploadedFiles.length > 0 &&
        uploadedFiles.map((file, index) => (
          <div
            key={file.id ?? index}
            className="existing-file"
            role="button"
            onClick={() => {
              if (uploadFileCategory === "submission") {
                downloadSubmissionFile(file);
              } else if (uploadFileCategory === "teachingTeamUploadedSubmission") {
                downloadTeachingTeamUploadedSubmissionFile(file);
              } else if (uploadFileCategory === "assignment") {
                downloadAssignmentFile(file);
              } else if (uploadFileCategory === "page") {
                downloadPageFile(file);
              }
            }}
          >
            <Icon name="file outline" />
            {file.originalFileName}
            {isValidID(file.id) && (
              <div className="icons">
                <Icon
                  name="trash"
                  onClick={(e: React.MouseEvent) => {
                    deleteCurrentFile(file);
                    e.stopPropagation();
                  }}
                />
                <Icon name="checkmark" />
              </div>
            )}
            {!isValidID(file.id) && <Loader active={true} size="small" inline />}
          </div>
        ))}
      <div className={createClassName("drop", { name: "drag-over", apply: dragActive })}>
        {dragActive ? <Icon name="plus" size="large" /> : <Icon name="upload" size="large" />}
        <input
          type="file"
          multiple={true}
          onChange={(e) => {
            handleDrop(e, e.target.files);
            e.target.value = "";
          }}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={(e) => handleDrop(e, e.dataTransfer.files)}
        />
        <label>
          {directoryName && (
            <>
              To Upload into {directoryName},<br />
            </>
          )}{" "}
          Drag and Drop or Select File
        </label>
      </div>
    </div>
  );
};

export default observer(DragAndDropFile);
