import FlexContainer from "../style/FlexContainer";
import "./TeamFrontCard.css";

interface TeamFrontCardProps {
  className: string;
  name: string;
  role: string;
  photo: string;
}

const TeamFrontCard: React.FC<TeamFrontCardProps> = ({ className, name, role, photo }) => (
  <FlexContainer
    className={`TeamFrontCard ${className}`}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    <div
      className="photo"
      style={{
        backgroundImage: `url(${photo})`,
      }}
    ></div>
    <div className="name">{name}</div>
    <div className="role">{role}</div>
  </FlexContainer>
);

export default TeamFrontCard;
