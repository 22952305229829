import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";
import { Header } from "semantic-ui-react";
import Sidebar from "../../../_common/sidebar/Sidebar";
import { useStore } from "../../../../stores/store";
import FlexContainer from "../../../_common/style/FlexContainer";
import useWindowSize from "../../../../hooks/useWindowSize";
import "./RosterView.css";
import useCurrentUserCourseRole from "../../../../hooks/useCurrentUserCourseRole";
import { User } from "../../../../models/User";
import { GroupSet } from "../../../../models/GroupSet";
import { SidebarRow } from "../../../../models/sidebar";
import { CourseRoleGroup } from "../../../../utilities/routing/components/routeAuthorizationExtensions/CourseRoles";
import { doesRosterHaveCourseRole } from "../../../../utilities/routing/components/routeAuthorizationExtensions/RequireCourseRosterAndGroupSets";

const courseRosterSidebarData = (
  isUserTeachingTeam: boolean,
  roster: User[],
  // TODO remove line below when group work is finished
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  groupSets: GroupSet[]
): SidebarRow[] => {
  const courseMembersItem: SidebarRow = {
    icon: "user",
    header: "Course Members",
    subItems: [
      {
        icon: "angle right",
        header: "All",
        route: "CourseHomeRosterTab",
      },
    ],
  };

  if (doesRosterHaveCourseRole(roster, CourseRoleGroup.STUDENTS)) {
    courseMembersItem.subItems?.push({
      icon: "angle right",
      header: "Students",
      route: "CourseHomeRosterTabStudents",
    });
  }

  if (doesRosterHaveCourseRole(roster, CourseRoleGroup.TEACHING_TEAM)) {
    courseMembersItem.subItems?.push({
      icon: "angle right",
      header: "Teaching Team",
      route: "CourseHomeRosterTabTeachingTeam",
    });
  }

  if (doesRosterHaveCourseRole(roster, CourseRoleGroup.INSTRUCTORS)) {
    courseMembersItem.subItems?.push({
      icon: "angle right",
      header: "Instructors",
      route: "CourseHomeRosterTabInstructors",
    });
  }

  if (doesRosterHaveCourseRole(roster, CourseRoleGroup.TEACHING_ASSISTANTS)) {
    courseMembersItem.subItems?.push({
      icon: "angle right",
      header: "Teaching Assistants",
      route: "CourseHomeRosterTabTeachingAssistants",
    });
  }

  // TODO uncomment when group work is finished
  // const groupSetsItem: SidebarRow = {
  //   icon: "group",
  //   header: "Groups",
  //   subItems: [],
  //   hiddenSubItems: [
  //     {
  //       route: "CourseHomeRosterTabGroupSetNotFound",
  //     },
  //   ],
  // };

  // if (isUserTeachingTeam) {
  //   groupSetsItem.subItems?.push({
  //     icon: "plus",
  //     header: "Add Group Set",
  //     route: "CourseHomeRosterTabNewGroupSet",
  //   });
  // }

  // groupSets.forEach((gs) => {
  //   groupSetsItem.subItems?.push({
  //     icon: "angle right",
  //     header: gs.name,
  //     route: "CourseHomeRosterTabGroupSet",
  //     params: {
  //       groupSetID: gs.id,
  //     },
  //   });
  // });

  const courseRosterSidebarItems = [courseMembersItem];

  // if (groupSetsItem.subItems && groupSetsItem.subItems.length > 0) {
  //   courseRosterSidebarItems.push(groupSetsItem);
  // }

  return courseRosterSidebarItems;
};

interface RosterViewProps {
  courseID: string;
}

const RosterView: React.FC<PropsWithChildren<RosterViewProps>> = ({ courseID, children }) => {
  const { width } = useWindowSize([1000]);
  const { courseStore, groupSetsStore, userStore } = useStore();
  const { roster } = courseStore;
  const { groupSets } = groupSetsStore;
  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);

  // These conditions should never be true due to router protection
  if (!roster || !groupSets || !userStore) {
    return <></>;
  }

  return (
    <div className="RosterView">
      <Header as="h2">Course Roster</Header>
      <FlexContainer
        className="roster-view-content"
        flexDirection={width < 1000 ? "column" : "row"}
        gap="1rem"
        alignItems="stretch"
      >
        <Sidebar sidebarData={courseRosterSidebarData(isUserTeachingTeam, roster, groupSets)} />
        <div className="children-container">{children}</div>
      </FlexContainer>
    </div>
  );
};

export default observer(RosterView);
