import { makeAutoObservable } from "mobx";
import { AssessmentTask } from "../models/AssessmentTask";
import { getLocalDate } from "../utilities/dateTimeUtils";
// eslint-disable-next-line import/no-cycle
import { store } from "./store";
import { StoreValue } from "./storeValue";
// eslint-disable-next-line import/no-cycle
import CourseRoles from "../utilities/routing/components/routeAuthorizationExtensions/CourseRoles";

export default class AssessmentTaskStore {
  constructor() {
    makeAutoObservable(this);
  }

  // assessment tasks
  private assessmentTasksRegistry: StoreValue<Map<string, AssessmentTask[]>> = new StoreValue();

  get assessmentTasks() {
    return this.assessmentTasksRegistry.value;
  }

  setAssessmentTasksLoading() {
    this.assessmentTasksRegistry.setLoading(true);
  }

  // count of assessment tasks
  private assessmentTasksCountTotal: StoreValue<number> = new StoreValue();

  get assessmentTasksCount() {
    return this.assessmentTasksCountTotal.value;
  }

  // assessment tasks assigned to user
  private assessmentTasksAssignedToUserRegistry: StoreValue<Map<string, AssessmentTask[]>> =
    new StoreValue();

  get assessmentTasksAssignedToUser() {
    return this.assessmentTasksAssignedToUserRegistry.value;
  }

  // count of assessment tasks assigned to user
  private assessmentTasksAssignedToUserCountTotal: StoreValue<number> = new StoreValue();

  get assessmentTasksAssignedToUserCount() {
    return this.assessmentTasksAssignedToUserCountTotal.value;
  }

  loadAssessmentTasks = async (
    existingTasksMap: Map<string, AssessmentTask[]>,
    existingTasksAssignedToUserMap: Map<string, AssessmentTask[]>,
    updatedTasks: AssessmentTask[],
    userID: string
  ) => {
    // loop through each updated task
    updatedTasks.forEach((t: AssessmentTask) => {
      // set dates
      this.setTaskDates(t);

      // update the task in the set of all tasks
      this.updateSingleAssessmentTask(existingTasksMap, t, "");

      // if the task is assigned to user, update the task in the list of user's tasks
      this.updateSingleAssessmentTask(existingTasksAssignedToUserMap, t, userID);
    });

    // make sure the tasks maps are ordered properly, and that assignments with no tasks are removed
    const tasksCount = this.polishTasksListAndReturnTasksCount(existingTasksMap);
    const tasksAssignedToUserCount = this.polishTasksListAndReturnTasksCount(
      existingTasksAssignedToUserMap
    );

    // update the observables
    this.assessmentTasksRegistry.setValue(existingTasksMap);
    this.assessmentTasksAssignedToUserRegistry.setValue(existingTasksAssignedToUserMap);
    this.assessmentTasksCountTotal.setValue(tasksCount);
    this.assessmentTasksAssignedToUserCountTotal.setValue(tasksAssignedToUserCount);

    this.assessmentTasksRegistry.setLoading(false);
  };

  private updateSingleAssessmentTask = async (
    tasksMap: Map<string, AssessmentTask[]>,
    updatedTask: AssessmentTask,
    userID: string
  ) => {
    const role = store.courseStore.courseMemberRoleForCurrentUser;

    // a helper method to determine whether two tasks reference the same thing
    const isSameTask = (task1: AssessmentTask, task2: AssessmentTask) =>
      task1.assignmentID === task2.assignmentID && task1.studentID === task2.studentID;

    // we only care about the tasks for our updatedTask's assignment
    let tasksForAssignment = tasksMap.get(updatedTask.assignmentID);

    // if the task is incomplete and the assignment doesn't exist yet in assessment tasks, create the assignment
    if (!updatedTask.isCompleted && !tasksForAssignment) {
      tasksMap.set(updatedTask.assignmentID, []);
      tasksForAssignment = tasksMap.get(updatedTask.assignmentID);
    }

    // if the assignment exists in assessment tasks
    if (tasksForAssignment) {
      const existingTaskIndex = tasksForAssignment.findIndex((t) => isSameTask(updatedTask, t));

      // if the task is complete and it exists in the assignment, delete it.
      if (updatedTask.isCompleted && existingTaskIndex >= 0) {
        tasksForAssignment.splice(existingTaskIndex, 1); // only delete one element
      }

      // if the task used to be assigned to you, but it isn't anymore, delete it
      else if (
        !updatedTask.isCompleted &&
        existingTaskIndex >= 0 &&
        userID &&
        updatedTask.assignedRaterIDs.includes(userID)
      ) {
        tasksForAssignment.splice(existingTaskIndex, 1); // only delete one element
      }

      // if the task is incomplete and it exists in the assignment, update it
      else if (
        !updatedTask.isCompleted &&
        existingTaskIndex >= 0 &&
        (!userID || updatedTask.assignedRaterIDs.includes(userID))
      ) {
        tasksForAssignment[existingTaskIndex] = updatedTask;
      }

      // if the task is incomplete and doesn't exist in the assignment, add it
      else if (
        !updatedTask.isCompleted &&
        existingTaskIndex < 0 &&
        (!userID ||
          updatedTask.assignedRaterIDs.includes(userID) ||
          (updatedTask.countReassessmentRequests > 1 &&
            role &&
            CourseRoles.ResubmissionManagerRoles.includes(role)))
      ) {
        tasksForAssignment.push(updatedTask);
      }
    }
  };

  private polishTasksListAndReturnTasksCount = (tasksMap: Map<string, AssessmentTask[]>) => {
    let tasksCount = 0;

    tasksMap.forEach((tasks, assignment) => {
      // it doesn't have any tasks, delete it
      if (tasks?.length === 0) {
        tasksMap.delete(assignment);
      }

      // otherwise, count the tasks and ensure that they are in chronological order
      else if (tasks) {
        tasksCount += tasks.length;
        tasks.sort((t1, t2) => t2.submissionCreatedAt.getTime() - t1.submissionCreatedAt.getTime());
      }
    });

    return tasksCount;
  };

  setTaskDates = (t: AssessmentTask) => {
    const task = t;
    task.assignmentDueDate = getLocalDate(task.assignmentDueDate);
    task.submissionCreatedAt = getLocalDate(task.submissionCreatedAt);
  };

  reset = () => {
    this.assessmentTasksRegistry.reset();
    this.assessmentTasksCountTotal.reset();
    this.assessmentTasksAssignedToUserRegistry.reset();
    this.assessmentTasksAssignedToUserCountTotal.reset();
  };
}
