// recharts only allows 20 colors to be defined, so this list allows the colors to

import { HistogramChartData } from "../components/_common/charts/HistogramChart";

// fade to light blue, then fade back to dark blue
export const chartColors = [
  "var(--blue-7)",
  "var(--blue-6)",
  "var(--blue-5)",
  "var(--blue-4)",
  "var(--blue-4)",
  "var(--blue-3)",
  "var(--blue-3)",
  "var(--blue-2)",
  "var(--blue-2)",
  "var(--blue-1)",
  "var(--blue-1)",
  "var(--blue-2)",
  "var(--blue-2)",
  "var(--blue-3)",
  "var(--blue-3)",
  "var(--blue-4)",
  "var(--blue-4)",
  "var(--blue-5)",
  "var(--blue-6)",
  "var(--blue-7)",
];

// Converts an array of objects into the format needed for the histogram chart.
// The user needs to supply the objects and the key within the objects that contains
// the values to be displayed in the histogram (variables named data and dataKey respectively),
// as well as the number of bins desired in the histogram.
// This function assumes that the type of data stored at dataKey is numeric.
// at the histogram is numeric.
export function getHistogramChartDataForNumericValue(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[],
  dataKey: string,
  numberOfBins: number,
  minBinValue: number | undefined,
  maxBinValue: number | undefined,
  binLabels: string[] = []
): HistogramChartData[] {
  // get the min and max values from the data array
  const min =
    minBinValue !== undefined ? minBinValue : Math.min(...data.map((obj) => obj[dataKey]));
  const max =
    maxBinValue !== undefined ? maxBinValue : Math.max(...data.map((obj) => obj[dataKey]));

  // get the number of bins that elements should be sorted in to (histograms display the count of items in a bin)
  let numBins = numberOfBins;
  if (binLabels.length > 0) numBins = binLabels.length;
  else if (numBins < 0) numBins = max - min + 1;

  // each bin should have an equal share
  const interval = (max - min) / numBins;

  // initialize the bins to each start with a count of zero
  const bins = new Array(numBins).fill(0);

  // update the bin counts according to the data provided
  data.forEach((value) => {
    const binIndex = Math.min(Math.floor((value[dataKey] - min) / interval), numBins - 1);
    bins[binIndex] += 1;
  });

  // change the bin data to a format that's more helpful for the histogram chart
  // that format is a list of objects, one for each bin, with the following format {bin: string, count: number}
  const chartData = bins.map((count, index) => ({
    bin:
      binLabels.length === 0
        ? `${(min + interval * index).toFixed(1)} - ${(min + interval * (index + 1)).toFixed(1)}`
        : binLabels[index],
    count,
  }));

  return chartData;
}
