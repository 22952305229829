import { makeAutoObservable } from "mobx";
import InputDialog from "../components/_common/dialog/InputDialog";
import ConfirmationModal from "../components/_modal/ConfirmationModal";

// adapted from https://github.com/TryCatchLearn/Reactivities

export enum ModalBackgroundColor {
  PRIMARY = "primary",
  WHITE = "white",
}

export enum ModalType {
  POLLING = "polling",
  MISC = "misc",
}

type ModalBodySupplier = JSX.Element | ((index: number) => JSX.Element);

type ModalProperties = {
  backgroundColor?: ModalBackgroundColor;
  showCloseIcon?: boolean;
  isChildModal?: boolean;
  modalType?: ModalType;
  onClose?: () => void;
};

type ConfirmationModalOptions = {
  confirmButtonText?: string;
  cancelButtonText?: string;
  showCancelButton?: boolean;
};

const defaultBackgroundColor: ModalBackgroundColor = ModalBackgroundColor.WHITE;
const defaultModalType: ModalType = ModalType.MISC;
const defaultShowCloseIcon = true;
const defaultIsChildModal = false;

export interface Modal {
  body: JSX.Element;
  backgroundColor: ModalBackgroundColor;
  modalType: ModalType;
  showCloseIcon: boolean;
  child: Modal | undefined;
  index: number;
  onClose?: () => void;
}

/**
 * Props to be extended for use in components that are modals. Useful to prevent repeated code in components that will take the modal index to later close themselves.
 */
export interface ModalProps {
  modalIndex: number;
}

export default class ModalStore {
  loading = false;

  modal: Modal | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Adds a modal to the child of the main modal, or just sets the main modal if it is currently undefined.
   *
   * This method recursively searches through modals and their children to add the modal at the correct spot.
   * @param content a supplier that supplies the index of the model and expects a JSX.Element in return. The index of the supplier can be passed into the component to close the modal if necessary.
   * @param properties optional properties given to the modal
   */
  openModal = (content: ModalBodySupplier, properties?: ModalProperties) => {
    const backgroundColor = properties?.backgroundColor ?? defaultBackgroundColor;
    const modalType = properties?.modalType ?? defaultModalType;
    const showCloseIcon = properties?.showCloseIcon ?? defaultShowCloseIcon;
    const isChildModal = properties?.isChildModal ?? defaultIsChildModal;

    const getContent = (index: number) => {
      if (typeof content === "function") return content(index);

      return content;
    };

    if (this.modal === undefined || !isChildModal) {
      this.modal = {
        body: getContent(0),
        backgroundColor,
        modalType,
        showCloseIcon,
        child: undefined,
        index: 0,
        onClose: properties?.onClose,
      };
      return;
    }

    let currentModal: Modal = this.modal;
    let index = 1;

    while (currentModal.child !== undefined) {
      currentModal = currentModal.child;
      index += 1;
    }

    currentModal.child = {
      body: getContent(index),
      modalType,
      backgroundColor: backgroundColor || defaultBackgroundColor,
      showCloseIcon,
      child: undefined,
      index,
      onClose: properties?.onClose,
    };
  };

  /**
   * Opens an input dialog modal with a specific configuration.
   *
   * @param message The message or prompt to display in the modal.
   * @param onConfirm Callback function to execute with the input value when confirmed.
   */
  openInputDialogModal = (
    message: JSX.Element,
    {
      defaultValue = "",
      confirmButtonText = "Confirm",
      cancelButtonText = "Cancel",
      validateInput = (input: string) => true as true | string, // eslint-disable-line @typescript-eslint/no-unused-vars
    } = {}
  ): Promise<string | null> =>
    new Promise((resolve) => {
      const modalContent = (index: number) => (
        <InputDialog
          key={index}
          prompt={message}
          defaultValue={defaultValue}
          validateInput={validateInput}
          confirmButtonText={confirmButtonText}
          onConfirm={(inputValue) => {
            resolve(inputValue); // Resolve the promise with the input value
            this.closeModal(index); // Close the modal
          }}
          cancelButtonText={cancelButtonText}
          onCancel={() => {
            resolve(null); // Resolve the promise with null when canceled
            this.closeModal(index); // Close the modal
          }}
        />
      );

      this.openModal(modalContent);
    });

  /**
   * Closes the main modal or a child model if an index is defined.
   *
   * This method will recursively look through modals and their children to close the correct modal.
   *
   * NOTE: if a modal is closed that has children, those children will also be closed.
   * @param index the index of the child modal to close.
   */
  closeModal = (index = 0) => {
    if (this.modal === undefined || index < 1) {
      this.modal = undefined;
      return;
    }

    let currentModal: Modal = this.modal;

    while (currentModal.index < index - 1 && currentModal.child !== undefined) {
      currentModal = currentModal.child;
    }

    currentModal.child = undefined;
  };

  /**
   * Removes the first modal that matches the ModalType. It's possible that a modal's children will also be removed if
   * the specified type matches a modal that is "lower" on the screen than a child modal.
   * @param type the type of the modal to close
   */
  closeModalByType = (type: ModalType) => {
    if (this.modal === undefined || this.modal.modalType === type) {
      this.modal = undefined;
      return;
    }

    let currentModal: Modal = this.modal;

    while (currentModal.child !== undefined) {
      if (currentModal.child.modalType === type) {
        currentModal.child = undefined;
      } else {
        currentModal = currentModal.child;
      }
    }
  };

  openConfirmationModal = (
    prompt: JSX.Element | string,
    options?: ConfirmationModalOptions
  ): Promise<boolean> =>
    new Promise((resolve) => {
      const modalContent = (index: number) => (
        <ConfirmationModal
          prompt={prompt}
          confirmButtonText={options?.confirmButtonText ?? "Confirm"}
          cancelButtonText={options?.cancelButtonText ?? "Cancel"}
          showCancelButton={options?.showCancelButton ?? true}
          onConfirm={() => {
            resolve(true);
            this.closeModal(index);
          }}
          onCancel={() => {
            resolve(false);
            this.closeModal(index);
          }}
        />
      );

      this.openModal(modalContent, {
        onClose: () => {
          resolve(false);
        },
      });
    });
}
