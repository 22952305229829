import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Header, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import useCurrentUserCourseRole from "../../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../../hooks/useValidParameters";
import { useStore } from "../../../../../stores/store";
import LoadingComponent from "../../../../../utilities/routing/components/LoadingComponent";
import TeachFrontNavLink from "../../../../../utilities/routing/components/TeachFrontNavLink";
import AssignmentSubmissionDetails from "../../_common/AssignmentSubmissionDetails";
import CreateOrEditAssignment from "../../_modal/assignment/CreateOrEditAssignment";
import PollingAssignmentDetails from "./PollingAssignmentDetails";
import StandardAssignmentDetails from "./StandardAssignmentDetails";
import "./ViewAssignmentDetails.css";
import FlexContainer from "../../../../_common/style/FlexContainer";
import useWindowSize from "../../../../../hooks/useWindowSize";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import { isPollingAssignment } from "../../../../../utilities/utils";
import CreateOrEditPollingAssignment from "../CreateOrEditPollingAssignment";

const ViewAssignmentDetails: React.FC = () => {
  const { assignmentStore, courseStore, userStore, modalStore, toastStore, googleAnalyticsStore } =
    useStore();
  const navigate = useNavigate();
  const { showToast } = toastStore;
  const { isMobile } = useWindowSize();
  const {
    loadAssignmentWithObjectivesAndMasteryLevels,
    assignmentWithObjectivesAndMasteryLevels,
    hasLoadedAssignmentWithObjectivesAndMasteryLevels,
    deleteAssignment,
  } = assignmentStore;
  const { course, courseRoleForCurrentUser } = courseStore;
  const { user } = userStore;
  const { courseID, assignmentID } = useValidParams<{
    courseID: string;
    assignmentID: string;
  }>();
  const { isUserStudent, isUserTeachingTeam } = useCurrentUserCourseRole(courseID);

  useEffect(() => {
    if (user && assignmentWithObjectivesAndMasteryLevels) {
      googleAnalyticsStore.userOpenedAssignmentPage(
        user.userID,
        assignmentID,
        assignmentWithObjectivesAndMasteryLevels.name ?? ""
      );
    }
  }, [user, assignmentWithObjectivesAndMasteryLevels]);

  useEffect(() => {
    loadAssignmentWithObjectivesAndMasteryLevels(assignmentID, courseID);
  }, [
    assignmentWithObjectivesAndMasteryLevels,
    loadAssignmentWithObjectivesAndMasteryLevels,
    course,
    assignmentID,
    user,
    courseID,
    courseRoleForCurrentUser,
  ]);

  if (!user) return <></>;

  if (
    !assignmentWithObjectivesAndMasteryLevels ||
    !hasLoadedAssignmentWithObjectivesAndMasteryLevels(assignmentID)
  )
    return <LoadingComponent content="Loading Assignment..." />;

  async function handleDeleteAssignment() {
    const confirmDelete = await modalStore.openConfirmationModal(
      "Are you sure you want to delete the assignment?"
    );
    if (confirmDelete) {
      deleteAssignment(courseID, assignmentID);
      navigate(`/courses/${courseID}/assignments`);
      showToast(
        `Assignment "${assignmentWithObjectivesAndMasteryLevels?.name}" was successfully deleted`
      );
    }
  }

  return (
    <>
      <Header as="h2">Assignment: {assignmentWithObjectivesAndMasteryLevels?.name}</Header>
      <div className="AssignmentDetails">
        {isUserTeachingTeam && (
          <>
            <FlexContainer flexWrap="wrap">
              <Button
                color="grey"
                as={TeachFrontNavLink}
                to={"CourseHomeAssignmentAllSubmissionsTab"}
                params={{ courseID, assignmentID }}
              >
                <FlexContainer justifyContent="center">
                  <Icon name="eye"></Icon>
                  {isMobile ? "View" : "Student Submissions"}
                </FlexContainer>
              </Button>
              <Button
                color="grey"
                onClick={() =>
                  modalStore.openModal(
                    isPollingAssignment(assignmentWithObjectivesAndMasteryLevels) ? (
                      <CreateOrEditPollingAssignment
                        courseID={courseID}
                        pollingAssignment={assignmentWithObjectivesAndMasteryLevels}
                      />
                    ) : (
                      <CreateOrEditAssignment
                        assignmentToEdit={assignmentWithObjectivesAndMasteryLevels}
                        courseID={courseID}
                      />
                    )
                  )
                }
              >
                <FlexContainer justifyContent="center">
                  <Icon name="edit" />
                  {isMobile ? "Edit" : "Edit Assignment"}
                </FlexContainer>
              </Button>
              <Button color="red" onClick={handleDeleteAssignment}>
                <FlexContainer justifyContent="center">
                  <Icon name="trash" />
                  {isMobile ? "Delete" : "Delete"}
                </FlexContainer>
              </Button>
            </FlexContainer>
          </>
        )}
        {!assignmentWithObjectivesAndMasteryLevels.allowedSubmissionFormat?.includes("Polling") ? (
          <>
            <StandardAssignmentDetails />
            {isUserStudent && (
              <AssignmentSubmissionDetails
                assignmentWithObjectives={assignmentWithObjectivesAndMasteryLevels}
                userID={user.userID}
                courseID={courseID}
                showingInAssignmentDetails={true}
              />
            )}
          </>
        ) : (
          <>
            <VerticalGap height="1rem" />
            <PollingAssignmentDetails />
          </>
        )}
      </div>
    </>
  );
};

export default observer(ViewAssignmentDetails);
