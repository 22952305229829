import { forwardRef, Ref } from "react";
import { Header } from "semantic-ui-react";
import applyAndReuseFeedback from "../../../assets/applyAndReuseFeedback.png";
import assignmentFeedback from "../../../assets/assignmentFeedback.png";
import courseCalendarImage from "../../../assets/courseCalendar.png";
import createAssignment from "../../../assets/createAssignment.png";
import gradeCalculations from "../../../assets/gradeCalculations.png";
import polling from "../../../assets/polling.png";
import reassessments from "../../../assets/reassessments.png";
import FeaturePreview from "./FeaturePreview";
import "./FeatureSection.css";

const features = [
  {
    feature: "Assign, Collect, & Assess Assignments",
    description:
      "TeachFront is a fully-featured learning management system (LMS). On TeachFront, you can create assignments: write instructions, select how students should submit their work (rich text, URL, file upload, etc.) set enforceable due dates, select which course objectives the assignment should assess, and more. TeachFront will collect student work in the format you selected. Then, you can rate the level to which the work shows mastery of the selected course objectives.",
    photo: createAssignment,
    alignPhoto: "left",
  },
  {
    feature: "Apply & Reuse Feedback",
    description:
      "The feedback given on student work in mastery grading contexts takes a different tone than in traditional grading. Where feedback in traditional grading points out imperfections (do you see the pun there?), mastery grading offers opportunities for improvement. TeachFront supports this growth-based assessment by allowing instructors to collect a vault of common feedback items so the same feedback can be offered to future submissions with the click of a button.",
    photo: applyAndReuseFeedback,
    alignPhoto: "right",
  },
  {
    feature: "Reassess Improved Student Work",
    description:
      "One of the most challenging tasks of practicing mastery grading (without the proper tooling, of course) is the organization of reassessments. TeachFront makes this process seamless. Students can request reassessment by filling out an easily available form. On the form, students can indicate which objectives they would like reassessed and explain what they have improved to warrant a higher mastery level. Once the reassessment request is submitted by the student, a notification is sent to the teaching team (instructors, TAs, etc.). All outstanding assessments (including reassessment requests) are available in a list of tasks on the instructor’s dashboard.",
    photo: reassessments,
    alignPhoto: "left",
  },
  {
    feature: "In-Class Polling",
    description:
      "TeachFront allows instructors to receive real-time feedback from students during class time with polling assignments. Support peer instruction activities with multiple choice responses, short answer responses, and even \"Are you finished?\" checks to keep class moving when other active learning activities are used. All questions are accompanied with confidence checks, so instructors can track students' self-efficacy with the material alongside their activity responses. At the end of class, TeachFront can calculate each student's participation level and use that to auto-assess the polling assignment.",
    photo: polling,
    alignPhoto: "right",
  },
  {
    feature: "Transparent Grade Calculator",
    description:
      "The process of translating mastery assessments into grade distinctions (A+, B-, C, etc.) can be complicated and confusing for students. TeachFront provides up-to-date grade calculations and thorough descriptions of how those calculations work. Interactive visualizations help students understand their grade distinction and which areas need improvement in order to earn the grade they want. TeachFront even provides a syllabus builder, so your syllabi will always be up to date with the methodologies used to calculate grade distinctions.",
    photo: gradeCalculations,
    alignPhoto: "left",
  },
  {
    feature: "Course Calendar",
    description:
      "Keep all members of the course in sync with TeachFront’s course calendar. All assignment due dates are automatically included, but you can add custom events, too, like lecture sessions, office hours, reminders, and more. On each calendar event, you can provide quick links to items like lecture slides, reading assignments, etc. You can even import the TeachFront calendar into your favorite calendar app!",
    photo: courseCalendarImage,
    alignPhoto: "right",
  },
  {
    feature: "Student Feedback",
    description:
      "Let's face it, some assignments are more effective than others. It can be difficult, though, for instructors to know which assignments are which. TeachFront allows instructors to request feedback from their students about any or all assignments, with metrics for learning effectiveness, task relevance, time taken, and more. Plan for your next course offering now with TeachFront's student feedback analytics.",
    photo: assignmentFeedback,
    alignPhoto: "left",
  },
];

const FeatureSection = forwardRef<HTMLDivElement>((props, ref: Ref<HTMLDivElement>) => (
  <div id="FeatureSection" className="FeatureSection" ref={ref}>
    <Header as="h2">TeachFront Feature Highlights</Header>
    {features.map((feature) => (
      <FeaturePreview
        key={feature.feature}
        feature={feature.feature}
        description={feature.description}
        photo={feature.photo}
        alignPhoto={feature.alignPhoto}
      />
    ))}
  </div>
));

export default FeatureSection;
