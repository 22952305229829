import { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { useStore } from "../../stores/store";
import VerticalGap from "../_common/style/spacing/VerticalGap";
import "./UploadProfilePhotoModal.css";

interface UploadProfilePhotoModalProps {
  userID: string;
}

const UploadProfilePhotoModal: React.FC<UploadProfilePhotoModalProps> = ({ userID }) => {
  const { uploadedFileStore, modalStore, userStore } = useStore();
  const { loadUserProfile } = userStore;
  const { uploadProfilePhoto } = uploadedFileStore;
  const [avatar, setAvatar] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="UploadProfilePhotoModal">
      <h4>Upload Profile Photo</h4>
      <div className="input-container">
        <input
          type="file"
          multiple={false}
          onChange={(e) => {
            const { files } = e.target;

            if (files && files.length > 0) setAvatar(files[0] as File);

            e.target.value = "";
          }}
          accept="image/*"
        />
        <div
          style={{
            backgroundImage: avatar ? `url(${URL.createObjectURL(avatar)})` : undefined,
          }}
        >
          {!avatar && (
            <>
              <Icon name="upload" />
              <p>Click to Upload</p>
            </>
          )}
        </div>
      </div>
      <VerticalGap height="1em" />
      {avatar && <p>Chose file: {avatar.name}</p>}
      <VerticalGap height="3em" />
      <div>
        <Button color="grey" content="Cancel" onClick={() => modalStore.closeModal()} />
        <Button
          color="blue"
          content="Save"
          floated="right"
          disabled={!avatar || loading}
          onClick={async () => {
            setLoading(true);
            await uploadProfilePhoto(userID, avatar as File);
            await loadUserProfile(userID);
            modalStore.closeModal();
            setLoading(false);
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default UploadProfilePhotoModal;
