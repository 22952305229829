// eslint-disable-next-line import/no-cycle
import api, { ErrorHandlerPackage } from "../api/api";
import { AssignmentSurveySubmission } from "../models/AssignmentSurveySubmission";
import { emptyID } from "../utilities/submissionUtils";
import { StoreValue } from "./storeValue";

export default class AssignmentSurveyStore {
  private assignmentSurveySubmissionRegistry = new StoreValue<
    AssignmentSurveySubmission,
    { userID: string; assignmentID: string; courseID: string }
  >();

  private assignmentSurveySubmissionsForAssignmentRegistry = new StoreValue<
    AssignmentSurveySubmission[],
    { assignmentID: string; courseID: string }
  >();

  get assignmentSurveySubmission() {
    return this.assignmentSurveySubmissionRegistry.value;
  }

  get assignmentSurveySubmissionsForAssignment() {
    return this.assignmentSurveySubmissionsForAssignmentRegistry.value;
  }

  setDefaultAssignmentSurveySubmission = (
    userID: string,
    assignmentID: string,
    courseID: string
  ) => {
    const newSurvey: AssignmentSurveySubmission = {
      id: emptyID,
      courseID,
      assignmentID,
      userID,
      learningQuality: undefined,
      taskEffectiveness: undefined,
      difficulty: undefined,
      minutesToComplete: undefined,
      additionalComments: undefined,
    };

    this.assignmentSurveySubmissionRegistry.setAll(newSurvey, {
      userID,
      assignmentID,
      courseID,
    });

    this.assignmentSurveySubmissionRegistry.setLoading(false);
  };

  hasLoadedAssignmentSurveySubmission = (userID: string, assignmentID: string, courseID: string) =>
    this.assignmentSurveySubmissionRegistry.fresh(false, { userID, assignmentID, courseID });

  hasLoadedAssignmentSurveySubmissionsForAssignment = (assignmentID: string, courseID: string) =>
    this.assignmentSurveySubmissionsForAssignmentRegistry.fresh(false, { assignmentID, courseID });

  reset = () => {
    this.assignmentSurveySubmissionRegistry.reset();
    this.assignmentSurveySubmissionsForAssignmentRegistry.reset();
  };

  createOrUpdate = async (
    survey: AssignmentSurveySubmission,
    errorhandlerPackage?: ErrorHandlerPackage
  ) => {
    const success = await api.AssignmentSurveySubmissions.createOrUpdate(
      survey,
      errorhandlerPackage
    );

    this.assignmentSurveySubmissionRegistry.setAll(survey, {
      userID: survey.userID,
      assignmentID: survey.assignmentID,
      courseID: survey.courseID,
    });

    return success;
  };

  loadAssignmentSurveySubmission = async (
    userID: string,
    assignmentID: string,
    courseID: string,
    errorhandlerPackage?: ErrorHandlerPackage
  ) => {
    if (this.assignmentSurveySubmissionRegistry.fresh(true, { userID, assignmentID, courseID }))
      return undefined;

    this.assignmentSurveySubmissionRegistry.setLoading(true, { userID, assignmentID, courseID });

    const survey = await api.AssignmentSurveySubmissions.details(
      userID,
      assignmentID,
      courseID,
      errorhandlerPackage
    );

    this.assignmentSurveySubmissionRegistry.setAll(survey, {
      userID: survey.userID,
      assignmentID: survey.assignmentID,
      courseID: survey.courseID,
    });

    this.assignmentSurveySubmissionRegistry.setLoading(false);

    return survey;
  };

  loadAssignmentSurveySubmissionsForAssignment = async (
    assignmentID: string,
    courseID: string,
    errorhandlerPackage?: ErrorHandlerPackage
  ) => {
    if (
      this.assignmentSurveySubmissionsForAssignmentRegistry.fresh(true, { assignmentID, courseID })
    )
      return undefined;

    this.assignmentSurveySubmissionsForAssignmentRegistry.setLoading(true, {
      assignmentID,
      courseID,
    });

    const surveys = await api.AssignmentSurveySubmissions.listForAssignment(
      assignmentID,
      courseID,
      errorhandlerPackage
    );

    this.assignmentSurveySubmissionsForAssignmentRegistry.setAll(surveys, {
      assignmentID,
      courseID,
    });

    this.assignmentSurveySubmissionsForAssignmentRegistry.setLoading(false);

    return surveys;
  };
}
