import { Header } from "semantic-ui-react";
import TeachFrontNavLink from "../../../../../../utilities/routing/components/TeachFrontNavLink";
import useValidParams from "../../../../../../hooks/useValidParameters";

const NoGroupFound: React.FC = () => {
  const { courseID } = useValidParams<{ courseID: string }>();

  return (
    <div>
      <Header as="h3">No Group Found</Header>
      It seems the link that took you here might be invalid. Would you like to{" "}
      <TeachFrontNavLink to="CourseHomeRosterTabNewGroupSet" params={{ courseID }}>
        create a group
      </TeachFrontNavLink>{" "}
      instead?
    </div>
  );
};

export default NoGroupFound;
