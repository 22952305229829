import { User } from "../../../../../models/User";
import CopyableReadonlyField from "../../../../_common/form/CopyableReadonlyField";
import "./ExportCalendarModal.css";

interface ExportCalendarModalProps {
  user: User | null;
}

const ExportCalendarModal: React.FC<ExportCalendarModalProps> = ({ user }) => {
  const calendarURL = `${process.env.REACT_APP_API_URL}/calendars/generateForUser/${user?.userID}`;
  return (
    <div className="ExportCalendarModal">
      <h4 className="Header">Export Calendar</h4>
      <div className="Instructions">
        To integrate the TeachFront calendar with your calendar app, simply copy the provided link
        and paste it into the app's 'import calendar' feature. Your events will then be
        automatically added to your calendar.
      </div>
      <CopyableReadonlyField value={calendarURL} />
    </div>
  );
};

export default ExportCalendarModal;
