import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Grid, Header, List } from "semantic-ui-react";
import useValidParams from "../../../../hooks/useValidParameters";
import { AssignmentSurveySubmission } from "../../../../models/AssignmentSurveySubmission";
import { useStore } from "../../../../stores/store";
import LoadingComponent from "../../../../utilities/routing/components/LoadingComponent";
import HistogramChart from "../../../_common/charts/HistogramChart";
import HorizontalIndent from "../../../_common/style/spacing/HorizontalIndent";
import "./AssignmentSurveyData.css";

const AssignmentSurveyData: React.FC = () => {
  const { assignmentSurveyStore, assignmentStore } = useStore();
  const { courseID, assignmentID } = useValidParams<{ courseID: string; assignmentID: string }>();
  const {
    loadAssignmentSurveySubmissionsForAssignment,
    hasLoadedAssignmentSurveySubmissionsForAssignment,
    assignmentSurveySubmissionsForAssignment,
  } = assignmentSurveyStore;
  const {
    assignmentWithObjectivesAndMasteryLevels,
    loadAssignmentWithObjectivesAndMasteryLevels,
    hasLoadedAssignmentWithObjectivesAndMasteryLevels,
  } = assignmentStore;

  // load surveys
  useEffect(() => {
    loadAssignmentSurveySubmissionsForAssignment(assignmentID, courseID);
  }, [
    assignmentSurveySubmissionsForAssignment,
    loadAssignmentSurveySubmissionsForAssignment,
    assignmentID,
    courseID,
  ]);

  // load assignment
  useEffect(() => {
    loadAssignmentWithObjectivesAndMasteryLevels(assignmentID, courseID);
  }, [assignmentWithObjectivesAndMasteryLevels, assignmentID, courseID]);

  // loading component if things aren't ready yet
  if (
    !hasLoadedAssignmentSurveySubmissionsForAssignment(assignmentID, courseID) ||
    !hasLoadedAssignmentWithObjectivesAndMasteryLevels(assignmentID)
  ) {
    return <LoadingComponent content="Loading Survey Data..." />;
  }

  // find max count of any of the 3 Likert scale responses (learning quality, task effectiveness, difficulty)
  // (this will determine the max x axis value, so all three graphs are proportional)
  const findMaxSharedValueCount = (surveys: AssignmentSurveySubmission[] | undefined): number => {
    if (surveys === undefined) return 0;
    // Initialize a nested count object for each field.
    const count: { [key: string]: { [key: number]: number } } = {};

    // Loop over the array of objects.
    surveys.forEach((submission) => {
      // Loop over the keys of each submission object.
      Object.entries(submission).forEach(([key, value]) => {
        // in case the key or value doesn't exist yet
        count[key] = count[key] ?? [];
        const countAtKey = count[key] ?? [];
        count[key] = countAtKey;
        countAtKey[value] = countAtKey[value] ?? 0;

        if (value !== undefined) {
          countAtKey[value] += 1;
        }
      });
    });

    // max of counts
    const learningMax =
      count.learningQuality !== undefined ? Math.max(...Object.values(count.learningQuality)) : 0;
    const taskMax =
      count.taskEffectiveness !== undefined
        ? Math.max(...Object.values(count.taskEffectiveness))
        : 0;
    const difficultyMax =
      count.difficulty !== undefined ? Math.max(...Object.values(count.difficulty)) : 0;

    // max of maxes
    return Math.max(learningMax, taskMax, difficultyMax);
  };

  const xAxisLimit = findMaxSharedValueCount(assignmentSurveySubmissionsForAssignment);

  return (
    <div className="AssignmentSurveyData">
      <Header as="h2">
        Student Survey Data for {assignmentWithObjectivesAndMasteryLevels?.name} (
        {assignmentSurveySubmissionsForAssignment?.length} respondents)
      </Header>
      <Grid stackable columns={3}>
        <Grid.Row>
          <Grid.Column>
            <HistogramChart
              height={180}
              chartTitle="Learning Quality"
              data={assignmentSurveySubmissionsForAssignment ?? []}
              dataKey="learningQuality"
              minBinValue={0}
              maxBinValue={4}
              binLabels={["not well at all", "not very well", "kind of well", "very well"]}
              countUnit="students"
              variableAxisMax={xAxisLimit}
            />
          </Grid.Column>
          <Grid.Column>
            <HistogramChart
              height={180}
              chartTitle="Task Effectiveness"
              data={assignmentSurveySubmissionsForAssignment ?? []}
              dataKey="taskEffectiveness"
              minBinValue={0}
              maxBinValue={4}
              binLabels={[
                "mostly busywork",
                "a little tedious",
                "a little helpful",
                "mostly helpful",
              ]}
              countUnit="students"
              variableAxisMax={xAxisLimit}
            />
          </Grid.Column>
          <Grid.Column>
            <HistogramChart
              height={180}
              chartTitle="Difficulty"
              data={assignmentSurveySubmissionsForAssignment ?? []}
              dataKey="difficulty"
              minBinValue={0}
              maxBinValue={4}
              binLabels={["easy peasy", "pretty simple", "kind of tough", "really difficult"]}
              countUnit="students"
              variableAxisMax={xAxisLimit}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <HistogramChart
            height={180}
            verticalBars
            chartTitle="Hours to Complete Assignment"
            data={assignmentSurveySubmissionsForAssignment ?? []}
            dataKey="minutesToComplete"
            minBinValue={0} // zero minutes
            maxBinValue={1500} // 25 hours
            binLabels={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25+",
            ]}
            labelUnit="hours"
            countUnit="students"
          />
        </Grid.Row>
      </Grid>
      <HorizontalIndent>
        <Header as="h3">Additional Comments</Header>
        <List bulleted>
          {assignmentSurveySubmissionsForAssignment?.map((survey) =>
            survey.additionalComments !== undefined ? (
              <List.Item key={survey.id}>{survey.additionalComments}</List.Item>
            ) : (
              ""
            )
          )}
        </List>
      </HorizontalIndent>
    </div>
  );
};

export default observer(AssignmentSurveyData);
