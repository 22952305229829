import { Formik, FormikValues } from "formik";
import "./ResetPassword.css";
import { Button, Form, Header, Image } from "semantic-ui-react";
import { Navigate, useNavigate } from "react-router-dom";
import { useStore } from "../../stores/store";
import { invalidPasswordMessage, isValidPassword } from "../../utilities/utils";
import FormLogoInput from "../_common/form/FormLogoInput";
import logo from "../../assets/logo.svg";
import { objectEvery } from "../../utilities/collectionUtils";
import useQuery from "../../hooks/useQuery";

const ResetPassword: React.FC = () => {
  const { userStore, toastStore } = useStore();
  const query = useQuery();
  const navigate = useNavigate();
  const token = query.get("token");
  const email = query.get("email");

  if (!token || !email) {
    return <Navigate to={"/"} />;
  }

  const validate = (values: FormikValues) => {
    const errors: {
      password?: string;
      confirmPassword?: string;
      errors?: string;
    } = {};
    if (!isValidPassword(values.password)) errors.password = invalidPasswordMessage;
    if (!isValidPassword(values.confirmPassword)) errors.confirmPassword = invalidPasswordMessage;
    if (values.password !== values.confirmPassword)
      errors.confirmPassword = "Your passwords must match";
    return errors;
  };

  return (
    <Formik
      validate={validate}
      initialTouched={{}}
      initialErrors={{}}
      initialValues={{
        password: "",
        confirmPassword: "",
        error: null,
      }}
      onSubmit={(values, { setErrors }) =>
        userStore
          .passwordReset(email, token, values.password, {
            401: (status, message) => setErrors({ error: message }),
          })
          .then((success) => {
            if (success) {
              navigate("/login");
              toastStore.showToast("Password has been reset. Please continue to login.");
            }
          })
      }
    >
      {({ handleSubmit, isSubmitting, errors, touched, values }) => (
        <Form className="ResetPassword ui form" onSubmit={handleSubmit} autoComplete="on">
          <Image src={logo} centered size="small" />
          <Header as="h2" content="Change your password" textAlign="center" />
          <div className="content">
            <FormLogoInput
              type={"password"}
              label={"Password"}
              name={"password"}
              placeholder={"Enter your password..."}
              iconName={"lock"}
              required={true}
              error={touched.password && errors.password}
            />
            <FormLogoInput
              type={"password"}
              label={"Confirm Password"}
              name={"confirmPassword"}
              placeholder={"Confirm your password..."}
              iconName={"lock"}
              required={true}
              error={touched.confirmPassword && errors.confirmPassword}
            />
            {errors.error && <span className="error">Error: {errors.error}</span>}
            <Button
              loading={isSubmitting}
              content="Reset Password"
              type="submit"
              color="grey"
              fluid
              disabled={!objectEvery(validate(values), (_, value) => !value)}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default ResetPassword;
