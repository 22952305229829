import TEACHFRONT_PATHS from "../../paths";
import { TeachFrontPageName, TeachFrontPathParams } from "./types";

// Based off of https://dev.to/0916dhkim/type-safe-usage-of-react-router-5c44

// Generates a URI for a specific page, injected with the parameters relevant to that page's URI.
const buildPath = <TPage extends TeachFrontPageName>(
  page: TPage,
  // eslint-disable-next-line prettier/prettier
  params: TeachFrontPathParams<(typeof TEACHFRONT_PATHS)[TPage]>
): string => {
  // Disabling eslint rules is bad practice in most cases, but this is code from
  // an external source, so eslint exceptions are added to reduce the risk of
  // refactoring third party code:
  let ret: string = TEACHFRONT_PATHS[page];
  const paramObj: { [i: string]: string } = params;
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(paramObj)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ret = ret.replace(`:${key}`, paramObj[key]!);
  }
  return ret;
};

export default buildPath;
