import { Header, Icon, Label } from "semantic-ui-react";
import TeachFrontNavLink from "../../utilities/routing/components/TeachFrontNavLink";
import "./NotFound404.css";
import FlexContainer from "../_common/style/FlexContainer";
import ComplicatedShell from "../_misc/ComplicatedShell";
import RoundedSegment from "../_common/style/RoundedSegment";

const NotFound404: React.FC = () => (
  <>
    <FlexContainer gap="4rem" className="error-page-container">
      <FlexContainer flexDirection="column">
        <div className="error-info-container">
          <Header className="error-page-header" as="h3">
            404
          </Header>
          <RoundedSegment className="error-page-background">
            <span className="error-page-text">
              <span className="error-page-whoops">Whoops</span>, no page here! <br /> Choose your
              next step to continue growing with us.
            </span>
          </RoundedSegment>
          <div>
            <Label
              as={TeachFrontNavLink}
              params={{}}
              to="Landing"
              size="medium"
              className="blue-5 error-page-button"
            >
              <Icon name="arrow right" />
              Return Home
            </Label>
          </div>
        </div>
      </FlexContainer>
      <ComplicatedShell />
    </FlexContainer>
  </>
);

export default NotFound404;
