import { observer } from "mobx-react-lite";
import { Header, Step } from "semantic-ui-react";
import { MasteryLevel } from "../../../../models/MasteryLevel";
import { MasteryLevelScheme } from "../../../../models/MasteryLevelScheme";
import { useStore } from "../../../../stores/store";
import UserInputDisplay from "../../../_common/textEditing/UserInputDisplay";
import "./CourseMasteryLevelScheme.css";

interface CourseMasteryLevelSchemeProps {
  scheme: MasteryLevelScheme;
}

const CourseMasteryLevelScheme: React.FC<CourseMasteryLevelSchemeProps> = ({ scheme }) => {
  const { courseStore } = useStore();
  const { currentCourse } = courseStore;

  if (!scheme) return <></>;

  return (
    <div className="CourseMasteryLevelScheme">
      <Header as="h4">{scheme.name}</Header>
      <Step.Group stackable="tablet">
        {scheme.masteryLevels.map((masteryLevel: MasteryLevel) => (
          <Step key={masteryLevel.id}>
            <Step.Content>
              <Step.Title style={{ color: `var(--${currentCourse?.color})` }}>
                {masteryLevel.name}:
              </Step.Title>
              <Step.Description>
                <UserInputDisplay content={masteryLevel.description} />
              </Step.Description>
            </Step.Content>
          </Step>
        ))}
      </Step.Group>
    </div>
  );
};

export default observer(CourseMasteryLevelScheme);
