import Hub from "../api/hub";
import PollingActivityModal from "../components/courses/[courseID]/_modal/polling/PollingActivityModal";
import { Observer } from "../models/Observer";
import { PollingActivity } from "../models/polling/PollingActivity";
import { PollingActivityQuestionResponse } from "../models/polling/PollingActivityQuestionResponse";
import { ModalType } from "../stores/modalStore";
import { store } from "../stores/store";

const isPollingActivityAnsweredByStudent = (
  activity: PollingActivity,
  userID: string | undefined
) =>
  !activity.pollingActivityQuestions ||
  activity.pollingActivityQuestions.every(({ pollingActivityQuestionResponses }) =>
    pollingActivityQuestionResponses.some((r) => r.studentID === userID)
  );

// this class is instantiated (and thus registered as a hub observer) in App.tsx
export default class PollingHubObserver implements Observer {
  // the constructor registers this observer with the Hub
  constructor() {
    // register this as an observer
    Hub.addObserver(this);
  }

  // This method will be called when the hub is about to connect.
  // So we'll register the listeners for this feature here.
  update(): void {
    const hubInstance = Hub.getCurrentInstance();

    // listen for a message indicating that a poll has been opened
    hubInstance.listenForHook("PollingActivityOpened", (activity: PollingActivity) => {
      store.pollingStore.setActivePollingActivity(activity);

      // only raise the polling modal for students
      if (
        store.courseStore.courseMemberDetailsForCurrentUser?.courseRole === "Student" &&
        !isPollingActivityAnsweredByStudent(activity, store.userStore.user?.userID)
      ) {
        store.modalStore.openModal(<PollingActivityModal activePollingActivity={activity} />, {
          showCloseIcon: true,
          modalType: ModalType.POLLING,
        });
      }
    });

    // listen for a message indicating that a poll has been closed
    hubInstance.listenForHook("PollingActivityClosed", (activityID: string) => {
      store.pollingStore.closeActivePollingActivity(activityID);
      store.modalStore.closeModalByType(ModalType.POLLING);
    });

    // listen for a message indicating that a poll has been closed
    hubInstance.listenForHook("PollingActivityDeleted", (activityID: string) => {
      store.pollingStore.removePollingActivity(activityID);
      store.modalStore.closeModalByType(ModalType.POLLING);
    });

    // listen for a message indicating that a poll response has been received
    hubInstance.listenForHook(
      "PollingActivityStudentResponse",
      (response: PollingActivityQuestionResponse) => {
        store.pollingStore.recordNewPollingActivityQuestionResponseForQuestion(response);
      }
    );
  }
}
