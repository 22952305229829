import Hub from "../api/hub";
import { Assignment } from "../models/Assignment";
import { Objective } from "../models/Objective";
import { Observer } from "../models/Observer";
import { store } from "../stores/store";

export default class AssignmentsHubObserver implements Observer {
  constructor() {
    Hub.addObserver(this);
  }

  update(): void {
    const hubInstance = Hub.getCurrentInstance();
    const userID = store.userStore.user?.userID;
    if (!userID) return;

    // an assignment was deleted
    hubInstance.listenForHook("AssignmentDeleted", (assignmentID: string) => {
      store.assignmentStore.recordDeletedAssignment(assignmentID);
    });

    // an assignment was created or updated
    hubInstance.listenForHook("AssignmentCreatedOrUpdated", (assignment: Assignment<Objective>) => {
      store.assignmentStore.recordUpdatedAssignment(assignment);
    });
  }
}
