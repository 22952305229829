import { useState } from "react";
import { Header } from "semantic-ui-react";
import { createClassName } from "../../../utilities/utils";
import Tooltip from "../misc/Tooltip";
import TextEditor from "../textEditing/TextEditor";
import "./FormRichTextInput.css";

interface FormRichTextInputProps {
  label?: string;
  placeholder: string;
  defaultValue: string;
  error?: string | undefined | boolean;
  required: boolean;
  tooltip?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  maxWidth?: string;
}

const FormRichTextInput: React.FC<FormRichTextInputProps> = ({
  label,
  placeholder,
  defaultValue,
  required,
  error,
  tooltip,
  disabled,
  onBlur,
  onFocus,
  onChange,
  maxWidth,
}) => {
  const [focused, setFocused] = useState<boolean>(false);

  return (
    <div
      className={createClassName("FormRichTextInput", "textarea", {
        name: "disabled",
        apply: !!disabled,
      })}
      style={{ maxWidth: maxWidth ?? "unset" }}
    >
      {label && (
        <Header as="h4">
          {label}
          {required && <span className={"required"}> *</span>}
        </Header>
      )}
      <div
        className={createClassName("input-box", { name: "focused", apply: focused })}
        onFocus={() => {
          setFocused(true);
          if (onFocus) onFocus();
        }}
        onBlur={() => {
          setFocused(false);
          if (onBlur) onBlur();
        }}
      >
        <TextEditor
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={(value) => onChange && onChange(value)}
          smallHeight
        />
      </div>
      {error && <span className="input-error">{error}</span>}
      {tooltip && (
        <div className="tooltip-container">
          <Tooltip tooltip={tooltip} />
        </div>
      )}
    </div>
  );
};

export default FormRichTextInput;
