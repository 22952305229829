import { useRef } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { Icon, Label, SemanticICONS } from "semantic-ui-react";
import { Without } from "../../../models/utilModels";
import { createClassName } from "../../../utilities/utils";
import FlexContainer from "../style/FlexContainer";
import "./FormDateInput.css";

interface FormDateInputProps {
  icon: SemanticICONS;
  label: string;
  required?: boolean;
  disabled?: boolean;
  currentDate: Date | undefined;
  onDateChange: (newDate: Date) => void;
  datePickerProps?: Without<ReactDatePickerProps, "onChange">;
  error?: string | undefined | boolean;
}

const FormDateInput: React.FC<FormDateInputProps> = ({
  icon,
  label,
  required = false,
  disabled = false,
  currentDate,
  onDateChange,
  datePickerProps = {},
  error,
}) => {
  const dateRef = useRef<DatePicker<never, undefined> | null>();

  return (
    <div className="FormDateInput">
      <FlexContainer
        className={createClassName("form", { name: "disabled", apply: disabled })}
        justifyContent="center"
        onClick={() => dateRef.current?.setOpen(true)}
      >
        <Label>
          <Icon name={icon} />
          {label}
        </Label>
        <DatePicker
          {...datePickerProps}
          ref={(r) => {
            dateRef.current = r as DatePicker<never, undefined> | null;
          }}
          selected={currentDate}
          onChange={onDateChange}
          disabled={disabled || !!datePickerProps.disabled}
        />
        {required && <span className="required">*</span>}
      </FlexContainer>
      {error && <span className="input-error">{error}</span>}
    </div>
  );
};

export default FormDateInput;
