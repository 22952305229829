import { Header } from "semantic-ui-react";
import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { MdDragIndicator } from "react-icons/md";
import useValidParams from "../../../../../hooks/useValidParameters";
import { useStore } from "../../../../../stores/store";
import HorizontalIndent from "../../../../_common/style/spacing/HorizontalIndent";
import CourseRoles from "../../../../../utilities/routing/components/routeAuthorizationExtensions/CourseRoles";
import GroupCard from "./_common/GroupCard";
import "./GroupSetView.css";
import { cn, concatenateUserName } from "../../../../../utilities/utils";
import FlexContainer from "../../../../_common/style/FlexContainer";

const GroupSetView: React.FC = () => {
  const { courseID, groupSetID } = useValidParams<{ courseID: string; groupSetID: string }>();
  const { groupSetsStore, courseStore, userStore } = useStore();
  const { groupSets } = groupSetsStore;
  const { roster } = courseStore;
  const { user } = userStore;

  const groupSet = useMemo(
    () => groupSets?.find((gs) => gs.id === groupSetID && gs.courseID === courseID),
    [groupSets, courseID, groupSetID]
  );

  const ungroupedStudents = useMemo(() => {
    if (!groupSet || !roster) return undefined;

    const groupedStudentsUserIDs: string[] = [];
    groupSet.groups
      .map((g) => g.groupMembers.map((gm) => gm.studentID))
      .forEach((studentIDs) => groupedStudentsUserIDs.push(...studentIDs));

    return roster
      .filter(
        (student) => student.courseRole && CourseRoles.StudentRoles.includes(student.courseRole)
      )
      .filter((student) => !groupedStudentsUserIDs.includes(student.userID));
  }, [groupSet]);

  // The roster should always exist
  if (!groupSet || !roster || !ungroupedStudents) return <></>;

  const getGroupCreationTypeMessage = () => {
    if (groupSet.studentsCanSelfOrganize) {
      return "Students can self-organize into groups";
    }

    const isOnlyInstructorOnTeachingTeam =
      roster.filter((u) => CourseRoles.TeachingTeamRoles.includes(u.courseRole ?? "")).length > 1;

    return `Groups are created manually by the ${
      isOnlyInstructorOnTeachingTeam ? "instructor" : "teaching team"
    }.`;
  };

  return (
    <div className="GroupSetView">
      <Header as="h3">Group Set: {groupSet.name}</Header>
      <HorizontalIndent>
        <div>
          Groups should have at least {groupSet.minMemberLimit} student
          {groupSet.minMemberLimit === 1 ? "" : "s"} and at most {groupSet.maxMemberLimit} student
          {groupSet.maxMemberLimit === 1 ? "" : "s"}.
        </div>
        <div>{getGroupCreationTypeMessage()}</div>
      </HorizontalIndent>
      <Header as="h3">Ungrouped Students:</Header>
      <div className="ungrouped-students-container">
        {ungroupedStudents.map((s) => (
          <FlexContainer
            className={cn("ungrouped-student", {
              "is-current-user": s.userID === user?.userID,
            })}
            alignItems="center"
            gap="0.25rem"
            key={s.userID}
          >
            <MdDragIndicator />
            {concatenateUserName(s.firstName, s.lastName, s.honorific, s.suffix)}
          </FlexContainer>
        ))}
      </div>
      <Header as="h3">Groups</Header>
      <div className="group-container">
        {groupSet.groups.map((g) => (
          <GroupCard group={g} key={g.id} />
        ))}
      </div>
    </div>
  );
};

export default observer(GroupSetView);
