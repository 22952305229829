import { Header } from "semantic-ui-react";
import Group from "../../../../../../models/Group";
import { concatenateUserName } from "../../../../../../utilities/utils";
import "./GroupCard.css";
import { getGroupName } from "../../../../../../utilities/groupUtils";

interface GroupCardProps {
  group: Group;
}

const GroupCard: React.FC<GroupCardProps> = ({ group }) => (
  <div className="GroupCard">
    <Header as="h4">{getGroupName(group)}</Header>
    {group.groupMembers.length === 0 && <i>This group has no members.</i>}
    {group.groupMembers.length > 0 && (
      <ul>
        {group.groupMembers.map((gm) => (
          <li key={gm.studentID}>
            {concatenateUserName(
              gm.student.firstName,
              gm.student.lastName,
              gm.student.honorific,
              gm.student.suffix
            )}
          </li>
        ))}
      </ul>
    )}
  </div>
);

export default GroupCard;
