import React, { useState } from "react";
import { Icon, Popup } from "semantic-ui-react";
import useValidParams from "../../../hooks/useValidParameters";
import { AssessmentSummaryGraphicData } from "../../../utilities/assessmentGraphicUtils";
import TeachFrontNavLink from "../../../utilities/routing/components/TeachFrontNavLink";
import { createClassName } from "../../../utilities/utils";
import AssessmentGraphicToolTip from "./AssessmentGraphicToolTip";
import FlexContainer from "../style/FlexContainer";
import "./AssessmentSummaryGraphic.css";
import { Objective } from "../../../models/Objective";
import useWindowSize from "../../../hooks/useWindowSize";

interface AssessmentSummaryGraphicProps {
  data: AssessmentSummaryGraphicData[];
  size: string;
  assignmentID?: string;
  userID?: string;
  submissionID?: string;
  unassessedIconSize?: string;
  unassessedLabelSize?: string;
  unpublishedRatingIconSize?: string;
  unpublishedRatings?: boolean;
  showToolTip?: boolean;
  objectives?: Objective[];
  highlightIndex?: number;
  linkToSubmission?: boolean;
  onObjectiveClick?: (objectiveId: number) => void;
}

/**
 * @param data the data to be used to create this graphic.
 * @param size the size of the star. Can be in any size with any unit (e.g. px, em, %)
 * @param unratedIconSize the required text size for the icon used to show that the current assessment has
 *                        not been graded.
 * @param unratedLabelSize optional text size for the label used to show that the current assessment has not been graded.
 */
const AssessmentSummaryGraphic: React.FC<AssessmentSummaryGraphicProps> = ({
  data,
  size,
  assignmentID,
  userID,
  submissionID,
  unassessedIconSize,
  unassessedLabelSize,
  unpublishedRatings,
  showToolTip = false,
  objectives,
  unpublishedRatingIconSize,
  highlightIndex,
  linkToSubmission,
  onObjectiveClick,
}) => {
  const { courseID } = useValidParams<{ courseID: string }>();

  const viewBoxWidth = 1000;
  const viewBoxHeight = viewBoxWidth * 0.75;
  const [tooltipInfo, setTooltipInfo] = useState({
    show: false,
    segmentData: {} as AssessmentSummaryGraphicData,
  });

  const handleMouseOver = (
    event: React.MouseEvent<SVGUseElement, MouseEvent>,
    segmentData: AssessmentSummaryGraphicData & { index: number }
  ) => {
    setTooltipInfo({
      show: true,
      segmentData,
    });
  };

  const handleMouseOut = () => {
    setTooltipInfo({ show: false, segmentData: {} as AssessmentSummaryGraphicData });
  };

  const uses = data.map((segmentData, index) => {
    const segmentDataIndexed = { ...segmentData, index }; // Add index to each segmentData
    const key = `obj-${index}-of-${data.length}-with-${segmentData.numLevels + 1}-mls-in-${
      segmentData.color
    }`;

    let classes = createClassName({
      name: "de-emphasize",
      apply: highlightIndex !== undefined && index !== highlightIndex,
    });

    for (let i = segmentData.numLevels; i >= 0; i -= 1) {
      if (segmentData.assessmentScore === -1 || i < segmentData.assessmentScore) {
        classes += ` hasnt-earned-level-${i}-from-top-yet`;
      }
    }
    return (
      <use
        key={`${key}-${segmentData.id}`}
        className={`${createClassName(classes, { name: "excused", apply: segmentData.excused })}`}
        xlinkHref={`#${key}`}
        x="0"
        y="0"
        onMouseOver={(e) => handleMouseOver(e, segmentDataIndexed)}
        onMouseOut={handleMouseOut}
        onClick={() => onObjectiveClick && onObjectiveClick(index)}
      />
    );
  });

  const unrated = data.every((d) => d.assessmentScore === -1);

  const windowSize = useWindowSize();

  const graphic = (
    <div
      className={createClassName("AssessmentSummaryGraphic", {
        name: "unassessed",
        apply: unrated,
      })}
      style={{ width: size }}
    >
      <svg
        className="shell-graphic"
        width={"100%"}
        height={"100%"}
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        {uses}
      </svg>
      {unrated && (
        <div>
          <div
            className={"assessment-summary-graphic-overlay-icon"}
            style={{ fontSize: unassessedIconSize }}
          >
            ?
          </div>
          {unassessedLabelSize && (
            <strong
              className={"assessment-summary-graphic-overlay-label"}
              style={{ fontSize: unassessedLabelSize }}
            >
              {unrated ? "Waiting for Assessment" : ""}
            </strong>
          )}
        </div>
      )}
      {unpublishedRatings && (
        <Popup
          content={
            <FlexContainer>
              <Icon name="warning" />
              This assessment has unpublished ratings.
            </FlexContainer>
          }
          position="right center"
          trigger={
            <div className="unpublished-ratings" style={{ fontSize: unpublishedRatingIconSize }}>
              <Icon fitted name="eye slash outline" />
            </div>
          }
        />
      )}
      {!windowSize.isMobile && (
        <AssessmentGraphicToolTip
          data={tooltipInfo.segmentData}
          show={tooltipInfo.show && showToolTip}
          objectives={objectives}
        />
      )}
    </div>
  );

  return !linkToSubmission || !assignmentID || !userID || !submissionID ? (
    graphic
  ) : (
    <TeachFrontNavLink
      to={"CourseHomeAssignmentSubmissionTab"}
      params={{ courseID, assignmentID, userID, submissionID }}
    >
      {graphic}
    </TeachFrontNavLink>
  );
};

export default AssessmentSummaryGraphic;
