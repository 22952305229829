import { Form, Formik } from "formik";
import {
  Button,
  Checkbox,
  Dropdown,
  Icon,
  Label,
  Placeholder,
  PlaceholderLine,
  SemanticCOLORS,
} from "semantic-ui-react";
import useBooleanState from "../../../hooks/useBooleanState";
import useCourseColor from "../../../hooks/useCourseColor";
import Color from "../../../models/Color";
import { MasteryLevel } from "../../../models/MasteryLevel";
import { MasteryLevelScheme } from "../../../models/MasteryLevelScheme";
import { useStore } from "../../../stores/store";
import { objectSize } from "../../../utilities/collectionUtils";
import { isFormDirty } from "../../../utilities/formUtils";
import { emptyID } from "../../../utilities/submissionUtils";
import { createClassName } from "../../../utilities/utils";
import FlexContainer from "../style/FlexContainer";
import VerticalGap from "../style/spacing/VerticalGap";
import "./MasteryLevelSchemeCard.css";
import CardTypeLabel from "./_common/CardTypeLabel";

interface MasteryLevelSchemeCardSkeletonProps {
  color: Color;
}

export const MasteryLevelSchemeCardSkeleton: React.FC<MasteryLevelSchemeCardSkeletonProps> = ({
  color,
}) => (
  <div className={"MasteryLevelSchemeCard"}>
    <CardTypeLabel content={"Mastery Level Scheme"} />
    <VerticalGap height="1rem" />
    <Label
      ribbon
      color={color as SemanticCOLORS}
      icon={"sitemap"}
      content={<input placeholder="Enter scheme name..." disabled={true} />}
      className="mastery-level-scheme-name"
    />
    <VerticalGap height="1rem" />
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
  </div>
);

interface MasteryLevelSchemeCardProps {
  masteryLevelScheme: MasteryLevelScheme;
  onCancelButtonClick?: (values: MasteryLevelScheme) => void;
  onSaveButtonClick?: (values: MasteryLevelScheme) => void;
}

const MasteryLevelSchemeCard: React.FC<MasteryLevelSchemeCardProps> = ({
  masteryLevelScheme,
  onCancelButtonClick,
  onSaveButtonClick,
}) => {
  const { masteryLevelSchemeStore, toastStore } = useStore();
  const { createOrUpdate, delete: deleteMasteryLevelScheme } = masteryLevelSchemeStore;
  const color = useCourseColor(Color.BLUE);
  const [isSubmitting, setIsSubmitting] = useBooleanState();

  const initialValues: MasteryLevelScheme = { ...masteryLevelScheme };

  const handleSubmit = async (values: MasteryLevelScheme) => {
    setIsSubmitting(true);

    await createOrUpdate(values);

    toastStore.showToast(
      `Successfully ${masteryLevelScheme.isDraft ? "created" : "updated"} mastery level scheme`
    );

    if (onSaveButtonClick) onSaveButtonClick(values);
    setIsSubmitting(false);
  };

  // add a new mastery level, while keeping the constraint that the "excused" mastery level is always last
  const addNewMasteryLevel = (masteryLevels: MasteryLevel[], newMasteryLevel: MasteryLevel) => {
    // Check if the last element has exclude=true
    const lastElement = masteryLevels[masteryLevels.length - 1];
    let removedElement: MasteryLevel | undefined;

    if (lastElement && lastElement.excludeInGradeCalculations) {
      // Temporarily remove the last element
      removedElement = masteryLevels.pop();
    }

    // Add the new object
    masteryLevels.push(newMasteryLevel);

    // If we removed the last element, add it back
    if (removedElement) {
      masteryLevels.push(removedElement);
    }

    return masteryLevels;
  };

  const handleNewMasteryLevelButtonClick = (
    masteryLevels: MasteryLevel[],
    setFieldValue: (field: string, value: unknown) => void
  ) => {
    const newMasteryLevel: MasteryLevel = {
      description: "",
      id: emptyID,
      excludeInGradeCalculations: false,
      name: "",
      relativeOrderIndex: masteryLevels.length,
      isDraft: true,
      draftCreatedAt: Date.now(),
    };

    const newMasteryLevels = addNewMasteryLevel(masteryLevels, newMasteryLevel);

    setFieldValue("masteryLevels", newMasteryLevels);
  };

  const handleMasteryLevelDeleteButtonClick = (
    index: number,
    masteryLevels: MasteryLevel[],
    setFieldValue: (field: string, value: unknown) => void
  ) => {
    const masteryLevel = masteryLevels[index] as MasteryLevel;

    if (masteryLevel.usedInRating) {
      toastStore.showToast("This mastery level is used in a rating and cannot be deleted", {
        color: "orange",
      });
      return;
    }

    if (masteryLevel.usedInGradeRecipeConstraint) {
      toastStore.showToast(
        "This mastery level is used in a grade recipe constraint and cannot be deleted.",
        {
          color: "orange",
        }
      );
      return;
    }

    const newMasteryLevels = [...masteryLevels];

    newMasteryLevels.splice(index, 1);

    setFieldValue("masteryLevels", newMasteryLevels);
  };

  const handleExcusedToggleClick = (
    masteryLevels: MasteryLevel[],
    setFieldValue: (field: string, value: unknown) => void
  ) => {
    // if excused ratings were not previously allowed, add a mastery level for excused
    if (!masteryLevels.some((ml) => ml.excludeInGradeCalculations)) {
      const newMasteryLevel: MasteryLevel = {
        id: emptyID,
        name: "Excused",
        description: "Excused ratings are not included in grade calculations.",
        excludeInGradeCalculations: true,
        relativeOrderIndex: masteryLevels.length,
        isDraft: true,
        draftCreatedAt: Date.now(),
        usedInRating: false,
        usedInGradeRecipeConstraint: false,
      };
      setFieldValue("masteryLevels", [...masteryLevels, newMasteryLevel]);
    } // if excused ratings were previously allowed, remove the mastery level for excused
    else {
      const excusedLevel = masteryLevels.find((ml) => ml.excludeInGradeCalculations);
      if (excusedLevel?.usedInRating) {
        toastStore.showToast(
          'A rating has already been marked "Excused" so this setting cannot be changed.',
          { color: "orange" }
        );
      } else if (excusedLevel?.usedInGradeRecipeConstraint) {
        toastStore.showToast(
          '"Excused" is used in a grade recipe ingredient, so this setting cannot be changed.',
          {
            color: "orange",
          }
        );
      } else {
        setFieldValue(
          "masteryLevels",
          masteryLevels.filter((ml) => !ml.excludeInGradeCalculations)
        );
      }
    }
  };

  const handleMasteryLevelInputUpdate = (
    index: number,
    field: keyof MasteryLevel,
    value: never,
    masteryLevels: MasteryLevel[],
    setFieldValue: (field: string, value: unknown) => void
  ) => {
    const newMasteryLevel: MasteryLevel = { ...(masteryLevels[index] as MasteryLevel) };

    newMasteryLevel[field] = value;

    const newMasteryLevels = [...masteryLevels];

    newMasteryLevels[index] = newMasteryLevel;

    setFieldValue("masteryLevels", newMasteryLevels);
  };

  const handleCancelButtonClicked = (
    values: MasteryLevelScheme,
    setValues: (values: MasteryLevelScheme) => void
  ) => {
    if (onCancelButtonClick) onCancelButtonClick(values);

    setValues({ ...masteryLevelScheme });
  };

  const handleDeleteMasteryLevelSchemeButtonClicked = async () => {
    if (masteryLevelScheme.usedInObjective) {
      toastStore.showToast(
        "This mastery level scheme is used by objectives and cannot be deleted",
        {
          color: "orange",
        }
      );
      return;
    }

    setIsSubmitting(true);

    await deleteMasteryLevelScheme(masteryLevelScheme.courseID, masteryLevelScheme.id);

    setIsSubmitting(false);
  };

  const validate = (values: MasteryLevelScheme) => {
    const errors: { [key: string]: string } = {};

    if (!values.name) errors.name = "The mastery level scheme must have a name";

    if (values.masteryLevels.length === 0)
      errors.masteryLevels = "There must be at least one mastery level for this scheme.";

    values.masteryLevels.forEach((masteryLevel) => {
      if (!masteryLevel.name)
        errors.masteryLevelIncompleteName = "All mastery levels must have names";
    });

    return errors;
  };

  const shouldShowEditButtons = (values: MasteryLevelScheme) =>
    values.isDraft || isFormDirty(initialValues, values);

  return (
    <Formik
      validateOnMount={true}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      enableReinitialize
    >
      {({ values, setFieldValue, errors, setValues }) => (
        <Form
          className={createClassName("MasteryLevelSchemeCard", {
            name: "is-draft",
            apply: !!values.isDraft,
          })}
        >
          <CardTypeLabel content={`Mastery Level Scheme${values.isDraft ? "  (draft)" : ""}`} />
          {!masteryLevelScheme.isDraft && (
            <Dropdown
              className="options"
              icon={"ellipsis vertical"}
              loading={isSubmitting}
              options={[
                {
                  key: "delete",
                  content: "Delete Mastery Level Scheme",
                  icon: "trash",
                  onClick: handleDeleteMasteryLevelSchemeButtonClicked,
                },
              ]}
              direction="left"
            />
          )}
          <VerticalGap height="1rem" />
          <Label
            ribbon
            // content={values.name}
            color={color as SemanticCOLORS}
            icon={"sitemap"}
            content={
              <input
                value={values.name}
                placeholder="Enter scheme name..."
                onChange={({ target }) => setFieldValue("name", target.value)}
              />
            }
            className="mastery-level-scheme-name"
          />
          <VerticalGap height="1rem" />
          <FlexContainer flexDirection="column" className="mastery-levels" gap="1rem">
            {values.masteryLevels.length > 0 && <div className="vertical-line" />}
            {values.masteryLevels
              .filter((ml) => !ml.excludeInGradeCalculations)
              .map((level, index) => (
                <FlexContainer
                  className={createClassName("mastery-level", {
                    name: "is-draft",
                    apply: !!level.isDraft,
                  })}
                  key={level.draftCreatedAt ? level.draftCreatedAt : level.id}
                >
                  <div
                    className="index"
                    style={{
                      backgroundColor: `var(--${color}-1)`,
                      borderColor: `var(--${color}-1)`,
                    }}
                  >
                    {index + 1}
                  </div>
                  <div className="line" />
                  <div
                    className={createClassName("delete-level", {
                      name: "cannot-delete",
                      apply: !!level.usedInRating || !!level.usedInGradeRecipeConstraint,
                    })}
                    role="button"
                    onClick={() =>
                      handleMasteryLevelDeleteButtonClick(
                        index,
                        values.masteryLevels,
                        setFieldValue
                      )
                    }
                  >
                    <Icon name="trash" fitted />
                  </div>
                  <div className="line" />
                  <input
                    className="name"
                    defaultValue={level.name}
                    onChange={({ target }) =>
                      handleMasteryLevelInputUpdate(
                        index,
                        "name",
                        target.value as never,
                        values.masteryLevels,
                        setFieldValue
                      )
                    }
                    disabled={isSubmitting}
                  />
                  <div className="line" />
                  <input
                    className="description"
                    defaultValue={level.description}
                    onChange={({ target }) =>
                      handleMasteryLevelInputUpdate(
                        index,
                        "description",
                        target.value as never,
                        values.masteryLevels,
                        setFieldValue
                      )
                    }
                    disabled={isSubmitting}
                  />
                </FlexContainer>
              ))}
            <div className="excused-and-add-button">
              <Checkbox
                toggle
                className="allow-excused"
                checked={values.masteryLevels.some((ml) => ml.excludeInGradeCalculations)}
                disabled={
                  isSubmitting ||
                  values.masteryLevels.find((ml) => ml.excludeInGradeCalculations)?.usedInRating
                }
                label={'Allow "Excused" ratings.'}
                onClick={() => handleExcusedToggleClick(values.masteryLevels, setFieldValue)}
              />
              <Button
                className="white new-mastery-level-button"
                onClick={() =>
                  handleNewMasteryLevelButtonClick(values.masteryLevels, setFieldValue)
                }
                disabled={isSubmitting}
                icon="plus"
                content="New Mastery Level"
                type="button"
              />
            </div>
          </FlexContainer>
          <VerticalGap height="1rem" />
          {shouldShowEditButtons(values) && (
            <>
              <Button
                color="red"
                content="Cancel"
                icon="x"
                onClick={() => handleCancelButtonClicked(values, setValues)}
                disabled={isSubmitting}
                type="button"
              />
              <Button
                color="blue"
                type="submit"
                content={values.isDraft ? "Create Mastery Level Scheme" : "Save"}
                icon={values.isDraft ? "plus" : "checkmark"}
                disabled={objectSize(errors) > 0}
                floated="right"
                loading={isSubmitting}
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default MasteryLevelSchemeCard;
