import { User } from "../../../../../models/User";

interface RosterDataWidgetProps {
  roster: User[];
  courseRolesToAccept: string[];
  courseRolesToIgnore?: string[];
  label: string;
}

const RosterDataWidget: React.FC<RosterDataWidgetProps> = ({
  courseRolesToAccept,
  courseRolesToIgnore,
  label,
  roster,
}) => {
  const filteredRoster = roster
    .map((u) => u.courseRole ?? "")
    .filter((role) => courseRolesToAccept.includes(role))
    .filter((role) => !courseRolesToIgnore || !courseRolesToIgnore.includes(role));

  if (filteredRoster.length === 0) return <></>;

  return (
    <div className="RosterDataWidget">
      <strong className="count">{filteredRoster.length}</strong>{" "}
      <span>{`${label}${filteredRoster.length > 1 ? "s" : ""}`}</span>
    </div>
  );
};

export default RosterDataWidget;
