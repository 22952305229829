import { Header } from "semantic-ui-react";

const GradeRecipesSettingsFormHeader: React.FC = () => (
  <div>
    <Header as="h3" content="Grade Recipes" />
    <p>
      The grade recipes are combinations of Grade Ingredients that determine which Grade Distinction
      should be given to a student, based on their set of assessments.
    </p>
    <Header as="h3" content="Create and Update Grade Recipes" />
  </div>
);

export default GradeRecipesSettingsFormHeader;
