import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Label, Table } from "semantic-ui-react";
import useValidParams from "../../../../../../hooks/useValidParameters";
import { Assignment } from "../../../../../../models/Assignment";
import { GradeDistinction } from "../../../../../../models/GradeDistinction";
import { GradeRecipeConstraint } from "../../../../../../models/GradeRecipeConstraint";
import { GradeRecipeIngredient } from "../../../../../../models/GradeRecipeIngredient";
import { ObjectiveConstraint } from "../../../../../../models/ObjectiveConstraint";
import { RatedObjective } from "../../../../../../models/RatedObjective";
import { useStore } from "../../../../../../stores/store";
import {
  AssignmentRatingsMappedToMasteryLevel,
  RecoupledAssignmentRatings,
  gradeCalculationsTableCellClass,
  recoupleRatedAssignments,
  studentGradeDistinctionClassName,
} from "../../../../../../utilities/gradeCalculationUtils";
import "./GradeCalculationsTable.css";
import GradeCalculationsTableRow from "./GradeCalculationsTableRow";

interface GradeCalculationsTableProps {
  studentID: string;
}

/**
 * Displays the constraints and thresholds for earning grade distinctions.
 */
const GradeCalculationsTable: React.FC<GradeCalculationsTableProps> = ({ studentID }) => {
  const { gradeStore, ratingStore, objectiveStore, courseStore, gradeDistinctionStore } =
    useStore();
  // eslint-disable-next-line prefer-const
  let { courseID } = useValidParams<{ courseID: string; userID: string }>();

  const [expandedRowID, setExpandedRowID] = useState<string>("");

  const {
    getGradeObjectiveConstraintsForCourse,
    loadGradeObjectiveConstraintsForCourse,
    gradeCalculationsForSpecificStudents,
    loadGradeCalculationsForSpecificStudents,
    hasLoadedGradeCalculationsForSpecificStudents,
  } = gradeStore;

  const {
    gradeDistinctionsForCourse,
    loadGradeDistinctionsForCourse,
    hasLoadedGradeDistinctionsForCourse,
  } = gradeDistinctionStore;

  const {
    ratingsForAllAssignmentsForSpecificStudents,
    loadRatingsForAllAssignmentsForSpecificStudents,
  } = ratingStore;

  const { allObjectives, loadAllObjectives } = objectiveStore;
  const { course } = courseStore;

  useEffect(() => {
    loadAllObjectives(courseID);
  }, [courseID, allObjectives, loadAllObjectives]);

  useEffect(() => {
    loadRatingsForAllAssignmentsForSpecificStudents([studentID], courseID, true);
  }, [
    studentID,
    courseID,
    loadRatingsForAllAssignmentsForSpecificStudents,
    ratingsForAllAssignmentsForSpecificStudents,
  ]);

  useEffect(() => {
    loadGradeCalculationsForSpecificStudents(courseID, [studentID]);
  }, [courseID, studentID, loadGradeCalculationsForSpecificStudents]);

  useEffect(() => {
    loadGradeObjectiveConstraintsForCourse(courseID);
  }, [courseID, loadGradeObjectiveConstraintsForCourse]);

  useEffect(() => {
    loadGradeDistinctionsForCourse(courseID);
  }, [courseID, loadGradeDistinctionsForCourse]);

  const gradeObjectiveConstraints = getGradeObjectiveConstraintsForCourse();

  // if everything is not loaded, return loading component
  if (
    !hasLoadedGradeCalculationsForSpecificStudents ||
    !ratingsForAllAssignmentsForSpecificStudents
  )
    return <></>;

  let recoupledAssignmentRatings: RecoupledAssignmentRatings | undefined;
  let assignmentToMasteryLevelSchemeRatings:
    | Map<string, Map<string, AssignmentRatingsMappedToMasteryLevel>>
    | undefined;
  let parentObjectiveToAssignmentMap: Map<string, Assignment<RatedObjective>[]> | undefined;
  let gradeDistinctionForStudent: GradeDistinction | undefined;

  if (ratingsForAllAssignmentsForSpecificStudents && allObjectives) {
    // perform some grade calculations
    recoupledAssignmentRatings = recoupleRatedAssignments(
      studentID,
      ratingsForAllAssignmentsForSpecificStudents,
      allObjectives
    );

    assignmentToMasteryLevelSchemeRatings =
      recoupledAssignmentRatings.assignmentToMasteryLevelSchemeRatings;
    parentObjectiveToAssignmentMap = recoupledAssignmentRatings.parentObjectiveToAssignmentMap;

    // get the student's current grade distinction
    gradeDistinctionForStudent =
      gradeCalculationsForSpecificStudents?.get(studentID)?.gradeDistinction;
  }

  // if everything is not loaded, return loading component
  if (
    !hasLoadedGradeDistinctionsForCourse ||
    !allObjectives ||
    gradeObjectiveConstraints === undefined
  )
    return <></>;

  const defaultGradeDistinction =
    gradeDistinctionsForCourse && gradeDistinctionsForCourse.length > 0
      ? gradeDistinctionsForCourse[gradeDistinctionsForCourse.length - 1]
      : undefined;

  // gets the first row of the table where the grade distinctions are listed
  const titleRow = (gradeObjectiveConstraint: ObjectiveConstraint) => (
    <Table.Row key={gradeObjectiveConstraint.objective.id}>
      <Table.Cell>
        <Label ribbon className={gradeObjectiveConstraint.objective.color} data-print-id="color">
          {gradeObjectiveConstraint.objective.shortName}
        </Label>
      </Table.Cell>
      {gradeObjectiveConstraint.gradeRecipeConstraints[0]?.gradeRecipeIngredients.map(
        (gr: GradeRecipeIngredient) => (
          <Table.Cell
            key={gr.id}
            className={gradeCalculationsTableCellClass(
              false,
              -1,
              gr.gradeDistinction,
              gradeObjectiveConstraint.objective,
              gradeDistinctionForStudent
            )}
          />
        )
      )}
      <Table.Cell
        key={`${gradeObjectiveConstraint.objective.id}_titleRow`}
        // prevent the default column in title rows from thinking that it is earned,
        // because only ingredient rows can be earned and this is a title row
        className={
          gradeCalculationsTableCellClass(
            false,
            -1,
            defaultGradeDistinction,
            gradeObjectiveConstraint.objective
          ) === "" || !studentID
            ? ""
            : studentGradeDistinctionClassName
        }
      />
    </Table.Row>
  );

  // gets the (mostly empty) row that announces a new objective
  const headingsRow = (gradeObjectiveConstraint: ObjectiveConstraint) => (
    <Table.Row
      key={
        gradeObjectiveConstraint.objective.id +
        gradeObjectiveConstraint.gradeRecipeConstraints.length
      }
    >
      <>
        <Table.Cell textAlign="right">To earn a(n)...</Table.Cell>
        {gradeObjectiveConstraint.gradeRecipeConstraints[0]?.gradeRecipeIngredients.map(
          (i: GradeRecipeIngredient) => (
            <Table.Cell
              key={i.id}
              className={gradeCalculationsTableCellClass(
                false,
                -1,
                i.gradeDistinction,
                gradeObjectiveConstraint.objective,
                gradeDistinctionForStudent
              )}
            >
              <Label className={course?.color}>{i.gradeDistinction?.name}</Label>
            </Table.Cell>
          )
        )}
        <Table.Cell
          key={`${gradeObjectiveConstraint.objective.id}_defaultGrade`}
          className={gradeCalculationsTableCellClass(
            false,
            -1,
            defaultGradeDistinction,
            gradeObjectiveConstraint.objective,
            gradeDistinctionForStudent
          )}
        >
          <Label className={course?.color}>
            {defaultGradeDistinction && defaultGradeDistinction.name}
          </Label>
        </Table.Cell>
      </>
    </Table.Row>
  );

  const setCollapsed = (gradeRecipeConstraintID: string) => {
    setExpandedRowID(expandedRowID === gradeRecipeConstraintID ? "" : gradeRecipeConstraintID);
  };

  return (
    <div className="GradeCalculationsTable">
      <div className="table-container">
        <Table unstackable={true}>
          <Table.Body>
            {[...gradeObjectiveConstraints]
              .sort((a, b) => a.objective.shortName.localeCompare(b.objective.shortName))
              .map((gradeObjectiveConstraint: ObjectiveConstraint) => (
                <React.Fragment key={gradeObjectiveConstraint.objective.id}>
                  {gradeObjectiveConstraint === gradeObjectiveConstraints[0] &&
                    headingsRow(gradeObjectiveConstraint)}
                  {titleRow(gradeObjectiveConstraint)}
                  {gradeObjectiveConstraint.gradeRecipeConstraints.map(
                    (gradeRecipeConstraint: GradeRecipeConstraint) => (
                      <GradeCalculationsTableRow
                        key={`${studentID} ${gradeRecipeConstraint.id}`}
                        studentID={studentID}
                        gradeObjectiveConstraint={gradeObjectiveConstraint}
                        gradeRecipeConstraint={gradeRecipeConstraint}
                        defaultGradeDistinction={defaultGradeDistinction}
                        assignmentToMasteryLevelSchemeRatings={
                          assignmentToMasteryLevelSchemeRatings
                        }
                        gradeDistinctionForStudent={gradeDistinctionForStudent}
                        parentObjectiveToAssignmentMap={parentObjectiveToAssignmentMap}
                        allObjectives={allObjectives}
                        collapsed={expandedRowID !== gradeRecipeConstraint.id}
                        setCollapsed={setCollapsed}
                      />
                    )
                  )}
                </React.Fragment>
              ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default observer(GradeCalculationsTable);
