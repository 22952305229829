// eslint-disable-next-line import/no-cycle
import api, { ErrorHandlerPackage } from "../api/api";
import { Page } from "../models/Page";
import { StoreValue } from "./storeValue";

export default class PagesStore {
  private pageRegistry = new StoreValue<Page, { pageID: string; courseID: string }>();

  get page() {
    return this.pageRegistry.value;
  }

  hasLoadedPage = (pageID: string, courseID: string) =>
    this.pageRegistry.fresh(false, { pageID, courseID });

  reset = () => {
    this.pageRegistry.reset();
  };

  createOrUpdate = async (page: Page, errorhandlerPackage?: ErrorHandlerPackage) => {
    const returnedPage = await api.Pages.createOrUpdate(page, errorhandlerPackage);

    if (returnedPage) this.pageRegistry.setAll(page, { pageID: page.id, courseID: page.courseID });

    return returnedPage;
  };

  loadPage = async (
    pageID: string,
    courseID: string,
    errorhandlerPackage?: ErrorHandlerPackage
  ) => {
    if (this.pageRegistry.fresh(true, { pageID, courseID })) return undefined;

    this.pageRegistry.setLoading(true, { pageID, courseID });

    const page = await api.Pages.details(pageID, courseID, errorhandlerPackage);

    this.pageRegistry.setAll(page, { pageID: page.id, courseID: page.courseID });

    this.pageRegistry.setLoading(false);

    return page;
  };
}
