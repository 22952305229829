import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Header, Loader } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../../hooks/useCurrentUserCourseRole";
import { useStore } from "../../../../../stores/store";
import HorizontalIndent from "../../../../_common/style/spacing/HorizontalIndent";
import UserInputDisplay from "../../../../_common/textEditing/UserInputDisplay";
import "./PageView.css";

interface PageViewProps {
  pageID: string;
  courseID: string;
  excludeHeader?: boolean;
}

const PageView: React.FC<PageViewProps> = ({ pageID, courseID, excludeHeader }) => {
  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);
  const { pagesStore, userStore } = useStore();
  const { createOrUpdate, page, loadPage, hasLoadedPage } = pagesStore;
  const { user } = userStore;

  useEffect(() => {
    loadPage(pageID, courseID);
  }, [page, loadPage, pageID, courseID]);

  if (!page || !hasLoadedPage(pageID, courseID)) return <Loader inline content="Loading page..." />;

  return (
    <div className="PageView">
      {!excludeHeader && <Header as="h3" className="page-header" content={page.name} />}
      <HorizontalIndent>
        <UserInputDisplay
          editable={isUserTeachingTeam}
          content={page.pageContent}
          placeholder="Enter course details here..."
          onSave={async (newContent) => {
            await createOrUpdate({
              id: pageID,
              courseID,
              isDraft: page.isDraft,
              name: page.name,
              pageContent: newContent,
            });
          }}
          editButtonContent="Edit Page Content"
          allowFileUploads
          fileConnectedComponentID={pageID}
          fileUploadCategory="page"
          fileUserID={user?.userID}
        />
      </HorizontalIndent>
    </div>
  );
};

export default observer(PageView);
