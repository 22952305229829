import RoundedSegment from "../components/_common/style/RoundedSegment";
import ViewInstructorGradebookForCourse from "../components/courses/[courseID]/gradebook/ViewInstructorGradebookForCourse";

const CourseHomeCourseHomeGradebookTab: React.FC = () => (
  <RoundedSegment>
    <ViewInstructorGradebookForCourse />
  </RoundedSegment>
);

export default CourseHomeCourseHomeGradebookTab;
