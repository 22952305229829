import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Header } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../../hooks/useValidParameters";
import { useStore } from "../../../../../stores/store";
import LoadingComponent from "../../../../../utilities/routing/components/LoadingComponent";
import FlexContainer from "../../../../_common/style/FlexContainer";
import HorizontalIndent from "../../../../_common/style/spacing/HorizontalIndent";
import CourseObjective from "../../syllabus/CourseObjective";
import CreateOrUpdateObjectiveModal from "./CreateOrUpdateObjectiveModal";

const ObjectiveSettingsForm: React.FC = () => {
  const { courseID } = useValidParams<{ courseID: string }>();
  const { objectiveStore, modalStore } = useStore();
  const {
    hierarchicalObjectivesWithAssignments,
    loadHierarchicalObjectivesWithAssignments,
    hasLoadedHierarchicalObjectivesWithAssignments,
  } = objectiveStore;
  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);

  useEffect(() => {
    loadHierarchicalObjectivesWithAssignments(courseID);
  }, [courseID]);

  if (!hierarchicalObjectivesWithAssignments || !hasLoadedHierarchicalObjectivesWithAssignments())
    return <LoadingComponent content="Loading objectives..." />;

  const handleNewObjectiveButtonClick = () => {
    modalStore.openModal(
      <CreateOrUpdateObjectiveModal courseID={courseID} title="Create Objective" />
    );
  };

  return (
    <div className="ObjectiveSettingsForm">
      <Header as="h3" content={"Objectives"} />
      <p>
        Course objectives (sometimes also called <i>standards</i>) are descriptions of the skills
        and knowledge that instructors expect their students to exhibit at the end of a term. Nearly
        every element of assessment depends on this set of objectives, so it's important to define a
        thorough list.
      </p>
      <Header as="h3" content={"Create and Update Course Objectives"} />
      <Button
        content="Create new objective"
        icon="plus"
        color="blue"
        onClick={handleNewObjectiveButtonClick}
      />
      <HorizontalIndent>
        <FlexContainer flexDirection="column" gap="1rem">
          {hierarchicalObjectivesWithAssignments.map((objective) => (
            <CourseObjective
              objective={objective}
              key={objective.id}
              isEditable={isUserTeachingTeam}
            />
          ))}
        </FlexContainer>
      </HorizontalIndent>
    </div>
  );
};

export default observer(ObjectiveSettingsForm);
