enum Color {
  VIOLET = "violet",
  PURPLE = "purple",
  INDIGO = "indigo",
  BLUE = "blue",
  TEAL = "teal",
  GREEN = "green",
  OLIVE = "olive",
  YELLOW = "yellow",
  ORANGE = "orange",
  BROWN = "brown",
  RED = "red",
  PINK = "pink",
  GREY = "grey",
}

export default Color;

export function getRandomColor(...excludeColors: Color[]) {
  const colors: Color[] = [];

  Object.values(Color)
    .filter((color) => !excludeColors.includes(color))
    .forEach((color) => colors.push(color));

  return colors[Math.floor(Math.random() * colors.length)] ?? Color.GREY;
}
