import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Header, Icon, Label, List } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../../hooks/useValidParameters";
import { useStore } from "../../../../../stores/store";
import { formatDateDownToMinutes } from "../../../../../utilities/dateTimeUtils";
import LoadingComponent from "../../../../../utilities/routing/components/LoadingComponent";
import { createClassName } from "../../../../../utilities/utils";
import FileLister from "../../../../_common/file/FileLister";
import RoundedSegment from "../../../../_common/style/RoundedSegment";
import HorizontalIndent from "../../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import UserInputDisplay from "../../../../_common/textEditing/UserInputDisplay";
import AssignmentSurveyData from "../../_common/AssignmentSurveyData";
import AssignmentSurveyForm from "../../_common/AssignmentSurveyForm";
import CourseObjective from "../../syllabus/CourseObjective";
import "./ViewAssignmentDetails.css";

const StandardAssignmentDetails: React.FC = () => {
  const { assignmentStore, courseStore, userStore, modalStore } = useStore();
  const {
    loadAssignmentWithObjectivesAndMasteryLevels,
    assignmentWithObjectivesAndMasteryLevels,
    hasLoadedAssignmentWithObjectivesAndMasteryLevels,
  } = assignmentStore;
  const { course, courseRoleForCurrentUser } = courseStore;
  const { user } = userStore;
  const { courseID, assignmentID } = useValidParams<{
    courseID: string;
    assignmentID: string;
  }>();
  const { isUserStudent, isUserTeachingTeam } = useCurrentUserCourseRole(courseID);

  useEffect(() => {
    loadAssignmentWithObjectivesAndMasteryLevels(assignmentID, courseID);
  }, [
    assignmentWithObjectivesAndMasteryLevels,
    loadAssignmentWithObjectivesAndMasteryLevels,
    course,
    assignmentID,
    user,
    courseID,
    courseRoleForCurrentUser,
  ]);

  if (!user) return <></>;

  if (
    !assignmentWithObjectivesAndMasteryLevels ||
    !hasLoadedAssignmentWithObjectivesAndMasteryLevels(assignmentID)
  )
    return <LoadingComponent content="Loading Assignment..." />;

  return (
    <>
      {assignmentWithObjectivesAndMasteryLevels.assignmentTypes &&
        assignmentWithObjectivesAndMasteryLevels.assignmentTypes.length > 0 && (
          <>
            <Header as="h3">
              Assignment Type
              {assignmentWithObjectivesAndMasteryLevels.assignmentTypes.length > 1 ? "s" : ""}:
            </Header>
            <HorizontalIndent>
              {assignmentWithObjectivesAndMasteryLevels?.assignmentTypes.map((t) => (
                <Label key={t.id} tag className={course?.color}>
                  {t.name}
                </Label>
              ))}
            </HorizontalIndent>
          </>
        )}
      {assignmentWithObjectivesAndMasteryLevels?.description && (
        <>
          <Header as="h3">Assignment Instructions:</Header>
          <HorizontalIndent>
            <UserInputDisplay content={assignmentWithObjectivesAndMasteryLevels?.description} />
          </HorizontalIndent>
        </>
      )}
      <FileLister
        header={<Header as="h3">Assignment Files:</Header>}
        connectedComponentID={assignmentID}
        courseID={courseID}
        uploadFileCategory={"assignment"}
        hideIfNoFiles
        userID={""}
        showFilePreviewer
      />
      {isUserTeachingTeam && (
        <>
          <Header as="h3" className={"assessment-details-objectives-header"}>
            Objectives:
          </Header>
          {assignmentWithObjectivesAndMasteryLevels?.objectives?.map((o) => (
            <HorizontalIndent key={o.id}>
              <CourseObjective objective={o} cardTypeLabel="Objective to be Assessed" />
            </HorizontalIndent>
          ))}
        </>
      )}
      <Header as="h3">Important Dates:</Header>
      <HorizontalIndent>
        <RoundedSegment
          withBorder
          className={createClassName("assignment-details-due-date", "initial-due-date", {
            name: "date-passed",
            apply:
              assignmentWithObjectivesAndMasteryLevels.dueDate !== undefined &&
              assignmentWithObjectivesAndMasteryLevels.dueDate < new Date(),
          })}
        >
          <Header as="h5">
            <Icon
              name="calendar alternate outline"
              fitted
              circular
              inverted
              className={course?.color}
            />{" "}
            Initial Submissions Due:
          </Header>
          <HorizontalIndent>
            {assignmentWithObjectivesAndMasteryLevels?.dueDate
              ? formatDateDownToMinutes(assignmentWithObjectivesAndMasteryLevels.dueDate)
              : ""}
          </HorizontalIndent>
        </RoundedSegment>
        <>
          {isUserTeachingTeam &&
            assignmentWithObjectivesAndMasteryLevels?.acceptSubmissionsUntilDate && (
              <RoundedSegment
                withBorder
                className={createClassName("assignment-details-due-date", "late-due-date", {
                  name: "date-passed",
                  apply:
                    assignmentWithObjectivesAndMasteryLevels?.acceptSubmissionsUntilDate <
                    new Date(),
                })}
              >
                <Header as="h5">
                  <Icon name="stopwatch" fitted circular inverted className={course?.color} /> Late
                  Initial Submissions Due:
                </Header>
                <HorizontalIndent>
                  {formatDateDownToMinutes(
                    assignmentWithObjectivesAndMasteryLevels.acceptSubmissionsUntilDate
                  )}
                </HorizontalIndent>
              </RoundedSegment>
            )}
        </>
        <>
          {assignmentWithObjectivesAndMasteryLevels?.resubmissionDueDate && (
            <RoundedSegment
              withBorder
              className={createClassName(
                "assignment-details-due-date",
                "reassessment-request-due-date",
                {
                  name: "date-passed",
                  apply: assignmentWithObjectivesAndMasteryLevels.resubmissionDueDate < new Date(),
                }
              )}
            >
              <Header as="h5">
                <Icon name="redo" fitted circular inverted className={course?.color} /> Reassessment
                Requests Due:
              </Header>
              <HorizontalIndent>
                {formatDateDownToMinutes(
                  assignmentWithObjectivesAndMasteryLevels.resubmissionDueDate
                )}
              </HorizontalIndent>
            </RoundedSegment>
          )}
        </>
      </HorizontalIndent>
      <Header as="h3">Acceptable Submission Formats:</Header>
      <VerticalGap height="0.25em" />
      <HorizontalIndent>
        <List className="submission-formats">
          {!!assignmentWithObjectivesAndMasteryLevels.allowedSubmissionFormat?.includes("Text") && (
            <List.Item>
              <List.Icon name="write" />
              <List.Content>Freeform Text</List.Content>
            </List.Item>
          )}
          {!!assignmentWithObjectivesAndMasteryLevels.allowedSubmissionFormat?.includes(
            "FileUploads"
          ) && (
            <List.Item>
              <List.Icon name="upload" />
              <List.Content>Uploaded Files</List.Content>
            </List.Item>
          )}
          {!!assignmentWithObjectivesAndMasteryLevels.allowedSubmissionFormat?.includes("URL") && (
            <List.Item>
              <List.Icon name="linkify" />
              <List.Content>Web Link</List.Content>
            </List.Item>
          )}
          {!!assignmentWithObjectivesAndMasteryLevels.allowedSubmissionFormat?.includes(
            "NoSubmission"
          ) && (
            <List.Item>
              <List.Icon name="dont" />
              <List.Content>No Submission</List.Content>
            </List.Item>
          )}
        </List>
      </HorizontalIndent>
      {assignmentWithObjectivesAndMasteryLevels.requestAssignmentSurvey && (
        <>
          <Header as="h3">Post-Assignment Survey</Header>
          <HorizontalIndent>
            {isUserStudent ? (
              <>
                Your instructor requests that you fill out a survey about the quality of this
                assignment.
                <br />
                <Button
                  className="launchSurvey"
                  onClick={() =>
                    modalStore.openModal(<AssignmentSurveyForm />, { showCloseIcon: true })
                  }
                >
                  <Icon name="thumbs down" size="large" flipped="horizontally" />
                  <Icon name="thumbs up" size="large" />
                  <br />
                  Provide Feedback
                </Button>
              </>
            ) : (
              <>
                You have requested that students fill out a survey about the quality of this
                assignment.
                <br />
                <Button
                  className="launchSurvey"
                  onClick={() =>
                    modalStore.openModal(<AssignmentSurveyData />, { showCloseIcon: true })
                  }
                >
                  <Icon name="thumbs down" size="large" flipped="horizontally" />
                  <Icon name="thumbs up" size="large" />
                  <br />
                  View Feedback
                </Button>
              </>
            )}
            <VerticalGap height="1em" />
          </HorizontalIndent>
        </>
      )}
    </>
  );
};

export default observer(StandardAssignmentDetails);
