interface BlueLoadingLogoProps {
  width?: string;
}

const BlueLoadingLogo: React.FC<BlueLoadingLogoProps> = ({ width = "200px" }) => (
  <svg
    version="1.1"
    id="TeachFrontLogo"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    xmlSpace="preserve"
    data-print-id="hide"
    viewBox="0 -125 1000 1000"
    width={width}
  >
    <g>
      <path
        fill="#4382c6"
        d="M133.4,218.2c-11.6-17.7-21.9-26.7-30.7-26.7c-16,0.1-37,27.6-63.5,68.9c-20.3,31.6-28.4,60.5-23.9,86.2
  c4,22.9,18.1,43.6,41.9,61.4l395.1,296.4L133.4,218.2z"
        style={{ fill: `var(--logo-color, #4382c6` }}
      />
      <path
        fill="#4382c6"
        d="M468.1,684.4L309.7,111c-6.6-23.7-14.7-37.5-24.8-42.1l-0.1,0c-3.2-1.5-6.8-2.2-11.1-2.2c-16.4,0-39.8,10.5-68.7,24.2
  c-38.7,18.4-60.3,37.3-66.1,57.9c-4.6,16.1,0.2,34.5,14.4,56.3L468.1,684.4z"
        style={{ fill: `var(--logo-color, #4382c6` }}
      />
      <path
        fill="#4382c6"
        d="M488,666.6V80c0-26-4.2-42.4-12.7-50c-6.4-5.8-16.5-8.4-32.7-8.4c-10.6,0-23.1,1.1-39.9,2.8c-39.3,4-59.9,11.3-69,24.5
  c-7.9,11.5-8.2,29.8-1,55.8L488,666.6z"
        style={{ fill: `var(--logo-color, #4382c6` }}
      />
      <path
        fill="#4382c6"
        d="M597.3,24.3c-16.8-1.7-29.4-2.8-39.9-2.8c-16.2,0-26.3,2.6-32.7,8.4c-8.6,7.7-12.7,24.1-12.7,50v586.5l155.3-561.9
  c7.2-26,6.9-44.2-1-55.8C657.2,35.5,636.6,28.2,597.3,24.3z"
        style={{ fill: `var(--logo-color, #4382c6` }}
      />
      <path
        fill="#4382c6"
        d="M846.5,205c14.2-21.6,18.9-39.9,14.4-56.2c-5.8-20.6-27.4-39.6-66.1-57.9c-28.8-13.7-52.1-24.2-68.7-24.2
  c-4.3,0-8,0.7-11.1,2.2c-10.1,4.6-18.2,18.4-24.7,42.1L532,684.4L846.5,205z"
        style={{ fill: `var(--logo-color, #4382c6` }}
      />
      <path
        fill="#4382c6"
        d="M897.4,191.5L897.4,191.5c-8.9,0-19.2,9-30.7,26.7l-319,486.1l395.1-296.4c23.8-17.8,37.9-38.5,41.9-61.4l0,0
  c4.5-25.6-3.6-54.6-23.9-86.1C934.2,219.1,913.3,191.6,897.4,191.5z"
        style={{ fill: `var(--logo-color, #4382c6` }}
      />
    </g>
  </svg>
);

export default BlueLoadingLogo;
