import copy from "copy-to-clipboard";
import { FC } from "react";
import { Button, Input } from "semantic-ui-react";
import { useStore } from "../../../stores/store";
import "./CopyableReadonlyField.css";

interface CopyableReadonlyFieldProps {
  value: string;
}

const CopyableReadonlyField: FC<CopyableReadonlyFieldProps> = ({ value }) => {
  const { toastStore } = useStore();
  const { showToast } = toastStore;

  const handleCopy = () => {
    copy(value);
    showToast("Copied to clipboard!", {
      color: "green",
    });
  };

  return (
    <Input
      className="CopyableReadonlyField"
      action={<Button type="button" icon="copy" content="Copy" onClick={handleCopy} />}
      value={value}
      readOnly
    />
  );
};

export default CopyableReadonlyField;
