import { observer } from "mobx-react-lite";
import AuthenticatedLandingPage from "../components/AuthenticatedLandingPage";
import UnauthenticatedLandingPage from "../components/UnauthenticatedLandingPage";
import { useStore } from "../stores/store";
import { AppSkeleton } from "../App";

const Landing: React.FC = () => {
  const { userStore } = useStore();
  const { user, loading } = userStore;

  if (loading) {
    return <AppSkeleton />;
  }

  if (!user) {
    return <UnauthenticatedLandingPage />;
  }

  return <AuthenticatedLandingPage />;
};

export default observer(Landing);
