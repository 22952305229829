// Checks if the form is dirty, meaning if it has been edited at all.

import { FormikValues } from "formik";
import { deepCompareValues, objectSome } from "./collectionUtils";

// This will only return true if no values are empty and values differ from the initial values.
// eslint-disable-next-line import/prefer-default-export
export function isFormDirty<T extends FormikValues>(
  initialValues: T,
  currentValues: T,
  keysToOmit?: (keyof T)[]
) {
  return objectSome(currentValues, (key, value) => {
    // if this is a key we should ignore, the key is not dirty
    if (keysToOmit && keysToOmit?.includes(key as keyof T)) return false;

    // get the initial value of the form
    const initialValue = initialValues[key as string];

    // if there is a current value and an initial value and they are not the same, the form is dirty at this key
    if (value && initialValue && !deepCompareValues(value, initialValue)) return true;

    // if there is a current value but there wasn't an initial value, the form is dirty at this key
    if (value && !initialValue) return true;

    // if there is not a current value but there is an initial value, the form is dirty at this key
    if (!value && initialValue) return true;

    // the form is not dirty
    return false;
  });
}

export type FormikSetFieldValueFunction = (
  field: string,
  value: unknown,
  shouldValidate?: boolean | undefined
) => void;

export type FormikSetFieldTouchedFunction = (
  field: string,
  isTouched?: boolean | undefined,
  shouldValidate?: boolean | undefined
) => unknown;

export type FormikSetValuesFunction<T> = (
  values: T,
  shouldValidate?: boolean | undefined
) => unknown;

export const cleanCommentText = (commentText: string) => {
  // SOURCE: ChatGPT

  // // HTML markup
  const htmlMarkup = commentText;

  // Create a temporary element
  const tempElement = document.createElement("div");

  // Set the HTML content of the temporary element
  tempElement.innerHTML = htmlMarkup;

  // Get the text content of the temporary element
  const { textContent } = tempElement;

  // If you want to remove whitespace and newline characters
  const cleanTextContent = textContent?.replace(/\s+/g, " ").trim();

  // END SOURCE
  if (cleanTextContent === "undefined" || cleanTextContent === "") return undefined; // don't allow pac with only whitespace

  const extraNewline = "<p><br></p>";

  const extraListItem = "<li><br></li>";

  const unorderedList = "</ul>";

  const orderedList = "</ol>";

  if (commentText.endsWith(extraNewline)) return commentText.slice(0, -extraNewline.length); // remove extra newline at end of pac

  if (commentText.endsWith(extraListItem + unorderedList))
    return commentText.slice(0, -(extraListItem.length + unorderedList.length)) + unorderedList; // remove extra bullet point at end of pac

  if (commentText.endsWith(extraListItem + orderedList))
    return commentText.slice(0, -(extraListItem.length + orderedList.length)) + orderedList; // remove extra number-bullet at end of pac

  return commentText;
};
