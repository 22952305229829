import React, { LegacyRef } from "react";

const Toolbar = React.forwardRef((_, ref) => (
  <div ref={ref as LegacyRef<HTMLDivElement> | undefined}>
    <span className="ql-formats">
      <button type="button" className="ql-bold" />
      <button type="button" className="ql-italic" />
      <button type="button" className="ql-underline" />
      <button type="button" className="ql-blockquote" />
      <button type="button" className="ql-link" />
      <button type="button" className="ql-align" value="" />
      <button type="button" className="ql-align" value="center" />
      <button type="button" className="ql-align" value="right" />
      <button type="button" className="ql-align" value="justify" />
      <button type="button" className="ql-list" value="ordered" />
      <button type="button" className="ql-list" value="bullet" />
      <select className="ql-color" />
      <select className="ql-background" />
      <select className="ql-header" defaultValue="0">
        <option value="3">Title</option>
        <option value="4">Subtitle</option>
        <option value="0">Text</option>
      </select>
      <button type="button" className="ql-clean" />
    </span>
  </div>
));

export default Toolbar;
