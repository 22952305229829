// eslint-disable-next-line import/no-cycle
import api, { ErrorHandlerPackage } from "../api/api";
import { GradeRecipeIngredient } from "../models/GradeRecipeIngredient";

import { StoreValue } from "./storeValue";

const cleanGradeRecipeIngredientForDatabaseCall = (constraint: GradeRecipeIngredient) => {
  const copy: GradeRecipeIngredient = { ...constraint };

  delete copy.gradeDistinction;
  delete copy.gradeRecipeConstraint;

  return copy;
};

export default class GradeRecipeIngredientStore {
  private gradeRecipeIngredientsRegistry = new StoreValue<
    GradeRecipeIngredient[],
    { courseID: string }
  >();

  hasLoadedGradeRecipeIngredients = (courseID: string) =>
    this.gradeRecipeIngredientsRegistry.fresh(false, { courseID });

  get gradeRecipeIngredients() {
    return this.gradeRecipeIngredientsRegistry.value;
  }

  reset = () => {
    this.gradeRecipeIngredientsRegistry.reset();
  };

  loadGradeRecipeIngredients = async (
    courseID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    if (this.gradeRecipeIngredientsRegistry.fresh(true, { courseID })) return;

    this.gradeRecipeIngredientsRegistry.setLoading(true, { courseID });

    const result = await api.GradeRecipeIngredients.listForCourse(courseID, errorHandlerPackage);

    this.gradeRecipeIngredientsRegistry.setAll(result, { courseID });

    this.gradeRecipeIngredientsRegistry.setLoading(false);
  };

  deleteGradeRecipeIngredients = async (
    courseID: string,
    ingredients: GradeRecipeIngredient[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.GradeRecipeIngredients.delete(
      courseID,
      ingredients.map(cleanGradeRecipeIngredientForDatabaseCall),
      errorHandlerPackage
    );

    if (result) {
      const deletedIngredientIDs = ingredients.map(({ id }) => id);
      const newIngredients = [...(this.gradeRecipeIngredientsRegistry.value ?? [])].filter(
        ({ id }) => !deletedIngredientIDs.includes(id)
      );

      this.gradeRecipeIngredientsRegistry.setAll(newIngredients, { courseID });
    }
  };

  createOrUpdateGradeRecipeIngredients = async (
    courseID: string,
    ingredients: GradeRecipeIngredient[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.GradeRecipeIngredients.createOrUpdate(
      courseID,
      ingredients.map(cleanGradeRecipeIngredientForDatabaseCall),
      errorHandlerPackage
    );

    if (result) {
      const newIngredients = [...(this.gradeRecipeIngredientsRegistry.value ?? [])];

      result.forEach((newIngredient) => {
        const index = newIngredients.findIndex(({ id }) => id === newIngredient.id);

        if (index === -1) newIngredients.push(newIngredient);
        else newIngredients[index] = newIngredient;
      });

      this.gradeRecipeIngredientsRegistry.setAll(newIngredients, { courseID });
    }
  };
}
