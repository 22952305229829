import { observer } from "mobx-react-lite";
import { Image } from "semantic-ui-react";
import { Course } from "../../models/Course";
import "./CourseLogo.css";

interface CourseLogoProps {
  course: Course | undefined;
}

const CourseLogo: React.FC<CourseLogoProps> = ({ course }) =>
  course?.backgroundPhotoBlobLink ? (
    <div className="course-logo course-background-photo">
      <Image src={course?.backgroundPhotoBlobLink} />
      <div className="color-mask ui label" />
    </div>
  ) : (
    <svg
      version="1.1"
      id="course-logo"
      className="course-logo"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      xmlSpace="preserve"
      viewBox="0 -25 1100 1100"
    >
      <g>
        <rect x="0" y="-25" width="1100" height="1100" fill="#fff" />
        <path
          fill="#000"
          d="M152.7,329C152.7,329,152.7,329,152.7,329c-19.5,0.1-40.4,26.6-69.8,72.3c-21.4,33.2-29.8,64-25,91.5
		c4.3,24.8,19.4,47.1,44.8,66.1L532.1,881L189.7,359.1C176.4,338.8,164.3,329,152.7,329z"
        />
        <path
          fill="#000"
          d="M338,207.1c-4.3-2-9-2.9-14.3-2.9c-17.7,0-41.1,10.3-71.9,24.9c-40.7,19.3-63.7,39.8-70.1,62.7
		c-5.2,18.4-0.1,38.8,15.4,62.4L538,873.5L366.9,254C359.6,227.6,350.4,212.7,338,207.1z"
        />
        <path
          fill="#000"
          d="M545.5,225c0-28.6-4.8-46.3-15.2-55.6c-8.5-7.7-20.8-10.3-37.7-10.3c-11.4,0-24.9,1.2-40.7,2.8
		c-41.7,4.2-63.9,12.4-74.4,27.7c-9.2,13.4-9.9,33.7-2,62.1l170,615.2V225z"
        />
        <path
          fill="#000"
          d="M722.5,189.5c-10.5-15.3-32.7-23.5-74.4-27.7c-15.8-1.6-29.3-2.8-40.7-2.8c-16.9,0-29.2,2.6-37.7,10.3
		c-10.4,9.4-15.2,27.1-15.2,55.6v641.8l170-615.2C732.3,223.2,731.6,202.9,722.5,189.5z"
        />
        <path
          fill="#000"
          d="M918.2,291.8c-6.4-22.9-29.4-43.4-70.1-62.7c-30.7-14.6-54.1-24.9-71.9-24.9c-5.2,0-10,0.9-14.3,2.9
		c-12.4,5.7-21.5,20.6-28.8,46.9L562,873.5l340.8-519.4C918.3,330.6,923.3,310.2,918.2,291.8z"
        />
        <path
          fill="#000"
          d="M1042.1,492.8c4.8-27.5-3.6-58.3-25-91.5c-29.4-45.7-50.2-72.2-69.7-72.3c0,0-0.1,0-0.1,0
		c-11.6,0-23.7,9.8-36.9,30.1L567.9,881l429.4-322.1C1022.7,539.9,1037.8,517.6,1042.1,492.8z"
        />
      </g>
    </svg>
  );

export default observer(CourseLogo);
