import { Button, Header } from "semantic-ui-react";
import { useStore } from "../../stores/store";
import useToast from "../../hooks/useToast";
import VerticalGap from "../_common/style/spacing/VerticalGap";
import FlexContainer from "../_common/style/FlexContainer";
import "./PublishModal.css";
import useBooleanState from "../../hooks/useBooleanState";

interface PublishModalProps {
  courseID: string;
  assignmentID: string;
  assignmentName: string;
  then?: () => void;
  disableToast?: boolean;
  submissionIDs?: string[];
}

const PublishModal: React.FC<PublishModalProps> = ({
  courseID,
  assignmentID,
  assignmentName,
  disableToast,
  submissionIDs,
  then,
}) => {
  const { ratingStore, modalStore, confettiStore } = useStore();
  const setToast = useToast();
  const [publishing, setPublishing] = useBooleanState();

  const publishRatings = (notify: boolean) => {
    setPublishing(true);
    ratingStore.publishRatings(courseID, assignmentID, notify, submissionIDs).then((anyUpdated) => {
      setPublishing(false);

      if (then) then();

      if (disableToast) {
        return;
      }

      if (anyUpdated) {
        setToast(`Published all ratings for assignment: ${assignmentName}`, "green");
        confettiStore.addConfetti();
      } else {
        setToast(`All ratings for assignment: ${assignmentName} were already published.`, "orange");
      }
    });

    modalStore.closeModal();
  };

  return (
    <div className="PublishModal">
      <Header as="h3" content="Publish Ratings" />
      <span className="description">
        Choose whether to notify students of their updated assessment. Opting to notify students
        will send an email to their inbox communicating that their assessment has been updated.
        Publishing silently will update the assessment, but no notification will be sent.
      </span>
      <VerticalGap height="1rem" />
      <FlexContainer justifyContent="space-between" flexWrap="wrap">
        <Button
          color="grey"
          content="Publish And Notify Student(s)"
          onClick={() => publishRatings(true)}
          icon="mail"
          loading={publishing}
        />
        <Button
          color="blue"
          content="Publish Silently"
          onClick={() => publishRatings(false)}
          icon="spy"
          loading={publishing}
        />
      </FlexContainer>
    </div>
  );
};

export default PublishModal;
