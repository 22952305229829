import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import useValidParams from "../../../../../../hooks/useValidParameters";
import { useStore } from "../../../../../../stores/store";
import { getMapValues } from "../../../../../../utilities/collectionUtils";
import FlexContainer from "../../../../../_common/style/FlexContainer";
import HorizontalIndent from "../../../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../../../_common/style/spacing/VerticalGap";
import GradeIngredientsFormHeader from "./GradeIngredientsFormHeader";
import "./GradeIngredientsSettingsForm.css";
import ObjectiveGradeIngredientForm, {
  ObjectiveGradeIngredientPlaceholder,
} from "./ObjectiveGradeIngredientForm";

const GradeIngredientsSettingsFormSkeleton: React.FC = () => (
  <div>
    <GradeIngredientsFormHeader />
    <VerticalGap height="1rem" />
    <FlexContainer flexDirection="column" gap="1rem">
      <ObjectiveGradeIngredientPlaceholder />
      <ObjectiveGradeIngredientPlaceholder />
      <ObjectiveGradeIngredientPlaceholder />
      <ObjectiveGradeIngredientPlaceholder />
      <ObjectiveGradeIngredientPlaceholder />
    </FlexContainer>
  </div>
);

const GradeIngredientsSettingsForm: React.FC = () => {
  const { courseID } = useValidParams<{ courseID: string }>();
  const {
    objectiveStore,
    gradeRecipeIngredientStore,
    gradeRecipeConstraintStore,
    gradeDistinctionStore,
  } = useStore();
  const { hierarchicalObjectives, loadObjectivesHierarchical, hasLoadedHierarchicalObjectives } =
    objectiveStore;
  const { gradeRecipeIngredients, loadGradeRecipeIngredients, hasLoadedGradeRecipeIngredients } =
    gradeRecipeIngredientStore;
  const { gradeRecipeConstraints, loadGradeRecipeConstraints, hasLoadedGradeRecipeConstraints } =
    gradeRecipeConstraintStore;
  const {
    gradeDistinctionsForCourse,
    loadGradeDistinctionsForCourse,
    hasLoadedGradeDistinctionsForCourse,
  } = gradeDistinctionStore;

  useEffect(() => {
    loadObjectivesHierarchical(courseID);
    loadGradeRecipeConstraints(courseID);
    loadGradeRecipeIngredients(courseID);
    loadGradeDistinctionsForCourse(courseID);
  }, [courseID, gradeRecipeIngredients]);

  if (
    !hierarchicalObjectives ||
    !gradeRecipeConstraints ||
    !gradeRecipeIngredients ||
    !gradeDistinctionsForCourse ||
    !hasLoadedGradeRecipeConstraints(courseID) ||
    !hasLoadedGradeRecipeIngredients(courseID) ||
    !hasLoadedGradeDistinctionsForCourse() ||
    !hasLoadedHierarchicalObjectives()
  )
    return <GradeIngredientsSettingsFormSkeleton />;

  return (
    <div className="GradeIngredientsSettingsForm">
      <GradeIngredientsFormHeader />
      <VerticalGap height="1rem" />
      <HorizontalIndent>
        <FlexContainer gap="1rem" flexDirection="column">
          {getMapValues(hierarchicalObjectives).map((objective) => (
            <ObjectiveGradeIngredientForm
              key={objective.id}
              constraintsForCourse={gradeRecipeConstraints}
              objective={objective}
            />
          ))}
        </FlexContainer>
      </HorizontalIndent>
    </div>
  );
};

export default observer(GradeIngredientsSettingsForm);
