import { Ref, forwardRef } from "react";
import { Header } from "semantic-ui-react";
import logo from "../../../assets/logo.svg";
import "./MissionStatement.css";

const MissionStatement = forwardRef<HTMLDivElement>((props, ref: Ref<HTMLDivElement>) => (
  <div id="MissionStatement" className="MissionStatement" ref={ref}>
    <div className="inner-mission-statement">
      <Header as="h2" className="Header">
        Our Mission
      </Header>
      <div className="Text">
        <p>
          TeachFront is a platform that supports alternative grading systems and encourages
          equitable education.
        </p>
        <p>
          Objective-based feedback and opportunities for resubmissions redirect the focus from
          perfection to progress, modeling our vision of lifelong learning.
        </p>
        <p>
          TeachFront aims to foster an assessment culture that produces curious individuals, rather
          than those that fear failure.
        </p>
        <p className="little-logo">
          <img src={logo} alt="" />
        </p>
      </div>
    </div>
  </div>
));

export default MissionStatement;
