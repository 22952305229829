import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Button, Dropdown, Header } from "semantic-ui-react";
import useBooleanState from "../../../hooks/useBooleanState";
import useValidParams from "../../../hooks/useValidParameters";
import { useStore } from "../../../stores/store";
import { UploadedFileCategory } from "../../../stores/uploadedFileStore";
import { isQuillEmpty } from "../../../utilities/utils";
import DragAndDropFile from "../file/DragAndDropFile";
import FileLister from "../file/FileLister";
import TextEditor from "./TextEditor";
import "./UserInputDisplay.css";

interface UserInputDisplayProps {
  content: string | undefined;
  placeholder?: string;
  invalidContentMessage?: string;
  editable?: boolean;
  allowFileUploads?: boolean;
  fileConnectedComponentID?: string;
  fileUserID?: string;
  fileUploadCategory?: UploadedFileCategory;
  editButtonContent?: string;
  onSave?: (newContent: string) => Promise<void> | void;
  className?: string;
  maxHeight?: string;
  maxWidth?: string;
}

const UserInputDisplay: React.FC<UserInputDisplayProps> = ({
  content,
  placeholder,
  invalidContentMessage,
  editable,
  allowFileUploads,
  fileConnectedComponentID,
  fileUserID,
  fileUploadCategory,
  onSave,
  editButtonContent,
  className = "",
  maxHeight,
  maxWidth,
}) => {
  const { courseID } = useValidParams<{ courseID: string }>();

  const { uploadedFileStore } = useStore();

  const [editing, setEditing] = useBooleanState();
  const [saving, setSaving] = useBooleanState();
  const [currentContent, setCurrentContent] = useState<string>(content ?? "");

  function getModules() {
    return {
      clipboard: { matchVisual: false },
    };
  }

  if (!content && !editing) {
    return (
      <>
        {editable && (
          <Button
            icon="edit"
            content={editButtonContent || "Edit"}
            color="grey"
            data-print-id="hide"
            onClick={() => setEditing(true)}
          />
        )}
        {invalidContentMessage ? <i>{invalidContentMessage}</i> : <></>}
      </>
    );
  }

  return (
    <div
      className={`RichTextDisplay ${className}`}
      style={{
        maxHeight,
        maxWidth,
        overflow: "hidden",
        overflowY: maxHeight ? "auto" : undefined,
      }}
    >
      {!editing && (
        <>
          {editable && (
            <Dropdown
              icon={"ellipsis vertical"}
              className="options"
              data-print-id="hide"
              options={[
                {
                  key: "edit",
                  icon: "edit",
                  content: "Edit",
                  onClick: () => setEditing(true),
                },
              ]}
              direction="left"
            />
          )}
          <ReactQuill
            readOnly={true}
            value={content}
            theme={"bubble"}
            className="text-display"
            modules={getModules()}
          />
          {allowFileUploads && fileConnectedComponentID && fileUserID && fileUploadCategory && (
            <>
              <FileLister
                userID={fileUserID}
                connectedComponentID={fileConnectedComponentID}
                courseID={courseID}
                uploadFileCategory={fileUploadCategory}
                header={<Header as="h4">Syllabus Files:</Header>}
                hideIfNoFiles
                showFilePreviewer
              />
            </>
          )}
        </>
      )}
      {editing && (
        <>
          <TextEditor
            defaultValue={content}
            placeholder={placeholder}
            onChange={(newContent) => setCurrentContent(newContent)}
          />
          {allowFileUploads && fileConnectedComponentID && (
            <>
              <Header as="h4">Relevant Files:</Header>
              <DragAndDropFile
                connectedComponentID={fileConnectedComponentID}
                uploadFileCategory="submission"
                courseID={courseID}
                userID={fileUserID || ""}
                showUploadComponentByDefault={editing}
              />
            </>
          )}
          <Button content="Cancel" icon="x" color="grey" onClick={() => setEditing(false)} />
          <Button
            content="Save"
            color="blue"
            icon="checkmark"
            onClick={async () => {
              if (onSave) {
                setSaving(true);
                await onSave(isQuillEmpty(currentContent) ? "" : currentContent);
                setSaving(false);
              }
              if (onSave && allowFileUploads && fileUploadCategory) {
                uploadedFileStore.resetUploadedFilesCategory(fileUploadCategory);
              }

              setEditing(false);
            }}
            floated="right"
            loading={saving}
          />
        </>
      )}
    </div>
  );
};

export default UserInputDisplay;
