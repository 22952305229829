import { Icon, SemanticICONS } from "semantic-ui-react";
import "./NoValuesFound.css";

interface NoValuesFoundProps {
  iconName: SemanticICONS;
  text: string;
}

const NoValuesFound: React.FC<NoValuesFoundProps> = ({ iconName, text }) => (
  <div className="NoValuesFound">
    <Icon name={iconName} size="big" />
    <span>{text}</span>
  </div>
);

export default NoValuesFound;
