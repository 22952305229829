import useValidParams from "../hooks/useValidParameters";
import PageView from "../components/courses/[courseID]/page/[pageID]/PageView";
import RoundedSegment from "../components/_common/style/RoundedSegment";

const CourseHomePageView: React.FC = () => {
  const { pageID, courseID } = useValidParams<{
    pageID: string;
    courseID: string;
  }>();

  return (
    <RoundedSegment>
      <PageView courseID={courseID} pageID={pageID} />
    </RoundedSegment>
  );
};

export default CourseHomePageView;
