import { PropsWithChildren } from "react";
import { Header } from "semantic-ui-react";
import { createClassName } from "../../../utilities/utils";
import "./PageContainer.css";

interface PageContainerProps {
  header: string;
  subheader?: string;
  className?: string;
}

const PageContainer: React.FC<PropsWithChildren<PageContainerProps>> = ({
  children,
  header,
  className,
  subheader,
}) => (
  <div className={createClassName("PageContainer", className)}>
    <div className="header-banner" data-print-id="hide">
      <Header as="h2" content={header} />
      {subheader && <div className="sub-header">{subheader}</div>}
    </div>
    <div className="content">{children}</div>
  </div>
);

export default PageContainer;
