import { Header } from "semantic-ui-react";
import andy from "../../../assets/andy.jpeg";
import jonathan from "../../../assets/jonathan.jpg";
import kaden from "../../../assets/kaden.jpg";
import lena from "../../../assets/lena.jpg";
import logo from "../../../assets/logo.svg";
import mattVav from "../../../assets/mattVav.jpeg";
import nathan from "../../../assets/nathan.jpg";
import peyton from "../../../assets/peyton.jpg";
import sophia from "../../../assets/sophia.jpg";
import val from "../../../assets/stephanie-valentine.jpg";
import "./MeetTeamFront.css";
import TeamFrontCard from "./TeamFrontCard";

const people = [
  {
    className: "val",
    name: "Dr. Stephanie Valentine",
    role: "Founder",
    photo: val,
  },
  {
    className: "kaden",
    name: "Kaden Semerad",
    role: "Co-Founder",
    photo: kaden,
  },
  {
    className: "nathan",
    name: "Nathan Ray",
    role: "Business Developement Manager",
    photo: nathan,
  },
  {
    className: "lena",
    name: "Lena Lankas",
    role: "Co-Founder",
    photo: lena,
  },
  {
    className: "peyton",
    name: "Peyton Peck",
    role: "Co-Founder",
    photo: peyton,
  },
  {
    className: "sophia",
    name: "Sophia Heinrich",
    role: "Co-Founder",
    photo: sophia,
  },
  {
    className: "jonathan",
    name: "Jonathan Skelton",
    role: "Frontend Developer",
    photo: jonathan,
  },
  {
    className: "andy",
    name: "Andy Giese",
    role: "Co-Founder, Alumni",
    photo: andy,
  },
  {
    className: "mattVav",
    name: "Matt Vavricek",
    role: "Co-Founder, Alumni",
    photo: mattVav,
  },
];

const MeetTeamFront: React.FC = () => (
  <div className="MeetTeamFront">
    <img className="logo" src={logo} alt="" />
    <Header as="h2" className="Title">
      Meet Our Team <br />
      (a.k.a., the TeamFront)
    </Header>
    <div className="Description">
      TeachFront was made by students and teachers for students and teachers
    </div>
    {people.map((person) => (
      <TeamFrontCard
        key={person.name}
        className={person.className}
        name={person.name}
        role={person.role}
        photo={person.photo}
      ></TeamFrontCard>
    ))}
  </div>
);

export default MeetTeamFront;
