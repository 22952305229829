import JSConfetti from "js-confetti";

export default class ConfettiStore {
  jsConfetti: JSConfetti;

  constructor() {
    this.jsConfetti = new JSConfetti();
  }

  addConfetti = () => {
    const date = new Date();
    // adding 1 b/c 0 = January, 1 = February, ..., 11 = December
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    const dayName = date.getDay();

    let emojiList: string[] = [];

    // January
    if (month === 1) {
      // New Year's
      if (day === 1) {
        emojiList = ["🎊", "🪩", "🥂"];
        // Martin Luther King Jr Day
      } else if (day >= 15 && day <= 21 && dayName === 1) {
        emojiList = ["🇺🇸", "⚖️", "🗣️"];
      } else {
        emojiList = ["❄️", "❄️", "❄️"];
      }
    }
    // February
    else if (month === 2) {
      // Groundhog Day
      if (day === 2) {
        emojiList = ["🐿️", "☀️", "🌷"];
        // President's Day
      } else if (day >= 15 && day <= 21 && dayName === 1) {
        emojiList = ["🇺🇸", "🏛️"];
        // Valentine's Day
      } else {
        emojiList = ["❤️", "🍫", "💐"];
      }
    }
    // March
    else if (month === 3) {
      // St. Patrick's Day
      if (day === 17) {
        emojiList = ["🍀", "🌈", "🎩"];
      }
    }
    // April
    else if (month === 4) {
      // April Fools Day
      if (day === 1) {
        emojiList = ["🤡", "🥸", "🤣"];
        // Earth Day
      } else if (day === 22) {
        emojiList = ["🌎", "♻️", "💡"];
      }
    }
    // May
    else if (month === 5) {
      // Cinco De Mayo
      if (day === 5) {
        emojiList = ["🇲🇽", "🪅", "💃🏻"];
        // Mother's Day
      } else if (day >= 8 && day <= 14 && dayName === 0) {
        emojiList = ["🤱🏻", "💐", "💞"];
        // Memorial Day
      } else if (day >= 25 && day <= 31 && dayName === 1) {
        emojiList = ["🪖", "🎖️", "🇺🇸"];
      }
      // June
    } else if (month === 6) {
      // Father's Day
      if (day >= 8 && day <= 14 && dayName === 0) {
        emojiList = ["👨🏻‍🍼", "⛳️", "💞"];
      }
    }
    // July
    else if (month === 7) {
      // Independence Day
      if (day === 4) {
        emojiList = ["🇺🇸", "🦅", "🎆"];
      }
    }
    // August: No Holidays
    // September
    else if (month === 9) {
      // Labor Day
      if (day >= 1 && day <= 7 && dayName === 1) {
        emojiList = ["🇺🇸", "☀️", "🌭"];
      } else {
        emojiList = ["🍂", "🪵", "☕"];
      }
    }
    // October
    else if (month === 10) {
      // Halloween
      if (day >= 26) {
        emojiList = ["👻", "🎃", "🕸"];
        // Indigenous Peoples' Day
      } else if (day >= 8 && day <= 14 && dayName === 1) {
        emojiList = ["🪶", "🦬", "🏹"];
      } else {
        emojiList = ["🍂", "🪵", "☕"];
      }
    }
    // November
    else if (month === 11) {
      // Veterans Day
      if (day === 11) {
        emojiList = ["🎖️", "🇺🇸", "🫡"];
      } // Day of the Dead
      else if (day === 2) {
        emojiList = ["🌺", "🕯️", "⚰️"];
      }
      // Thanksgiving
      else if (day >= 22 && day <= 28) {
        emojiList = ["🦃", "🍂", "🥧"];
      } else {
        emojiList = ["🧣", "🍂", "🔅"];
      }
    }
    // December
    else if (month === 12) {
      // Christmas
      if (day === 24 || day === 25) {
        emojiList = ["🎅🏻", "🎄", "🎁"];
        // Kwanzaa
      } else if (day >= 26 && day <= 31) {
        emojiList = ["🕯️", "🎁", "🌽"];
      } else {
        emojiList = ["⛄", "🌲", "❄️"];
      }
    }
    // Default Emojis
    else {
      emojiList = ["🏖️", "🏝️"];
    }

    this.jsConfetti.addConfetti({
      emojis: emojiList,
    });
  };
}
