import { makeAutoObservable, reaction, runInAction } from "mobx";
import { ServerError } from "../models/serverError";
import { StoreValue } from "./storeValue";

// adapted from https://github.com/TryCatchLearn/Reactivities

interface FontOption {
  className: string;
  label: string;
}

export default class CommonStore {
  error: ServerError | null = null;

  token: string | null = window.localStorage.getItem("jwt");

  currentLocation: string | undefined;

  onLocationChange: undefined | (() => void);

  loading = true;

  private fontOptionRegistry = new StoreValue<string>();

  fontOptions: FontOption[] = [
    { className: "smaller-font", label: "Smaller" },
    { className: "normal-font", label: "Normal" },
    { className: "bigger-font", label: "Bigger" },
    { className: "biggest-font", label: "Biggest" },
  ];

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.token,
      (token) => {
        if (token) {
          window.localStorage.setItem("jwt", token);
        } else {
          window.localStorage.removeItem("jwt");
        }
      }
    );
  }

  setServerError = (error: ServerError) => {
    this.error = error;
  };

  setToken = (token: string | null) => {
    this.token = token;
  };

  setAppLoading = (value: boolean) => {
    this.loading = value;
  };

  setCurrentLocation = (location: string) => {
    if (location !== this.currentLocation && this.onLocationChange) this.onLocationChange();

    runInAction(() => {
      this.onLocationChange = undefined;
      this.currentLocation = location;
    });
  };

  setOnLocationChangeHandler = (location: string, onChange: () => void) => {
    runInAction(() => {
      this.currentLocation = location;
      this.onLocationChange = onChange;
    });
  };

  get fontOption() {
    return this.fontOptionRegistry.value;
  }

  loadFontOption = () => {
    const option = localStorage.getItem("font-size-option") ?? "normal-font";
    this.setFontOption(option);
  };

  setFontOption = (className: string) => {
    this.fontOptions.forEach((option) => {
      document.documentElement.classList.remove(option.className);
      document.body.classList.remove(option.className);
    });

    // add to html and body tags
    document.documentElement.classList.add(className);
    document.body.classList.add(className);

    localStorage.setItem("font-size-option", className);
    this.fontOptionRegistry.setValue(className);
  };
}
