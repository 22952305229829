import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Container, Dropdown, Icon, Menu, MenuMenu } from "semantic-ui-react";
import logo from "../../assets/logo.svg";
import { useCheckForRouteParameters } from "../../hooks/useCheckForRouteParameters";
import useWindowSize from "../../hooks/useWindowSize";
import TEACHFRONT_PATHS from "../../paths";
import { useStore } from "../../stores/store";
import TeachFrontLink from "../../utilities/routing/components/TeachFrontLink";
import TeachFrontNavLink from "../../utilities/routing/components/TeachFrontNavLink";
import CourseRoles from "../../utilities/routing/components/routeAuthorizationExtensions/CourseRoles";
import { createClassName, isValidID } from "../../utilities/utils";
import RoundLabelWithIcon from "../_common/style/RoundLabelWithIcon";
import VerticalGap from "../_common/style/spacing/VerticalGap";
import ProfilePhoto from "../_common/user/ProfilePhoto";
import CourseRegistrationModal from "../_modal/CourseRegistrationModal";
import "./NavBar.css";
import TextSizePicker from "./TextSizePickerMenuItem";

const NavBar: React.FC = () => {
  const { isMobile } = useWindowSize();
  const { userStore, courseStore, assessmentTaskStore, modalStore } = useStore();
  const { user, logout, isLoggedIn } = userStore;
  const { courseID } = useParams<{ courseID: string }>();
  const {
    currentCourse,
    loadCurrentCourse,
    loadCoursesForUser,
    coursesForUser,
    courseRoleForCurrentUser,
    loadCourseRoleForCurrentUser,
  } = courseStore;
  const activeCourses =
    coursesForUser?.filter((course) => new Date(course.endDate) >= new Date()) || [];
  const { assessmentTasksCount, assessmentTasksAssignedToUserCount } = assessmentTaskStore;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [mobileActiveIndex, setMobileActiveIndex] = useState<number>(-1);
  const navigate = useNavigate();
  const showCourseDetails = useCheckForRouteParameters("courseID");

  useEffect(() => {
    if (courseID && isValidID(courseID) && user) {
      loadCourseRoleForCurrentUser(courseID, user.userID);
    }
  }, [courseID, user]);

  useEffect(() => {
    if (isLoggedIn && user?.userID && courseID && isValidID(courseID)) {
      loadCoursesForUser(courseID, user.userID);
      loadCurrentCourse(courseID);
    }
  }, [user, isLoggedIn, courseID]);

  useEffect(() => {
    if (!isMobile && expanded) setExpanded(false);
  }, [isMobile, expanded, setExpanded]);

  const handleMobileActiveIndexClick = (index: number) => {
    if (mobileActiveIndex === index) setMobileActiveIndex(-1);
    else setMobileActiveIndex(index);
  };

  return (
    <>
      {isMobile && (
        <div className="NavBar-mobile-unexpanded" data-print-id="hide">
          <div
            onClick={() => setExpanded(!expanded)}
            role="button"
            className={createClassName("navbar-toggle", { name: "expanded", apply: expanded })}
          >
            <Icon name={expanded ? "close" : "bars"} size="large" />
          </div>
          {!currentCourse && !expanded && (
            <div className="text">
              <img
                className="teachfront-header-img"
                src={logo}
                alt="TeachFront Wave Logo"
                style={{ marginRight: "10px", width: "2em" }}
              />{" "}
              TeachFront
            </div>
          )}
          {showCourseDetails && currentCourse && (
            <div className="text">
              {currentCourse?.shortName}: {currentCourse?.longName}
            </div>
          )}
        </div>
      )}
      {isMobile && expanded && (
        <div className="NavBar-mobile-expanded" data-print-id="hide">
          <VerticalGap height={"3em"} />
          <TeachFrontLink to="Landing" params={{}}>
            <div className="logo">
              <img
                className="teachfront-header-img"
                src={logo}
                alt="TeachFront Wave Logo"
                style={{ marginRight: "10px" }}
              />
              <span id="teachfront-header-logo-text">TeachFront</span>
            </div>
          </TeachFrontLink>
          <div className="mobile-options">
            {isLoggedIn && user && (
              <>
                <div
                  className="mobile-header"
                  onClick={() => {
                    setExpanded(false);
                    navigate("/");
                  }}
                  role="button"
                >
                  <Icon name="dashboard" size="large" />
                  Dashboard
                </div>
                <div
                  onClick={() => handleMobileActiveIndexClick(0)}
                  className="mobile-header"
                  role="button"
                >
                  <Icon name="book" size="large" />
                  Courses
                </div>
                {mobileActiveIndex === 0 && (
                  <div className="mobile-dropdown">
                    {activeCourses?.map((course) => (
                      <Link
                        key={course.id}
                        onClick={() => setExpanded(false)}
                        to={`/courses/${course.id}/assignments`}
                        className="mobile-option"
                      >
                        <Icon name="arrow right" />
                        {course.shortName}: {course.longName}
                      </Link>
                    ))}
                    <div
                      className="mobile-option"
                      onClick={() => {
                        setExpanded(false);
                        navigate("/");
                      }}
                    >
                      <Icon name="list" />
                      View All
                    </div>
                    <div
                      className="mobile-option"
                      onClick={() => {
                        setExpanded(false);
                        modalStore.openModal(<CourseRegistrationModal />, { showCloseIcon: true });
                      }}
                    >
                      <Icon name="plus" />
                      Join Course
                    </div>
                  </div>
                )}
                <div
                  className="mobile-header"
                  role="button"
                  onClick={() => handleMobileActiveIndexClick(1)}
                >
                  <Icon name="user circle outline" size="large" />
                  {user.firstName} {user.lastName}
                </div>
                {mobileActiveIndex === 1 && (
                  <div className="mobile-dropdown">
                    <div
                      className="mobile-option"
                      onClick={() => {
                        setExpanded(false);
                        navigate("/profile");
                      }}
                    >
                      <Icon name="user" />
                      My Profile
                    </div>
                    <div
                      className="mobile-option"
                      role="button"
                      onClick={() => {
                        setExpanded(false);
                        navigate("/");
                        logout();
                      }}
                    >
                      <Icon name="power" />
                      Logout
                    </div>
                  </div>
                )}
              </>
            )}
            {!isLoggedIn && (
              <>
                <div
                  className="mobile-header"
                  role="button"
                  onClick={() => {
                    setExpanded(false);
                    navigate(TEACHFRONT_PATHS.Login);
                  }}
                >
                  <Icon name="user circle outline" size="large" />
                  Login
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {!isMobile && (
        <Menu
          inverted
          fixed="top"
          color={"blue"}
          vertical={isMobile}
          className="NavBar"
          data-print-id="hide"
        >
          <Container>
            <TeachFrontLink to="Landing" params={{}}>
              <Menu.Item>
                <img
                  className="teachfront-header-img"
                  src={logo}
                  alt="TeachFront Wave Logo"
                  style={{ marginRight: "10px" }}
                />
                <span id="teachfront-header-logo-text">TeachFront</span>
              </Menu.Item>
            </TeachFrontLink>
            {isLoggedIn && (
              <>
                {activeCourses && (
                  <Menu.Item>
                    <Dropdown pointing="top left" text="Courses">
                      <Dropdown.Menu>
                        {activeCourses?.map((course) => (
                          <Dropdown.Item
                            key={course.id}
                            as={Link}
                            to={`/courses/${course.id}/assignments`}
                            text={`${course.shortName}: ${course.longName}`}
                          />
                        ))}
                        <Dropdown.Item
                          onClick={() => {
                            setExpanded(false);
                            navigate("/");
                          }}
                        >
                          <Icon name="list" fitted size="tiny" /> View All
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            modalStore.openModal(<CourseRegistrationModal />, {
                              showCloseIcon: true,
                            });
                          }}
                        >
                          <Icon name="plus" fitted size="tiny" /> Join Course
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu.Item>
                )}
                {showCourseDetails &&
                  courseRoleForCurrentUser &&
                  CourseRoles.TeachingTeamRoles.includes(courseRoleForCurrentUser) && (
                    <Menu.Item
                      as={TeachFrontNavLink}
                      to={"CourseHomeAssessmentTasksTab"}
                      params={{ courseID }}
                    >
                      Tasks
                      <RoundLabelWithIcon
                        icon="user"
                        value={assessmentTasksAssignedToUserCount?.toString() || "?"}
                      />
                      <RoundLabelWithIcon
                        icon="users"
                        value={assessmentTasksCount?.toString() || "?"}
                      />
                    </Menu.Item>
                  )}
                <MenuMenu position="right">
                  <TextSizePicker />
                  <Menu.Item>
                    {user && <ProfilePhoto user={user} size="2em" color="white" />}
                    <Dropdown pointing="top left" text={`${user?.firstName} ${user?.lastName}`}>
                      <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={`/profile`} text="My Profile" icon="user" />
                        <Dropdown.Item
                          as={Link}
                          to={"/"}
                          onClick={logout}
                          text="Logout"
                          icon="power"
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu.Item>
                </MenuMenu>
              </>
            )}
            {!isLoggedIn && (
              <Menu.Item position="right">
                <Button as={Link} to={TEACHFRONT_PATHS.Login} size="huge" name="login" inverted>
                  Login!
                </Button>
              </Menu.Item>
            )}
          </Container>
        </Menu>
      )}
    </>
  );
};

export default observer(NavBar);
