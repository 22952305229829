import { Formik, FormikProps, FormikValues } from "formik";
import { observer } from "mobx-react-lite";
import React, { Ref, useEffect, useState } from "react";
import { Button, Form, Header, List, Radio } from "semantic-ui-react";
import useValidParams from "../../../../hooks/useValidParameters";
import { AssignmentSurveySubmission } from "../../../../models/AssignmentSurveySubmission";
import { useStore } from "../../../../stores/store";
import LoadingComponent from "../../../../utilities/routing/components/LoadingComponent";
import { emptyID } from "../../../../utilities/submissionUtils";
import "./AssignmentSurveyForm.css";

export interface SurveyFormValues {
  learningQuality: number | undefined;
  taskEffectiveness: number | undefined;
  difficulty: number | undefined;
  hoursToComplete: number | undefined;
  minutesToComplete: number | undefined;
  additionalComments: string | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AssignmentSurveyForm = React.forwardRef((props, ref: Ref<FormikProps<SurveyFormValues>>) => {
  const { assignmentSurveyStore, modalStore, userStore, toastStore } = useStore();
  const { courseID, assignmentID } = useValidParams<{ courseID: string; assignmentID: string }>();
  const { user } = userStore;
  const {
    loadAssignmentSurveySubmission,
    hasLoadedAssignmentSurveySubmission,
    assignmentSurveySubmission,
    setDefaultAssignmentSurveySubmission,
  } = assignmentSurveyStore;
  const { showToast } = toastStore;

  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (user)
      loadAssignmentSurveySubmission(user.userID, assignmentID, courseID, {
        400: () => {
          setDefaultAssignmentSurveySubmission(user.userID, assignmentID, courseID);
        },
      });
  }, [assignmentSurveySubmission, loadAssignmentSurveySubmission, user, assignmentID, courseID]);

  if (!user || !hasLoadedAssignmentSurveySubmission(user.userID, assignmentID, courseID))
    return <LoadingComponent content="Loading Survey..." />;

  const hourOptions = [
    { key: 0, text: "0 hours", value: 0 },
    { key: 1, text: "1 hour", value: 60 },
    { key: 2, text: "2 hours", value: 120 },
    { key: 3, text: "3 hours", value: 180 },
    { key: 4, text: "4 hours", value: 240 },
    { key: 5, text: "5 hours", value: 300 },
    { key: 6, text: "6 hours", value: 360 },
    { key: 7, text: "7 hours", value: 420 },
    { key: 8, text: "8 hours", value: 480 },
    { key: 9, text: "9 hours", value: 540 },
    { key: 10, text: "10 hours", value: 600 },
    { key: 11, text: "11 hours", value: 600 },
    { key: 12, text: "12 hours", value: 720 },
    { key: 13, text: "13 hours", value: 780 },
    { key: 14, text: "14 hours", value: 840 },
    { key: 15, text: "15 hours", value: 900 },
    { key: 16, text: "16 hours", value: 960 },
    { key: 17, text: "17 hours", value: 1020 },
    { key: 18, text: "18 hours", value: 1080 },
    { key: 19, text: "19 hours", value: 1140 },
    { key: 20, text: "20 hours", value: 1200 },
    { key: 21, text: "21 hours", value: 1260 },
    { key: 22, text: "22 hours", value: 1320 },
    { key: 23, text: "23 hours", value: 1380 },
    { key: 24, text: "24 hours", value: 1440 },
    { key: 25, text: "25 or more hours", value: 1500 },
  ];
  const minuteOptions = [
    { key: 1, text: "0 minutes", value: 0 },
    { key: 1, text: "15 minutes", value: 15 },
    { key: 2, text: "30 minutes", value: 30 },
    { key: 3, text: "45 minutes", value: 45 },
  ];

  const initValues: SurveyFormValues = {
    learningQuality: assignmentSurveySubmission?.learningQuality ?? undefined,
    taskEffectiveness: assignmentSurveySubmission?.taskEffectiveness ?? undefined,
    difficulty: assignmentSurveySubmission?.difficulty ?? undefined,
    minutesToComplete:
      assignmentSurveySubmission?.minutesToComplete !== undefined
        ? assignmentSurveySubmission.minutesToComplete % 60
        : undefined,
    hoursToComplete:
      assignmentSurveySubmission?.minutesToComplete !== undefined
        ? Math.floor(assignmentSurveySubmission.minutesToComplete / 60)
        : undefined,
    additionalComments: assignmentSurveySubmission?.additionalComments ?? undefined,
  };

  const handleFormSubmit = (values: FormikValues) => {
    if (!values || !user) return;

    setSubmitting(true);

    const {
      learningQuality,
      taskEffectiveness,
      difficulty,
      hoursToComplete,
      minutesToComplete,
      additionalComments,
    } = values;

    const surveyID = assignmentSurveySubmission?.id ?? emptyID;
    const newSurvey: AssignmentSurveySubmission = {
      id: surveyID,
      courseID,
      assignmentID,
      userID: user.userID,
      learningQuality,
      taskEffectiveness,
      difficulty,
      minutesToComplete: hoursToComplete + minutesToComplete,
      additionalComments,
    };

    assignmentSurveyStore.createOrUpdate(newSurvey).then((success) => {
      assignmentSurveyStore.reset();
      modalStore.closeModal();

      if (success) {
        showToast("Successfully saved assignment survey.");
      } else {
        showToast("Something went wrong while saving the assignment survey.");
      }
    });

    setSubmitting(false);
  };

  return (
    <div className="AssignmentSurveyForm">
      <Formik innerRef={ref} initialValues={initValues} onSubmit={() => {}}>
        {({ values, setFieldValue }) => (
          <Form>
            <Header as="h2">
              {!assignmentSurveySubmission ? "Provide some" : "Update your"} feedback about this
              assignment:
            </Header>
            <List ordered>
              <List.Item>
                How well did this assignment help you learn?
                <Form.Group widths="equal">
                  <Form.Field onClick={() => setFieldValue("learningQuality", 0)}>
                    <Radio checked={values.learningQuality === 0} />
                    <label>not well at all</label>
                  </Form.Field>
                  <Form.Field onClick={() => setFieldValue("learningQuality", 1)}>
                    <Radio checked={values.learningQuality === 1} />
                    <label>not very well</label>
                  </Form.Field>
                  <Form.Field onClick={() => setFieldValue("learningQuality", 2)}>
                    <Radio checked={values.learningQuality === 2} />
                    <label>kind of well</label>
                  </Form.Field>
                  <Form.Field onClick={() => setFieldValue("learningQuality", 3)}>
                    <Radio checked={values.learningQuality === 3} />
                    <label>very well</label>
                  </Form.Field>
                </Form.Group>
              </List.Item>
              <List.Item>
                How helpful were the tasks to your learning?
                <Form.Group widths="equal">
                  <Form.Field onClick={() => setFieldValue("taskEffectiveness", 0)}>
                    <Radio checked={values.taskEffectiveness === 0} />
                    <label>mostly busywork</label>
                  </Form.Field>
                  <Form.Field onClick={() => setFieldValue("taskEffectiveness", 1)}>
                    <Radio checked={values.taskEffectiveness === 1} />
                    <label>a little tedious</label>
                  </Form.Field>
                  <Form.Field onClick={() => setFieldValue("taskEffectiveness", 2)}>
                    <Radio checked={values.taskEffectiveness === 2} />
                    <label>a little helpful</label>
                  </Form.Field>
                  <Form.Field onClick={() => setFieldValue("taskEffectiveness", 3)}>
                    <Radio checked={values.taskEffectiveness === 3} />
                    <label>mostly helpful</label>
                  </Form.Field>
                </Form.Group>
              </List.Item>
              <List.Item>
                How difficult did you find this assignment?
                <Form.Group widths="equal">
                  <Form.Field onClick={() => setFieldValue("difficulty", 0)}>
                    <Radio checked={values.difficulty === 0} />
                    <label>easy peasy</label>
                  </Form.Field>
                  <Form.Field onClick={() => setFieldValue("difficulty", 1)}>
                    <Radio checked={values.difficulty === 1} />
                    <label>pretty simple</label>
                  </Form.Field>
                  <Form.Field onClick={() => setFieldValue("difficulty", 2)}>
                    <Radio checked={values.difficulty === 2} />
                    <label>kind of tough</label>
                  </Form.Field>
                  <Form.Field onClick={() => setFieldValue("difficulty", 3)}>
                    <Radio checked={values.difficulty === 3} />
                    <label>really difficult</label>
                  </Form.Field>
                </Form.Group>
              </List.Item>
              <List.Item>
                How long did it take you to complete this assignment?
                <Form.Group widths="equal">
                  <Form.Dropdown
                    selection
                    options={hourOptions}
                    placeholder="select hours"
                    name="hoursToComplete"
                    defaultValue={values.hoursToComplete}
                    onChange={(e, { value }) => setFieldValue("hoursToComplete", value)}
                  />
                  <Form.Dropdown
                    selection
                    options={minuteOptions}
                    placeholder="select minutes"
                    name="minutesToComplete"
                    defaultValue={values.minutesToComplete}
                    onChange={(e, { value }) => setFieldValue("minutesToComplete", value)}
                  />
                </Form.Group>
              </List.Item>
              <List.Item>
                Additional Comments:
                <Form.Field>
                  <Form.TextArea
                    name="additionalComments"
                    defaultValue={values.additionalComments}
                    onChange={(e) => setFieldValue("additionalComments", e.target.value)}
                  />
                </Form.Field>
              </List.Item>
            </List>
            <Button
              content="Submit Survey"
              type="submit"
              color="blue"
              floated="right"
              loading={submitting}
              onClick={() => handleFormSubmit(values)}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
});

export default observer(AssignmentSurveyForm);
