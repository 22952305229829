import { useNavigate } from "react-router-dom";
import { Placeholder, PlaceholderLine } from "semantic-ui-react";
import { User } from "../../../models/User";
import { useStore } from "../../../stores/store";
import { concatenateUserName } from "../../../utilities/utils";
import ProfilePhoto from "../user/ProfilePhoto";
import "./UserCard.css";

interface UserCardProps {
  user: User;
  backgroundColor?: "grey" | "white";
}

export const UserCardSkeleton: React.FC = () => (
  <div className="UserCardSkeleton">
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
  </div>
);

const UserCard: React.FC<UserCardProps> = ({ user, backgroundColor = "grey" }) => {
  const navigate = useNavigate();

  const { modalStore } = useStore();

  return (
    <div
      className={`UserCard ${backgroundColor}`}
      onClick={() => {
        modalStore.closeModal();
        navigate(`/profile/${user.userID}`);
      }}
    >
      <div className="photo-container">
        <ProfilePhoto user={user} size="3em" data-print-id="color" />
      </div>
      <div className="user-info">
        <div className="user-card-full-name">
          {concatenateUserName(user.firstName, user.lastName, user.honorific, user.suffix)}
        </div>
        <div className="title" style={{ gridArea: user.title ? "c" : "d" }}>
          {user.title ?? ""}
        </div>
        <div className="pronouns" style={{ gridArea: user.title ? "d" : "c" }}>
          {user.pronouns}
        </div>
      </div>
    </div>
  );
};

export default UserCard;
