import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import assessmentsImage from "../../../assets/assessments.png";
import assignmentsImage from "../../../assets/assignments.png";
import gradeCalculationsImage from "../../../assets/gradeCalculations.png";
import masteryLevelsImage from "../../../assets/masteryLevels.png";
import objectivesImage from "../../../assets/objectives.png";
import reassessmentsImage from "../../../assets/reassessments.png";

export interface MasteryGradingStep {
  stepNumber: number;
  title: string;
  description: JSX.Element;
  icon: SemanticICONS;
  img: string;
  imgAlt: string;
}
export const MasteryGradingSteps = [
  {
    stepNumber: 1,
    title: "Objectives",
    description: (
      <>
        <p>
          Course objectives (sometimes also called <i>standards</i>) are descriptions of the skills
          and knowledge that instructors expect their students to exhibit at the end of a term.
          Mastery Grading promotes equity by including only progress toward the stated objectives in
          assessments.
        </p>
        <p>
          <b>Objectives on TeachFront:</b> TeachFront allows instructors to define, describe, and
          color code their own set of objectives, then organize those objectives into logical blocks
          (modules, themes, etc.).
        </p>
        <p>
          <b>Tip:</b> Consider all of the elements that constitute "mastery" of your course content,
          including effortful engagement in and effective communication about your subject matter.
        </p>
      </>
    ),
    icon: "list",
    img: objectivesImage,
    imgAlt: "Screenshot of hierarchical objectives in TeachFront",
  } as MasteryGradingStep,
  {
    stepNumber: 2,
    title: "Mastery Levels",
    description: (
      <>
        <p>
          Mastery levels are used to describe how well a student's work exhibits mastery toward an
          objective. Mastery levels are like the columns in a rubric, but with descriptive names
          instead of grade distinctions or point values. Perhaps a student's work is{" "}
          <i>Satisfactory</i> or <i>Exemplary</i>. Perhaps the work is <i>Progressing</i> or{" "}
          <i>Not There Yet</i>.
        </p>
        <p>
          <b>Mastery Levels on TeachFront:</b> With TeachFront, instructors can create custom
          mastery level "schemes" for assessing student work. Instructors assign one of the course's
          mastery level schemes to each objective or objective category, which ensures consistency
          in the assessment of all assignments related to that objective.
        </p>
        <p>
          <b>Tip:</b> Choose names for mastery levels that describe the students' work, and cannot
          be misunderstood to describe a student themselves (e.g., choose a name like <i>Not Yet</i>
          rather than <i>Falling Behind</i>).
        </p>
      </>
    ),
    icon: "sitemap",
    img: masteryLevelsImage,
  } as MasteryGradingStep,
  {
    stepNumber: 3,
    title: "Assignments",
    description: (
      <>
        <p>
          "Assignment" is a generic term that includes all manner of student work: exams,
          presentations, essays, worksheets, and more. The goal of all assignments should be to
          measure student mastery of the relevant course objectives. When assessing assignments,
          only mastery of the objectives will be assessed, so work unrelated to the objectives
          should be avoided.
        </p>
        <p>
          <b>Assignments on TeachFront:</b> Instructors can provide instructions, upload files, set
          due dates, choose objectives, and more. Students can submit their work on TeachFront, too.
          We accept submissions in several formats, including rich text, file uploads, URLs, etc.
        </p>
        <p>
          <b>Tip:</b> When designing the portfolio of assignments that a student will complete
          throughout the course, ensure that the most important course objectives are assessed the
          most frequently.
        </p>
      </>
    ),
    icon: "clipboard list",
    img: assignmentsImage,
  } as MasteryGradingStep,
  {
    stepNumber: 4,
    title: "Assessments",
    description: (
      <>
        <p>
          Instructors weigh the evidence of objective mastery present in a student's work and select
          the appropriate mastery level, taking care to provide feedback as to how the work might be
          strengthened. Assessment "marks" are communicated to students as a set of mastery levels
          and associated feedback.
        </p>
        <p>
          <b>Assessments on TeachFront:</b> TeachFront visualizes the assessment of a student's work
          with colorful graphs. Colored sections indicate the level of mastery evidenced by the
          work, while the grey sections represent opportunities for improvement.
        </p>
        <p>
          <b>Tip:</b> Instructors new to mastery grading sometimes mourn the loss of "incidental
          points" (for correct grammar, timeliness, adherence to instructions, etc.) in assessment
          rubrics. This is usually a sign that their list of objectives is incomplete. Consider
          adding objectives for "Communication About Subject" or "Subject Best Practices."
        </p>
      </>
    ),
    icon: "checkmark",
    img: assessmentsImage,
  } as MasteryGradingStep,
  {
    stepNumber: 5,
    title: "Reassessments",
    description: (
      <>
        <p>
          The mastery grading mindset is reminiscent of apprenticeship. With instruction and
          feedback from an expert, the work of an apprentice (or student) can grow and improve. In
          mastery grading, students have the opportunity to improve their work and request
          reassessment. The improved assessments replace the old, modeling lifelong learning.
        </p>
        <p>
          <b>Reassessments on TeachFront:</b> TeachFront streamlines the reassessment request
          process: instructors control whether and when resubmissions will be accepted and students
          can see their assessment progress over time.
        </p>
        <p>
          <b>Tip:</b> Often, instructors fear that reassessment opportunities will result in lower
          quality work from students. In our experience, the opposite is true. Providing students
          multiple attempts at mastery allows them to reach higher heights than they could in a
          single attempt, without expert feedback.
        </p>
      </>
    ),
    icon: "redo alternate",
    img: reassessmentsImage,
  } as MasteryGradingStep,
  {
    stepNumber: 6,
    title: "Grade Calculations",
    description: (
      <>
        <p>
          Summative grades are calculated using what we call "recipes". Each grade distinction (A+,
          B-, etc.) has a set of required ingredients (e.g., "72% of assessments must be at the
          highest level"). A student will earn the highest grade distinction that their portfolio of
          assessments satisfies.
        </p>
        <p>
          <b>Grade Calculations on TeachFront:</b> Instructors can supply a custom set of grade
          distinctions, as well as the ingredients and recipes that TeachFront will use to
          automatically calculate grades. For students, their current grade distinction and the
          calculations that lead to it are transparent and always accessible.
        </p>
        <p>
          <b>Tip:</b> Like everything in mastery grading, recipe ingredients are created with regard
          to particular objectives. Keep in mind the relative importance of some objectives over
          others, and set the recipe thresholds accordingly.
        </p>
      </>
    ),
    icon: "calculator",
    img: gradeCalculationsImage,
    imgAlt:
      "Screenshot of table in TeachFront that helps students understand how their grade is calculated.",
  } as MasteryGradingStep,
];
