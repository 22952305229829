import RoundedSegment from "../components/_common/style/RoundedSegment";
import ViewGradeCalculationsForCourse from "../components/courses/[courseID]/grade-calculations/user/[userID]/ViewGradeCalculationsForCourse";

const CourseHomeGradeCalculationsTab: React.FC = () => (
  <RoundedSegment>
    <ViewGradeCalculationsForCourse />
  </RoundedSegment>
);

export default CourseHomeGradeCalculationsTab;
