import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Header } from "semantic-ui-react";
import useValidParams from "../../../../../../hooks/useValidParameters";
import { User } from "../../../../../../models/User";
import { useStore } from "../../../../../../stores/store";
import CourseRoles from "../../../../../../utilities/routing/components/routeAuthorizationExtensions/CourseRoles";
import HorizontalIndent from "../../../../../_common/style/spacing/HorizontalIndent";
import FlexContainer from "../../../../../_common/style/FlexContainer";
import "./GradeCalculationSelectionForm.css";

interface GradeCalculationSelectionFormProps {
  studentID: string;
}

const GradeCalculationSelectionFormSkeleton: React.FC = () => (
  <Form className="GradeCalculationSelectionForm">
    <Header as="h3">Change Student</Header>
    <HorizontalIndent>
      <FlexContainer alignItems="center" gap="0.5rem" flexWrap="wrap">
        <Form.Select search disabled={true} options={[]} />
        <Button content="View Preceding Student" icon="arrow left" color="grey" disabled={true} />
        <Button content="View Next Student" icon="arrow right" color="grey" disabled={true} />
      </FlexContainer>
    </HorizontalIndent>
  </Form>
);

/**
 * Form to allow a student or instructor to select various options for the grade calculations page.
 *
 * If the user is a student, they will be able to see all of their courses and select the course they'd like
 * to see grade calculations for.
 *
 * If the user is a instructor, they will be able to see all of the students in the course and select the student they'd
 * like to see grade calculations for.
 */
const GradeCalculationSelectionForm: React.FC<GradeCalculationSelectionFormProps> = ({
  studentID,
}) => {
  const { courseStore } = useStore();
  const { currentCourseRoster } = courseStore;
  const { courseID } = useValidParams<{
    courseID: string;
    userID: string;
  }>();

  const [selectedStudent, setSelectedStudent] = useState<string>(studentID);
  const navigate = useNavigate();

  const handleCourseSelectionMade = (userID: string) => {
    setSelectedStudent(userID);
    navigate(`/courses/${courseID}/grade-calculations/user/${userID}`);
  };

  const studentRoster = useMemo(
    () =>
      currentCourseRoster?.filter(
        ({ courseRole }) => courseRole && CourseRoles.StudentRoles.includes(courseRole)
      ),
    [currentCourseRoster]
  );

  const studentRosterOptions = useMemo(
    () =>
      studentRoster?.map((courseMember) => ({
        key: courseMember.userID,
        value: courseMember.userID,
        text: `${courseMember.lastName}, ${courseMember.firstName}`,
      })),
    [studentRoster]
  );

  if (!studentRoster || !selectedStudent || !studentRosterOptions)
    return <GradeCalculationSelectionFormSkeleton />;

  const userIndex = studentRoster.findIndex((u) => u.userID === studentID);

  const handleNavigationButtonClick = (direction: -1 | 1) => {
    const user = studentRoster[userIndex + direction] as User;

    handleCourseSelectionMade(user.userID);
  };

  // This should never be the case.
  if (userIndex === -1) return <>The current student is not apart of the course.</>;

  return (
    <Form className="GradeCalculationSelectionForm">
      <Header as="h3">Change Student</Header>
      <HorizontalIndent>
        <FlexContainer alignItems="center" gap="0.5rem" flexWrap="wrap">
          <Form.Select
            search
            onChange={(e, { value }) => handleCourseSelectionMade(value as string)}
            value={selectedStudent}
            options={studentRosterOptions}
          />
          <Button
            type="button"
            content="View Preceding Student"
            icon="arrow left"
            color="grey"
            disabled={userIndex === 0}
            onClick={() => handleNavigationButtonClick(-1)}
          />
          <Button
            type="button"
            content="View Next Student"
            icon="arrow right"
            color="grey"
            disabled={userIndex === studentRoster.length - 1}
            onClick={() => handleNavigationButtonClick(1)}
          />
        </FlexContainer>
      </HorizontalIndent>
    </Form>
  );
};

export default observer(GradeCalculationSelectionForm);
