import { Button, Header } from "semantic-ui-react";
import { useStore } from "../../../../../stores/store";
import FlexContainer from "../../../../_common/style/FlexContainer";
import RoundedSegment from "../../../../_common/style/RoundedSegment";
import CreateOrUpdateCalendarEntryModal from "../_modal/CreateOrUpdateCalendarEntryModal";
import "./VerticalCalendarEditor.css";

interface VerticalCalendarEditorProps {
  courseID: string;
}

const VerticalCalendarEditor: React.FC<VerticalCalendarEditorProps> = ({ courseID }) => {
  const { modalStore } = useStore();

  return (
    <RoundedSegment className="VerticalCalendarEditor no-top-padding" data-print-id="hide">
      <Header as="h3" content={"Create Calendar Events"} className="no-top-margin" />
      <FlexContainer flexWrap="wrap" gap="0.5em">
        <Button
          icon="plus"
          content="Create New Event"
          color="grey"
          onClick={() =>
            modalStore.openModal(<CreateOrUpdateCalendarEntryModal courseID={courseID} />)
          }
        />
      </FlexContainer>
    </RoundedSegment>
  );
};

export default VerticalCalendarEditor;
