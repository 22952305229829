import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "../stores/store";

/**
 * Hook that listens to location changes (navigation) and runs code when it happens.
 * @param onReload a function to call when navigation occurs.
 */
export default function useLocationListener(onReload: () => void) {
  const { commonStore } = useStore();
  const { setOnLocationChangeHandler } = commonStore;

  const location = useLocation();

  useEffect(() => {
    setOnLocationChangeHandler(location.key, onReload);
    // Only reset the location handler when a new onReload function is made
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onReload]);
}
