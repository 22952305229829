import Group from "../models/Group";
import { getCommaSeparatedList } from "./collectionUtils";

// eslint-disable-next-line import/prefer-default-export
export function getGroupName(group: Group): string {
  if (group.name) {
    return group.name;
  }

  if (!group.groupMembers || group.groupMembers.length === 0) {
    return "";
  }

  const namesCopy = [...group.groupMembers.map((gm) => gm.student)]
    .sort((a, b) => a.lastName.localeCompare(b.lastName, undefined, { sensitivity: "base" }))
    .map((gm) => gm.firstName);

  return getCommaSeparatedList(namesCopy, "&");
}
