import { observer } from "mobx-react-lite";
import { AccordionContent, AccordionTitle, Button, Icon } from "semantic-ui-react";
import useBooleanState from "../../../../../../hooks/useBooleanState";
import useCurrentUserCourseRole from "../../../../../../hooks/useCurrentUserCourseRole";
import { User } from "../../../../../../models/User";
import { PollingActivity } from "../../../../../../models/polling/PollingActivity";
import { useStore } from "../../../../../../stores/store";
import { formatDateMinutesHoursDayMonth } from "../../../../../../utilities/dateTimeUtils";
import PollingActivityModal from "../../../_modal/polling/PollingActivityModal";
import "./PollingActivityAccordion.css";
import PollingActivityOverview from "./PollingActivityOverview";
import { createClassName } from "../../../../../../utilities/utils";
import { ModalType } from "../../../../../../stores/modalStore";

interface PollingActivityAccordionProps {
  activeAccordionID: string;
  setActiveAccordionID: (index: string) => void;
  handleAccordionClick: (index: string) => void;
  pollingActivity: PollingActivity;
  courseID: string;
  roster: User[];
  index: number;
  isActivePollingActivity?: boolean;
}

const PollingActivityAccordion: React.FC<PollingActivityAccordionProps> = ({
  activeAccordionID,
  setActiveAccordionID,
  handleAccordionClick,
  pollingActivity,
  courseID,
  roster,
  isActivePollingActivity,
}) => {
  const { pollingStore, modalStore, toastStore } = useStore();
  const { isUserTeachingTeam, isUserStudent } = useCurrentUserCourseRole(courseID);
  const { closePollingActivity, deletePollingActivity } = pollingStore;
  const [isClosingActivity, setClosingActivity] = useBooleanState();
  const [isDeletingActivity, setDeletingActivity] = useBooleanState();

  const handleEndActivityClicked = async () => {
    setClosingActivity(true);
    await closePollingActivity(courseID, pollingActivity.assignmentID, pollingActivity.id);
    setActiveAccordionID("");
    toastStore.showToast("Polling activity successfully closed", { color: "blue" });
    setClosingActivity(false);
  };

  const handleOpenActivityClicked = () => {
    modalStore.openModal(<PollingActivityModal activePollingActivity={pollingActivity} />, {
      showCloseIcon: true,
      modalType: ModalType.POLLING,
    });
  };

  const handleDeleteActivityClicked = async () => {
    setDeletingActivity(true);
    const result = await deletePollingActivity(
      courseID,
      pollingActivity.id,
      pollingActivity.assignmentID,
      {
        customHandler: () =>
          toastStore.showToast("Something went wrong when deleting the polling activity", {
            color: "red",
          }),
      }
    );

    if (result) {
      toastStore.showToast("Successfully deleted polling activity");
    }
    setActiveAccordionID("");
    setDeletingActivity(false);
  };

  return (
    <div
      className={createClassName("PollingActivityAccordion", {
        name: "active",
        apply: !pollingActivity.closeTime,
      })}
    >
      <AccordionTitle
        active={activeAccordionID === pollingActivity.id}
        onClick={() => handleAccordionClick(pollingActivity.id)}
      >
        <Icon name="dropdown" />
        <span className="name">{pollingActivity.name}</span>
        <span className="separator"> • </span>
        <span className="time">{formatDateMinutesHoursDayMonth(pollingActivity.createdTime)}</span>
        <div className="action-item">
          {isActivePollingActivity ? (
            <>
              {isUserTeachingTeam && (
                <Button
                  content={"End Activity"}
                  icon="x"
                  color="blue"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEndActivityClicked();
                  }}
                  loading={isClosingActivity}
                />
              )}
              {isUserStudent && (
                <Button
                  content={"Open Activity"}
                  icon="eye"
                  color="blue"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenActivityClicked();
                  }}
                />
              )}
            </>
          ) : (
            isUserTeachingTeam && (
              <Button
                disabled={isDeletingActivity}
                icon="trash"
                color="red"
                onClick={async (e) => {
                  e.stopPropagation();
                  if (!isActivePollingActivity && !isDeletingActivity) {
                    const confirmDelete = await modalStore.openConfirmationModal(
                      "Are you sure you want to delete this polling activity?"
                    );
                    if (confirmDelete) {
                      handleDeleteActivityClicked();
                    }
                  }
                }}
              />
            )
          )}
        </div>
      </AccordionTitle>
      {activeAccordionID === pollingActivity.id && (
        <AccordionContent active={activeAccordionID === pollingActivity.id}>
          <PollingActivityOverview
            courseID={courseID}
            pollingActivity={pollingActivity}
            roster={roster}
          />
        </AccordionContent>
      )}
    </div>
  );
};

export default observer(PollingActivityAccordion);
