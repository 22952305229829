import { Header } from "semantic-ui-react";

const GradeIngredientsFormHeader: React.FC = () => (
  <div>
    <Header as="h3" content="Grade Ingredients" />
    <p>
      These are the measurements that are used to convert a student's set of assessments into a
      grade distinction.
    </p>
    <p>
      On this page, you can create ingredients that for each top-level objective. After creating
      each ingredient, quantities can be assigned for each grade distinction (e.g. A, B, C, or 4.0,
      3.9, 3.8, etc...) on the grade recipes page.
    </p>
    <Header as="h3" content="Create and Update Grade Ingredients" />
  </div>
);

export default GradeIngredientsFormHeader;
