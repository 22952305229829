import { Field, Form, Formik, FormikValues } from "formik";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Header, SegmentGroup } from "semantic-ui-react";
import useValidParams from "../../../../hooks/useValidParameters";
import { Assignment, AssignmentSubmissionFormat } from "../../../../models/Assignment";
import { Objective } from "../../../../models/Objective";
import { Submission } from "../../../../models/Submission";
import { useStore } from "../../../../stores/store";
import buildPath from "../../../../utilities/routing/buildPath";
import LoadingComponent from "../../../../utilities/routing/components/LoadingComponent";
import PageReloadOrRedirectHandler from "../../../../utilities/routing/components/PageReloadOrRedirectHandler";
import TeachFrontNavLink from "../../../../utilities/routing/components/TeachFrontNavLink";
import {
  SubmissionPackage,
  emptyID,
  getSubmissionInstructions,
} from "../../../../utilities/submissionUtils";
import { isQuillEmpty } from "../../../../utilities/utils";
import Tooltip from "../../../_common/misc/Tooltip";
import RoundedSegment from "../../../_common/style/RoundedSegment";
import HorizontalIndent from "../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../_common/style/spacing/VerticalGap";
import CourseObjective from "../syllabus/CourseObjective";
import "./AssignmentSubmissionForm.css";
import SubmissionDataSelection from "./SubmissionDataSelection";

interface SubmissionFormValues {
  id: string;
  data: string;
  url: string;
  isDraft: boolean;
}

interface AssignmentSubmissionFormProps {
  assignmentWithObjectives: Assignment<Objective>;
  allowedSubmissionFormat: AssignmentSubmissionFormat[];
  submissionPackage: SubmissionPackage;
  setSubmissionID?: (submissionID: string | undefined) => void;
  showingInAssignmentDetails?: boolean;
}

const AssignmentSubmissionForm: React.FC<AssignmentSubmissionFormProps> = ({
  submissionPackage,
  setSubmissionID,
  showingInAssignmentDetails,
  allowedSubmissionFormat,
  assignmentWithObjectives,
}) => {
  const { submissionStore, ratingStore, userStore, confettiStore } = useStore();
  const { user } = userStore;
  const { courseID, assignmentID } = useValidParams<{
    courseID: string;
    assignmentID: string;
  }>();
  const [savingAsDraft, setSavingAsDraft] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const navigate = useNavigate();

  if (!user) {
    return <LoadingComponent content="Loading submission contents..." />;
  }

  const { submission, isDraft: isPackageDraft } = submissionPackage;

  const initValues: SubmissionFormValues = {
    id: submission?.id ?? emptyID,
    data: submission?.data ?? "",
    url: submission?.url ?? "",
    isDraft: submissionPackage.isDraft,
  };

  const handleFormSubmit = async (
    formValues: FormikValues,
    draft: boolean,
    onPageReload: boolean,
    preventNavigation?: boolean
  ): Promise<string> => {
    // If the page isn't being changed/reloaded, set the loading state for buttons
    if (!onPageReload) {
      if (draft) setSavingAsDraft(true);
      else setSubmitting(true);
    }

    const data = isQuillEmpty(formValues.data) ? undefined : formValues.data;

    const newSubmission: Submission = {
      id: formValues.id,
      courseID,
      userID: user.userID,
      assignmentID,
      data,
      url: formValues.url,
      isDraft: draft,
      createdAt: new Date(Date.now()),
      hasUploadedFiles: false,
    };

    const returnedSubmission = await submissionStore.newSubmission(newSubmission, courseID);

    if (!onPageReload) {
      if (draft) setSavingAsDraft(false);
      else setSubmitting(false);
    }

    // Part of navigation is resetting stores
    if (!preventNavigation) {
      submissionStore.reset();
      ratingStore.reset();
    }

    if (showingInAssignmentDetails && setSubmissionID) setSubmissionID(returnedSubmission.id);
    else if (!preventNavigation)
      navigate(buildPath("CourseHomeAssignmentDetailsTab", { courseID, assignmentID }));

    return returnedSubmission.id as string;
  };

  return (
    <div className="AssignmentSubmissionForm">
      <>
        <Tooltip
          tooltip={getSubmissionInstructions(allowedSubmissionFormat)}
          style={{ position: "absolute", right: "1em" }}
        />
        <Formik
          initialValues={initValues}
          onSubmit={(formValues) => {
            handleFormSubmit(formValues, false, false);
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => {
            const onReload =
              values.data !== initValues.data || values.url !== initValues.url
                ? () => handleFormSubmit(values, isPackageDraft && true, true)
                : () => {};

            return (
              <Form className="ui form" onSubmit={handleSubmit}>
                <PageReloadOrRedirectHandler onReload={onReload} />
                <Header as="h3">Prepare Your Submission:</Header>
                {assignmentWithObjectives.dueDate &&
                assignmentWithObjectives.dueDate < new Date() ? (
                  <HorizontalIndent className="late-reminder">
                    (The due date has passed. This submission will be late.)
                  </HorizontalIndent>
                ) : (
                  ""
                )}
                <SubmissionDataSelection
                  submissionTypes={allowedSubmissionFormat}
                  fileUploadsSubmissionProps={
                    allowedSubmissionFormat.includes("FileUploads") &&
                    submissionPackage.submissionID
                      ? {
                          courseID,
                          userID: user.userID,
                          connectedComponentID: submissionPackage.submissionID,
                        }
                      : undefined
                  }
                  textSubmissionProps={
                    allowedSubmissionFormat.includes("Text")
                      ? { data: values.data, setFieldValue }
                      : undefined
                  }
                  urlSubmissionProps={
                    allowedSubmissionFormat.includes("URL")
                      ? {
                          url: values.url,
                        }
                      : undefined
                  }
                />
                <Field type="hidden" name="isDraft" />
                <Field type="hidden" name="id" />
                <VerticalGap height={"1em"} />
                {!showingInAssignmentDetails && (
                  <Button
                    content="Cancel"
                    type="button"
                    as={TeachFrontNavLink}
                    to={"CourseHomeAssignmentDetailsTab"}
                    params={{ courseID, assignmentID }}
                    color="red"
                  />
                )}
                <Button
                  content="Submit Assignment"
                  type="submit"
                  color="blue"
                  floated="right"
                  loading={submitting}
                  onClick={() => confettiStore.addConfetti()}
                />
                {isPackageDraft && (
                  <Button
                    disabled={initValues.data === values.data && initValues.url === values.url}
                    content="Save As Draft"
                    type="button"
                    onClick={() => handleFormSubmit(values, true, false)}
                    color="green"
                    floated="right"
                    loading={savingAsDraft}
                  />
                )}
                <VerticalGap height={"1em"} />
                <Header as="h3" className={"objectives-header"}>
                  Objectives:
                </Header>
                <RoundedSegment>
                  <SegmentGroup>
                    {assignmentWithObjectives.objectives?.map((o) => (
                      <CourseObjective key={o.id} objective={o} />
                    ))}
                  </SegmentGroup>
                </RoundedSegment>
              </Form>
            );
          }}
        </Formik>
      </>
    </div>
  );
};

export default observer(AssignmentSubmissionForm);
