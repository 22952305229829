import "./TestimonialSlide.css";

interface TestimonialSlideProps {
  id: number;
  quote: string;
  name: string;
  bio: string;
  quoteIndex: number;
  index: number;
  length: number;
}

const TestimonialSlide: React.FC<TestimonialSlideProps> = ({
  id,
  quote,
  name,
  bio,
  quoteIndex,
  index,
  length,
}) => {
  let position = "nextSlide";
  if (quoteIndex === index) {
    position = "activeSlide";
  }
  if (quoteIndex === index - 1 || (index === 0 && quoteIndex === length - 1)) {
    position = "lastSlide";
  }

  return (
    <div className="TestimonialSlide">
      <article className={position} key={id}>
        <p className="text">{quote}</p>
        <h4 className="name">-- {name}</h4>
        <p className="bio">{bio}</p>
      </article>
    </div>
  );
};

export default TestimonialSlide;
