import "./VerticalGap.css";

interface VerticalGapProps {
  height: string;
}

const VerticalGap: React.FC<VerticalGapProps> = ({ height }) => (
  <div className={"VerticalGap"} style={{ height }} />
);

export default VerticalGap;
