import React from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import "./ConfirmationModal.css";

interface ConfirmationModalProps {
  prompt: JSX.Element | string;
  confirmButtonText?: string;
  onConfirm: () => void;
  cancelButtonText?: string;
  onCancel: () => void;
  showCancelButton?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  prompt,
  confirmButtonText = "Confirm",
  onConfirm,
  cancelButtonText = "Cancel",
  onCancel,
  showCancelButton = true,
}) => {
  const autoFocus = (element: Button | null) => element?.focus();

  return (
    <div className="ConfirmationModal">
      <Modal.Content>
        <Header as="h3">{prompt}</Header>
      </Modal.Content>
      <Modal.Actions className="actions">
        {showCancelButton && (
          <Button color="grey" icon="x" onClick={onCancel}>
            {cancelButtonText}
          </Button>
        )}
        <Button ref={autoFocus} color="blue" icon="check" onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </Modal.Actions>
    </div>
  );
};

export default ConfirmationModal;
