import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Header } from "semantic-ui-react";
import useValidParams from "../../../../hooks/useValidParameters";
import { useStore } from "../../../../stores/store";
import NoValuesFound from "../../../_common/misc/NoValuesFound";
import FlexContainer from "../../../_common/style/FlexContainer";
import VerticalGap from "../../../_common/style/spacing/VerticalGap";
import AssignmentFilter from "./AssignmentsSidebar";
import CourseAssignment, { CourseAssignmentSkeleton } from "./CourseAssignment";
import "./ViewAssignmentsForCourse.css";
import { filterAssignments } from "../../../../utilities/preferenceUtils";

const ViewAssignmentsForCourseSkeleton: React.FC = () => (
  <>
    <Header as="h3" content="Course Assignments" />
    <VerticalGap height="1rem" />
    <FlexContainer gap="1rem" flexDirection="column">
      <CourseAssignmentSkeleton />
      <CourseAssignmentSkeleton />
      <CourseAssignmentSkeleton />
      <CourseAssignmentSkeleton />
      <CourseAssignmentSkeleton />
    </FlexContainer>
  </>
);

const ViewAssignmentsForCourse: React.FC = () => {
  const { assignmentStore, courseStore, persistentPreferenceStore } = useStore();
  const { course } = courseStore;
  const { assignmentFilter } = persistentPreferenceStore;
  const {
    assignmentsByCourse,
    loadAssignmentsByCourse,
    assignmentTypes,
    loadAssignmentTypes,
    hasLoadedAssignmentTypes,
    hasLoadedAssignmentsByCourse,
  } = assignmentStore;
  const { courseID } = useValidParams<{ courseID: string }>();

  useEffect(() => {
    loadAssignmentsByCourse(courseID);
  }, [assignmentsByCourse, loadAssignmentsByCourse, courseID]);

  useEffect(() => {
    loadAssignmentTypes(courseID);
  }, [loadAssignmentTypes, assignmentTypes, courseID]);

  if (
    !assignmentsByCourse ||
    !course ||
    !assignmentTypes ||
    !hasLoadedAssignmentTypes() ||
    !hasLoadedAssignmentsByCourse()
  )
    return <ViewAssignmentsForCourseSkeleton />;

  const filteredAssignments = filterAssignments(assignmentsByCourse, assignmentFilter);

  return (
    <>
      <Header as="h2">Course Assignments</Header>

      <div className="AssignmentsForCourse">
        <div className="assignments">
          {filteredAssignments.map((o) => (
            <CourseAssignment key={o.id} assignment={o} course={course} />
          ))}
          {assignmentsByCourse.length !== 0 && filteredAssignments.length === 0 && (
            <NoValuesFound
              iconName="ban"
              text="There are no assignments under the filter you specified."
            />
          )}
          {assignmentsByCourse.length === 0 && (
            <NoValuesFound
              iconName="pencil alternate"
              text="There are no posted assignments yet."
            />
          )}
        </div>
        <div className="filter">
          <AssignmentFilter
            assignmentsByCourse={assignmentsByCourse}
            assignmentTypes={assignmentTypes}
          />
        </div>
      </div>
    </>
  );
};

export default observer(ViewAssignmentsForCourse);
