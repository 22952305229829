import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Icon, SemanticICONS } from "semantic-ui-react";

import { useStore } from "../../stores/store";
import { ToastColor } from "../../stores/toastStore";
import { createClassName } from "../../utilities/utils";
import "./ToastContainer.css";

const ToastContainer: React.FC = () => {
  const { toastStore } = useStore();
  const location = useLocation();
  const { updateLocation } = toastStore;
  const { display, message, color, location: locationKey, action } = toastStore.toastInstance;

  useEffect(() => {
    if (location.key !== locationKey) {
      if (locationKey === undefined) {
        updateLocation(location.key);
      } else {
        toastStore.hideToast();
      }
    }
  }, [location.key, locationKey, toastStore, updateLocation]);

  // If there is no toast, don't display anything.
  if (!display) return <></>;

  const getIcon = (toastColor: ToastColor): SemanticICONS => {
    switch (toastColor) {
      case "green":
        return "thumbs up";
      case "red":
        return "warning sign";
      case "blue":
        return "group";
      case "orange":
        return "warning circle";
      default:
        return "thumbs up";
    }
  };

  return (
    <div
      className={createClassName("ToastContainer ui toast-box-shadow", color)}
      onClick={() => toastStore.hideToast()}
    >
      <div className={`ribbon-label ${color}`}>
        <div className="ribbon-label-icon">
          <Icon name={getIcon(color)} fitted />
        </div>
      </div>
      {display && (
        <>
          {message}
          {action && (
            <div className="action-text" role="button" onClick={action.action}>
              {action.text}
            </div>
          )}
          <div className="x-icon">
            <Icon name="x" size="small" fitted />
          </div>
        </>
      )}
    </div>
  );
};

export default observer(ToastContainer);
