import { observer } from "mobx-react-lite";
import { Header } from "semantic-ui-react";
import { AssignmentSubmissionFormat } from "../../../../models/Assignment";
import DragAndDropFile from "../../../_common/file/DragAndDropFile";
import HorizontalIndent from "../../../_common/style/spacing/HorizontalIndent";
import TextEditor from "../../../_common/textEditing/TextEditor";
import "./SubmissionDataSelection.css";
import SubmissionURLTextBox, { SubmissionURLTextInputProps } from "./SubmissionURLTextInput";

interface TextSubmissionProps {
  setFieldValue: (field: string, value: unknown) => void;
  data: string;
}

interface FileUploadsSubmissionProps {
  connectedComponentID: string;
  courseID: string;
  userID: string;
}

interface SubmissionDataSelectionProps {
  submissionTypes: AssignmentSubmissionFormat[];
  textSubmissionProps?: TextSubmissionProps;
  fileUploadsSubmissionProps?: FileUploadsSubmissionProps;
  urlSubmissionProps?: SubmissionURLTextInputProps;
}

const SubmissionDataSelection: React.FC<SubmissionDataSelectionProps> = ({
  submissionTypes,
  textSubmissionProps,
  urlSubmissionProps,
  fileUploadsSubmissionProps,
}) => (
  <div className={"SubmissionDataSelection"}>
    {submissionTypes.includes("Text") && textSubmissionProps && (
      <>
        <Header as="h4">Add Text:</Header>
        <HorizontalIndent>
          <TextEditor
            onChange={(value) => textSubmissionProps?.setFieldValue("data", value)}
            placeholder={"Your submission here..."}
            defaultValue={textSubmissionProps.data}
          />
        </HorizontalIndent>
      </>
    )}
    {submissionTypes.includes("FileUploads") && fileUploadsSubmissionProps && (
      <>
        <Header as="h4">Add Files:</Header>
        <HorizontalIndent>
          <DragAndDropFile
            connectedComponentID={fileUploadsSubmissionProps.connectedComponentID}
            uploadFileCategory="submission"
            courseID={fileUploadsSubmissionProps.courseID}
            userID={fileUploadsSubmissionProps.userID}
            showUploadComponentByDefault={submissionTypes.length === 1}
          />
        </HorizontalIndent>
      </>
    )}
    {submissionTypes.includes("URL") && urlSubmissionProps && (
      <>
        <Header as="h4">Add Link:</Header>
        <HorizontalIndent>
          <SubmissionURLTextBox {...urlSubmissionProps} />
        </HorizontalIndent>
      </>
    )}
  </div>
);
export default observer(SubmissionDataSelection);
