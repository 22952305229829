import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { FormikErrors } from "formik";
import { Button, Header, Input } from "semantic-ui-react";
import { Objective } from "../../../../models/Objective";
import { FormikSetFieldValueFunction } from "../../../../utilities/formUtils";
import FlexContainer from "../../../_common/style/FlexContainer";
import VerticalGap from "../../../_common/style/spacing/VerticalGap";
import {
  CreateOrEditPollingAssignmentFormValues,
  PollingRuleMasteryPercentages,
} from "./CreateOrEditPollingAssignment";
import "./CreateOrEditPollingAssignment.css";
import { useStore } from "../../../../stores/store";
import { objectMap } from "../../../../utilities/collectionUtils";
import { PollingAssignmentAssessmentDefaultRule } from "../../../../models/PollingAssignmentAssessmentDefaultRule";
import { emptyID } from "../../../../utilities/submissionUtils";
import { MasteryLevelScheme } from "../../../../models/MasteryLevelScheme";
import { PollingAssignmentAssessmentRule } from "../../../../models/PollingAssignmentAssessmentRule";
import useBooleanState from "../../../../hooks/useBooleanState";

interface MasteryLevelSchemeInputsProps {
  selectedObjective: Objective;
  values: CreateOrEditPollingAssignmentFormValues;
  errors: FormikErrors<CreateOrEditPollingAssignmentFormValues>;
  setFieldValue: FormikSetFieldValueFunction;
  pollingAssessmentRules: PollingAssignmentAssessmentRule[] | undefined;
  courseID: string;
}

// Creates default mastery level percentages object using existing default rules or defined default values based on the number of mastery levels.
// E.g. a mastery level scheme with 4 levels will result in 75, 50, 25, and 0 being the default percentages (this method excludes mastery levels that are excluded from grade calculations)
const createDefaultMasteryLevelPercentages = (
  objectiveID: string,
  masteryLevelScheme: MasteryLevelScheme,
  defaultRules: PollingAssignmentAssessmentDefaultRule[]
): PollingRuleMasteryPercentages => {
  const newMasteryPercentages: PollingRuleMasteryPercentages = {};
  const masteryLevels = masteryLevelScheme.masteryLevels
    .filter((ml) => !ml.excludeInGradeCalculations)
    .sort((a, b) => a.relativeOrderIndex - b.relativeOrderIndex);

  masteryLevels.forEach(({ id: masteryLevelID }, index) => {
    newMasteryPercentages[masteryLevelID] =
      defaultRules
        .find((r) => r.objectiveID === objectiveID && r.masteryLevelID === masteryLevelID)
        ?.percentComplete.toString() ??
      (((masteryLevels.length - index - 1) * 100) / masteryLevels.length).toString();
  });

  return newMasteryPercentages;
};

const MasteryLevelSchemeInputsSkeleton: React.FC = () => (
  <div className="MasteryLevelSchemeInputsSkeleton"></div>
);

const MasteryLevelSchemeInputs: React.FC<MasteryLevelSchemeInputsProps> = ({
  selectedObjective,
  values,
  setFieldValue,
  courseID,
  errors,
  pollingAssessmentRules,
}) => {
  const { pollingStore } = useStore();
  const { defaultRules, loadDefaultRules, hasLoadedDefaultRules, createOrUpdateDefaultRules } =
    pollingStore;
  const [settingNewDefaultPercentages, setSettingNewDefaultPercentages] = useBooleanState();

  useEffect(() => {
    loadDefaultRules(courseID);
  }, [courseID]);

  // Updates the forms mastery level percentages with default values or existing rules when present
  const updateFormMasteryLevelPercentages = (
    overrideDefaultPercentagesWithExistingRules: boolean
  ) => {
    if (!defaultRules || !selectedObjective.masteryLevelScheme) return;

    const defaultMasteryLevelPercentages = createDefaultMasteryLevelPercentages(
      selectedObjective.id,
      selectedObjective.masteryLevelScheme,
      defaultRules
    );

    if (overrideDefaultPercentagesWithExistingRules)
      selectedObjective.masteryLevelScheme.masteryLevels
        .map(({ id: masteryLevelID }) =>
          pollingAssessmentRules?.find(
            (r) => r.masteryLevelID === masteryLevelID && r.objectiveID === selectedObjective.id
          )
        )
        .forEach((rule) => {
          if (rule) {
            defaultMasteryLevelPercentages[rule.masteryLevelID] = rule.percentComplete.toString();
          }
        });

    setFieldValue("masteryPercentages", defaultMasteryLevelPercentages);
  };

  // When w rules are loaded, reset the mastery level percentages for this objective by searching default rules
  useEffect(() => {
    updateFormMasteryLevelPercentages(false);
  }, [defaultRules]);

  // When an objective is switched, reset the mastery level percentages and prioritize existing rules
  useEffect(() => {
    updateFormMasteryLevelPercentages(true);
  }, [selectedObjective]);

  if (!defaultRules || !hasLoadedDefaultRules(courseID))
    return <MasteryLevelSchemeInputsSkeleton />;

  const scheme = selectedObjective.masteryLevelScheme;

  const masteryLevels = scheme?.masteryLevels
    .filter((ml) => !ml.excludeInGradeCalculations)
    .sort((a, b) => a.relativeOrderIndex - b.relativeOrderIndex);

  if (!scheme || !masteryLevels) {
    return <></>;
  }

  // Checks if the current mastery level percentages are different from any existing default rules for the current objective
  const areDefaultRulesChanged = () => {
    const defaultMasteryLevelPercentages = createDefaultMasteryLevelPercentages(
      selectedObjective.id,
      scheme,
      defaultRules
    );

    return masteryLevels.some(
      ({ id: masteryLevelID }) =>
        values.masteryPercentages[masteryLevelID] !== defaultMasteryLevelPercentages[masteryLevelID]
    );
  };

  const updateDefaultRules = async () => {
    const newDefaultRules = objectMap(
      values.masteryPercentages,
      (masteryLevelID, percentComplete) =>
        ({
          id:
            defaultRules.find(
              (dr) =>
                dr.masteryLevelID === masteryLevelID && dr.objectiveID === selectedObjective.id
            )?.id ?? emptyID,
          courseID,
          masteryLevelID,
          objectiveID: selectedObjective.id,
          percentComplete: Number(percentComplete),
        } as PollingAssignmentAssessmentDefaultRule)
    );

    setSettingNewDefaultPercentages(true);

    await createOrUpdateDefaultRules(courseID, newDefaultRules);

    setSettingNewDefaultPercentages(false);
  };

  return (
    <div className="MasteryLevelSchemeInputs" key={scheme.id}>
      <VerticalGap height=".25rem" />
      <Header as="h4" content={`Mastery Level Scheme for ${selectedObjective.shortName}`} />
      <label className="mastery-level-label">
        <ul>
          {masteryLevels.map((level, index) => (
            <div key={level.id}>
              <li>
                {index === masteryLevels.length - 1 ? (
                  <>Otherwise, students will receive a rating of {level.name}</>
                ) : (
                  <FlexContainer
                    className="mastery-level-container"
                    justifyContent="space-between"
                    gap="1rem"
                  >
                    <span>Give students a(n) {level.name} if they complete at least</span>
                    <div className="mastery-level-input-container">
                      <Input
                        className="mastery-level-input"
                        type="number"
                        value={
                          values.masteryPercentages
                            ? values.masteryPercentages[level.id] ?? "0"
                            : "0"
                        }
                        min="0"
                        max="100"
                        onChange={(e) =>
                          setFieldValue(`masteryPercentages.${level.id}`, e.target.value, true)
                        }
                      />
                      <span className="mastery-percent">%</span>
                    </div>
                  </FlexContainer>
                )}
              </li>
            </div>
          ))}
        </ul>
      </label>
      <Button
        icon="checkmark"
        onClick={updateDefaultRules}
        color="blue"
        loading={settingNewDefaultPercentages}
        disabled={!!errors.masteryPercentages || !areDefaultRulesChanged()}
        content={`Use these percentages as default values for ${selectedObjective.shortName}`}
        type="button"
      />
    </div>
  );
};

export default observer(MasteryLevelSchemeInputs);
