import { Dropdown, Header, SemanticICONS } from "semantic-ui-react";
import "./FormDropdownInput.css";

type FormDropdownInputOption = {
  label: string;
  id: string;
  icon?: SemanticICONS;
};

interface FormDropdownInputProps {
  options: FormDropdownInputOption[];
  label: string;
  onOptionSelect: (option: FormDropdownInputOption) => void;
  placeholder: string;
  error?: string | boolean;
  required?: boolean;
  defaultValue?: string;
  disabled?: boolean;
}

const FormDropdownInput: React.FC<FormDropdownInputProps> = ({
  options,
  label,
  onOptionSelect,
  placeholder,
  error,
  required,
  defaultValue,
  disabled,
}) => (
  <div className="FormDropdownInput">
    <Header as="h4">
      {label}
      {required && <span className={"required"}> *</span>}
    </Header>
    <Dropdown
      disabled={disabled}
      placeholder={placeholder}
      defaultValue={defaultValue}
      selection
      options={options.map((option) => ({ key: option.id, text: option.label, value: option.id }))}
      onChange={(_, { value }) =>
        onOptionSelect(options.find(({ id }) => id === value) as FormDropdownInputOption)
      }
    />
    {error && <span className="error-message">{error}</span>}
  </div>
);

export default FormDropdownInput;
