import { observer } from "mobx-react-lite";
import React from "react";
import { Icon, Label, Popup, Table } from "semantic-ui-react";
import useValidParams from "../../../../../../hooks/useValidParameters";
import { Assignment } from "../../../../../../models/Assignment";
import { GradeDistinction } from "../../../../../../models/GradeDistinction";
import { GradeRecipeConstraint } from "../../../../../../models/GradeRecipeConstraint";
import GradeRecipeConstraintExtension from "../../../../../../models/GradeRecipeConstraintExtension";
import { GradeRecipeIngredient } from "../../../../../../models/GradeRecipeIngredient";
import { MasteryLevel } from "../../../../../../models/MasteryLevel";
import { Objective } from "../../../../../../models/Objective";
import { ObjectiveConstraint } from "../../../../../../models/ObjectiveConstraint";
import { RatedObjective } from "../../../../../../models/RatedObjective";
import { getGraphicDataForBestRatings } from "../../../../../../utilities/assessmentGraphicUtils";
import {
  calculateDaysBetweenDates,
  dateIsToday,
  formatDateDownToDayNoYear,
  formatDateOnlyHoursAndMinutes,
} from "../../../../../../utilities/dateTimeUtils";
import {
  AssignmentRatingsMappedToMasteryLevel,
  constraintString,
  doesStudentWorkFulfillIngredient,
  getBestRatingsForObjectiveAndMasteryLevels,
  gradeCalculationsTableCellClass,
} from "../../../../../../utilities/gradeCalculationUtils";
import TeachFrontNavLink from "../../../../../../utilities/routing/components/TeachFrontNavLink";
import { areAnyAssignmentRatingsUnpublished } from "../../../../../../utilities/submissionUtils";
import {
  capitalizeWords,
  createClassName,
  indefiniteArticle,
} from "../../../../../../utilities/utils";
import AssessmentSummaryGraphic from "../../../../../_common/assessmentGraphic/AssessmentSummaryGraphic";
import HorizontalIndent from "../../../../../_common/style/spacing/HorizontalIndent";

interface GradeCalculationsTableRowProps {
  studentID: string;
  gradeObjectiveConstraint: ObjectiveConstraint;
  gradeRecipeConstraint: GradeRecipeConstraint;
  gradeDistinctionForStudent: GradeDistinction | undefined;
  defaultGradeDistinction: GradeDistinction | undefined;
  assignmentToMasteryLevelSchemeRatings:
    | Map<string, Map<string, AssignmentRatingsMappedToMasteryLevel>>
    | undefined;
  parentObjectiveToAssignmentMap: Map<string, Assignment<RatedObjective>[]> | undefined;
  allObjectives: Map<string, Objective>;
  collapsed: boolean;
  setCollapsed: (gradeRecipeIngredientID: string) => void;
}

/**
 * Displays the constraints and thresholds for earning grade distinctions.
 */
const GradeCalculationsTableRow: React.FC<GradeCalculationsTableRowProps> = ({
  studentID,
  gradeObjectiveConstraint,
  gradeRecipeConstraint,
  gradeDistinctionForStudent,
  defaultGradeDistinction,
  assignmentToMasteryLevelSchemeRatings,
  parentObjectiveToAssignmentMap,
  allObjectives,
  collapsed,
  setCollapsed,
}) => {
  const { courseID } = useValidParams<{ courseID: string }>();

  const numberOfColumns = gradeObjectiveConstraint.gradeRecipeConstraints[0]
    ? gradeObjectiveConstraint.gradeRecipeConstraints[0].gradeRecipeIngredients.length + 2
    : 2;

  if (!parentObjectiveToAssignmentMap) return <></>;

  // get mastery level scheme
  let masteryLevels = gradeObjectiveConstraint.objective.masteryLevelScheme?.masteryLevels;
  const relevantMasteryLevels: MasteryLevel[] = [];

  // get this mastery level
  const constraintMasteryLevel = gradeRecipeConstraint.masteryLevel;
  const constraintExtension = gradeRecipeConstraint.extension;

  // if the extension is "or better", we want to reverse the list of mastery levels
  if (constraintExtension === GradeRecipeConstraintExtension.OR_BETTER) {
    masteryLevels = masteryLevels?.slice().reverse();
  }

  // collect the mastery levels that are relevant to this constraint
  masteryLevels?.forEach((masteryLevel) => {
    // if this mastery level is better, below, or equal to the constraint mastery level, then include it
    if (
      (constraintExtension === GradeRecipeConstraintExtension.OR_BETTER &&
        masteryLevel.relativeOrderIndex < (constraintMasteryLevel?.relativeOrderIndex || 0)) ||
      (constraintExtension === GradeRecipeConstraintExtension.OR_BELOW &&
        masteryLevel.relativeOrderIndex >
          (constraintMasteryLevel?.relativeOrderIndex || Number.MAX_SAFE_INTEGER)) ||
      masteryLevel.id === constraintMasteryLevel?.id
    )
      relevantMasteryLevels.push(masteryLevel);
  });

  const relevantRatings = getBestRatingsForObjectiveAndMasteryLevels(
    gradeObjectiveConstraint.objective.id,
    relevantMasteryLevels.map((masteryLevel) => masteryLevel.id),
    studentID,
    parentObjectiveToAssignmentMap.get(gradeObjectiveConstraint.objective.id),
    allObjectives
  );

  let rawQuantity = -1;
  let numTotalUnexcusedRatings = -1;

  let lastIngredientThatStudentFulfilled: GradeRecipeIngredient | undefined;

  // gets a cell in the table that communicates a threshold and whether the student met that threshold
  const ingredientCell = (gradeRecipeIngredient: GradeRecipeIngredient | undefined) => {
    let calculatedQuantity = -1;
    let fulfilled = false;

    if (gradeRecipeIngredient && gradeRecipeConstraint) {
      // determine whether student work fulfills this ingredient
      ({ calculatedQuantity, rawQuantity, fulfilled, numTotalUnexcusedRatings } =
        doesStudentWorkFulfillIngredient(
          assignmentToMasteryLevelSchemeRatings,
          gradeRecipeConstraint,
          gradeRecipeIngredient,
          gradeRecipeConstraint.masteryLevelID,
          gradeObjectiveConstraint.objective
        ));
      if (!lastIngredientThatStudentFulfilled && fulfilled && calculatedQuantity >= 0) {
        lastIngredientThatStudentFulfilled = gradeRecipeIngredient;
      }
    }

    // get a special class, if any, that communicates student's performance with this ingredient
    const earned = gradeCalculationsTableCellClass(
      fulfilled,
      numTotalUnexcusedRatings,
      gradeRecipeIngredient?.gradeDistinction ?? defaultGradeDistinction,
      gradeObjectiveConstraint.objective,
      gradeDistinctionForStudent,
      defaultGradeDistinction,
      gradeRecipeConstraint,
      lastIngredientThatStudentFulfilled ?? undefined
    );

    // actually make the cell
    return (
      <Popup
        key={gradeRecipeIngredient?.id ?? defaultGradeDistinction?.id}
        position="top center"
        trigger={
          <Table.Cell className={earned}>
            {gradeRecipeIngredient?.quantity !== undefined && gradeRecipeIngredient?.quantity >= 0
              ? gradeRecipeIngredient.quantity
              : "*"}
          </Table.Cell>
        }
        content={constraintString({
          gradeRecipeConstraint,
          defaultGradeDistinction,
          gradeRecipeIngredient,
          gradeDistinction: gradeRecipeIngredient
            ? gradeRecipeIngredient.gradeDistinction
            : defaultGradeDistinction,
          objective: gradeObjectiveConstraint.objective,
          fulfilled,
          calculatedQuantity,
          rawQuantity,
          numTotalRatings: numTotalUnexcusedRatings,
        })}
      />
    );
  };

  const whenAreReassessmentRequestsDue = (date: Date) => {
    const daysRemaining = calculateDaysBetweenDates(new Date(), date);
    const isToday = dateIsToday(date);
    const formattedDate = formatDateDownToDayNoYear(date);
    const formattedTime = formatDateOnlyHoursAndMinutes(date);

    let message = `(Reassessment requests are due `;
    if (daysRemaining === 0 && isToday) {
      message += `today at ${formatDateOnlyHoursAndMinutes(date)}.)`;
    } else if (daysRemaining === 0) {
      message += `tomorrow, ${formattedDate}, at ${formattedTime}.)`;
    } else {
      message += `in ${daysRemaining} days, on ${formattedDate}.)`;
    }
    return message;
  };

  return (
    <>
      <Table.Row
        key={gradeRecipeConstraint.id}
        className={createClassName({ name: "collapsed", apply: collapsed, else: "expanded" })}
        onClick={() => setCollapsed(gradeRecipeConstraint.id)}
      >
        <>
          <Table.Cell className="constraint-description">
            {constraintString({
              gradeRecipeConstraint,
              defaultGradeDistinction,
              frontMatter: <Icon name={`caret ${collapsed ? "right" : "down"}`} />,
            })}
          </Table.Cell>
          {gradeRecipeConstraint.gradeRecipeIngredients.map(
            (gradeRecipeIngredient: GradeRecipeIngredient) => ingredientCell(gradeRecipeIngredient)
          )}
          {ingredientCell(undefined)}
        </>
      </Table.Row>
      {!collapsed && (
        <Table.Row className="expanded-details-of-constraint-row">
          <Table.Cell colSpan={numberOfColumns}>
            <h4>
              Your "{gradeRecipeConstraint.masteryLevel?.name}
              {gradeRecipeConstraint.extension !== GradeRecipeConstraintExtension.FULL_STOP && (
                <> {capitalizeWords(gradeRecipeConstraint.extension)}</>
              )}
              {'" '}
              Ratings for the {gradeObjectiveConstraint.objective.shortName} Objective:
            </h4>
            {relevantMasteryLevels.map((relevantMasteryLevel) => (
              <>
                {relevantMasteryLevel.id === gradeRecipeConstraint.masteryLevelID &&
                  numTotalUnexcusedRatings > 0 && (
                    <p>
                      You earned{" "}
                      <strong>
                        {Math.round(Math.max((100 * rawQuantity) / numTotalUnexcusedRatings))}%
                      </strong>{" "}
                      {gradeRecipeConstraint.masteryLevel?.name}{" "}
                      {constraintExtension !== GradeRecipeConstraintExtension.FULL_STOP
                        ? constraintExtension
                        : ""}{" "}
                      ratings ({rawQuantity} of the {numTotalUnexcusedRatings} ratings for the{" "}
                      {gradeObjectiveConstraint.objective.shortName} objective). Your work meets the
                      threshold for{" "}
                      {indefiniteArticle(
                        lastIngredientThatStudentFulfilled?.gradeDistinction?.name
                      )}{" "}
                      {lastIngredientThatStudentFulfilled?.gradeDistinction?.name}.
                    </p>
                  )}
                {(relevantRatings.get(relevantMasteryLevel.id)?.length || 0) > 0 && (
                  <h5>
                    You earned {relevantRatings.get(relevantMasteryLevel.id)?.length || 0}{" "}
                    {relevantMasteryLevel.name} rating
                    {relevantRatings.get(relevantMasteryLevel.id)?.length !== 1 ? "s" : ""}:
                  </h5>
                )}
                <HorizontalIndent>
                  {relevantRatings.get(relevantMasteryLevel.id)?.map((ratingToAssignment) => (
                    <div className="earned-rating" key={ratingToAssignment.rating.id}>
                      <AssessmentSummaryGraphic
                        data={getGraphicDataForBestRatings(
                          studentID,
                          ratingToAssignment.assignment.objectives ?? []
                        )}
                        size="4em"
                        assignmentID={ratingToAssignment.assignment.id}
                        userID={studentID}
                        submissionID={ratingToAssignment.rating.submissionID}
                        linkToSubmission
                        unpublishedRatings={areAnyAssignmentRatingsUnpublished(
                          studentID,
                          ratingToAssignment.assignment.objectives ?? []
                        )}
                        highlightIndex={ratingToAssignment.assignment.objectives?.findIndex(
                          (objective) => objective.id === ratingToAssignment.rating.objectiveID
                        )}
                      />
                      <Label
                        as={TeachFrontNavLink}
                        to={"CourseHomeSyllabusTab"}
                        params={{ courseID }}
                        size="small"
                        className={createClassName(
                          allObjectives.get(ratingToAssignment.rating.objectiveID)?.color,
                          "rounded-0"
                        )}
                      >
                        {allObjectives.get(ratingToAssignment.rating.objectiveID)?.shortName}
                      </Label>{" "}
                      in
                      <TeachFrontNavLink
                        to={"CourseHomeAssignmentDetailsTab"}
                        params={{ courseID, assignmentID: ratingToAssignment.assignment.id }}
                        className="assignment-name"
                      >
                        {ratingToAssignment.assignment.name}
                      </TeachFrontNavLink>
                      {ratingToAssignment.assignment.resubmissionDueDate &&
                        ratingToAssignment.assignment.resubmissionDueDate > new Date() && (
                          <>
                            {" "}
                            {whenAreReassessmentRequestsDue(
                              ratingToAssignment.assignment.resubmissionDueDate
                            )}
                          </>
                        )}
                    </div>
                  ))}
                </HorizontalIndent>
              </>
            ))}
            {numTotalUnexcusedRatings === 0 &&
              "You have not earned any ratings for this objective, yet."}
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

export default observer(GradeCalculationsTableRow);
