// eslint-disable-next-line import/no-cycle
import api, { ErrorHandlerPackage } from "../api/api";
import { GroupSet } from "../models/GroupSet";

import { StoreValue } from "./storeValue";

export default class GroupSetsStore {
  private groupSetsRegistry = new StoreValue<GroupSet[], { courseID: string }>();

  reset = () => {
    this.groupSetsRegistry.reset();
  };

  get groupSets() {
    return this.groupSetsRegistry.value;
  }

  hasLoadedGroupSets = (courseID: string) => this.groupSetsRegistry.fresh(false, { courseID });

  loadGroupSets = async (courseID: string, errorHandlerPackage?: ErrorHandlerPackage) => {
    if (this.groupSetsRegistry.fresh(true, { courseID })) return;

    this.groupSetsRegistry.setLoading(true, { courseID });

    const groupSets = await api.GroupSets.listForCourse(courseID, errorHandlerPackage);

    if (groupSets) {
      this.groupSetsRegistry.setAll(groupSets, { courseID });
    }

    this.groupSetsRegistry.setLoading(false);
  };
}
