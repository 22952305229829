import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Header } from "semantic-ui-react";
import useBooleanState from "../../../../hooks/useBooleanState";
import useCurrentUserCourseRole from "../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../hooks/useValidParameters";
import { Course } from "../../../../models/Course";
import { useStore } from "../../../../stores/store";
import { getDateOnly } from "../../../../utilities/dateTimeUtils";
import { emptyID } from "../../../../utilities/submissionUtils";
import UserCard, { UserCardSkeleton } from "../../../_common/cards/UserCard";
import FlexContainer from "../../../_common/style/FlexContainer";
import PageContainer from "../../../_common/style/PageContainer";
import HorizontalIndent from "../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../_common/style/spacing/VerticalGap";
import UserInputDisplay from "../../../_common/textEditing/UserInputDisplay";
import ViewVerticalCalendar from "../dashboard/ViewVerticalCalendar";
import GradeCalculationsTable from "../grade-calculations/user/[userID]/GradeCalculationsTable";
import PageView from "../page/[pageID]/PageView";
import "./CourseDetails.css";
import CourseMasteryLevelScheme from "./CourseMasteryLevelScheme";
import CourseObjective, { CourseObjectiveSkeleton } from "./CourseObjective";

interface CourseDetailsSkeletonProps {
  course: Course;
}

const CourseDetailsSkeleton: React.FC<CourseDetailsSkeletonProps> = ({ course }) => (
  <PageContainer
    className="CourseDetailsSkeleton"
    header={`${course.shortName}: ${course.longName}`}
    subheader={course?.term}
  >
    <Header as="h3">Instructors:</Header>
    <HorizontalIndent>
      <FlexContainer gap="0.5em" flexDirection="column">
        <UserCardSkeleton />
        <UserCardSkeleton />
        <UserCardSkeleton />
      </FlexContainer>
    </HorizontalIndent>
    <Header as="h3">Course Description:</Header>
    <HorizontalIndent>
      <UserInputDisplay
        editButtonContent="Edit Course Description"
        placeholder="Edit course description here..."
        content={course.courseDescription}
        data-print-id="hide"
        editable={false}
      />
    </HorizontalIndent>
    <Header as="h3" data-print-id="hide">
      Course Objectives:
    </Header>
    <HorizontalIndent>
      The objectives for this course define the knowledge and skill goals expected of students by
      the end of the course. Assignments that relate to each objective are included in the objective
      definition.
    </HorizontalIndent>
    <FlexContainer gap="1rem" className="ObjectivesForCourse" flexDirection="column">
      <CourseObjectiveSkeleton />
      <CourseObjectiveSkeleton />
      <CourseObjectiveSkeleton />
    </FlexContainer>
  </PageContainer>
);

const CourseDetails: React.FC = () => {
  const { courseID } = useValidParams<{ courseID: string }>();

  const { courseStore, objectiveStore, masteryLevelSchemeStore, userStore, pagesStore } =
    useStore();
  const { user } = userStore;
  const { createOrUpdate: createPage } = pagesStore;
  const { hierarchicalObjectivesWithAssignments, loadHierarchicalObjectivesWithAssignments } =
    objectiveStore;
  const {
    currentCourse,
    loadCurrentCourse,
    teachingTeamForCourse,
    loadTeachingTeamForCourse,
    createOrUpdateCourse,
  } = courseStore;
  const { loadMasteryLevelSchemesForCourse, masteryLevelSchemesForCourse } =
    masteryLevelSchemeStore;
  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);
  const [creatingPage, setCreatingPage] = useBooleanState();

  useEffect(() => {
    loadCurrentCourse(courseID);
    loadTeachingTeamForCourse(courseID);
    loadHierarchicalObjectivesWithAssignments(courseID);
    loadMasteryLevelSchemesForCourse(courseID);
  }, [
    courseID,
    currentCourse,
    loadCurrentCourse,
    loadHierarchicalObjectivesWithAssignments,
    loadMasteryLevelSchemesForCourse,
    loadTeachingTeamForCourse,
  ]);

  if (!currentCourse) return <></>;

  if (
    !teachingTeamForCourse ||
    !hierarchicalObjectivesWithAssignments ||
    !masteryLevelSchemesForCourse ||
    !user
  )
    return <CourseDetailsSkeleton course={currentCourse} />;

  return (
    <PageContainer
      className="CourseDetails"
      header={`${currentCourse.shortName}: ${currentCourse.longName}`}
      subheader={currentCourse?.term}
    >
      <Header as="h3">
        Instructor
        {teachingTeamForCourse.filter(
          (ttm) => ttm.courseRole === "Instructor-Owner" || ttm.courseRole === "Instructor"
        ).length > 1
          ? "s"
          : ""}
        :
      </Header>
      <HorizontalIndent>
        <FlexContainer gap="0.5em" flexDirection="column">
          {teachingTeamForCourse
            .filter((ttm) => ttm.courseRole === "Instructor-Owner")
            .map((ttm) => (
              <UserCard user={ttm} key={ttm.userID} />
            ))}
          {teachingTeamForCourse
            .filter((ttm) => ttm.courseRole === "Instructor")
            .map((ttm) => (
              <UserCard user={ttm} key={ttm.userID} />
            ))}
        </FlexContainer>
      </HorizontalIndent>
      {teachingTeamForCourse.some((ttm) => ttm.courseRole?.includes("TA")) && (
        <>
          <Header as="h3">
            Teaching Assistant
            {teachingTeamForCourse.filter((ttm) => ttm.courseRole?.includes("TA")).length > 1
              ? "s"
              : ""}
            :
          </Header>
          <HorizontalIndent>
            <FlexContainer gap="0.5em" flexDirection="column">
              {teachingTeamForCourse
                .filter((ttm) => ttm.courseRole?.includes("TA"))
                .map((ttm) => (
                  <UserCard user={ttm} key={ttm.userID} />
                ))}
            </FlexContainer>
          </HorizontalIndent>
        </>
      )}
      <Header as="h3">Course Description:</Header>
      <HorizontalIndent>
        <UserInputDisplay
          editButtonContent="Edit Course Description"
          placeholder="Edit course description here..."
          content={currentCourse.courseDescription}
          data-print-id="hide"
          editable={isUserTeachingTeam}
          onSave={async (newDescription) => {
            await createOrUpdateCourse(user?.userID, {
              ...currentCourse,
              startDate: getDateOnly(currentCourse.startDate),
              endDate: getDateOnly(currentCourse.endDate),
              courseDescription: newDescription,
            });
          }}
        />
      </HorizontalIndent>
      <Header as="h3" data-print-id="hide">
        Course Objectives:
      </Header>
      <HorizontalIndent>
        The objectives for this course define the knowledge and skill goals expected of students by
        the end of the course. Assignments that relate to each objective are included in the
        objective definition.
      </HorizontalIndent>
      <FlexContainer gap="0rem" className="ObjectivesForCourse" flexDirection="column">
        {hierarchicalObjectivesWithAssignments.map((o) => (
          <CourseObjective key={o.id} objective={o} />
        ))}
      </FlexContainer>
      {!currentCourse.syllabusID && isUserTeachingTeam && (
        <>
          <VerticalGap height="1em" />
          <Button
            color="green"
            content="Add more syllabus details"
            icon="plus"
            data-print-id="hide"
            loading={creatingPage}
            onClick={async () => {
              setCreatingPage(true);
              const page = await createPage({
                courseID: currentCourse.id,
                id: emptyID,
                isDraft: false,
                name: `Course Details`,
                pageContent: "",
              });
              if (page) {
                await createOrUpdateCourse(user.userID, {
                  ...currentCourse,
                  startDate: getDateOnly(currentCourse.startDate),
                  endDate: getDateOnly(currentCourse.endDate),
                  syllabusID: page.id,
                });
              }
              setCreatingPage(false);
            }}
          />
        </>
      )}
      {currentCourse.syllabusID && (
        <PageView courseID={currentCourse.id} pageID={currentCourse.syllabusID} />
      )}
      <Header as="h3">Appendix I: Mastery Level Schemes</Header>
      <HorizontalIndent>
        This course utilizes {masteryLevelSchemesForCourse.length.toString()} mastery level schemes
        for assessing student progress toward mastery.
        <div className="MasteryLevelSchemesForCourse">
          {masteryLevelSchemesForCourse.map((s) => (
            <CourseMasteryLevelScheme key={s.id} scheme={s} />
          ))}
        </div>
      </HorizontalIndent>
      <Header as="h3">Appendix II: Grade Calculations</Header>
      <GradeCalculationsTable studentID={user.userID} />
      <Header as="h3">Appendix III: Course Calendar</Header>
      <ViewVerticalCalendar allowFiltering={false} />
    </PageContainer>
  );
};

export default observer(CourseDetails);
