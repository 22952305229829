import { Form, Formik, FormikValues } from "formik";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Button, Header, Image } from "semantic-ui-react";
import logo from "../../assets/logo.svg";
import { useStore } from "../../stores/store";
import "./LoginPage.css";
import FormLogoInput from "../_common/form/FormLogoInput";
import Or from "../_common/style/spacing/Or";
import { invalidPasswordMessage, isValidEmail, isValidPassword } from "../../utilities/utils";
import { objectEvery } from "../../utilities/collectionUtils";
import TEACHFRONT_PATHS from "../../paths";

const LoginForm: React.FC = () => {
  const { userStore } = useStore();
  const navigate = useNavigate();

  const validate = (values: FormikValues) => {
    const errors: {
      email?: string;
      password?: string;
      errors?: string;
    } = {};
    if (!isValidEmail(values.email)) errors.email = "You must enter a valid email";
    if (!isValidPassword(values.password)) errors.password = invalidPasswordMessage;

    return errors;
  };

  return (
    <Formik
      validate={validate}
      initialTouched={{}}
      initialErrors={{}}
      initialValues={{ email: "", password: "", error: null }}
      onSubmit={(values, { setErrors }) =>
        userStore
          .login(values, {
            401: (status, message) => setErrors({ error: message }),
          })
          .then((success) => success && navigate("/"))
      }
    >
      {({ handleSubmit, isSubmitting, errors, touched, values }) => (
        <Form className="LoginPage ui form" onSubmit={handleSubmit} autoComplete="on">
          <Image src={logo} centered size="small" />
          <Header as="h2" content="Login to TeachFront" textAlign="center" />
          <div className="content">
            <FormLogoInput
              type={"email"}
              label={"Username"}
              name={"email"}
              placeholder={"Enter your email..."}
              iconName={"mail"}
              required={true}
              error={touched.email && errors.email}
            />
            <FormLogoInput
              type={"password"}
              label={"Password"}
              name={"password"}
              placeholder={"Enter your password..."}
              iconName={"lock"}
              required={true}
              error={touched.password && errors.password}
            />
            <div
              className="PasswordReset"
              onClick={() => navigate(TEACHFRONT_PATHS.ForgotPassword)}
            >
              Forgot your password?
            </div>
            {errors.error && <span className="error">Error: {errors.error}</span>}
            <Button
              loading={isSubmitting}
              content="Login"
              type="submit"
              color="grey"
              fluid
              disabled={!objectEvery(validate(values), (_, value) => !value)}
            />
            <Or />
            <Button
              content="Create an Account"
              type="button"
              onClick={() => navigate("/register")}
              color="blue"
              fluid
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default observer(LoginForm);
