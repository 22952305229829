import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Header, Icon } from "semantic-ui-react";
import api, { ErrorHandlerPackage } from "../../../../../api/api";
import completionCheck from "../../../../../assets/polling/completionCheck.png";
import completionCheck_results from "../../../../../assets/polling/completionCheck_results.png";
import completionCheckPlus from "../../../../../assets/polling/completionCheckPlus.png";
import confidenceCheck from "../../../../../assets/polling/confidenceCheck.png";
import confidenceCheck_results from "../../../../../assets/polling/confidenceCheck_results.png";
import exitTicket_1 from "../../../../../assets/polling/exitTicket_1.png";
import exitTicket_2 from "../../../../../assets/polling/exitTicket_2.png";
import exitTicket_3 from "../../../../../assets/polling/exitTicket_3.png";
import multipleChoice from "../../../../../assets/polling/multipleChoice.png";
import multipleChoice_results from "../../../../../assets/polling/multipleChoice_results.png";
import multipleChoicePlus from "../../../../../assets/polling/multipleChoicePlus.png";
import multipleChoicePlus_results from "../../../../../assets/polling/multipleChoicePlus_results.png";
import plusConfidenceCheck from "../../../../../assets/polling/plusConfidenceCheck.png";
import shortAnswer from "../../../../../assets/polling/shortAnswer.png";
import shortAnswer_results from "../../../../../assets/polling/shortAnswer_results.png";
import shortAnswer_plus from "../../../../../assets/polling/shortAnswerPlus.png";
import wordCloud from "../../../../../assets/polling/wordCloud.png";
import wordCloud_results from "../../../../../assets/polling/wordCloud_results.png";
import useBooleanState from "../../../../../hooks/useBooleanState";
import useCurrentUserCourseRole from "../../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../../hooks/useValidParameters";
import { PollingActivity } from "../../../../../models/polling/PollingActivity";
import { store, useStore } from "../../../../../stores/store";
import LoadingComponent from "../../../../../utilities/routing/components/LoadingComponent";
import { createClassName } from "../../../../../utilities/utils";
import FlexContainer from "../../../../_common/style/FlexContainer";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import PollingActivitiesForAssignment from "./_common/PollingActivitiesForAssignment";
import "./PollingAssignmentDetails.css";
import PollingInstructionsModal from "./PollingInstructionsModal";

export type FakePollingActivity = {
  id: string;
  name: string;
  openTime: Date;
  closeTime: Date;
  createdTime: Date;
  assignmentID: string;
  courseID: string;
  pollingActivityQuestions?: string[];
};

export type PollingActivityType = {
  className: string;
  onClick: (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage | undefined
  ) => Promise<PollingActivity>;
  name: JSX.Element;
  key: string;
  icon: JSX.Element;
  description: JSX.Element;
  images: string[] | undefined;
};

const PollingAssignmentDetails: React.FC = () => {
  const { courseID, assignmentID } = useValidParams<{
    courseID: string;
    assignmentID: string;
  }>();
  const { assignmentStore, pollingStore, userStore } = useStore();
  const {
    loadAssignmentWithObjectivesAndMasteryLevels,
    assignmentWithObjectivesAndMasteryLevels,
    hasLoadedAssignmentWithObjectivesAndMasteryLevels,
  } = assignmentStore;
  const { user } = userStore;
  const { loadPollingActivityTemplates, activePollingActivity, setActivePollingActivity } =
    pollingStore;
  const [activeAccordionID, setActiveAccordionID] = useState<string>("");

  const multipleChoiceButton = {
    className: "multiple-choice",
    onClick: api.PollingActivities.createMultipleChoiceFromTemplate,
    name: <>Multiple Choice</>,
    key: "list ul",
    icon: <Icon className="polling-details-icon" size="big" name="list ul" />,
    description: (
      <p>
        This activity type offers students their choice of five answers, simply labeled A, B, C, D,
        or E. Instructors can keep track student response rates and watch the frequencies of each
        answer change in real time.
      </p>
    ),
    images: [multipleChoice, multipleChoice_results],
  } as PollingActivityType;
  const shortAnswerButton = {
    className: "short-answer",
    onClick: api.PollingActivities.createShortAnswerFromTemplate,
    name: <>Short Answer</>,
    key: "comment alternate outline",
    icon: <Icon className="polling-details-icon" size="big" name="comment alternate outline" />,
    description: (
      <p>
        This activity type allows students to submit freeform text — numbers, words, sentences, etc.
      </p>
    ),
    images: [shortAnswer, shortAnswer_results],
  } as PollingActivityType;
  const completionCheckButton = {
    className: "completion-check",
    onClick: api.PollingActivities.createCompletionCheckFromTemplate,
    name: <>Completion Check</>,
    key: "clock outline",
    icon: <Icon className="polling-details-icon" size="big" name="clock outline" />,
    description: (
      <p>
        This activity type simply asks whether students are finished with an active learning task,
        like a think-pair-share exercise or an application problem. The completion check only offers
        students one answer choice ("Yep, I'm finished.") and can be a great tool for pacing a class
        with hands-on activities.
      </p>
    ),
    images: [completionCheck, completionCheck_results],
  } as PollingActivityType;

  const multipleChoicePlusConfidenceCheckButton = {
    className: "multiple-choice-plus-confidence-check",
    onClick: api.PollingActivities.createMultipleChoicePlusConfidenceCheckFromTemplate,
    name: (
      <>
        Multiple Choice
        <br />+<br />
        Confidence Check
      </>
    ),
    key: "list ul check circle",
    icon: (
      <Icon className="big icons polling-details-icon-nested">
        <Icon className="check list ul" />
        <Icon className="bottom right corner check circle" />
      </Icon>
    ),
    description: (
      <p>
        This activity type includes a multiple choice activity, plus an extra question that asks
        students how confident they are in their answer (on a scale of "Totally Confident" to "I
        have no idea").
      </p>
    ),
    images: [multipleChoicePlus, plusConfidenceCheck, multipleChoicePlus_results],
  } as PollingActivityType;
  const shortAnswerPlusConfidenceCheckButton = {
    className: "short-answer-plus-confidence-check",
    onClick: api.PollingActivities.createShortAnswerPlusConfidenceCheckFromTemplate,
    name: (
      <>
        Short Answer
        <br />+<br />
        Confidence Check
      </>
    ),
    key: "comment alternate outline check circle",
    icon: (
      <Icon className="big icons polling-details-icon-nested">
        <Icon className="check comment alternate outline" />
        <Icon className="bottom right corner check circle" />
      </Icon>
    ),
    description: (
      <p>
        This activity type includes a short answer activity, plus an extra question that asks
        students how confident they are in their answer (on a scale of "Totally Confident" to "I
        have no idea").
      </p>
    ),
    images: [shortAnswer_plus, plusConfidenceCheck, shortAnswer_results, confidenceCheck_results],
  } as PollingActivityType;
  const completionCheckPlusConfidenceCheckButton = {
    className: "completion-check-plus-confidence-check",
    onClick: api.PollingActivities.createCompletionCheckPlusConfidenceCheckFromTemplate,
    name: (
      <>
        Completion
        <br />+<br />
        Confidence Check
      </>
    ),
    key: "clock outline check circle",
    icon: (
      <Icon className="big icons polling-details-icon-nested">
        <Icon className="clock outline circle" />
        <Icon className="bottom right corner check circle" />
      </Icon>
    ),
    description: (
      <p>
        This activity type includes a completion check, plus an extra question that asks students
        how confident they are in their answer (on a scale of "Totally Confident" to "I have no
        idea").
      </p>
    ),
    images: [completionCheckPlus, plusConfidenceCheck, confidenceCheck_results],
  } as PollingActivityType;
  const confidenceCheckButton = {
    className: "confidence-check",
    onClick: api.PollingActivities.createConfidenceCheckFromTemplate,
    name: <>Confidence Check</>,
    key: "check circle",
    icon: <Icon className="polling-details-icon" size="big" name="check circle" />,
    description: (
      <p>
        This activity type asks students how confident they are in their answer (on a scale of
        "Totally Confident" to "I have no idea").
      </p>
    ),
    images: [confidenceCheck, confidenceCheck_results],
  } as PollingActivityType;
  const wordCloudButton = {
    className: "word-cloud",
    onClick: api.PollingActivities.createWordCloudFromTemplate,
    name: <>Word Cloud</>,
    key: "cloud",
    icon: <Icon className="polling-details-icon" size="big" name="cloud" />,
    description: (
      <p>
        This activity type allows students to submit one or two words, which are displayed to the
        instructor in a word cloud (which is maximizable and sharable on a projector). Words
        submitted by multiple students will have the most prominence, but all submissions are
        included.
      </p>
    ),
    images: [wordCloud, wordCloud_results],
  } as PollingActivityType;
  const exitTicketButton = {
    className: "exit-ticket",
    onClick: api.PollingActivities.createExitTicketFromTemplate,
    name: <>Exit Ticket</>,
    key: "ticket",
    icon: <Icon className="polling-details-icon" size="big" name="ticket" />,
    description: (
      <p>
        This activity type is often used at the end of every class period, as the "ticket" that
        gives students permission to leave. It offers the students two generic questions and one
        instructor-posed short answer question:
        <ol>
          <li>
            "How confident do you feel in today's material?" (Scale of "Totally confident" to "Not
            confident at all"),
          </li>
          <li>"What did you learn in class today?" (short answer), and</li>
          <li>
            "Your instructor posed a question. What's your answer?" (Short answer question, usually
            related to reflecting on day's learning objectives).
          </li>
        </ol>
        Every answer to the "What did you learn in class today?" question is listed on a student's
        grade calculation page to remind students that learning is the primary goal of mastery
        grading.
      </p>
    ),
    images: [exitTicket_1, exitTicket_2, exitTicket_3],
  } as PollingActivityType;

  const getPollingButtons = () =>
    [
      multipleChoiceButton,
      multipleChoicePlusConfidenceCheckButton,
      shortAnswerButton,
      shortAnswerPlusConfidenceCheckButton,
      completionCheckButton,
      completionCheckPlusConfidenceCheckButton,
      confidenceCheckButton,
      wordCloudButton,
      exitTicketButton,
    ] as PollingActivityType[];

  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);
  const [isCreatingActivity, setCreatingActivity] = useBooleanState(false);
  const pollingButtons = getPollingButtons();

  useEffect(() => {
    if (isUserTeachingTeam) {
      loadPollingActivityTemplates();
    }
  }, [isUserTeachingTeam]);

  useEffect(() => {
    loadAssignmentWithObjectivesAndMasteryLevels(assignmentID, courseID);
  }, [assignmentWithObjectivesAndMasteryLevels, assignmentID, courseID]);

  if (!user) return <></>;

  if (
    !assignmentWithObjectivesAndMasteryLevels ||
    !hasLoadedAssignmentWithObjectivesAndMasteryLevels(assignmentID)
  )
    return <LoadingComponent content="Loading Assignment..." />;

  const handleOpenActivity = async (
    pollingButtonAction: (
      courseID: string,
      assignmentID: string,
      errorHandlerPackage?: ErrorHandlerPackage
    ) => Promise<PollingActivity>
  ) => {
    setCreatingActivity(true);

    const pollingActivity = await pollingButtonAction(courseID, assignmentID);

    if (pollingActivity) {
      setActivePollingActivity(pollingActivity);
      setActiveAccordionID(pollingActivity.id);
    }

    setCreatingActivity(false);
  };

  return (
    <div className="PollingAssignmentDetails">
      {isUserTeachingTeam && (
        <>
          <div className="polling-header">
            <Header as="h3">Instructor Polling Headquarters</Header>
            <Icon
              name="info"
              size="small"
              circular
              onClick={() => {
                // raise the modal that explains the polling types
                store.modalStore.openModal(
                  <PollingInstructionsModal activityTypes={pollingButtons} />
                );
              }}
            />
          </div>
          <div className="polling-details-container">
            {pollingButtons.map((button, index) => (
              <FlexContainer
                key={index}
                className={createClassName("polling-button-container", button.className)}
                flexDirection="column"
                alignItems="center"
              >
                <Button
                  className="polling-details-button centered-icon"
                  onClick={() => handleOpenActivity(button.onClick)}
                  disabled={isCreatingActivity}
                >
                  {button.icon}
                </Button>
                <span className="polling-details-text">{button.name}</span>
              </FlexContainer>
            ))}
          </div>
          <VerticalGap height="1em" />
        </>
      )}
      <PollingActivitiesForAssignment
        activeAccordionID={activeAccordionID}
        setActiveAccordionID={setActiveAccordionID}
        assignmentID={assignmentID}
        courseID={courseID}
        activePollingActivity={activePollingActivity}
      />
    </div>
  );
};

export default observer(PollingAssignmentDetails);
