// eslint-disable-next-line import/no-cycle
import api from "../api/api";
import { Submission } from "../models/Submission";
import { SubmissionLogEntry } from "../models/SubmissionLogEntry";
import { getLocalDate } from "../utilities/dateTimeUtils";
import { emptyID } from "../utilities/submissionUtils";

import { StoreValue } from "./storeValue";

export default class SubmissionStore {
  private submissionsForAssignmentRegistry = new StoreValue<
    Submission[],
    { userID: string; assignmentID: string }
  >();

  private submissionLogEntriesForAssignmentRegistry = new StoreValue<
    SubmissionLogEntry[],
    { assignmentID: string }
  >();

  private autoGeneratingSubmission = false;

  hasLoadedSubmissionsForAssignment = (userID: string, assignmentID: string) =>
    !this.submissionsForAssignmentRegistry.isLoading() &&
    this.submissionsForAssignmentRegistry.fresh(false, { userID, assignmentID });

  hasLoadedSubmissionLogEntriesForAssignment = (assignmentID: string) =>
    !this.submissionLogEntriesForAssignmentRegistry.isLoading() &&
    this.submissionLogEntriesForAssignmentRegistry.fresh(false, { assignmentID });

  reset = () => {
    this.submissionsForAssignmentRegistry.reset();
    this.submissionLogEntriesForAssignmentRegistry.reset();
  };

  get submissionsForAssignment() {
    return this.submissionsForAssignmentRegistry.value;
  }

  get submissionLogEntriesForAssignment() {
    return this.submissionLogEntriesForAssignmentRegistry.value;
  }

  loadSubmissionsForAssignment = async (
    assignmentID: string,
    userID: string | undefined,
    courseID: string
  ) => {
    const uID = userID || emptyID;

    // if we've already retrieved the submissions, just return
    if (this.submissionsForAssignmentRegistry.fresh(true, { assignmentID, userID: uID })) return;

    this.submissionsForAssignmentRegistry.setLoading(true, { assignmentID, userID: uID });

    let submissions: Submission[];

    if (!userID) {
      submissions = [];
    } else {
      // retrieve the submissions
      submissions = await api.Submissions.list(userID, assignmentID, courseID);
    }

    this.submissionsForAssignmentRegistry.setAll(submissions, { assignmentID, userID: uID }, (v) =>
      v.forEach((s) => this.setSubmissionDates(s))
    );

    this.submissionsForAssignmentRegistry.setLoading(false);
  };

  loadSubmissionLogEntriesForAssignment = async (assignmentID: string, courseID: string) => {
    // if we've already retrieved the submissions, just return
    if (this.submissionLogEntriesForAssignmentRegistry.fresh(true, { assignmentID })) return;

    this.submissionLogEntriesForAssignmentRegistry.setLoading(true, { assignmentID });

    // retrieve the submissions
    const submissionLogEntries = await api.Submissions.logEntriesForAssignment(
      assignmentID,
      courseID
    );

    this.submissionLogEntriesForAssignmentRegistry.setAll(submissionLogEntries, { assignmentID });

    this.submissionLogEntriesForAssignmentRegistry.setLoading(false);
  };

  autoGenerateSubmission = async (assignmentID: string, courseID: string, studentID: string) => {
    if (this.autoGeneratingSubmission) return;

    this.autoGeneratingSubmission = true;
    await api.Submissions.autoGenerateSubmissionsForSpecificStudents(assignmentID, courseID, [
      studentID,
    ]);
    this.submissionsForAssignmentRegistry.reset();
    await this.loadSubmissionsForAssignment(assignmentID, studentID, courseID);
    this.submissionLogEntriesForAssignmentRegistry.reset();
    await this.loadSubmissionLogEntriesForAssignment(assignmentID, courseID);

    this.autoGeneratingSubmission = false;
  };

  newSubmission = async (s: Submission, courseID: string) =>
    api.Submissions.createOrUpdate(s, courseID);

  setSubmissionDates(s: Submission) {
    const submission = s;
    if (submission.createdAt) {
      submission.createdAt = getLocalDate(submission.createdAt);
    }
  }
}
