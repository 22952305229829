import RoundedSegment from "../components/_common/style/RoundedSegment";
import ViewCourseFiles from "../components/courses/[courseID]/files/ViewCourseFiles";

const CourseHomeFilesTab: React.FC = () => (
  <RoundedSegment>
    <ViewCourseFiles />
  </RoundedSegment>
);

export default CourseHomeFilesTab;
