import { useMemo } from "react";
import { Icon, Table } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../../../hooks/useCurrentUserCourseRole";
import { User } from "../../../../../../models/User";
import { PollingActivityQuestion } from "../../../../../../models/polling/PollingActivityQuestion";
import { PollingActivityQuestionResponse } from "../../../../../../models/polling/PollingActivityQuestionResponse";
import { useStore } from "../../../../../../stores/store";
import { createClassName, getFullUserName } from "../../../../../../utilities/utils";
import ProfilePhoto from "../../../../../_common/user/ProfilePhoto";
import "./PollingActivityOverviewResponseRateTable.css";
import UserInputDisplay from "../../../../../_common/textEditing/UserInputDisplay";

interface StudentRowProps {
  student: User;
  pollingActivityQuestions: PollingActivityQuestion[];
}

export const StudentRow: React.FC<StudentRowProps> = ({ student, pollingActivityQuestions }) => {
  const questionToResponseMap = useMemo(() => {
    const map = new Map<string, PollingActivityQuestionResponse>();

    pollingActivityQuestions.forEach(({ id, pollingActivityQuestionResponses }) => {
      const studentResponse = pollingActivityQuestionResponses.find(
        (r) => r.studentID === student.userID
      );

      if (studentResponse) {
        map.set(id, studentResponse);
      }
    });

    return map;
  }, [pollingActivityQuestions]);

  const hasCompletedAllQuestions = questionToResponseMap.size === pollingActivityQuestions.length;

  const getResponseText = (
    question: PollingActivityQuestion,
    response: PollingActivityQuestionResponse | undefined
  ) => {
    if (!response) return <Icon name="question" className="not-answered" />;

    if (question.pollingQuestionTemplate.allowShortAnswer)
      return <UserInputDisplay content={response.text} maxHeight="10rem" maxWidth="30rem" />;

    const { pollingQuestionResponseOption: option } = response;

    if (!option) return "";

    if (option?.name.length === 1) {
      return (
        <span>
          <span className="response-option-letter">{option.name}</span>
          {option.description ?? ""}
        </span>
      );
    }

    return `${option.name ? `${option.name} ` : ""}${option.description}`;
  };

  return (
    <Table.Row key={student.userID} className="student-row">
      <Table.Cell
        className={createClassName("icon-cell", {
          name: "complete",
          apply: hasCompletedAllQuestions,
          else: "incomplete",
        })}
      >
        <div className="profile-photo-wrapper">
          <ProfilePhoto size="2rem" user={student} preventNavigation={true} />
          <Icon
            name={hasCompletedAllQuestions ? "checkmark" : "x"}
            className={createClassName({
              name: "completed",
              apply: hasCompletedAllQuestions,
            })}
          />
        </div>
      </Table.Cell>
      <Table.Cell>{getFullUserName(student)}</Table.Cell>
      {pollingActivityQuestions.map((q) => (
        <Table.Cell key={q.id}>{getResponseText(q, questionToResponseMap.get(q.id))}</Table.Cell>
      ))}
    </Table.Row>
  );
};

interface PollingActivityOverviewResponseRateTableProps {
  pollingActivityQuestions: PollingActivityQuestion[];
  students: User[];
  courseID: string;
}

const PollingActivityOverviewResponseRateTable: React.FC<
  PollingActivityOverviewResponseRateTableProps
> = ({ pollingActivityQuestions, students, courseID }) => {
  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);
  const { userStore } = useStore();
  const { user } = userStore;

  if (!user) return <></>;

  return (
    <Table className="PollingActivityOverviewResponseRateTable" striped unstackable={true}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="column complete first-header"></Table.HeaderCell>
          <Table.HeaderCell className="column name">Name</Table.HeaderCell>
          {pollingActivityQuestions.map((q, index) => (
            <Table.HeaderCell
              className={createClassName({
                name: "last-header",
                apply: index === pollingActivityQuestions.length,
              })}
              key={q.id}
            >
              {q.pollingQuestionTemplate.name}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {isUserTeachingTeam ? (
          <>
            {students.map((student) => (
              <StudentRow
                key={student.userID}
                pollingActivityQuestions={pollingActivityQuestions}
                student={student}
              />
            ))}
          </>
        ) : (
          <StudentRow
            key={user.userID}
            pollingActivityQuestions={pollingActivityQuestions}
            student={user}
          />
        )}
      </Table.Body>
    </Table>
  );
};
export default PollingActivityOverviewResponseRateTable;
