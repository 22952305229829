import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { makeAutoObservable, runInAction } from "mobx";
import { Observer } from "../models/Observer";
// eslint-disable-next-line import/no-cycle
import { store } from "../stores/store";

type ResponseFunction<U> = (...values: U[]) => void;

export default class Hub {
  // we're using a singleton pattern to ensure there is only one hub instance created at a time
  private static instance: Hub;

  // this is the courseID associated with the current instance
  private static instanceCourseID: string;

  // these are classes in src/hubObservers that want to register listeners before the hub connects
  private static observers: Observer[] = [];

  // very rudimentary singleton pattern for hub class
  static getInstance = (courseID: string) => {
    // if the hub already exists but the new courseID being requested is different, disconnect
    if (Hub.instance && Hub.instanceCourseID !== courseID) {
      Hub.instance.disconnect();
    }
    // if no hub exists or if the courseID being requested is different,
    // create a new hub with the provided course id
    if (!Hub.instance || Hub.instanceCourseID !== courseID) {
      Hub.instance = new Hub(`/course?courseID=${courseID}`);
      Hub.instanceCourseID = courseID;
      Hub.instance.connect();
    }
    // return the instance
    return Hub.instance;
  };

  // in case you need the instance but don't have/need the courseID
  static getCurrentInstance = () => Hub.instance;

  // in case you need the courseID for the current instance
  static getInstanceCourseID = () => Hub.instanceCourseID;

  // creates the hub. notice that this does not establish the connection.
  constructor(connectionPath: string) {
    this.connectionPath = connectionPath;
    makeAutoObservable(this);
  }

  // the url to which this hub should connect
  private connectionPath: string;

  // this is the actual hub connection, managed by the SignalR package
  private hubConnection: HubConnection | null = null;

  // tasks the user wants to do after connecting, if any
  private doAfterConnecting: () => void = () => {};

  // allows user to set tasks they want to do after connecting
  set afterConnecting(fn: () => void) {
    this.doAfterConnecting = fn;
  }

  // tasks the user wants to do before connecting, if any
  private doBeforeConnecting: () => void = () => {};

  // allows the user to set tasks they want to do before connecting
  set beforeConnecting(fn: () => void) {
    this.doBeforeConnecting = fn;
  }

  // tasks the user wants to do before disconnecting, if any
  private doBeforeDisconnecting: () => void = () => {};

  // allows the user to set tasks they want to do before disconnecting
  set beforeDisconnecting(fn: () => void) {
    this.doBeforeDisconnecting = fn;
  }

  // tasks the user wants to do after disconnecting, if any
  private doAfterDisconnecting: () => void = () => {};

  // allows the user to set tasks they want to do after disconnecting
  set afterDisconnecting(fn: () => void) {
    this.doAfterDisconnecting = fn;
  }

  // these are the callbacks  provided by the feature hub observer classes.
  // the callbacks are added when the the notifyObservers() function is called.
  private listeningForHooks: Map<string, (...args: unknown[]) => void> = new Map();

  // this is the function that the hub observers will call so that they can listen for
  // hooks related to their features
  listenForHook<U>(listenForHookName: string, respondWithFunction: ResponseFunction<U>) {
    this.listeningForHooks.set(listenForHookName, (...values: unknown[]) => {
      respondWithFunction(...(values as U[]));
    });
    this.registerHookWithHub(listenForHookName, respondWithFunction);
  }

  // internal details of hook registration
  private registerHookWithHub<U>(
    listenForHookName: string,
    respondWithFunction: ResponseFunction<U>
  ) {
    const responseFunctionInAction = (...values: U[]) => {
      runInAction(() => respondWithFunction(...values));
    };
    this.hubConnection?.on(listenForHookName, responseFunctionInAction);
  }

  // actually connect the hub
  connect = () => {
    if (!store.userStore.user) {
      return;
    }
    const { token } = store.userStore.user;

    // do whatever the user wants to do before connecting
    this.doBeforeConnecting();

    // build the hub
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_SIGNALR_URL}${this.connectionPath}`, {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();

    // allow all the features to register their hooks (see src/hubObservers/ directory)
    Hub.notifyObservers();

    // actually listen for the hooks
    this.listeningForHooks.forEach((respondWithFunction, listenForHookName) => {
      const responseFunctionInAction = (values: unknown) => {
        runInAction(() => respondWithFunction(values));
      };
      this.hubConnection?.on(listenForHookName, responseFunctionInAction);
    });

    // actually connect
    this.hubConnection.start().catch((error) => {
      console.log("Error establishing the connection: ", error);
    });

    // do whatever the user wants to do after connecting
    this.doAfterConnecting();
  };

  // this is just a placeholder right now; we're not using it
  sendMessage = async (hubHookName: string, args: unknown | null) => {
    try {
      await this.hubConnection?.invoke(hubHookName, args);
    } catch (err) {
      console.error(err);
    }
  };

  // disconnect the hub
  disconnect = () => {
    // do whatever the user wants to do before disconnecting
    this.doBeforeDisconnecting();
    // actually disconnect
    this.hubConnection?.stop().catch((error) => {
      console.log("Error disconnecting: ", error);
    });
    this.hubConnection = null;
    Hub.instanceCourseID = "";

    // do whatever the user wants to do after disconnecting
    this.doAfterDisconnecting();
  };

  // Implement the Observable interface (allows observers to register hub listeners before connecting)
  // Since this is a static method, it doesn't actually implement any interface, but this is part of the Observer pattern.
  static addObserver(observer: Observer): void {
    this.observers.push(observer);
  }

  // Implement the Observable interface (allows observers to register hub listeners before connecting)
  // Since this is a static method, it doesn't actually implement any interface, but this is part of the Observer pattern.
  static removeObserver(observer: Observer): void {
    const index = this.observers.indexOf(observer);
    if (index > -1) {
      this.observers.splice(index, 1);
    }
  }

  // Implement the Observable interface (allows observers to register hub listeners before connecting)
  // Since this is a static method, it doesn't actually implement any interface, but this is part of the Observer pattern.
  static notifyObservers(): void {
    this.observers.forEach((observer) => observer.update());
  }
}
