import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Header, Label, Loader } from "semantic-ui-react";
import useValidParams from "../../../../../../hooks/useValidParameters";
import { StudentGradeDistinction } from "../../../../../../models/StudentGradeDistinction";
import { useStore } from "../../../../../../stores/store";
import VerticalGap from "../../../../../_common/style/spacing/VerticalGap";
import "./GradeCalculationOverview.css";

interface GradeCalculationOverviewProps {
  studentID: string;
}

const GradeCalculationOverviewSkeleton: React.FC = () => (
  <div>
    <VerticalGap height="0rem" /> {/* TODO: Investigate why this works? */}
    <Header as="h3">
      Grade Overview:
      <Label size="massive" circular className="green-7">
        <Loader active inline size="small" />
      </Label>
    </Header>
  </div>
);

const GradeCalculationOverview: React.FC<GradeCalculationOverviewProps> = ({ studentID }) => {
  const { gradeStore } = useStore();
  const { courseID } = useValidParams<{ courseID: string; userID: string }>();

  const {
    gradeCalculationsForSpecificStudents,
    loadGradeCalculationsForSpecificStudents,
    hasLoadedGradeCalculationsForSpecificStudents,
  } = gradeStore;

  useEffect(() => {
    loadGradeCalculationsForSpecificStudents(courseID, [studentID]);
  }, [courseID, studentID, loadGradeCalculationsForSpecificStudents]);

  if (
    !hasLoadedGradeCalculationsForSpecificStudents([studentID]) ||
    !gradeCalculationsForSpecificStudents
  )
    return <GradeCalculationOverviewSkeleton />;

  const gradeCalculation = gradeCalculationsForSpecificStudents.get(
    studentID
  ) as StudentGradeDistinction;

  return (
    <Header as="h3">
      Grade Overview:
      <Label size="massive" circular className="green-7">
        {gradeCalculation.gradeDistinction?.name}
      </Label>
    </Header>
  );
};
export default observer(GradeCalculationOverview);
