import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Container,
  Header,
  Label,
  Message,
  Segment,
  SegmentGroup,
} from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../hooks/useCurrentUserCourseRole";
import { Assignment } from "../../../../models/Assignment";
import { Objective } from "../../../../models/Objective";
import { RatedObjective } from "../../../../models/RatedObjective";
import { Submission } from "../../../../models/Submission";
import { useStore } from "../../../../stores/store";
import { getGraphicDataForSubmission } from "../../../../utilities/assessmentGraphicUtils";
import LoadingComponent from "../../../../utilities/routing/components/LoadingComponent";
import {
  SubmissionPackage,
  getSubmissionInstructions,
  getSubmissionPackages,
  isSubmissionAssessed,
  isSubmissionAssessmentPublished,
} from "../../../../utilities/submissionUtils";
import { getFullUserName, isQuillEmpty, isValidID } from "../../../../utilities/utils";
import AssessmentSummaryGraphic from "../../../_common/assessmentGraphic/AssessmentSummaryGraphic";
import CardTypeLabel from "../../../_common/cards/_common/CardTypeLabel";
import RoundedSegment from "../../../_common/style/RoundedSegment";
import HorizontalIndent from "../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../_common/style/spacing/VerticalGap";
import UserInputDisplay from "../../../_common/textEditing/UserInputDisplay";
import PublishModal from "../../../_modal/PublishModal";
import AssessmentModal from "../_modal/assessment/AssessmentModal";
import CourseObjective from "../syllabus/CourseObjective";
import AssignmentReassessmentForm from "./AssignmentReassessmentForm";
import AssignmentSubmission from "./AssignmentSubmission";
import "./AssignmentSubmissionDetails.css";
import AssignmentSubmissionForm from "./AssignmentSubmissionForm";
import "./AssignmentSubmissionForm.css";
import SubmissionRatedObjective from "./SubmissionRatedObjective";
import SubmissionsMenu from "./SubmissionsMenu";

interface AssignmentSubmissionDetailsProps {
  courseID: string;
  userID: string;
  assignmentWithObjectives: Assignment<Objective>;
  showingInAssignmentDetails?: boolean;
}

const AssignmentSubmissionDetails: React.FC<AssignmentSubmissionDetailsProps> = ({
  assignmentWithObjectives,
  courseID,
  userID,
  showingInAssignmentDetails,
}) => {
  const { isUserStudent, isUserTeachingTeam } = useCurrentUserCourseRole(courseID);
  const { submissionStore, ratingStore, modalStore, feedbackStore } = useStore();
  const {
    submissionsForAssignment,
    loadSubmissionsForAssignment,
    hasLoadedSubmissionsForAssignment,
  } = submissionStore;
  const {
    ratingsForOneAssignmentForOneStudent,
    loadRatingsForOneAssignmentForOneStudent,
    hasLoadedRatingsForOneAssignmentForOneStudent,
  } = ratingStore;
  const { feedbackForSubmission, loadFeedbackForSubmission, hasLoadedFeedbackForSubmission } =
    feedbackStore;
  const { submissionID: potentialSubmissionID } = useParams<{
    submissionID: string | undefined;
  }>();

  const [submissionID, setSubmissionID] = useState<string | undefined>(potentialSubmissionID);
  const [isEditing, setEditing] = useState<boolean>(false);
  const [scrolledToObjectiveIndex, setScrolledToObjectiveIndex] = useState<number>(-1);

  const { id: assignmentID } = assignmentWithObjectives;

  const { courseStore } = useStore();
  const { roster, loadCurrentCourseRoster, hasLoadedRoster } = courseStore;

  const objectiveRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const scrollToObjective = (objectiveIndex: number) => {
    const objectiveRef = objectiveRefs.current[objectiveIndex];
    if (objectiveRef) {
      setScrolledToObjectiveIndex(objectiveIndex);

      // Delay the scroll to allow the content to open (ensures the opened content is centered on page)
      setTimeout(() => {
        objectiveRef.scrollIntoView({ behavior: "smooth", block: "center" });

        // Reset the index after scrolling
        setTimeout(() => {
          setScrolledToObjectiveIndex(-1);
        }, 500);
      }, 100);
    }
  };

  useEffect(() => {
    loadCurrentCourseRoster(courseID);
  }, [loadCurrentCourseRoster, courseID]);

  // if the submission id is set in the url, set the submissionID state variable
  useEffect(() => {
    setSubmissionID(potentialSubmissionID);
  }, [potentialSubmissionID]);

  useEffect(() => {
    feedbackStore.reset();
    loadFeedbackForSubmission(submissionID, userID, courseID);
  }, [userID, submissionID, courseID]);

  // load the submissions for this assignment
  useEffect(() => {
    loadSubmissionsForAssignment(assignmentID, userID, courseID);
  }, [loadSubmissionsForAssignment, submissionsForAssignment, assignmentID, userID, courseID]);

  // load the ratings for this student/assignment
  useEffect(() => {
    loadRatingsForOneAssignmentForOneStudent(assignmentID, userID, courseID);
  }, [
    loadRatingsForOneAssignmentForOneStudent,
    ratingsForOneAssignmentForOneStudent,
    assignmentID,
    userID,
    courseID,
  ]);

  useEffect(() => {
    setEditing(false);
  }, [submissionsForAssignment]);

  // if there aren't any submissions or if the page is still loading, don't render the details page
  if (
    !submissionsForAssignment ||
    !hasLoadedSubmissionsForAssignment(userID, assignmentID) ||
    !ratingsForOneAssignmentForOneStudent ||
    !hasLoadedRatingsForOneAssignmentForOneStudent(assignmentID, userID) ||
    !hasLoadedFeedbackForSubmission(userID, submissionID) ||
    !hasLoadedRoster(courseID)
  )
    return <LoadingComponent content="Loading Assignment..." />;

  const student = roster?.find((cm) => cm.userID === userID);

  // There are cases where a student's original draft submission was not submitted and assessments were made for a different submission
  // that was automatically generated.
  let originalDraftSubmission: Submission | undefined;
  let submissionsToLoadPackages: Submission[] = [];

  if (submissionsForAssignment.length > 1 && submissionsForAssignment[0]?.isDraft) {
    originalDraftSubmission = submissionsForAssignment[0] as Submission;

    for (let i = 1; i < submissionsForAssignment.length; i += 1) {
      submissionsToLoadPackages.push(submissionsForAssignment[i] as Submission);
    }
  } else {
    submissionsToLoadPackages = [...submissionsForAssignment];
  }

  // put the submission data in a more convenient format
  const submissionPackages = getSubmissionPackages(
    userID,
    isValidID(submissionID) ? submissionID : undefined,
    submissionsToLoadPackages,
    assignmentWithObjectives,
    isUserStudent
  );

  // which submission should we be looking at?
  const activePackage: SubmissionPackage | undefined = submissionPackages.find((sp) => sp.selected);
  const activeSubmissionID = activePackage?.submission?.id;

  // set the variables for what will be displayed
  let child: JSX.Element | undefined;
  let title: JSX.Element | undefined;
  const createHeader = (text: string, className?: string) => (
    <Header as={showingInAssignmentDetails ? "h3" : "h2"} className={className}>
      {text}
    </Header>
  );

  // If this is a first time submission and it is still a draft, show the submission form.
  if (
    activePackage &&
    activePackage.submissionType === "submission" &&
    activePackage.isEditable &&
    (activePackage.isDraft || isEditing)
  ) {
    child = (
      <AssignmentSubmissionForm
        assignmentWithObjectives={assignmentWithObjectives}
        allowedSubmissionFormat={assignmentWithObjectives.allowedSubmissionFormat ?? []}
        submissionPackage={activePackage}
        showingInAssignmentDetails={showingInAssignmentDetails}
        setSubmissionID={setSubmissionID}
      />
    );
  }
  // If this is a resubmission and it is still a draft, show the reassessment form.
  if (
    activePackage &&
    activePackage.submissionType === "resubmission" &&
    activePackage.isEditable &&
    (activePackage.isDraft || isEditing)
  ) {
    title = createHeader("Request Reassessment:");
    child = (
      <AssignmentReassessmentForm
        assignmentID={assignmentID}
        courseID={courseID}
        submissionPackage={{
          ...activePackage,
          submission: activePackage.submission ? { ...activePackage.submission } : undefined,
        }}
        showingInAssignmentDetails={showingInAssignmentDetails}
        setSubmissionID={setSubmissionID}
        allowedSubmissionFormat={assignmentWithObjectives.allowedSubmissionFormat ?? []}
      />
    );
  }

  // If neither of the previous conditionals apply, show the submissions
  if (!child) {
    title = createHeader(
      `Submissions for ${assignmentWithObjectives.name} by ${
        student ? getFullUserName(student) : "Student"
      }:`
    );
    child = (
      <RoundedSegment className="rated-objectives" withBorder={true}>
        <>
          {isUserTeachingTeam &&
            activeSubmissionID &&
            !isSubmissionAssessed(
              userID,
              activeSubmissionID,
              ratingsForOneAssignmentForOneStudent
            ) && (
              <Message className="warning unassessed-message">
                <span>This submission has not been assessed.</span>
                <Button
                  content="Assess"
                  onClick={() =>
                    modalStore.openModal(
                      <AssessmentModal
                        assignment={{
                          ...assignmentWithObjectives,
                          objectives: ratingsForOneAssignmentForOneStudent,
                        }}
                        submissionID={submissionID}
                        userID={userID}
                      />
                    )
                  }
                />
              </Message>
            )}
          {isUserTeachingTeam &&
            activeSubmissionID &&
            isSubmissionAssessed(
              userID,
              activeSubmissionID,
              ratingsForOneAssignmentForOneStudent
            ) &&
            !isSubmissionAssessmentPublished(
              userID,
              submissionID,
              ratingsForOneAssignmentForOneStudent
            ) && (
              <Message className="warning unassessed-message">
                <span>This submission has been assessed, but not published.</span>
                <Button
                  content="Publish"
                  onClick={() =>
                    modalStore.openModal(
                      <PublishModal
                        assignmentID={assignmentID}
                        assignmentName={assignmentWithObjectives.name ?? ""}
                        courseID={courseID}
                        submissionIDs={[activeSubmissionID]}
                        then={() => ratingStore.reset()}
                      />
                    )
                  }
                />
              </Message>
            )}
          {activePackage && activeSubmissionID && (
            <>
              <AssignmentSubmission
                submissionPackage={activePackage}
                assignment={assignmentWithObjectives}
                setEditing={setEditing}
              />
              <Header as="h3">Submission Assessment:</Header>
              {ratingsForOneAssignmentForOneStudent && activePackage.submission && (
                <HorizontalIndent>
                  <AssessmentSummaryGraphic
                    objectives={assignmentWithObjectives.objectives}
                    showToolTip={true}
                    data={getGraphicDataForSubmission(
                      activePackage.submission,
                      submissionsForAssignment,
                      ratingsForOneAssignmentForOneStudent
                    )}
                    size={"20em"}
                    unpublishedRatingIconSize={"3.5em"}
                    unassessedIconSize="4em"
                    unassessedLabelSize="1em"
                    unpublishedRatings={
                      isSubmissionAssessed(
                        activePackage.submission.userID,
                        activePackage.submissionID ?? "",
                        ratingsForOneAssignmentForOneStudent
                      ) &&
                      !isSubmissionAssessmentPublished(
                        activePackage.submission.userID,
                        activePackage.submissionID,
                        ratingsForOneAssignmentForOneStudent
                      )
                    }
                    onObjectiveClick={scrollToObjective}
                  />
                </HorizontalIndent>
              )}
            </>
          )}
          <SegmentGroup>
            {feedbackForSubmission &&
              !isQuillEmpty(feedbackForSubmission.comment) &&
              activeSubmissionID && (
                <>
                  <Segment className="overall-feedback">
                    <CardTypeLabel content="Feedback" />
                    <Label ribbon className="black">
                      Overall Comments
                    </Label>
                    <VerticalGap height="1em" />
                    <UserInputDisplay
                      className="feedback-body"
                      content={feedbackForSubmission?.comment}
                    />
                  </Segment>
                </>
              )}
            {ratingsForOneAssignmentForOneStudent && activeSubmissionID && (
              <HorizontalIndent>
                {ratingsForOneAssignmentForOneStudent.map(
                  (ratedObjective: RatedObjective, index) => (
                    <div
                      key={index}
                      ref={(el) => {
                        objectiveRefs.current[index] = el;
                      }}
                    >
                      <SubmissionRatedObjective
                        userID={userID}
                        submissionID={activeSubmissionID}
                        key={ratedObjective.id}
                        ratedObjective={ratedObjective}
                        submissions={submissionsForAssignment}
                        scrolledTo={scrolledToObjectiveIndex === index}
                      />
                    </div>
                  )
                )}
              </HorizontalIndent>
            )}
          </SegmentGroup>
        </>
      </RoundedSegment>
    );
  }

  return (
    <Container className="AssignmentSubmissionDetails">
      {submissionPackages.length === 0 && (
        <>
          <Header as="h3" className={"objectives-header"}>
            Objectives:
          </Header>
          <RoundedSegment>
            <SegmentGroup>
              {assignmentWithObjectives.objectives?.map((o) => (
                <CourseObjective key={o.id} objective={o} />
              ))}
            </SegmentGroup>
          </RoundedSegment>
          {createHeader("Submit Assignment:", "submission-details-header")}
          {assignmentWithObjectives.allowedSubmissionFormat?.length === 1 &&
            assignmentWithObjectives.allowedSubmissionFormat.includes("NoSubmission") && (
              <HorizontalIndent>{getSubmissionInstructions([])}</HorizontalIndent>
            )}
        </>
      )}
      {activePackage && title}
      <>
        {activePackage && (
          <SubmissionsMenu
            assignmentID={assignmentID}
            courseID={courseID}
            userID={userID}
            ratings={ratingsForOneAssignmentForOneStudent}
            submissionPackages={submissionPackages}
            showingInAssignmentDetails={showingInAssignmentDetails}
            setSubmissionID={setSubmissionID}
            originalDraftSubmission={originalDraftSubmission}
          />
        )}
        {activePackage && child}
      </>
    </Container>
  );
};

export default observer(AssignmentSubmissionDetails);
