import RoundedSegment from "../components/_common/style/RoundedSegment";
import ViewAssignmentAllSubmissions from "../components/courses/[courseID]/assignments/[assignmentID]/submissions/ViewAssignmentAllSubmissions";

const CourseHomeAssignmentAllSubmissionsTab: React.FC = () => (
  <RoundedSegment>
    <ViewAssignmentAllSubmissions />
  </RoundedSegment>
);

export default CourseHomeAssignmentAllSubmissionsTab;
