// eslint-disable-next-line import/no-cycle
import api, { ApiCallOptions, ErrorHandlerPackage } from "../api/api";
import { Assignment } from "../models/Assignment";
import { Objective } from "../models/Objective";
import { deepCopy } from "../utilities/collectionUtils";
import { compareDates } from "../utilities/dateTimeUtils";

import { StoreValue } from "./storeValue";

export default class ObjectiveStore {
  private hierarchicalObjectiveRegistry: StoreValue<Map<string, Objective>> = new StoreValue();

  private allObjectiveRegistry: StoreValue<Map<string, Objective>> = new StoreValue();

  private hierarchicalObjectivesWithAssignmentsRegistry = new StoreValue<
    Objective[],
    { courseID: string }
  >();

  hasLoadedHierarchicalObjectivesWithAssignments = () =>
    !this.hierarchicalObjectivesWithAssignmentsRegistry.isLoading() &&
    this.hierarchicalObjectivesWithAssignmentsRegistry.fresh(false);

  hasLoadedHierarchicalObjectives = () =>
    !this.hierarchicalObjectiveRegistry.isLoading() &&
    this.hierarchicalObjectiveRegistry.fresh(false);

  hasLoadedAllObjectives = () =>
    !this.allObjectiveRegistry.isLoading() && this.allObjectiveRegistry.fresh(false);

  get hierarchicalObjectivesWithAssignments() {
    return this.hierarchicalObjectivesWithAssignmentsRegistry.value;
  }

  get objectivesByCourseHierarchical() {
    return (
      this.hierarchicalObjectiveRegistry.value &&
      Array.from(this.hierarchicalObjectiveRegistry.value.values())
    );
  }

  get hierarchicalObjectives() {
    return this.hierarchicalObjectiveRegistry.value;
  }

  get allObjectives() {
    return this.allObjectiveRegistry.value;
  }

  reset = () => {
    this.hierarchicalObjectiveRegistry.reset();
    this.hierarchicalObjectivesWithAssignmentsRegistry.reset();
    this.allObjectiveRegistry.reset();
  };

  loadObjectivesHierarchical = async (courseID: string, apiCallOptions?: ApiCallOptions) => {
    if (this.hierarchicalObjectiveRegistry.fresh(true) && !apiCallOptions?.overrideIfFresh) return;

    this.hierarchicalObjectiveRegistry.setLoading(true);

    const objectives = await api.Objectives.listHierarchical(courseID);

    this.hierarchicalObjectiveRegistry.setValue(new Map<string, Objective>());
    objectives.forEach((objective) => {
      this.hierarchicalObjectiveRegistry.ifPresent((v) => v.set(objective.id, objective));
    });

    this.hierarchicalObjectiveRegistry.setLoading(false);
  };

  loadAllObjectives = async (courseID: string, apiCallOptions?: ApiCallOptions) => {
    if (this.allObjectiveRegistry.fresh(true) && !apiCallOptions?.overrideIfFresh) return;

    this.allObjectiveRegistry.setLoading(true);

    const objectives = await api.Objectives.list(courseID);

    this.allObjectiveRegistry.setValue(new Map<string, Objective>());
    objectives.forEach((objective) => {
      this.allObjectiveRegistry.ifPresent((v) => v.set(objective.id, objective));
    });

    this.allObjectiveRegistry.setLoading(false);
  };

  loadHierarchicalObjectivesWithAssignments = async (
    courseID: string,
    apiCallOptions?: ApiCallOptions
  ) => {
    if (
      (!apiCallOptions?.overrideIfFresh ||
        this.hierarchicalObjectivesWithAssignmentsRegistry.isLoading()) &&
      this.hierarchicalObjectivesWithAssignmentsRegistry.fresh(true, { courseID })
    )
      return;

    try {
      this.hierarchicalObjectivesWithAssignmentsRegistry.setLoading(true, { courseID });

      const objectives = await api.Objectives.listHierarchicalWithAssignments(
        courseID,
        apiCallOptions?.errorHandlerPackage
      );

      this.hierarchicalObjectivesWithAssignmentsRegistry.setAll(objectives, { courseID });

      this.hierarchicalObjectivesWithAssignmentsRegistry.setLoading(false);
    } catch (error) {
      this.hierarchicalObjectivesWithAssignmentsRegistry.setLoading(false);
    }
  };

  updateAssignmentInHierarchicalObjectivesWithAssignments = (
    updatedAssignment: Assignment<Objective>,
    oldAssignment: Assignment<Objective> | undefined
  ) => {
    if (!this.hierarchicalObjectivesWithAssignmentsRegistry.value) {
      return;
    }

    // make a copy of the registry
    const newHierarchicalObjectivesWithAssignments = deepCopy(
      this.hierarchicalObjectivesWithAssignmentsRegistry.value
    );

    // find the objectives that are no longer included in the assignment
    if (oldAssignment) {
      const defunctObjectives = oldAssignment.objectives?.filter(
        (oldObjective) =>
          !updatedAssignment.objectives?.some(
            (updatedObjective) => oldObjective.id === updatedObjective.id
          )
      );

      // remove the assignment from each defunct objective
      defunctObjectives?.forEach((defunctObjective) => {
        const hierarchicalObjective = this.getObjectiveFromHierarchicalStructure(
          defunctObjective,
          newHierarchicalObjectivesWithAssignments
        );

        if (hierarchicalObjective) {
          // remove the assignment if it exists
          hierarchicalObjective.assignments =
            hierarchicalObjective?.assignments?.filter((a) => a.id !== updatedAssignment.id) ?? [];
        }
      });
    }

    // find the objectives that are in the updated assignment but not the old assignment
    const newObjectives = updatedAssignment.objectives;

    newObjectives?.forEach((newObjective) => {
      const hierarchicalObjective = this.getObjectiveFromHierarchicalStructure(
        newObjective,
        newHierarchicalObjectivesWithAssignments
      );

      if (hierarchicalObjective) {
        // remove the assignment if it exists
        hierarchicalObjective.assignments =
          hierarchicalObjective?.assignments?.filter((a) => a.id !== updatedAssignment.id) ?? [];

        // add the objective
        hierarchicalObjective.assignments.push(updatedAssignment);

        // sort the assignments
        hierarchicalObjective.assignments = hierarchicalObjective.assignments.sort(
          (assignmentA: Assignment<Objective>, assignmentB: Assignment<Objective>) =>
            compareDates(assignmentA.dueDate, assignmentB.dueDate)
        );
      }
    });

    this.hierarchicalObjectivesWithAssignmentsRegistry.setValue(
      newHierarchicalObjectivesWithAssignments
    );
  };

  deleteAssignmentInHierarchicalObjectivesWithAssignments = (assignmentID: string) => {
    if (!this.hierarchicalObjectivesWithAssignmentsRegistry.value) {
      return;
    }

    // make a copy of the registry
    const newHierarchicalObjectivesWithAssignments = deepCopy(
      this.hierarchicalObjectivesWithAssignmentsRegistry.value
    );

    // remove the assignment from each objective, if it exists
    newHierarchicalObjectivesWithAssignments.forEach((objective) =>
      this.removeAssignmentFromHierarchicalStructure(assignmentID, objective)
    );

    this.hierarchicalObjectivesWithAssignmentsRegistry.setValue(
      newHierarchicalObjectivesWithAssignments
    );
  };

  createOrUpdate = (
    courseID: string,
    objectives: Objective[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) => api.Objectives.createOrUpdate(courseID, objectives, errorHandlerPackage);

  delete = async (
    courseID: string,
    objectives: Objective[],
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const successfullyRemoved = await api.Objectives.delete(
      courseID,
      objectives,
      errorHandlerPackage
    );

    if (successfullyRemoved) {
      const deleteObjectiveFromParent = (objective: Objective, parentObjective: Objective) => {
        const po = parentObjective;
        const { children } = po;

        if (children) {
          po.children = children.filter((childObjective) => childObjective.id !== objective.id);
        }
      };

      // loop through each objective to delete it from the cache
      objectives.forEach((objective) => {
        let newHierarchicalObjectivesWithAssignments =
          this.hierarchicalObjectivesWithAssignmentsRegistry.value;

        // if there are hierarchical objectives with assignments, delete the parents or the children of parents with the objective id
        if (newHierarchicalObjectivesWithAssignments) {
          newHierarchicalObjectivesWithAssignments = [...newHierarchicalObjectivesWithAssignments];
          newHierarchicalObjectivesWithAssignments =
            newHierarchicalObjectivesWithAssignments.filter(
              (parentObjective) => parentObjective.id !== objective.id
            );

          newHierarchicalObjectivesWithAssignments.forEach((parentObjective) =>
            deleteObjectiveFromParent(objective, parentObjective)
          );

          this.hierarchicalObjectivesWithAssignmentsRegistry.setValue(
            newHierarchicalObjectivesWithAssignments
          );
        }

        let newHierarchicalObjectives = this.hierarchicalObjectiveRegistry.value;

        if (newHierarchicalObjectives) {
          newHierarchicalObjectives = new Map<string, Objective>(newHierarchicalObjectives);

          newHierarchicalObjectives.delete(objective.id);

          if (objective.parentID && newHierarchicalObjectives.has(objective.parentID)) {
            deleteObjectiveFromParent(
              objective,
              newHierarchicalObjectives.get(objective.parentID) as Objective
            );
          }

          this.hierarchicalObjectiveRegistry.setValue(newHierarchicalObjectives);
        }

        let newAllObjectives = this.allObjectiveRegistry.value;

        if (newAllObjectives) {
          newAllObjectives = new Map<string, Objective>(newAllObjectives);
          newAllObjectives.delete(objective.id);

          this.allObjectiveRegistry.setValue(newAllObjectives);
        }
      });
    }
  };

  getObjectiveFromHierarchicalStructure = (
    objective: Objective,
    hierarchicalStructure: Objective[]
  ) => {
    // if objective has a parent, find the objective through the parent
    if (objective.parentID) {
      const parentObjective = hierarchicalStructure.find((a) => a.id === objective.parentID);
      return parentObjective?.children?.find((o) => o.id === objective.id);
    }
    // if the objective doesn't have a parent, find the objective directly
    return hierarchicalStructure.find((a) => a.id === objective.parentID);
  };

  removeAssignmentFromHierarchicalStructure = (assignmentID: string, objective: Objective) => {
    // eslint-disable-next-line no-param-reassign
    objective.assignments = objective?.assignments?.filter((a) => a.id !== assignmentID) ?? [];

    objective.children?.forEach((child) =>
      this.removeAssignmentFromHierarchicalStructure(assignmentID, child)
    );
  };
}
