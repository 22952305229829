import { observer } from "mobx-react-lite";
import { Assignment } from "../../../models/Assignment";
import { RatedObjective } from "../../../models/RatedObjective";
import { useStore } from "../../../stores/store";
import { getGraphicDataForBestRatings } from "../../../utilities/assessmentGraphicUtils";
import { areAnyAssignmentRatingsUnpublished } from "../../../utilities/submissionUtils";
import AssessmentModal from "../../courses/[courseID]/_modal/assessment/AssessmentModal";
import "./AssessmentGraphicWithFunctionality.css";
import AssessmentSummaryGraphic from "./AssessmentSummaryGraphic";

interface AssessmentGraphicWithFunctionalityProps {
  assignment: Assignment<RatedObjective>;
  userID: string;
  alignStar: "left" | "center";
}

const AssessmentGraphicWithFunctionality: React.FC<AssessmentGraphicWithFunctionalityProps> = ({
  assignment,
  userID,
  alignStar,
}) => {
  const { modalStore } = useStore();

  return (
    <div
      className="modal-button"
      style={{ justifyContent: alignStar }}
      onClick={() =>
        modalStore.openModal(<AssessmentModal assignment={assignment} userID={userID} />, {
          showCloseIcon: true,
        })
      }
    >
      <AssessmentSummaryGraphic
        size="6em"
        data={getGraphicDataForBestRatings(userID, assignment.objectives as RatedObjective[])}
        unpublishedRatingIconSize="1.1em"
        unassessedIconSize="2em"
        unpublishedRatings={areAnyAssignmentRatingsUnpublished(userID, assignment.objectives)}
      />
    </div>
  );
};

export default observer(AssessmentGraphicWithFunctionality);
