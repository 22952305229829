import { event, initialize, set } from "react-ga";

export default class GoogleAnalyticsStore {
  private usingAnalytics = true;

  constructor() {
    const googleAnalyticsToken = process.env.REACT_APP_GOOGLE_ANALYTICS_TOKEN;

    if (!googleAnalyticsToken) {
      this.usingAnalytics = false;
      return;
    }

    initialize(googleAnalyticsToken);

    set({
      dimension1: "UserID",
      dimension2: "AssignmentID",
      dimension3: "AssignmentName",
      metric1: "CurrentTimeMillis",
    });
  }

  userOpenedAssignmentPage = (userID: string, assignmentID: string, assignmentName: string) => {
    if (!this.usingAnalytics) {
      return;
    }

    event({
      category: "Assignment",
      action: "Opened",
      dimension1: userID,
      dimension2: assignmentID,
      dimension3: assignmentName,
      metric1: Date.now(),
    });
  };
}
