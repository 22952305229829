import { Interweave } from "interweave";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../../stores/store";

const AssessmentSummarySymbols: React.FC = () => {
  const { assignmentStore } = useStore();

  const { assessmentSummarySymbols } = assignmentStore;

  if (assessmentSummarySymbols == null) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <svg
      id="assessment-summary-symbol-cache"
      viewBox="0 0 1000 750"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height="0"
      width="0"
    >
      {Array.from(assessmentSummarySymbols.entries()).map((symbol) =>
        React.cloneElement(
          <Interweave content={symbol[1]} tagName="defs" allowAttributes allowElements />,
          { key: symbol[0] }
        )
      )}
    </svg>
  );
};

export default observer(AssessmentSummarySymbols);
