import { observer } from "mobx-react-lite";
import { Header, Label, Segment } from "semantic-ui-react";
import { AssignmentSubmissionFormat } from "../../../../../models/Assignment";
import { MasteryLevel } from "../../../../../models/MasteryLevel";
import { MasteryLevelScheme } from "../../../../../models/MasteryLevelScheme";
import { Rating } from "../../../../../models/Rating";
import { ReassessmentRequest } from "../../../../../models/ReassessmentRequest";
import { Submission } from "../../../../../models/Submission";
import { User } from "../../../../../models/User";
import { useStore } from "../../../../../stores/store";
import { formatDateDownToMinutes } from "../../../../../utilities/dateTimeUtils";
import { isFirstSubmission } from "../../../../../utilities/submissionUtils";
import { createClassName, getColorForRating } from "../../../../../utilities/utils";
import CardTypeLabel from "../../../../_common/cards/_common/CardTypeLabel";
import HorizontalIndent from "../../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import TextEditor from "../../../../_common/textEditing/TextEditor";
import UserInputDisplay from "../../../../_common/textEditing/UserInputDisplay";
import SubmissionCommentModal from "../SubmissionCommentModal";
import { objectiveFeedbackName, objectiveMasteryLevelName } from "./CreateOrEditReassessment";
import MasteryLevelPicker from "./MasteryLevelPicker";

interface PastRatingsForSubmissionProps {
  ratingsForStudents: { [studentID: string]: Rating[] } | undefined;
  userID: string;
  roster: User[];
  submissionsForAssignment: Submission[];
  masteryLevelScheme: MasteryLevelScheme | undefined;
  selectedMasteryLevelID: string | undefined;
  color: string;
  submission: Submission;
  objectiveID: string;
  allowedSubmissionFormats: AssignmentSubmissionFormat[];
  setFieldValue: (field: string, value: string) => void;
  defaultValue: string | undefined;
}

const PastRatingsForSubmission: React.FC<PastRatingsForSubmissionProps> = ({
  ratingsForStudents,
  userID,
  roster,
  submissionsForAssignment,
  masteryLevelScheme,
  selectedMasteryLevelID,
  color,
  submission,
  objectiveID,
  defaultValue,
  setFieldValue,
}) => {
  const { modalStore } = useStore();

  const createSubmissionSegment = (
    key: string,
    currentSubmission: Submission,
    student: User | undefined,
    reassessmentRequest: ReassessmentRequest | undefined,
    children?: JSX.Element,
    rating?: MasteryLevel | undefined,
    masteryLevelID?: string | undefined,
    rater?: User | undefined,
    ratingFeedback?: string | undefined,
    ratingCreatedAt?: Date | undefined
  ) => {
    const submissionNumber = submissionsForAssignment.indexOf(currentSubmission);
    return (
      <Segment
        key={key}
        className={createClassName("past-submission-overview", {
          name: "reassessment-submission-overview",
          apply: currentSubmission.id === submission.id,
        })}
      >
        <CardTypeLabel
          content={
            submissionNumber === 0
              ? "Rating for Initial Submission"
              : `Rating for Resubmission #${submissionNumber}`
          }
        />
        <Label ribbon className={currentSubmission.id === submission.id ? "blue" : "middle-grey"}>
          Submitted on: {formatDateDownToMinutes(currentSubmission.createdAt)}
        </Label>
        <div className="past-submission-overview-submission-container">
          {student && (
            <div className="past-submission-overview-submission-information">
              <span>
                <strong>Submitted by: </strong>
                {student.firstName} {student.lastName}
              </span>
              {currentSubmission && (
                <span>
                  <strong>Submission Contents: </strong>
                  <span
                    className="ui button-text"
                    onClick={() =>
                      modalStore.openModal(
                        (index) => (
                          <SubmissionCommentModal
                            modalIndex={index}
                            submission={currentSubmission}
                            isFirstSubmission={isFirstSubmission(
                              currentSubmission,
                              submissionsForAssignment
                            )}
                          />
                        ),
                        { showCloseIcon: true, isChildModal: true }
                      )
                    }
                  >
                    View
                  </span>
                </span>
              )}
            </div>
          )}
          {currentSubmission &&
            currentSubmission.reassessmentRequests &&
            currentSubmission.reassessmentRequests.some((rr) => rr.objectiveID === objectiveID) && (
              <div className="past-submission-overview-comment">
                {reassessmentRequest && (
                  <>
                    <strong>Improvement Summary: </strong>
                    <UserInputDisplay
                      content={
                        currentSubmission.reassessmentRequests.find(
                          (rr) => rr.objectiveID === objectiveID
                        )?.improvementSummary
                      }
                    />
                  </>
                )}
              </div>
            )}
        </div>
        {rating && rater && (
          <div className="past-submission-overview-rating-container">
            <div className="past-submission-overview-rating-container-information">
              {rating && (
                <div>
                  <strong>Rating: </strong>
                  <Label
                    className={createClassName(
                      getColorForRating(color, masteryLevelScheme, masteryLevelID),
                      "rounded-0"
                    )}
                    content={rating.name}
                  />
                </div>
              )}
              {rater && (
                <>
                  <span>
                    <strong>Rated by: </strong>
                    {rater.firstName} {rater.lastName}
                  </span>
                  {ratingCreatedAt && (
                    <span>
                      <strong>Rated on: </strong>
                      {formatDateDownToMinutes(ratingCreatedAt)}
                    </span>
                  )}
                </>
              )}
            </div>

            {ratingFeedback && (
              <div className="past-submission-overview-comment">
                <strong>Feedback:</strong>
                <UserInputDisplay content={ratingFeedback} />
              </div>
            )}
          </div>
        )}
        {children}
      </Segment>
    );
  };

  return (
    <div className="past-ratings-for-objective">
      <Header as="h5" content="Past Ratings for this Objective:" />
      {ratingsForStudents &&
        ratingsForStudents[userID] &&
        ratingsForStudents[userID]
          ?.filter((r) => r.submissionID !== submission.id)
          .map(
            ({
              raterID,
              comment,
              submissionID: ratingSubmissionID,
              createdAt,
              masteryLevelID,
              id: ratingID,
            }) => {
              const rater: User | undefined = roster.find((member) => member.userID === raterID);
              const student = roster.find((member) => member.userID === userID);
              const currentSubmission = submissionsForAssignment.find(
                (s) => s.id === ratingSubmissionID
              );
              const rating = masteryLevelScheme?.masteryLevels.find(
                (ml) => ml.id === masteryLevelID
              );
              const reassessmentRequest = currentSubmission?.reassessmentRequests?.find(
                (rr) => rr.objectiveID === objectiveID
              );

              if (!currentSubmission) return <div></div>;

              return createSubmissionSegment(
                ratingID,
                currentSubmission,
                student,
                reassessmentRequest,
                undefined,
                rating,
                masteryLevelID,
                rater,
                comment,
                createdAt
              );
            }
          )}
      {createSubmissionSegment(
        "current",
        submission,
        roster.find((cm) => cm.userID === userID),
        submission.reassessmentRequests?.find((rr) => rr.objectiveID === objectiveID),
        <>
          <Header as="h5" className="reassess-this-objective">
            Reassess this Objective:
          </Header>
          <HorizontalIndent>
            {masteryLevelScheme && (
              <MasteryLevelPicker
                currentMasteryLevelID={selectedMasteryLevelID}
                masteryLevelScheme={masteryLevelScheme}
                selectMasteryLevelID={(id) =>
                  setFieldValue(objectiveMasteryLevelName(objectiveID), id)
                }
              />
            )}
            <VerticalGap height={"0.5em"} />
            <TextEditor
              placeholder={"Enter feedback..."}
              container={`editor-${objectiveFeedbackName(objectiveID)}`}
              defaultValue={defaultValue}
              onChange={(value) => setFieldValue(objectiveFeedbackName(objectiveID), value)}
            />
          </HorizontalIndent>
        </>
      )}
    </div>
  );
};

export default observer(PastRatingsForSubmission);
