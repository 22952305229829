import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { SyntheticEvent, useMemo } from "react";
import { Button, Dropdown, DropdownProps, Header, Loader, Table } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../hooks/useValidParameters";
import { User } from "../../../../models/User";
import { useStore } from "../../../../stores/store";
import VerticalGap from "../../../_common/style/spacing/VerticalGap";
import ProfilePhoto from "../../../_common/user/ProfilePhoto";
import "./ViewRosterForCourse.css";
import CourseRoles, {
  CourseRoleGroup,
} from "../../../../utilities/routing/components/routeAuthorizationExtensions/CourseRoles";
import FlexContainer from "../../../_common/style/FlexContainer";
import RosterDataWidget from "./_common/RosterDataWidget";

interface RoleOption {
  key: string;
  text: string;
  value: string;
}

const ViewRosterForCourseSkeleton: React.FC = () => (
  <div className="ViewRosterForCourseSkeleton">
    <Header as="h3" content="Course Roster" />
    <VerticalGap height="1rem" />
    <Loader active={true} content={"Loading table content..."} />
  </div>
);

const roleOptions: RoleOption[] = [
  {
    key: "Student",
    text: "Student",
    value: "Student",
  },
  {
    key: "TA",
    text: "TA",
    value: "TA",
  },
  {
    key: "TA (Resubmission Manager)",
    text: "TA (Resubmission Manager)",
    value: "TA (Resubmission Manager)",
  },
  {
    key: "Instructor",
    text: "Instructor",
    value: "Instructor",
  },
];

function getFilteredRoster(courseRoleGroup: CourseRoleGroup, roster: User[]) {
  const rosterClone = [...roster];

  switch (courseRoleGroup) {
    case CourseRoleGroup.ALL:
      return rosterClone;
    case CourseRoleGroup.STUDENTS:
      return rosterClone.filter(
        (u) => u.courseRole && CourseRoles.StudentRoles.includes(u.courseRole)
      );
    case CourseRoleGroup.TEACHING_TEAM:
      return rosterClone.filter(
        (u) => u.courseRole && CourseRoles.TeachingTeamRoles.includes(u.courseRole)
      );
    case CourseRoleGroup.INSTRUCTORS:
      return rosterClone.filter(
        (u) => u.courseRole && CourseRoles.InstructorRoles.includes(u.courseRole)
      );
    case CourseRoleGroup.TEACHING_ASSISTANTS:
      return rosterClone.filter(
        (u) =>
          u.courseRole &&
          CourseRoles.TeachingTeamRoles.includes(u.courseRole) &&
          !CourseRoles.InstructorRoles.includes(u.courseRole)
      );
    default:
      return rosterClone;
  }
}

interface ViewRosterForCourseProps {
  selectedCourseRoleGroup: CourseRoleGroup;
}

const ViewRosterForCourse: React.FC<ViewRosterForCourseProps> = ({ selectedCourseRoleGroup }) => {
  const { courseStore, modalStore } = useStore();
  const { course, updateUserRole, unenroll, roster } = courseStore;
  const { courseID } = useValidParams<{ courseID: string }>();

  const { isUserInstructor } = useCurrentUserCourseRole(courseID);

  const onChange = action((e: SyntheticEvent<HTMLElement, Event>, result: DropdownProps) => {
    const user = roster?.find((courseMember) => courseMember.userID === result.userid);
    if (user && result.value) {
      user.courseRole = result.value.toString();
      updateUserRole(user);
    }
  });

  const unenrollUser = async (user: User) => {
    const confirmUnenroll = await modalStore.openConfirmationModal(
      `Do you really want to unenroll ${user.firstName} ${user.lastName} ${
        user.suffix == null ? "" : ` ${user.suffix}`
      }from ${course?.shortName}?`
    );

    if (confirmUnenroll) {
      unenroll(user);
    }
  };

  const filteredRoster = useMemo(
    () => (roster ? getFilteredRoster(selectedCourseRoleGroup, roster) : undefined),
    [roster, selectedCourseRoleGroup]
  );

  if (!filteredRoster) return <ViewRosterForCourseSkeleton />;

  function getPageSubtitle() {
    switch (selectedCourseRoleGroup) {
      case CourseRoleGroup.ALL:
        return "All Course Members";
      case CourseRoleGroup.STUDENTS:
        return "Students";
      case CourseRoleGroup.TEACHING_TEAM:
        return "Teaching Team";
      case CourseRoleGroup.INSTRUCTORS:
        return "Instructors";
      case CourseRoleGroup.TEACHING_ASSISTANTS:
        return "Teaching Assistants";
      default:
        return "";
    }
  }

  return (
    <div className="RosterForCourse">
      <Header as="h3">{getPageSubtitle()}</Header>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={6}>
              <FlexContainer
                className="roster-breakdown"
                flexDirection="row"
                gap="0.75rem"
                justifyContent="right"
                flexWrap="wrap"
              >
                <RosterDataWidget
                  courseRolesToAccept={CourseRoles.AllRoles}
                  label="total member"
                  roster={filteredRoster}
                />
                <RosterDataWidget
                  courseRolesToAccept={CourseRoles.InstructorRoles}
                  label="instructor"
                  roster={filteredRoster}
                />
                <RosterDataWidget
                  courseRolesToAccept={CourseRoles.TeachingTeamRoles}
                  courseRolesToIgnore={CourseRoles.InstructorRoles}
                  label="teaching assistant"
                  roster={filteredRoster}
                />
                <RosterDataWidget
                  courseRolesToAccept={CourseRoles.StudentRoles}
                  label="student"
                  roster={filteredRoster}
                />
              </FlexContainer>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>E-mail</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {filteredRoster.map((u) => (
            <Table.Row key={u.userID}>
              <Table.Cell>
                <ProfilePhoto user={u} size={"3em"} preventNavigation={u.anonymized}></ProfilePhoto>
              </Table.Cell>
              <Table.Cell>{u.firstName}</Table.Cell>
              <Table.Cell>{u.lastName}</Table.Cell>
              <Table.Cell>{u.email}</Table.Cell>
              <Table.Cell>
                {isUserInstructor && u.courseRole !== "Instructor-Owner" ? (
                  <Dropdown
                    fluid
                    selection
                    userid={u.userID}
                    value={u.courseRole}
                    options={roleOptions}
                    onChange={action(onChange)}
                  />
                ) : (
                  u.courseRole
                )}
              </Table.Cell>
              <Table.Cell className="unenroll-button-cell">
                {isUserInstructor && u.courseRole !== "Instructor-Owner" && (
                  <Button color="red" icon="trash" onClick={() => unenrollUser(u)} />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default observer(ViewRosterForCourse);
