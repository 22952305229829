import { Field, FormikErrors } from "formik";
import { Table } from "semantic-ui-react";
import { GradeDistinction } from "../../../../../../../models/GradeDistinction";
import { GradeRecipeConstraint } from "../../../../../../../models/GradeRecipeConstraint";
import GradeRecipeConstraintExtension from "../../../../../../../models/GradeRecipeConstraintExtension";
import GradeRecipeConstraintQuantityUnit from "../../../../../../../models/GradeRecipeConstraintQuantityUnit";
import {
  createClassName,
  isValidIntegerAllowLeadingZeroes,
} from "../../../../../../../utilities/utils";
import {
  GradeRecipesSettingsFormValues,
  createGradeRecipesSettingsFormValueKey,
} from "../GradeRecipesSettingsForm";
import "./GradeRecipesTableIngredientRow.css";

interface GradeRecipesTableIngredientRowProps {
  constraint: GradeRecipeConstraint;
  gradeDistinctions: GradeDistinction[];
  errors: FormikErrors<GradeRecipesSettingsFormValues>;
}

const validateNumberField = (quantity: string, isConstraintNumber: boolean) => {
  let error;

  // If the string is empty do nothing
  if (quantity === "" || quantity == null) return error;

  if (!isValidIntegerAllowLeadingZeroes(quantity))
    error = "One or more of the values you have entered is not a number.";

  const n = parseInt(quantity, 10);

  if (n < 0) error = "You cannot enter a number less than zero.";

  if (n > 100 && !isConstraintNumber) error = "You cannot enter a percentage greater than 100.";

  return error;
};

const GradeRecipesTableIngredientRow: React.FC<GradeRecipesTableIngredientRowProps> = ({
  constraint,
  gradeDistinctions,
  errors,
}) => (
  <Table.Row className="GradeRecipesTableIngredientRow">
    <Table.Cell className="first-column">{`Students must earn ${constraint.quantifier} _____${
      constraint.quantityUnit
    } ${constraint.masteryLevel?.name} ratings${
      constraint.extension !== GradeRecipeConstraintExtension.FULL_STOP
        ? ` ${constraint.extension}`
        : ""
    }.`}</Table.Cell>
    {gradeDistinctions.map((distinction, index) => {
      const key = createGradeRecipesSettingsFormValueKey(constraint.id, index);

      return (
        <Table.Cell
          key={key}
          className={createClassName("data-cell", {
            name: "dark-cell-background",
            apply: index % 2 === 0,
          })}
        >
          <Field
            className={createClassName({ name: "error", apply: !!errors[key] })}
            validate={(quantity: string) =>
              validateNumberField(
                quantity,
                constraint.quantityUnit === GradeRecipeConstraintQuantityUnit.RAW_NUMBER
              )
            }
            disabled={index === gradeDistinctions.length - 1}
            name={key}
          />
        </Table.Cell>
      );
    })}
  </Table.Row>
);

export default GradeRecipesTableIngredientRow;
