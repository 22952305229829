import { observer } from "mobx-react-lite";
import { Menu } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../hooks/useValidParameters";
import TeachFrontNavLink from "../../../utilities/routing/components/TeachFrontNavLink";
import "./CourseHomeMenu.css";

const CourseHomeMenu: React.FC = () => {
  const { courseID } = useValidParams<{ courseID: string }>();
  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);

  return (
    <>
      <Menu pointing secondary className="CourseHomeMenu" data-print-id="hide">
        {isUserTeachingTeam && (
          <>
            <Menu.Item
              as={TeachFrontNavLink}
              to={"CourseHomeAssessmentTasksTab"}
              params={{ courseID }}
            >
              Tasks
            </Menu.Item>
          </>
        )}
        <Menu.Item as={TeachFrontNavLink} to={"CourseHomeCalendarTab"} params={{ courseID }}>
          Calendar
        </Menu.Item>
        <Menu.Item as={TeachFrontNavLink} to={"CourseHomeSyllabusTab"} params={{ courseID }}>
          Syllabus
        </Menu.Item>
        <Menu.Item as={TeachFrontNavLink} to={"CourseHomeAssignmentsTab"} params={{ courseID }}>
          Assignments
        </Menu.Item>
        <Menu.Item as={TeachFrontNavLink} to={"CourseHomeFilesTab"} params={{ courseID }}>
          Files
        </Menu.Item>
        <Menu.Item as={TeachFrontNavLink} to={"CourseHomeRosterTab"} params={{ courseID }}>
          Roster
        </Menu.Item>
        <Menu.Item
          as={TeachFrontNavLink}
          to={"CourseHomeGradeCalculationsTab"}
          params={{ courseID }}
        >
          Grade Calculations
        </Menu.Item>
        {isUserTeachingTeam && (
          <>
            <Menu.Item as={TeachFrontNavLink} to={"CourseHomeGradebookTab"} params={{ courseID }}>
              Gradebook
            </Menu.Item>
            <Menu.Item as={TeachFrontNavLink} to={"CourseHomeSettingsTab"} params={{ courseID }}>
              Settings
            </Menu.Item>
          </>
        )}
      </Menu>
      <div className="vertical-space" />
    </>
  );
};

export default observer(CourseHomeMenu);
