import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";
import { Header } from "semantic-ui-react";
import "./SettingsView.css";
import Sidebar from "../../../_common/sidebar/Sidebar";
import useWindowSize from "../../../../hooks/useWindowSize";
import FlexContainer from "../../../_common/style/FlexContainer";
import { SidebarRow } from "../../../../models/sidebar";

const courseSettingsSidebarData: SidebarRow[] = [
  {
    icon: "cog",
    header: "Course Settings",
    route: "CourseHomeSettingsTab",
  },
  {
    icon: "sitemap",
    header: "Mastery Levels",
    route: "CourseHomeSettingsTabMasteryLevels",
  },
  {
    icon: "star",
    header: "Objectives",
    route: "CourseHomeSettingsTabObjectives",
  },
  {
    icon: "pencil alternate",
    header: "Grade Calculations",
    subItems: [
      {
        icon: "angle right",
        header: "Grade Distinctions",
        route: "CourseHomeSettingsTabGradeDistinctions",
      },
      {
        icon: "angle right",
        header: "Grade Ingredients",
        route: "CourseHomeSettingsTabGradeIngredients",
      },
      {
        icon: "angle right",
        header: "Grade Recipes",
        route: "CourseHomeSettingsTabGradeRecipes",
      },
    ],
  },
];

interface SettingsViewProps {
  courseID: string;
}

const SettingsView: React.FC<PropsWithChildren<SettingsViewProps>> = ({ children }) => {
  const { width } = useWindowSize([750]);

  return (
    <div className="SettingsView">
      <Header as="h2">Course Settings</Header>
      <FlexContainer
        className="sidebar-container"
        flexDirection={width < 750 ? "column" : "row"}
        gap="1rem"
      >
        <Sidebar sidebarData={courseSettingsSidebarData} />
        <div className="children-container">{children}</div>
      </FlexContainer>
    </div>
  );
};

export default observer(SettingsView);
