import { useState } from "react";

/**
 * Conveniently holds a loading state for a component to use.
 * @param defaultValue an optional default value for the loading state.
 * @returns a Loader object.
 */
export default function useBooleanState(
  defaultValue = false
): [boolean, (newValue: boolean) => void] {
  const [value, setValue] = useState<boolean>(defaultValue);

  return [value, setValue];
}
