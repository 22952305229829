import { createContext, useContext } from "react";
// eslint-disable-next-line import/no-cycle
import AssessmentTaskStore from "./assessmentTaskStore";
// eslint-disable-next-line import/no-cycle
import AssignedRaterStore from "./assignedRaterStore";
// eslint-disable-next-line import/no-cycle
import AssignmentStore from "./assignmentStore";
// eslint-disable-next-line import/no-cycle
import CommonStore from "./commonStore";
// eslint-disable-next-line import/no-cycle
import CourseStore from "./courseStore";
// eslint-disable-next-line import/no-cycle
import FeedbackStore from "./feedbackStore";
// eslint-disable-next-line import/no-cycle
import GradeStore from "./gradeStore";
// eslint-disable-next-line import/no-cycle
import ModalStore from "./modalStore";
// eslint-disable-next-line import/no-cycle
import ObjectiveStore from "./objectiveStore";
// eslint-disable-next-line import/no-cycle
import RatingStore from "./ratingStore";
// eslint-disable-next-line import/no-cycle
import PredefinedAssessmentCommentStore from "./predefinedAssessmentCommentStore";
import SubmissionStore from "./submissionStore";
import ToastStore from "./toastStore";
// eslint-disable-next-line import/no-cycle
import UploadedFileStore from "./uploadedFileStore";
// eslint-disable-next-line import/no-cycle
import MasteryLevelSchemeStore from "./masteryLevelSchemeStore";
// eslint-disable-next-line import/no-cycle
import UserStore from "./userStore";
// eslint-disable-next-line import/no-cycle
import PagesStore from "./pagesStore";
// eslint-disable-next-line import/no-cycle
import CalendarStore from "./calendarStore";
import ConfettiStore from "./confettiStore";
import PersistentPreferenceStore from "./persistentPreferenceStore";
// eslint-disable-next-line import/no-cycle
import PollingStore from "./pollingStore";
// eslint-disable-next-line import/no-cycle
import AssignmentSurveyStore from "./assignmentSurveyStore";
// eslint-disable-next-line import/no-cycle
import LinkPreviewStore from "./linkPreviewStore";
// eslint-disable-next-line import/no-cycle
import GoogleAnalyticsStore from "./googleAnalyticsStore";
// eslint-disable-next-line import/no-cycle
import GradeDistinctionStore from "./gradeDistinctionStore";
// eslint-disable-next-line import/no-cycle
import GradeRecipeConstraintStore from "./gradeRecipeConstraintStore";
// eslint-disable-next-line import/no-cycle
import GradeRecipeIngredientStore from "./gradeRecipeIngredientStore";
// eslint-disable-next-line import/no-cycle
import UploadedCourseFileStore from "./uploadedCourseFileStore";
import GroupSetsStore from "./groupSetsStore";

interface IStore {
  assignedRaterStore: AssignedRaterStore;
  assignmentStore: AssignmentStore;
  calendarStore: CalendarStore;
  commonStore: CommonStore;
  courseStore: CourseStore;
  feedbackStore: FeedbackStore;
  googleAnalyticsStore: GoogleAnalyticsStore;
  groupSetsStore: GroupSetsStore;
  linkPreviewStore: LinkPreviewStore;
  modalStore: ModalStore;
  objectiveStore: ObjectiveStore;
  persistentPreferenceStore: PersistentPreferenceStore;
  ratingStore: RatingStore;
  submissionStore: SubmissionStore;
  toastStore: ToastStore;
  userStore: UserStore;
}

class Store implements IStore {
  persistentPreferenceStore = new PersistentPreferenceStore();

  assessmentTaskStore = new AssessmentTaskStore();

  assignedRaterStore = new AssignedRaterStore();

  assignmentStore = new AssignmentStore(this.persistentPreferenceStore);

  assignmentSurveyStore = new AssignmentSurveyStore();

  calendarStore = new CalendarStore(this.persistentPreferenceStore);

  commonStore = new CommonStore();

  confettiStore = new ConfettiStore();

  courseStore = new CourseStore();

  feedbackStore = new FeedbackStore();

  googleAnalyticsStore = new GoogleAnalyticsStore();

  gradeStore = new GradeStore();

  gradeDistinctionStore = new GradeDistinctionStore();

  gradeRecipeConstraintStore = new GradeRecipeConstraintStore();

  gradeRecipeIngredientStore = new GradeRecipeIngredientStore();

  groupSetsStore = new GroupSetsStore();

  linkPreviewStore = new LinkPreviewStore();

  masteryLevelSchemeStore = new MasteryLevelSchemeStore();

  modalStore = new ModalStore();

  objectiveStore = new ObjectiveStore();

  pagesStore = new PagesStore();

  pollingStore = new PollingStore();

  predefinedAssessmentCommentStore = new PredefinedAssessmentCommentStore();

  ratingStore = new RatingStore();

  submissionStore = new SubmissionStore();

  toastStore = new ToastStore();

  uploadedFileStore = new UploadedFileStore();

  uploadedCourseFileStore = new UploadedCourseFileStore();

  userStore = new UserStore();

  reset = () => {
    this.assessmentTaskStore.reset();
    this.assignedRaterStore.reset();
    this.assignmentStore.reset();
    this.assignmentSurveyStore.reset();
    this.calendarStore.reset();
    this.courseStore.reset();
    this.feedbackStore.reset();
    this.gradeStore.reset();
    this.gradeDistinctionStore.reset();
    this.gradeRecipeConstraintStore.reset();
    this.gradeRecipeIngredientStore.reset();
    this.groupSetsStore.reset();
    this.masteryLevelSchemeStore.reset();
    this.objectiveStore.reset();
    this.pagesStore.reset();
    this.pollingStore.reset();
    this.predefinedAssessmentCommentStore.reset();
    this.ratingStore.reset();
    this.submissionStore.reset();
    this.uploadedCourseFileStore.reset();
    this.uploadedFileStore.reset();
    // do not reset user store, modal store, common store, preference store, toast store, google analytics store, or link preview store
  };
}

export const store: Store = new Store();

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
