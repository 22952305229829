import { observer } from "mobx-react-lite";
import { Dropdown, Icon, Placeholder, PlaceholderLine } from "semantic-ui-react";
import { GradeDistinction } from "../../../models/GradeDistinction";
import { createClassName } from "../../../utilities/utils";
import FlexContainer from "../style/FlexContainer";
import "./GradeDistinctionCard.css";
import CardTypeLabel from "./_common/CardTypeLabel";

interface GradeDistinctionCardProps {
  gradeDistinction: GradeDistinction;
  totalNumGradeDistinctions: number;
  moveGradeDistinctionUp?: () => void;
  moveGradeDistinctionDown?: () => void;
  onNameChange?: (newName: string) => void;
  onDelete?: () => void;
  showAnimation?: boolean;
}

export const GradeDistinctionCardSkeleton: React.FC = () => (
  <div className="GradeDistinctionCardSkeleton">
    <CardTypeLabel content="Grade Distinction" />
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
  </div>
);

const GradeDistinctionCard: React.FC<GradeDistinctionCardProps> = ({
  gradeDistinction,
  totalNumGradeDistinctions,
  moveGradeDistinctionDown,
  moveGradeDistinctionUp,
  onNameChange,
  onDelete,
  showAnimation,
}) => (
  <div
    className={createClassName("GradeDistinctionCard", { name: "animate", apply: !!showAnimation })}
  >
    <CardTypeLabel content="Grade Distinction" />
    <FlexContainer flexDirection="column" className="reorder-buttons">
      <div
        className={createClassName("reorder-button", {
          name: "disabled",
          apply: gradeDistinction.relativeOrderIndex === 0,
        })}
        onClick={() =>
          gradeDistinction.relativeOrderIndex !== 0 &&
          moveGradeDistinctionUp &&
          moveGradeDistinctionUp()
        }
      >
        <Icon name="triangle up" size="large" fitted />
      </div>
      <div
        className={createClassName("reorder-button", {
          name: "disabled",
          apply: gradeDistinction.relativeOrderIndex === totalNumGradeDistinctions - 1,
        })}
        onClick={() =>
          gradeDistinction.relativeOrderIndex !== totalNumGradeDistinctions - 1 &&
          moveGradeDistinctionDown &&
          moveGradeDistinctionDown()
        }
      >
        <Icon name="triangle down" size="large" fitted />
      </div>
    </FlexContainer>
    <input
      value={gradeDistinction.name}
      name="name"
      className="input"
      onChange={(e) => onNameChange && onNameChange(e.target.value)}
    />
    <Dropdown
      className="options"
      icon={"ellipsis vertical"}
      options={[
        {
          key: "delete",
          content: "Delete Mastery Level Scheme",
          icon: "trash",
          onClick: onDelete,
        },
      ]}
      direction="left"
    />
  </div>
);

export default observer(GradeDistinctionCard);
