import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  Button,
  Grid,
  Header,
  Icon,
  Label,
  Placeholder,
  PlaceholderLine,
} from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../../hooks/useValidParameters";
import { AssessmentTask } from "../../../../../models/AssessmentTask";
import { useStore } from "../../../../../stores/store";
import { getCommaSeparatedList } from "../../../../../utilities/collectionUtils";
import { getTimeSince } from "../../../../../utilities/dateTimeUtils";
import TeachFrontNavLink from "../../../../../utilities/routing/components/TeachFrontNavLink";
import CardTypeLabel from "../../../../_common/cards/_common/CardTypeLabel";
import FlexContainer from "../../../../_common/style/FlexContainer";
import PageContainer from "../../../../_common/style/PageContainer";
import HorizontalIndent from "../../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import AssessmentModal from "../../_modal/assessment/AssessmentModal";
import "./ViewAssessmentTasksForCourse.css";

const AssessmentTaskSkeleton: React.FC = () => (
  <div className="AssessmentTaskSkeleton">
    <CardTypeLabel content="Assessment Task for Assignment" />
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
  </div>
);

const ViewAssessmentTasksForCourseSkeleton: React.FC = () => (
  <PageContainer header="Course Tasks" className="ViewAssessmentTasksForCourseSkeleton">
    <Header as="h3">Assessment Tasks</Header>
    <Grid columns={2} className="header">
      <Grid.Column>
        <Placeholder>
          <PlaceholderLine />
          <PlaceholderLine />
        </Placeholder>
      </Grid.Column>
      <Grid.Column>
        <Placeholder>
          <PlaceholderLine />
          <PlaceholderLine />
        </Placeholder>
      </Grid.Column>
    </Grid>
    <HorizontalIndent>
      <VerticalGap height="1rem" />
      <FlexContainer gap="1rem" flexDirection="column">
        <AssessmentTaskSkeleton />
        <AssessmentTaskSkeleton />
        <AssessmentTaskSkeleton />
        <AssessmentTaskSkeleton />
        <AssessmentTaskSkeleton />
      </FlexContainer>
    </HorizontalIndent>
  </PageContainer>
);

const ViewAssessmentTasksForCourse: React.FC = () => {
  const { courseID } = useValidParams<{ courseID: string }>();
  const { taskType } = useParams<{ taskType: string }>() ?? "all";
  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);
  const { userStore, courseStore, assessmentTaskStore, modalStore, ratingStore } = useStore();
  const { user } = userStore;
  const {
    assessmentTasks,
    assessmentTasksCount,
    assessmentTasksAssignedToUser,
    assessmentTasksAssignedToUserCount,
  } = assessmentTaskStore;
  const { roster, course, loadCurrentCourseRoster, hasLoadedRoster } = courseStore;
  const { ratingsForAllAssignmentsForAllStudents, loadRatingsForAllAssignmentsForAllStudents } =
    ratingStore;

  // get the roster
  useEffect(() => {
    loadCurrentCourseRoster(courseID);
  }, [loadCurrentCourseRoster, courseID]);

  // get the ratings for all assignments/students
  useEffect(() => {
    loadRatingsForAllAssignmentsForAllStudents(courseID, true);
  }, [
    ratingsForAllAssignmentsForAllStudents,
    loadRatingsForAllAssignmentsForAllStudents,
    courseID,
  ]);

  const [activeAssignmentsWithTasks, setActiveAssignmentsWithTasks] = useState<{
    [accordionIndex: string]: boolean;
  }>({ all0: true, mine0: true });

  const isAssignmentWithTasksActive = (index: string) => activeAssignmentsWithTasks[index];

  // if not loaded yet, don't display anything
  if (
    !assessmentTasks ||
    !assessmentTasksAssignedToUser ||
    !hasLoadedRoster(courseID) ||
    !ratingsForAllAssignmentsForAllStudents
  ) {
    return <ViewAssessmentTasksForCourseSkeleton />;
  }
  const tasks = () => (taskType === "mine" ? assessmentTasksAssignedToUser : assessmentTasks);

  const getName = (courseMemberID: string | undefined) => {
    const courseMember = roster?.find((student) => student.userID === courseMemberID);
    return courseMember ? `${courseMember.firstName} ${courseMember.lastName}` : "";
  };

  const getTask = (a: AssessmentTask, assignmentID: string) => (
    <p key={`${a.studentID}+${assignmentID}`}>
      <Button
        className={user?.userID && a.assignedRaterIDs.includes(user?.userID) ? "assignedToMe" : ""}
        onClick={() =>
          modalStore.openModal(
            <AssessmentModal
              assignment={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ratingsForAllAssignmentsForAllStudents.find(
                  (assignment) => assignment.id === assignmentID
                )!
              }
              userID={a.studentID}
            />,
            { showCloseIcon: true }
          )
        }
      >
        <>
          <Icon name="square outline" />
          <span className="name">
            {getName(a.studentID)}
            {a.countReassessmentRequests > 0
              ? ` requested reassessment ${getTimeSince(a.submissionCreatedAt)} `
              : ""}
            {!a.countReassessmentRequests && a.submissionID && !a.submissionWasAutoGenerated
              ? ` submitted ${getTimeSince(a.submissionCreatedAt)} `
              : ""}
            {(!a.countReassessmentRequests && !a.submissionID) || a.submissionWasAutoGenerated
              ? ` did not submit, but the assignment was due ${getTimeSince(a.assignmentDueDate)} `
              : ""}
            ago.
          </span>
          <br />
          {a.countAssignmentObjectives > a.countRatings
            ? `${
                a.countReassessmentRequests > 0
                  ? a.countReassessmentRequests
                  : a.countAssignmentObjectives
              }/${a.countAssignmentObjectives - a.countRatings} objective${
                a.countAssignmentObjectives - a.countRatings !== 1 ? "s" : ""
              } need${a.countAssignmentObjectives - a.countRatings === 1 ? "s" : ""} assessment.`
            : ""}
          {a.countUnpublishedRatings > 0 && a.countAssignmentObjectives === a.countRatings
            ? `${a.countRatings}/${a.countAssignmentObjectives} objective${
                a.countAssignmentObjectives - a.countRatings !== 1 ? "s" : ""
              } have been assessed, but ${a.countUnpublishedRatings} rating${
                a.countUnpublishedRatings !== 1 ? "s" : ""
              } ${a.countUnpublishedRatings !== 1 ? "are" : "is"} unpublished.`
            : ""}
          {!a.assignedRaterIDs || a.assignedRaterIDs.length === 0 ? (
            ""
          ) : (
            <>
              {" Assessment assigned to "}
              {getCommaSeparatedList(a.assignedRaterIDs.map((ar) => getName(ar)))}.
            </>
          )}
        </>
      </Button>
    </p>
  );

  const handleAccordionClick = (index: string) => {
    setActiveAssignmentsWithTasks({
      ...activeAssignmentsWithTasks,
      [index]: !activeAssignmentsWithTasks[index],
    });
  };

  return (
    <PageContainer header="Course Tasks">
      <Header as="h3">Assessment Tasks ({assessmentTasksCount})</Header>

      <div className="AssessmentTasksForCourse">
        <Button
          className={`TaskType ${
            !taskType || taskType === "all" || taskType === "" ? "" : "basic"
          }`}
          as={TeachFrontNavLink}
          to={"CourseHomeAssessmentTasksTab"}
          params={{ courseID, taskType: "all" }}
        >
          <>All Tasks ({assessmentTasksCount})</>
        </Button>
        <Button
          className={`TaskType ${taskType === "mine" ? "" : "basic"}`}
          as={TeachFrontNavLink}
          to={"CourseHomeAssessmentTasksTab"}
          params={{ courseID, taskType: "mine" }}
        >
          My Tasks ({assessmentTasksAssignedToUserCount})
        </Button>
        {Array.from(tasks()?.keys()).map((assignmentID, index) => (
          <Accordion styled key={assignmentID}>
            <Accordion.Title
              active={isAssignmentWithTasksActive(`${taskType}${index}`)}
              onClick={() => handleAccordionClick(`${taskType}${index}`)}
            >
              <CardTypeLabel content="Assessment Tasks For Assignment" />
              <Label ribbon size="large" className={course?.color}>
                <Icon name="dropdown" />
                {assessmentTasks?.get(assignmentID)?.at(0)?.assignmentName}
              </Label>
              ({assessmentTasks.get(assignmentID)?.length} task
              {assessmentTasks.get(assignmentID)?.length === 1 ? "" : "s"}
              {assessmentTasksAssignedToUser?.get(assignmentID)
                ? `; ${assessmentTasksAssignedToUser?.get(assignmentID)?.length.toString()} task${
                    assessmentTasksAssignedToUser.get(assignmentID)?.length === 1 ? "" : "s"
                  } assigned to you`
                : ""}
              )
            </Accordion.Title>
            <Accordion.Content active={isAssignmentWithTasksActive(`${taskType}${index}`)}>
              {isUserTeachingTeam && (
                <>
                  <TeachFrontNavLink
                    to={"CourseHomeAssignmentDetailsTab"}
                    params={{ courseID, assignmentID }}
                  >
                    View Assignment Details
                  </TeachFrontNavLink>
                  <span style={{ fontSize: "1.25em" }}> | </span>
                  <TeachFrontNavLink
                    to={"CourseHomeAssignmentAllSubmissionsTab"}
                    params={{ courseID, assignmentID }}
                  >
                    View All Student Submissions
                  </TeachFrontNavLink>
                  <VerticalGap height="1.5rem" />
                </>
              )}
              {tasks()
                ?.get(assignmentID)
                ?.map((a) => getTask(a, assignmentID))}
            </Accordion.Content>
          </Accordion>
        ))}
      </div>
    </PageContainer>
  );
};

export default observer(ViewAssessmentTasksForCourse);
