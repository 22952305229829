import { Icon } from "semantic-ui-react";
import { createClassName } from "../../../../../../utilities/utils";
import FlexContainer from "../../../../../_common/style/FlexContainer";
import "./PollingActivityOverviewTabs.css";

interface PollingActivityOverviewTabsProps {
  selectedTabIndex: number;
  setSelectedTabIndex: (tab: number) => void;
  defaultTabName: string;
  questionTabNames: string[];
}

const PollingActivityOverviewTabs: React.FC<PollingActivityOverviewTabsProps> = ({
  selectedTabIndex,
  setSelectedTabIndex,
  defaultTabName,
  questionTabNames,
}) => (
  <FlexContainer
    className="PollingActivityOverviewTabs"
    gap="0.5rem"
    justifyContent="space-between"
  >
    <div
      className={createClassName("overview-tab", {
        name: "selected",
        apply: selectedTabIndex === 0,
      })}
      role="button"
      key="default"
      onClick={() => setSelectedTabIndex(0)}
    >
      <Icon name="feed" />
      {defaultTabName}
    </div>
    {questionTabNames.map((name, index) => (
      <div
        className={createClassName("overview-tab", {
          name: "selected",
          apply: selectedTabIndex === index + 1,
        })}
        role="button"
        key={name}
        onClick={() => setSelectedTabIndex(index + 1)}
      >
        <Icon name="question circle outline" />
        {name}
      </div>
    ))}
  </FlexContainer>
);

export default PollingActivityOverviewTabs;
