import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Button, Header, Loader } from "semantic-ui-react";
import useValidParams from "../../../../../../hooks/useValidParameters";
import { RatedObjective } from "../../../../../../models/RatedObjective";
import { useStore } from "../../../../../../stores/store";
import { concatenateUserName } from "../../../../../../utilities/utils";
import FlexContainer from "../../../../../_common/style/FlexContainer";
import PublishModal from "../../../../../_modal/PublishModal";
import CreateOrEditAssessment from "../../../_modal/assessment/CreateOrEditAssessment";
import "../../../roster/ViewRosterForCourse.css";
import "./ViewAssignmentAllSubmissions.css";
import AssignmentSubmissionsTable, {
  AssignmentSubmissionsTableSkeleton,
} from "./AssignmentSubmissionsTable";
import AssignRaterToAllDropdown, {
  AssignRaterToAllDropdownSkeleton,
} from "./AssignRaterToAllDropdown";
import VerticalGap from "../../../../../_common/style/spacing/VerticalGap";

export const defaultRaterOption = "Set Rater...";
export const defaultRaterForAllOption = "Set Rater For All...";

export type RaterOption = {
  key: string;
  text: string;
  value: string;
};

interface ViewAssignmentAllSubmissionsSkeletonProps {
  rosterSize?: number;
}

const ViewAssignmentAllSubmissionsSkeleton: React.FC<ViewAssignmentAllSubmissionsSkeletonProps> = ({
  rosterSize = 10,
}) => (
  <div className="ViewAssignmentAllSubmissions">
    <Header as="h2">
      Submissions For Assignment: <Loader active={true} inline={true} />
    </Header>
    <FlexContainer alignItems="center">
      <Button content={"Download All"} disabled={true} icon={"download"} color={"grey"} />
      <Button content={"Publish Ratings"} disabled={true} icon={"upload"} color={"grey"} />
      <Button content={"Assess For All Students"} disabled={true} icon={"edit"} color={"grey"} />
      <AssignRaterToAllDropdownSkeleton />
    </FlexContainer>
    <VerticalGap height="1rem" />
    <AssignmentSubmissionsTableSkeleton numStudents={rosterSize} />
  </div>
);

const ViewAssignmentAllSubmissions: React.FC = () => {
  const {
    submissionStore,
    assignmentStore,
    ratingStore,
    userStore,
    courseStore,
    modalStore,
    gradeStore,
    toastStore,
    confettiStore,
    uploadedFileStore,
  } = useStore();
  const { loadAssignmentWithObjectivesAndMasteryLevels, assignmentWithObjectivesAndMasteryLevels } =
    assignmentStore;
  const { ratingsForOneAssignmentForAllStudents, loadRatingsForOneAssignmentForAllStudents } =
    ratingStore;
  const {
    roster,
    hasLoadedRoster,
    loadCurrentCourseRoster,
    teachingTeamForCourse,
    loadTeachingTeamForCourse,
    hasLoadedTeachingTeamForCourse,
  } = courseStore;
  const { showToast } = toastStore;
  const { courseID, assignmentID } = useValidParams<{ courseID: string; assignmentID: string }>();
  const { user } = userStore;

  useEffect(() => {
    loadAssignmentWithObjectivesAndMasteryLevels(assignmentID, courseID);
    loadTeachingTeamForCourse(courseID);
  }, [assignmentID, assignmentWithObjectivesAndMasteryLevels, courseID, teachingTeamForCourse]);

  useEffect(() => {
    loadCurrentCourseRoster(courseID);
  }, [courseID]);

  useEffect(() => {
    loadRatingsForOneAssignmentForAllStudents(assignmentID, courseID);
  }, [
    courseID,
    assignmentID,
    ratingsForOneAssignmentForAllStudents,
    loadRatingsForOneAssignmentForAllStudents,
  ]);

  // From the teaching team, create a list of users who can rate assignments.
  const raterOptions: RaterOption[] | undefined = useMemo(() => {
    if (!teachingTeamForCourse) return undefined;

    return teachingTeamForCourse.map((tt) => ({
      key: tt.userID,
      text: concatenateUserName(tt.firstName, tt.lastName, tt.honorific, tt.suffix),
      value: tt.userID,
    }));
  }, [teachingTeamForCourse]);

  if (
    !assignmentWithObjectivesAndMasteryLevels ||
    !ratingsForOneAssignmentForAllStudents ||
    !teachingTeamForCourse ||
    !hasLoadedTeachingTeamForCourse(courseID) ||
    !user ||
    !raterOptions ||
    !roster ||
    !hasLoadedRoster(courseID)
  )
    return <ViewAssignmentAllSubmissionsSkeleton rosterSize={roster?.length} />;

  const handleDownloadAllButtonClick = () => {
    uploadedFileStore.downloadAllSubmissionFiles(courseID, assignmentID);
  };

  const handlePublishRatingsButtonClick = () => {
    modalStore.openModal(
      <PublishModal
        assignmentID={assignmentWithObjectivesAndMasteryLevels.id}
        assignmentName={assignmentWithObjectivesAndMasteryLevels.name || ""}
        courseID={courseID}
        then={() => ratingStore.reset()}
      />
    );
  };

  const handleAssessForAllStudentsButtonClick = () => {
    modalStore.openModal(
      <CreateOrEditAssessment
        assignment={{
          ...assignmentWithObjectivesAndMasteryLevels,
          objectives: assignmentWithObjectivesAndMasteryLevels.objectives as RatedObjective[],
        }}
        courseID={courseID}
        user={user}
        isSettingDefaultAssessment={true}
        onSubmit={(success) => {
          modalStore.closeModal();
          gradeStore.resetGradeDistinctions();
          submissionStore.reset();

          if (success) {
            showToast(
              `Set default ratings for assignment: ${assignmentWithObjectivesAndMasteryLevels.name}.`
            );
            confettiStore.addConfetti();
          } else {
            showToast(
              `No ratings were updated for assignment: ${assignmentWithObjectivesAndMasteryLevels.name} when setting default ratings`,
              { color: "orange" }
            );
          }
        }}
        onCancel={() => modalStore.closeModal()}
      />,
      { showCloseIcon: true }
    );
  };

  return (
    <div className="ViewAssignmentAllSubmissions">
      <Header as="h2">
        Submissions For Assignment: {assignmentWithObjectivesAndMasteryLevels?.name}
      </Header>
      <FlexContainer alignItems="center">
        <Button
          content={"Download All"}
          onClick={handleDownloadAllButtonClick}
          icon={"download"}
          color={"grey"}
        />
        <Button
          content={"Publish Ratings"}
          onClick={handlePublishRatingsButtonClick}
          icon={"upload"}
          color={"grey"}
        />
        <Button
          content={"Assess For All Students"}
          onClick={handleAssessForAllStudentsButtonClick}
          icon={"edit"}
          color={"grey"}
        />
        <AssignRaterToAllDropdown
          assignmentID={assignmentID}
          courseID={courseID}
          raterOptions={raterOptions}
          teachingTeamForCourse={teachingTeamForCourse}
        />
      </FlexContainer>
      <VerticalGap height="1rem" />
      <AssignmentSubmissionsTable
        assignmentID={assignmentID}
        courseID={courseID}
        raterOptions={raterOptions}
        assignmentWithRatings={{
          ...assignmentWithObjectivesAndMasteryLevels,
          objectives: ratingsForOneAssignmentForAllStudents,
        }}
        roster={roster}
      />
    </div>
  );
};

export default observer(ViewAssignmentAllSubmissions);
