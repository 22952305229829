import { Button, Label } from "semantic-ui-react";
import { useEffect } from "react";
import { Field } from "formik";
import { AssignmentType } from "../../../../../models/AssignmentType";
import { createClassName } from "../../../../../utilities/utils";
import {
  FormikSetFieldTouchedFunction,
  FormikSetFieldValueFunction,
  FormikSetValuesFunction,
} from "../../../../../utilities/formUtils";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import FlexContainer from "../../../../_common/style/FlexContainer";
import useBooleanState from "../../../../../hooks/useBooleanState";
import { CreateOrEditAssignmentFormValues } from "./CreateOrEditAssignment";
import { emptyID } from "../../../../../utilities/submissionUtils";
import { useStore } from "../../../../../stores/store";
import "./AssignmentTypeSelectorForm.css";

interface AssignmentTypeSelectorFormProps {
  values: CreateOrEditAssignmentFormValues;
  setFieldValue: FormikSetFieldValueFunction;
  handleAssignmentTypeLabelClick: (
    id: string,
    assignmentTypes: AssignmentType[],
    setFieldValue: FormikSetFieldValueFunction
  ) => void;
  setValues: FormikSetValuesFunction<CreateOrEditAssignmentFormValues>;
  setFieldTouched: FormikSetFieldTouchedFunction;
  courseID: string;
}

const AssignmentTypeSelectorForm: React.FC<AssignmentTypeSelectorFormProps> = ({
  values,
  setFieldValue,
  handleAssignmentTypeLabelClick,
  setValues,
  setFieldTouched,
  courseID,
}) => {
  const { assignmentStore } = useStore();
  const {
    createOrUpdateAssignmentType,
    assignmentTypes,
    loadAssignmentTypes,
    hasLoadedAssignmentTypes,
  } = assignmentStore;
  const [isAddingNewAssignmentType, setAddingNewAssignmentType] = useBooleanState(false);
  const [isSubmitting, setSubmitting] = useBooleanState(false);

  useEffect(() => {
    loadAssignmentTypes(courseID);
  }, [courseID]);

  if (!assignmentTypes || !hasLoadedAssignmentTypes()) return <></>;

  const getUpdatedAssignmentTypeArrayAfterClick = (
    assignmentType: AssignmentType,
    selectedAssignmentTypeIDs: AssignmentType[]
  ) => {
    const formAssignmentTypes = [...selectedAssignmentTypeIDs];
    const index = formAssignmentTypes.findIndex((at) => at.id === assignmentType.id);

    if (index === -1) formAssignmentTypes.push(assignmentType);
    else formAssignmentTypes.splice(index, 1);

    return formAssignmentTypes;
  };

  return (
    <FlexContainer gap="0.5rem" className="AssignmentTypeSelectorForm" flexWrap="wrap">
      {assignmentTypes.map((assignmentType) => (
        <div key={assignmentType.id}>
          <Label
            tag
            className={createClassName("pointer", {
              name: "blue",
              else: "",
              apply: !!values.assignmentTypes.some((aT) => aT.id === assignmentType.id),
            })}
            onClick={() =>
              handleAssignmentTypeLabelClick(
                assignmentType.id ?? "",
                values.assignmentTypes,
                setFieldValue
              )
            }
          >
            {assignmentType.name}
          </Label>
          <VerticalGap height="0.5rem" />
        </div>
      ))}
      {isAddingNewAssignmentType ? (
        <div className="new-assignment-type-container">
          <Field
            name="newAssignmentTypeName"
            className="new-assignment-type-input"
            placeholder="Enter assignment type name..."
          />
          <Button
            color="blue"
            icon="checkmark"
            // content="Add Assignment Type"
            disabled={
              !values.newAssignmentTypeName ||
              assignmentTypes.some((at) => at.name === values.newAssignmentTypeName)
            }
            onClick={async () => {
              setSubmitting(true);

              const savedEntryType = await createOrUpdateAssignmentType({
                courseID,
                name: values.newAssignmentTypeName,
                id: emptyID,
              });

              setSubmitting(false);
              setAddingNewAssignmentType(false);

              const newValues: CreateOrEditAssignmentFormValues = { ...values };

              newValues.newAssignmentTypeName = "";

              if (savedEntryType) {
                newValues.assignmentTypes = getUpdatedAssignmentTypeArrayAfterClick(
                  savedEntryType,
                  values.assignmentTypes
                );
              }

              setValues(newValues);
              setFieldTouched("calendarEntryTypes", true, false);
            }}
            type="button"
            loading={isSubmitting}
            className="new-assignment-type-complete-button"
          />
          <Button
            color="red"
            icon="x"
            // content="Cancel"
            onClick={() => {
              setAddingNewAssignmentType(false);
              setFieldValue("newAssignmentTypeName", "");
              setFieldTouched("calendarEntryTypes", true, false);
            }}
            className="new-assignment-type-cancel-button"
          />
        </div>
      ) : (
        <Label
          content="Add Assignment Type"
          icon="plus"
          tag
          role="button"
          className="new-assignment-type-button"
          onClick={() => setAddingNewAssignmentType(true)}
        />
      )}
    </FlexContainer>
  );
};

export default AssignmentTypeSelectorForm;
