import Hub from "../api/hub";
import { AssessmentTask } from "../models/AssessmentTask";
import { Observer } from "../models/Observer";
import { store } from "../stores/store";

// this class is instantiated (and thus registered as a hub observer) in App.tsx
export default class AssessmentTaskHubObserver implements Observer {
  // the constructor registers this observer with the Hub
  constructor() {
    // register this as an observer
    Hub.addObserver(this);
  }

  // This method will be called when the hub is about to connect.
  // So we'll register the listeners for this feature here.
  update(): void {
    const hubInstance = Hub.getCurrentInstance();
    const userID = store.userStore.user?.userID;
    if (!userID) return;

    // listen for a message to start the tasks list from scratch
    hubInstance.listenForHook("LoadInitialAssessmentTasks", (tasks: AssessmentTask[]) => {
      store.assessmentTaskStore.loadAssessmentTasks(
        new Map<string, AssessmentTask[]>(),
        new Map<string, AssessmentTask[]>(),
        tasks,
        userID
      );
    });

    // listen for a message to modify the existing tasks list
    hubInstance.listenForHook("UpdateAssessmentTasks", (tasks: AssessmentTask[]) => {
      store.assessmentTaskStore.loadAssessmentTasks(
        store.assessmentTaskStore.assessmentTasks ?? new Map<string, AssessmentTask[]>(),
        store.assessmentTaskStore.assessmentTasksAssignedToUser ??
          new Map<string, AssessmentTask[]>(),
        tasks,
        userID
      );
    });
  }
}
