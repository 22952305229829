import { uniqueId } from "lodash";

type ActionBufferAction = () => void;

type ActionBufferData = {
  id: string;
  timeSet: number;
  action: ActionBufferAction | undefined;
};

let actionData: ActionBufferData;

/**
 * Custom hook that allows users to run callbacks in delayMS milliseconds if the specified callback has not been overridden.
 *
 * This is especially useful for things like input validation where such validation might require a call to the database, and these calls
 * should be limited.
 * @param delayMS the amount of milliseconds to wait before calling the callback.
 * @returns an object containing a method that sets the action. When the setAction method is called, the action callback will be invoked delayMS milliseconds
 * later, unless the setAction method is called again before the current action callback is invoked.
 */
function useActionBuffer(delayMS: number) {
  return {
    setAction: (callback: ActionBufferAction) => {
      const newData = {
        id: uniqueId(),
        action: callback,
        timeSet: Date.now(),
      };

      actionData = { ...newData };

      setTimeout(() => {
        if (actionData.id === newData.id) {
          newData.action();
        }
      }, delayMS);
    },
  };
}

export default useActionBuffer;
