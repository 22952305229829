import { AssignedRater } from "../models/AssignedRater";
import { Assignment } from "../models/Assignment";
import { CalendarEntry } from "../models/CalendarEntry";
import { Objective } from "../models/Objective";
import { RatedObjective } from "../models/RatedObjective";
import { SubmissionLogEntry } from "../models/SubmissionLogEntry";
import { User } from "../models/User";
import {
  AssignmentFilter,
  CalendarEntryFilter,
  ColumnSortState,
  SortDirection,
} from "../stores/persistentPreferenceStore";
import { isSubmissionAssessed } from "./submissionUtils";

export function filterCalendarEntries(
  calendarEntries: CalendarEntry[],
  preferences: CalendarEntryFilter
): CalendarEntry[] {
  // Get the current day without hours, minutes, and seconds, so we can filter dates by the day excluding more specific units of time.
  const date = new Date(Date.now());
  date.setHours(0, 0, 0, 0);
  const currentTime = date.getTime();

  const doesCalendarEntryMeetTimeConstraint = (calendarEntry: CalendarEntry) =>
    preferences.showPastCalendarEntries || currentTime <= calendarEntry.startTime.getTime();

  const isEntryTypeSelected = (calendarEntry: CalendarEntry) =>
    calendarEntry.calendarEntryTypes.some((type) =>
      preferences.calendarEntryTypes.includes(type.id)
    );

  const isEntryAssignmentTypeSelected = (calendarEntry: CalendarEntry) =>
    calendarEntry.assignment?.assignmentTypes?.some((type) =>
      preferences.assignmentTypes.includes(type.id)
    );

  return [...calendarEntries]
    .filter(doesCalendarEntryMeetTimeConstraint)
    .filter((ce) => isEntryAssignmentTypeSelected(ce) || isEntryTypeSelected(ce));
}

export function filterAssignments(
  assignments: Assignment<Objective>[],
  preferences: AssignmentFilter
): Assignment<Objective>[] {
  // Get the current day without hours, minutes, and seconds, so we can filter dates by the day excluding more specific units of time.
  const date = new Date(Date.now());
  date.setHours(0, 0, 0, 0);
  const currentTime = date.getTime();

  const doesAssignmentMeetTimeConstraint = (assignment: Assignment<Objective>) =>
    preferences.showPastAssignments ||
    !assignment.dueDate ||
    currentTime <= assignment.dueDate?.getTime();

  const isAssignmentTypeSelected = (assignment: Assignment<Objective>) =>
    !assignment.assignmentTypes ||
    assignment.assignmentTypes.length === 0 ||
    assignment.assignmentTypes?.some((type) => preferences.assignmentTypes.includes(type.id));

  const isAssignmentNameSearchedFor = (assignment: Assignment<Objective>) =>
    !preferences.searchValue ||
    (assignment.name &&
      assignment.name.toLowerCase().includes(preferences.searchValue.toLowerCase()));

  return [...assignments]
    .filter(doesAssignmentMeetTimeConstraint)
    .filter(isAssignmentNameSearchedFor)
    .filter(isAssignmentTypeSelected);
}

/**
 * Sorts submission log entires using persistent preference store values. Supports two columns being sorted at the same time.
 * @returns a sorted array or undefined if provided values are undefined.
 */
export function sortAssignmentSubmissionsTableLogs(
  submissionLogEntriesForAssignment: SubmissionLogEntry[],
  assignedRatersByStudent: Map<string, AssignedRater>,
  assignmentWithRatings: Assignment<RatedObjective>,
  user: User,
  assignSort: ColumnSortState,
  assessedSort: ColumnSortState
): SubmissionLogEntry[] | undefined {
  if (!submissionLogEntriesForAssignment || !assignedRatersByStudent || !user) return undefined;

  const sortedArray = [...submissionLogEntriesForAssignment];

  if (!assessedSort.isSet && !assignSort.isSet) return sortedArray;

  type LogFilter = (log: SubmissionLogEntry) => boolean;

  const isAssignedToMeFilter: LogFilter = (log: SubmissionLogEntry) => {
    const aRater = assignedRatersByStudent.get(log.userID)?.raterID;
    const isLogAssigned = aRater === user.userID;

    return isLogAssigned;
  };

  const isAssessedFilter: LogFilter = (log: SubmissionLogEntry) =>
    !!assignmentWithRatings.objectives &&
    isSubmissionAssessed(log.userID, log.mostRecentSubmissionID, assignmentWithRatings.objectives);

  let firstFilter: LogFilter | undefined;
  let secondFilter: LogFilter | undefined;
  let firstDirection: SortDirection | undefined;
  let secondDirection: SortDirection | undefined;

  if (assignSort.isSet && assessedSort.isSet) {
    if (assignSort.sortTime < assessedSort.sortTime) {
      firstFilter = isAssignedToMeFilter;
      firstDirection = assignSort.sortDirection;
      secondFilter = isAssessedFilter;
      secondDirection = assessedSort.sortDirection;
    } else {
      firstFilter = isAssessedFilter;
      firstDirection = assessedSort.sortDirection;
      secondFilter = isAssignedToMeFilter;
      secondDirection = assignSort.sortDirection;
    }
  } else if (assignSort.isSet) {
    firstFilter = isAssignedToMeFilter;
    firstDirection = assignSort.sortDirection;
  } else if (assessedSort.isSet) {
    firstFilter = isAssessedFilter;
    firstDirection = assessedSort.sortDirection;
  }

  const firstLogs: SubmissionLogEntry[] = [];
  const lastLogs: SubmissionLogEntry[] = [];

  sortedArray.forEach((log) => {
    const firstFilterResult = firstFilter && firstDirection && firstFilter(log);

    if (firstFilterResult) {
      if (firstDirection === "up") {
        firstLogs.push(log);
      } else {
        lastLogs.push(log);
      }
    } else if (firstDirection === "up") {
      lastLogs.push(log);
    } else {
      firstLogs.push(log);
    }
  });

  if (secondFilter && secondDirection) {
    const sortFunction = (a: SubmissionLogEntry, b: SubmissionLogEntry) => {
      if (!secondFilter || !secondDirection) return 0;
      const aFilterSuccess = secondFilter(a);
      const bFilterSuccess = secondFilter(b);

      if (aFilterSuccess && bFilterSuccess) return 0;
      if (aFilterSuccess) return secondDirection === "up" ? -1 : 1;
      if (bFilterSuccess) return secondDirection === "up" ? 1 : -1;
      return 0;
    };
    firstLogs.sort(sortFunction);
    lastLogs.sort(sortFunction);
  }

  return [...firstLogs, ...lastLogs];
}
