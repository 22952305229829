import { observer } from "mobx-react-lite";
import { Dropdown, Icon, Label, Placeholder, PlaceholderLine } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../hooks/useValidParameters";
import { Assignment } from "../../../../models/Assignment";
import { Course } from "../../../../models/Course";
import { Objective } from "../../../../models/Objective";
import { useStore } from "../../../../stores/store";
import { formatDateDownToMinutes, getTime } from "../../../../utilities/dateTimeUtils";
import TeachFrontNavLink from "../../../../utilities/routing/components/TeachFrontNavLink";
import { createClassName, isPollingAssignment } from "../../../../utilities/utils";
import CardTypeLabel from "../../../_common/cards/_common/CardTypeLabel";
import RoundedSegment from "../../../_common/style/RoundedSegment";
import CreateOrEditAssignment from "../_modal/assignment/CreateOrEditAssignment";
import "./ViewAssignmentsForCourse.css";
import CreateOrEditPollingAssignment from "./CreateOrEditPollingAssignment";

interface CourseAssignmentProps {
  assignment: Assignment<Objective>;
  course: Course;
}

export const CourseAssignmentSkeleton: React.FC = () => (
  <div className="CourseAssignmentSkeleton">
    <CardTypeLabel content="Assignment" />
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
  </div>
);

const CourseAssignment: React.FC<CourseAssignmentProps> = ({ assignment, course }) => {
  const { courseID } = useValidParams<{ courseID: string }>();
  const { modalStore, assignmentStore, toastStore } = useStore();
  const { deleteAssignment } = assignmentStore;
  const { id: assignmentID, dueDate, resubmissionDueDate } = assignment;
  const { isUserTeachingTeam } = useCurrentUserCourseRole(courseID);

  const { showToast } = toastStore;
  const pastDue = !!(dueDate && getTime() > dueDate.getTime());
  const pastDueResubmission = getTime() > (resubmissionDueDate?.getTime() || 0);

  async function handleDeleteAssignment() {
    const confirmDelete = await modalStore.openConfirmationModal(
      "Are you sure you want to delete the assignment?"
    );
    if (confirmDelete) {
      deleteAssignment(courseID, assignmentID);
      showToast(`Assignment "${assignment?.name}" was successfully deleted`);
    }
  }

  return (
    <RoundedSegment className="CourseAssignment" key={assignment.id} style={{ width: "100%" }}>
      <CardTypeLabel content="Assignment" />
      <Label
        ribbon
        as={TeachFrontNavLink}
        to={"CourseHomeAssignmentDetailsTab"}
        params={{ courseID, assignmentID }}
        className={!assignment.isDraft ? course.color : ""}
        size="large"
      >
        {assignment.isDraft ? "DRAFT: " : ""}
        {assignment.name ? assignment.name : "Untitled Assignment"}
      </Label>

      {isUserTeachingTeam && (
        <>
          <Dropdown
            className="options"
            icon={"ellipsis vertical"}
            options={[
              {
                key: "edit",
                content: "Edit Assignment",
                icon: "edit",
                onClick: () => {
                  modalStore.openModal(
                    isPollingAssignment(assignment) ? (
                      <CreateOrEditPollingAssignment
                        courseID={courseID}
                        pollingAssignment={assignment}
                      />
                    ) : (
                      <CreateOrEditAssignment assignmentToEdit={assignment} courseID={courseID} />
                    )
                  );
                },
              },
              {
                key: "delete",
                content: "Delete Assignment",
                icon: "trash",
                onClick: handleDeleteAssignment,
              },
            ]}
            direction="left"
          />
        </>
      )}
      <br />
      <div className="dates">
        {assignment.dueDate && (
          <span className="initial-due-date">
            <Icon
              name="calendar alternate outline"
              className={createClassName({ name: "ui greyed-out", apply: pastDue })}
            />
            <span className={createClassName({ name: "ui greyed-out", apply: pastDue })}>
              {formatDateDownToMinutes(assignment.dueDate)}
            </span>
          </span>
        )}

        {assignment.resubmissionDueDate && (
          <span className="reassessment-request-due-date">
            <br />
            <Icon
              name="redo"
              className={createClassName({ name: "ui greyed-out", apply: pastDueResubmission })}
            />
            <span
              className={createClassName({ name: "ui greyed-out", apply: pastDueResubmission })}
            >
              {formatDateDownToMinutes(assignment.resubmissionDueDate)}
            </span>
          </span>
        )}
      </div>

      {assignment.objectives?.map((objective: Objective) => (
        <Label
          key={objective.id}
          as={TeachFrontNavLink}
          to={"CourseHomeSyllabusTab"}
          params={{ courseID }}
          size="small"
          className={createClassName(objective.color, "rounded-0")}
        >
          {objective.shortName}
        </Label>
      ))}
    </RoundedSegment>
  );
};
export default observer(CourseAssignment);
