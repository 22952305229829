import { makeAutoObservable } from "mobx";
import { deepCompareValues, objectEvery, objectSize } from "../utilities/collectionUtils";

export type StoreValueAttribute = string | number | boolean | string[];

export type StoreValueAttributes = {
  [name: string]: StoreValueAttribute;
};

export class StoreValue<
  ValueType,
  AttributeType extends StoreValueAttributes = Record<string, never> // Default to Record for empty attributes
> {
  value: ValueType | undefined;

  // Internally, each attribute can be undefined, but once a user updates the attributes, each attribute cannot be undefined.
  private attributes: AttributeType | undefined;

  private loading: boolean;

  private loadingAttributes: AttributeType | undefined;

  /**
   * Creates a StoreValue object.
   * @param loadData a function that handles loading data for this store value.
   * @param attributes the attributes of this StoreValue (e.g. {courseID: undefined}). Holds all of the metadata for the StoreValue, which is important in determining if the value is fresh (if it isn't fresh, the api should be called).
   */
  constructor(attributes?: AttributeType) {
    makeAutoObservable(this);

    this.requireNonEmptyAttributes(attributes);

    this.value = undefined;
    this.attributes = attributes || undefined;
    this.loading = false;
    this.loadingAttributes = undefined;
  }

  /**
   * Sets the value of this StoreValue.
   * @param value the new value to be set.
   * @param consumer the consumer method that consumes the value.
   */
  setValue = (value: ValueType, consumer?: (value: ValueType) => void) => {
    this.value = value;
    if (consumer) consumer(this.value);
  };

  /**
   * Sets the attributes of this StoreValue.
   * @param attributes the new attributes to be set.
   */
  setAttributes = (attributes: AttributeType) => {
    this.requireNonEmptyAttributes(attributes);

    this.attributes = attributes;
  };

  /**
   * Sets both the value and attributes of this StoreValue.
   * @param value the value to bet set.
   * @param attributes the new attributes to be set.
   * @param consumer the consumer method that consumes the value.
   */
  setAll = (value: ValueType, attributes: AttributeType, consumer?: (value: ValueType) => void) => {
    this.requireNonEmptyAttributes(attributes);

    this.value = value;
    this.attributes = attributes;
    if (consumer) this.ifPresent(consumer);
  };

  /**
   * Resets the value to its default value and all attributes to undefined.
   */
  reset = () => {
    this.value = undefined;
    this.attributes = undefined;
  };

  /**
   * Runs a consumer method on the value if it is defined.
   * @param consumer the consumer function.
   */
  ifPresent = (consumer: (value: ValueType) => void): void => {
    if (this.value) consumer(this.value);
  };

  /**
   * Sets the value of this StoreValue if it is undefined.
   * @param valueSupplier the supplier function that will supply the new value.
   * @param consumer an optional consumer that will be supplied with the new value.
   */
  setValueIfAbsent = (valueSupplier: () => ValueType, consumer?: (value: ValueType) => void) => {
    if (!this.value) this.value = valueSupplier();
    if (this.value && consumer) consumer(this.value);
  };

  /**
   * Tests a predicate on the value if it is defined.
   * @param predicate the predicate function.
   */
  test = (predicate: (value: ValueType) => boolean): boolean =>
    this.value !== undefined && predicate(this.value);

  /**
   * Sets this store value to be loading or not loading.
   * @param loading if the store value should be loading or not.
   * @param attributes the attributes that were used at the time of setting this store value to be loading. Should be undefined if this store value has no attributes or if loading is false.
   */
  setLoading = (loading: boolean, attributes?: AttributeType) => {
    this.loading = loading;
    this.loadingAttributes = attributes;
  };

  /**
   * Returns whether this store value is loading. If this.loading is true and no new attributes are passed, the method will return true.
   * Otherwise, the method will return whether the store value is loading with the defined new attributes.
   * @param newAttributes the new attributes to test.
   */
  isLoading = (newAttributes?: AttributeType): boolean =>
    this.loading && (!newAttributes || deepCompareValues(newAttributes, this.loadingAttributes));

  /**
   * Based on new attributes, finds whether or not the old attributes are the same.
   * @param newAttributes the new attributes to test. Should contain all the keys that the old attributes has.
   * @param returnTrueIfLoading if this value is true, and the storeValue is in a loading state, the method will return true if the attributes are the same.
   * @returns true if the attributes are the same, false otherwise.
   */
  fresh = (returnTrueIfLoading: boolean, newAttributes?: AttributeType) => {
    // If this storeValue is loading and the loading attributes and new attributes are the same, then return that the data is fresh.
    if (returnTrueIfLoading && this.loading) {
      if (deepCompareValues(newAttributes, this.loadingAttributes)) {
        return true;
      }
    }

    // If the value is undefined, the store is not fresh and needs to be loaded.
    if (this.value === undefined) return false;

    // For safety, the data is considered fresh if the attributes object is empty .
    if (!this.requireNonEmptyAttributes(newAttributes)) return true;

    // If there were no attributes before, but there are now, the data is not fresh.
    if (this.attributes === undefined && newAttributes) return false;

    // If the new attributes are undefined, and the previous attributes are defined, the store is fresh.
    if (this.attributes && newAttributes === undefined) return true;

    // If the old attributes are undefined and the new attributes are undefined, the store is fresh.
    if (this.attributes === undefined && newAttributes === undefined) return true;

    return objectEvery(this.attributes as AttributeType, (key, oldAttribute) =>
      deepCompareValues(oldAttribute, (newAttributes as AttributeType)[key as string])
    );
  };

  private requireNonEmptyAttributes(attributes: AttributeType | undefined) {
    if (attributes && objectSize(attributes) === 0) {
      throw new Error(
        "Attribute object size must not be zero. Attributes must be undefined or populated."
      );
    }

    return true;
  }
}
