// eslint-disable-next-line import/no-cycle
import api from "../api/api";
import { Assignment } from "../models/Assignment";
import { RatedObjective } from "../models/RatedObjective";
import { Rating } from "../models/Rating";
import { getLocalDate } from "../utilities/dateTimeUtils";
// eslint-disable-next-line import/no-cycle
import { store } from "./store";
import { StoreValue } from "./storeValue";

export default class RatingStore {
  private ratingsForOneAssignmentForOneStudentRegistry = new StoreValue<
    RatedObjective[],
    { assignmentID: string; userID: string }
  >();

  private ratingsForOneAssignmentForAllStudentsRegistry = new StoreValue<
    RatedObjective[],
    { assignmentID: string }
  >();

  private ratingsForAllAssignmentsForSpecificStudentsRegistry = new StoreValue<
    Assignment<RatedObjective>[],
    { studentIDs: string[] }
  >();

  private ratingsForAllAssignmentsForAllStudentsRegistry = new StoreValue<
    Assignment<RatedObjective>[]
  >();

  hasLoadedRatingsForOneAssignmentForOneStudent = (assignmentID: string, userID: string) =>
    !this.ratingsForOneAssignmentForOneStudentRegistry.isLoading() &&
    this.ratingsForOneAssignmentForOneStudentRegistry.fresh(false, {
      assignmentID,
      userID,
    });

  hasLoadedRatingsForOneAssignmentForAllStudents = (assignmentID: string) =>
    !this.ratingsForOneAssignmentForAllStudentsRegistry.isLoading() &&
    this.ratingsForOneAssignmentForAllStudentsRegistry.fresh(false, { assignmentID });

  hasLoadedRatingsForAllAssignmentsForSpecificStudents = (studentIDs: string[]) =>
    !this.ratingsForAllAssignmentsForSpecificStudentsRegistry.isLoading() &&
    this.ratingsForAllAssignmentsForSpecificStudentsRegistry.fresh(false, { studentIDs });

  hasLoadedRatingsForAllAssignmentsForAllStudents = () =>
    !this.ratingsForAllAssignmentsForAllStudentsRegistry.isLoading() &&
    this.ratingsForAllAssignmentsForAllStudentsRegistry.fresh(false);

  get ratingsForOneAssignmentForOneStudent() {
    return this.ratingsForOneAssignmentForOneStudentRegistry.value;
  }

  get ratingsForOneAssignmentForAllStudents() {
    return this.ratingsForOneAssignmentForAllStudentsRegistry.value;
  }

  get ratingsForAllAssignmentsForSpecificStudents() {
    return this.ratingsForAllAssignmentsForSpecificStudentsRegistry.value;
  }

  get ratingsForAllAssignmentsForAllStudents() {
    return this.ratingsForAllAssignmentsForAllStudentsRegistry.value;
  }

  reset = () => {
    this.ratingsForOneAssignmentForOneStudentRegistry.reset();
    this.ratingsForOneAssignmentForAllStudentsRegistry.reset();
    this.ratingsForAllAssignmentsForSpecificStudentsRegistry.reset();
    this.ratingsForAllAssignmentsForAllStudentsRegistry.reset();
  };

  loadRatingsForOneAssignmentForOneStudent = async (
    assignmentID: string,
    userID: string,
    courseID: string,
    highestRatingOnly?: boolean
  ) => {
    // if we've already retrieved the Ratings, just return
    if (this.ratingsForOneAssignmentForOneStudentRegistry.fresh(true, { userID, assignmentID }))
      return;

    this.ratingsForOneAssignmentForOneStudentRegistry.setLoading(true, { userID, assignmentID });

    const Ratings = await api.Ratings.listByObjectivesForSpecificStudentsOneAssignment(
      [assignmentID],
      [userID],
      courseID,
      !!highestRatingOnly
    );

    this.ratingsForOneAssignmentForOneStudentRegistry.setAll(
      Ratings,
      { userID, assignmentID },
      (v) =>
        v
          .filter((s) => s.ratingsForStudents) // Assure rating aren't null
          .forEach((s) => {
            s.ratingsForStudents[userID]?.forEach((r) => this.setRatingDates(r));
          })
    );

    this.ratingsForOneAssignmentForOneStudentRegistry.setLoading(false);
  };

  loadRatingsForOneAssignmentForAllStudents = async (
    assignmentID: string,
    courseID: string,
    highestRatingOnly?: boolean
  ) => {
    // if we've already retrieved the Ratings, just return
    if (this.ratingsForOneAssignmentForAllStudentsRegistry.fresh(true, { assignmentID })) return;

    this.ratingsForOneAssignmentForAllStudentsRegistry.setLoading(true, { assignmentID });

    // retrieve the Ratings
    const Ratings = await api.Ratings.listByObjectivesForAllStudentsOneAssignment(
      [assignmentID],
      courseID,
      !!highestRatingOnly
    );

    this.ratingsForOneAssignmentForAllStudentsRegistry.setAll(Ratings, { assignmentID }, (v) =>
      v
        .filter((s) => s.ratingsForStudents) // ensure ratings isn't null
        .forEach((s) => {
          // set dates for every individual Rating in each RatedObjective
          Object.values(s.ratingsForStudents).forEach((ratingArray) =>
            ratingArray.forEach((rating) => this.setRatingDates(rating))
          );
        })
    );

    this.ratingsForOneAssignmentForAllStudentsRegistry.setLoading(false);
  };

  loadRatingsForAllAssignmentsForSpecificStudents = async (
    studentIDs: string[],
    courseID: string,
    highestRatingOnly?: boolean
  ) => {
    // if we've already retrieved the Ratings, just return
    if (this.ratingsForAllAssignmentsForSpecificStudentsRegistry.fresh(true, { studentIDs }))
      return;

    this.ratingsForAllAssignmentsForSpecificStudentsRegistry.setLoading(true, { studentIDs });

    const Ratings =
      await api.Ratings.listAllAssignmentsInCourseWithObjectiveRatingsForSpecificStudents(
        studentIDs,
        courseID,
        !!highestRatingOnly
      );

    this.ratingsForAllAssignmentsForSpecificStudentsRegistry.setAll(Ratings, { studentIDs }, (v) =>
      v.forEach((assignment) => {
        store.assignmentStore.addAssessmentSummarySymbols(assignment.graphicComponents);

        this.setAssignmentDates(assignment);

        assignment.objectives?.forEach((s) => {
          if (!s || !s.ratingsForStudents) return;
          Object.values(s.ratingsForStudents).forEach((ratings) =>
            ratings.forEach((r) => this.setRatingDates(r))
          );
        });
      })
    );

    this.ratingsForAllAssignmentsForSpecificStudentsRegistry.setLoading(false);
  };

  loadRatingsForAllAssignmentsForAllStudents = async (
    courseID: string,
    highestRatingOnly?: boolean
  ) => {
    // if we've already retrieved the Ratings, just return
    if (this.ratingsForAllAssignmentsForAllStudentsRegistry.fresh(true)) return;

    this.ratingsForAllAssignmentsForAllStudentsRegistry.setLoading(true);

    const Ratings = await api.Ratings.listAllAssignmentsInCourseWithObjectiveRatingsForAllStudents(
      courseID,
      !!highestRatingOnly
    );

    this.ratingsForAllAssignmentsForAllStudentsRegistry.setValue(Ratings, (v) =>
      v.forEach((assignment) => {
        store.assignmentStore.addAssessmentSummarySymbols(assignment.graphicComponents);

        this.setAssignmentDates(assignment);

        assignment.objectives?.forEach((s) => {
          if (!s?.ratingsForStudents) return;

          Object.values(s.ratingsForStudents).forEach((ratings) =>
            ratings.forEach((r) => this.setRatingDates(r))
          );
        });
      })
    );

    this.ratingsForAllAssignmentsForAllStudentsRegistry.setLoading(false);
  };

  createOrUpdateRatings = async (
    courseID: string,
    assignmentID: string,
    studentIDs: string[],
    ratings: Rating[]
  ) => api.Ratings.createOrUpdate(courseID, assignmentID, studentIDs, ratings);

  setDefaultRatingsForAllStudents = async (
    courseID: string,
    assignmentID: string,
    ratings: Rating[],
    objectiveIDsToBeOverridden?: string[]
  ) =>
    api.Ratings.setDefaultRatingsForAllStudents(
      courseID,
      assignmentID,
      ratings,
      objectiveIDsToBeOverridden
    );

  setDefaultRatingsForSpecificStudents = (
    courseID: string,
    assignmentID: string,
    ratings: Rating[],
    studentIDs: string[],
    objectiveIDsToBeOverridden?: string[]
  ) =>
    api.Ratings.setDefaultRatingsForSpecificStudents(
      courseID,
      assignmentID,
      ratings,
      studentIDs,
      objectiveIDsToBeOverridden
    );

  publishRatings = async (
    courseID: string,
    assignmentID: string,
    notifyOfChanges: boolean,
    submissionIDs?: string[]
  ) => api.Ratings.publish(courseID, assignmentID, notifyOfChanges, submissionIDs);

  setRatingDates(r: Rating) {
    const rating = r;
    if (rating.createdAt) {
      rating.createdAt = getLocalDate(rating.createdAt);
    }
  }

  setAssignmentDates(a: Assignment<RatedObjective>) {
    const assignment = a;
    if (assignment.dueDate) {
      assignment.dueDate = getLocalDate(assignment.dueDate);
    }
    if (assignment.resubmissionDueDate) {
      assignment.resubmissionDueDate = getLocalDate(assignment.resubmissionDueDate);
    }
  }
}
